import React, { FC } from 'react';
import { TableErrorPropsType } from './table-error.types';
import { ErrorIndicator } from '@/components';

export const TableError: FC<TableErrorPropsType> = ({ className, text }) => {
  const handleReload = () => {
    location.reload();
  };

  return (
    <ErrorIndicator reloadAfterError={() => handleReload()} text={text} className={className} />
  );
};
