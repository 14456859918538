import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import * as Yup from 'yup';
import { ResetPasswordFormTypes, ResetPasswordInitialValues } from './reset-password.types';
import { emailValidator } from '@/utils';
import { Button, InputField } from '@/components';
import { ButtonTypes, Colors, ComponentSize, FillTypes, InputTypes } from '@/constants/enums';
import styles from './reset-password.module.scss';
import { ErrorPlaceholder } from '@/components/authorization';

export const ResetPasswordForm: FC<ResetPasswordFormTypes> = ({
  onSubmitForm,
  loading,
  onClickBack,
  error,
  isError,
}) => {
  const validationSchema = Yup.object({
    email: Yup.string()
      .required('Пожалуйста, введите E-mail')
      .test('verify', 'Почта введена неверно. Возможно вы сделали опечатку.', (email) =>
        emailValidator(email),
      ),
  });

  const initialValues: ResetPasswordInitialValues = {
    email: '',
  };

  return (
    <Formik
      onSubmit={onSubmitForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnMount={true}
    >
      {({ dirty, isValid, handleSubmit, errors, touched }) => (
        <Form
          className={styles.wrapper}
          onSubmit={(event) => {
            event && event.preventDefault();
            handleSubmit();
          }}
        >
          <span className={styles.title}>
            Забыли пароль? Пожалуйста, введите свой адрес электронной почты. Вы получите письмо с
            ссылкой для изменения пароля.
          </span>
          <InputField
            name="email"
            label="Рабочий E-mail"
            placeholder="Введите рабочий E-mail"
            size={ComponentSize.large}
            type={InputTypes.text}
            className={styles.field}
            showToggleButton={false}
            showErrorPlaceholder={false}
            required={true}
          />
          <div className="position-relative mt-2">
            <ErrorPlaceholder
              errors={errors}
              requestError={error}
              touched={touched}
              isError={isError}
            />
          </div>

          <Button
            minWidth={312}
            color={Colors.black}
            size={ComponentSize.large}
            type={ButtonTypes.submit}
            disable={!dirty || !isValid}
            loading={loading}
            className={styles.btn}
          >
            Сбросить пароль
          </Button>
          <Button
            minWidth={312}
            color={Colors.black}
            fillType={FillTypes.outline}
            size={ComponentSize.large}
            onClick={() => onClickBack()}
          >
            Отмена
          </Button>
        </Form>
      )}
    </Formik>
  );
};
