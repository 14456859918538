import React, { FC } from 'react';
import styles from './section.module.scss';
import { Icon, PanelTabButton, SectionPropsType } from '@/components';
import { PanelTabButtonTypes } from '@/constants/enums';

export const ExpeditionCardSection: FC<SectionPropsType> = ({
  className = '',
  label,
  labelCustom,
  isEditable,
  icon = 'outline-plus-24',
  buttonText = 'Добавить данные',
  onClickAdd,
  children,
  uploadAvailable,
  customRequest,
  onDelete,
  fileList,
  fileType,
}) => {
  const customClasses = [styles.wrapper, className].join(' ');

  return (
    <div className={customClasses}>
      <div className={styles.title}>{labelCustom ?? <h3>{label}</h3>}</div>
      <div className={styles.content}>{children}</div>
      {isEditable && (
        <div className={styles.footer}>
          {typeof buttonText === 'string' ? (
            <PanelTabButton
              type={PanelTabButtonTypes.primary}
              onClick={(event) => onClickAdd && onClickAdd(event)}
              onDelete={(item) => onDelete && onDelete(item)}
              uploadAvailable={uploadAvailable}
              customRequest={customRequest}
              fileList={fileList}
              fileType={fileType}
            >
              <Icon icon={icon} size={14} className="me-1" />
              {buttonText}
            </PanelTabButton>
          ) : (
            <div className="d-flex flex-row">
              {buttonText?.map((item, index) => (
                <PanelTabButton
                  type={PanelTabButtonTypes.primary}
                  onClick={(event) => onClickAdd && onClickAdd(event, index)}
                  uploadAvailable={uploadAvailable}
                  customRequest={customRequest}
                  fileList={fileList}
                  fileType={fileType}
                  key={index}
                >
                  <Icon icon={icon} size={14} className="me-1" />
                  {item}
                </PanelTabButton>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
