import { api } from '../api';
import { DefaultResponse, GetMemberResponse } from '@/types';

export const membersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMembers: builder.query<
      GetMemberResponse,
      {
        expId: number;
      } | void
    >({
      query(body) {
        return {
          url: '/member/list',
          method: 'GET',
          params: { expId: body?.expId },
          credentials: 'include',
        };
      },
      providesTags: ['Members'],
    }),
    deleteMember: builder.mutation<DefaultResponse, { id: number }>({
      query(body) {
        return {
          url: '/member',
          method: 'DELETE',
          body: { id: body.id },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Members'],
    }),
    editMember: builder.mutation<
      DefaultResponse,
      {
        id: string;
        name: string;
        position: string;
        birthday: string;
      }
    >({
      query(body) {
        return {
          url: '/member/edit',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Members'],
    }),
    createMember: builder.mutation<
      DefaultResponse,
      {
        name: string;
        position: string;
        birthday: string;
      }
    >({
      query(body) {
        return {
          url: '/member/create',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Members'],
    }),
  }),
});

export const {
  useGetMembersQuery,
  useEditMemberMutation,
  useDeleteMemberMutation,
  useCreateMemberMutation,
} = membersApi;
