import { Icon, Tooltip } from '@/components';
import { ToolsButtonHeaderPropsType } from './tools-button.types';
import { FC } from 'react';

export const ToolsButtonHeader: FC<ToolsButtonHeaderPropsType> = ({
  isActive,
  title,
}): JSX.Element => {
  return isActive ? (
    <div>
      <Icon icon="outline-chevron-up" size={20} />
    </div>
  ) : (
    <Tooltip placement={'left'} title={title}>
      <div>
        <Icon icon="outline-toolbox" size={20} />
      </div>
    </Tooltip>
  );
};
