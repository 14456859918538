import React, { FC, useEffect } from 'react';
import { Input } from 'antd';
import { useField } from 'formik';
import { IHiddenField } from '@/components';

export const HiddenField: FC<IHiddenField> = ({ className, value, name }) => {
  const [, , { setValue, setTouched }] = useField<number | string | boolean | undefined>(name);

  useEffect(() => {
    setValue(value);
    setTouched(true);
  }, [value]);

  return <Input id={name} className={className} type="text" />;
};
