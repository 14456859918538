import React, { FC } from 'react';
import styles from './new-password.module.scss';
import { BlackTooltip, Icon } from '@/components';

export const PasswordTooltipContent: FC = (): JSX.Element => {
  return (
    <BlackTooltip
      title={
        <div className={styles.help}>
          <div>
            <p>Пароль должен содержать:</p>
            <ul>
              <li>8 и более символов</li>
              <li>Прописные латинские буквы</li>
              <li>Строчные латинские буквы</li>
              <li>Цифры</li>
              <li>Знаки пунктуации (!”$%&()+,-./;:&lt;=&gt;?@[]^_&#123;&#124;&#125;~`)</li>
            </ul>
          </div>
          <div>
            <p>Пароль не должен:</p>
            <ul>
              <li>
                Содержать последовательность символов, входящую в состав индивидуального имени
                пользователя (логина)
              </li>
              <li>Совпадать с предыдущими паролями</li>
            </ul>
          </div>
        </div>
      }
      placement={'right'}
    >
      <Icon style={{ cursor: 'pointer' }} size={16} icon={'outline-question-circle'} />
    </BlackTooltip>
  );
};
