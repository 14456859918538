import React, { FC } from 'react';
import { ObjectCardItem } from '@/components';
import { ObjectCardPropsType } from '../object-card-panel.types';

export const ObjectHeightAngDepthsSection: FC<ObjectCardPropsType> = ({
  onSubmit,
  success,
  loading,
  isEditable,
}) => {
  return (
    <ObjectCardItem
      title="Таблица высот и глубин"
      smallTitle={true}
      type="table"
      isEditable={isEditable}
      onSubmit={() => onSubmit()}
      success={success}
      loading={loading}
    />
  );
};
