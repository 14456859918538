import React, { FC, useEffect, useRef } from 'react';
import styles from './table-search.module.scss';
import { TableSearchPropsType } from './table-search.types';
import { Icon, Input, PanelTabButton } from '@/components';
import { PanelTabButtonTypes } from '@/constants/enums';
import { InputRef } from 'antd';

export const TableSearch: FC<TableSearchPropsType> = ({
  onClear,
  onSearch,
  onFilter,
  visible,
  setSelectedKeys,
  selectedKeys,
}) => {
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  }, [visible]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Input
          ref={searchInput}
          value={selectedKeys[0]?.toString()}
          onChange={(event) => setSelectedKeys([event?.target?.value])}
          tabIndex={1}
          onPressEnter={() => onSearch()}
          className={styles.input}
        />
      </div>
      <div className={styles.footer}>
        <PanelTabButton onClick={() => onFilter()} tabIndex={2}>
          Фильтр
        </PanelTabButton>
        <PanelTabButton
          onClick={() => {
            onClear && onClear();
            onSearch();
          }}
          tabIndex={3}
          type={PanelTabButtonTypes.primary}
          disabledStyle={true}
        >
          Сбросить
        </PanelTabButton>
        <PanelTabButton
          onClick={() => {
            onSearch();
            onClear && onClear();
          }}
          tabIndex={4}
        >
          <Icon size={12} icon="outline-search" />
          &nbsp;Поиск
        </PanelTabButton>
      </div>
    </div>
  );
};
