import { DecimalCord, GraduatedCord } from '@/types';
import { LatLng, LatLngExpression, LatLngTuple } from 'leaflet';
import { decimalCoordsValidator } from './validators';

export const convertToDecimalCord = (cord: string | null | undefined, isLat = false): string => {
  if (cord) {
    const absDd = Math.abs(Number(cord));
    const deg = absDd | 0;
    const frac = absDd - deg;
    const min = (frac * 60) | 0;
    let sec = frac * 3600 - min * 60;
    sec = Math.round(sec * 100) / 100;
    const isNegative = Number(cord) < 0;
    const dir = isNegative ? (isLat ? 'S' : 'W') : isLat ? 'N' : 'E';

    return `${deg}°${min}'${sec}"${dir}`;
  }
  return '';
};

export const convertToGraduatedCord = (cord: string | null | undefined): string => {
  if (cord) {
    const splitted = cord?.split('°');
    const hh = splitted[0];
    const mm = splitted[1]?.split("'")[0];
    const ss = splitted[1]?.split("'")[1].replace(/"\w/g, '');
    const result = parseInt(hh) + parseFloat(mm) / 60 + parseFloat(ss) / 3600;
    const dir = cord[cord.length - 1];

    return dir === 'W' || dir === 'S' ? `-${result}` : `${result}`;
  }
  return '';
};

export const prepareCoords = (cords: LatLng | null, cordType: string): Array<string> => {
  if (cordType === 'DEC') {
    return [cords?.lat.toFixed(6).toString() ?? '', cords?.lng.toFixed(6).toString() ?? ''];
  } else {
    return [
      convertToDecimalCord(cords?.lat.toFixed(6).toString() ?? null, true),
      convertToDecimalCord(cords?.lng.toFixed(6).toString() ?? null, false),
    ];
  }
};

export const getRoundNum = (maxMeters: number): number => {
  const pow10 = Math.pow(10, Math.floor(maxMeters).toString().length - 1),
    d = maxMeters / pow10;

  const scale = d >= 10 ? 10 : d >= 5 ? 5 : d >= 3 ? 3 : d >= 2 ? 2 : 1;

  return pow10 * scale;
};

export const prepareScale = (maxMeters: number): Array<string> => {
  const meters = getRoundNum(maxMeters);
  const meterLabel = meters < 1000 ? `${meters} m` : `${meters / 1000} km`;
  const maxFeets = maxMeters * 3.2808399;
  const feetsLabel =
    maxFeets > 5280 ? `${getRoundNum(maxFeets / 5280)} mi` : `${getRoundNum(maxFeets)} ft`;

  return [meterLabel, feetsLabel];
};

export const convertToDecimal = ({
  DD,
  MM,
  SS,
  directionIsNegative,
}: GraduatedCord): DecimalCord => {
  const Day = directionIsNegative
    ? parseInt(DD ? '-' + DD.toString() : '0')
    : parseInt(DD ? DD : '0');
  const Month = (MM ? +MM : 0) / 60;
  const Sec = (SS ? +SS : 0) / 3600;

  return (Day + Month + Sec).toString() || '';
};

export const convertToGraduated = (value: DecimalCord): GraduatedCord => {
  let DD = Math.floor(+value);
  const MM = Math.floor((+value - DD) * 60);
  let SS = Math.floor(((+value - DD) * 60 - +MM) * 60 * 100).toString();
  const directionIsNegative = DD < 0;

  if (DD < 0) {
    DD = +DD.toString().replace(/\D/, '');
  }

  switch (SS.length) {
    case 0:
      SS = '0';
      break;
    case 1:
      SS = SS.slice(0, 1);
      break;
    case 2:
      SS = SS + '.00';
      break;
    case 3:
      SS = SS.slice(0, 2) + '.' + SS.slice(2, 3) + '0';
      break;
    default:
      SS = SS.slice(0, 2) + '.' + SS.slice(2);
      break;
  }

  return {
    DD: DD.toString(),
    MM: MM.toString(),
    SS,
    directionIsNegative,
  };
};

export const prepareCordToString = (
  value?: DecimalCord | GraduatedCord,
  directions?: ['N', 'S'] | ['E', 'W'],
): string => {
  if (!value) {
    return '';
  }
  if (typeof value === 'string') {
    if (value.includes('°') && value.includes('"') && value.includes("'")) {
      return value;
    }
    if (value.length > 5) {
      return Number(value).toFixed(6);
    }
    if (!value.split('.')?.[1] || value.split('.')?.[1]?.length === 0) {
      return Number(value).toFixed(1);
    }
    return value;
  }
  if (typeof value === 'object') {
    return Object.entries(value)
      .map(([key, objValue]) => {
        switch (key) {
          case 'DD':
            if (value.directionIsNegative) {
              return '-' + objValue.toString() + '°';
            }
            return objValue.toString() + '°';
          case 'MM':
            return objValue.toString() + "'";
          case 'SS':
            return objValue.toString() + '"';
          case 'directionIsNegative':
            if (directions) {
              return objValue ? directions[1] : directions[0];
            }
            return '';
          default:
            return objValue;
        }
      })
      .join('');
  }
  return '';
};

export const getConvertLatLngSixSigns = (number: number | string): number | undefined => {
  if (number)
    if (typeof number === 'number') return parseFloat(number.toFixed(6));
    else {
      return parseFloat(number);
    }

  return undefined;
};

export const checkPolygon = (polygon: LatLngExpression[][][], isCreateMode = false) => {
  const isValid =
    (polygon[0][0].length >= 4 || (isCreateMode && polygon[0][0].length >= 3)) &&
    polygon[0][0].filter((item) => {
      const coord = item as LatLngTuple;

      return (
        !decimalCoordsValidator(coord[0].toString()) || !decimalCoordsValidator(coord[1].toString())
      );
    }).length === 0;

  return isValid;
};

export const checkEmptyPointsAtPolygon = (polygon: LatLngExpression[][][]) => {
  const isValid =
    polygon[0][0].filter((item) => {
      const coord = item as LatLngTuple;

      return isNaN(coord[0]) || isNaN(coord[1]);
    }).length === 0;

  return isValid;
};

export const parsePolygonToWKT = (polygon: LatLngExpression[][][], reversed = false) => {
  const slicesPolygon = polygon[0][0];
  const joinedPolygon = slicesPolygon
    .map((item) => {
      const tuple = [...(item as LatLngTuple)];

      return reversed ? tuple.reverse().join(' ') : tuple.join(' ');
    })
    .join(', ');

  return `POLYGON((${joinedPolygon}))`;
};
