import { api } from '../api';
import { Conclusion, DefaultExpeditionType, CreationConclusion } from '@/types';

export const conclusionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createExpeditionConclusion: builder.mutation<DefaultExpeditionType, CreationConclusion>({
      query(body) {
        return {
          url: '/conclusion/add',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    editExpeditionConclusion: builder.mutation<DefaultExpeditionType, Conclusion>({
      query(body) {
        return {
          url: '/conclusion/edit',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    deleteExpeditionConclusion: builder.mutation<
      DefaultExpeditionType,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/conclusion',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
  }),
});

export const {
  useCreateExpeditionConclusionMutation,
  useEditExpeditionConclusionMutation,
  useDeleteExpeditionConclusionMutation,
} = conclusionApi;
