import React, { FC } from 'react';
import { Section } from './card-content-section';
import styles from './card-content.module.scss';
import { ContentPropsType } from './card-content.types';
import { TableLoader } from '@/components';

type ContentSubComponents = {
  Section: typeof Section;
};

export const Content: FC<ContentPropsType> & ContentSubComponents = ({
  className,
  loading,
  children,
}) => {
  const customClasses = [styles.content, className].join(' ');

  return (
    <div className={customClasses} id="scroll-area">
      <div className={styles.card}>
        {loading && (
          <div className={styles['loader-wrapper']}>
            <TableLoader className={styles.loader} />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
Content.Section = Section;
