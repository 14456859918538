import React, { FC, useEffect, useRef } from 'react';
import { RegionPolygon } from '../../elements';
import { useAppSelector } from '@/state';
import { useGetRegionsQuery } from '@/state/api';
import { REGIONS_LAYER, SECTORS_LAYER, WATER_AREAS_LAYER } from '@/constants';
import { FeatureGroup } from 'react-leaflet';
import { FeatureGroup as FG } from 'leaflet';

export const RegionsLayer: FC = (): JSX.Element => {
  const selectedLayers = useAppSelector((state) => state.map.selectedLayers);
  const { data: regions } = useGetRegionsQuery();
  const layerRef = useRef<FG>(null);

  useEffect(() => {
    (selectedLayers.includes(WATER_AREAS_LAYER) || selectedLayers.includes(SECTORS_LAYER)) &&
      layerRef.current?.bringToBack();
  }, [selectedLayers]);

  return (
    <FeatureGroup ref={layerRef}>
      {selectedLayers.includes(REGIONS_LAYER)
        ? regions?.map((item, idx) => {
            return <RegionPolygon item={item} key={idx} />;
          })
        : false}
    </FeatureGroup>
  );
};
