import React, { FC } from 'react';
import styles from './deep-and-height-table.module.scss';
import { ObjectCardTablePropsType } from '../../object-card-panel.types';
import { InputField } from '@/components';
import { ComponentSize } from '@/constants/enums';

export const DeepAndHeightTable: FC<ObjectCardTablePropsType> = ({ isEdit = true, className }) => {
  const customStyles = [styles.wrapper, className].join(' ');

  const rows = [
    {
      label: 'Модель шпангоут',
      fields: [
        {
          label: 'ЛБ',
          height: 'dim_height_LB',
          depth: 'dim_depth_LB',
        },
        {
          label: 'ПБ',
          height: 'dim_height_RB',
          depth: 'dim_depth_RB',
        },
        {
          label: 'Ахтерштевень',
          height: 'dim_height_sternpost',
          depth: 'dim_depth_sternpost',
          bold: true,
        },
        {
          label: 'Форштевень',
          height: 'dim_height_voorsteven',
          depth: 'dim_depth_voorsteven',
          bold: true,
        },
      ],
    },
    {
      label: 'Нос',
      fields: [
        {
          label: 'ЛБ',
          height: 'dim_height_bow_LB',
          depth: 'dim_depth_bow_LB',
        },
        {
          label: 'ПБ',
          height: 'dim_height_bow_RB',
          depth: 'dim_depth_bow_RB',
        },
      ],
    },
    {
      label: 'Корма',
      fields: [
        {
          label: 'ЛБ',
          height: 'dim_height_stern_LB',
          depth: 'dim_depth_stern_LB',
        },
        {
          label: 'ПБ',
          height: 'dim_height_stern_RB',
          depth: 'dim_depth_stern_RB',
        },
      ],
    },
  ];

  return (
    <div className={customStyles}>
      <div className={`${styles.header} ${styles.row}`}>
        <span className={styles.cell}>Параметр</span>
        <div className={styles.divider} />
        <span className={styles.cell}>Высота от грунта</span>
        <div className={styles.divider} />
        <span className={styles.cell}>Глубина</span>
      </div>
      {rows.map(({ label, fields }, index) => (
        <div key={index} className={styles.field}>
          <div className={styles.row}>
            <span className={`${styles.label} ${styles.cell}`}>{label}</span>
          </div>
          {fields.map(({ label, bold, height, depth }, index) => (
            <div className={styles.row} key={index}>
              <span className={`${bold ? styles.bold : ''} ${styles.cell}`}>{label}</span>
              <div className={styles.divider} />
              <InputField
                name={height}
                placeholder="Высота не указана"
                className={styles.input}
                size={ComponentSize.small}
                readOnly={!isEdit}
              />
              <div className={styles.divider} />
              <InputField
                name={depth}
                placeholder="Глубина не указана"
                className={styles.input}
                size={ComponentSize.small}
                readOnly={!isEdit}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
