import React from 'react';
import styles from './ships-base.module.scss';
import { ITooltipObjectShipsBase } from './';

export const ShipsBase = ({ item }: { item: ITooltipObjectShipsBase }) => {
  return (
    <>
      <div className={styles.title}>
        <p className={styles.bold}>База судов</p>
      </div>
      <div className={styles.body}>
        {item.name && (
          <p className={styles.text}>
            <span className={styles.other}>Наименование:</span> {item.name}
          </p>
        )}
      </div>
    </>
  );
};
