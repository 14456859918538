import React, { ChangeEvent, FC, useState } from 'react';
import styles from './table-filter.module.scss';
import { TableFilterPropsType } from './table-filter.types';
import { Checkbox, Input, PanelTabButton } from '@/components';
import { PanelTabButtonTypes } from '@/constants/enums';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Key } from 'antd/lib/table/interface';
import { includes as textIncludes } from 'lodash';

export const TableFilter: FC<TableFilterPropsType> = ({
  filters,
  onFilter,
  onChange,
  onClear,
  selectedKeys,
}): JSX.Element => {
  const [currentFilters, setCurrentFilters] = useState(filters);

  const handleSetCurrentFilters = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event?.target?.value.toString().toLowerCase();
    const newFilters = filters?.filter(({ text }) =>
      textIncludes(text?.toString().toLowerCase(), value),
    );
    setCurrentFilters(newFilters);
  };

  const handleToggleAllFilters = (event: CheckboxChangeEvent): void => {
    if (event?.target?.checked) {
      const newKeys = filters?.map(({ value }) => value);
      onChange(newKeys as Key[]);
    } else {
      onChange([]);
    }
  };

  const handleToggleFilter = (event: CheckboxChangeEvent, key: string | number): void => {
    if (event?.target?.checked) {
      const newKeys = [...selectedKeys, key];
      onChange(newKeys);
    } else {
      const newKeys = selectedKeys.filter((item) => item !== key);
      onChange(newKeys);
    }
  };

  const handleCheck = (value: string | number): boolean => selectedKeys.includes(value);

  const checkedAll = selectedKeys.length === filters?.length;
  const checkedSomething = selectedKeys.length > 0;

  return (
    <div className={styles.filter}>
      <div className={styles.header}>
        <Input
          tabIndex={1}
          className={styles.input}
          onChange={handleSetCurrentFilters}
          placeholder="Поиск"
        />
      </div>
      <div className={styles.body}>
        <div className={styles.checkbox}>
          <Checkbox
            onChange={(event) => handleToggleAllFilters(event)}
            indeterminate={checkedSomething && !checkedAll}
            checked={checkedAll}
          >
            Выбрать все
          </Checkbox>
        </div>
        <div className={styles['checkbox-group']}>
          {currentFilters?.map(({ text, value }, idx) => (
            <div key={idx} className={styles.checkbox}>
              <Checkbox
                checked={handleCheck(value.toString())}
                onChange={(event) => handleToggleFilter(event, value.toString())}
              >
                {text}
              </Checkbox>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.footer}>
        <PanelTabButton
          onClick={() => {
            onClear && onClear();
            onFilter && onFilter();
          }}
          type={PanelTabButtonTypes.primary}
          disabledStyle={true}
          tabIndex={2}
        >
          Сбросить
        </PanelTabButton>
        <PanelTabButton onClick={() => onFilter && onFilter()} tabIndex={3}>
          Ок
        </PanelTabButton>
      </div>
    </div>
  );
};
