import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import styles from './login.module.scss';
import * as Yup from 'yup';
import { ILoginForm, InitialValuesType } from './login.types';
import { emailValidator } from '@/utils';
// import { Checkbox } from '@/components';
import { ButtonTypes, Colors, ComponentSize, FillTypes, InputTypes } from '@/constants/enums';
import { Button, InputField } from '@/components';
import { ErrorPlaceholder } from '../error-placeholder';

export const LoginForm: FC<ILoginForm> = ({
  onSubmitForm,
  onResetPassword,
  loading,
  error,
  isError,
}) => {
  const initialValues: InitialValuesType = {
    email: '',
    password: '',
    rememberMe: false,
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .required('Пожалуйста, введите E-mail')
      .test('verify', 'Почта введена неверно. Возможно вы сделали опечатку.', (email): boolean =>
        emailValidator(email),
      ),
    password: Yup.string().required('Пожалуйста, введите пароль'),
  });

  return (
    <Formik
      onSubmit={onSubmitForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnMount={true}
    >
      {({ dirty, isValid, handleSubmit, errors, touched }) => (
        <Form
          className={styles.wrapper}
          onSubmit={(event) => {
            event && event.preventDefault();
            handleSubmit();
          }}
        >
          <InputField
            name="email"
            label="Рабочий E-mail"
            placeholder="Введите рабочий E-mail"
            size={ComponentSize.large}
            className={styles.field}
            showErrorPlaceholder={false}
            required={true}
          />
          <InputField
            name="password"
            label="Пароль"
            placeholder="Введите пароль"
            size={ComponentSize.large}
            type={InputTypes.password}
            className={styles.field}
            showErrorPlaceholder={false}
            required={true}
          />
          <ErrorPlaceholder
            errors={errors}
            touched={touched}
            requestError={error}
            isError={isError}
          />
          {/*<Field name='rememberMe'>*/}
          {/*  {({ field: { value, onChange, name } }: FieldProps): JSX.Element => (*/}
          {/*    <Checkbox*/}
          {/*      className={styles.layers-checkbox}*/}
          {/*      type='layers-checkbox'*/}
          {/*      id={name}*/}
          {/*      checked={!!value}*/}
          {/*      onChange={onChange}*/}
          {/*    >*/}
          {/*      Запомнить меня*/}
          {/*    </Checkbox>*/}
          {/*  )}*/}
          {/*</Field>*/}
          <div className={styles.footer}>
            <Button
              minWidth={312}
              color={Colors.black}
              size={ComponentSize.large}
              type={ButtonTypes.submit}
              disable={!dirty || !isValid}
              loading={loading}
            >
              Войти
            </Button>
            <Button
              minWidth={312}
              color={Colors.black}
              fillType={FillTypes.noFill}
              size={ComponentSize.medium}
              type={ButtonTypes.button}
              onClick={() => onResetPassword()}
            >
              Забыли пароль?
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
