import { LatLng } from 'leaflet';
import { createContext, useContext, useState, Dispatch, SetStateAction } from 'react';

type LiveCordsContextType = {
  liveCords: LatLng | null;
  setLiveCords: Dispatch<SetStateAction<LatLng | null>>;
};

export const LiveCordsContext = createContext<LiveCordsContextType>({
  liveCords: null,
  setLiveCords: () => {},
});

export const useLiveCords = () => useContext(LiveCordsContext);

export const LiveCordsProvider = ({ children }: { children: JSX.Element }) => {
  const [liveCords, setLiveCords] = useState<LatLng | null>(null);

  return (
    <LiveCordsContext.Provider value={{ liveCords, setLiveCords }}>
      {children}
    </LiveCordsContext.Provider>
  );
};
