import React, { FC, useEffect } from 'react';
import styles from './dropdown-field.module.scss';
import {
  DropdownFieldDataType,
  DropdownFieldPropsType,
  DropdownFieldValueType,
  ErrorMessage,
  Icon,
} from '@/components';
import { Field, FieldProps, useField } from 'formik';
import { Select } from 'antd';
import { openErrorNotify, prepareSelectFieldValue } from '@/utils';
import { ComponentSize } from '@/constants/enums';
import { isEqual } from 'lodash';

export const DropdownField: FC<DropdownFieldPropsType> = ({
  label = '',
  data,
  name,
  className,
  required,
  loading,
  placeholder = 'Не выбрано',
  size = ComponentSize.medium,
  hideError = false,
  error,
  mode = undefined,
  noDataPlaceholder,
  isDefaultPopupContainer = true,
}): JSX.Element => {
  const customStyles = [styles.dropdown, styles[size], className].join(' ');

  const [, { initialValue, value }, { setValue, setTouched }] =
    useField<DropdownFieldValueType>(name);

  const preparedData = data?.map((item) => ({
    ...item,
    value: item?.value?.toString(),
    label: item?.label || 'Элемент не выбран',
  }));
  const preparedInitialValue = prepareSelectFieldValue(initialValue, preparedData);
  const preparedValue = prepareSelectFieldValue(value, preparedData);

  const handleFilter = (searchValue: string, data?: DropdownFieldDataType): boolean =>
    (data?.label ?? '').toLowerCase().includes(searchValue.toLowerCase());

  const handleSearch = (value: DropdownFieldValueType, prevValue?: DropdownFieldValueType) => {
    if (mode === 'multiple') {
      if (prevValue instanceof Array) {
        const preparedValue = prevValue && prevValue.length ? [...prevValue, +value] : [+value];
        const array = prevValue as number[];
        const valueUnique = !array?.find(
          (item) =>
            item === value || item?.toString() === value?.toString() || isEqual(item, value),
        );
        valueUnique && setValue(preparedValue as DropdownFieldValueType);
      }
    } else {
      setValue(value);
    }
  };

  const handleDeselect = (value: DropdownFieldValueType, prevValue?: DropdownFieldValueType) => {
    if (prevValue instanceof Array) {
      const array = prevValue as number[];
      const preparedValue = array?.filter((item) => !isEqual(item, value)) || [];
      setValue(preparedValue);
      setTouched(true);
    }
  };

  const handleBlur = (prevValue: DropdownFieldValueType, value: DropdownFieldValueType) => {
    if (!isEqual(prevValue, value)) {
      setTouched(true);
    }
  };

  useEffect(() => {
    return () => {
      setValue(preparedValue);
    };
  }, []);

  useEffect(() => {
    if (!isEqual(preparedInitialValue, preparedValue)) {
      setTouched(true);
    }
  }, [value]);

  useEffect(() => {
    if (error) {
      openErrorNotify('Произошла ошибка', 'Не удалось загрузить данные');
    }
  }, [error]);

  return (
    <div className={customStyles} key={`field-${String(name)}`}>
      {label && <label className="mb-1">{required ? label + '*' : label}</label>}
      <Field name={name}>
        {(props: FieldProps): JSX.Element => (
          <>
            <Select
              {...props}
              showSearch
              mode={mode}
              options={preparedData}
              loading={loading}
              placeholder={placeholder}
              popupClassName={styles.list}
              value={preparedValue || null}
              notFoundContent={
                preparedData && preparedData?.length > 0 ? noDataPlaceholder : undefined
              }
              defaultValue={preparedValue || null}
              filterOption={handleFilter}
              onBlur={() => handleBlur(preparedInitialValue, preparedValue)}
              onDeselect={(optionValue: DropdownFieldValueType) =>
                handleDeselect(optionValue, preparedValue)
              }
              onSelect={(optionValue: DropdownFieldValueType) =>
                handleSearch(optionValue, preparedValue)
              }
              dropdownAlign={{
                offset: [0, mode === 'multiple' ? 4 : 0],
              }}
              suffixIcon={<Icon size={16} icon="outline-chevron-down" />}
              getPopupContainer={(trigger) =>
                !isDefaultPopupContainer ? trigger.parentElement : document.body
              }
              key={`dropdown-${name}`}
              defaultActiveFirstOption={true}
              allowClear={false}
              showArrow={true}
              virtual={false}
            />
            {!hideError && <ErrorMessage name={name} className={styles.error} />}
          </>
        )}
      </Field>
    </div>
  );
};
