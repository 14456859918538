import { resetAction } from '@/state/actions';
import { ShipsBasesStateType } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ShipsBasesStateType = {
  selectedItem: undefined,
};

const shipsBasesSlice = createSlice({
  name: 'shipsBases',
  initialState,
  reducers: {
    setSelectedShipBase: (state, { payload }: PayloadAction<number | undefined>) => {
      state.selectedItem = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAction, () => {
      return initialState;
    });
  },
});

export const { setSelectedShipBase } = shipsBasesSlice.actions;
export const { reducer: shipsBasesReducer } = shipsBasesSlice;
