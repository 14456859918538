import React, { FC } from 'react';
import { ObjectCoordsSelectionToolsButtonPropsType } from './marker-selection-page.types';
import { useAppSelector } from '@/state';
import { useDispatch } from 'react-redux';
import { toggleCordStyle } from '@/state/slice';
import { ToolsButton } from '@/components';

export const MarkerSelectionToolsButton: FC<ObjectCoordsSelectionToolsButtonPropsType> = ({
  isRoutePanelOpen,
  toggleRoutePanel,
}) => {
  const cordStyle = useAppSelector((state) => state?.map.coordsType);
  const dispatch = useDispatch();

  const handleOpenModal = (isActive: boolean, name?: string): void => {
    switch (name) {
      case 'cordStyle':
        dispatch(toggleCordStyle());
        break;
      case 'routePanel':
        toggleRoutePanel();
        break;
      default:
        break;
    }
  };

  const toolsList = [
    {
      icon: 'outline-divider',
      title: 'Измерить расстояние',
      name: 'routePanel',
      onClick: handleOpenModal,
      isActive: isRoutePanelOpen,
    },
    {
      icon: 'outline-globe',
      title: 'Стиль координат',
      name: 'cordStyle',
      onClick: handleOpenModal,
      isActive: cordStyle === 'DEG',
    },
  ];

  return <ToolsButton toolsList={toolsList} />;
};
