import React, { FC } from 'react';
import styles from './title.module.scss';
import { CloseButton, ModalHeaderButtons, TableCell } from '@/components';
import { TitlePropsType } from './title.types';
import { StatusChanger } from '../status-changer';
import { ExpeditionStatuses, ExpeditionStatusesType } from '@/constants/enums';

export const Title: FC<TitlePropsType> = ({
  item,
  onClickDocDownload,
  onClickPdfDownload,
  onClose,
  isEditable,
  loadingPdf,
  loadingWord,
  loading,
}) => {
  const getText = (status?: ExpeditionStatusesType) => {
    switch (status) {
      case ExpeditionStatuses.plan:
        return 'Для создания карточки экспедиции нажмите на кнопку «Перевести в карточку экспедиции»';
      case ExpeditionStatuses.approved:
        return (
          'Для возврата в отчет экспедиции нажмите кнопку «Отчет экспедиции». ' +
          'Для переключения в карточку экспедиции в содержании нажмите кнопку «Карточка экспедиции»'
        );
      case ExpeditionStatuses.card:
        return 'Для создания отчета экспедиции нажмите на кнопку «Перевести в отчет экспедиции»';
      case ExpeditionStatuses.report:
        return 'Для утверждения отчета экспедиции нажмите на кнопку «Утвердить отчет экспедиции»';
      default:
        return '';
    }
  };

  return (
    <div className={styles.title}>
      <div className={styles['title-text-wrapper']}>
        <TableCell
          value={loading ? 'Загрузка...' : item?.name}
          className={styles['title-text']}
          maxWidth={1400}
          fontSize={18}
        />
        <StatusChanger item={item} isEditable={isEditable} />
      </div>
      <div className={styles['title-btn-group']}>
        {item?.status && (
          <ModalHeaderButtons
            key="question"
            text={getText(item?.status)}
            onClick={() => {}}
            icon="outline-question-circle"
            placement="bottomLeft"
          />
        )}
        {isEditable ? (
          <>
            <ModalHeaderButtons
              key="Word"
              text="Экспортировать экспедицию в Word"
              onClick={() => onClickDocDownload(item?.id as number)}
              icon="outline-export-Word"
              placement="bottomLeft"
              loading={loadingWord}
            />
            <ModalHeaderButtons
              key="pdf"
              text="Экспортировать экспедицию в PDF"
              onClick={() => onClickPdfDownload(item?.id as number)}
              icon="outline-export-Pdf"
              placement="bottomLeft"
              loading={loadingPdf}
            />
          </>
        ) : null}
        <CloseButton className={styles['close-button']} onClick={() => onClose()} />
      </div>
    </div>
  );
};
