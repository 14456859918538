import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM, MAP } from '@/constants';
import { Permissions } from '@/constants/enums';
import { useMap, useClickPosition, useLiveCords } from '@/context';
import { useAppSelector } from '@/state';
import { LatLng, LatLngExpression } from 'leaflet';
import React, { useEffect } from 'react';
import { useMapEvents } from 'react-leaflet';
import { useLocation, useSearchParams } from 'react-router-dom';

export const MapEvents: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { map } = useMap();
  const location = useLocation();
  const { setLiveCords } = useLiveCords();
  const { setPosition } = useClickPosition();
  const isCoordsEditorEnabled = useAppSelector((state) => state.map.coordsEditorEnabled);
  const activePanelRoute = useAppSelector((state) => state.toolsPanels.routePanel.activePanelRoute);
  const isZCoordsEditor = useAppSelector((state) => state.toolsPanels.zCoordsPanel.isZCoordsEditor);
  const isMarkerCreation = useAppSelector(
    (state) => state.toolsPanels.objectCreationPanel.isMarkerCreationSelection,
  );
  const isMetaLogOpen = useAppSelector((state) => state.toolsPanels.metaLogPanel.isMetaLogOpen);
  const isMetaGalsOpen = useAppSelector((state) => state.toolsPanels.metaGalsPanel.isMetaGalsOpen);
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  useEffect(() => {
    if (map) {
      let zoom: number;
      let center: LatLngExpression;
      if (
        (searchParams.get('lat') || searchParams.get('lng') || searchParams.get('zoom')) &&
        userPermissions?.includes(Permissions.specificDataViewing)
      ) {
        const lat = Number(searchParams.get('lat'));
        const lng = Number(searchParams.get('lng'));
        zoom = Number(searchParams.get('zoom'));
        center = [lat, lng];
        map.flyTo(center, zoom, { duration: 0.5 });
      } else if (
        localStorage.getItem('zoom') !== null &&
        localStorage.getItem('lat') !== null &&
        localStorage.getItem('lng') !== null
      ) {
        center = [Number(localStorage.getItem('lat')), Number(localStorage.getItem('lng'))];
        zoom = Number(localStorage.getItem('zoom'));
        map.flyTo(center, zoom, { duration: 0.5 });
      } else {
        zoom = DEFAULT_ZOOM;
        center = [DEFAULT_LAT, DEFAULT_LNG];
        map.flyTo(center, zoom, { duration: 0.5 });
      }
      setLiveCords(map.getCenter());
    }
  }, [map]);

  useMapEvents({
    click(e) {
      const position: LatLng = e.latlng;
      if (isCoordsEditorEnabled) {
        map?.getContainer().style.setProperty('cursor', 'crosshair');
      }

      const checkPanels =
        isCoordsEditorEnabled ||
        activePanelRoute ||
        isZCoordsEditor ||
        isMetaLogOpen ||
        isMetaGalsOpen ||
        isMarkerCreation;
      if (position && checkPanels) {
        setPosition(position);
      }
    },

    mousemove(e) {
      const position = e.latlng;
      if (position) {
        setLiveCords(position);
      }
    },
    moveend() {
      if (map) {
        const mapParamsChange = {
          zoom: map.getZoom(),
          center: map.getCenter(),
        };
        if (mapParamsChange.center) {
          if (
            location.pathname === MAP &&
            userPermissions?.includes(Permissions.specificDataViewing)
          ) {
            searchParams.set('lat', mapParamsChange.center.lat.toString());
            searchParams.set('lng', mapParamsChange.center.lng.toString());
            searchParams.set('zoom', mapParamsChange.zoom.toString());
          } else {
            searchParams.delete('lat');
            searchParams.delete('lng');
            searchParams.delete('zoom');
          }
          localStorage.setItem('zoom', mapParamsChange.zoom.toString());
          localStorage.setItem('lat', mapParamsChange.center.lat.toString());
          localStorage.setItem('lng', mapParamsChange.center.lng.toString());
        }
        setSearchParams(searchParams);
      }
    },
  });
  return null;
};
