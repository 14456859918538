import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { LINK_TO_OTP_VALIDAITON, LINK_TO_RESET_PASSWORD } from '@/constants';
import { AuthorizationWrapper } from '@/components/authorization';
import { InitialValuesType } from '@/components/authorization/login/login.types';
import { useCheckUserMutation } from '@/state/api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { LoginForm } from './login-form';

export const Login: FC = (): JSX.Element => {
  const [checkUser, { isLoading, error, isError, isSuccess, originalArgs }] =
    useCheckUserMutation();
  const navigate = useNavigate();

  const handleResetPassword = (): void => {
    navigate(LINK_TO_RESET_PASSWORD);
  };

  const handleSubmit = async ({ email, password }: InitialValuesType): Promise<void> => {
    await checkUser({ email, password });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(LINK_TO_OTP_VALIDAITON, { state: originalArgs });
    }
  }, [isSuccess, originalArgs]);

  return (
    <AuthorizationWrapper headerText="авторизация">
      <LoginForm
        onSubmitForm={(value) => {
          handleSubmit(value);
        }}
        onResetPassword={handleResetPassword}
        loading={isLoading}
        error={error as FetchBaseQueryError}
        isError={isError}
      />
    </AuthorizationWrapper>
  );
};
