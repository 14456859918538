import React, { FC } from 'react';
import { menuData, ObjectCardItem } from '@/components';
import { ObjectCardPropsType } from '../object-card-panel.types';

export const ObjectFixationPlacementSection: FC<ObjectCardPropsType> = ({
  onSubmit,
  success,
  loading,
  isEditable,
}) => {
  const fields = [
    {
      label: 'Фиксация места объекта по трем пеленгам магнитного компаса ОА',
      placeholder: 'Пеленги не указаны',
      name: 'attach_bearing_OA',
    },
    {
      label: 'Фиксация места объекта по трем пеленгам магнитного компаса ОВ',
      placeholder: 'Пеленги не указаны',
      name: 'attach_bearing_OB',
    },
    {
      label: 'Фиксация места объекта по трем пеленгам магнитного компаса ОС',
      placeholder: 'Пеленги не указаны',
      name: 'attach_bearing_OC',
    },
  ];

  return (
    <ObjectCardItem
      title={menuData[2]?.subtitle?.[0].label}
      smallTitle={true}
      type="column"
      fields={fields}
      id={menuData[2]?.subtitle?.[0].link}
      onSubmit={() => onSubmit()}
      isEditable={isEditable}
      success={success}
      loading={loading}
    />
  );
};
