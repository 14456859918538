import React, { FC } from 'react';
import { WaterAreaPolygon } from '../../elements';
import { useAppSelector } from '@/state';
import { useGetWaterAreasQuery } from '@/state/api';
import { WATER_AREAS_LAYER } from '@/constants';
import { FeatureGroup } from 'react-leaflet';

export const WaterAreasLayer: FC<{ isInteractive?: boolean }> = ({
  isInteractive = true,
}): JSX.Element => {
  const selectedLayers = useAppSelector((state) => state.map.selectedLayers);
  const { data: waterAreas } = useGetWaterAreasQuery();

  return (
    <FeatureGroup>
      {selectedLayers.includes(WATER_AREAS_LAYER)
        ? waterAreas?.map((item, idx) => {
            return <WaterAreaPolygon item={item} key={idx} isNonInteractive={!isInteractive} />;
          })
        : false}
    </FeatureGroup>
  );
};
