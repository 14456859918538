import { TableLoaderPropsType } from './table-loader.types';
import React, { FC } from 'react';
import styles from './table-loader.module.scss';
import { ReactComponent as Loader } from '@/assets/icons/table-loader.svg';

export const TableLoader: FC<TableLoaderPropsType> = ({ className }) => {
  const customClasses = [styles.loader, className ?? ''].join(' ');

  return (
    <div className={customClasses}>
      <Loader className={styles.spin} />
    </div>
  );
};
