import { Icon, PanelTabButton, PanelTableButton, Table, TableLoader } from '@/components';
import { FC, useMemo } from 'react';
import { CoordsRowType, CoordsTablePropsType } from './polygon-tool-panel.types';
import styles from './polygon-tool-panel.module.scss';
import { ColumnsType } from 'antd/es/table';
import { LatLngTuple, Polygon } from 'leaflet';
import { useAppSelector } from '@/state';
import area from '@turf/area';
import { convertArea } from '@turf/helpers';
import { ColumnType } from 'antd/lib/table';
import { CoordsInput } from './coords-input';
import { checkPolygon, convertToDecimalCord } from '@/utils';
import { PanelTabButtonTypes } from '@/constants/enums';

export const CoordsTable: FC<CoordsTablePropsType> = ({
  setData,
  data,
  isLoading,
  isError,
  isCreateMode,
  setIsCreateMode,
  setIsEditMode,
  isEditMode,
}) => {
  const preparedData = data[0][0].map((item, idx) => ({
    id: +idx + 1,
    lat: (item as LatLngTuple)[0],
    lng: (item as LatLngTuple)[1],
  }));

  const canDelete = preparedData.length > 4 || (isCreateMode && preparedData.length > 3);

  const handleDeletePoint = (id: number) => {
    if (canDelete) {
      const dataSlice = data[0][0].filter((item, idx) => +idx + 1 !== id);
      if (id === 1 && !isCreateMode) {
        dataSlice[dataSlice.length - 1] = dataSlice[0];
      }
      setData([[dataSlice]]);
    }
  };

  const handleClosePolygon = () => {
    if (checkPolygon(data, true)) {
      setIsEditMode(true);
      setIsCreateMode(false);
      setData([[[...data[0][0], data[0][0][0]]]]);
    }
  };

  const checkPoints = (points: CoordsRowType[]) => {
    return points.filter((point) => point.lat && point.lng).length === points.length ? true : false;
  };

  const polygonArea = useMemo(() => {
    if (preparedData.length >= 4 && checkPoints(preparedData)) {
      const polygon = new Polygon(data);
      const computedArea = area(polygon.toGeoJSON());
      const convertedArea = convertArea(computedArea, 'meters', 'kilometers');

      return convertedArea.toFixed(2);
    } else return 0;
  }, [preparedData]);

  const handleCoordsChange = (value: string, id: number, isLat = false) => {
    const coords = [...data[0][0]];
    const row = coords[id - 1] as LatLngTuple;
    const changedRow = isLat
      ? ([Number(value), row[1]] as LatLngTuple)
      : ([row[0], Number(value)] as LatLngTuple);
    coords.splice(id - 1, 1, changedRow);
    if (id === 1 && !isCreateMode) {
      coords.splice(coords.length - 1, 1, changedRow);
    }
    setData([[coords]]);
  };

  const coordsType = useAppSelector((state) => state.map.coordsType);

  const handleAddPoint = () => {
    if (!isCreateMode) {
      const coords = [...data[0][0]];
      const last = coords.slice(coords.length - 1);
      coords.splice(coords.length - 1, 1, [NaN, NaN]);
      coords.push(last[0]);
      setData([[coords]]);
    } else {
      const coords = [...data[0][0]];

      coords.push([NaN, NaN]);
      setData([[coords]]);
    }
    setTimeout(() => {
      const scrollArea = document.getElementsByClassName('ant-table-body')[0];
      scrollArea?.scrollBy({ top: scrollArea.scrollHeight, behavior: 'smooth' });
    }, 100);
  };

  const deleteColumn: ColumnType<CoordsRowType> = {
    title: <Icon size={12} icon="outline-trash" />,
    dataIndex: 'id',
    align: 'center',
    width: 40,
    render: (value: number) => (
      <PanelTableButton
        onClick={() => handleDeletePoint(value)}
        tooltipTitle="Удалить точку"
        tooltipPlacement="bottom"
        icon="outline-trash"
        showTooltip={true}
        size={12}
      />
    ),
  };

  const columns: ColumnsType<CoordsRowType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 40,
      render: (id: string) => 'T' + id,
    },
    {
      title: 'Широта',
      dataIndex: 'lat',
      key: 'lat',
      align: 'left',
      width: canDelete ? 130 : 200,
      render: (lat: number, row) => (
        <CoordsInput
          value={
            isNaN(lat)
              ? ''
              : coordsType === 'DEC'
              ? lat.toString()
              : convertToDecimalCord(lat.toString(), true)
          }
          isLat={true}
          coordsType={coordsType}
          onChange={(value: string) => handleCoordsChange(value, row.id, true)}
        />
      ),
    },
    {
      title: 'Долгота',
      dataIndex: 'lng',
      key: 'lng',
      align: 'left',
      width: canDelete ? 130 : 200,
      render: (lng: number, row) => (
        <CoordsInput
          value={
            isNaN(lng)
              ? ''
              : coordsType === 'DEC'
              ? lng.toString()
              : convertToDecimalCord(lng.toString())
          }
          coordsType={coordsType}
          onChange={(value: string) => handleCoordsChange(value, row.id)}
        />
      ),
    },
  ];

  const preparedColumns = canDelete ? [...columns, deleteColumn] : columns;

  return (
    <>
      {isLoading ? (
        <div style={{ height: 200, background: '#f4f5fa' }}>
          <TableLoader className={styles.loader} />
        </div>
      ) : (
        <>
          <p className={styles['square-text']}>
            Площадь полигона, км²:{' '}
            {`${polygonArea}${polygonArea === 0 ? ' (пока область не замкнута)' : ''}`}
          </p>
          <Table
            error={isError}
            className={styles.table}
            dataSource={
              !isCreateMode ? preparedData.slice(0, preparedData.length - 1) : preparedData
            }
            columns={preparedColumns}
            withSelection={false}
            withPagination={false}
            scroll={{ y: 140 }}
          />
          <>
            <div className={styles['action-buttons-wrapper']}>
              <PanelTabButton type={PanelTabButtonTypes.primary} onClick={() => handleAddPoint()}>
                <Icon icon={'outline-plus-24'} size={14} className="me-1" />
                <span className={styles['action-button-text']}>Добавить вершину</span>
              </PanelTabButton>
              {!isEditMode ? (
                <PanelTabButton
                  type={PanelTabButtonTypes.primary}
                  disabled={!checkPolygon(data, true)}
                  onClick={handleClosePolygon}
                >
                  <Icon icon={'outline-polygon'} size={14} className="me-1" />
                  <span className={styles['action-button-text']}>Замкнуть полигон</span>
                </PanelTabButton>
              ) : null}
            </div>
          </>
        </>
      )}
    </>
  );
};
