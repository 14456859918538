import React, { FC } from 'react';
import styles from './layers-tab.module.scss';
import {
  BATHYMETRY_LAYER,
  GALSES_LAYER,
  MO_LAYER,
  OOPT_LAYER,
  REGIONS_LAYER,
  SECTORS_LAYER,
  SHIPS_BASES_LAYER,
  UTM_BORDERS_LAYER,
  WATER_AREAS_LAYER,
} from '@/constants';
import { Card, GroupCard } from '../layers-panel-cards';
import layers from '@/assets/json-layers/raster-map-layers.json';
import { useAppSelector } from '@/state';
import { Permissions } from '@/constants/enums';

export const ExpeditionTab: FC = (): JSX.Element => {
  const isOpenSectorTooltip = useAppSelector((state) => state.sectors.isOpenSectorTooltip);
  const isOpenWaterAreaTooltip = useAppSelector((state) => state.waterAreas.isOpenWaterAreaTooltip);
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  return (
    <div className={styles.wrapper}>
      {userPermissions?.includes(Permissions.mapDataViewing) ? <GroupCard /> : null}

      <Card
        icon="fill-sector-area"
        title="Сектора"
        value={SECTORS_LAYER}
        isOpenTooltip={true}
        isIconTooltip={isOpenSectorTooltip}
      />
      <Card
        icon="fill-aqua-area"
        title="Акватории"
        value={WATER_AREAS_LAYER}
        isOpenTooltip={true}
        isIconTooltip={isOpenWaterAreaTooltip}
      />
      <Card icon="fill-ship-base" title="Базы судов" value={SHIPS_BASES_LAYER} />
    </div>
  );
};

export const MapLayoutTab: FC = (): JSX.Element => {
  const isOpenRegionTooltip = useAppSelector((state) => state.regions.isOpenRegionTooltip);

  return (
    <div className={styles.wrapper}>
      <GroupCard />
      <Card
        icon="fill-district-area"
        title="Районы"
        value={REGIONS_LAYER}
        isOpenTooltip={true}
        isIconTooltip={isOpenRegionTooltip}
      />
      <Card icon="fill-bathymetry" title="Батиметрическая съёмка" value={BATHYMETRY_LAYER} />
      <Card icon="fill-gals" title="Галсы" value={GALSES_LAYER} />
    </div>
  );
};

export const NauticalChartsTab: FC = (): JSX.Element => {
  return (
    <div className={styles.wrapper + ' ' + styles.scrollable}>
      {layers &&
        layers.map(({ name, header }) => (
          <Card key={header} icon="outline-map" title={name} value={header} />
        ))}
    </div>
  );
};

export const SupportingTab: FC = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <Card
        icon="fill-accessory"
        title="Границы зон UTM 34, 35, 36, 37, 38"
        value={UTM_BORDERS_LAYER}
      />
      <Card icon="outline-zone-oopt" title="ООПТ" value={OOPT_LAYER} />
      <Card icon="fill-zone-mo" title="МО" value={MO_LAYER} />
    </div>
  );
};
