import React, { FC } from 'react';
import styles from './layout.module.scss';
import { Children } from '@/types/default-types';
import { Footer, Content, Sidebar } from './layout-components';

type LayoutSubComponents = {
  Sidebar: typeof Sidebar;
  Footer: typeof Footer;
  Content: typeof Content;
};

const Layout: FC<{ children: Children }> & LayoutSubComponents = ({ children }): JSX.Element => {
  return <div className={styles.wrapper}>{children}</div>;
};

Layout.Sidebar = Sidebar;
Layout.Content = Content;
Layout.Footer = Footer;
export { Layout };
