import React, { FC, useEffect, useState } from 'react';
import { Table, TableLoader } from '@/components';
import styles from './z-coords-panel-table.module.scss';
import { useAppSelector } from '@/state';
import L, { LatLngExpression } from 'leaflet';
import { useClickPosition, useMap } from '@/context';
import { useGetZCoordsMutation } from '@/state/api';
import { getPointMarker, openErrorNotify } from '@/utils';
import { columns } from '../z-coords-panel-utils';
import { IZCoords, IZCoordsTable } from '@/types';

export const ZCoordsPanelTable: FC<IZCoordsTable> = ({ coords, setCoords, setCheckBoxMarked }) => {
  const { map } = useMap();
  const [getZCoords, { data, isLoading, isError: error }] = useGetZCoordsMutation();
  const [count, setCount] = useState<number>(1);
  const { position } = useClickPosition();
  const isZCoordsEditor = useAppSelector((state) => state.toolsPanels.zCoordsPanel.isZCoordsEditor);

  const handleCheckBoxStatus = (selectedPrint: IZCoords[]) => {
    const selectedCoords: IZCoords[] = [];
    selectedPrint.map((defaultCoords) => {
      if (defaultCoords !== undefined) {
        selectedCoords.push(defaultCoords);
      }
    });
    setCheckBoxMarked(() => selectedCoords);
  };

  useEffect(() => {
    if (error) {
      openErrorNotify('Произошла ошибка', 'Не удалось загрузить данные');
    }
  }, [error]);

  useEffect(() => {
    if (position && isZCoordsEditor && !isNaN(position.lat)) {
      const coords = L.CRS.EPSG3857.project({
        lat: position.lat,
        lng: position.lng,
      });

      getZCoords(coords);
    }
  }, [position]);

  useEffect(() => {
    if (data) {
      const newZCoords: IZCoords = {
        id: count,
        number: count,
        x: parseFloat(position?.lat.toFixed(6) as string),
        y: parseFloat(position?.lng.toFixed(6) as string),
        z:
          data?.zcoord === 'Точка не найдена'
            ? 'Не определена'
            : Math.abs(parseFloat(data?.zcoord as string)),
        marker: undefined,
      };
      setCount((prevState) => prevState + 1);
      setCoords((prevState) => [...prevState, newZCoords]);
    }
  }, [data]);

  useEffect(() => {
    coords.map((defaultCoords) => {
      if (map && defaultCoords.z) {
        const latLngXY = [defaultCoords.x as number, defaultCoords.y as number];
        const marker = L.marker(latLngXY as LatLngExpression).addTo(map);
        marker.setIcon(getPointMarker(styles['default-marker'], defaultCoords.number));
        const text =
          defaultCoords.z === 'Не определена'
            ? 'Глубина не определена'
            : `Глубина - ${defaultCoords.z}м`;
        marker.bindTooltip(text, { direction: 'top' });
        defaultCoords.marker = marker;
      }
    });

    return () => {
      coords.map((defaultCoords) => {
        defaultCoords.marker?.remove();
      });
    };
  }, [coords]);

  if (isLoading) {
    return <TableLoader className={styles.loader} />;
  }

  return (
    <Table
      className={styles.table}
      withPagination={false}
      columns={columns}
      dataSource={coords}
      scroll={{ y: 188 }}
      loading={isLoading}
      loaderClassName={styles.loader}
      onSelectRow={(row, checked, selectedRows) => handleCheckBoxStatus(selectedRows)}
    />
  );
};
