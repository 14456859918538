import { api } from '../api';
import { Bathymetric } from '@/types';

export const metaDataApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMetaData: builder.mutation<{ metaData: Bathymetric }, { x: number; y: number }>({
      query(point) {
        return {
          url: 'polygon/checkpoint',
          method: 'GET',
          credentials: 'include',
          params: { x: point.x, y: point.y },
        };
      },
    }),
  }),
});

export const { useGetMetaDataMutation } = metaDataApi;
