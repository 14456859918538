import React, { FC, useState } from 'react';
import { Table } from 'antd';
import { Speed } from './speed';
import { Distance } from './distance';
import { Time } from './time';
import { IPoint } from '@/types';

export const RouteTableSummary: FC<{ coords: IPoint[] }> = ({ coords }) => {
  const [speed, setSpeed] = useState(8);
  const [distanceKm, setDistanceKm] = useState(0);

  return (
    <Table.Summary fixed>
      <Speed speed={speed} setSpeed={setSpeed} />
      <Distance coords={coords} distanceKm={distanceKm} setDistanceKm={setDistanceKm} />
      <Time speed={speed} distanceKm={distanceKm} coords={coords} />
    </Table.Summary>
  );
};
