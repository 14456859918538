import React from 'react';
import styles from './sector.module.scss';
import { ISector } from '@/types';

export const Sector = ({ item }: { item: ISector }) => {
  return (
    <>
      <div>
        {item.description && (
          <p className={styles.text}>
            <span className={styles.other}>Описание:</span>
            {item.description}
          </p>
        )}
      </div>
    </>
  );
};
