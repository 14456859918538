import React, { FC, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  MAP,
  AUTH,
  POLYGON_TOOL,
  EXPEDITION_STEP_PAGE,
  OBJECT,
  SECTOR,
  SELECT_COORDS_PAGE,
} from '@/constants';
import {
  AuthPage,
  MapPage,
  ObjectSelectPage,
  PolygonToolPage,
  SectorSelectPage,
  MarkerSelectionPage,
} from '@/pages';
import { useAppSelector } from '@/state';
import { useGetUserInfoMutation } from '@/state/api';
import styles from './router.module.scss';
import { TableLoader } from '@/components';
import { Permissions } from '@/constants/enums';

export const Router: FC = () => {
  const userId = useAppSelector((state) => state.auth.user?.id);
  const token = useAppSelector((state) => state.auth.accessToken);
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);
  const isAuth = userId && token;
  const [getUser, { isLoading }] = useGetUserInfoMutation({ fixedCacheKey: 'user-info' });

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      {isLoading ? (
        <TableLoader className={styles.loader} />
      ) : isAuth ? (
        <Routes>
          <Route path={'/*'} element={<Navigate to={MAP} />} />
          <Route path={`${MAP}/*`} element={<MapPage />} />
          {userPermissions?.includes(Permissions.expeditionsDataManagment) ||
          userPermissions?.includes(Permissions.mapDataManagment) ? (
            <>
              <Route path={`${POLYGON_TOOL}/:type/:id`} element={<PolygonToolPage />} />
              <Route path={`${POLYGON_TOOL}/:type/`} element={<PolygonToolPage />} />
            </>
          ) : null}
          {userPermissions?.includes(Permissions.expeditionsDataManagment) ? (
            <>
              <Route
                path={`${EXPEDITION_STEP_PAGE}/*`}
                element={
                  <Routes>
                    <Route path={`${OBJECT}/*`} element={<ObjectSelectPage />} />
                    <Route path={`${SECTOR}/*`} element={<SectorSelectPage />} />
                    <Route path={`${SECTOR}/create`} element={<PolygonToolPage />} />
                    <Route path="/*" element={<Navigate to={MAP} />} />
                  </Routes>
                }
              />
            </>
          ) : null}
          {userPermissions?.includes(Permissions.expeditionsDataManagment) ||
          userPermissions?.includes(Permissions.mapDataManagment) ? (
            <>
              <Route path={`${SELECT_COORDS_PAGE}/:type/:id/*`} element={<MarkerSelectionPage />} />
              <Route path={`${SELECT_COORDS_PAGE}/:type/*`} element={<MarkerSelectionPage />} />
              <Route path="/*" element={<Navigate to={MAP} />} />
            </>
          ) : null}
        </Routes>
      ) : (
        <Routes>
          <Route path={`${AUTH}/*`} element={<AuthPage />} />
          <Route path={'/*'} element={<Navigate to={AUTH} />} />
        </Routes>
      )}
    </>
  );
};
