import React, { FC } from 'react';
import { Icon, PanelTabButton } from '@/components';
import { ComponentSize, PanelTabButtonTypes } from '@/constants/enums';
import { TableModalWithArrowLayoutPropsType } from './table-modal-with-arrow.types';
import styles from './table-modal-with-arrow.module.scss';

export const TableModalWithArrowLayout: FC<TableModalWithArrowLayoutPropsType> = ({
  onClickBack,
  onClickForward,
  disableBackButton,
  disableForwardButton,
}) => {
  return (
    <div className={styles.layout}>
      <PanelTabButton
        className={styles.left}
        size={ComponentSize.medium}
        type={PanelTabButtonTypes.roundSecondary}
        onClick={() => onClickBack()}
        disabled={disableBackButton}
      >
        <Icon size={18} icon="outline-chevron-left" />
      </PanelTabButton>
      <PanelTabButton
        className={styles.right}
        size={ComponentSize.medium}
        type={PanelTabButtonTypes.roundSecondary}
        onClick={() => onClickForward()}
        disabled={disableForwardButton}
      >
        <Icon size={18} icon="outline-chevron-right" />
      </PanelTabButton>
    </div>
  );
};
