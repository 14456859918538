import { UtmBorder } from '../../elements';
import { FeatureCollection, MultiPolygon } from 'geojson';
import { UTM_BORDERS_LAYER } from '@/constants';
import { useAppSelector } from '@/state';
import React from 'react';
import utmBordersJson from '@/assets/json-layers/utm-borders.json';

export const UtmBorders = (): JSX.Element => {
  const selectedLayers = useAppSelector((state) => state.map.selectedLayers);
  const parsedLayer = JSON.parse(utmBordersJson.data) as FeatureCollection<MultiPolygon>;

  return selectedLayers.includes(UTM_BORDERS_LAYER) ? (
    <>
      {parsedLayer.features.map((feature, idx) => (
        <UtmBorder data={feature} key={idx} />
      ))}
    </>
  ) : (
    <></>
  );
};
