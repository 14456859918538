import React, { FC, useState } from 'react';
import styles from './coords-selector-field.module.scss';
import { ComponentSize, CoordsTypes } from '@/constants/enums';
import { Field, FieldProps, FormikValues, useFormikContext } from 'formik';
import { BlackTooltip, ErrorMessage, Icon, Input, CordSelectorFieldPropsType } from '@/components';
import { useMap } from '@/context';
import { useAppSelector } from '@/state';
import { setFormWithCoordsMarkerValue } from '@/state/slice';
import { useDispatch } from 'react-redux';
import { graduatedCoordsValidator, getObjectIconByType, convertToDecimalCord } from '@/utils';
import { words } from 'lodash';
import L, { LatLngExpression, Marker } from 'leaflet';
import { SELECT_OBJECT_COORDS_PAGE } from '@/constants';
import { useNavigate } from 'react-router';

export const CoordsSelectorField: FC<CordSelectorFieldPropsType> = ({
  iconType = 1,
  name,
  className,
  size = ComponentSize.medium,
  label = '',
  required = false,
  onIconClick,
  readOnly = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { map } = useMap();

  const [marker, setMarker] = useState<Marker>();

  const cordType: CoordsTypes = useAppSelector((state) => state.map.coordsType);

  const { values, setFieldValue, setFieldTouched } = useFormikContext<FormikValues>();

  const placeholder =
    cordType === CoordsTypes.decimal ? 'XX.XXXXX, YY.YYYYY' : 'HH°MM\'SS.s", HH°MM\'SS.s"';
  const tooltipText = `${values[name] ? 'Изменить' : 'Указать'} координаты объекта на карте`;
  const customStyles = [styles.wrapper, className].join(' ');

  const handleSelectFromPage = (formValues?: FormikValues) => {
    if (onIconClick) {
      onIconClick && onIconClick(formValues);
    } else {
      formValues && dispatch(setFormWithCoordsMarkerValue(formValues));
      navigate(`/${SELECT_OBJECT_COORDS_PAGE}${values?.id ? `/${String(values?.id)}` : ''}`);
    }
  };

  const handleCreateMarker = (lat: number, lng: number): void => {
    marker?.remove();
    const preparedCords = {
      lat: lat,
      lng: lng - 0.02,
    };

    map?.flyTo(preparedCords);

    const newMarker = L.marker([lat, lng] as LatLngExpression);
    newMarker.setIcon(getObjectIconByType(iconType, true, styles));
    map && newMarker?.addTo(map);
    setMarker(newMarker);
  };

  const handleSetValue = (lat = '', lng = ''): void => {
    const isGraduatedCord = graduatedCoordsValidator(lng) || graduatedCoordsValidator(lat, true);

    if (isGraduatedCord) {
      handleCreateMarker(
        parseFloat(convertToDecimalCord(lat, true)),
        parseFloat(convertToDecimalCord(lng)),
      );
    } else {
      handleCreateMarker(parseFloat(lat), parseFloat(lng));
    }
    setFieldValue(name, lat + ', ' + lng);
  };

  const handleBlur = (value: string) => {
    setFieldTouched(name, !!value);
    if (value) {
      const lat = words(value, /[^, ]+/g)[0];
      const lng = words(value, /[^, ]+/g)[1];
      handleSetValue(lat, lng);
    }
  };

  return (
    <div className={customStyles}>
      {label ? <label className="mb-1">{required ? `${label}*` : label}</label> : null}
      <Field name={name}>
        {({ field, form: { values: formValues } }: FieldProps) => (
          <div className={styles.field}>
            <Input
              {...field}
              id={name}
              size={size}
              onBlur={(event) => {
                handleBlur(field.value);
                field.onBlur(event);
              }}
              readOnly={readOnly}
              className={styles.input}
              placeholder={placeholder}
            />
            <BlackTooltip placement="left" title={tooltipText}>
              <div className={styles.icon} onClick={() => handleSelectFromPage(formValues)}>
                <Icon icon="fill-geo-alt" size={16} />
              </div>
            </BlackTooltip>
            <ErrorMessage name={name} className={styles.error} />
          </div>
        )}
      </Field>
    </div>
  );
};
