import React, { FC } from 'react';
import styles from '../../section.module.scss';
import { FileTypes, MediaTargetsTypes } from '@/constants/enums';
import { ExpeditionCardSection, ImageGallery, MediaSectionPropsType, Spinner } from '@/components';
import { DefaultMediaType } from '@/types';

export const ImagesAttachments: FC<MediaSectionPropsType> = ({
  isEditable,
  label,
  fileList,
  loading,
  uploadedFilesList,
  onRemove,
  onUpload,
  onClick,
  onDelete,
  onSelectItem,
}) => {
  const handleDelete = (item: DefaultMediaType) => {
    onSelectItem && onSelectItem(item);
    onDelete && onDelete();
  };

  return (
    <ExpeditionCardSection
      isEditable={isEditable}
      fileList={uploadedFilesList}
      buttonText="Добавить изображение"
      fileType={FileTypes.image}
      uploadAvailable={true}
      customRequest={(options) => onUpload && onUpload(options)}
      onDelete={(item) => onRemove && onRemove(item)}
      onClickAdd={(event) => onClick && onClick(event)}
      labelCustom={
        <h5 className={styles.label}>
          {label}
          {loading && <Spinner className={styles.spinner} />}
        </h5>
      }
    >
      <ImageGallery
        data={fileList}
        mediaType={MediaTargetsTypes.expedition}
        isEdit={isEditable}
        onDelete={(item) => handleDelete(item)}
      />
    </ExpeditionCardSection>
  );
};
