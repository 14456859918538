import React, { FC, useEffect, useState } from 'react';
import excel from '../../../assets/icons/excel-icon.svg';
import pdf from '../../../assets/icons/pdf-icon.svg';
import word from '../../../assets/icons/word-icon.svg';
import { BlackTooltip, Icon } from '@/components';
import styles from './media-files-panel.module.scss';
import { MediaFilesPanelFileRowPropsType } from './media-files-panel.types';

export const MediaFilesPanelFileRow: FC<MediaFilesPanelFileRowPropsType> = ({
  type,
  name,
  onClick,
  success,
  loading,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    setShowTooltip(true);
    onClick && onClick();
  };

  useEffect(() => {
    if (success) {
      setShowTooltip(false);
    }
  }, [success, loading]);

  switch (type) {
    case 'pdf':
      return (
        <BlackTooltip title="Загрузка..." placement="left" open={showTooltip}>
          <div className={styles.file} onClick={() => handleClick()}>
            <img src={pdf} alt="pdf-icon" />
            <span>{name}</span>
          </div>
        </BlackTooltip>
      );
    case 'xlsx':
    case 'xls':
      return (
        <BlackTooltip title="Загрузка..." placement="left" open={showTooltip}>
          <div className={styles.file} onClick={() => handleClick()}>
            <img src={excel} alt="excel-icon" />
            <span>{name}</span>
          </div>
        </BlackTooltip>
      );
    case 'word':
    case 'doc':
    case 'docx':
      return (
        <BlackTooltip title="Загрузка..." placement="left" open={showTooltip}>
          <div className={styles.file} onClick={() => handleClick()}>
            <img src={word} alt="word-icon" />
            <span>{name}</span>
          </div>
        </BlackTooltip>
      );
    default:
      return (
        <BlackTooltip title="Загрузка..." placement="left" open={showTooltip}>
          <div className={styles.file} onClick={() => handleClick()}>
            <div className={styles['file-no-icon']}>
              <Icon icon="fill-image-alt" size={16} />
            </div>
            <span>{name}</span>
          </div>
        </BlackTooltip>
      );
  }
};
