import React, { FC } from 'react';
import { ErrorIndicatorProps } from './error-indicator.types';
import { Button, Icon } from '@/components';
import { Colors, ComponentSize, FillTypes } from '@/constants/enums';
import styles from './error-indicator.module.scss';

export const ErrorIndicator: FC<ErrorIndicatorProps> = ({ reloadAfterError, text, className }) => {
  const customClasses = [styles.card, className ?? ''].join(' ');

  return (
    <div className={customClasses}>
      <Icon icon="outline-exclamation-circle" size={58} />
      <span className={styles.title}>{text || 'Произошла непредвиденная ошибка'}</span>
      <Button
        size={ComponentSize.small}
        color={Colors.black}
        fillType={FillTypes.outline}
        onClick={(event) => reloadAfterError(event)}
        className="w-100"
      >
        Перезагрузить страницу
      </Button>
    </div>
  );
};
