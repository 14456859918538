import { dividerAvailableValidator } from './';

export const TransformToMinutes = (value?: string | number | undefined): number => {
  if (!value) {
    return 0;
  }
  if (dividerAvailableValidator(value)) {
    const time = value.toString()?.split(/(:|;|,|\.)/gm);

    if (time[0] && time[2]) {
      return +time[0] * 60 + +time[2];
    }

    if (time[0]) {
      return +time[0];
    }
  }
  return +value;
};

export const TransformToHours = (value?: number): string => {
  if (!value) {
    return '00:00';
  }
  const hh = Math.floor(value / 60);
  const mm = value % 60;
  const preparedHours = hh < 10 ? `0${hh}` : hh.toString();
  const preparedMinutes = mm < 10 ? `:0${mm}` : `:${mm}`;

  return preparedHours + preparedMinutes;
};
