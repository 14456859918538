import { api } from '../api';
import {
  BathymetricResponse,
  DefaultResponse,
  EditBathymetric,
  GetBathymetricResponse,
  NewBathymetric,
} from '@/types';

export const bathymetricsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBathymetricData: builder.query<GetBathymetricResponse, void>({
      query() {
        return {
          url: '/project/list',
          method: 'GET',
          credentials: 'include',
        };
      },
      providesTags: ['Bathymetrics'],
    }),
    getBathymetric3DModel: builder.query<
      DefaultResponse & {
        scene: string;
        name: string;
        type: string;
      },
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/project/3d',
          method: 'GET',
          params: { id: body.id },
          credentials: 'include',
        };
      },
      keepUnusedDataFor: 20,
      providesTags: ['Bathymetrics'],
    }),
    createBathymetric: builder.mutation<BathymetricResponse, NewBathymetric[]>({
      query(body) {
        const preparedData = body.map((bath) => ({ ...bath, expedition_id: bath.expedition }));

        return {
          url: '/project/info',
          method: 'POST',
          body: { bathymetrics: preparedData },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Bathymetrics'],
    }),
    editBathymetric: builder.mutation<BathymetricResponse, EditBathymetric>({
      query(body) {
        return {
          url: '/project/info',
          method: 'PUT',
          body: { ...body, expedition_id: body.expedition },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Bathymetrics'],
    }),
    deleteBathymetric: builder.mutation<
      BathymetricResponse,
      {
        id: string;
      }
    >({
      query(body) {
        return {
          url: '/project/info',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Bathymetrics'],
    }),
  }),
});

export const {
  useGetBathymetricDataQuery,
  useCreateBathymetricMutation,
  useEditBathymetricMutation,
  useGetBathymetric3DModelQuery,
  useDeleteBathymetricMutation,
} = bathymetricsApi;
