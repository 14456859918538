import { DefaultResponse, NewUser, User, UserProfile } from '@/types';
import { api } from '../api';

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<{ users: User[] }, void>({
      query() {
        return {
          url: '/user/list',
          method: 'GET',
          credentials: 'include',
        };
      },
      providesTags: ['Users'],
    }),
    createUser: builder.mutation<DefaultResponse, NewUser>({
      query(body) {
        return {
          url: '/user/add',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Users'],
    }),
    editUser: builder.mutation<DefaultResponse, User>({
      query(body) {
        return {
          url: 'user',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Auth', 'Users'],
    }),
    editUserProfile: builder.mutation<DefaultResponse & { user: UserProfile }, User>({
      query(body) {
        return {
          url: 'user/edit_profile',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Auth', 'Users'],
    }),
    deleteUser: builder.mutation<
      DefaultResponse,
      {
        uid: number;
      }
    >({
      query(body) {
        return {
          url: '/user',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Auth', 'Users'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useDeleteUserMutation,
  useEditUserMutation,
  useCreateUserMutation,
  useEditUserProfileMutation,
} = usersApi;
