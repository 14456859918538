import React, { Component, ErrorInfo, MouseEvent } from 'react';
import { ErrorIndicator } from '@/components/error-indicator';
import { ErrorBoundaryProps, ErrorBoundaryState } from './error-boundary.types';

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    error: false,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error.message, errorInfo);
    this.setState({ error: true });
  }

  reloadAfterError = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({ error: false });
    location.reload();
  };

  render() {
    if (this.state.error) {
      return (
        <ErrorIndicator
          reloadAfterError={(e: MouseEvent<HTMLElement>) => this.reloadAfterError(e)}
        />
      );
    }
    return this.props.children;
  }
}
