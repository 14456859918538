import React, { FC, useEffect, useState } from 'react';
import { DivingSectionPropsType, ExpeditionCardSection } from '../index';
import { Dive } from '@/types';
import { FormikValues } from 'formik';
import styles from '../section.module.scss';
import { ColumnsType } from 'antd/lib/table';
import { findIndex } from 'lodash';
import { InputTypes } from '@/constants/enums';
import * as Yup from 'yup';
import {
  useCreateExpeditionDiveMutation,
  useDeleteExpeditionDiveMutation,
  useEditExpeditionDiveMutation,
} from '@/state/api';
import {
  handleDateSort,
  handleSortNumber,
  openErrorNotify,
  openInfoNotify,
  workTimeValidator,
} from '@/utils';
import {
  ConfirmModal,
  defaultDataPickerProps,
  defaultSearchProps,
  DropdownNoDataPlaceholder,
  DropdownFieldDataType,
  FieldType,
  Icon,
  PanelTableButton,
  Table,
  TableCell,
  TableModal,
} from '@/components';

export const DivingSection: FC<DivingSectionPropsType> = ({
  label,
  item,
  isEditable,
  membersSelectionData,
  objectsSelectionData,
  transformToMinutes,
  transformToHours,
  ...rest
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState<Dive>();

  const [
    createDiving,
    {
      isLoading: createLoading,
      isError: createError,
      isSuccess: createSuccess,
      reset: createReset,
    },
  ] = useCreateExpeditionDiveMutation();
  const [
    editDiving,
    { isLoading: editLoading, isError: editError, isSuccess: editSuccess, reset: editReset },
  ] = useEditExpeditionDiveMutation();
  const [
    deleteDiving,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
      reset: deleteReset,
    },
  ] = useDeleteExpeditionDiveMutation();

  const handlePrepareSelectedItem = (selectedItem?: Dive) => {
    if (selectedItem) {
      return Object.fromEntries(
        Object.entries(selectedItem).map(([key, value]) => {
          switch (key) {
            case 'duration':
              return [key, transformToHours && transformToHours(value as number)];
            default:
              return [key, value];
          }
        }),
      );
    }
  };

  const searchNameFromIdObject = (value: string, record: Dive) => {
    const OnlyOneObject = item?.map_objects.find(
      (object) => object.map_object_id === record.map_object_id,
    );

    return OnlyOneObject?.info.wm_name.includes(value) ?? false;
  };

  const searchNameFromIdMember = (value: string, record: Dive) => {
    const OnlyOneMember = item?.members.find((member) => member.member_id === record.member_id);

    return OnlyOneMember?.info.name.includes(value) ?? false;
  };

  const handleSetSelectedItem = (id: number) => {
    setSelectedItem(item?.dives?.find((item) => item.id === id));
  };

  const toggleAdd = () => {
    setShowAddModal((prevState) => !prevState);
  };

  const toggleEdit = (id: number) => {
    handleSetSelectedItem(id);
    setShowEditModal((prevState) => !prevState);
  };

  const toggleDelete = (id: number) => {
    handleSetSelectedItem(id);
    setShowDeleteModal((prevState) => !prevState);
  };

  const handleAdd = (value: FormikValues) => {
    createDiving({
      expedition_id: Number(item?.id),
      deep: Number(value.deep),
      duration: (transformToMinutes && transformToMinutes(value.duration)) || 0,
      date: value.date as string,
      member_id: Number(value.member_id),
      map_object_id: Number(value.map_object_id),
    });
  };

  const handleEdit = (value: FormikValues) => {
    editDiving({
      id: Number(selectedItem?.id),
      deep: Number(value.deep),
      duration: (transformToMinutes && transformToMinutes(value.duration)) || 0,
      date: value.date as string,
    });
  };

  const handleDelete = (id: number) => {
    deleteDiving({
      id,
      expedition_id: Number(item?.id),
    });
  };

  const editableColumns: ColumnsType<Dive> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      fixed: 'left',
      width: 60,
      render: (value: string, row) => <TableCell value={findIndex(item?.dives, row) + 1} />,
      sorter: (first, second) =>
        handleSortNumber(findIndex(item?.dives, first), findIndex(item?.dives, second)),
    },
    {
      title: 'ID',
      align: 'left',
      fixed: 'left',
      dataIndex: 'id',
      width: 60,
      sorter: (first, second, sortOrder) => handleSortNumber(first?.id, second?.id, sortOrder),
      render: (value: string) => <TableCell value={value} />,
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleEdit(id)}
          tooltipTitle="Редактировать погружение"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleDelete(id)}
          tooltipTitle="Удалить погружение"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'Объект',
      width: 300,
      dataIndex: 'map_object_id',
      key: 'map_object_id',
      align: 'left',
      render: (value: number) => {
        const preparedData = item?.map_objects?.find((object) => object?.info?.id === value)?.info
          ?.wm_name;

        return <TableCell value={preparedData} />;
      },
      sorter: (first, second, sortOrder) =>
        handleSortNumber(first?.map_object_id, second?.map_object_id, sortOrder),
      ...defaultSearchProps('map_object_id', (value, record) =>
        searchNameFromIdObject(value as string, record),
      ),
    },
    {
      title: 'Водолаз',
      width: 300,
      dataIndex: 'member_id',
      key: 'member_id',
      align: 'left',
      render: (value: number) => {
        const preparedData = item?.members?.find((member) => member?.info?.id === value)?.info
          ?.name;

        return <TableCell value={preparedData} />;
      },
      sorter: (first, second, sortOrder) =>
        handleSortNumber(first?.member_id, second?.member_id, sortOrder),
      ...defaultSearchProps('member_id', (value, record) =>
        searchNameFromIdMember(value as string, record),
      ),
    },
    {
      title: 'Глубина, м',
      width: 100,
      dataIndex: 'deep',
      key: 'deep',
      align: 'left',
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second, sortOrder) => handleSortNumber(first?.deep, second?.deep, sortOrder),
    },
    {
      title: 'Время, чч:мм',
      width: 125,
      dataIndex: 'duration',
      key: 'duration',
      align: 'left',
      sorter: (first, second, sortOrder) =>
        handleSortNumber(first?.duration, second?.duration, sortOrder),
      render: (value: number) => {
        const preparedData = transformToHours && transformToHours(value);

        return <TableCell value={preparedData} maxWidth={125} isDay={false} />;
      },
    },
    {
      title: 'Дата погружения',
      width: 175,
      dataIndex: 'date',
      key: 'date',
      align: 'left',
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second, sortOrder) => handleDateSort(first?.date, second?.date, sortOrder),
      ...defaultDataPickerProps('date'),
    },
  ];

  const initialValues = {
    expedition_id: item?.id,
    deep: '',
    duration: '',
    date: '',
    member_id: '',
    map_object_id: '',
  };

  const validationSchema = Yup.object({
    member_id: Yup.string().required('Пожалуйста, выберите участника погружения').nullable(true),
    map_object_id: Yup.string().required('Пожалуйста, выберите объект погружения').nullable(true),
    deep: Yup.number()
      .typeError('Можно вводить только числа')
      .required('Пожалуйста, введите глубину погружения')
      .nullable(true),
    duration: Yup.string()
      .test('', 'Пожалуйста, укажите время в формате чч:мм', (value) => workTimeValidator(value))
      .required('Пожалуйста, введите время погружения')
      .nullable(true),
    date: Yup.date()
      .typeError('Можно вводить только числа и знаки разделителя ( , . - )')
      .required('Пожалуйста, введите дату погружения')
      .max(
        new Date(),
        'Пожалуйста, введите корректную дату, погружение не может быть указана в будущем',
      )
      .nullable(true),
  });

  const creationFields: FieldType[] = [
    {
      label: 'Объект',
      name: 'map_object_id',
      type: 'dropdown',
      placeholder: 'Выберите Объект',
      required: true,
      data: objectsSelectionData as DropdownFieldDataType[],
      noDataPlaceholder: (
        <DropdownNoDataPlaceholder
          text={
            item?.objects_works && item?.objects_works?.length > 0
              ? 'Объекты не найдены'
              : 'Не выбраны объекты исследования'
          }
        />
      ),
    },
    {
      label: 'Водолаз',
      name: 'member_id',
      type: 'dropdown',
      placeholder: 'Выберите водолоза',
      required: true,
      data: membersSelectionData as DropdownFieldDataType[],
      noDataPlaceholder: (
        <DropdownNoDataPlaceholder
          text={
            item?.members && item?.members?.length > 0
              ? 'Водолазы не найдены'
              : 'Не выбраны водолазы/участники экспедиции'
          }
        />
      ),
    },
    {
      label: 'Глубина, м',
      name: 'deep',
      placeholder: 'Введите глубину погружения',
      required: true,
      type: InputTypes.number,
    },
    {
      label: 'Время, чч:мм',
      name: 'duration',
      placeholder: 'Введите время погружения',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Дата',
      name: 'date',
      placeholder: 'Введите день погружения',
      type: 'datePicker',
    },
  ];

  const editFields: FieldType[] = [
    {
      label: 'Глубина, м',
      name: 'deep',
      placeholder: 'Введите глубину погружения',
      required: true,
      type: InputTypes.number,
    },
    {
      label: 'Время, чч:мм',
      name: 'duration',
      placeholder: 'Введите время погружения',
      type: InputTypes.number,
      required: true,
    },
    {
      label: 'Дата',
      name: 'date',
      placeholder: 'Введите день погружения',
      type: 'datePicker',
    },
  ];

  const readOnlyColumn = editableColumns.filter((item) => item.key !== 'icon');

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify('Добавление погружение', 'Добавление погружение экспедиции успешно выполнено');
      createReset();
    }
    if (createError) {
      openErrorNotify('Произошла ошибка', 'При добавлении погружение экспедиции произошла ошибка');
      createReset();
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify('Изменение погружение', 'Изменение погружение экспедиции успешно выполнено');
      editReset();
    }
    if (editError) {
      openErrorNotify(
        'Произошла ошибка',
        'При редактировании погружение экспедиции произошла ошибка',
      );
      editReset();
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify('Удаление погружение', 'Удаление погружение экспедиции успешно выполнено');
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении погружение экспедиции произошла ошибка');
      deleteReset();
    }
  }, [deleteSuccess, deleteError]);

  return (
    <ExpeditionCardSection
      {...rest}
      label={label}
      buttonText="Добавить погружение"
      onClickAdd={toggleAdd}
      isEditable={isEditable}
    >
      <>
        {item?.dives && item?.dives?.length > 0 && (
          <Table
            withSelection={false}
            dataSource={item?.dives}
            columns={isEditable ? editableColumns : readOnlyColumn}
            className={styles.table}
            noDataClassName={styles['no-data']}
            scroll={{ x: 1225, y: 300 }}
            withPagination={false}
          />
        )}
        <TableModal
          onSubmit={(value) => handleAdd(value)}
          onClose={() => setShowAddModal(false)}
          initialValues={initialValues}
          validationSchema={validationSchema}
          loading={!!createLoading}
          success={!!createSuccess}
          buttonText="Добавить"
          open={showAddModal}
          fields={creationFields}
          width={563}
          title="Добавление погружения экспедиции"
        />
        <TableModal
          onSubmit={(value) => handleEdit(value)}
          onClose={() => setShowEditModal(false)}
          initialValues={handlePrepareSelectedItem(selectedItem) as Dive}
          validationSchema={validationSchema}
          loading={!!editLoading}
          success={!!editSuccess}
          buttonText="Изменить"
          open={showEditModal}
          fields={editFields}
          width={563}
          title="Изменение погружения экспедиции"
        />
        <ConfirmModal
          open={showDeleteModal}
          title="Подтвердите удаление погружения экспедиции"
          content={<p>Вы уверены, что хотите удалить выбранное погружение экспедиции?</p>}
          submitButtonText="Удалить"
          cancelButtonText="отменить"
          onSubmit={() => selectedItem && handleDelete(selectedItem.id)}
          onCancel={() => setShowDeleteModal(false)}
          loading={!!deleteLoading}
          success={!!deleteSuccess}
          type="danger"
        />
      </>
    </ExpeditionCardSection>
  );
};
