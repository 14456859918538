import { useEffect, useState } from 'react';
import { useClickPosition, useMap } from '@/context';
import { LatLng } from 'leaflet';
import styles from './meta-gals-panel.module.scss';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/state';
import { openErrorNotify } from '@/utils';
import { useGetCoordsGalsMutation } from '@/state/api';
import { GalsMetaData, StateGalsMetaData } from '@/types';
import { setCollectMetaGals, setIsMetaGalsOpen, setIsMetaGalsOpenTooltip } from '@/state/slice';

export const MetaGalsPanel = () => {
  const dispatch = useDispatch();
  const position = useClickPosition();
  const { map } = useMap();
  const checkMap = map !== null;
  const [gals, { data, isLoading, isError }] = useGetCoordsGalsMutation();
  const prepareData = data?.success ? data.galsMetaData : [];
  const [oneLatLng, setOneLatLng] = useState<{ x?: number; y?: number }>({});
  const collectMetaGals = useAppSelector(
    (state) => state.toolsPanels.metaGalsPanel.collectMetaGals,
  );
  const isMetaGalsOpenTooltip = useAppSelector(
    (state) => state.toolsPanels.metaGalsPanel.isMetaGalsOpenTooltip,
  );
  const isMetaGalsOpen = useAppSelector((state) => state.toolsPanels.metaGalsPanel.isMetaGalsOpen);

  useEffect(() => {
    if (checkMap) {
      dispatch(setIsMetaGalsOpen(true));
      dispatch(setCollectMetaGals([]));
      map?.getContainer().classList.add(styles.cursor);
      position.setPosition(null);

      return () => {
        dispatch(setIsMetaGalsOpen(false));
        dispatch(setCollectMetaGals([]));
        map?.getContainer().classList.remove(styles.cursor);
        position.setPosition(null);
      };
    }
  }, [checkMap]);

  useEffect(() => {
    const positionLatLng = position.position;

    if (positionLatLng?.lat && positionLatLng?.lng && isMetaGalsOpenTooltip && isMetaGalsOpen) {
      setOneLatLng({ x: positionLatLng?.lat, y: positionLatLng?.lng });
      gals({ x: positionLatLng?.lat, y: positionLatLng?.lng });
      position.setPosition(null);
    }

    dispatch(setIsMetaGalsOpenTooltip(true));
  }, [position]);

  useEffect(() => {
    isError && openErrorNotify('Ошибка метаданных галса', 'Не удалось загрузить метаданные галса');
  }, [isError]);

  useEffect(() => {
    if (!isLoading && oneLatLng.x && oneLatLng.y) {
      const newGals: StateGalsMetaData = {
        collectLatLng: { lat: oneLatLng?.x, lng: oneLatLng?.y } as LatLng,
        project: prepareData as GalsMetaData,
      };
      dispatch(setCollectMetaGals([...collectMetaGals, newGals]));
    }
  }, [isLoading, isError]);

  useEffect(() => {
    return () => {
      position.setPosition(null);
    };
  }, []);

  return null;
};
