import { Form, Formik } from 'formik';
import styles from './coordinate-converter-panel.module.scss';
import { CopyButtonWrapper } from './buttons/copy-button-wrapper';
import { DecimalCordField } from './fields/decimal-cord-field';
import { GraduatedCordFieldGroup } from './fields/graduated-cord-field-group';
import { ConvertButton } from './buttons/convert-button';
import React, { FC } from 'react';
import { CoordinateConverterFormContentPropsType } from './coordinate-converter.types';

export const CoordinateConverterForm: FC<CoordinateConverterFormContentPropsType> = ({
  onConvertValue,
  initialValues,
  valueAvailable,
  onChangeForm,
  formRef,
}): JSX.Element => {
  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={false}
      initialValues={initialValues}
      onSubmit={onConvertValue}
    >
      {({ handleSubmit, values, handleChange }) => (
        <Form
          className={styles.wrapper}
          onChange={(event) => {
            handleChange(event);
            onChangeForm(values);
          }}
          onSubmit={(event) => {
            handleSubmit(event);
            event.preventDefault();
          }}
        >
          <div className={styles.card}>
            <h5>Десятичные координаты*</h5>
            <CopyButtonWrapper
              valueAvailable={valueAvailable}
              values={values?.decimalCord}
              className={styles['copy-button']}
            />
            <div className={styles.decimal}>
              <DecimalCordField
                name="decimalCord.latitude"
                placeholder="xx.xxxxxxx"
                label="Широта (X)"
              />
              <DecimalCordField
                name="decimalCord.longitude"
                placeholder="yy.yyyyyyy"
                label="Долгота (Y)"
              />
            </div>
          </div>

          <div className={styles.card}>
            <h5>Градуированные координаты*</h5>
            <CopyButtonWrapper
              valueAvailable={valueAvailable}
              values={values?.graduatedCord}
              className={styles['copy-button']}
            />
            <div className={styles.graduated}>
              <GraduatedCordFieldGroup radioLabel={['N', 'S']} name="latitude" label="Широта" />
              <GraduatedCordFieldGroup radioLabel={['E', 'W']} name="longitude" label="Долгота" />
            </div>
            <span className={styles.description}>
              * Всемирная геодезическая система 84 (WGS 84)
            </span>
          </div>

          <ConvertButton valueAvailable={valueAvailable} />
        </Form>
      )}
    </Formik>
  );
};
