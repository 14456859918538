import { ToolsButton } from '@/components';
import { useAppSelector } from '@/state';
import { toggleCordStyle } from '@/state/slice';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { PolygonToolPageType } from './polygon-tool-page.types';

export const PolygonToolPageTools: FC<PolygonToolPageType> = ({
  isConvertPanelOpen,
  isRoutePanelOpen,
  toggleConvertPanel,
  toggleRoutePanel,
}) => {
  const {
    map: { coordsType: cordStyle },
  } = useAppSelector((state) => state);
  const dispatch = useDispatch();

  const handleOpenModal = (isActive: boolean, name?: string): void => {
    switch (name) {
      case 'cordStyle':
        dispatch(toggleCordStyle());
        break;
      case 'routePanel':
        toggleRoutePanel();
        break;
      case 'convertPanel':
        toggleConvertPanel();
        break;
      default:
        break;
    }
  };

  const toolsList = [
    {
      icon: 'outline-divider',
      title: 'Измерить расстояние',
      name: 'routePanel',
      onClick: handleOpenModal,
      isActive: isRoutePanelOpen,
    },
    {
      icon: 'outline-convertion',
      title: 'Конвертер координат',
      name: 'convertPanel',
      onClick: handleOpenModal,
      isActive: isConvertPanelOpen,
    },
    {
      icon: 'outline-globe',
      title: 'Стиль координат',
      name: 'cordStyle',
      onClick: handleOpenModal,
      isActive: cordStyle === 'DEG',
    },
  ];

  return <ToolsButton toolsList={toolsList} />;
};
