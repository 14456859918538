import React, { FC } from 'react';
import { GalsTableAddFilePropsType } from './gals-table-types';
import { ColumnsType } from 'antd/lib/table';
import { Gals, GalsFile } from '@/types';
import { handleDateSort, handleSortNumber, handleSortString } from '@/utils';
import { ComponentSize, PanelTabButtonTypes } from '@/constants/enums';
import styles from './gals-add-file-table.module.scss';
import {
  defaultDataPickerProps,
  defaultSearchProps,
  Icon,
  PanelTabButton,
  PanelTableButton,
  Table,
  TableCell,
  TableEmpty,
  TableError,
  TableFilter,
  TableLoader,
} from '@/components';

export const GalsAddFileTable: FC<GalsTableAddFilePropsType> = ({
  galsData,
  galsLoading,
  galsError,
  galsClassName,
  fileData,
  fileLoading,
  filesError,
  fileClassName,
  onSelectRow,
  className,
  onAddFiles,
  selectedRows,
  info,
}): JSX.Element => {
  const galsColumns: ColumnsType<Gals> = [
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: 110,
      className: 'text-center',
      sorter: (first, second, sortOrder) =>
        handleSortNumber(first?.status, second?.status, sortOrder),
      filterDropdown: ({ filters, clearFilters, confirm, setSelectedKeys, selectedKeys }) => (
        <TableFilter
          filters={filters}
          onFilter={confirm}
          onClear={clearFilters}
          selectedKeys={selectedKeys}
          onChange={setSelectedKeys}
        />
      ),
      onFilter: (value, record) => record.status === value,
      filters: [
        {
          text: 'Обработан',
          value: 1,
        },
        {
          text: 'В обработке',
          value: 2,
        },
      ],
      render: (value: number) => {
        switch (value) {
          case 1:
            return (
              <PanelTableButton
                showTooltip={true}
                tooltipTitle="Обработан"
                icon="outline-status-confirmed"
                size={12}
                tooltipPlacement="bottom"
              />
            );
          case 2:
            return (
              <PanelTableButton
                showTooltip={true}
                tooltipTitle="В процессе обработки"
                icon="outline-status-planned"
                size={12}
                tooltipPlacement="bottom"
              />
            );
          case 3:
            return (
              <PanelTableButton
                showTooltip={true}
                tooltipTitle=""
                icon="outline-status-error"
                size={12}
                tooltipPlacement="bottom"
              />
            );
        }
      },
    },
    {
      title: 'Наименование лога галса',
      dataIndex: 'file_path',
      key: 'file_path',
      align: 'left',
      sorter: (first, second, sortOrder) =>
        handleSortString(first?.file_path, second?.file_path, sortOrder),
      render: (value: string) => <TableCell value={value} maxWidth={411} />,
      ...defaultSearchProps('file_path'),
    },
  ];

  const fileColumns: ColumnsType<GalsFile> = [
    {
      title: 'Наименование лога галса',
      dataIndex: 'path',
      align: 'left',
      key: 'path',
      width: 500,
      sorter: (first, second, sortOrder) => handleSortString(first?.path, second?.path, sortOrder),
      render: (value: string) => <TableCell value={value} maxWidth={608} />,
      ...defaultSearchProps('path'),
    },
    {
      title: 'Дата изменения файла',
      dataIndex: 'lastEdit',
      key: 'lastEdit',
      align: 'left',
      render: (value: string) => <TableCell value={value} maxWidth={234} />,
      sorter: (first, second, sortOrder) =>
        handleDateSort(first.lastEdit ?? null, second.lastEdit ?? null, sortOrder),
      ...defaultDataPickerProps<GalsFile>('lastEdit'),
    },
    {
      title: 'Размер, Мб',
      dataIndex: 'size',
      key: 'size',
      align: 'left',
      sorter: (first, second, sortOrder) => handleSortString(first?.size, second?.size, sortOrder),
      render: (value: string) => {
        const preparedValue = value ? parseFloat(value).toFixed(1) : '';

        return <TableCell value={preparedValue} maxWidth={150} />;
      },
    },
  ];

  return (
    <div className={className}>
      {fileLoading || galsLoading ? (
        <TableLoader className={styles.loader} />
      ) : galsError || filesError ? (
        <TableError text={'Произошла ошибка'} />
      ) : (
        <>
          <Table
            className={styles['table-gals-from']}
            dataSource={galsData}
            columns={galsColumns}
            scroll={{ x: 400 }}
            withSelection={false}
            paginationPosition={['topLeft']}
            emptyPlaceholder={<TableEmpty className={galsClassName} />}
          />
          <PanelTabButton
            className={styles['button-flex']}
            disabled={selectedRows.length === 0}
            type={PanelTabButtonTypes.roundSecondary}
            size={ComponentSize.medium}
            onClick={() => onAddFiles()}
          >
            <Icon size={16} icon="outline-arrow-left" />
          </PanelTabButton>
          <div style={{ position: 'relative' }}>
            {info}
            <Table
              className={styles['table-gals-to']}
              dataSource={fileData}
              columns={fileColumns}
              paginationPosition={['topRight']}
              scroll={{ x: 1028 }}
              onSelectRow={(row, checked, selectedRows) => onSelectRow(selectedRows)}
              emptyPlaceholder={<TableEmpty className={fileClassName} />}
            />
          </div>
        </>
      )}
    </div>
  );
};
