import React, { FC } from 'react';
import styles from '../../../default-tab.module.scss';
import {
  BlackTooltip,
  ConfirmModal,
  FieldType,
  Icon,
  PanelTabButton,
  TableModal,
  UsersTable,
} from '@/components';
import { ComponentSize, InputTypes, PanelTabButtonTypes } from '@/constants/enums';
import { NewUser, User } from '@/types';
import { UserTabPropsType } from './user-tab.types';
import * as Yup from 'yup';
import { passwordStrengthValidation } from '@/utils';
import { onlyRussianSymbolsValidator } from '@/utils/validators';
import { useGetRolesListQuery } from '@/state/api';

export const UsersTabForm: FC<UserTabPropsType> = ({
  showAddModal,
  createLoading,
  createSuccess,
  onToggleAddUser,
  setShowAddModal,
  onCreate,

  showEditModal,
  updateSuccess,
  updateLoading,
  onToggleEditUser,
  setShowEditModal,
  onUpdate,

  showDeleteModal,
  deleteSuccess,
  deleteLoading,
  onToggleDeleteUser,
  setShowDeleteModal,
  onDelete,

  preparedData,
  isLoading = false,
  isError = false,

  selectData,
  selectDataLoading,
  selectDataError,

  selectedItem,
  userId,
}) => {
  const initialValues = {
    email: '',
    password: '',
    name: '',
    middlename: '',
    family: '',
    position: '',
    roles: [],
  };

  const { isLoading: loadingRoles, data: dataRoles } = useGetRolesListQuery();
  const preparedRolesData = dataRoles?.success && dataRoles.data;

  const validationSchema = Yup.object({
    id: Yup.number(),
    email: Yup.string().required('Пожалуйста, введите почту').email('Почта указана неверно'),
    password: Yup.string().when('id', {
      is: (value: string | number) => !value,
      then: (schema) =>
        schema
          .required('Пожалуйста, введите пароль')
          .min(8, 'Минимальная длина пароля 8 символов')
          .max(30, 'Максимальная длина пароля 30 символов')
          .test(
            '',
            'Нельзя использовать русские буквы',
            (password) => !onlyRussianSymbolsValidator(password),
          )
          .test('verify', 'Пароль недостаточно сложный.', (password) =>
            passwordStrengthValidation(password),
          ),
    }),
    name: Yup.string().required('Пожалуйста, введите имя'),
    family: Yup.string().required('Пожалуйста, введите фамилию'),
    middlename: Yup.string().required('Пожалуйста, введите отчество'),
    position: Yup.string().required('Пожалуйста, введите должность'),
    roles: Yup.number().required('Пожалуйста, выберите доступные роли'),
  });

  const creatableFields: FieldType[] = [
    {
      label: 'E-mail',
      name: 'email',
      placeholder: 'Введите рабочий E-mail',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Пароль',
      name: 'password',
      placeholder: 'Введите пароль',
      type: InputTypes.password,
      required: true,
    },
    {
      label: 'Фамилия',
      name: 'family',
      placeholder: 'Введите фамилию',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Имя',
      name: 'name',
      placeholder: 'Введите имя',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Отчество',
      name: 'middlename',
      placeholder: 'Введите отчество',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Должность',
      name: 'position',
      placeholder: 'Введите должность',
      type: InputTypes.text,
    },
    {
      label: 'Роли',
      name: 'roles',
      type: 'dropdown',
      placeholder: 'Выберите роль',
      required: true,
      data: selectData,
      loading: selectDataLoading,
      error: selectDataError,
    },
  ];

  const editableFields: FieldType[] = creatableFields.filter((item) => item.name !== 'password');

  return (
    <div className={styles.wrapper}>
      {!isError && !isLoading && (
        <div className={styles.header}>
          <BlackTooltip title={'Таблица пользователей'} placement={'bottomLeft'}>
            <PanelTabButton
              className={styles['button-panel-help']}
              onClick={() => {}}
              type={PanelTabButtonTypes.icon}
              size={ComponentSize.small}
            >
              <Icon icon="outline-question-circle" size={14} />
            </PanelTabButton>
          </BlackTooltip>
          <PanelTabButton
            disabled={isError || isLoading}
            type={PanelTabButtonTypes.roundSecondary}
            onClick={() => onToggleAddUser()}
            size={ComponentSize.small}
          >
            Добавить
          </PanelTabButton>
        </div>
      )}

      <TableModal
        onSubmit={(value) => onCreate(value as NewUser)}
        buttonText="Добавить"
        onClose={() => setShowAddModal(false)}
        title="Добавление нового пользователя"
        validationSchema={validationSchema}
        initialValues={initialValues}
        open={showAddModal}
        loading={createLoading}
        success={createSuccess}
        fields={creatableFields}
        width={563}
      />
      <TableModal
        onSubmit={(value) => onUpdate(value as User)}
        onClose={() => setShowEditModal(false)}
        validationSchema={validationSchema}
        initialValues={{
          ...selectedItem,
          roles: selectedItem?.roles.map((role) => role.id) ?? [],
        }}
        loading={updateLoading}
        success={updateSuccess}
        buttonText="Изменить"
        open={showEditModal}
        fields={editableFields}
        width={563}
        title={`Изменение данных пользователя ${
          selectedItem?.name && selectedItem?.family
            ? selectedItem?.name + ' ' + selectedItem?.family
            : ''
        }`}
      />
      <ConfirmModal
        open={showDeleteModal}
        title="Подтвердите удаление пользователя"
        content={
          <p>
            Вы уверены, что хотите удалить&nbsp;
            {selectedItem?.name && selectedItem?.family ? (
              <strong>
                {selectedItem?.family}&nbsp;{selectedItem?.name}
              </strong>
            ) : (
              'выбранного пользователя'
            )}
            ?
          </p>
        }
        submitButtonText="Удалить"
        cancelButtonText="отменить"
        onSubmit={() => onDelete(selectedItem?.id || 0)}
        onCancel={() => setShowDeleteModal(false)}
        loading={deleteLoading}
        success={deleteSuccess}
        type="danger"
      />

      <UsersTable
        data={preparedData}
        dataRoles={preparedRolesData}
        loading={isLoading || loadingRoles}
        error={isError}
        idToDisable={userId}
        onClickDeleteBtn={onToggleDeleteUser}
        onClickEditBtn={onToggleEditUser}
      />
    </div>
  );
};
