import styles from '../coordinate-converter-panel.module.scss';
import { GraduatedCordFieldRadio } from './graduated-cord-field-radio';
import { GraduatedCordFieldInput } from './graduated-cord-field-input';
import React, { FC, useRef } from 'react';
import { CordFieldGroupPropsType } from '../coordinate-converter.types';
import { InputRef } from 'antd';

export const GraduatedCordFieldGroup: FC<CordFieldGroupPropsType> = ({
  name,
  label,
  radioLabel,
}): JSX.Element => {
  const DD = useRef<InputRef | null>(null);
  const MM = useRef<InputRef | null>(null);
  const SS = useRef<InputRef | null>(null);

  return (
    <div className={styles.field}>
      <span className={styles.label}>{label}</span>
      <div className={styles['input-group']}>
        <GraduatedCordFieldInput
          name={`graduatedCord.${name}.DD`}
          spanTitle="°"
          currentRef={DD}
          nextRef={MM}
        />
        <GraduatedCordFieldInput
          name={`graduatedCord.${name}.MM`}
          spanTitle="'"
          prevRef={DD}
          currentRef={MM}
          nextRef={SS}
        />
        <GraduatedCordFieldInput
          name={`graduatedCord.${name}.SS`}
          spanTitle='"'
          prevRef={MM}
          currentRef={SS}
        />
      </div>
      <GraduatedCordFieldRadio
        name={`graduatedCord.${name}.directionIsNegative`}
        label={radioLabel}
      />
    </div>
  );
};
