import React, { FC } from 'react';
import { Spinner } from '@/components';
import styles from './styles.module.scss';

export const SearchPanelLoadingPlaceholder: FC = (): JSX.Element => {
  return (
    <div className={styles.loading}>
      <Spinner />
      <span>Идет загрузка, пожалуйста подождите.</span>
    </div>
  );
};
