import React, { FC, useEffect, useRef } from 'react';
import { useAppSelector } from '@/state';
import { FeatureGroup } from 'react-leaflet';
import { FeatureGroup as FG, LatLngExpression } from 'leaflet';
import { polygon, Position } from '@turf/helpers';
import mask from '@turf/mask';
import { FogPolygon } from '@/components/map/elements';

export const FogLayer: FC = (): JSX.Element => {
  const layerRef = useRef<FG>(null);
  const userInfo = useAppSelector((state) => state.auth.user);
  const worldBoundsPolygon = [
    [
      [-180, 180],
      [180, 180],
      [180, -180],
      [-180, -180],
      [-180, 180],
    ],
  ];

  const accessAreaGeometry = userInfo?.role?.access_area?.restricted_area.coordinates;
  const fogPolygonMask = mask(
    polygon(accessAreaGeometry as Position[][]),
    polygon(worldBoundsPolygon as Position[][]),
  );

  useEffect(() => {
    layerRef.current?.bringToFront();
  }, [layerRef]);

  return (
    <FeatureGroup ref={layerRef}>
      <FogPolygon polygon={fogPolygonMask.geometry.coordinates as LatLngExpression[][]} />;
    </FeatureGroup>
  );
};
