import React, { FC, useEffect } from 'react';
import styles from './object-creation-panel.module.scss';
import {
  Button,
  CoordsSelectorField,
  DatePickerField,
  DropdownField,
  InputField,
  Modal,
} from '@/components';
import {
  LINK_TO_EXPEDITION_OBJECT_STEP_PAGE,
  LINK_TO_OBJECT_FORMULAR,
  MAP,
  SELECT_COORDS_PAGE,
  OBJECT_CREATE,
} from '@/constants';
import { useNavigate, matchPath } from 'react-router';
import { Formik, FormikValues } from 'formik';
import { ComponentSize, CoordsTypes, ModalPosition } from '@/constants/enums';

import { useAppSelector } from '@/state';
import * as Yup from 'yup';
import { decimalCoordsValidator, graduatedCoordsValidator } from '@/utils/validators';
import { useCreateObjectMutation, useGetObjectsQuery } from '@/state/api';
import { NewObject } from '@/types';
import { openErrorNotify, openInfoNotify, prepareCoordsFieldToString } from '@/utils';
import { useHistoryBackstack } from '@/context';
import { clearFormWithCoordsMarker } from '@/state/slice';
import { useDispatch } from 'react-redux';

export const ObjectCreationPanel: FC<{ expeditionId?: number; className?: string }> = ({
  expeditionId,
  className = '',
}) => {
  const { data: objectsData } = useGetObjectsQuery();

  const preparedData = objectsData?.object_types || [];
  const formData = useAppSelector((state) => state.toolsPanels.objectCreationPanel.persistedForm);
  const objectTypes = preparedData.map((item) => ({
    label: item.type,
    value: item.id.toString(),
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { backstack } = useHistoryBackstack();

  const [createObj, { data, isLoading, isSuccess, isError }] = useCreateObjectMutation();

  const routes = [MAP + OBJECT_CREATE, SELECT_COORDS_PAGE + '/*'];

  const handleClose = () => {
    dispatch(clearFormWithCoordsMarker());
    if (expeditionId) {
      return navigate(LINK_TO_EXPEDITION_OBJECT_STEP_PAGE(expeditionId));
    } else if (data?.uid) {
      return navigate(LINK_TO_OBJECT_FORMULAR(data.uid));
    }
    const filteredBackstack = backstack.filter(
      (item) => routes.filter((route) => matchPath(route, item)).length === 0,
    );
    navigate(filteredBackstack.pop() || MAP);
  };

  const handleSubmit = (values: FormikValues) => {
    dispatch(clearFormWithCoordsMarker());
    const preparedValue = {
      ...values,
      type: String(values.type) || '1',
      expedition_id: expeditionId,
      coordinates: prepareCoordsFieldToString(values, CoordsTypes.decimal, 'coordinates'),
    };
    createObj(preparedValue as NewObject);
  };

  const initialValues: FormikValues = {
    name: '',
    identifier: '',
    date: '',
    updated_at: '',
    coordinates: '',
    type: '1',
  };

  const errorText = (label: string) =>
    `${label} координата указанно не верно. Необходимый формат: XX.XXXXXX YY.YYYYYY или HH°MM'SS.ss"N|S, HH°MM'SS.ss"W|E`;

  const validationSchema = Yup.object({
    type: Yup.string(),
    date: Yup.string(),
    updated_at: Yup.string(),
    identifier: Yup.string().required('Пожалуйста, введите идентификатор объекта'),
    name: Yup.string()
      .required('Пожалуйста, введите наименование объекта')
      .max(200, 'Длина наименования не более 200 символов'),
    coordinates: Yup.string()
      .required('Пожалуйста, введите координаты объекта')
      .test('', errorText('Первая'), (value): boolean => {
        const lat = value?.split(', ')[0] || '';

        return graduatedCoordsValidator(lat, true) || decimalCoordsValidator(lat);
      })
      .test(
        '',
        'Пожалуйста, введите вторую координату объекта',
        (value): boolean => !!value?.split(', ')[1],
      )
      .test('', errorText('Вторая'), (value): boolean => {
        const lng = value?.split(', ')[1] || '';

        return graduatedCoordsValidator(lng) || decimalCoordsValidator(lng);
      }),
  });

  const rows = [
    {
      name: 'Наименование объекта',
      required: true,
      component: <InputField name="name" size={ComponentSize.small} className={styles.field} />,
    },
    {
      name: 'Идентификатор',
      required: true,
      component: (
        <InputField name="identifier" size={ComponentSize.small} className={styles.field} />
      ),
    },
    {
      name: 'Дата обнаружения',
      component: (
        <DatePickerField name="date" className={styles.field} size={ComponentSize.small} />
      ),
    },
    {
      name: 'Дата последней съемки',
      component: (
        <DatePickerField name="updated_at" className={styles.field} size={ComponentSize.small} />
      ),
    },
    {
      name: 'Координаты объекта',
      required: true,
      component: (
        <CoordsSelectorField
          name="coordinates"
          size={ComponentSize.small}
          className={styles.field}
        />
      ),
    },
    {
      name: 'Тип объекта',
      component: (
        <DropdownField
          name="type"
          placeholder="Тип объекта не выбран"
          data={objectTypes}
          className={styles.field}
          size={ComponentSize.small}
        />
      ),
    },
  ];

  useEffect(() => {
    if (isSuccess) {
      openInfoNotify('Изменения сохранены', 'Объект успешно добавлен');
      setTimeout(handleClose, 1000);
    }
    if (isError) {
      openErrorNotify('Произошла ошибка', 'При добавлении объекта произошла ошибка');
    }
  }, [isSuccess, isError]);

  return (
    <Modal
      width={800}
      onClose={handleClose}
      title={<h5 className={styles['title-object']}>Наименование объекта</h5>}
      wrapClassName={`${styles.modal} ${className}`}
      position={ModalPosition.left}
    >
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={formData ?? initialValues}
        onSubmit={handleSubmit}
      >
        {({ isValid, dirty, handleSubmit }) => (
          <div className={styles.wrapper}>
            <div className={styles.card}>
              {rows.map(({ name, required, component }) => (
                <div className={styles.row} key={'cord' + name}>
                  <div className={styles.cell}>{required ? name + '*' : name}</div>
                  {component}
                </div>
              ))}
            </div>
            <Button
              onClick={() => handleSubmit()}
              disable={formData ? !isValid : !isValid || !dirty}
              size={ComponentSize.medium}
              loading={isLoading}
            >
              Создать объект
            </Button>
          </div>
        )}
      </Formik>
    </Modal>
  );
};
