import { FC, useEffect, useState } from 'react';
import { Marker } from 'react-leaflet';
import L, { LatLng, LatLngTuple, LeafletEvent, LeafletMouseEvent } from 'leaflet';
import 'leaflet-editable';
import { useMap } from '@/context';
import styles from './polygon-tool-panel.module.scss';
import { checkEmptyPointsAtPolygon, getVertexMarker } from '@/utils';
import { PolygonDrawableLayerPropsType } from './polygon-tool-panel.types';
import { isArray } from 'lodash';

let drawingPolygon: L.Polygon | undefined;
let drawerTooltip: L.Tooltip | undefined;

export const PolygonDrawableLayer: FC<PolygonDrawableLayerPropsType> = ({
  polygon,
  setIsEditMode,
  setPolygon,
  pathOptions,
  setIsCreateMode,
  isPurged,
}) => {
  const { map } = useMap();
  const [tools, setTools] = useState<L.Editable>();
  const dashArray = [10, 20];
  const [, setLocalePolygon] = useState<typeof polygon | null>(null);
  const pathForEdit = { ...pathOptions, dashArray: dashArray };

  const handleMouseMove = (e: LeafletMouseEvent) => {
    map && drawerTooltip?.removeFrom(map);
    map && drawerTooltip?.setLatLng(e.latlng).addTo(map);
  };

  useEffect(() => {
    if (
      polygon[0][0].filter((item) => {
        const typedItem = item as LatLngTuple;

        return isNaN(typedItem[0]) || isNaN(typedItem[1]);
      }).length === 0 &&
      polygon[0][0].length
    ) {
      drawingPolygon?.setLatLngs(polygon);
      const drawedPoints = drawingPolygon?.getLatLngs() as LatLng[][][];
      if (drawingPolygon && map && !map?.hasLayer(drawingPolygon)) {
        drawingPolygon.addTo(map);
        drawerTooltip?.setContent('Кликните на карте для включения режима рисования');
      }
      if (drawedPoints) {
        //@ts-ignore
        drawedPoints.length && drawingPolygon?.editor.setDrawnLatLngs(drawedPoints[0][0]);
        //@ts-ignore
        drawingPolygon?.editor.reset();
        //@ts-ignore
        drawingPolygon?.editor.tools.anchorForwardLineGuide(
          drawedPoints[0][0][drawedPoints[0][0].length - 1],
        );
        //@ts-ignore
        drawingPolygon?.editor.tools.anchorBackwardLineGuide(drawedPoints[0][0][0]);
        drawingPolygon?.redraw();
        setLocalePolygon(polygon);
      }
    }
  }, [polygon]);

  const handleDrawing = (e: LeafletEvent) => {
    const coords = e.layer?.editor?._drawnLatLngs as LatLng[];
    const items = coords.map((item) => [item.lat, item.lng] as LatLngTuple);
    drawingPolygon?.setLatLngs([[items]]);
    const tempPolygon = [...polygon];
    if (polygon[0][0].length === 3 && !checkEmptyPointsAtPolygon(polygon)) {
      items.forEach((item, idx) => {
        tempPolygon[0][0][idx] = item;
      });
      setPolygon(tempPolygon);
    } else {
      setPolygon([[items]]);
    }

    if (coords.length > 0 && coords.length < 2) {
      drawerTooltip?.setContent(
        'Кликните, чтобы создать новую точку <br>или отредактируйте созданные',
      );
    } else if (coords.length >= 2) {
      drawerTooltip?.setContent(
        'Для завершения рисования полигона <br>кликните на первую или последнюю точку<br>(обозначены пунктирной линией)',
      );
    }
  };

  const handleEnd = () => {
    //@ts-ignore
    drawingPolygon?.editor.disable();
    map && drawingPolygon?.removeFrom(map);
    map && drawingPolygon?.setLatLngs([[[]]]);
    tools?.stopDrawing();
    map?.getContainer().classList.remove(styles['coords-cursor']);
    map?.off('editable:drawing:commit');
    map?.off('mousemove');
    map?.off('editable:editing');
    map && drawerTooltip?.removeFrom(map);
  };

  const handleDrawEnd = (e: L.LeafletEvent) => {
    const coords = e.layer?.editor?._drawnLatLngs as LatLng[];

    const preparedCoords = [
      [
        [
          ...coords.map((coord) => [Number(coord.lat.toFixed(6)), Number(coord.lng.toFixed(6))]),
          [Number(coords[0].lat.toFixed(6)), Number(coords[0].lng.toFixed(6))],
        ],
      ],
    ] as L.LatLngExpression[][][];
    setIsCreateMode(false);
    handleEnd();
    setIsEditMode(true);

    setPolygon(preparedCoords);
  };

  useEffect(() => {
    if (!isPurged) {
      map?.getContainer().classList.add(styles['coords-cursor']);
      drawingPolygon = tools?.startPolygon(undefined, { ...pathForEdit });
      map?.on('editable:drawing:commit', (e) => handleDrawEnd(e));
      drawerTooltip = (
        drawerTooltip ||
        new L.Tooltip({
          direction: 'right',
          className: styles.tooltip,
          offset: [20, 0],
        })
      ).setContent('Кликните, чтобы указать первую точку');

      map?.on('editable:editing', handleDrawing);
      map?.on('mousemove', handleMouseMove);
    } else {
      drawingPolygon?.setLatLngs([[[]]]);
      setPolygon([
        [
          [
            [NaN, NaN],
            [NaN, NaN],
            [NaN, NaN],
          ],
        ],
      ]);
    }
    return () => {
      handleEnd();
    };
  }, [tools, isPurged]);

  useEffect(() => {
    map &&
      !tools &&
      setTools(
        new L.Editable(map, {
          lineGuideOptions: pathOptions,
        }),
      );
  }, [map]);

  return (
    <>
      {drawingPolygon && !isArray((drawingPolygon.getLatLngs() as LatLng[][][])[0][0])
        ? (drawingPolygon.getLatLngs() as LatLng[][])[0].map((item, idx) => {
            return (
              <Marker
                key={idx}
                icon={getVertexMarker(`T${idx + 1}`, styles['vertex-marker'])}
                position={item}
              ></Marker>
            );
          })
        : drawingPolygon && isArray((drawingPolygon.getLatLngs() as LatLng[][][])[0][0])
        ? (drawingPolygon.getLatLngs() as LatLng[][][])[0][0].map((item, idx) => {
            return (
              <Marker
                key={idx}
                icon={getVertexMarker(`T${idx + 1}`, styles['vertex-marker'])}
                position={item}
              ></Marker>
            );
          })
        : null}
    </>
  );
};
