import React, { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { MAP } from '@/constants';
import { Modal } from '@/components';
import styles from './layers-panel.module.scss';
import { ModalPosition, Permissions } from '@/constants/enums';
import { Tabs } from 'antd';
import { MapLayoutTab, ExpeditionTab, NauticalChartsTab, SupportingTab } from './layers-tab';
import { LayersPanelPropsType } from './layers-panel.types';
import { useAppSelector } from '@/state';

export const LayersPanel: FC<LayersPanelPropsType> = ({ onClose }) => {
  const navigate = useNavigate();
  const defaultOnCloseHandler = () => navigate(MAP);
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  const handleCloseModal = onClose || defaultOnCloseHandler;
  const tabs = [
    { label: 'Карта', key: 'map', children: <MapLayoutTab /> },
    { label: 'Экспедиция', key: 'expedition', children: <ExpeditionTab /> },
    { label: 'Морские карты', key: 'seaMaps', children: <NauticalChartsTab /> },
    { label: 'Вспомогательные', key: 'utils', children: <SupportingTab /> },
  ];

  const filterTabByPermissions = (tab: { label: string; key: string; children: ReactNode }) => {
    if (
      tab.key === 'expedition' &&
      !userPermissions?.includes(Permissions.expeditionsDataViewing)
    ) {
      return false;
    }
    if (!userPermissions?.includes(Permissions.mapDataViewing) && tab.key === 'map') {
      return false;
    }
    return true;
  };

  const preparedTabs = tabs.filter(filterTabByPermissions);

  return (
    <Modal
      width={524}
      wrapClassName={styles.modal}
      position={ModalPosition.right}
      onClose={() => handleCloseModal()}
      title="Слои"
      additionalTitle={<span className={styles.title}>Выберите категорию для просмотра.</span>}
    >
      <div className={styles.wrapper}>
        <Tabs type="line" centered items={preparedTabs} />
      </div>
    </Modal>
  );
};
