import { ZoomControlPropsTypes } from './zoom-control.types';
import styles from './zoom-control.module.scss';
import React, { FC } from 'react';
import { Icon } from '@/components';
import { useMap } from '@/context';
import { MAX_ZOOM, MIN_ZOOM } from '@/constants';

export const ZoomControl: FC<ZoomControlPropsTypes> = ({ rotate = false }) => {
  const customCLasses = [styles.wrapper, rotate ? styles.rotate : ''].join(' ');
  const { map } = useMap();
  const currentZoom = map?.getZoom() ?? 14;

  return (
    <div className={customCLasses}>
      <div
        className={`${styles.icon} ${currentZoom === MIN_ZOOM ? styles.disabled : ''}`}
        onClick={() => currentZoom !== MIN_ZOOM && map?.setZoom(currentZoom - 1)}
      >
        <Icon size={16} icon="outline-minus-circle" />
      </div>
      <div className={styles.separator} />
      <div
        className={`${styles.icon} ${currentZoom === MAX_ZOOM ? styles.disabled : ''}`}
        onClick={() => currentZoom !== MAX_ZOOM && map?.setZoom(currentZoom + 1)}
      >
        <Icon size={16} icon="outline-plus-circle" />
      </div>
    </div>
  );
};
