import React, { FC } from 'react';
import { menuData, ObjectCardItem } from '@/components';
import { ObjectCardPropsType } from '../object-card-panel.types';

export const ObjectFilesSection: FC<ObjectCardPropsType> = ({
  onSubmit,
  success,
  loading,
  isEditable,
  item,
}) => {
  return (
    <ObjectCardItem
      showSaveButton={false}
      title={menuData[5].label}
      type="media"
      isEditable={isEditable}
      id={menuData[5].link}
      onSubmit={() => onSubmit()}
      success={success}
      loading={loading}
      item={item}
    />
  );
};
