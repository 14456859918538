import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import styles from './coordinate-converter-panel.module.scss';
import { Modal, ModalHeaderButtons } from '@/components';
import { ModalPosition } from '@/constants/enums';
import { useAppSelector } from '@/state';
import { useDispatch } from 'react-redux';
import { convertToDecimal, convertToGraduated } from '@/utils';
import { CoordinateConverterForm } from './coordinate-converter-form';
import { debounce, isEqual } from 'lodash';
import { FormikProps } from 'formik';
import { ConvertCoordsFormValues, DecimalCord, GraduatedCord } from '@/types';
import { setCoordsConverterValue } from '@/state/slice';
import { useNavigate } from 'react-router';
import { MAP } from '@/constants';
import { CoordinateConverterPropsType } from './coordinate-converter.types';

const initialValues: ConvertCoordsFormValues = {
  cordType: '',
  decimalCord: {
    latitude: '',
    longitude: '',
  },
  graduatedCord: {
    latitude: {
      DD: '',
      MM: '',
      SS: '',
      directionIsNegative: false,
    },
    longitude: {
      DD: '',
      MM: '',
      SS: '',
      directionIsNegative: false,
    },
  },
};

const valueAvailable = (value: GraduatedCord | DecimalCord): boolean => {
  if (value) {
    if (typeof value === 'string') {
      return !!value;
    }
    if (typeof value === 'object') {
      return (
        Object.entries(value).filter(([key, objValue]): boolean => {
          if (key === 'directionIsNegative') {
            return false;
          }
          return !!objValue;
        }).length > 0
      );
    }
  }
  return false;
};

export const CoordinateConverterPanel: FC<CoordinateConverterPropsType> = ({ onClose }) => {
  const { currentValue } = useAppSelector(
    (state) => state.toolsPanels.converterPanel.coordsPanelState,
  );
  const formRef = useRef<FormikProps<ConvertCoordsFormValues>>(null);
  const [formValues, setFormValues] = useState(currentValue);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const defaultCloseModal = () => navigate(MAP);
  const handleCloseModal = onClose ?? defaultCloseModal;
  const handleSaveFormValue = (formValues: ConvertCoordsFormValues) =>
    dispatch(setCoordsConverterValue(formValues));

  const debounceSaveFormValue = useMemo(() => debounce(handleSaveFormValue, 300), []);

  useEffect(() => {
    if (!isEqual(formValues, initialValues)) {
      debounceSaveFormValue(formRef?.current?.values as ConvertCoordsFormValues);
    }
  }, [formValues, formRef]);

  const handleUpdateFormValue = (newValue: ConvertCoordsFormValues): void => {
    setFormValues(newValue);
    formRef?.current?.resetForm({ values: newValue });
    debounceSaveFormValue(newValue);
  };

  const handleConvert = (values: ConvertCoordsFormValues): void => {
    let latitude, longitude, newValues;
    const { cordType, decimalCord, graduatedCord } = values;

    if (cordType === 'graduatedCord') {
      if (valueAvailable(graduatedCord.latitude)) {
        latitude = convertToDecimal(graduatedCord.latitude);
      }
      if (valueAvailable(graduatedCord.longitude)) {
        longitude = convertToDecimal(graduatedCord.longitude);
      }
      newValues = {
        cordType: '',
        graduatedCord: values.graduatedCord,
        decimalCord: {
          latitude: latitude || values.decimalCord.latitude,
          longitude: longitude || values.decimalCord.longitude,
        },
      };
    }
    if (cordType === 'decimalCord') {
      if (valueAvailable(decimalCord.latitude)) {
        latitude = convertToGraduated(decimalCord.latitude);
      }
      if (valueAvailable(decimalCord.longitude)) {
        longitude = convertToGraduated(decimalCord.longitude);
      }
      newValues = {
        cordType: '',
        decimalCord: values.decimalCord,
        graduatedCord: {
          latitude: latitude || values.graduatedCord.latitude,
          longitude: longitude || values.graduatedCord.longitude,
        },
      };
    }
    handleUpdateFormValue(newValues as ConvertCoordsFormValues);
  };

  const buttonIsActive =
    valueAvailable(formValues?.decimalCord?.latitude) ||
    valueAvailable(formValues?.decimalCord?.longitude) ||
    valueAvailable(formValues?.graduatedCord?.latitude) ||
    valueAvailable(formValues?.graduatedCord?.longitude);

  return (
    <Modal
      width={524}
      // open={isOpen}
      position={ModalPosition.right}
      wrapClassName={styles['modal-wrapper']}
      onClose={() => handleCloseModal()}
      title={
        <div className={styles['title-wrapper']}>
          <h5>Конвертер координат</h5>
          <ModalHeaderButtons
            disable={!buttonIsActive}
            text="Очистить форму"
            onClick={() => handleUpdateFormValue(initialValues)}
            className={styles['title-icon']}
            icon="outline-trash"
            placement="bottom"
          />
        </div>
      }
    >
      <CoordinateConverterForm
        formRef={formRef}
        initialValues={formValues}
        valueAvailable={valueAvailable}
        onConvertValue={handleConvert}
        onChangeForm={setFormValues}
      />
    </Modal>
  );
};
