import React, { FC } from 'react';
import styles from './table-cell.module.scss';
import { Popover } from 'antd';
import {
  TableCellForStringContentPropsType,
  TableCellForStringPropsType,
} from './table-cell.types';
import { getTextWidth } from './table-cell-utils';

const ContentItem: FC<TableCellForStringContentPropsType> = ({
  value,
  link,
  isHighlighted = false,
}) => {
  if (link) {
    return link as JSX.Element;
  }
  if (isHighlighted) {
    return <div dangerouslySetInnerHTML={{ __html: value }} />;
  }
  return <span>{value}</span>;
};

export const TableCellForString: FC<TableCellForStringPropsType> = ({
  value = '',
  additionalValue = '',
  className = '',
  maxWidth,
  link,
  isHighlighted = false,
  showAllText = false,
  fontSize,
}) => {
  const popoverClasses = [
    className,
    link ? styles['popover-link'] : '',
    showAllText ? styles['no-wrap'] : '',
  ].join(' ');

  const tooltipClasses = [popoverClasses, styles.tooltip].join(' ');
  const isOutlined = getTextWidth(value, fontSize) > maxWidth;

  if (isOutlined && !showAllText) {
    return (
      <Popover
        overlayClassName={styles.string}
        showArrow={false}
        align={{
          offset: [-13, -9],
          targetOffset: [0, 0],
          points: ['tl', 'tl'],
        }}
        content={
          <div className={tooltipClasses} key={value} style={{ maxWidth: maxWidth }}>
            <ContentItem value={value} isHighlighted={isHighlighted} link={link} />
          </div>
        }
      >
        <div className={popoverClasses}>
          <ContentItem value={additionalValue || value} isHighlighted={isHighlighted} link={link} />
        </div>
      </Popover>
    );
  }
  return (
    <div className={popoverClasses}>
      <ContentItem value={additionalValue || value} isHighlighted={isHighlighted} link={link} />
    </div>
  );
};
