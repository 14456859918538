import React, { FC } from 'react';
import styles from '../../../default-tab.module.scss';
import {
  BathymetricAddFileTable,
  BathymetricDefaultTable,
  BlackTooltip,
  ConfirmModal,
  Icon,
  PanelTabButton,
  TableModal,
  TableModalWithArrow,
} from '@/components';
import { ComponentSize, PanelTabButtonTypes } from '@/constants/enums';
import { EditBathymetric, NewBathymetric } from '@/types';
import { BathTableInfoPropsType, BathymetricTabContentPropsType } from './bathymetric-tab.types';

const BathTabContentInfo: FC<BathTableInfoPropsType> = ({
  loading,
  error,
  currentTotalSize,
  currentFilesLength,
  maxTotalSize = 200,
  maxFilesLength = 5,
  onToggleAddFiles,
}) => {
  return (
    <div className={styles['header-bath']}>
      <div className={styles['title-bath']}>
        <div className={styles['text-bath']}>
          <p>
            Файлов выбрано{' '}
            <strong>
              {currentFilesLength}/{maxFilesLength}
            </strong>
          </p>
          <p
            className={
              parseFloat(currentTotalSize) > maxTotalSize ? styles['title-second-warning'] : ''
            }
          >
            Объем выбранных файлов <strong>{currentTotalSize} Мб</strong> (рекомендовано{' '}
            {maxTotalSize} Мб)
          </p>
        </div>
        <PanelTabButton
          disabled={error && loading}
          type={PanelTabButtonTypes.roundSecondary}
          size={ComponentSize.small}
          onClick={() => onToggleAddFiles((prevState) => !prevState)}
        >
          <div>
            <span>Закрыть </span>
            <Icon size={16} icon="outline-arrow-right" />
          </div>
        </PanelTabButton>
      </div>
    </div>
  );
};

export const BathymetricTabContent: FC<BathymetricTabContentPropsType> = ({
  showAddFilesContent,
  onToggleAddFiles,
  selectedRows,
  isEditable,
  onClick3DBtn,
  onClickDeleteBtn,
  onClickEditBtn,

  currentTotalSize,
  currentFilesLength,
  maxTotalSize = 200,
  maxFilesLength = 5,

  bathData,
  filesData,
  loading,
  error,
  expeditionLoading,
  expeditionError,
  editableFields,
  addFields,
  onSelectRow,
  selectedItem,
  initialValues,
  validationSchema,
  showDeleteModal,
  setShowDeleteModal,
  deleteLoading,
  deleteSuccess,
  onDelete,

  showEditModal,
  setShowEditsModal,
  editLoading,
  editSuccess,
  onEdit,
  expeditionsData,

  showAddModals,
  setShowAddModal,
  addLoading,
  addSuccess,
  onAdd,
}) => {
  return (
    <div className={styles.wrapper}>
      {!loading && !error && !showAddFilesContent && (
        <div className={styles.header}>
          <BlackTooltip
            title={`Перечень загруженных и просчитанных файлов лога дна в 
                слое батиметрической съёмки на карте. Возможно редактировать 
                информацию о логе и удалять логи из слоя. Отображаются на 
                карте в слое Батиметрия.`}
            placement={'bottomLeft'}
          >
            <PanelTabButton
              className={styles['button-panel-help']}
              onClick={() => {}}
              type={PanelTabButtonTypes.icon}
              size={ComponentSize.small}
            >
              <Icon icon="outline-question-circle" size={14} />
            </PanelTabButton>
          </BlackTooltip>
          {isEditable ? (
            <PanelTabButton
              disabled={error && loading}
              type={PanelTabButtonTypes.roundSecondary}
              size={ComponentSize.small}
              onClick={() => onToggleAddFiles()}
            >
              Добавить
            </PanelTabButton>
          ) : null}
        </div>
      )}
      {showAddModals && isEditable ? (
        <TableModalWithArrow
          onSubmit={(value) => onAdd(value as { array: NewBathymetric[] })}
          onClose={() => setShowAddModal(false)}
          validationSchema={validationSchema}
          initialValues={initialValues}
          selectedRows={selectedRows}
          loading={addLoading}
          success={addSuccess}
          fields={addFields}
        />
      ) : null}
      {showAddFilesContent && isEditable ? (
        <BathymetricAddFileTable
          onAddFiles={() => setShowAddModal(true)}
          disableRowSelection={currentFilesLength >= maxFilesLength}
          onSelectRow={onSelectRow}
          selectedRows={selectedRows}
          loading={loading}
          error={error}
          expeditionLoading={expeditionLoading}
          expeditionError={expeditionError}
          bathData={bathData}
          filesData={filesData}
          className={styles['additional-table']}
          bathTableClassName={styles['additional-table-additional']}
          fileTableClassName={styles['additional-table-file']}
          info={
            <BathTabContentInfo
              error={error}
              loading={loading}
              currentFilesLength={currentFilesLength}
              currentTotalSize={currentTotalSize}
              maxFilesLength={maxFilesLength}
              maxTotalSize={maxTotalSize}
              onToggleAddFiles={onToggleAddFiles}
            />
          }
        />
      ) : (
        <BathymetricDefaultTable
          loading={loading}
          error={error}
          isEditable={isEditable}
          expeditionsData={expeditionsData}
          data={bathData}
          onClick3DBtn={onClick3DBtn}
          onClickDeleteBtn={onClickDeleteBtn}
          onClickEditBtn={onClickEditBtn}
        />
      )}

      {isEditable ? (
        <>
          <TableModal
            onSubmit={(value) => onEdit(value as EditBathymetric)}
            onClose={() => setShowEditsModal(false)}
            validationSchema={validationSchema}
            initialValues={selectedItem}
            loading={editLoading}
            success={editSuccess}
            buttonText="Изменить"
            open={showEditModal}
            fields={editableFields}
            width={563}
            title="Изменение данных батиметрии"
            additionalTitle={
              <div className={styles['additional-title']}>
                {selectedItem?.path || selectedItem?.name}
              </div>
            }
          />
          <ConfirmModal
            open={showDeleteModal}
            title="Подтвердите удаление файла"
            content={
              <p>
                Вы уверены, что хотите удалить&nbsp;
                {selectedItem?.name ? (
                  <>
                    файл <strong>{selectedItem?.name}</strong>
                  </>
                ) : (
                  'выбранный файл?'
                )}
                ?
              </p>
            }
            submitButtonText="Удалить файл"
            cancelButtonText="отменить"
            onSubmit={() => onDelete(selectedItem?.id)}
            onCancel={() => setShowDeleteModal(false)}
            loading={deleteLoading}
            success={deleteSuccess}
            type="danger"
          />
        </>
      ) : null}
    </div>
  );
};
