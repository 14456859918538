import { FieldType, TableModal } from '@/components/table-components';
import { FC, useState } from 'react';
import type { RegionFormPropsType, RegionFormType } from './region-content.types';
import * as Yup from 'yup';
import { InputTypes } from '@/constants/enums';

export const RegionForm: FC<RegionFormPropsType> = ({ item, setData, onRedirect }) => {
  const fields: FieldType[] = [
    {
      label: 'Идентификатор района',
      name: 'districtId',
      placeholder: 'Введите идентификатор',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Наименование района',
      name: 'name',
      placeholder: 'Введите наименование',
      type: InputTypes.text,
      required: true,
    },
  ];

  const validationSchema = Yup.object({
    id: Yup.number(),
    districtId: Yup.string().required('Пожалуйста, введите идентификатор'),
    name: Yup.string().required('Пожалуйста, введите наименование'),
  });

  const [isShowAddModal, setIsShowAddModal] = useState(item ? false : true);
  const [isShowEditModal, setIsShowEditModal] = useState(item ? true : false);

  const initialValues = {
    name: item?.name || '',
    districtId: item?.district_id || '',
  };

  const handleCreateForm = (value: RegionFormType) => {
    setData(value);
    setIsShowAddModal(false);
  };
  const handleUpdateForm = (value: RegionFormType) => {
    setData(value);
    setIsShowEditModal(false);
  };

  return (
    <>
      <TableModal
        onSubmit={(value) => handleCreateForm(value as RegionFormType)}
        buttonText="Перейти к созданию полигона"
        onClose={() => setIsShowAddModal(false)}
        title="Добавление района"
        validationSchema={validationSchema}
        redirectOnClose={onRedirect}
        initialValues={initialValues}
        open={isShowAddModal}
        loading={false}
        success={false}
        fields={fields}
        width={563}
      />
      <TableModal
        onSubmit={(value) => handleUpdateForm(value as RegionFormType)}
        onClose={() => setIsShowEditModal(false)}
        validationSchema={validationSchema}
        initialValues={initialValues}
        loading={false}
        redirectOnClose={onRedirect}
        initialFormDirty={true}
        success={false}
        buttonText="Перейти к редактированию полигона"
        open={isShowEditModal}
        fields={fields}
        width={563}
        title={'Редактировать район'}
      />
    </>
  );
};
