import React, { FC } from 'react';
import { Tooltip } from 'antd';
import styles from './default-tooltip.module.scss';
import { IDefaultTooltipPropsTypes } from './default-tooltip.types';

export const DefaultTooltip: FC<IDefaultTooltipPropsTypes> = ({
  children,
  title,
  overlayClassName = '',
  ...rest
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <Tooltip overlayClassName={overlayClassName + ' ' + styles.wrapper} title={title} {...rest}>
        {children}
      </Tooltip>
    </div>
  );
};
