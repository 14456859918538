import { ExpeditionStatuses } from '@/constants/enums';
import React, { FC } from 'react';
import styles from './statuses-tab.module.scss';
import { StatusesTabPropsType } from './statuses-tab.types';

export const StatusesTab: FC<StatusesTabPropsType> = ({ status, item, onTabChange }) => {
  const isCardActive = status === ExpeditionStatuses.card;
  const isReportActive =
    status === ExpeditionStatuses.report || status === ExpeditionStatuses.approved;

  return (
    <div className={styles.container}>
      <div
        className={styles.tab + ' ' + String(isReportActive ? styles['tab-active'] : '')}
        onClick={() => onTabChange(item?.status)}
      >
        <span>Отчет экспедиции</span>
      </div>
      <div
        className={styles.tab + ' ' + String(isCardActive ? styles['tab-active'] : '')}
        onClick={() => onTabChange(ExpeditionStatuses.card)}
      >
        <span>Карточка экспедиции</span>
      </div>
    </div>
  );
};
