import React, { FC, useEffect } from 'react';
import styles from './coords-system-dropdown-field.module.scss';
import { ErrorMessage, Icon } from '@/components';
import { Field, FieldProps, useField } from 'formik';
import { Select } from 'antd';
import { CordSystemDropdownFieldPropsType } from './coords-system-dropdown-field.types';
import { CordSystemData } from '@/constants';

const { Option } = Select;

export const CoordsSystemDropdownField: FC<CordSystemDropdownFieldPropsType> = ({
  name,
  label,
  required,
  isDefaultPopupContainer = true,
}): JSX.Element => {
  const [, { initialValue, value }, { setValue, setTouched }] = useField<string>(name);

  const handleSearch = (value: string) => {
    setValue(value);
  };

  useEffect(() => {
    const fieldIsDirty = !!value && value !== CordSystemData[0].value && value !== initialValue;
    setTouched(fieldIsDirty);
  }, [value, initialValue]);

  return (
    <div className={styles.wrapper} key={name}>
      <label className="mb-1">{required ? label + '*' : label}</label>
      <Field name={name}>
        {(props: FieldProps): JSX.Element => (
          <Select
            defaultValue={initialValue}
            getPopupContainer={(trigger) =>
              !isDefaultPopupContainer ? trigger.parentElement : document.body
            }
            placeholder="Не выбрано"
            virtual={false}
            onSelect={handleSearch}
            defaultActiveFirstOption={true}
            popupClassName={styles.dropdown}
            suffixIcon={<Icon size={16} icon="outline-chevron-down" />}
            {...props}
          >
            {CordSystemData &&
              CordSystemData.map(
                ({
                  firstLabel,
                  secondLabel,
                  value,
                }: {
                  firstLabel: string;
                  secondLabel: string;
                  value: string;
                }) => (
                  <Option key={value} value={value} label={firstLabel}>
                    <div className={styles.item}>
                      <label>{firstLabel}</label>
                      <br />
                      <label>{secondLabel}</label>
                    </div>
                  </Option>
                ),
              )}
          </Select>
        )}
      </Field>
      <ErrorMessage name={name} className={styles.error} />
    </div>
  );
};
