import React, { FC } from 'react';
import { LayersCheckboxPropsTypes } from './card.types';
import styles from './card.module.scss';
import { Icon } from '@/components';
import { useAppSelector } from '@/state';
import { useDispatch } from 'react-redux';
import {
  setIsOpenRegionTooltip,
  setIsOpenSectorTooltip,
  setIsOpenWaterAreaTooltip,
  toggleSelectedLayer,
} from '@/state/slice';

export const Card: FC<LayersCheckboxPropsTypes> = ({
  icon,
  title,
  value,
  onChange,
  border = true,
  isOpenTooltip = false,
  isIconTooltip = false,
  isChecked,
}): JSX.Element => {
  const dispatch = useDispatch();
  const { selectedLayers } = useAppSelector((state) => state.map);

  const customClasses = [border ? styles.border : '', styles.wrapper].join(' ');
  const checked = isChecked || selectedLayers.includes(value);

  const handleToggleObjectLayer = (value: string): void => {
    dispatch(toggleSelectedLayer(value));
  };

  const handleChange = (value: string): void => {
    if (onChange) {
      onChange(value);
    } else {
      handleToggleObjectLayer(value);
    }
  };

  const handleIsOpenTooltip = () => {
    switch (value) {
      case 'regions':
        dispatch(setIsOpenRegionTooltip(!isIconTooltip));
        break;
      case 'sectors':
        dispatch(setIsOpenSectorTooltip(!isIconTooltip));
        break;
      case 'water_areas':
        dispatch(setIsOpenWaterAreaTooltip(!isIconTooltip));
        break;
    }
  };

  return (
    <div className={customClasses}>
      <Icon
        className={styles.arrow}
        icon={checked ? 'outline-eye' : 'outline-eye-close'}
        size={16}
        onClick={() => handleChange(value)}
      />
      {isOpenTooltip && (
        <Icon
          className={styles.arrow}
          icon={isIconTooltip ? 'outline-text' : 'outline-text-slash'}
          size={16}
          onClick={() => handleIsOpenTooltip()}
        />
      )}
      <Icon icon={icon} size={16} />
      <span>{title}</span>
    </div>
  );
};
