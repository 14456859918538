import React, { FC, useEffect, useMemo, useState } from 'react';
import { Marker } from 'react-leaflet';
import { useAppSelector } from '@/state';
import { getPointMarker } from '@/utils';
import { MapTooltip } from '@/components/map/elements/map-tooltip';
import { useDispatch } from 'react-redux';
import { StateGalsMetaData } from '@/types';
import { setIsMetaGalsOpen } from '@/state/slice';

const MetaMarker: FC<{ item: StateGalsMetaData; index: number }> = ({ item, index }) => {
  const [open, setOpen] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsMetaGalsOpen(true));
  });

  return useMemo(
    () =>
      open ? (
        <Marker
          opacity={0}
          position={item.collectLatLng}
          icon={getPointMarker('', NaN, '')}
          key={index}
        >
          <MapTooltip typeOfGeometry={'meta-data-gals'} setOpen={setOpen} {...item}></MapTooltip>
        </Marker>
      ) : null,
    [item, open],
  );
};

export const MetaGalsLayer = () => {
  const collectMetaGals = useAppSelector(
    (state) => state.toolsPanels.metaGalsPanel.collectMetaGals,
  );

  return (
    <>
      {collectMetaGals.map((item, index) => (
        <MetaMarker key={index} item={item} index={index} />
      ))}
    </>
  );
};
