import { FC } from 'react';
import styles from './card-annotation.module.scss';
import { ItemPropsType } from './card-annotation.types';
import { Anchor } from 'antd';

export const Item: FC<ItemPropsType> = ({ children, label, link, className }) => {
  const customClasses = [className ?? ''].join(' ');

  if (!!children) {
    return (
      <div key={label}>
        <Anchor.Link className={customClasses} href={link} title={label} />
        <div className={styles['annotation-group']}>{children}</div>
      </div>
    );
  }

  return <Anchor.Link className={customClasses} href={link} title={label} key={label} />;
};
