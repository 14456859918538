import React, { FC, useEffect, useState } from 'react';
import {
  BathymetricLayer,
  GalsesLayer,
  LayersPanel,
  Layout,
  LiveCoordinatePanel,
  Logo,
  MapBaseLayer,
  MoLayer,
  ObjectsLayer,
  OldBathymetryLayer,
  OoptLayer,
  RoutePanel,
  RulerPanel,
  SectorsLayer,
  ShipsBasesLayer,
  SidebarButton,
  TopomapsLayer,
  UtmBorders,
  WaterAreasLayer,
  ZoomControl,
} from '@/components';
import { matchPath, useNavigate, useParams } from 'react-router';
import {
  ALL_OBJECT_LAYERS,
  LAYERS,
  LINK_TO_SELECT_COORDS_PAGES,
  LINK_TO_SELECT_COORDS_PAGES_LAYOUT,
  MAP,
  SELECT_COORDS_PAGE,
  WATER_AREAS_LAYER,
} from '@/constants';
import { Route, Routes } from 'react-router-dom';
import { useAppSelector } from '@/state';
import { useDispatch } from 'react-redux';
import {
  setFormWithCoordsMarkerValue,
  setIsMarkerCreationSelection,
  toggleObjectLayer,
  toggleSelectedLayer,
} from '@/state/slice';
import { difference, isEmpty } from 'lodash';
import { useClickPosition, useHistoryBackstack, useMap } from '@/context';
import styles from './marker-selection-page.module.scss';
import { MarkerSelectionToolsButton } from '@/pages';
import {
  convertToDecimalCord,
  decimalCordValidator,
  getObjectIconByType,
  getShipsBaseIcon,
  graduatedCoordsValidator,
  prepareCordToString,
} from '@/utils';
import {
  LatLng,
  LatLngLiteral,
  LeafletMouseEvent,
  marker as LMarker,
  Marker,
  Tooltip,
} from 'leaflet';
import { FormikValues } from 'formik';
import { CoordsTypes, Permissions, SelectCoordsPages } from '@/constants/enums';
import { useGetObjectsQuery, useGetShipsBasesQuery } from '@/state/api';

let drawerTooltip: Tooltip | undefined;

export const MarkerSelectionPage: FC = ({}) => {
  const initialValues = useAppSelector(
    (state) => state.toolsPanels.objectCreationPanel.persistedForm,
  );
  const selectedLayers = useAppSelector((state) => state.map.selectedLayers);
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);
  const cordType = useAppSelector((state) => state.map.coordsType);

  const [marker, setMarker] = useState<Marker>();
  const [initialMarker, setInitialMarker] = useState<Marker>();
  const [formValues, setFormValues] = useState<FormikValues>(() => initialValues);
  const [isRoutePanelOpen, setIsRoutePanelOpen] = useState(false);

  const { position, setPosition } = useClickPosition();
  const { backstack } = useHistoryBackstack();
  const { map, setMap } = useMap();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { data: shipsBases } = useGetShipsBasesQuery();
  const { data: objects } = useGetObjectsQuery();

  const type = String(params?.type);
  const id = Number(params?.id);
  const isShipBasesPage = type === SelectCoordsPages.shipBases;
  const isObjectPage = type === SelectCoordsPages.object;

  const icon = isObjectPage
    ? getObjectIconByType(Number(initialValues?.type || 1), true, styles)
    : isShipBasesPage
    ? getShipsBaseIcon(true, styles)
    : '';

  const name = isObjectPage ? 'coordinates' : isShipBasesPage ? 'coords' : '';

  const objectsData = objects?.objects?.filter(({ id: objectId }) => Number(objectId) !== id);
  const shipBasesData = shipsBases?.filter(({ id: shipBaseId }) => Number(shipBaseId) !== id);

  const mapAvailable = map !== null && !!map;
  const ignoredRoutes = [SELECT_COORDS_PAGE + '/*', `/${SELECT_COORDS_PAGE}`];

  const toggleRoutePanel = () => {
    setIsRoutePanelOpen(!isRoutePanelOpen);
  };

  const handleMouseMove = (e: LeafletMouseEvent) => {
    if (map) {
      drawerTooltip?.removeFrom(map);
      drawerTooltip =
        drawerTooltip ||
        new Tooltip({
          className: styles?.tooltip,
          opacity: 1,
          offset: [15, 0],
          content: 'Для изменения координат маркера, кликните в выбранную область на карте',
        });
      drawerTooltip?.setLatLng(e.latlng).addTo(map);
    }
  };

  const handleClose = () => {
    marker?.remove();
    initialMarker?.remove();
    setPosition(null);
    map?.off('mousemove', handleMouseMove);
    map?.getContainer().classList.remove(styles?.cursor);
    dispatch(setIsMarkerCreationSelection(false));
    const filteredBackstack = backstack.filter(
      (path) => ignoredRoutes.filter((route) => matchPath(route, path)).length === 0,
    );
    navigate(filteredBackstack[filteredBackstack.length - 1] ?? MAP);
  };

  const handleToggleLayersPanel = (isActive?: boolean): void => {
    if (isActive) {
      navigate(LINK_TO_SELECT_COORDS_PAGES(type, id));
    } else {
      navigate(LINK_TO_SELECT_COORDS_PAGES_LAYOUT(type, id));
    }
  };

  const handleCreateMarker = (coords: LatLng | LatLngLiteral, isInitial = false): void => {
    const newIcon = icon ? icon : getObjectIconByType(formValues?.type || 1, true, styles);
    const preparedCords = {
      lat: coords?.lat,
      lng: coords?.lng - 0.02,
    };
    if (map) {
      marker?.remove();
      const newMarker = LMarker(coords);
      isInitial ? setInitialMarker(newMarker) : setMarker(newMarker);
      isInitial && newMarker?.setOpacity(0.5);
      newMarker?.setIcon(newIcon);
      newMarker?.addTo(map);
      map?.flyTo(preparedCords);
    }
  };

  const handlePrepareInitialCoords = (value: FormikValues): LatLngLiteral | null => {
    let preparedLat, preparedLng;

    const coordsFieldsAvailable =
      !!value && 'lat' in value && 'lng' in value && !!value.lat && !!value.lng;
    if (coordsFieldsAvailable) {
      preparedLat = value.lat as string;
      preparedLng = value.lng as string;
    } else {
      preparedLat = value?.[name]?.split(', ')[0] as string;
      preparedLng = value?.[name]?.split(', ')[1] as string;
    }

    const isGraduatedCord =
      graduatedCoordsValidator(preparedLng) && graduatedCoordsValidator(preparedLat, true);
    const isDecimalCord = decimalCordValidator(preparedLng) && decimalCordValidator(preparedLat);
    if (isDecimalCord) {
      return {
        lat: parseFloat(preparedLat),
        lng: parseFloat(preparedLng),
      };
    } else if (isGraduatedCord) {
      return {
        lat: parseFloat(convertToDecimalCord(preparedLat, true)),
        lng: parseFloat(convertToDecimalCord(preparedLng)),
      };
    }
    return null;
  };

  const handleChange = (values?: FormikValues, position?: LatLng | null) => {
    if (values && position) {
      const lat = position?.lat?.toString();
      const lng = position?.lng?.toString();

      const preparedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]: [key: string, value: string]) => {
          let preparedValue = value;
          switch (key) {
            case name:
              preparedValue =
                cordType === CoordsTypes.decimal
                  ? `${prepareCordToString(lat)}, ` + `${prepareCordToString(lng)}`
                  : `${prepareCordToString(convertToDecimalCord(lat, true))}, ` +
                    `${prepareCordToString(convertToDecimalCord(lng))}`;
              break;
            case 'lat':
              preparedValue = prepareCordToString(lat);
              break;
            case 'lng':
              preparedValue = prepareCordToString(lng);
              break;
            case 'latGrad':
              preparedValue = prepareCordToString(convertToDecimalCord(lat));
              break;
            case 'lngGrad':
              preparedValue = prepareCordToString(convertToDecimalCord(lng));
              break;
          }

          return [key, preparedValue];
        }),
      );
      setFormValues(preparedValues);
    }
  };

  const handleSubmit = (values?: FormikValues, position?: LatLng | null) => {
    handleClose();
    if (values && position) {
      dispatch(setFormWithCoordsMarkerValue(values));
    }
  };

  const handleCancel = (values?: FormikValues | null) => {
    handleClose();
    if (values) {
      dispatch(setFormWithCoordsMarkerValue(values));
    }
  };

  useEffect(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (mapAvailable) {
      dispatch(setIsMarkerCreationSelection(true));
      map && map?.getContainer().classList.add(styles?.cursor);
    }
  }, [mapAvailable]);

  useEffect(() => {
    if (!!position) {
      handleCreateMarker(position);
      handleChange(formValues, position);
    }
  }, [position]);

  useEffect(() => {
    if (!!position) {
      map && drawerTooltip?.removeFrom(map);
      map?.off('mousemove');
    }
    if (!position) {
      map?.on('mousemove', (event) => {
        !position && handleMouseMove(event);
      });
    }
  }, [position, mapAvailable]);

  useEffect(() => {
    const typeUnavailable = !(
      type === SelectCoordsPages.object || type === SelectCoordsPages.shipBases
    );
    const expeditionManagementUnavailable =
      !userPermissions?.includes(Permissions.expeditionsDataManagment) && isShipBasesPage;
    const mapManagementUnavailable =
      !userPermissions?.includes(Permissions.mapDataManagment) && isObjectPage;
    if (typeUnavailable || expeditionManagementUnavailable || mapManagementUnavailable) {
      handleClose();
    }
  }, [type, userPermissions]);

  useEffect(() => {
    if (!!initialValues) {
      const preparedCoords = handlePrepareInitialCoords(initialValues);
      const creationAvailable =
        mapAvailable &&
        map?.getPanes() !== undefined &&
        !isEmpty(map?.getPanes()) &&
        preparedCoords &&
        !initialMarker;
      creationAvailable && handleCreateMarker(preparedCoords, true);
    }
  }, [initialValues, mapAvailable, initialMarker, map]);

  useEffect(() => {
    const shipBasesLayerUnavailable = !selectedLayers?.includes(WATER_AREAS_LAYER);
    if (shipBasesLayerUnavailable && isShipBasesPage) {
      dispatch(toggleSelectedLayer(WATER_AREAS_LAYER));
    }
    const objectLayerUnavailable = difference(ALL_OBJECT_LAYERS, selectedLayers).length > 0;
    if (objectLayerUnavailable && isObjectPage) {
      dispatch(toggleObjectLayer(true));
    }
  }, [selectedLayers]);

  return (
    <div className={styles[type]}>
      <Layout>
        <Layout.Sidebar>
          <div className={styles.header}>
            <SidebarButton hideTooltip={true} onClick={() => handleCancel(initialValues)}>
              Отмена
            </SidebarButton>
            <SidebarButton
              onClick={() => handleSubmit(formValues, position)}
              hideTooltip={true}
              disable={!position}
            >
              Сохранить изменения
            </SidebarButton>
          </div>
          <div className={styles.sidebar}>
            <SidebarButton
              title="Слои"
              icon="outline-layers"
              isActive={isRoutePanelOpen}
              path={LAYERS}
              onClick={(isActive) => handleToggleLayersPanel(isActive)}
            />
            <MarkerSelectionToolsButton
              isRoutePanelOpen={isRoutePanelOpen}
              toggleRoutePanel={() => toggleRoutePanel()}
            />
          </div>
        </Layout.Sidebar>
        <Layout.Content className={styles.content}>
          <MapBaseLayer>
            <BathymetricLayer />
            <MoLayer />
            <OoptLayer />
            <GalsesLayer />
            <OldBathymetryLayer />
            <TopomapsLayer />
            <UtmBorders />
            <ObjectsLayer defaultObjects={objectsData} isInteractive={false} />
            <ShipsBasesLayer defaultShipBases={shipBasesData} />
            <WaterAreasLayer isInteractive={false} />
            <SectorsLayer />
          </MapBaseLayer>
        </Layout.Content>
        <Layout.Footer>
          <Logo />
          <ZoomControl />
          <LiveCoordinatePanel />
          <RulerPanel />
        </Layout.Footer>
      </Layout>
      {isRoutePanelOpen && <RoutePanel onClose={() => setIsRoutePanelOpen(false)} />}
      <Routes>
        <Route
          path={LAYERS}
          element={<LayersPanel onClose={() => handleToggleLayersPanel(true)} />}
        />
      </Routes>
    </div>
  );
};
