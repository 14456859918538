import { getShipsBaseIcon } from '@/utils';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Marker } from 'react-leaflet';
import { ShipsBasePropsType } from './ships-base-marker.types';
import styles from './ships-base-marker-styles.module.scss';
import { MapTooltip } from '../map-tooltip';
import { setSelectedShipBase } from '@/state/slice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/state';
import { Marker as LM } from 'leaflet';
import { useLocation } from 'react-router';
import { MAP } from '@/constants';

export const ShipsBaseMarker: FC<ShipsBasePropsType> = ({ item }) => {
  const activeItem = useAppSelector((state) => state.shipsBases.selectedItem);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const markerRef = useRef<LM>(null);

  const { coordinates, id } = item;

  const eventHandlers = {
    click: () => {
      if (!pathname.includes(MAP)) dispatch(setSelectedShipBase(id));
    },
  };

  useEffect(() => {
    markerRef.current?.setIcon(getShipsBaseIcon(activeItem === id, styles));
  }, [activeItem, markerRef, item]);

  useEffect(() => {
    return () => {
      const itemActive = activeItem === id;
      const pathAvailable = !pathname.includes(MAP);
      if (itemActive && pathAvailable) {
        dispatch(setSelectedShipBase(undefined));
      }
    };
  }, []);

  return useMemo(
    () => (
      <Marker
        eventHandlers={eventHandlers}
        position={coordinates}
        key={`ship-base-${id}`}
        ref={markerRef}
      >
        <MapTooltip typeOfGeometry={'ships-base'} {...item} />
      </Marker>
    ),
    [item],
  );
};
