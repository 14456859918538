import React, { FC, useEffect, useState } from 'react';
import { BaseSectionPropsType, ExpeditionCardSection } from '../index';
import { ConfirmModal, Icon, PanelTableButton, Table, TableCell, TableModal } from '@/components';
import { handleSortNumber, openErrorNotify, openInfoNotify } from '@/utils';
import { ColumnsType } from 'antd/lib/table';
import { Goal } from '@/types';
import styles from '../section.module.scss';
import { InputTypes } from '@/constants/enums';
import {
  useCreateExpeditionGoalMutation,
  useDeleteExpeditionGoalMutation,
  useEditExpeditionGoalMutation,
} from '@/state/api';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { findIndex } from 'lodash';

export const GoalsSection: FC<BaseSectionPropsType> = ({ isEditable, item, ...rest }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState<Goal>();

  const [
    createGoal,
    {
      isLoading: createLoading,
      isError: createError,
      isSuccess: createSuccess,
      reset: createReset,
    },
  ] = useCreateExpeditionGoalMutation();
  const [
    editGoal,
    { isLoading: editLoading, isError: editError, isSuccess: editSuccess, reset: editReset },
  ] = useEditExpeditionGoalMutation();
  const [
    deleteGoal,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
      reset: deleteReset,
    },
  ] = useDeleteExpeditionGoalMutation();

  const handleSetSelectedItem = (id: number) => {
    setSelectedItem(item?.goals?.find((item) => item.id === id));
  };

  const handleDelete = (id: number) => {
    deleteGoal({ id });
  };

  const handleEdit = (value: FormikValues) => {
    const preparedValue = {
      ...value,
      expedition_id: item?.id,
    };
    editGoal(preparedValue);
  };

  const handleAdd = (value: FormikValues) => {
    const preparedValue = {
      ...value,
      expedition_id: item?.id,
    };
    createGoal(preparedValue);
  };

  const toggleDelete = (id: number) => {
    handleSetSelectedItem(id);
    setShowDeleteModal((prevState) => !prevState);
  };

  const toggleEdit = (id: number) => {
    handleSetSelectedItem(id);
    setShowEditModal((prevState) => !prevState);
  };

  const toggleAdd = () => {
    setShowAddModal((prevState) => !prevState);
  };

  const editableColumns: ColumnsType<Goal> = [
    {
      title: '№',
      align: 'left',
      fixed: 'left',
      width: 62,
      sorter: (first, second) =>
        handleSortNumber(findIndex(item?.goals, first), findIndex(item?.goals, second)),
      render: (value: string, row) => <TableCell value={findIndex(item?.goals, row) + 1} />,
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      fixed: 'left',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleEdit(id)}
          tooltipTitle="Редактировать цель экспедиции"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      fixed: 'left',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleDelete(id)}
          tooltipTitle="Удалить цель экспедиции"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'Цель',
      dataIndex: 'goal_name',
      key: 'goal_name',
      align: 'left',
      width: 280,
      render: (value: string) => <TableCell value={value} showAllText={true} />,
    },
    {
      title: 'Описание цели экспедиции',
      dataIndex: 'goal_description',
      className: 'with-pre-line',
      key: 'goal_description',
      align: 'left',
      render: (value: string) => <TableCell value={value} showAllText={true} />,
    },
  ];

  const readOnlyColumn = editableColumns.filter((item) => item.key !== 'icon');

  const validationSchema = Yup.object({
    goal_name: Yup.string().required('Пожалуйста, укажите цели'),
    goal_description: Yup.string().required('Пожалуйста, укажите описание целей экспедиции'),
  });

  const initialValues = {
    goal_name: '',
    goal_description: '',
  };

  const fields = [
    {
      label: 'Цель экспедиции',
      name: 'goal_name',
      placeholder: 'Введите цель экспедиции',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Описание цели экспедиции',
      name: 'goal_description',
      placeholder: 'Введите описание цели',
      type: InputTypes.textArea,
      required: true,
    },
  ];

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify('Изменения сохранены', 'Добавление цели экспедиции успешно выполнено');
      createReset();
    }
    if (createError) {
      openErrorNotify('Произошла ошибка', 'При добавлении цели экспедиции произошла ошибка');
      createReset();
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify('Изменения сохранены', 'Редактирование цели экспедиции успешно выполнено');
      editReset();
    }
    if (editError) {
      openErrorNotify('Произошла ошибка', 'При редактировании цели экспедиции произошла ошибка');
      editReset();
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify('Изменения сохранены', 'Удаление цели экспедиции успешно выполнено');
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении цели экспедиции произошла ошибка');
      deleteReset();
    }
  }, [deleteSuccess, deleteError]);

  return (
    <ExpeditionCardSection
      {...rest}
      buttonText="Добавить цель"
      onClickAdd={toggleAdd}
      isEditable={isEditable}
    >
      <>
        {item?.goals && item?.goals?.length > 0 && (
          <Table
            withSelection={false}
            dataSource={item?.goals}
            columns={isEditable ? editableColumns : readOnlyColumn}
            className={styles.table}
            noDataClassName={styles['no-data']}
            scroll={{ x: 1225, y: 300 }}
            withPagination={false}
          />
        )}
        <TableModal
          onSubmit={(value) => handleAdd(value)}
          onClose={() => setShowAddModal(false)}
          validationSchema={validationSchema}
          initialValues={initialValues}
          loading={createLoading}
          success={createSuccess}
          buttonText="Готово"
          open={showAddModal}
          fields={fields}
          width={563}
          title="Цели экспедиции"
        />
        <TableModal
          onSubmit={(value) => handleEdit(value)}
          onClose={() => setShowEditModal(false)}
          validationSchema={validationSchema}
          initialValues={selectedItem as Goal}
          loading={editLoading}
          success={editSuccess}
          buttonText="Готово"
          open={showEditModal}
          fields={fields}
          width={563}
          title="Цели экспедиции"
        />
        <ConfirmModal
          open={showDeleteModal}
          title="Подтвердите удаление цели экспедиции"
          content={
            <p>
              Вы уверены, что хотите удалить&nbsp;
              {selectedItem?.goal_name ? (
                <strong>{selectedItem?.goal_name}</strong>
              ) : (
                'выбранную цель экспедиции'
              )}
              ?
            </p>
          }
          submitButtonText="Удалить"
          cancelButtonText="отменить"
          onSubmit={() => selectedItem && handleDelete(selectedItem?.id)}
          onCancel={() => setShowDeleteModal(false)}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
      </>
    </ExpeditionCardSection>
  );
};
