import React, { FC, useEffect, useState } from 'react';
import { BaseSectionPropsType, ExpeditionCardSection } from '../index';
import { FieldType, TableModal } from '@/components';
import { useEditExpeditionReferenceMutation } from '@/state/api';
import { openErrorNotify, openInfoNotify } from '@/utils';
import { FormikValues } from 'formik';
import { IExpeditionSummary } from '@/types';
import { InputTypes } from '@/constants/enums';
import * as Yup from 'yup';
import styles from '../../../expedition-panel.module.scss';

export const HistorySection: FC<BaseSectionPropsType> = ({ item, label, isEditable }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IExpeditionSummary>();

  const initialValues = {
    description_history: '',
  };

  const fields: FieldType[] = [
    {
      label: 'Описание исторической справки экспедиции',
      name: 'description_history',
      placeholder: 'Введите исторической описание справки экспедиции',
      type: InputTypes.textArea,
      maxLength: 5000,
      className: styles['text-area-first'] ?? (styles['text-area-first'] as CSSModuleClasses),
    },
  ];

  const validationSchema = Yup.object({
    description_history: Yup.string().nullable(true),
  });

  const [
    editHistory,
    { isLoading: editLoading, isError: editError, isSuccess: editSuccess, reset: editReset },
  ] = useEditExpeditionReferenceMutation();

  const handleSetSelectedItem = () => {
    setSelectedItem(item);
  };

  const toggleEdit = () => {
    handleSetSelectedItem();
    setShowEditModal((prevState) => !prevState);
  };

  const handleEdit = (value: FormikValues) => {
    const preparedValue = {
      description_history: value.description_history as string,
      id: item?.id as number,
    };
    editHistory(preparedValue);
  };

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify(
        'Изменения исторической справки',
        'Редактирование исторической справки экспедиции успешно выполнено',
      );
      editReset();
    }
    if (editError) {
      openErrorNotify(
        'Произошла ошибка',
        'При редактировании исторической справки экспедиции произошла ошибка',
      );
      editReset();
    }
  }, [editSuccess, editError]);

  return (
    <ExpeditionCardSection
      buttonText={item?.description_history ? 'Редактировать справку' : 'Добавить справку'}
      label={label}
      icon={item?.description_history ? 'outline-pencil' : 'outline-plus-24'}
      isEditable={isEditable}
      onClickAdd={() => toggleEdit()}
    >
      <>
        {item?.description_history ? (
          <p className="with-pre-line">{item?.description_history}</p>
        ) : (
          <></>
        )}
        <TableModal
          onSubmit={(value) => handleEdit(value)}
          onClose={() => setShowEditModal(false)}
          validationSchema={validationSchema}
          initialValues={selectedItem || initialValues}
          loading={editLoading}
          success={editSuccess}
          buttonText={item?.description_history ? 'Изменить' : 'Добавить'}
          open={showEditModal}
          fields={fields}
          width={563}
          title={'Историческая справка'}
        />
      </>
    </ExpeditionCardSection>
  );
};
