import React, { FC, useEffect, useMemo } from 'react';
import styles from './expedition-panel.module.scss';
import { useHistoryBackstack } from '@/context';
import {
  EXPEDITION_PANEL,
  EXPEDITION_PANEL_WITHOUT_STATUS,
  EXPEDITION_STEP_PAGE,
  LINK_TO_EXPEDITION_PANEL,
  LINK_TO_EXPEDITION_TAB,
  MAP,
  POLYGON_TOOL,
} from '@/constants';
import { Card } from '@/components';
import { matchPath, Navigate, useNavigate, useParams } from 'react-router';
import {
  ExpeditionTablesTabsTypes,
  ExpeditionStatuses,
  Permissions,
  ExpeditionStatusesType,
} from '@/constants/enums';
import {
  AttachmentsSection,
  CommonInfoSection,
  ConclusionsSection,
  DatesSection,
  DivingSection,
  GoalsSection,
  HistoryLogSection,
  HistorySection,
  ObjectWorkSection,
  ParticipantsSection,
  ProposalsSection,
  SectorsWorksSection,
  ShipBasesSection,
  StatusesTab,
  TasksSection,
  Title,
  ToolsDivingsSection,
  ToolsSection,
} from './components';
import { ExpeditionAnnotationItemType } from './expedition-panel.types';
import {
  useExportExpeditionToDocxMutation,
  useExportExpeditionToPdfMutation,
  useGetExpeditionSummaryQuery,
  useGetMembersQuery,
  useGetObjectsQuery,
  useGetSectorsQuery,
  useGetShipsBasesQuery,
  useGetToolsListQuery,
} from '@/state/api';
import { openErrorNotify, openInfoNotify, TransformToHours, TransformToMinutes } from '@/utils';
import { AnyObject } from 'yup/lib/types';
import { useAppSelector } from '@/state';

export const ExpeditionPanel: FC = () => {
  const navigate = useNavigate();

  const { backstack } = useHistoryBackstack();

  const { id: idParam, status: statusParam } = useParams();
  const id = Number(idParam);

  const ignoredRoutes = [
    POLYGON_TOOL + '/*',
    `${MAP}${String(EXPEDITION_PANEL)}`,
    `${MAP}${String(EXPEDITION_PANEL_WITHOUT_STATUS)}`,
    EXPEDITION_STEP_PAGE + '/*',
  ];

  const statusFromParam = ExpeditionStatuses[statusParam as keyof typeof ExpeditionStatuses];

  const [
    exportToPdf,
    {
      data: blobDataPdf,
      isLoading: isLoadingPdfExport,
      isSuccess: isSuccessPdfExport,
      isError: isErrorPdfExport,
      reset: resetPdfExport,
    },
  ] = useExportExpeditionToPdfMutation();

  const [
    exportToDocx,
    {
      data: blobDataDocx,
      isLoading: isLoadingWordExport,
      isSuccess: isSuccessWordExport,
      isError: isErrorWordExport,
      reset: resetWordExport,
    },
  ] = useExportExpeditionToDocxMutation();

  const {
    isFetching: isExpeditionFetching,
    isLoading: isExpeditionLoading,
    isError: isExpeditionError,
    data,
  } = useGetExpeditionSummaryQuery({ id: id });

  const { data: membersData } = useGetMembersQuery({ expId: id });
  const { data: sectorData } = useGetSectorsQuery({ expId: id });
  const { data: shipBasesData } = useGetShipsBasesQuery({ expId: id });
  const { data: toolsData } = useGetToolsListQuery({ expId: id });
  const { data: objectsData } = useGetObjectsQuery({ expId: id });

  const expeditionCardData = data?.expedition_card;
  const expeditionReportData = data?.expedition_report ?? null;
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);
  const canEdit = userPermissions?.includes(Permissions.expeditionsDataManagment);

  const actualData = expeditionReportData || expeditionCardData;

  const handleClose = () => {
    const filteredBackstack = backstack.filter(
      (path) => ignoredRoutes.filter((route) => matchPath(route, path)).length === 0,
    );
    navigate(
      filteredBackstack[filteredBackstack.length - 1] ??
        LINK_TO_EXPEDITION_TAB(ExpeditionTablesTabsTypes.expeditions),
    );
  };

  const handleTabSwitch = (value: ExpeditionStatusesType) => {
    navigate(LINK_TO_EXPEDITION_PANEL(id, value));
  };

  useEffect(() => {
    const idUnavailable = !id || String(id) === 'undefined' || isNaN(id);
    if (idUnavailable) {
      handleClose();
    }
  }, [id]);

  useEffect(() => {
    if (isSuccessPdfExport) {
      openInfoNotify(
        'Загрузка началась',
        'Данные для экспорта успешно получены. Начинается загрузка pdf файла.',
        () => resetPdfExport(),
      );
    }
    if (isErrorPdfExport) {
      openErrorNotify('Произошла ошибка', 'При экспорте данных объекта произошла ошибка');
    }
  }, [isLoadingPdfExport, isErrorPdfExport, blobDataPdf]);

  useEffect(() => {
    if (isSuccessWordExport) {
      openInfoNotify(
        'Загрузка началась',
        'Данные для экспорта успешно получены. Начинается загрузка word файла',
        () => resetWordExport(),
      );
    }
    if (isErrorWordExport) {
      openErrorNotify('Произошла ошибка', 'При экспорте данных объекта произошла ошибка');
    }
  }, [isSuccessWordExport, isErrorWordExport, blobDataDocx]);

  const preparedData = useMemo(() => {
    if (statusParam === ExpeditionStatuses.card) {
      return expeditionCardData;
    }
    return actualData;
  }, [actualData, statusParam]);

  const handlePrepareOptions = (data?: AnyObject[]) => {
    if (!data || data.length === 0) {
      return [];
    }

    return data.map(({ name, id }) => ({
      label: name as string,
      value: id as string,
    }));
  };

  const selectedMembersIDs = preparedData?.members.map(({ member_id }) => member_id);

  const uniqueMembers = membersData?.members?.filter(({ id }) => !selectedMembersIDs?.includes(id));
  const selectedMembers = membersData?.members?.filter(({ id }) =>
    selectedMembersIDs?.includes(id),
  );
  const preparedMembersOptions = handlePrepareOptions(uniqueMembers);
  const preparedDiveMembersOptions = handlePrepareOptions(selectedMembers);

  const preparedSectorsOptions = handlePrepareOptions(sectorData);
  const preparedShipBasesOptions = handlePrepareOptions(shipBasesData);
  const preparedToolsOptions = handlePrepareOptions(toolsData?.tools);
  const preparedObjectsOptions = handlePrepareOptions(objectsData?.objects);

  const includesObject = preparedData?.objects_works.map(({ object_id }) => object_id);
  const preparedDiveObjectsData = objectsData?.objects?.filter(({ id }) =>
    includesObject?.includes(id),
  );
  const preparedDiveObjectsOptions = handlePrepareOptions(preparedDiveObjectsData);

  const includedTools = preparedData?.tools?.map(({ tool_id }) => tool_id);
  const preparedDiveToolsData = toolsData?.tools?.filter(({ id }) => includedTools?.includes(id));
  const preparedDiveToolsOptions = handlePrepareOptions(preparedDiveToolsData);

  const handleExportPdf = () => {
    exportToPdf({
      id: Number(preparedData?.id),
      name: preparedData?.name as string,
    });
  };

  const handleExportDocx = () => {
    exportToDocx({
      id: Number(preparedData?.id),
      name: preparedData?.name as string,
    });
  };

  const isEditable = useMemo(() => {
    if (
      actualData?.status === ExpeditionStatuses.approved ||
      actualData?.status === ExpeditionStatuses.plan ||
      (statusParam === ExpeditionStatuses.card && expeditionReportData) ||
      !canEdit
    ) {
      return false;
    }
    return true;
  }, [actualData, statusParam, data, canEdit]);

  const expeditionAnnotation: ExpeditionAnnotationItemType[] = [
    {
      label: 'Общая информация',
      link: '#common',
      component: (
        <CommonInfoSection
          item={preparedData}
          label="Общая информация"
          expedition={preparedData}
          isEditable={isEditable}
        />
      ),
    },
    {
      label: 'Историческая справка',
      link: '#history',
      hidden: preparedData?.status === ExpeditionStatuses.plan,
      component: (
        <HistorySection item={preparedData} isEditable={isEditable} label="Историческая справка" />
      ),
    },
    {
      label: 'Цели',
      link: '#goals',
      hidden: preparedData?.status === ExpeditionStatuses.plan,
      component: <GoalsSection isEditable={isEditable} label="Цели" item={preparedData} />,
    },
    {
      label: 'Задачи',
      link: '#tasks',
      hidden: preparedData?.status === ExpeditionStatuses.plan,
      component: <TasksSection isEditable={isEditable} label="Задачи" item={preparedData} />,
    },
    {
      label: 'Поисковые работы на секторах',
      link: '#sectors_works',
      hidden: preparedData?.status === ExpeditionStatuses.plan,
      component: (
        <SectorsWorksSection
          isEditable={isEditable}
          label="Поисковые работы на секторах"
          item={preparedData}
          selectData={preparedSectorsOptions}
          transformToHours={TransformToHours}
          transformToMinutes={TransformToMinutes}
        />
      ),
    },
    {
      label: 'Работы по объектам исследования',
      link: '#objects_works',
      hidden: preparedData?.status === ExpeditionStatuses.plan,
      component: (
        <ObjectWorkSection
          isEditable={isEditable}
          label="Работы по объектам исследования"
          item={preparedData}
          selectData={preparedObjectsOptions}
          transformToHours={TransformToHours}
          transformToMinutes={TransformToMinutes}
        />
      ),
    },
    {
      label: 'Список участников',
      link: '#participants',
      hidden: preparedData?.status === ExpeditionStatuses.plan,
      component: (
        <ParticipantsSection
          isEditable={isEditable}
          label={`Список участников ${
            preparedData && preparedData?.members.length > 0
              ? `(${preparedData.members.length})`
              : ''
          }`}
          item={preparedData}
          selectData={preparedMembersOptions}
        />
      ),
    },
    {
      label: 'Список МТО',
      link: '#tools',
      hidden: preparedData?.status === ExpeditionStatuses.plan,
      component: (
        <ToolsSection
          isEditable={isEditable}
          label="Список материально-технического обеспечения"
          item={preparedData}
          selectData={preparedToolsOptions}
        />
      ),
    },
    {
      label: 'Базы судов',
      link: '#ships_bases',
      hidden: preparedData?.status === ExpeditionStatuses.plan,
      component: (
        <ShipBasesSection
          isEditable={isEditable}
          label="Базы судов"
          item={preparedData}
          selectData={preparedShipBasesOptions}
        />
      ),
    },
    {
      label: 'Выводы',
      link: '#conclusions',
      hidden:
        preparedData?.status === ExpeditionStatuses.plan ||
        preparedData?.status === ExpeditionStatuses.card,
      component: <ConclusionsSection item={preparedData} isEditable={isEditable} label="Выводы" />,
    },
    {
      label: 'Предложения',
      link: '#proposals',
      hidden:
        preparedData?.status === ExpeditionStatuses.plan ||
        preparedData?.status === ExpeditionStatuses.card,
      component: (
        <ProposalsSection item={preparedData} isEditable={isEditable} label="Предложения" />
      ),
    },
    {
      label: 'Погружения',
      link: '#divings',
      hidden:
        preparedData?.status === ExpeditionStatuses.plan ||
        preparedData?.status === ExpeditionStatuses.card,
      component: (
        <DivingSection
          item={preparedData}
          isEditable={isEditable}
          label="Погружения"
          membersSelectionData={preparedDiveMembersOptions}
          objectsSelectionData={preparedDiveObjectsOptions}
          transformToHours={TransformToHours}
          transformToMinutes={TransformToMinutes}
        />
      ),
    },
    {
      label: 'Погружения техники',
      link: '#tools_divings',
      hidden:
        preparedData?.status === ExpeditionStatuses.plan ||
        preparedData?.status === ExpeditionStatuses.card,
      component: (
        <ToolsDivingsSection
          item={preparedData}
          isEditable={isEditable}
          label="Погружения техники"
          membersSelectionData={preparedDiveMembersOptions}
          objectsSelectionData={preparedDiveObjectsOptions}
          toolsSelectionData={preparedDiveToolsOptions}
          transformToHours={TransformToHours}
          transformToMinutes={TransformToMinutes}
        />
      ),
    },
    {
      label: 'Вложения',
      link: '#attachment',
      hidden: preparedData?.status === ExpeditionStatuses.plan,
      component: (
        <AttachmentsSection item={preparedData} isEditable={isEditable} label="Вложения" />
      ),
    },
    {
      label: 'Даты экспедиции',
      link: '#dates',
      hidden: !canEdit,
      component: (
        <DatesSection isEditable={isEditable} item={preparedData} label="Даты экспедиции" />
      ),
    },
    {
      label: 'История изменений',
      link: '#history_log',
      hidden: preparedData?.status === ExpeditionStatuses.plan || !canEdit,
      component: (
        <HistoryLogSection isEditable={isEditable} item={preparedData} label="История изменений" />
      ),
    },
  ];

  const hasTabsSwitcher =
    actualData?.status &&
    actualData?.status !== ExpeditionStatuses.plan &&
    actualData?.status !== ExpeditionStatuses.card;

  return (
    <Card
      onClose={handleClose}
      width={1654}
      title={false}
      loading={isExpeditionLoading}
      centered={true}
      showCloseIcon={false}
      className={styles.modal}
      error={isExpeditionError}
      footer={<div className={styles.footer} />}
      additionalTitle={
        <Title
          isEditable={canEdit}
          loading={isExpeditionLoading}
          refetching={isExpeditionFetching}
          onClickDocDownload={handleExportDocx}
          onClickPdfDownload={handleExportPdf}
          onClose={handleClose}
          item={actualData}
          loadingPdf={isLoadingPdfExport}
          loadingWord={isLoadingWordExport}
        />
      }
    >
      <Card.Annotation>
        {hasTabsSwitcher ? (
          <StatusesTab onTabChange={handleTabSwitch} item={actualData} status={statusFromParam} />
        ) : null}
        <div className={styles.menu}>
          {expeditionAnnotation.map(
            ({ hidden, label, link }) =>
              !hidden && <Card.Annotation.Item key={label} label={label} link={link} />,
          )}
        </div>
      </Card.Annotation>
      <Card.Content loading={isExpeditionFetching || isExpeditionLoading}>
        {expeditionAnnotation.map(
          ({ hidden, component, link }, index) =>
            !hidden && (
              <Card.Content.Section id={link.replace('#', '')} key={index}>
                {component}
              </Card.Content.Section>
            ),
        )}
      </Card.Content>
      {actualData && !statusFromParam ? (
        <Navigate to={LINK_TO_EXPEDITION_PANEL(id, actualData.status)} />
      ) : null}
      {actualData &&
      statusFromParam &&
      statusFromParam !== actualData.status &&
      statusFromParam !== ExpeditionStatuses.card ? (
        <Navigate to={LINK_TO_EXPEDITION_PANEL(id, actualData.status)} />
      ) : null}
    </Card>
  );
};
