import React, { FC, useEffect, useState } from 'react';
import {
  useDeleteUserMutation,
  useEditUserMutation,
  useGetUsersQuery,
  useCreateUserMutation,
  useGetRolesListQuery,
} from '@/state/api';
import { useAppSelector } from '@/state';
import { DefaultResponse, NewUser, User } from '@/types';
import { openErrorNotify, openInfoNotify } from '@/utils';
import { UsersTabForm } from './users-tab-form';

export const UsersTab: FC = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [createdName, setCreatedName] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User>();

  //take user id, to block user delete himself
  const userId = useAppSelector((state) => state.auth.user?.id);

  const { isLoading, isError, data, error: getError } = useGetUsersQuery();
  const {
    data: rolesData,
    isLoading: isRolesLoading,
    isError: isRolesError,
  } = useGetRolesListQuery();

  const loading = isLoading || isRolesLoading;
  const error = isError || isRolesError;

  const preparedUser = data?.users || [];
  const preparedRolesData =
    rolesData?.data.map((item) => ({ label: item.name, value: item.id })) || [];

  const [
    createUser,
    {
      isLoading: createLoading,
      isError: createError,
      isSuccess: createSuccess,
      reset: createReset,
    },
  ] = useCreateUserMutation();
  const [
    updateUser,
    {
      isLoading: updateLoading,
      isError: updateError,
      isSuccess: updateSuccess,
      reset: updateReset,
    },
  ] = useEditUserMutation();
  const [
    deleteUser,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      reset: deleteReset,
    },
  ] = useDeleteUserMutation();

  const handleToggleAddUser = () => {
    setShowAddModal((prevState) => !prevState);
    createReset();
  };

  const handleToggleEditUser = (value: User) => {
    setShowEditModal((prevState) => !prevState);
    setSelectedUser(value);
    updateReset();
  };

  const handleToggleDeleteUser = (value: User) => {
    setShowDeleteModal((prevState) => !prevState);
    setSelectedUser(value);
    deleteReset();
  };

  const handleCreate = (value: NewUser) => {
    const preparedValue = { ...value, login: value.email };
    setCreatedName(value.name);
    createUser(preparedValue as NewUser);
  };

  const handleUpdate = (value: User) => {
    updateUser(value);
  };

  const handleDelete = (uid: number) => {
    deleteUser({ uid });
  };

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify(
        'Создание пользователя',
        <>
          Пользователь <b>{createdName}</b> был создан
        </>,
      );
    }
    if (createError) {
      openErrorNotify('Произошла ошибка', 'При создании пользователя произошла ошибка');
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (updateSuccess) {
      openInfoNotify(
        'Изменения пользователя',
        <>
          Пользователь <b>{selectedUser?.name}</b> был изменен
        </>,
      );
    }
    if (updateError) {
      openErrorNotify('Произошла ошибка', 'При редактировании пользователя произошла ошибка');
    }
  }, [updateSuccess, updateError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Удаление пользователя',
        <>
          Пользователь <b>{selectedUser?.name}</b> был удален
        </>,
      );
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении пользователя произошла ошибка');
    }
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    if (isError) {
      const response = getError as DefaultResponse;
      openErrorNotify(
        'Произошла ошибка',
        response?.data?.message || 'При загрузке списка пользователей произошла ошибка',
      );
    }
  }, [isError]);

  return (
    <UsersTabForm
      userId={userId}
      isLoading={loading}
      isError={error}
      selectedItem={selectedUser}
      preparedData={preparedUser}
      selectDataLoading={isRolesLoading}
      selectDataError={isRolesError}
      selectData={preparedRolesData}
      showAddModal={showAddModal}
      createLoading={createLoading}
      createSuccess={createSuccess}
      onToggleAddUser={handleToggleAddUser}
      setShowAddModal={setShowAddModal}
      onCreate={handleCreate}
      showEditModal={showEditModal}
      updateSuccess={updateSuccess}
      updateLoading={updateLoading}
      onToggleEditUser={handleToggleEditUser}
      setShowEditModal={setShowEditModal}
      onUpdate={handleUpdate}
      showDeleteModal={showDeleteModal}
      deleteSuccess={deleteSuccess}
      deleteLoading={deleteLoading}
      onToggleDeleteUser={handleToggleDeleteUser}
      setShowDeleteModal={setShowDeleteModal}
      onDelete={handleDelete}
    />
  );
};
