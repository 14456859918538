import { TopomapLayer } from '@/components/map/elements';
import React from 'react';
import rasterMaps from '@/assets/json-layers/raster-map-layers.json';

export const TopomapsLayer = (): JSX.Element => {
  return (
    <>
      {rasterMaps.length &&
        rasterMaps.map((item, idx) => (
          <TopomapLayer ns={item.ns} header={item.header} key={idx} id={idx} />
        ))}
    </>
  );
};
