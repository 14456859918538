import React, { FC } from 'react';
import styles from './styles.module.scss';
import { SearchPanelLoadingPlaceholder } from '../placeholdres/search-panel-loading-placeholder';
import { SearchPanelErrorPlaceholder } from '../placeholdres/search-panel-error-placeholder';
import { SearchPanelNoDataPlaceholder } from '../placeholdres/search-panel-no-data-placeholder';
import { SearchPanelOption } from '../option/search-panel-input-option';
import { SearchPanelListProps } from './index.types';

export const SearchPanelList: FC<SearchPanelListProps> = ({
  loading,
  error,
  data,
  onSearch,
  searchValue,
}) => {
  return (
    <div className={styles.wrapper}>
      {loading ? (
        <SearchPanelLoadingPlaceholder />
      ) : error ? (
        <SearchPanelErrorPlaceholder error={error} />
      ) : data?.length < 1 ? (
        <SearchPanelNoDataPlaceholder />
      ) : (
        data?.map(({ identifier, id, name }) => (
          <SearchPanelOption
            key={`option-${id}`}
            prefix="Идентификатор: "
            subTitle={identifier}
            title={name}
            searchValue={searchValue}
            value={id}
            onClick={onSearch}
          />
        ))
      )}
    </div>
  );
};
