import { api } from '@/state/api';
import { combineReducers } from '@reduxjs/toolkit';
import {
  authReducer,
  objectsReducer,
  regionsReducer,
  sectorsReducer,
  shipsBasesReducer,
  waterAreasReducer,
  mapReducer,
  panelsReducer,
} from '../../slice';
import { toolsPanelsReducer } from '../tools-panels-reducer';

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  objects: objectsReducer,
  regions: regionsReducer,
  sectors: sectorsReducer,
  shipsBases: shipsBasesReducer,
  toolsPanels: toolsPanelsReducer,
  waterAreas: waterAreasReducer,
  map: mapReducer,
  panels: panelsReducer,
});
