import React, { FC } from 'react';
import { menuData, ObjectCardItem } from '@/components';
import { ObjectCardPropsType } from '../object-card-panel.types';
import { FormFieldsTypes } from '@/constants/enums';

export const ObjectHistoryAndDescriptionSection: FC<ObjectCardPropsType> = ({
  onSubmit,
  success,
  loading,
  isEditable,
}) => {
  const fields = [
    {
      label: 'Историческая справка',
      name: 'history',
      type: FormFieldsTypes.textArea,
    },
    {
      label: 'Описание',
      name: 'description',
      type: FormFieldsTypes.textArea,
    },
  ];

  return (
    <ObjectCardItem
      title="Историческая справка и описание"
      type="textArea"
      isEditable={isEditable}
      id={menuData[1].link}
      onSubmit={() => onSubmit()}
      success={success}
      loading={loading}
      fields={fields}
    />
  );
};
