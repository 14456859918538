export enum ComponentSize {
  small = 'sm',
  medium = 'md',
  large = 'lg',
}

export type ComponentSizeType = `${ComponentSize}`;

export enum Colors {
  black = 'primary',
  secondary = 'secondary',
  red = 'danger',
}

export type ColorsType = `${Colors}`;

export enum FillTypes {
  fill = 'fill',
  outline = 'outline',
  noFill = 'noFill',
  tertiary = 'tertiary',
}

export type FillType = `${FillTypes}`;

export enum ButtonTypes {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

export type ButtonType = `${ButtonTypes}`;

export enum InputTypes {
  text = 'text',
  password = 'password',
  textArea = 'textArea',
  number = 'number',
  hidden = 'hidden',
}

export type InputType = `${InputTypes}`;

export enum ModalPosition {
  left = 'left',
  right = 'right',
  center = 'center',
}

export type ModalPositionType = `${ModalPosition}`;

export enum PanelTabButtonTypes {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary',
  outline = 'outline',
  buttonPanel = 'button-panel',
  round = 'round',
  roundSecondary = 'round-secondary',
  icon = 'icon',
  danger = 'red-button',
}

export type PanelTabButtonType = `${PanelTabButtonTypes}`;

export enum CoordsTypes {
  decimal = 'DEC',
  graduated = 'DEG',
}

export type CoordsType = `${CoordsTypes}`;

export enum PolygonTypes {
  accessArea = 'access_area',
  sector = 'sector',
  waterArea = 'water_area',
  region = 'region',
}

export type PolygonType = `${PolygonTypes}`;

export enum ExpeditionTypeTranslation {
  plan = 'План',
  card = 'Карточка',
  report = 'Отчет',
  approved = 'Утверждено',
}

export enum ExpeditionStatuses {
  plan = 'plan',
  card = 'card',
  report = 'report',
  approved = 'approved',
}

export type ExpeditionStatusesType = `${ExpeditionStatuses}`;

export enum Permissions {
  rolesManagment = 7,
  expeditionsDataManagment = 6,
  mapDataManagment = 5,
  specificDataViewing = 4,
  expeditionsDataViewing = 3,
  mapDataViewing = 2,
  baseContentViewing = 1,
}

export enum FileTypes {
  image = 'image/*',
  docs = '.doc,.docx,.xls,.xlsx,.pdf',
  all = '*',
}

export type FileType = `${FileTypes}`;

export enum MediaTargetsTypes {
  expedition = 'expedition',
  object = 'object',
}

export type MediaTargetsType = `${MediaTargetsTypes}`;

export enum FileApplicationTypes {
  pdf = 'application/pdf',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export enum Targets3DTypes {
  bathymetry = 'bathymetry',
  object = 'object',
}

export type Targets3DType = `${Targets3DTypes}`;

export enum Models3DTypes {
  glb = 'glb',
  gltf = 'gltf',
  obj = 'obj',
}

export enum FormFieldsTypes {
  datePicker = 'datePicker',
  select = 'select',
  multiselect = 'multiselect',
  textArea = 'textArea',
  input = 'input',
  coordsSelect = 'coordsSelect',
}

export type FormFieldsType = `${FormFieldsTypes}`;

export enum ExpeditionTablesTabsTypes {
  expeditions = 'expeditions',
  objectsResearch = 'objects_research',
  sectors = 'sectors',
  waterAreas = 'water_area',
  shipBases = 'ship_bases',
  tools = 'tools',
  members = 'members',
}

export enum LayerTablesTabsTypes {
  bathymetric = 'bathymetric',
  galses = 'galses',
  regions = 'regions',
  objectsResearch = 'objects_research',
}

export enum SettingTablesTabsTypes {
  roles = 'roles',
  users = 'users',
  objectsGroups = 'objects_groups',
  accessesAreas = 'accesses_areas',
}

export type ExpeditionTablesTabsType = `${ExpeditionTablesTabsTypes}`;
export type LayerTablesTabsType = `${LayerTablesTabsTypes}`;
export type SettingTablesTabsType = `${SettingTablesTabsTypes}`;

export type EntireTablesTabsType = ExpeditionTablesTabsType &
  LayerTablesTabsType &
  SettingTablesTabsType;

export enum SelectCoordsPages {
  object = 'object',
  shipBases = 'ship-base',
}
