import React, { FC, useEffect, useState } from 'react';
import styles from '../../default-tab.module.scss';
import { Member, NewMember } from '@/types';
import * as Yup from 'yup';
import { openErrorNotify, openInfoNotify } from '@/utils';
import {
  BlackTooltip,
  ConfirmModal,
  FieldType,
  Icon,
  MembersTable,
  PanelTabButton,
  TableModal,
} from '@/components';
import {
  useCreateMemberMutation,
  useDeleteMemberMutation,
  useEditMemberMutation,
  useGetMembersQuery,
} from '@/state/api';
import { ComponentSize, InputTypes, PanelTabButtonTypes, Permissions } from '@/constants/enums';
import { useAppSelector } from '@/state';

export const MembersTab: FC = (): JSX.Element => {
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showEditMemberModal, setShowEditMemberModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [createdName, setCreatedName] = useState<string>('');
  const [selectedMember, setSelectedMember] = useState<Member>();
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);
  const isEditable = userPermissions?.includes(Permissions.expeditionsDataManagment);

  const { isLoading, data, isError } = useGetMembersQuery();
  const [
    createMember,
    {
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
      reset: resetCreate,
    },
  ] = useCreateMemberMutation();
  const [
    editMember,
    { isLoading: editLoading, isSuccess: editSuccess, isError: editError, reset: resetEdit },
  ] = useEditMemberMutation();
  const [
    deleteMember,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      reset: resetDelete,
    },
  ] = useDeleteMemberMutation();

  const preparedMembers = data?.members || [];

  const getMember = (id: number) => {
    const item = preparedMembers.find((member) => member.id === id);
    item && setSelectedMember(item);
  };

  const initialValues: NewMember = {
    name: '',
    birthday: '',
    position: '',
  };

  const fields: FieldType[] = [
    {
      label: 'ФИО участника',
      name: 'name',
      placeholder: 'Введите ФИО',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Дата рождения',
      name: 'birthday',
      type: 'datePicker',
      required: true,
    },
    {
      label: 'Должность',
      name: 'position',
      placeholder: 'Введите должность',
      type: InputTypes.text,
      required: true,
    },
  ];

  const validationSchema = Yup.object({
    name: Yup.string().required('Пожалуйста, укажите ФИО участника'),
    position: Yup.string().required('Пожалуйста, укажите должность'),
    birthday: Yup.date()
      .min(new Date(1900, 0, 1), 'Вы указали некорректный участок времени')
      .max(new Date(), 'Дата рождения не может быть указана в будущем')
      .required('Пожалуйста, укажите дату рождения'),
  });

  const handleCreate = () => {
    resetCreate();
    setShowAddMemberModal(true);
  };

  const handleEdit = (id: number) => {
    resetEdit();
    getMember(id);
    setShowEditMemberModal(true);
  };

  const handleDelete = (id: number): void => {
    resetDelete();
    getMember(id);
    setShowConfirmDeleteModal(true);
  };

  const handleCreateMember = (values: NewMember): void => {
    setCreatedName(values.name);
    createMember(values);
  };

  const handleEditMember = ({ id, name, birthday, position }: Member): void => {
    const preparedValue = {
      id: id.toString(),
      name,
      birthday,
      position,
    };
    editMember(preparedValue);
  };

  const handleDeleteMembers = (id: number): void => {
    deleteMember({
      id: id,
    });
  };

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify(
        'Добавление участника',
        <>
          Участник <b>{createdName}</b> был добавлен
        </>,
      );
    }
    if (createError) {
      openErrorNotify('Произошла ошибка', 'При добавлении произошла ошибка');
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify(
        'Изменение участника',
        <>
          Участник <b>{selectedMember?.name}</b> был изменен
        </>,
      );
    }
    if (editError) {
      openErrorNotify('Произошла ошибка', 'При редактировании произошла ошибка');
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Удаление участника',
        <>
          Участник <b>{selectedMember?.name}</b> был удален
        </>,
      );
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении произошла ошибка');
    }
  }, [deleteSuccess, deleteError]);

  return (
    <div className={styles.wrapper}>
      {!isError && !isLoading && (
        <div className={styles.header}>
          <BlackTooltip title={'Перечень участников'} placement={'bottomLeft'}>
            <PanelTabButton
              className={styles['button-panel-help']}
              onClick={() => {}}
              type={PanelTabButtonTypes.icon}
              size={ComponentSize.small}
            >
              <Icon icon="outline-question-circle" size={14} />
            </PanelTabButton>
          </BlackTooltip>
          {isEditable ? (
            <PanelTabButton
              disabled={isError || isLoading}
              type={PanelTabButtonTypes.roundSecondary}
              size={ComponentSize.small}
              onClick={() => handleCreate()}
            >
              Добавить нового участника
            </PanelTabButton>
          ) : null}
        </div>
      )}
      {isEditable ? (
        <>
          <TableModal
            onSubmit={(value) => handleCreateMember(value as NewMember)}
            buttonText="Добавить"
            onClose={() => setShowAddMemberModal(false)}
            title="Добавление нового участника"
            validationSchema={validationSchema}
            initialValues={initialValues}
            open={showAddMemberModal}
            loading={createLoading}
            success={createSuccess}
            fields={fields}
            width={563}
          />
          <TableModal
            onSubmit={(value) => handleEditMember(value as Member)}
            buttonText="Изменить"
            onClose={() => setShowEditMemberModal(false)}
            title="Редактирование участника"
            validationSchema={validationSchema}
            initialValues={selectedMember || initialValues}
            open={showEditMemberModal}
            loading={editLoading}
            success={editSuccess}
            fields={fields}
            width={563}
          />
          <ConfirmModal
            open={showConfirmDeleteModal}
            title="Подтвердите удаление участника"
            content={
              <p>
                Вы уверены, что хотите удалить&nbsp;
                {selectedMember?.name ? (
                  <>
                    участника <strong>{selectedMember?.name}</strong>
                  </>
                ) : (
                  'выбраного участника'
                )}
                ?
              </p>
            }
            submitButtonText="Удалить участника"
            cancelButtonText="Отменить"
            type="danger"
            onSubmit={() => handleDeleteMembers(selectedMember?.id as number)}
            onCancel={() => setShowConfirmDeleteModal((prevState) => !prevState)}
            loading={deleteLoading}
            success={deleteSuccess}
          />
        </>
      ) : null}
      <MembersTable
        isEditable={isEditable}
        data={preparedMembers}
        loading={isLoading}
        error={isError}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </div>
  );
};
