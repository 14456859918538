export const CordSystemData = [
  { firstLabel: 'UTM zone 35N', secondLabel: 'EPSG:32635 WGS 84', value: '32635' },
  { firstLabel: 'UTM zone 36N', secondLabel: 'EPSG:32636 WGS 84', value: '32636' },
  { firstLabel: 'UTM zone 37N', secondLabel: 'EPSG:32637 WGS 84', value: '32637' },
  { firstLabel: 'UTM zone 38N', secondLabel: 'EPSG:32638 WGS 84', value: '32638' },
  { firstLabel: 'UTM zone 39N', secondLabel: 'EPSG:32639 WGS 84', value: '32639' },
  { firstLabel: 'UTM zone 40N', secondLabel: 'EPSG:32640 WGS 84', value: '32640' },
  { firstLabel: 'UTM zone 41N', secondLabel: 'EPSG:32641 WGS 84', value: '32641' },
  { firstLabel: 'UTM zone 42N', secondLabel: 'EPSG:32642 WGS 84', value: '32642' },
  { firstLabel: 'UTM zone 43N', secondLabel: 'EPSG:32643 WGS 84', value: '32643' },
];
