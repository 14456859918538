import React, { FC, useEffect, useState } from 'react';
import { BaseSectionPropsType, ExpeditionCardSection } from '../index';
import { ConfirmModal, Icon, PanelTableButton, Table, TableCell, TableModal } from '@/components';
import { handleSortNumber, openErrorNotify, openInfoNotify } from '@/utils';
import { ColumnsType } from 'antd/lib/table';
import { Task } from '@/types';
import styles from '../section.module.scss';
import { InputTypes } from '@/constants/enums';
import {
  useCreateExpeditionTaskMutation,
  useEditExpeditionTaskMutation,
  useDeleteExpeditionTaskMutation,
} from '@/state/api';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { findIndex } from 'lodash';

export const TasksSection: FC<BaseSectionPropsType> = ({ isEditable, item, ...rest }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState<Task>();

  const [
    createTask,
    {
      isLoading: createLoading,
      isError: createError,
      isSuccess: createSuccess,
      reset: createReset,
    },
  ] = useCreateExpeditionTaskMutation();
  const [
    editTask,
    { isLoading: editLoading, isError: editError, isSuccess: editSuccess, reset: editReset },
  ] = useEditExpeditionTaskMutation();
  const [
    deleteTask,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
      reset: deleteReset,
    },
  ] = useDeleteExpeditionTaskMutation();

  const handleSetSelectedItem = (id: number) => {
    setSelectedItem(item?.tasks?.find((item) => item.id === id));
  };

  const handleDelete = (id: number) => {
    deleteTask({ id });
  };

  const handleEdit = (value: FormikValues) => {
    const preparedValue = {
      ...value,
      expedition_id: item?.id,
    };
    editTask(preparedValue);
  };

  const handleAdd = (value: FormikValues) => {
    const preparedValue = {
      ...value,
      expedition_id: item?.id,
    };
    createTask(preparedValue);
  };

  const toggleDelete = (id: number) => {
    handleSetSelectedItem(id);
    setShowDeleteModal((prevState) => !prevState);
  };

  const toggleEdit = (id: number) => {
    handleSetSelectedItem(id);
    setShowEditModal((prevState) => !prevState);
  };

  const toggleAdd = () => {
    setShowAddModal((prevState) => !prevState);
  };

  const editableColumns: ColumnsType<Task> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      fixed: 'left',
      width: 62,
      sorter: (first, second) =>
        handleSortNumber(findIndex(item?.tasks, first), findIndex(item?.tasks, second)),
      render: (value: string, row) => <TableCell value={findIndex(item?.tasks, row) + 1} />,
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      fixed: 'left',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleEdit(id)}
          tooltipTitle="Редактировать задачу экспедиции"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      fixed: 'left',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleDelete(id)}
          tooltipTitle="Удалить задачу экспедиции"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'Задача',
      dataIndex: 'task_name',
      key: 'task_name',
      align: 'left',
      width: 280,
      render: (value: string) => <TableCell value={value} showAllText={true} />,
    },
    {
      title: 'Описание задачи',
      dataIndex: 'task_description',
      className: 'with-pre-line',
      key: 'task_description',
      align: 'left',
      render: (value: string) => <TableCell value={value} showAllText={true} />,
    },
  ];

  const readOnlyColumn = editableColumns.filter((item) => item.key !== 'icon');

  const validationSchema = Yup.object({
    task_name: Yup.string().required('Пожалуйста, укажите наименование задачи'),
    task_description: Yup.string().required('Пожалуйста, укажите описание задачи'),
  });

  const initialValues = {
    task_name: '',
    task_description: '',
  };

  const fields = [
    {
      label: 'Задача экспедиции',
      name: 'task_name',
      placeholder: 'Введите задачу экспедиции',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Описание задачи экспедиции',
      name: 'task_description',
      placeholder: 'Введите описание задачи',
      type: InputTypes.textArea,
      required: true,
    },
  ];

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify('Изменения сохранены', 'Добавление задачи экспедиции успешно выполнено');
      createReset();
    }
    if (createError) {
      openErrorNotify('Произошла ошибка', 'При добавлении задачи экспедиции произошла ошибка');
      createReset();
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify('Изменения сохранены', 'Редактирование задачи экспедиции успешно выполнено');
      editReset();
    }
    if (editError) {
      openErrorNotify('Произошла ошибка', 'При редактировании задачи экспедиции произошла ошибка');
      editReset();
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify('Изменения сохранены', 'Удаление задачи экспедиции успешно выполнено');
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении задачи экспедиции произошла ошибка');
      deleteReset();
    }
  }, [deleteSuccess, deleteError]);

  return (
    <ExpeditionCardSection
      {...rest}
      buttonText="Добавить задачу"
      onClickAdd={toggleAdd}
      isEditable={isEditable}
    >
      <>
        {item?.tasks && item?.tasks?.length > 0 && (
          <Table
            withSelection={false}
            dataSource={item?.tasks}
            columns={isEditable ? editableColumns : readOnlyColumn}
            className={styles.table}
            noDataClassName={styles['no-data']}
            scroll={{ x: 1225, y: 300 }}
            withPagination={false}
          />
        )}
        <TableModal
          onSubmit={(value) => handleAdd(value)}
          onClose={() => setShowAddModal(false)}
          validationSchema={validationSchema}
          initialValues={initialValues}
          loading={createLoading}
          success={createSuccess}
          buttonText="Добавить"
          open={showAddModal}
          fields={fields}
          width={563}
          title="Добавление задачи экспедиции"
        />
        <TableModal
          onSubmit={(value) => handleEdit(value)}
          onClose={() => setShowEditModal(false)}
          validationSchema={validationSchema}
          initialValues={selectedItem as Task}
          loading={editLoading}
          success={editSuccess}
          buttonText="Изменить"
          open={showEditModal}
          fields={fields}
          width={563}
          title="Изменение задачи экспедиции"
          additionalTitle={
            <div className={styles['additional-title']}>{selectedItem?.task_name}</div>
          }
        />
        <ConfirmModal
          open={showDeleteModal}
          title="Подтвердите удаление задачи экспедиции"
          content={
            <p>
              Вы уверены, что хотите удалить&nbsp;
              {selectedItem?.task_name ? (
                <strong>{selectedItem?.task_name}</strong>
              ) : (
                'выбранную задачу экспедиции'
              )}
              ?
            </p>
          }
          submitButtonText="Удалить"
          cancelButtonText="отменить"
          onSubmit={() => selectedItem && handleDelete(selectedItem?.id)}
          onCancel={() => setShowDeleteModal(false)}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
      </>
    </ExpeditionCardSection>
  );
};
