import React, { FC, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useAppSelector } from '@/state';
import { useMap } from '@/context';
import { IObject } from '@/types';
import { toggleSelectedLayer } from '@/state/slice';
import { ALL_OBJECT_LAYERS, LINK_TO_OBJECT_FORMULAR } from '@/constants';
import { useGetObjectsQuery } from '@/state/api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SearchPanelPropsType } from './search-panel.types';
import { Collapse, InputRef } from 'antd';
import styles from '@/components/panels/search-panel/search-panel.module.scss';
import { SearchPanelHeader } from '@/components/panels/search-panel/header/search-panel-header';
import { SearchPanelList } from '@/components/panels/search-panel/list/search-panel-list';
import { useOnClickOutside } from 'usehooks-ts';

const { Panel } = Collapse;

export const SearchPanel: FC<SearchPanelPropsType> = ({
  expeditionId,
  withFormularNavigation = true,
}): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setOpen] = useState(false);

  const navigate = useNavigate();
  const inputRef = useRef<InputRef | null>(null);
  const dispatch = useDispatch();
  const { map } = useMap();

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => setOpen(false));

  const {
    map: { selectedLayers },
  } = useAppSelector((state) => state);

  const { data, error, isLoading } = useGetObjectsQuery(
    expeditionId ? { expId: expeditionId } : undefined,
  );

  const handleClear = () => {
    setSearchValue('');
  };

  const handleChange = (value?: string) => {
    const preparedValue = value || '';
    setSearchValue(preparedValue);
  };

  const handleClick = (visible: boolean) => {
    setOpen(visible);
    if (visible) {
      inputRef?.current?.focus();
    }
  };

  const navigateToObject = (lat: string, lng: string, id: number): void => {
    const coordinates = {
      lat: Number(lat),
      lng: withFormularNavigation ? Number(lng) - 0.04 : Number(lng),
    };
    map?.flyTo(coordinates, 14);
    withFormularNavigation && navigate(LINK_TO_OBJECT_FORMULAR(id, expeditionId));
  };

  const handleToggleLayers = (selectedLayers: Array<string>) => {
    ALL_OBJECT_LAYERS.forEach((item: string): void => {
      const needActiveLayer = !selectedLayers.includes(item);
      if (!!searchValue && needActiveLayer) {
        dispatch(toggleSelectedLayer(item));
      }
    });
  };

  const handleSelect = (
    objectID: number | undefined,
    objects: IObject[],
    selectedLayers: string[],
  ): void => {
    let object;

    if (objectID) {
      object = objects?.find((item) => item?.id === objectID);
    } else {
      object = objects?.[0];
    }

    if (object) {
      const { lat, lng, id } = object;
      handleToggleLayers(selectedLayers);
      navigateToObject(lat, lng, id);
      handleClear();
    }
  };

  const handleFilterOptions = (searchValue: string, objectID: number, objects: IObject[]) => {
    const object = objects.find((item) => item?.id === objectID);
    if (!searchValue || !object) {
      return true;
    }

    const { name, identifier } = object;
    const isValidByName = name.includes(searchValue);
    const isValidByIdentifier = identifier.includes(searchValue);

    return isValidByName || isValidByIdentifier;
  };

  const objects = useMemo(() => {
    const objects = data?.objects || [];

    if (!searchValue) {
      return objects;
    }

    return objects.filter((item: IObject) => handleFilterOptions(searchValue, item.id, objects));
  }, [searchValue, data]);

  return (
    <div ref={ref}>
      <Collapse
        activeKey={Number(isOpen)}
        className={`${styles.wrapper} ${isOpen ? styles.open : ''}}`}
        accordion={true}
      >
        <Panel
          key={1}
          className={styles.content}
          showArrow={false}
          header={
            <SearchPanelHeader
              isOpen={isOpen}
              inputRef={inputRef}
              loading={isLoading}
              searchValue={searchValue}
              onChange={handleChange}
              onClear={handleClear}
              onClick={handleClick}
              onSearch={() => handleSelect(undefined, objects, selectedLayers)}
            />
          }
        >
          <SearchPanelList
            data={objects}
            searchValue={searchValue}
            loading={isLoading}
            error={error as FetchBaseQueryError}
            onSearch={(objectID) => handleSelect(objectID, objects, selectedLayers)}
          />
        </Panel>
      </Collapse>
    </div>
  );
};
