import { FC, useEffect, useRef, useState } from 'react';
import { CoordsInputPropsType } from './polygon-tool-panel.types';
import styles from './polygon-tool-panel.module.scss';
import { convertToGraduatedCord, decimalCoordsValidator, graduatedCoordsValidator } from '@/utils';
import { Popover } from 'antd';
import { CoordsTypes } from '@/constants/enums';

export const CoordsInput: FC<CoordsInputPropsType> = ({
  value,
  onChange,
  isLat = false,
  coordsType,
}) => {
  const [coord, setCoord] = useState(value);
  const [isError, setIsError] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCoord(value);
    isError && setIsError(false);
  }, [value, coordsType]);

  const handleChange = (value: string) => {
    const isValidDec = decimalCoordsValidator(value);
    const isValidDeg = graduatedCoordsValidator(value, isLat);
    if ((isValidDec || isValidDeg) && coordsType === CoordsTypes.decimal) {
      onChange(isValidDeg ? convertToGraduatedCord(value) : value);
      setIsError(false);
    } else if ((isValidDec || isValidDeg) && coordsType === CoordsTypes.graduated) {
      setIsError(false);
    } else {
      setIsError(true);
    }
    setCoord(value);
  };

  useEffect(() => {
    if (isError) {
      ref.current?.focus();
    }
    return () => {
      ref.current?.focus();
    };
  }, [isError]);

  return (
    <>
      {isError ? (
        <Popover
          content={<div>Формат координат должен быть ввида {'xxx.xxx или xx°xx\'xx.xx"N'}</div>}
          overlayClassName={styles['error-message']}
          showArrow={false}
          placement={'bottom'}
          open={isError}
        >
          <input
            onChange={(e) => handleChange(e.target.value)}
            className={isError ? styles['row-input'] + ' ' + styles.error : styles['row-input']}
            value={coord}
            ref={ref}
            onBlur={() =>
              (decimalCoordsValidator(coord) || graduatedCoordsValidator(coord, isLat)) &&
              onChange(
                graduatedCoordsValidator(coord, isLat) ? convertToGraduatedCord(coord) : coord,
              )
            }
            placeholder={'xxx.xxx / xx°xx\'xx.xx"N'}
          ></input>
        </Popover>
      ) : (
        <input
          onChange={(e) => handleChange(e.target.value)}
          className={isError ? styles['row-input'] + ' ' + styles.error : styles['row-input']}
          value={coord}
          ref={ref}
          onBlur={() =>
            (decimalCoordsValidator(coord) || graduatedCoordsValidator(coord, isLat)) &&
            onChange(graduatedCoordsValidator(coord, isLat) ? convertToGraduatedCord(coord) : coord)
          }
          placeholder={'xxx.xxx / xx°xx\'xx.xx"N'}
        ></input>
      )}
    </>
  );
};
