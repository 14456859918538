import { Polygon } from 'geojson';
import { LatLngExpression } from 'leaflet';
import { api } from '../api';
import { DefaultResponse, EditWaterArea, IWaterArea, NewWaterArea } from '@/types';
import { parsePolygonToWKT } from '@/utils';

const transformWaterArea = (res: {
  water_areas: Array<{
    id: number;
    name: string;
    description: string | null;
    created_at: string | null;
    polygon: string;
    center: string;
  }>;
  success: true;
}): IWaterArea[] =>
  res.water_areas.map((waterArea) => {
    const splitCenter = waterArea.center
      ? waterArea.center.slice(6, waterArea.center.length - 7).split(' ')
      : ['0', '0'];
    const parsedPolygon: LatLngExpression[][][] = [
      [(JSON.parse(waterArea.polygon) as Polygon).coordinates[0].map((cord) => [cord[0], cord[1]])],
    ];

    return {
      id: waterArea.id,
      name: waterArea.name,
      description: waterArea.description as string,
      created_at: waterArea.created_at as string,
      coordinates: [parseFloat(splitCenter[1]), parseFloat(splitCenter[0])],
      polygon: parsedPolygon,
    };
  });

export const waterAreasApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWaterAreas: builder.query<IWaterArea[], { expId: number } | void>({
      query() {
        return {
          url: '/water_area/list',
          method: 'GET',
          credentials: 'include',
        };
      },
      transformResponse: transformWaterArea,
      providesTags: ['WaterAreas'],
    }),
    createWaterArea: builder.mutation<DefaultResponse, NewWaterArea>({
      query(body) {
        const polygon = parsePolygonToWKT(body.polygon);

        return {
          url: '/water_area',
          method: 'POST',
          body: { ...body, polygon: polygon },
          credentials: 'include',
        };
      },
      invalidatesTags: ['WaterAreas'],
    }),
    editWaterArea: builder.mutation<DefaultResponse, EditWaterArea>({
      query(body) {
        const polygon = parsePolygonToWKT(body.polygon);

        return {
          url: '/water_area',
          method: 'PUT',
          body: { ...body, polygon: polygon },
          credentials: 'include',
        };
      },
      invalidatesTags: ['WaterAreas'],
    }),
    deleteWaterArea: builder.mutation<
      DefaultResponse,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/water_area',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['WaterAreas'],
    }),
  }),
});

export const {
  useGetWaterAreasQuery,
  useCreateWaterAreaMutation,
  useEditWaterAreaMutation,
  useDeleteWaterAreaMutation,
} = waterAreasApi;
