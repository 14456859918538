import React, { FC, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Link } from '@/types';
import styles from '../../section.module.scss';
import { FormikValues } from 'formik';
import * as Yup from 'yup';
import { InputTypes } from '@/constants/enums';
import {
  useCreateExpeditionLinksMutation,
  useDeleteExpeditionLinksMutation,
  useEditExpeditionLinksMutation,
} from '@/state/api';
import { handleSortNumber, handleSortString, openErrorNotify, openInfoNotify } from '@/utils';
import {
  ConfirmModal,
  defaultSearchProps,
  Icon,
  PanelTableButton,
  Table,
  TableCell,
  TableModal,
  BaseSectionPropsType,
  ExpeditionCardSection,
} from '@/components';

export const LinksAttachments: FC<BaseSectionPropsType> = ({ item, isEditable, label }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState<Link>();

  const [
    createLink,
    {
      isLoading: createLoading,
      isError: createError,
      isSuccess: createSuccess,
      reset: createReset,
    },
  ] = useCreateExpeditionLinksMutation();

  const [
    editLink,
    { isLoading: editLoading, isError: editError, isSuccess: editSuccess, reset: editReset },
  ] = useEditExpeditionLinksMutation();

  const [
    deleteLink,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
      reset: deleteReset,
    },
  ] = useDeleteExpeditionLinksMutation();

  const handleSetSelectedItem = (id: number) => {
    setSelectedItem(item?.expedition_links?.find((item) => item.id === id));
  };

  const toggleAdd = () => setShowAddModal(true);

  const toggleEdit = (id: number) => {
    handleSetSelectedItem(id);
    setShowEditModal(true);
  };

  const toggleDelete = (id: number) => {
    handleSetSelectedItem(id);
    setShowDeleteModal(true);
  };

  const handleAdd = (value: FormikValues) => {
    createLink({
      expedition_id: item?.id as number,
      name: value.name as string,
      link: value.link as string,
    });
  };

  const handleEdit = (value: FormikValues) => {
    editLink({
      id: selectedItem?.id as number,
      expedition_id: item?.id as number,
      name: value.name as string,
      link: value.link as string,
    });
  };

  const handleDelete = () => {
    deleteLink({
      id: selectedItem?.id as number,
    });
  };

  const initialValues = {
    expedition_id: item?.id,
    name: '',
    link: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Пожалуйста, укажите наименование ссылки'),
    link: Yup.string().required('Пожалуйста, укажите ссылку'),
  });

  const fields = [
    {
      label: 'Наименование ссылки',
      name: 'name',
      placeholder: 'Введите наименование ссылки',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Ссылка',
      name: 'link',
      placeholder: 'Введите ссылку',
      type: InputTypes.text,
      required: true,
    },
  ];

  const columns: ColumnsType<Link> = [
    {
      title: '№',
      align: 'left',
      fixed: 'left',
      dataIndex: 'id',
      width: 60,
      sorter: (first, second, sortOrder) => handleSortNumber(first?.id, second?.id, sortOrder),
      render: (value: string) => <TableCell value={value} />,
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleEdit(id)}
          tooltipTitle="Редактировать ссылку"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleDelete(id)}
          tooltipTitle="Удалить ссылку"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'Наименование ссылки',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (value: string) => <TableCell value={value} showAllText={true} />,
      sorter: (first, second, sortOrder) => handleSortString(first?.name, second?.name, sortOrder),
      ...defaultSearchProps('name'),
    },
    {
      title: 'Ссылка',
      dataIndex: 'link',
      key: 'link',
      align: 'left',
      render: (value: string) => <a href={value}>{value}</a>,
      sorter: (first, second, sortOrder) => handleSortString(first?.link, second?.link, sortOrder),
      ...defaultSearchProps('link'),
    },
  ];

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify('Добавления ссылки', 'Добавления сслыки успешно выполнено');
      createReset();
    }
    if (createError) {
      openErrorNotify('Ошибка добавления ссылки', 'При добавления сслыки произошла ошибка');
      createReset();
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify('Изменения ссылки', 'Изменения ссылки успешно выполнено');
      editReset();
    }
    if (editError) {
      openErrorNotify('Ошибка изменения ссылки', 'При изменения ссылки произошла ошибка');
      editReset();
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify('Удаления ссылки', 'Удаление ссылки успешно выполнено');
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify('Ошибка удаления ссылки', 'При удаления ссылки произошла ошибка');
      deleteReset();
    }
  }, [deleteSuccess, deleteError]);

  const preparedColumns = isEditable ? columns : columns.filter((column) => column.key !== 'icon');

  return (
    <ExpeditionCardSection
      isEditable={isEditable}
      labelCustom={<h5 className={styles.label}>{label}</h5>}
      buttonText={'Добавить ссылку'}
      icon={'outline-link-45deg'}
      onClickAdd={toggleAdd}
    >
      <>
        {item?.expedition_links && item?.expedition_links?.length > 0 && (
          <Table
            dataSource={item?.expedition_links}
            columns={preparedColumns}
            className={styles.table}
            scroll={{ x: 1225, y: 300 }}
            withSelection={false}
            withPagination={false}
          />
        )}
        <TableModal
          onSubmit={(value) => handleAdd(value)}
          onClose={() => setShowAddModal(false)}
          initialValues={initialValues}
          validationSchema={validationSchema}
          loading={!!createLoading}
          success={!!createSuccess}
          buttonText="Добавить"
          open={showAddModal}
          fields={fields}
          width={563}
          title="Добавление ссылки"
        />
        <TableModal
          onSubmit={(value) => handleEdit(value)}
          onClose={() => setShowEditModal(false)}
          initialValues={selectedItem || initialValues}
          validationSchema={validationSchema}
          loading={!!editLoading}
          success={!!editSuccess}
          buttonText="Изменить"
          open={showEditModal}
          fields={fields}
          width={563}
          title="Изменение ссылки"
        />
        <ConfirmModal
          open={showDeleteModal}
          title="Подтвердите удаление ссылки"
          content={
            <p>
              Вы уверены, что хотите удалить ссылку <b>{selectedItem?.name}</b>?
            </p>
          }
          submitButtonText="Удалить"
          cancelButtonText="отменить"
          onSubmit={() => {
            selectedItem && handleDelete();
          }}
          onCancel={() => setShowDeleteModal(false)}
          loading={!!deleteLoading}
          success={!!deleteSuccess}
          type="danger"
        />
      </>
    </ExpeditionCardSection>
  );
};
