import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Polygon } from 'react-leaflet';
import { SectorPolygonPropsType } from './sector-polygon.types';
import { MapTooltip } from '../map-tooltip';
import { MapPopup } from '../map-popup';
import { useAppSelector } from '@/state';
import { matchPath, useLocation, useParams } from 'react-router';
import {
  CREATE_SECTOR_PAGE,
  EXPEDITION_STEP_PAGE,
  LINK_TO_DISTANCE,
  LINK_TO_GALSES,
  LINK_TO_LOG,
  LINK_TO_OBJECT_CREATE,
  LINK_TO_Z_CORD,
  POLYGON_TOOL,
} from '@/constants';
import { PathOptions, Polygon as LP } from 'leaflet';
import { toggleSectorInSelectedIds } from '@/state/slice';
import { useDispatch } from 'react-redux';

export const SectorPolygon: FC<SectorPolygonPropsType> = ({ item }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const ref = useRef<LP>(null);
  const { id } = useParams();

  const isHidden =
    !!matchPath(POLYGON_TOOL + '/sector/*', location.pathname) && id && Number(id) === item.id;

  const { selectedSectors } = useAppSelector((state) => state.sectors);
  const isSecondary =
    !!matchPath(CREATE_SECTOR_PAGE + '/*', location.pathname) ||
    !!matchPath(EXPEDITION_STEP_PAGE + '/object/*', location.pathname) ||
    (!!matchPath(EXPEDITION_STEP_PAGE + '/sector/*', location.pathname) &&
      !selectedSectors.includes(item?.id)) ||
    !!matchPath(POLYGON_TOOL + '/*', location.pathname);
  const isNonInteractive =
    !!matchPath(POLYGON_TOOL + '/*', location.pathname) ||
    !!matchPath(LINK_TO_Z_CORD, location.pathname) ||
    !!matchPath(LINK_TO_LOG, location.pathname) ||
    !!matchPath(LINK_TO_GALSES, location.pathname) ||
    !!matchPath(LINK_TO_DISTANCE, location.pathname) ||
    !!matchPath(LINK_TO_OBJECT_CREATE, location.pathname) ||
    !!matchPath(EXPEDITION_STEP_PAGE + '/object/*', location.pathname) ||
    !!matchPath(CREATE_SECTOR_PAGE + '/*', location.pathname);
  const polygonFillOpacity = isSecondary ? 0.08 : 0.15;
  const withoutTooltip = matchPath(EXPEDITION_STEP_PAGE + '/sector/*', location.pathname);
  const polygonFillColor = '#D91172';
  const polygonColor = '#D91172';
  const weight = isSecondary ? 1 : 2;

  const isOpenSectorTooltip = useAppSelector((state) => state.sectors.isOpenSectorTooltip);

  const pathOptions: PathOptions = {
    fillOpacity: polygonFillOpacity,
    fillColor: polygonFillColor,
    color: polygonColor,
    weight: weight,
    lineJoin: 'round',
    lineCap: 'round',
  };

  const eventHandlers = {
    click: () => {
      dispatch(toggleSectorInSelectedIds(item?.id));
    },
  };

  useEffect(() => {
    if (isNonInteractive) {
      ref.current?.getElement()?.classList.add('inherit-cursor');
    } else {
      ref.current?.getElement()?.classList.remove('inherit-cursor');
    }
  }, [isNonInteractive]);

  return useMemo(() => {
    return isHidden ? null : (
      <Polygon
        key={item.id}
        positions={item.polygon}
        ref={ref}
        interactive={!isNonInteractive}
        pathOptions={pathOptions}
        eventHandlers={eventHandlers}
      >
        {isOpenSectorTooltip && (
          <MapTooltip isSecondary={isSecondary} typeOfGeometry={'sector'} {...item} />
        )}
        {!isNonInteractive && !withoutTooltip ? (
          <MapPopup typeOfGeometry={'sector'} {...item} />
        ) : null}
      </Polygon>
    );
  }, [
    item,
    isOpenSectorTooltip,
    pathOptions,
    selectedSectors,
    isNonInteractive,
    isSecondary,
    isHidden,
  ]);
};
