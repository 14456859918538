import { api } from '../api';

type RequestBodyType = {
  x?: number;
  y?: number;
  success?: boolean;
  zcoord?: string;
};
type ResponseType = {
  x?: number;
  y?: number;
};

export const zCoordsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getZCoords: builder.mutation<RequestBodyType, ResponseType>({
      query(point) {
        return {
          url: '/layer/deepCoord',
          method: 'GET',
          credentials: 'include',
          params: { x: point.x, y: point.y },
        };
      },
    }),
  }),
});

export const { useGetZCoordsMutation } = zCoordsApi;
