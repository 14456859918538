import { MAX_ZOOM, MIN_ZOOM } from '@/constants';
import { WMS_TOPOMAPS_URL } from '@/constants/URL/URL';
import { useAppSelector } from '@/state';
import React, { FC, useMemo } from 'react';
import { WMSTileLayer } from 'react-leaflet';
import { TopomapLayerPropsType } from './topomap-layer.types';
import L, { LatLngExpression, Polygon } from 'leaflet';

export const TopomapLayer: FC<TopomapLayerPropsType> = ({ header, ns }) => {
  const rasterLayer = `${ns}:${header}`;
  const zIndex = 291;
  const selectedLayers = useAppSelector((state) => state.map.selectedLayers);
  const userInfo = useAppSelector((state) => state.auth.user);
  const accessAreaGeometry = userInfo?.role?.access_area?.restricted_area.coordinates;
  const restrictedAreaBounds = accessAreaGeometry
    ? new Polygon(accessAreaGeometry as LatLngExpression[][]).getBounds()
    : undefined;

  return useMemo(
    () => (
      <>
        {selectedLayers.includes(header) ? (
          <WMSTileLayer
            url={WMS_TOPOMAPS_URL}
            zIndex={zIndex}
            bounds={restrictedAreaBounds}
            crs={L.CRS.EPSG900913}
            maxZoom={MAX_ZOOM}
            minZoom={MIN_ZOOM}
            params={{
              layers: rasterLayer,
              service: 'WMS',
              version: '1.3.0',
              format: 'image/png',
              transparent: true,
            }}
          />
        ) : null}
      </>
    ),
    [selectedLayers.includes(header)],
  );
};
