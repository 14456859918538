import React from 'react';
import styles from '../meta-data.module.scss';
import { CloseButton } from '@/components';
import { setCollectMetaLog, setIsMetaLogOpenTooltip } from '@/state/slice';
import { useDispatch } from 'react-redux';
import { ITooltipMetaDataLog } from './log.types';
import { useAppSelector } from '@/state';
import dayjs from 'dayjs';

export const Log = ({ project, collectLatLng, setOpen }: ITooltipMetaDataLog) => {
  const dispatch = useDispatch();
  const collectMetaLog = useAppSelector((state) => state.toolsPanels.metaLogPanel.collectMetaLog);

  const getClose = () => {
    setOpen(false);
    dispatch(setIsMetaLogOpenTooltip(false));
    const collectMetaLogLatLng = collectMetaLog.filter(
      (item) => item.collectLatLng !== collectLatLng,
    );
    setCollectMetaLog(collectMetaLogLatLng);
  };

  return (
    <>
      <div className={styles.title}>
        <p className={styles.bold}>Метаданные лога дна</p>
        <CloseButton onClick={getClose} className={styles.button} />
      </div>
      <div className={styles.body}>
        {project.id && (
          <p className={styles.text}>
            <span className={styles.other}>ID лога:</span>
            {project.id}
          </p>
        )}
        {project.path && (
          <div className={styles.block}>
            <p className={styles.other}>Наименование:</p>
            <p className={styles.path}>{project.path}</p>
          </div>
        )}
        {project.expedition && (
          <p className={styles.text}>
            <span className={styles.other}>Экспедиция:</span>
            {project.expedition}
          </p>
        )}
        {project.date_shoot && (
          <p className={styles.text}>
            <span className={styles.other}>Дата съемки:</span>
            {dayjs(project.date_shoot, { utc: true }).isValid()
              ? dayjs(project.date_shoot, { utc: true }).format('DD.MM.YYYY')
              : project.date_shoot}
          </p>
        )}
        {!project.id && !project.path && !project.expedition && !project.date_shoot && (
          <p className={styles.none}>Метаданных не найдено</p>
        )}
      </div>
    </>
  );
};
