import React, { FC, useEffect, useRef, useState } from 'react';
import { Canvas, Vector3 } from '@react-three/fiber';
import {
  OrbitControls,
  Stats,
  PerspectiveCamera,
  GizmoHelper,
  GizmoViewport,
  Environment,
} from '@react-three/drei';
import styles from './3d-view.module.scss';
import { Header, LogModel, ObjectModel } from './components';
import { useNavigate, useParams } from 'react-router';
import { useHistoryBackstack } from '@/context';
import { MAP } from '@/constants';
import { Leva, useControls } from 'leva';
import type { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { Targets3DTypes } from '@/constants/enums';
import { OrthographicCamera } from 'three';
import hdrBackground from '@/assets/img/background-3d.hdr';

export const View3D: FC = () => {
  const cameraRef = useRef<OrthographicCamera>();
  const [isAutoRotate, setIsAutoRotate] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { type: paramType, id: paramId } = useParams();
  const type = paramType as Targets3DTypes;
  const [name, setName] = useState('');
  const [cameraPosition, setCameraPosition] = useState<Vector3>([0, 20, 27] as Vector3);
  const [isInBounds, setIsInBounds] = useState(false);
  const [autoRotateSpeed, setAutoRotateSpeed] = useState(5);
  const id = Number(paramId);
  const [position, setPosition] = useState<{ x?: number; y?: number }>({ x: -20, y: 50 });
  const { backstack } = useHistoryBackstack();
  const [intensity, setIntensity] = useState(type === Targets3DTypes.bathymetry ? 0.3 : 10);
  const maxIntensity = type === Targets3DTypes.bathymetry ? 1 : 40;
  const minIntensity = type === Targets3DTypes.bathymetry ? 0.1 : 1;
  const maxAutoRotateSpeed = 10;
  const minAutoRotateSpeed = 1;
  const orbitControlsRef = useRef<OrbitControlsImpl>(null);

  const [, setValues] = useControls('Настройки отображения', () => ({
    intensity: {
      value: intensity,
      onChange: (value: number) => setIntensity(value),
      max: maxIntensity,
      min: minIntensity,
      label: 'Яркость',
    },
    speed: {
      value: autoRotateSpeed,
      onChange: (value: number) => setAutoRotateSpeed(value),
      max: maxAutoRotateSpeed,
      min: minAutoRotateSpeed,
      label: 'Скорость автовращения',
    },
  }));

  useEffect(() => {
    setValues({ intensity: type === Targets3DTypes.bathymetry ? 0.3 : 10, speed: 5 });
    return () => {
      setValues({ intensity: type === Targets3DTypes.bathymetry ? 0.3 : 10, speed: 5 });
    };
  }, []);

  const handleClose = () => {
    navigate(backstack.length >= 2 ? backstack[backstack.length - 2] : MAP);
  };

  const handleReset = () => {
    orbitControlsRef.current?.reset();
  };

  useEffect(() => {
    cameraRef.current?.position && setCameraPosition(cameraRef.current?.position);
  }, [isInBounds]);

  return (
    <>
      <div className={styles.wrapper} ref={wrapperRef}>
        <Header
          onClose={() => handleClose()}
          onAutoRotate={() => setIsAutoRotate((prev) => !prev)}
          isAutoRotate={isAutoRotate}
          onReset={() => handleReset()}
          title={name}
        />
        <Leva
          flat
          titleBar={{
            filter: false,
            position: position,
            onDrag: (position) => setPosition(position),
          }}
        />
        <Canvas className={styles.canvas} shadows>
          <PerspectiveCamera ref={cameraRef} makeDefault position={cameraPosition} />

          {type === Targets3DTypes.object ? (
            <ObjectModel
              id={id}
              setName={(value) => setName(value)}
              intensity={intensity}
              onFitBounds={() => setIsInBounds(true)}
            />
          ) : null}
          {type === Targets3DTypes.bathymetry ? (
            <LogModel
              id={id}
              setName={(value) => setName(value)}
              intensity={intensity}
              onFitBounds={() => setIsInBounds(true)}
            />
          ) : null}
          <OrbitControls
            makeDefault
            ref={orbitControlsRef}
            camera={cameraRef.current}
            autoRotate={isAutoRotate}
            autoRotateSpeed={autoRotateSpeed}
            position0={cameraPosition as THREE.Vector3}
          />
          <Environment background files={hdrBackground} blur={0.5} />
          <GizmoHelper alignment="bottom-right" margin={[100, 100]}>
            <GizmoViewport labelColor="white" axisHeadScale={1} />
          </GizmoHelper>
          <Stats parent={wrapperRef} />
        </Canvas>
      </div>
    </>
  );
};
