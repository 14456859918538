import React, { FC } from 'react';
import { GalsTableDefaultPropsType } from './gals-table-types';
import { ColumnsType } from 'antd/lib/table';
import { Gals } from '@/types';
import { handleDateSort, handleSortNumber, handleSortString } from '@/utils';
import {
  defaultDataPickerProps,
  defaultSearchProps,
  Icon,
  PanelTableButton,
  Table,
  TableCell,
  TableFilter,
} from '@/components';
import { Polygon } from 'geojson';
import { LatLngExpression, Polygon as LP } from 'leaflet';
import { GALSES_LAYER, MAP } from '@/constants';
import { useMap } from '@/context';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setSelectedLayer } from '@/state/slice';

export const GalsDefaultTable: FC<GalsTableDefaultPropsType> = ({
  loading,
  error,
  isEditable,
  data,
  onClickDeleteBtn,
}): JSX.Element => {
  const { map } = useMap();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFlyTo = (value: string) => {
    if (value) {
      const parsedPolygon: LatLngExpression[][][] = [
        [(JSON.parse(value) as Polygon).coordinates[0].map((cord) => [cord[0], cord[1]])],
      ];
      const polygon = new LP(parsedPolygon);
      navigate(MAP);
      map?.flyToBounds(polygon.getBounds(), { maxZoom: 18 });
      dispatch(setSelectedLayer(GALSES_LAYER));
    }
  };

  const columns: ColumnsType<Gals> = [
    {
      title: <Icon size={12} icon="outline-geo-alt" />,
      width: 40,
      dataIndex: 'bounds',
      fixed: 'left',
      align: 'center',
      render: (value: string) => (
        <PanelTableButton
          onClick={() => handleFlyTo(value)}
          tooltipTitle="Приблизиться к галсу"
          tooltipPlacement="bottom"
          icon="outline-geo-alt"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      dataIndex: 'id',
      fixed: 'left',
      key: 'editable',
      width: 40,
      align: 'center',
      render: (value: number, row) => (
        <PanelTableButton
          onClick={() => onClickDeleteBtn(row)}
          tooltipTitle="Удалить галс"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      fixed: 'left',
      width: 110,
      className: 'text-center',
      sorter: (first, second, sortOrder) =>
        handleSortNumber(first?.status, second?.status, sortOrder),
      filterDropdown: ({ filters, clearFilters, confirm, setSelectedKeys, selectedKeys }) => (
        <TableFilter
          filters={filters}
          onFilter={confirm}
          onClear={clearFilters}
          selectedKeys={selectedKeys}
          onChange={setSelectedKeys}
        />
      ),
      onFilter: (value, record) => record.status === value,
      filters: [
        {
          text: 'Обработан',
          value: 1,
        },
        {
          text: 'В обработке',
          value: 2,
        },
      ],
      render: (value: number) => {
        switch (value) {
          case 1:
            return (
              <PanelTableButton
                showTooltip={true}
                tooltipTitle="Обработан"
                icon="outline-status-confirmed"
                size={12}
                tooltipPlacement="bottom"
              />
            );
          case 2:
            return (
              <PanelTableButton
                showTooltip={true}
                tooltipTitle="В процессе обработки"
                icon="outline-status-planned"
                size={12}
                tooltipPlacement="bottom"
              />
            );
          case 3:
            return (
              <PanelTableButton
                showTooltip={true}
                tooltipTitle=""
                icon="outline-status-error"
                size={12}
                tooltipPlacement="bottom"
              />
            );
        }
      },
    },
    {
      title: 'Наименование лога галса',
      align: 'left',
      dataIndex: 'file_path',
      key: 'file_path',
      sorter: (first, second, sortOrder) =>
        handleSortString(first?.file_path, second?.file_path, sortOrder),
      render: (value: string) => <TableCell value={value} maxWidth={700} />,
      ...defaultSearchProps('file_path'),
    },
    {
      title: 'Файл psd',
      align: 'left',
      dataIndex: 'psd_path',
      key: 'psd_path',
      sorter: (first, second, sortOrder) =>
        handleSortString(first?.psd_path, second?.psd_path, sortOrder),
      render: (value: string) => <TableCell value={value} maxWidth={700} />,
      ...defaultSearchProps('psd_path'),
    },
    {
      title: 'Добавлено',
      align: 'left',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (first, second, sortOrder) =>
        handleDateSort(first?.created_at, second?.created_at, sortOrder),
      render: (value: string) => <TableCell value={value} maxWidth={650} />,
      ...defaultDataPickerProps('created_at'),
    },
  ];

  const preparedColumns = isEditable ? columns : columns.filter((item) => item.key !== 'editable');

  return (
    <div>
      <Table
        dataSource={data}
        columns={preparedColumns}
        loading={loading}
        error={error}
        withSelection={false}
      />
    </div>
  );
};
