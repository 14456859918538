import React, { FC, useMemo } from 'react';
import { Polygon, Tooltip } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import { UtmBorderLayerPropsType } from './utm-border-layer.types';
import styles from './utm-border-layer.module.scss';
import { MAP } from '@/constants';
import { useLocation, matchPath } from 'react-router';

export const UtmBorder: FC<UtmBorderLayerPropsType> = ({ data }) => {
  const positions: LatLngExpression[][][] = [
    [data.geometry.coordinates[0][0].map((cord) => [cord[1], cord[0]])],
  ];

  const location = useLocation();
  const isNonInteractive = !!matchPath(MAP, location.pathname);

  return useMemo(
    () => (
      <Polygon
        className={styles.polygon}
        interactive={!isNonInteractive}
        pathOptions={{
          fillColor: data.properties?.style.fillColor as string,
          fillOpacity: data.properties?.style.fillOpacity as number,
          lineJoin: 'round',
          lineCap: 'round',
          color: '#3388ff',
        }}
        positions={positions}
      >
        <Tooltip className={styles.tooltip} permanent>
          {`${data.properties?.ZONE as number} ${data.properties?.ROW_ as string}`}
        </Tooltip>
      </Polygon>
    ),
    [data, isNonInteractive],
  );
};
