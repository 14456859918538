import React, { FC } from 'react';
import styles from './spinner.module.scss';
import { SpinnerPropsTypes } from './spinner.types';

export const Spinner: FC<SpinnerPropsTypes> = ({ className = '' }) => {
  const customStyles = [className, styles.wrapper].join(' ');

  return (
    <div className={customStyles}>
      <svg className={styles.spinner} viewBox="0 0 50 50">
        <circle className={styles.path} cx="25" cy="25" r="20" fill="none" />
      </svg>
    </div>
  );
};
