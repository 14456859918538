import { FieldType, Icon, TableModal } from '@/components';
import { ExpeditionStatuses, ExpeditionStatusesType } from '@/constants/enums';
import { useChangeExpeditionStatusMutation } from '@/state/api';
import { ExpeditionStatusChangeFormType } from '@/types';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styles from './status-changer.module.scss';
import * as Yup from 'yup';
import { StatusChangerPropsType } from './status-changer.types';
import { openErrorNotify, openInfoNotify } from '@/utils';
import { LINK_TO_EXPEDITION_PANEL } from '@/constants';

export const getLinkByStatus = (status: ExpeditionStatusesType) => {
  switch (status) {
    case ExpeditionStatuses.plan:
      return 'Перевести в карточку экспедиции';
    case ExpeditionStatuses.card:
      return 'Перевести в отчет экспедиции';
    case ExpeditionStatuses.report:
      return 'Утвердить отчет';
    case ExpeditionStatuses.approved:
      return 'Отчет экспедиции';
    default:
      return '';
  }
};

export const getTextByStatus = (status: ExpeditionStatusesType) => {
  switch (status) {
    case ExpeditionStatuses.plan:
      return 'План экспедиции';
    case ExpeditionStatuses.card:
      return 'Карточка экспедиции';
    case ExpeditionStatuses.report:
      return 'Отчет экспедиции';
    case ExpeditionStatuses.approved:
      return 'Утвержденная экспедиция';
    default:
      return '';
  }
};

export const getNotifySuccessTextByStatus = (status?: ExpeditionStatusesType) => {
  switch (status) {
    case ExpeditionStatuses.plan:
      return 'Экспедиция успешно переведена в карточку';
    case ExpeditionStatuses.card:
      return 'Экспедиция успешно переведена в отчет';
    case ExpeditionStatuses.report:
      return 'Экспедиция утверждена';
    case ExpeditionStatuses.approved:
      return 'Экспедиция успешно переведена в отчет';
    default:
      return '';
  }
};

export const getNotifyErrorTextByStatus = (status?: ExpeditionStatusesType) => {
  switch (status) {
    case ExpeditionStatuses.plan:
      return 'Ошибка при переводе экспедиции в карточку';
    case ExpeditionStatuses.card:
      return 'Ошибка при переводе экспедиции в отчет';
    case ExpeditionStatuses.report:
      return 'Ошибка при утверждении экспедиции';
    case ExpeditionStatuses.approved:
      return 'Ошибка при переводе экспедиции в отчет';
    default:
      return '';
  }
};

export const StatusChanger: FC<StatusChangerPropsType> = ({ item, isEditable }) => {
  const [changeStatus, { isLoading, data, isError, isSuccess, reset }] =
    useChangeExpeditionStatusMutation();
  const { status: paramStatus } = useParams();

  const navigate = useNavigate();

  const [isPlannedModalOpen, setIsPlannedModalOpen] = useState(false);
  const [isReportedModalOpen, setIsReportedModalOpen] = useState(false);

  const status = ExpeditionStatuses[paramStatus as keyof typeof ExpeditionStatuses];

  const handleClickOnChangeStatus = () => {
    if (item?.status === ExpeditionStatuses.plan) {
      setIsPlannedModalOpen(true);
      return;
    }
    if (item?.status === ExpeditionStatuses.card) {
      setIsReportedModalOpen(true);
      return;
    }
    changeStatus({ id: Number(item?.id) });
  };

  const handleStatusChange = (value: ExpeditionStatusChangeFormType) => {
    const preparedData = { ...value, id: Number(item?.id) };
    changeStatus(preparedData);
  };

  const validationSchemaForPlanned = Yup.object({
    planned_at: Yup.date().required('Пожалуйста, введите дату окончания планирования'),
  });

  const validationSchemaForReported = Yup.object({
    actual_start_date: Yup.date()
      .min(new Date(1900, 0, 1), 'Вы указали некорректную дату')
      .required('Пожалуйста, укажите дату'),
    actual_end_date: Yup.date()
      .min(new Date(1900, 0, 1), 'Вы указали некорректную дату')
      .when('actual_start_date', (eventStartDate, schema) => {
        return (
          eventStartDate &&
          schema.min(eventStartDate, 'Дата окончания не должна быть раньше даты начала.')
        );
      })
      .required('Пожалуйста, укажите дату'),
  });

  const initialPlanned = {
    planned_at: '',
  };

  const initialReported = {
    actual_start_date: item?.actual_start_date || '',
    actual_end_date: item?.actual_end_date || '',
  };

  const plannedFields: FieldType[] = [
    {
      label: 'Дата, когда планируем завершить заполнение карточки',
      name: 'planned_at',
      type: 'datePicker',
      placeholder: 'Введите дату',
      required: true,
    },
  ];

  const reportedFields: FieldType[] = [
    {
      label: 'Фактическая дата начала',
      name: 'actual_start_date',
      type: 'datePicker',
      placeholder: 'Введите фактическую дату начала',
      required: true,
    },
    {
      label: 'Фактическая дата окончания',
      name: 'actual_end_date',
      type: 'datePicker',
      placeholder: 'Введите фактическую дату окончания',
      required: true,
    },
  ];

  useEffect(() => {
    if (isSuccess) {
      if (item?.id === Number(data?.expedition_id)) {
        openInfoNotify('Изменения сохранены', getNotifySuccessTextByStatus(item.status));
        reset();
      }
      if (item?.id !== Number(data?.expedition_id)) {
        openInfoNotify(
          'Изменения сохранены',
          getNotifySuccessTextByStatus(ExpeditionStatuses.card),
        );
        navigate(LINK_TO_EXPEDITION_PANEL(Number(data?.expedition_id)));
        reset();
      }
    }
    if (isError) {
      openErrorNotify('Произошла ошибка', getNotifyErrorTextByStatus(item?.status));
      reset();
    }
  }, [isSuccess, isError, item?.id]);

  useEffect(() => {
    if (item?.status && item?.status !== paramStatus) {
      navigate(LINK_TO_EXPEDITION_PANEL(Number(item.id), item.status));
    }
  }, [item?.status, data?.expedition_id]);

  return (
    <>
      {status && item?.status && status === item.status && isEditable ? (
        <div className={styles.wrapper}>
          <span className={styles.text}>{getTextByStatus(item.status)}</span>
          <Icon icon={'outline-arrow-right'} width={16} height={14}></Icon>
          <span
            onClick={handleClickOnChangeStatus}
            className={String(styles.text) + ' ' + String(styles.link)}
          >
            {getLinkByStatus(item.status)}
          </span>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <span className={styles.text}>{item ? getTextByStatus(status) : ''}</span>
        </div>
      )}
      <TableModal
        onSubmit={(value) => handleStatusChange(value as ExpeditionStatusChangeFormType)}
        onClose={() => setIsPlannedModalOpen(false)}
        validationSchema={validationSchemaForPlanned}
        initialValues={initialPlanned}
        loading={isLoading}
        success={isSuccess}
        buttonText="Создать карточку экспедиции"
        open={isPlannedModalOpen}
        fields={plannedFields}
        width={563}
        title="Дата планирования"
      />
      <TableModal
        onSubmit={(value) => handleStatusChange(value as ExpeditionStatusChangeFormType)}
        onClose={() => setIsReportedModalOpen(false)}
        validationSchema={validationSchemaForReported}
        initialValues={initialReported}
        loading={isLoading}
        success={isSuccess}
        buttonText="Создать отчет экспедиции"
        open={isReportedModalOpen}
        fields={reportedFields}
        width={563}
        title="Создание отчета"
      />
    </>
  );
};
