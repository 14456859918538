import React, { FC, useCallback, useEffect } from 'react';
import styles from './object-formular-panel.module.scss';
import { MenuItem, Modal, ModalHeaderButtons, TableCell, TableLoader } from '@/components';
import { useNavigate, useParams } from 'react-router';
import {
  LINK_TO_EXPEDITION_PANEL,
  LINK_TO_OBJECT_CARD,
  LINK_TO_OBJECT_MEDIA,
  LINK_TO_VIEW_3D,
  MAP,
} from '@/constants';
import { CoordsTypes, ModalPosition, Targets3DTypes } from '@/constants/enums';
import { useAppSelector } from '@/state';
import { useEditObjectMutation, useGetObjectsQuery } from '@/state/api';

import { FormularContent, FormularPropsType } from './formular-content';
import { useDispatch } from 'react-redux';
import { clearFormWithCoordsMarker, setSelectedObject } from '@/state/slice';
import { useMap } from '@/context';
import { IObject } from '@/types';
import { openErrorNotify, openInfoNotify, prepareCoordsFieldToString } from '@/utils';
import { Link } from 'react-router-dom';
import { isEqual } from 'lodash';
import { FormikValues } from 'formik';

export const ObjectFormularPanel: FC<FormularPropsType> = ({ className }): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { map } = useMap();
  const params = useParams();
  const objectId = Number(params.id);

  const { data: objectsData, isLoading, isFetching } = useGetObjectsQuery();
  const [
    editObject,
    { isLoading: editLoading, isSuccess: editSuccess, isError: editError, reset: editReset },
  ] = useEditObjectMutation();

  const preparedData = objectsData?.objects || [];
  const object = preparedData.find((object) => object.id === objectId);

  const formData = useAppSelector((state) => state.toolsPanels.objectCreationPanel.persistedForm);
  const cordStyle = useAppSelector((state) => state.map.coordsType);

  const customClasses = [styles.modal, className].join(' ');
  const preparedObject = object
    ? {
        ...object,
        coordinates: prepareCoordsFieldToString(object, cordStyle, 'coordinates'),
        expeditions: object?.expeditions?.map(
          ({ expedition_id, name }: { expedition_id: number; name: string }): MenuItem => ({
            key: expedition_id,
            name: name,
            link: <Link to={LINK_TO_EXPEDITION_PANEL(expedition_id)}>{name}</Link>,
          }),
        ),
      }
    : null;

  const handleUpdateData = useCallback((values: FormikValues) => {
    dispatch(clearFormWithCoordsMarker());
    const preparedValue = {
      ...values,
      coordinates: prepareCoordsFieldToString(values, CoordsTypes.decimal, 'coordinates'),
    };
    editObject(preparedValue as IObject);
  }, []);

  const handleCloseModal = () => {
    navigate(MAP);
  };

  const handleFlyToObject = () => {
    if (object) {
      const { lat, lng } = object;
      const coordinates = { lat: Number(lat), lng: Number(lng) - 0.04 };
      map?.flyTo(coordinates, 14);
    }
  };

  const handleOpenMediaPanel = (id: number) => {
    navigate(LINK_TO_OBJECT_MEDIA(id), { state: { id } });
  };

  const handleOpenObjectCard = (id: number) => {
    navigate(LINK_TO_OBJECT_CARD(id));
  };

  const handleClick3D = () => {
    navigate(LINK_TO_VIEW_3D(Targets3DTypes.object, objectId));
  };

  useEffect(() => {
    dispatch(setSelectedObject(objectId));
    return () => {
      dispatch(clearFormWithCoordsMarker());
      dispatch(setSelectedObject(null));
    };
  }, [objectId]);

  useEffect(() => {
    const formDataUnique = !isEqual(formData, preparedObject) && !!formData && !!preparedObject;
    const requestEnded = !editSuccess && !editLoading;
    if (formData && formDataUnique && requestEnded) {
      formData && handleUpdateData(formData);
    }
  }, [formData, preparedObject, editSuccess, editLoading]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        <>
          Координаты объекта <b>{object?.name}</b> были изменены
        </>,
      );
      editReset();
    }
    if (editError) {
      openErrorNotify(
        'Произошла ошибка',
        <>
          При редактировании координат объекта <b>{object?.name}</b> произошла ошибка
        </>,
      );
      editReset();
    }
  }, [editSuccess, editError]);

  const title = (
    <div className={styles['title-wrapper']}>
      <TableCell
        value={isLoading || isFetching ? 'Загрузка...' : object?.name}
        className={styles.label}
        maxWidth={500}
        fontSize={18}
      />
      <div className={styles['title-buttons-wrapper']}>
        <ModalHeaderButtons
          text="Медиа-файлы"
          onClick={() => handleOpenMediaPanel(objectId)}
          icon="fill-image"
          placement="bottomLeft"
        />
        <ModalHeaderButtons
          text={object?.has_3d ? 'Просмотр 3D-модели объекта' : '3D-модель не загружена'}
          onClick={() => handleClick3D()}
          icon="outline-3D"
          disable={!object?.has_3d}
          placement="bottomLeft"
        />
        <ModalHeaderButtons
          text="Центрировать объект"
          onClick={() => handleFlyToObject()}
          icon="outline-target"
          placement="bottomLeft"
        />
        <ModalHeaderButtons
          text="Карточка объекта"
          onClick={() => handleOpenObjectCard(objectId)}
          icon="outline-file-earmark-text"
          placement="bottomLeft"
        />
      </div>
    </div>
  );

  return (
    <Modal
      width={800}
      position={ModalPosition.left}
      wrapClassName={customClasses}
      onClose={() => handleCloseModal()}
      title={title}
    >
      <div className={styles.body}>
        {isLoading || isFetching || editLoading ? (
          <TableLoader className={styles.loader} />
        ) : (
          <FormularContent item={formData ?? preparedObject} />
        )}
      </div>
    </Modal>
  );
};
