import React, { FC, useEffect, useState } from 'react';
import { BaseSectionPropsType, ExpeditionCardSection } from '../index';
import { ConfirmModal, Icon, PanelTableButton, Table, TableCell, TableModal } from '@/components';
import { handleSortNumber, openErrorNotify, openInfoNotify } from '@/utils';
import { ColumnsType } from 'antd/lib/table';
import styles from '../section.module.scss';
import { InputTypes } from '@/constants/enums';
import stylesExpedition from '../../../expedition-panel.module.scss';
import { FormikValues } from 'formik';
import { findIndex } from 'lodash';
import { Conclusion } from '@/types';
import * as Yup from 'yup';
import {
  useCreateExpeditionConclusionMutation,
  useDeleteExpeditionConclusionMutation,
  useEditExpeditionConclusionMutation,
} from '@/state/api';

export const ConclusionsSection: FC<BaseSectionPropsType> = ({ isEditable, item, ...rest }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState<Conclusion>();

  const [
    createConclusion,
    {
      isLoading: createLoading,
      isError: createError,
      isSuccess: createSuccess,
      reset: createReset,
    },
  ] = useCreateExpeditionConclusionMutation();
  const [
    editConclusion,
    { isLoading: editLoading, isError: editError, isSuccess: editSuccess, reset: editReset },
  ] = useEditExpeditionConclusionMutation();
  const [
    deleteConclusion,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
      reset: deleteReset,
    },
  ] = useDeleteExpeditionConclusionMutation();

  const handleSetSelectedItem = (id: number) => {
    setSelectedItem(item?.expedition_conclusions?.find((item) => item.id === id));
  };

  const handleDelete = (id: number) => {
    deleteConclusion({ id });
  };

  const handleEdit = (value: FormikValues) => {
    editConclusion({
      id: selectedItem?.id as number,
      expedition_id: item?.id as number,
      conclusion: value.conclusion as string,
    });
  };

  const handleAdd = (value: FormikValues) => {
    createConclusion({
      expedition_id: item?.id as number,
      conclusion: value.conclusion as string,
    });
  };

  const toggleDelete = (id: number) => {
    handleSetSelectedItem(id);
    setShowDeleteModal((prevState) => !prevState);
  };

  const toggleEdit = (id: number) => {
    handleSetSelectedItem(id);
    setShowEditModal((prevState) => !prevState);
  };

  const toggleAdd = () => {
    setShowAddModal((prevState) => !prevState);
  };

  const editableColumns: ColumnsType<Conclusion> = [
    {
      title: '№',
      align: 'left',
      fixed: 'left',
      width: 60,
      sorter: (first, second) =>
        handleSortNumber(
          findIndex(item?.expedition_conclusions, first),
          findIndex(item?.expedition_conclusions, second),
        ),
      render: (value: string, row) => (
        <TableCell value={findIndex(item?.expedition_conclusions, row) + 1} />
      ),
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      fixed: 'left',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleEdit(id)}
          tooltipTitle="Редактировать вывод экспедиции"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      fixed: 'left',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleDelete(id)}
          tooltipTitle="Удалить вывод экспедиции"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'Вывод',
      dataIndex: 'conclusion',
      className: 'with-pre-line',
      key: 'conclusion',
      align: 'left',
      render: (value: string) => value,
    },
  ];

  const readOnlyColumn = editableColumns.filter((item) => item.key !== 'icon');

  const initialValues = {
    expedition_id: item?.id,
    conclusion: '',
  };

  const validationSchema = Yup.object({
    conclusion: Yup.string().required('Пожалуйста, укажите справку экспедиции'),
  });

  const fields = [
    {
      label: 'Вывод',
      name: 'conclusion',
      placeholder: 'Введите вывод экспедиции',
      type: InputTypes.textArea,
      className: stylesExpedition['text-area-first'],
      required: true,
    },
  ];

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify('Добавление вывода', 'Добавление вывода экспедиции успешно выполнено');
      createReset();
    }
    if (createError) {
      openErrorNotify('Произошла ошибка', 'При добавлении вывода экспедиции произошла ошибка');
      createReset();
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify('Изменения вывода', 'Изменение вывода экспедиции успешно выполнено');
      editReset();
    }
    if (editError) {
      openErrorNotify('Произошла ошибка', 'При редактировании вывода экспедиции произошла ошибка');
      editReset();
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify('Удаление вывода', 'Удаление вывода экспедиции успешно выполнено');
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении вывода экспедиции произошла ошибка');
      deleteReset();
    }
  }, [deleteSuccess, deleteError]);

  return (
    <ExpeditionCardSection
      {...rest}
      buttonText="Добавить вывод"
      onClickAdd={toggleAdd}
      isEditable={isEditable}
    >
      <>
        {item?.expedition_conclusions && item?.expedition_conclusions?.length > 0 && (
          <Table
            withSelection={false}
            dataSource={item?.expedition_conclusions}
            columns={isEditable ? editableColumns : readOnlyColumn}
            className={styles.table}
            noDataClassName={styles['no-data']}
            scroll={{ x: 1225, y: 300 }}
            withPagination={false}
          />
        )}
        <TableModal
          onSubmit={(value) => handleAdd(value)}
          onClose={() => setShowAddModal(false)}
          initialValues={initialValues}
          validationSchema={validationSchema}
          loading={createLoading}
          success={createSuccess}
          buttonText="Добавить"
          open={showAddModal}
          fields={fields}
          width={563}
          title="Добавление вывода экспедиции"
        />
        <TableModal
          onSubmit={(value) => handleEdit(value)}
          onClose={() => setShowEditModal(false)}
          initialValues={selectedItem as Conclusion}
          validationSchema={validationSchema}
          loading={editLoading}
          success={editSuccess}
          buttonText="Изменить"
          open={showEditModal}
          fields={fields}
          width={563}
          title="Изменение вывода экспедиции"
        />
        <ConfirmModal
          open={showDeleteModal}
          title="Подтвердите удаление вывода экспедиции"
          content={<p>Вы уверены, что хотите удалить выбранный вывод экспедиции?</p>}
          submitButtonText="Удалить"
          cancelButtonText="отменить"
          onSubmit={() => selectedItem && handleDelete(selectedItem?.id)}
          onCancel={() => setShowDeleteModal(false)}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
      </>
    </ExpeditionCardSection>
  );
};
