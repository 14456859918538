import React, { FC, useRef } from 'react';
import styles from './image-gallery.module.scss';
import { ImageGalleryPropsType } from './image-gallery.types';
import { GalleryItem, Icon } from '@/components';
import { Image } from 'antd';
import { MediaTargetsTypes } from '@/constants/enums';
import { getNameByPath } from '@/utils';
import { DefaultMediaType } from '@/types';

const { PreviewGroup } = Image;

export const ImageGallery: FC<ImageGalleryPropsType> = ({
  data = [],
  onDelete,
  isEdit = false,
  mediaType = MediaTargetsTypes.object,
  itemSize = 152,
  className = '',
}) => {
  const ref = useRef(null);

  const galleryAvailable = data && data?.length > 0;
  const preparedClassNames = [styles.gallery, className].join(' ');

  const getPreparedName = (data: DefaultMediaType[] | undefined, index: number) => {
    const item = data?.[index - 1];
    if (item?.name) {
      return item?.name;
    }
    if (item?.path) {
      return getNameByPath(item?.path);
    }

    return '';
  };

  if (!galleryAvailable) {
    return null;
  }

  return (
    <div className={preparedClassNames} ref={ref}>
      <PreviewGroup
        preview={{
          className: styles.mask,
          getContainer: () => ref?.current as unknown as HTMLElement,
          // @ts-ignore
          countRender: (index, total) => {
            return (
              <>
                <div>
                  {index}/{total}
                </div>
                <div>{getPreparedName(data, index)}</div>
              </>
            );
          },
        }}
        icons={{
          left: <Icon icon="outline-chevron-left" size={56} />,
          right: <Icon icon="outline-chevron-right" size={56} />,
          rotateLeft: <Icon icon="outline-rotate-image-left" size={24} />,
          rotateRight: <Icon icon="outline-rotate-image-right" size={24} />,
          zoomOut: <Icon icon="outline-zoom-out" size={24} />,
          zoomIn: <Icon icon="outline-zoom-in" size={24} />,
          close: <Icon icon="outline-close" size={24} />,
        }}
      >
        {data?.map(
          (item, index) =>
            item && (
              <GalleryItem
                isEdit={isEdit}
                mediaType={mediaType}
                key={`${mediaType as string}-image-` + String(item?.path) + String(index)}
                onDelete={() => onDelete && onDelete(item)}
                size={itemSize}
                {...item}
              />
            ),
        )}
      </PreviewGroup>
    </div>
  );
};
