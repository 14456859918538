import React from 'react';
import { UsersTab } from './users-tab/users-tab';
import { RolesTab } from './roles-tab';
import { ObjectsGroupsTab } from './objects-groups-tab';
import { AccessesAreasTab } from './accesses-areas-tab';
import { SettingTablesTabsTypes } from '@/constants/enums';

export const SettingTabs = [
  {
    label: 'Роли',
    key: SettingTablesTabsTypes.roles,
    children: <RolesTab />,
  },
  {
    label: 'Пользователи',
    key: SettingTablesTabsTypes.users,
    children: <UsersTab />,
  },
  {
    label: 'Группы объектов',
    key: SettingTablesTabsTypes.objectsGroups,
    children: <ObjectsGroupsTab />,
  },
  {
    label: 'Области видимости',
    key: SettingTablesTabsTypes.accessesAreas,
    children: <AccessesAreasTab />,
  },
];
