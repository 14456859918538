import React, { FC, useEffect } from 'react';
import { LINK_TO_LOGIN } from '@/constants';
import { AuthorizationWrapper } from '@/components/authorization';
import { useLocation, useNavigate } from 'react-router';
import {
  useGetOtpLifetimeQuery,
  useGetUserInfoMutation,
  useLoginUserMutation,
  useResendOtpMutation,
} from '@/state/api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { OTPForm } from './otp-validation-form';
import { OTP } from './otp-validation.types';
import { openErrorNotify } from '@/utils';

export const OtpValidation: FC = (): JSX.Element => {
  const [
    login,
    {
      isLoading: isLoginLoading,
      error: loginError,
      isError: isLoginError,
      isSuccess: isLoginSuccess,
    },
  ] = useLoginUserMutation();

  const [getUserData] = useGetUserInfoMutation({ fixedCacheKey: 'user-info' });
  const navigate = useNavigate();

  const location = useLocation();

  const email = (location.state?.email as string) || '';
  const password = (location.state?.password as string) || '';

  const { isLoading: isLoadingOtpLifetime, data: otpLifetimeData } = useGetOtpLifetimeQuery({
    email,
  });

  const [
    resend,
    { isLoading: isLoadingOtpResend, isError: isResendError, data: newOtpLifetimeData },
  ] = useResendOtpMutation();

  const handleSubmit = async ({ otpCode }: OTP): Promise<void> => {
    if (password && email && otpCode) {
      login({ password, otpCode, email });
    }
  };

  const handleCancelResetOtp = (): void => {
    navigate(LINK_TO_LOGIN);
  };

  useEffect(() => {
    if (isLoginSuccess) {
      getUserData();
    }
  }, [isLoginSuccess]);

  useEffect(() => {
    if (isResendError) {
      openErrorNotify('Произошла ошибка', 'При отправке кода произошла ошибка');
    }
  }, [isResendError]);

  useEffect(() => {
    if (!email || !password) {
      navigate(LINK_TO_LOGIN);
    }
  }, [email, password]);

  const handleResendOtp = () => {
    resend({ email });
  };

  return (
    <AuthorizationWrapper headerText="Подтверждение">
      <OTPForm
        onSubmitForm={(value) => {
          handleSubmit({ ...value });
        }}
        onCancel={handleCancelResetOtp}
        loading={isLoginLoading}
        otpDataLoading={isLoadingOtpLifetime || isLoadingOtpResend}
        error={loginError as FetchBaseQueryError}
        isError={isLoginError}
        otpCreatedAt={newOtpLifetimeData?.created_at || otpLifetimeData?.created_at}
        validity={newOtpLifetimeData?.validity || otpLifetimeData?.validity}
        onResendOtp={handleResendOtp}
      />
    </AuthorizationWrapper>
  );
};
