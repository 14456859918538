import { Icon } from '@/components';
import { notification } from 'antd';
import styles from './notify-utils.module.scss';
import { ReactNode } from 'react';

export const openErrorNotify = (title: string, message?: ReactNode, onClose?: () => void) => {
  const key = window.crypto.randomUUID();

  const handleClick = () => {
    notification.close(key);
  };

  notification.open({
    placement: 'bottomRight',
    message: <h5>{title}</h5>,
    duration: 10,
    key: key,
    className: styles.notify,
    maxCount: 4,
    onClick: handleClick,
    icon: <Icon icon={'outline-exclamation-circle'} size={32} color={'#DC3545'}></Icon>,
    description: message ? <span className={styles.description}>{message}</span> : null,
    closeIcon: <></>,
    onClose: onClose,
  });
};

export const openInfoNotify = (title: string, message?: ReactNode, onClose?: () => void) => {
  const key = window.crypto.randomUUID();

  const handleClick = () => {
    notification.close(key);
  };

  notification.open({
    placement: 'bottomRight',
    message: <h5>{title}</h5>,
    duration: 10,
    key: key,
    onClick: handleClick,
    className: styles.notify,
    maxCount: 4,
    description: message ? <span className={styles.description}>{message}</span> : null,
    closeIcon: <></>,
    onClose: onClose,
  });
};
