import React, { FC, useEffect, useState } from 'react';
import styles from '../../../expedition-panel.module.scss';
import { CommonInfoSectionPropsType, ExpeditionCardSection } from '../../../components';
import dayjs from 'dayjs';
import { FieldType, Icon, PanelTabButton, TableModal } from '@/components';
import { ExpeditionStatusChangeFormType, IExpeditionSummary } from '@/types';
import * as Yup from 'yup';
import { ExpeditionStatuses, PanelTabButtonTypes } from '@/constants/enums';
import { openErrorNotify, openInfoNotify } from '@/utils';
import { useChangeExpeditionDateMutation } from '@/state/api';

export const CommonInfoSection: FC<CommonInfoSectionPropsType> = ({
  expedition,
  label,
  item,
  isEditable,
}) => {
  const [setTime, { isLoading, isError, isSuccess, reset }] = useChangeExpeditionDateMutation();

  const [isReportedModalOpen, setIsReportedModalOpen] = useState(false);

  const isTentativeData = item?.status === ExpeditionStatuses.card;

  const FIELDS = {
    firstDate: isTentativeData ? 'tentative_start_date' : 'actual_start_date',
    secondsDate: isTentativeData ? 'tentative_end_date' : 'actual_end_date',
  };

  const getDate = (value?: string | null): string => {
    if (!value) {
      return 'данные не указаны';
    }
    return dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : value;
  };

  const handleSubmit = (value: ExpeditionStatusChangeFormType) => {
    const preparedData = { ...value, id: Number(item?.id) };
    setTime(preparedData);
  };

  const toggleEdit = () => {
    setIsReportedModalOpen((prevState) => !prevState);
  };

  const validationSchema = Yup.object({
    [FIELDS.firstDate]: Yup.date()
      .required('Пожалуйста, укажите дату')
      .min(new Date(1900, 0, 1), 'Вы указали некорректную дату'),
    [FIELDS.secondsDate]: Yup.date()
      .required('Пожалуйста, укажите дату')
      .min(new Date(1900, 0, 1), 'Вы указали некорректную дату')
      .when(FIELDS.firstDate, (eventStartDate, schema) => {
        return (
          eventStartDate &&
          schema.min(eventStartDate, 'Дата окончания должна быть больше, чем дата начала.')
        );
      }),
  });

  const initialValues = {
    [FIELDS.firstDate]: item?.[FIELDS.firstDate as keyof IExpeditionSummary] || '',
    [FIELDS.secondsDate]: item?.[FIELDS.secondsDate as keyof IExpeditionSummary] || '',
  };

  const reportedFields: FieldType[] = [
    {
      label: `${isTentativeData ? 'Планирумеая' : 'Фактическая'} дата начала`,
      name: FIELDS.firstDate,
      type: 'datePicker',
      placeholder: `Введите ${isTentativeData ? 'планируемую' : 'фактическую'} дата начала`,
      required: true,
    },
    {
      label: `${isTentativeData ? 'Планирумеая' : 'Фактическая'} дата окончания`,
      name: FIELDS.secondsDate,
      type: 'datePicker',
      placeholder: `Введите ${isTentativeData ? 'планируемую' : 'фактическую'} дата окончания`,
      required: true,
    },
  ];

  useEffect(() => {
    if (isSuccess) {
      openInfoNotify('Изменения сохранены', 'Даты успешно изменены');
      reset();
    }
    if (isError) {
      openErrorNotify('Произошла ошибка', 'Не удалось изменить данные');
      reset();
    }
  }, [isSuccess, isError, item?.id]);

  return (
    <ExpeditionCardSection label={label}>
      <>
        <div className={styles['text-common-info']}>
          <p>Акватория: {expedition?.waterarea.name}</p>
          <p>
            {expedition?.actual_start_date
              ? 'Фактическая дата начала: '
              : 'Планируемая дата начала: '}
            {getDate(expedition?.actual_start_date || expedition?.tentative_start_date)}
          </p>
          <p>
            {expedition?.actual_end_date
              ? 'Фактическая дата окончания: '
              : 'Планируемая дата окончания: '}
            {getDate(expedition?.actual_end_date || expedition?.tentative_end_date)}
          </p>
          <TableModal
            onSubmit={(value) => handleSubmit(value as ExpeditionStatusChangeFormType)}
            onClose={() => setIsReportedModalOpen(false)}
            validationSchema={validationSchema}
            initialValues={initialValues}
            loading={isLoading}
            success={isSuccess}
            buttonText="Изменить"
            open={isReportedModalOpen}
            fields={reportedFields}
            width={563}
            title={isTentativeData ? 'Изменение планируемых дат' : 'Изменение фактических дат'}
          />
        </div>
        {isEditable && (
          <PanelTabButton
            className={styles.btn}
            type={PanelTabButtonTypes.primary}
            onClick={() => toggleEdit()}
          >
            <Icon icon="outline-pencil" size={14} className="me-1" />
            Редактировать
          </PanelTabButton>
        )}
      </>
    </ExpeditionCardSection>
  );
};
