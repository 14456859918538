import React, { useEffect, useState } from 'react';
import styles from './z-coords-panel.module.scss';
import { Modal } from '@/components';
import { useDispatch } from 'react-redux';
import { useClickPosition, useMap } from '@/context';
import { useNavigate } from 'react-router';
import { MAP } from '@/constants';
import { ZCoordsPanelTitle } from './z-coords-panel-title';
import { ZCoordsPanelTable } from './z-coords-panel-table';
import { setIsZCoordsEditor } from '@/state/slice';
import { IZCoords } from '@/types';
import { defaultCoords } from './z-coords-panel-utils';

export const ZCoordsPanel = () => {
  const { map } = useMap();
  const checkMap = map !== null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setPosition } = useClickPosition();
  const [coords, setCoords] = useState<IZCoords[]>([]);
  const [checkBoxMarked, setCheckBoxMarked] = useState<IZCoords[]>([]);

  useEffect(() => {
    dispatch(setIsZCoordsEditor(true));
    map?.getContainer().classList.add(styles['change-point']);

    return () => {
      setPosition(null);
      dispatch(setIsZCoordsEditor(false));
      map?.getContainer().classList.remove(styles['change-point']);
    };
  }, [checkMap]);

  return (
    <Modal
      width={589}
      onClose={() => navigate(MAP)}
      title={
        <ZCoordsPanelTitle
          coords={coords.length !== 0 ? coords : defaultCoords}
          setCoords={setCoords}
          checkBoxMarked={checkBoxMarked}
          setCheckBoxMarked={setCheckBoxMarked}
        />
      }
    >
      <ZCoordsPanelTable
        coords={coords.length !== 0 ? coords : defaultCoords}
        setCoords={setCoords}
        setCheckBoxMarked={setCheckBoxMarked}
      />
    </Modal>
  );
};
