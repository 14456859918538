import { ClassificationItemEnum } from './classification-item/classification-item.types';

export const areas = [
  {
    title: 'Район',
    designations: [
      {
        title: 'Верхний слой',
        description: 'Сплошная заливка цветом, с прозрачностью 10%, обводка – сплошная линия.',
        color: 'purple',
      },
      {
        title: 'Нижний слой',
        description: 'Сплошная заливка цветом, с прозрачностью 2%, обводка – пунктирная линия.',
        color: 'purple-bottom-layer',
      },
    ],
  },
  {
    title: 'Акватория',
    designations: [
      {
        title: 'Верхний слой',
        description: 'Сплошная заливка цветом, с прозрачностью 15%, обводка – сплошная линия.',
        color: 'green',
      },
      {
        title: 'Нижний слой',
        description: 'Сплошная заливка цветом, с прозрачностью 5%, обводка – пунктирная линия.',
        color: 'green-bottom-layer',
      },
    ],
  },
  {
    title: 'Сектор',
    designations: [
      {
        title: 'Верхний слой',
        description: 'Сплошная заливка цветом, с прозрачностью 15%, обводка – сплошная линия.',
        color: 'pink',
      },
    ],
  },
];

export const objects = [
  {
    type: ClassificationItemEnum.default,
    description: 'Условное обозначение объекта',
  },
  {
    type: ClassificationItemEnum.technical,
    description: 'Объект технический',
  },
  {
    type: ClassificationItemEnum.PDO,
    description: 'Объект потенциально опасный (ВОП)',
  },
  {
    type: ClassificationItemEnum.culture,
    description: 'Объект, имеющий культурную ценность',
  },
  {
    type: ClassificationItemEnum.military,
    description: 'Объект военно-исторического наследия',
  },
  {
    type: ClassificationItemEnum.unexplored,
    description: 'Неисследованный объект',
  },
];
