import React, { FC } from 'react';
import { ResetPasswordSubmitTypes } from './reset-password.types';
import styles from './reset-password.module.scss';

export const ResetPasswordSubmit: FC<ResetPasswordSubmitTypes> = ({ onResendMail, email }) => {
  return (
    <div className={styles.wrapper}>
      <span>Письмо отправлено</span>
      {email && (
        <span className={styles.body}>
          Мы отправили на адрес электронной почты
          <br />
          <span>{email}&nbsp;</span>
          письмо с ссылкой для изменения пароля.
        </span>
      )}
      <div className={styles.footer}>
        <span>Не получили письмо?</span>
        &nbsp;
        <span
          onClick={() => {
            onResendMail({ email });
          }}
        >
          Отправить еще раз.
        </span>
      </div>
    </div>
  );
};
