import React, { FC, useEffect, useState } from 'react';
import styles from '../../default-tab.module.scss';
import { ObjectsGroup, NewObjectsGroup } from '@/types';
import { ComponentSize, InputTypes, PanelTabButtonTypes } from '@/constants/enums';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { ColumnsType } from 'antd/lib/table';
import { handleSortString, openErrorNotify, openInfoNotify } from '@/utils';
import { AnyObject } from 'yup/lib/types';
import {
  useGetObjectsGroupsListQuery,
  useCreateObjectsGroupMutation,
  useEditObjectsGroupMutation,
  useDeleteObjectsGroupMutation,
  useGetObjectsQuery,
} from '@/state/api';
import {
  BlackTooltip,
  ConfirmModal,
  defaultSearchProps,
  FieldType,
  Icon,
  ObjectsGroupsTable,
  PanelTabButton,
  TableCell,
  TableModal,
} from '@/components';

export const ObjectsGroupsTab: FC = (): JSX.Element => {
  const [selectedObjectGroup, setSelectedObjectGroup] = useState<ObjectsGroup>();
  const [createdName, setCreatedName] = useState<string>('');

  const [showAddObjectsGroupModal, setShowAddObjectsGroupModal] = useState(false);
  const [showEditObjectsGroupModal, setShowEditObjectsGroupModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const { data: objectsData, isLoading, isError } = useGetObjectsQuery();

  const { data } = useGetObjectsGroupsListQuery();

  const objectGroups = data?.data || [];

  const [
    createObjectsGroup,
    {
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
      reset: resetCreate,
    },
  ] = useCreateObjectsGroupMutation();
  const [
    editObjectsGroup,
    { isLoading: editLoading, isSuccess: editSuccess, isError: editError, reset: resetEdit },
  ] = useEditObjectsGroupMutation();
  const [
    deleteObjectsGroup,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      reset: resetDelete,
    },
  ] = useDeleteObjectsGroupMutation();

  const initialValues: NewObjectsGroup = {
    name: '',
    objects: [],
  };

  const getObjectGroups = (id: number) => {
    const item = objectGroups?.find((object) => object.id === id);
    item && setSelectedObjectGroup(item);
  };

  const handleEdit = (id: number) => {
    resetEdit();
    setShowEditObjectsGroupModal(true);
    getObjectGroups(id);
  };
  const handleDelete = (id: number) => {
    resetDelete();
    setShowConfirmDeleteModal((prevState) => !prevState);
    getObjectGroups(id);
  };

  const handleCreateObjectsGroup = ({ name, objects }: NewObjectsGroup): void => {
    setCreatedName(name);
    const preparedValue = {
      name,
      objects_ids: objects.map((object) => object.id),
    };
    createObjectsGroup(preparedValue);
  };

  const handleEditObjectsGroup = ({ id, name, objects }: ObjectsGroup): void => {
    const preparedValue = {
      id: id.toString(),
      name: name,
      objects_ids: objects.map((object) => object.id),
    };
    editObjectsGroup(preparedValue);
  };

  const handleDeleteObjectsGroup = (id: number): void => {
    deleteObjectsGroup({
      objects_groups_ids: id,
    });
  };

  const handleGetSelectedRow = (id?: number, data?: Array<ObjectsGroup>): FormikValues => {
    if (id && data) {
      return data.filter((item) => item.id === id)[0];
    }
    return initialValues;
  };

  const optionsColumns: ColumnsType<AnyObject & { id: number }> = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (value: string) => <TableCell value={value} maxWidth={200} />,
      sorter: (first, second, sortOrder) => handleSortString(first.name, second.name, sortOrder),
      ...defaultSearchProps('name'),
    },
  ];

  const valuesColumns: ColumnsType<AnyObject & { id: number }> = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (value: string) => <TableCell value={value} maxWidth={300} />,
      sorter: (first, second, sortOrder) => handleSortString(first.name, second.name, sortOrder),
      ...defaultSearchProps('name'),
    },
  ];

  const customEmptyLeftDisclaimer = (
    <span className={styles['transfer-disclaimer']}>
      Для создания группы объектов,
      <br />
      выберите объекты в правой части
      <br />и потом нажмите на кнопку
      <Icon size={20} icon={'outline-chevron-left'} className={styles['transfer-button']} />
    </span>
  );

  const fields: FieldType[] = [
    {
      label: 'Наименование группы',
      name: 'name',
      placeholder: 'Введите наименование',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Объекты группы',
      name: 'objects',
      type: 'transfer',
      rightTableTitle: 'Доступные объекты',
      rightTableColumns: optionsColumns,
      rightTableDatasource: objectsData?.objects || [],
      loading: isLoading,
      emptyLeftDisclaimer: customEmptyLeftDisclaimer,
      leftTableTitle: 'Объекты группы',
      leftTableColumns: valuesColumns,
      required: true,
    },
  ];

  const validationSchema = Yup.object({
    name: Yup.string().required('Пожалуйста, введите наименование группы'),
    objects: Yup.array().min(1, 'Пожалуйста, добавьте в группу объекты').required(),
  });

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify(
        'Добавление группы объектов',
        <>
          Группа объектов <b>{createdName}</b> была добавлена
        </>,
      );
    }
    if (createError) {
      openErrorNotify('Произошла ошибка', 'При добавлении произошла ошибка');
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify(
        'Изменение группы объектов',
        <>
          Группа объектов <b>{selectedObjectGroup?.name}</b> была изменена
        </>,
      );
      resetEdit();
    }
    if (editError) {
      openErrorNotify('Произошла ошибка', 'При редактировании произошла ошибка');
      resetDelete();
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Удаление группы объектов',
        <>
          Группа объектов <b>{selectedObjectGroup?.name}</b> была удалена
        </>,
      );
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении произошла ошибка');
    }
  }, [deleteSuccess, deleteError]);

  return (
    <div className={styles.wrapper}>
      {!isError && !isLoading && (
        <div className={styles.header}>
          <BlackTooltip
            title={'Группы объектов ограничивают доступ роли к определнным объектам'}
            placement={'bottomLeft'}
          >
            <PanelTabButton
              className={styles['button-panel-help']}
              onClick={() => {}}
              type={PanelTabButtonTypes.icon}
              size={ComponentSize.small}
            >
              <Icon icon="outline-question-circle" size={14} />
            </PanelTabButton>
          </BlackTooltip>
          <PanelTabButton
            disabled={isError || isLoading}
            type={PanelTabButtonTypes.roundSecondary}
            size={ComponentSize.small}
            onClick={() => {
              resetCreate();
              setShowAddObjectsGroupModal((prevState) => !prevState);
            }}
          >
            Добавить
          </PanelTabButton>
        </div>
      )}
      <TableModal
        onSubmit={(value) => handleEditObjectsGroup(value as ObjectsGroup)}
        buttonText="Изменить"
        onClose={() => setShowEditObjectsGroupModal(false)}
        title="Редактирование группы объектов"
        validationSchema={validationSchema}
        initialValues={handleGetSelectedRow(selectedObjectGroup?.id, objectGroups)}
        open={showEditObjectsGroupModal}
        loading={editLoading}
        success={editSuccess}
        fields={fields}
        width={1000}
      />
      <ConfirmModal
        open={showConfirmDeleteModal}
        title="Подтвердите удаление группы объектов"
        content={
          <p>
            Вы уверены, что хотите удалить&nbsp;
            {selectedObjectGroup?.name ? (
              <>
                группу объектов <strong>{selectedObjectGroup?.name}</strong>
              </>
            ) : (
              'выбранную группу объектов'
            )}
            ?
          </p>
        }
        submitButtonText="Удалить группу"
        cancelButtonText="Отменить"
        onSubmit={() => handleDeleteObjectsGroup(selectedObjectGroup?.id as number)}
        onCancel={() => setShowConfirmDeleteModal((prevState) => !prevState)}
        loading={deleteLoading}
        success={deleteSuccess}
        type="danger"
      />
      <TableModal
        onSubmit={(value) => handleCreateObjectsGroup(value as NewObjectsGroup)}
        buttonText="Добавить"
        onClose={() => setShowAddObjectsGroupModal(false)}
        title="Добавление группы объектов"
        validationSchema={validationSchema}
        initialValues={initialValues}
        open={showAddObjectsGroupModal}
        loading={createLoading}
        success={createSuccess}
        fields={fields}
        width={1000}
      />

      <ObjectsGroupsTable
        data={objectGroups}
        loading={isLoading}
        error={isError}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </div>
  );
};
