import React, { FC, useEffect, useState } from 'react';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Bounds, Center } from '@react-three/drei';
import { LogModelPropsType } from './log-model.types';
import { useGetBathymetric3DModelQuery } from '@/state/api';
import { openErrorNotify } from '@/utils';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { Loader } from '../loader';

export const LogModel: FC<LogModelPropsType> = ({ id, setName, intensity, onFitBounds }) => {
  const { data, isError, isSuccess } = useGetBathymetric3DModelQuery({ id: id });
  const [isModelReady, setIsModelReady] = useState(false);
  const [log, setLog] = useState<GLTF | null>(null);

  useEffect(() => {
    if (isError) {
      openErrorNotify(
        'Произошла ошибка',
        'При загрузке 3D-модели произошла ошибка, либо 3D-модель не существует',
      );
      setIsModelReady(true);
    }
  }, [isError]);

  const handleModelRender = () => {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderConfig({ type: 'js' });
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
    loader.setDRACOLoader(dracoLoader);

    loader.load(
      `data:application/octet-stream;base64,${String(data?.scene)}`,
      (gltf) => {
        setLog(gltf);
        setIsModelReady(true);
      },
      () => {},
      () => openErrorNotify('Не удалось обработать 3D-модель'),
    );
  };

  useEffect(() => {
    if (isSuccess) {
      setName(data?.name || '');
      handleModelRender();
    }
  }, [isSuccess]);

  return (
    <>
      {!isModelReady ? (
        <Loader />
      ) : (
        <>
          <directionalLight intensity={intensity} position={[0, 20, 0]} castShadow />
          {/* <directionalLight intensity={intensity} position={[0, 20, -20]} castShadow /> */}
          <directionalLight intensity={intensity} position={[0, -20, 20]} castShadow />
          <Bounds fit clip margin={1} onFit={() => setTimeout(() => onFitBounds(), 500)}>
            <Center position={[0, 0, 0]}>
              <group dispose={null}>
                <primitive object={log?.scene || {}} />
              </group>
            </Center>
          </Bounds>
        </>
      )}
    </>
  );
};
