import { useEffect, useState } from 'react';
import { useClickPosition, useMap } from '@/context';
import { LatLng } from 'leaflet';
import styles from './meta-log-panel.module.scss';
import { useDispatch } from 'react-redux';
import { useGetMetaDataMutation } from '@/state/api';
import { Bathymetric, IMetaDataMap } from '@/types';
import { useAppSelector } from '@/state';
import { openErrorNotify } from '@/utils';
import { setCollectMetaLog, setIsMetaLogOpen, setIsMetaLogOpenTooltip } from '@/state/slice';

export const MetaLogPanel = () => {
  const dispatch = useDispatch();
  const position = useClickPosition();
  const { map } = useMap();
  const checkMap = map !== null;
  const [metaData, { data, isLoading, isError }] = useGetMetaDataMutation();
  const prepareData = data?.metaData ? data.metaData : [];
  const [oneLatLng, setOneLatLng] = useState<{ x?: number; y?: number }>({});
  const collectMetaLog = useAppSelector((state) => state.toolsPanels.metaLogPanel.collectMetaLog);
  const isMetaLogOpen = useAppSelector((state) => state.toolsPanels.metaLogPanel.isMetaLogOpen);
  const isMetaLogOpenTooltip = useAppSelector(
    (state) => state.toolsPanels.metaLogPanel.isMetaLogOpenTooltip,
  );

  useEffect(() => {
    if (checkMap) {
      dispatch(setIsMetaLogOpen(true));
      dispatch(setCollectMetaLog([]));
      map?.getContainer().classList.add(styles.cursor);
      position.setPosition(null);

      return () => {
        dispatch(setIsMetaLogOpen(false));
        dispatch(setCollectMetaLog([]));
        map?.getContainer().classList.remove(styles.cursor);
        position.setPosition(null);
      };
    }
  }, [checkMap]);

  useEffect(() => {
    const positionLatLng = position.position;

    if (positionLatLng?.lat && positionLatLng?.lng && isMetaLogOpen && isMetaLogOpenTooltip) {
      setOneLatLng({ x: positionLatLng?.lat, y: positionLatLng?.lng });
      metaData({ x: positionLatLng?.lat, y: positionLatLng?.lng });
      position.setPosition(null);
    }

    dispatch(setIsMetaLogOpenTooltip(true));
  }, [position]);

  useEffect(() => {
    isError && openErrorNotify('Произошла ошибка', 'Не удалось загрузить метаданные');
  }, [isError]);

  useEffect(() => {
    if (!isLoading && oneLatLng.x && oneLatLng.y) {
      const newMetaData: IMetaDataMap = {
        collectLatLng: { lat: oneLatLng?.x, lng: oneLatLng?.y } as LatLng,
        project: prepareData as Bathymetric,
      };
      dispatch(setCollectMetaLog([...collectMetaLog, newMetaData]));
    }
  }, [isLoading, isError]);

  useEffect(() => {
    return () => {
      position.setPosition(null);
    };
  }, []);

  return null;
};
