import React, { FC, useEffect, useState } from 'react';
import styles from './error-placeholder.module.scss';
import { Icon } from '@/components';
import { isEmpty } from 'lodash';
import { ErrorPlaceholderTypes } from './error-placeholder.types';

export const ErrorPlaceholder: FC<ErrorPlaceholderTypes> = ({
  errors,
  touched,
  requestError,
  isError,
}): JSX.Element => {
  const [errorText, setErrorText] = useState('');
  const [showServerErrorPlaceholder, setShowServerErrorPlaceholder] = useState(false);
  const [showError, setShowError] = useState(false);

  const errorAvailable = Object.keys(errors).filter((key) => touched[key]).length > 0 || isError;

  useEffect(() => {
    if (isError) {
      const error = requestError?.data as { error?: string; message?: string; msg?: string };
      setShowServerErrorPlaceholder(true);
      const errorText = error?.error || error?.message || error?.msg;
      if (errorText) {
        setErrorText(errorText);
      } else {
        setErrorText(
          'Непредвиденная ошибка. Проверьте подключение к интернету или попробуйте позже.',
        );
      }
    }
  }, [isError, requestError]);

  useEffect(() => {
    const objDiff = Object.keys(touched).filter((key) => touched[key] !== errors[key]);
    const actualErrors = objDiff.map((item) => errors[item]).filter((item) => !!item);
    const showError = !isEmpty(touched) && actualErrors.length !== 0 && objDiff.length !== 0;

    setShowError(showError);
  }, [errors, touched]);

  if (errorAvailable) {
    return (
      <div className={styles.wrapper}>
        {showError && (
          <div className={styles.card}>
            {Object.entries(errors).map(
              ([key, value]) =>
                touched[key] && (
                  <span className="d-block " key={key}>
                    {value}
                    <br />
                  </span>
                ),
            )}
          </div>
        )}
        {showServerErrorPlaceholder && (
          <div className={styles.card}>
            <div className={styles.header}>
              <span>Ошибка</span>
              <Icon
                size={12}
                icon="outline-close"
                onClick={() => setShowServerErrorPlaceholder(false)}
              />
            </div>
            <div>{errorText}</div>
          </div>
        )}
      </div>
    );
  }
  return <></>;
};
