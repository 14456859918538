import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex, MutexInterface } from 'async-mutex';
import { Token, ErrorResponse, DefaultResponse } from '@/types';
import { RootStateType } from '@/state/store';
import { setAccessToken, setRefreshToken } from '@/state/slice';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { API_URL } from '@/constants';
import { resetAction } from '@/state/actions';

const BASE_URL = API_URL;

export const tagList = [
  'Map',
  'Auth',
  'Objects',
  'Tools',
  'Users',
  'Regions',
  'Sectors',
  'ShipsBases',
  'WaterAreas',
  'ObjectsGroups',
  'Bathymetrics',
  'Expeditions',
  'Members',
  'ExpeditionsByObject',
  'Roles',
  'Rules',
  'AccessesAreas',
  'Bookmarks',
  'Gals',
  'ObjectsMedia',
  'ExpeditionSummary',
  'ExpeditionMedia',
  'Available3D',
  'OTP lifetime',
];

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set('X-Requested-With', 'XMLHttpRequest');

    const token = (getState() as RootStateType).auth.accessToken;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const mutex: MutexInterface = new Mutex();

export const authCheckQueryWithCustomError: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError | DefaultResponse
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401 && (api.getState() as RootStateType).auth.refreshToken) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const formData = new FormData();
        const refreshToken = (api.getState() as RootStateType).auth.refreshToken;
        refreshToken && formData.append('refresh_token', refreshToken);
        const id = (api.getState() as RootStateType).auth.user?.id.toString();
        id && formData.append('id', id);
        const refreshResult = await baseQuery(
          {
            method: 'POST',
            credentials: 'include',
            url: '/auth/refresh/token',
            body: {
              refresh_token: (api.getState() as RootStateType).auth.refreshToken,
              id: (api.getState() as RootStateType).auth.user?.id,
            },
          },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          api.dispatch(setAccessToken((refreshResult.data as Token).access_token));
          api.dispatch(setRefreshToken((refreshResult.data as Token).refresh_token));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(resetAction());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  if (result.meta?.response?.status === 200) {
    const error = result.data as ErrorResponse;
    if ((error?.message || error?.error) && !error?.success) {
      return { error: { error: error?.error || error?.message, status: 500, data: result?.data } };
    }
  }
  return result;
};

export const api = createApi({
  baseQuery: authCheckQueryWithCustomError,
  tagTypes: tagList,
  endpoints: () => ({}),
});
