import { resetAction } from '@/state/actions';
import { ConvertCoordsFormValues, CoordsConverterStateType } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: CoordsConverterStateType = {
  coordsPanelState: {
    currentValue: {
      cordType: '',
      decimalCord: {
        latitude: '',
        longitude: '',
      },
      graduatedCord: {
        latitude: {
          DD: '',
          MM: '',
          SS: '',
          directionIsNegative: false,
        },
        longitude: {
          DD: '',
          MM: '',
          SS: '',
          directionIsNegative: false,
        },
      },
    },
  },
};

const coordinatesConverterSlice = createSlice({
  name: 'coordinatesConverter',
  initialState,
  reducers: {
    setCoordsConverterValue: (state, { payload }: PayloadAction<ConvertCoordsFormValues>) => {
      state.coordsPanelState.currentValue = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAction, () => {
      return initialState;
    });
  },
});

export const { setCoordsConverterValue } = coordinatesConverterSlice.actions;
export const { reducer: coordinatesConverterReducer } = coordinatesConverterSlice;
