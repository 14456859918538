import styles from '../../route-panel-table.module.scss';
import { IOnDeleteTableCoords } from '@/types';
import React, { FC, SyntheticEvent } from 'react';
import { Icon } from '@/components';
import { Button } from 'antd';

export const DeleteCoordsButton: FC<IOnDeleteTableCoords> = ({ id, coords, setCoords }) => {
  const handleDeleteCoords = (item: SyntheticEvent): void => {
    const coordsDelete = coords.filter(
      (coordsOne) => coordsOne.value?.id === parseInt(item.currentTarget.id),
    )[0];

    if (coords[1] === undefined) {
      setCoords([]);
      return;
    } else if (!coordsDelete || coordsDelete.value?.isDefault) {
      return;
    }

    coordsDelete.value?.polyline?.remove();

    const newCoords = coords.filter(
      (coordsOne) => coordsOne.value?.id !== parseInt(item.currentTarget.id),
    );
    setCoords(newCoords);
  };

  return (
    <Button id={id} className={styles.btn} onClick={handleDeleteCoords}>
      <Icon width={16} icon={'outline-x'} />
    </Button>
  );
};
