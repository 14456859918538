import { ConfirmModal } from '@/components';
import { useGetRegionsQuery, useCreateRegionMutation, useEditRegionMutation } from '@/state/api';
import styles from '../polygon-tool-panel.module.scss';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Handlers } from '../polygon-tool-panel.types';
import { RegionContentPropsType, RegionFormType } from './region-content.types';
import { useNavigate } from 'react-router';
import { useHistoryBackstack, useMap } from '@/context';
import { LINK_TO_LAYERS_TAB } from '@/constants';
import { RegionForm } from './region-form';
import { checkPolygon, openErrorNotify, openInfoNotify } from '@/utils';
import { LatLngExpression, Polygon } from 'leaflet';
import { isEqual } from 'lodash';
import { CoordsTable } from '../coords-table';
import { PolygonEditableLayer } from '../polygon-editable-layer';
import { PolygonDrawableLayer } from '../polygon-drawable-layer';
import { LayerTablesTabsTypes } from '@/constants/enums';

export const RegionContent = forwardRef<Handlers, RegionContentPropsType>(
  ({ id, setIsButtonsDisabled, setTitle }, ref) => {
    const { isLoading: isGetLoading, isError: isGetError, data } = useGetRegionsQuery();

    const preparedRegion = data?.find((item) => item.id === id);

    const [isEditMode, setIsEditMode] = useState(id ? true : false);
    const [isCreateMode, setIsCreateMode] = useState(id ? false : true);

    const pathOptions: L.PathOptions = {
      fillOpacity: 0.15,
      fillColor: '#7912E0',
      color: '#7912E0',
      weight: 2,
      lineJoin: 'round',
      lineCap: 'round',
    };

    const [region, setRegion] = useState<RegionFormType>();

    const [isPurged, setIsPurged] = useState(false);

    const [polygon, setPolygon] = useState<LatLngExpression[][][]>([
      [
        [
          [NaN, NaN],
          [NaN, NaN],
          [NaN, NaN],
        ],
      ],
    ]);

    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

    useEffect(() => {
      preparedRegion && setPolygon(preparedRegion.polygon);
    }, [preparedRegion]);

    useEffect(() => {
      const title = region?.name || preparedRegion?.name || 'Район';
      setTitle(title);
    }, [preparedRegion, region]);

    const [
      createRegion,
      { isError: isCreateError, reset: resetCreate, isSuccess: isCreateSuccess },
    ] = useCreateRegionMutation();

    const [
      updateRegion,
      { isError: isUpdateError, reset: resetUpdate, isSuccess: isUpdateSuccess },
    ] = useEditRegionMutation();

    const navigate = useNavigate();

    const { backstack } = useHistoryBackstack();

    const handleRedirect = () => {
      navigate(backstack[backstack.length - 2] ?? LINK_TO_LAYERS_TAB(LayerTablesTabsTypes.regions));
    };

    const handleChangePolygon = (data: LatLngExpression[][][]) => {
      setPolygon(data);
      setIsPurged(false);
    };

    const { map } = useMap();

    useImperativeHandle(
      ref,
      () => ({
        handleCancel() {
          if (region && id) {
            const initialPolygon = preparedRegion?.polygon;
            const initialData = {
              name: preparedRegion?.name,
              districtId: preparedRegion?.district_id,
            };
            if (!isEqual(initialData, region) || !isEqual(polygon, initialPolygon)) {
              setIsShowConfirmModal(true);
            } else {
              handleRedirect();
            }
          } else if (region) {
            setIsShowConfirmModal(true);
          } else {
            handleRedirect();
          }
        },
        handleChange() {
          if (id && region) {
            const preparedData = {
              id: id,
              name: region.name,
              polygon: polygon,
              district_id: region.districtId,
            };
            updateRegion(preparedData);
          } else if (region) {
            const preparedData = {
              name: region.name,
              polygon: polygon,
              district_id: region.districtId,
            };
            createRegion(preparedData);
          }
        },
        handleFlyTo() {
          if (checkPolygon(polygon)) {
            const lPolygon = new Polygon(polygon);
            map?.flyToBounds(lPolygon.getBounds());
          }
        },
        handlePurge() {
          setIsEditMode(false);
          setIsCreateMode(true);
          setIsPurged(true);
        },
      }),
      [backstack, region, polygon, preparedRegion, id],
    );

    useEffect(() => {
      if (isGetError) {
        openErrorNotify('Произошла ошибка', 'Не удалось загрузить районы');
        handleRedirect();
      }
    }, [isGetError]);

    useEffect(() => {
      setIsButtonsDisabled(isGetLoading && !isGetError);
    }, [isGetLoading, isGetError]);

    useEffect(() => {
      const initialData = {
        name: preparedRegion?.name,
        districtId: preparedRegion?.district_id,
      };
      const isTouchedPolygon = !isEqual(polygon, preparedRegion?.polygon);
      const isTouchedData = !isEqual(initialData, region);
      setIsButtonsDisabled(
        (!isTouchedData && !isTouchedPolygon) || !checkPolygon(polygon) || isCreateMode,
      );
    }, [polygon, region]);

    useEffect(() => {
      if (isCreateSuccess) {
        openInfoNotify('Изменения сохранены', 'Район создан');
        handleRedirect();
      }
      if (isCreateError) {
        openErrorNotify('Произошла ошибка', 'При создании района произошла ошибка');
      }
      resetCreate();
    }, [isCreateSuccess, isCreateError]);

    useEffect(() => {
      if (isUpdateSuccess) {
        handleRedirect();
        openInfoNotify('Изменения сохранены', 'Район изменен');
      }
      if (isUpdateError) {
        openErrorNotify('Произошла ошибка', 'При редактировании района произошла ошибка');
      }
      resetUpdate();
    }, [isUpdateSuccess, isUpdateError]);

    return (
      <>
        <ConfirmModal
          open={isShowConfirmModal}
          onCancel={() => setIsShowConfirmModal(false)}
          onSubmit={() => {
            handleRedirect();
          }}
        />
        <CoordsTable
          data={polygon}
          isCreateMode={isCreateMode}
          setIsCreateMode={(value: boolean) => setIsCreateMode(value)}
          setIsEditMode={(value: boolean) => setIsEditMode(value)}
          isEditMode={isEditMode}
          isError={isGetError}
          isLoading={isGetLoading}
          setData={(data) => handleChangePolygon(data)}
        />
        {!isGetLoading && (
          <RegionForm
            item={preparedRegion}
            setData={(item) => setRegion(item)}
            onRedirect={handleRedirect}
          />
        )}
        {isEditMode ? (
          <PolygonEditableLayer
            name={region?.name || preparedRegion?.name || 'Район'}
            polygon={polygon}
            tooltipClassname={styles['region-tooltip']}
            pathOptions={pathOptions}
            setPolygon={(value) => handleChangePolygon(value)}
          />
        ) : null}
        {isCreateMode ? (
          <PolygonDrawableLayer
            polygon={polygon}
            setIsEditMode={(value: boolean) => setIsEditMode(value)}
            tooltipClassname={styles['region-tooltip']}
            pathOptions={pathOptions}
            isPurged={isPurged}
            setIsCreateMode={(value) => setIsCreateMode(value)}
            setPolygon={(value) => handleChangePolygon(value)}
          />
        ) : null}
      </>
    );
  },
);
