import { api } from '../api';
import {
  DefaultExpeditionType,
  GetExpeditionSummaryType,
  GetExpeditionType,
  ICreateExpedition,
  IEditExpeditionReference,
} from '@/types';

export const expeditionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getExpeditionList: builder.query<GetExpeditionType, { id: number } | void>({
      query(body) {
        return {
          url: '/expedition/list',
          params: body ? { id: body.id } : {},
          method: 'GET',
          credentials: 'include',
        };
      },
      providesTags: ['Expeditions'],
    }),
    getExpeditionsByObjectList: builder.query<
      { data: { expeditions: { id: number; name: string }[] } },
      { id: number }
    >({
      query(body) {
        return {
          url: '/expedition/list_by_object_id',
          params: { id: body.id },
          method: 'GET',
          credentials: 'include',
        };
      },
      providesTags: ['ExpeditionsByObject'],
    }),
    getExpeditionSummary: builder.query<GetExpeditionSummaryType, { id: number }>({
      query(body) {
        return {
          url: '/expedition/summary',
          method: 'GET',
          params: { id: body.id },
          credentials: 'include',
        };
      },
      providesTags: ['ExpeditionSummary'],
    }),
    createExpedition: builder.mutation<
      DefaultExpeditionType,
      {
        name: string;
      }
    >({
      query(body) {
        return {
          url: '/expedition/add',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Expeditions', 'ExpeditionsByObject'],
    }),
    editExpedition: builder.mutation<DefaultExpeditionType, ICreateExpedition>({
      query(body) {
        return {
          url: '/expedition/edit',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Expeditions', 'ExpeditionsByObject'],
    }),
    editExpeditionReference: builder.mutation<DefaultExpeditionType, IEditExpeditionReference>({
      query(body) {
        return {
          url: '/expedition/edit',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    deleteExpedition: builder.mutation<
      DefaultExpeditionType,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/expedition',
          method: 'DELETE',
          body: { uid: body.id },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Expeditions', 'ExpeditionsByObject'],
    }),
    exportExpeditionToPdf: builder.mutation<
      Blob,
      {
        id: number;
        name: string;
      }
    >({
      query(body) {
        return {
          url: '/expedition/summary/export/pdf',
          method: 'GET',
          credentials: 'include',
          params: { id: body.id },
          cache: 'no-cache',
          responseHandler: async (response) => {
            const text = await response?.text();
            const link = document.createElement('a');
            link.href = `data:application/pdf;base64, ${text}`;
            link.target = '_blank';
            link.download = `${body.name}.pdf`;
            link.click();
          },
        };
      },
    }),
    exportExpeditionToDocx: builder.mutation<
      Blob,
      {
        id: number;
        name: string;
      }
    >({
      query(body) {
        return {
          url: '/expedition/summary/export/docx',
          method: 'GET',
          credentials: 'include',
          params: { id: body.id },
          cache: 'no-cache',
          responseHandler: async (response) => {
            const text = (await response?.json()) as string;
            const link = document.createElement('a');
            link.href = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64, ${text}`;
            link.target = '_blank';
            link.download = `${body.name}.docx`;
            link.click();
          },
        };
      },
    }),
  }),
});

export const {
  useGetExpeditionListQuery,
  useCreateExpeditionMutation,
  useEditExpeditionMutation,
  useGetExpeditionsByObjectListQuery,
  useEditExpeditionReferenceMutation,
  useDeleteExpeditionMutation,
  useGetExpeditionSummaryQuery,
  useExportExpeditionToPdfMutation,
  useExportExpeditionToDocxMutation,
} = expeditionsApi;
