import styles from './route-panel-title.module.scss';
import React, { FC } from 'react';
import { useAppSelector } from '@/state';
import { useDispatch } from 'react-redux';
import { setIsAddCoords, setIsShow } from '@/state/slice';
import { ModalHeaderButtons } from '@/components';

export const RoutePanelTitle: FC = () => {
  const dispatch = useDispatch();

  const isAddCoords = useAppSelector((state) => {
    return state.toolsPanels.routePanel.isAddCoords;
  });
  const isShow = useAppSelector((state) => {
    return state.toolsPanels.routePanel.isShow;
  });

  const titleAddCoords = isAddCoords ? 'Отмена добавление координат' : 'Добавление Координаты';
  const titleShow = isShow ? 'Показать путь' : 'Скрыть путь';

  const toggleIsAddCoords = () => dispatch(setIsAddCoords(!isAddCoords));
  const toggleIsShow = () => dispatch(setIsShow(!isShow));

  const items = [
    {
      key: '1',
      title: titleAddCoords,
      icon: 'outline-create-point',
      isActive: isAddCoords,
      onClick: () => toggleIsAddCoords(),
    },
    {
      key: '2',
      title: titleShow,
      icon: 'outline-pathdash',
      isActive: isShow,
      onClick: () => toggleIsShow(),
    },
  ];

  return (
    <div className={styles.title}>
      <p className={styles['text-title']}>Измерение расстояния</p>
      <div className={styles['list-icon']}>
        {items.map(({ key, title, icon, isActive, onClick }) => (
          <ModalHeaderButtons
            key={key}
            className={`${styles.btn} ${isActive ? styles.active : ''}`}
            text={title}
            onClick={() => onClick()}
            icon={icon}
            placement="top"
          />
        ))}
      </div>
    </div>
  );
};
