import React from 'react';
import { LeftSidebar } from './left-sidebar';
import { RightSidebar } from './right-sidebar';
import { CenterSidebar } from './center-sidebar';
import styles from '../layout.module.scss';
import { Children } from '@/types';

type SidebarSubComponentsType = {
  LeftSidebar: typeof LeftSidebar;
  RightSidebar: typeof RightSidebar;
  CenterSidebar: typeof CenterSidebar;
};

const Sidebar: React.FC<{ children: Children }> & SidebarSubComponentsType = ({ children }) => {
  return <div className={styles.sidebar}>{children}</div>;
};

Sidebar.LeftSidebar = LeftSidebar;
Sidebar.RightSidebar = RightSidebar;
Sidebar.CenterSidebar = CenterSidebar;

export { Sidebar };
