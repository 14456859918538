import React, { FC, useState } from 'react';
import styles from './tools-button.module.scss';
import { ToolsButtonHeader } from './tools-button-header';
import { Collapse } from 'antd';
import { SidebarButton } from '@/components';
import { ToolsButtonPropsType } from './tools-button.types';

const { Panel } = Collapse;

export const ToolsButton: FC<ToolsButtonPropsType> = ({ toolsList }): JSX.Element => {
  const [isActive, setIsActive] = useState(false);

  const handleChange = (isActive: boolean): void => {
    if (isActive) {
      return setIsActive(false);
    }
    return setIsActive(true);
  };

  return (
    <Collapse
      className={styles.wrapper}
      defaultActiveKey={['']}
      onChange={() => handleChange(isActive)}
    >
      <Panel
        showArrow={false}
        key="1"
        header={<ToolsButtonHeader isActive={isActive} title="Панель инструментов карты" />}
      >
        <div className={styles.item}>
          {toolsList.map(({ icon, title, path, onClick, name, isActive }, idx) => (
            <SidebarButton
              key={idx}
              className={styles.button}
              isActive={isActive}
              name={name}
              icon={icon}
              title={title}
              path={path}
              onClick={onClick}
            />
          ))}
        </div>
      </Panel>
    </Collapse>
  );
};
