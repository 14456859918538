import { resetAction } from '@/state/actions';
import { ToolsPanelStateType } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ToolsPanelStateType = {
  activePanelRoute: false,
  isAddCoords: true,
  isShow: false,
  isSave: false,
};

const routePanelSlice = createSlice({
  name: 'routePanel',
  initialState,
  reducers: {
    setActivePanelRoute: (state, { payload }: PayloadAction<boolean>) => {
      state.activePanelRoute = payload;
    },
    setIsAddCoords: (state, { payload }: PayloadAction<boolean>) => {
      state.isAddCoords = payload;
    },
    setIsShow: (state, { payload }: PayloadAction<boolean>) => {
      state.isShow = payload;
    },
    setIsSave: (state, { payload }: PayloadAction<boolean>) => {
      state.isSave = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAction, () => {
      return initialState;
    });
  },
});

export const { setActivePanelRoute, setIsAddCoords, setIsShow, setIsSave } =
  routePanelSlice.actions;

export const { reducer: routePanelReducer } = routePanelSlice;
