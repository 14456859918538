import React, { FC } from 'react';
import styles from './bookmark-table.module.scss';
import { ColumnsType } from 'antd/lib/table';
import { Bookmark, EditBookmark } from '@/types';
import { handleSortString } from '@/utils';
import { LatLngExpression } from 'leaflet';
import { useMap } from '@/context';
import { BookmarksTableTypes } from './bookmarks-table.types';
import { defaultSearchProps, Icon, PanelTableButton, Table, BlackTooltip } from '@/components';

export const BookmarksTable: FC<BookmarksTableTypes> = ({
  data,
  isLoading,
  handleOpenEdit,
  handleOpenDelete,
}) => {
  const { map } = useMap();

  const handleFlyTo = (value: EditBookmark) => {
    const bookmark = data.filter((item) => item.id === value)[0];
    map?.flyTo([bookmark?.lat, bookmark?.lng] as LatLngExpression, bookmark?.zoom);
  };

  const columns: ColumnsType<Bookmark> = [
    {
      title: <Icon size={12} icon={'outline-geo-alt'} />,
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 40,
      render: (value) => (
        <PanelTableButton
          showTooltip={true}
          tooltipPlacement={'bottom'}
          tooltipTitle={'Приблизиться к точке'}
          icon="outline-geo-alt"
          onClick={() => handleFlyTo(value)}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon={'outline-pencil'} />,
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 40,
      render: (value) => (
        <PanelTableButton
          showTooltip={true}
          tooltipPlacement={'bottom'}
          tooltipTitle={'Редактировать'}
          icon="outline-pencil"
          onClick={() => handleOpenEdit(value)}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon={'outline-trash'} />,
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 40,
      render: (value) => (
        <PanelTableButton
          showTooltip={true}
          tooltipPlacement={'bottom'}
          tooltipTitle={'Удалить'}
          icon="outline-trash"
          onClick={() => handleOpenDelete(value)}
          size={12}
        />
      ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 350,
      render: (name, item) => {
        if (item.description) {
          return (
            <BlackTooltip title={item.description} placement={'bottomLeft'}>
              {name}
            </BlackTooltip>
          );
        } else {
          return <>{name}</>;
        }
      },
      sorter: (first, second) => handleSortString(first?.name, second?.name),
      ...defaultSearchProps('name'),
    },
  ];

  return (
    <>
      <Table
        className={styles.table}
        dataSource={data}
        columns={columns}
        loading={isLoading}
        loaderClassName={styles.loader}
        scroll={{ y: 288 }}
        noDataClassName={styles['no-data']}
        withSelection={false}
        withPagination={false}
      />
    </>
  );
};
