import { createContext, useContext, useState, Dispatch, SetStateAction } from 'react';
import type { Map } from 'leaflet';

type MapContextType = {
  map: Map | null;
  setMap: Dispatch<SetStateAction<Map | null>>;
};

export const MapContext = createContext<MapContextType>({
  map: null,
  setMap: () => {},
});

export const useMap = () => useContext(MapContext);

export const MapProvider = ({ children }: { children: JSX.Element }) => {
  const [map, setMap] = useState<Map | null>(null);

  return <MapContext.Provider value={{ map, setMap }}>{children}</MapContext.Provider>;
};
