import { combineReducers } from '@reduxjs/toolkit';
import {
  coordinatesConverterReducer,
  metaGalsReducer,
  metaLogReducer,
  objectCreationReducer,
  routePanelReducer,
  zCoordsReducer,
} from '@/state/slice';

export const toolsPanelsReducer = combineReducers({
  converterPanel: coordinatesConverterReducer,
  routePanel: routePanelReducer,
  zCoordsPanel: zCoordsReducer,
  metaLogPanel: metaLogReducer,
  metaGalsPanel: metaGalsReducer,
  objectCreationPanel: objectCreationReducer,
});
