import React, { FC, useState } from 'react';
import { Input } from 'antd';
import styles from './input.module.scss';
import { InputPropsTypes } from './input.types';
import { ComponentSize, InputTypes } from '@/constants/enums';
import { Icon } from '@/components';
import { TextAreaProps } from 'antd/es/input';

const { TextArea } = Input;

export const NewInput: FC<InputPropsTypes> = ({
  size = ComponentSize.medium,
  error = false,
  disable = false,
  className = '',
  minWidth = 'auto',
  maxWidth = 'auto',
  onToggleShowPassword,
  showToggleButton = true,
  placeholder = '',
  type = InputTypes.text,
  children,
  readOnly,
  ref,
  maxLength = 1000,
  onFocus,
  showCount = true,
  autoSize = false,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevState) => {
      onToggleShowPassword && onToggleShowPassword(!prevState);
      return !prevState;
    });
  };

  const handleFocus = (event?: React.FocusEvent<HTMLInputElement, Element>, disabled?: boolean) => {
    onFocus && onFocus(event);
    disabled && event?.target?.select();
  };
  const customClasses = [
    styles.wrapper,
    size ? styles[size] : '',
    error ? styles.error : '',
    className,
  ].join(' ');

  switch (type) {
    case InputTypes.password:
      return (
        <div className={customClasses}>
          <>
            <Input
              {...rest}
              ref={ref}
              autoComplete="new-password"
              placeholder={placeholder}
              disabled={disable}
              readOnly={readOnly}
              onFocus={(event) => handleFocus(event, readOnly)}
              type={showPassword ? 'text' : 'password'}
              style={{
                minWidth,
                maxWidth,
              }}
            >
              {children}
            </Input>
            <>
              {showToggleButton && (
                <Icon
                  size={16}
                  icon={showPassword ? 'outline-eye-slash' : 'outline-eye'}
                  className={styles.icon}
                  onClick={() => toggleShowPassword()}
                />
              )}
            </>
          </>
        </div>
      );
    case InputTypes.textArea:
      return (
        <div className={customClasses}>
          <TextArea
            placeholder={placeholder}
            disabled={disable}
            autoSize={autoSize || { minRows: 6, maxRows: 8 }}
            maxLength={maxLength}
            readOnly={readOnly}
            showCount={showCount}
            {...(rest as TextAreaProps)}
          >
            {children}
          </TextArea>
        </div>
      );
    default:
      return (
        <div className={customClasses}>
          <Input
            {...rest}
            ref={ref}
            autoComplete="new-password"
            onFocus={(event) => handleFocus(event, readOnly)}
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={disable}
            style={{
              minWidth,
              maxWidth,
            }}
          >
            {children}
          </Input>
        </div>
      );
  }
};
