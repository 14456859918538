import React, { FC } from 'react';
import styles from './dropdown-placeholder.module.scss';
import { DropdownPlaceholdersPropsType } from './dropdown-placeholder.types';
import { Icon } from '@/components';

export const DropdownNoDataPlaceholder: FC<DropdownPlaceholdersPropsType> = ({
  className,
  text = 'Данные отсутствуют',
  icon = 'fill-image',
  size = 48,
}) => {
  const customStyles = [styles.wrapper, className].join(' ');

  return (
    <div className={customStyles}>
      <Icon icon={icon} size={size} />
      <span>{text}</span>
    </div>
  );
};
