import React from 'react';
import styles from './region.module.scss';
import { IRegion } from '@/types';

export const Region = ({ item }: { item: IRegion }) => {
  return (
    <>
      <div>
        {item.district_id && (
          <p className={styles.text}>
            <span className={styles.other}>Идентификатор района:</span>
            {item.district_id}
          </p>
        )}
        {item.region && (
          <p className={styles.text}>
            <span className={styles.other}>Регион:</span>
            {item.region}
          </p>
        )}
      </div>
    </>
  );
};
