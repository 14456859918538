import 'antd/dist/antd.min.css';
import 'leaflet/dist/leaflet.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/SCSS/text-classes.scss';
import '@/assets/SCSS/default-styles.scss';
import { Router } from '@/router';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import {
  MapProvider,
  ClickPostionProvider,
  LiveCordsProvider,
  HistoryBackstackProvider,
} from '@/context';
import { PersistGate } from 'redux-persist/integration/react';
import { ErrorBoundary } from '@/components';
import { store, persistor } from '@/state';
import ruRe from 'antd/es/locale/ru_RU';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { ConfigProvider } from 'antd';

dayjs.locale('ru');
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const App = () => {
  return (
    <ConfigProvider locale={ruRe}>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <MapProvider>
              <ClickPostionProvider>
                <LiveCordsProvider>
                  <BrowserRouter>
                    <HistoryBackstackProvider>
                      <div className="App">
                        <Router />
                      </div>
                    </HistoryBackstackProvider>
                  </BrowserRouter>
                </LiveCordsProvider>
              </ClickPostionProvider>
            </MapProvider>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </ConfigProvider>
  );
};
