import { api } from '../api';
import { DefaultExpeditionType, ExpeditionStatusChangeFormType } from '@/types';

export const expeditionsSummaryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createExpeditionGoal: builder.mutation<DefaultExpeditionType, unknown>({
      query(body) {
        return {
          url: '/goal/add',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    editExpeditionGoal: builder.mutation<DefaultExpeditionType, unknown>({
      query(body) {
        return {
          url: '/goal/edit',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    deleteExpeditionGoal: builder.mutation<
      DefaultExpeditionType,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/goal',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),

    createExpeditionTask: builder.mutation<DefaultExpeditionType, unknown>({
      query(body) {
        return {
          url: '/task/add',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    editExpeditionTask: builder.mutation<DefaultExpeditionType, unknown>({
      query(body) {
        return {
          url: '/task/edit',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    changeExpeditionStatus: builder.mutation<
      { expedition_id: number },
      ExpeditionStatusChangeFormType & { id: number }
    >({
      query(body) {
        return {
          url: '/expedition/change_status',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary', 'Expeditions', 'ExpeditionsByObject'],
    }),
    changeExpeditionDate: builder.mutation<{ expedition_id: number }, unknown>({
      query(body) {
        return {
          url: 'expedition/edit',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary', 'Expeditions'],
    }),
    deleteExpeditionTask: builder.mutation<
      DefaultExpeditionType,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/task',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    createExpeditionTool: builder.mutation<DefaultExpeditionType, unknown>({
      query(body) {
        return {
          url: '/tool/add',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    editExpeditionTool: builder.mutation<DefaultExpeditionType, unknown>({
      query(body) {
        return {
          url: '/tool/edit',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    deleteExpeditionTool: builder.mutation<
      DefaultExpeditionType,
      {
        expedition_id: number;
        tool_id: number;
      }
    >({
      query(body) {
        return {
          url: '/expedition/tool',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    createExpeditionMember: builder.mutation<DefaultExpeditionType, unknown>({
      query(body) {
        return {
          url: '/member/add',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    editExpeditionMember: builder.mutation<DefaultExpeditionType, unknown>({
      query(body) {
        return {
          url: '/expedition/members/purpose',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    deleteExpeditionMember: builder.mutation<
      DefaultExpeditionType,
      {
        expedition_id: number;
        member_id: number;
      }
    >({
      query(body) {
        return {
          url: '/expedition/member',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    createExpeditionShipBase: builder.mutation<
      DefaultExpeditionType,
      {
        expedition_id: number;
        ships_base_id: number;
      }
    >({
      query(body) {
        return {
          url: '/expedition/ships-bases',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    deleteExpeditionShipBase: builder.mutation<
      DefaultExpeditionType,
      {
        ships_base_id: number;
        expedition_id: number;
      }
    >({
      query(body) {
        return {
          url: '/expedition/ships-bases',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),

    createExpeditionSectionWork: builder.mutation<DefaultExpeditionType, unknown>({
      query(body) {
        return {
          url: 'expedition/sectors_work',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    editExpeditionSectionWork: builder.mutation<DefaultExpeditionType, unknown>({
      query(body) {
        return {
          url: 'expedition/sectors_work',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    deleteExpeditionSectionWork: builder.mutation<
      DefaultExpeditionType,
      {
        expedition_id: number;
        id: number;
      }
    >({
      query(body) {
        return {
          url: 'expedition/sectors_work',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    createExpeditionObjectWork: builder.mutation<DefaultExpeditionType, unknown>({
      query(body) {
        return {
          url: 'expedition/objects_work',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    editExpeditionObjectWork: builder.mutation<DefaultExpeditionType, unknown>({
      query(body) {
        return {
          url: 'expedition/objects_work',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    deleteExpeditionObjectWork: builder.mutation<
      DefaultExpeditionType,
      {
        expedition_id: number;
        id: number;
      }
    >({
      query(body) {
        return {
          url: 'expedition/objects_work',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    addExpeditionObjectsWork: builder.mutation<DefaultExpeditionType, unknown>({
      query(body) {
        return {
          url: 'objects/info',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary', 'Objects'],
    }),
    addExpeditionSectorsWork: builder.mutation<DefaultExpeditionType, unknown>({
      query(body) {
        return {
          url: 'sectors',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary', 'Sectors'],
    }),
  }),
});

export const {
  useCreateExpeditionGoalMutation,
  useEditExpeditionGoalMutation,
  useDeleteExpeditionGoalMutation,
  useCreateExpeditionTaskMutation,
  useEditExpeditionTaskMutation,
  useDeleteExpeditionTaskMutation,
  useCreateExpeditionToolMutation,
  useEditExpeditionToolMutation,
  useDeleteExpeditionToolMutation,
  useCreateExpeditionMemberMutation,
  useDeleteExpeditionMemberMutation,
  useEditExpeditionMemberMutation,
  useCreateExpeditionShipBaseMutation,
  useDeleteExpeditionShipBaseMutation,
  useChangeExpeditionStatusMutation,
  useCreateExpeditionSectionWorkMutation,
  useEditExpeditionSectionWorkMutation,
  useDeleteExpeditionSectionWorkMutation,
  useCreateExpeditionObjectWorkMutation,
  useEditExpeditionObjectWorkMutation,
  useDeleteExpeditionObjectWorkMutation,
  useChangeExpeditionDateMutation,
  useAddExpeditionObjectsWorkMutation,
  useAddExpeditionSectorsWorkMutation,
} = expeditionsSummaryApi;
