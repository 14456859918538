import React, { FC, useEffect, useState } from 'react';
import { Gals, GalsFile } from '@/types';
import { GalsTabContent } from './gals-tab-content';
import { openErrorNotify, openInfoNotify } from '@/utils';
import {
  useCreateGalsMutation,
  useDeleteGalsMutation,
  useGetGalsListQuery,
  useGetUnloadGalsListQuery,
} from '@/state/api';
import { useAppSelector } from '@/state';
import { Permissions } from '@/constants/enums';

export const GalsTab: FC = (): JSX.Element => {
  const [showAddFilesContent, setShowAddFilesContent] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showAddsModal, setShowAddModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState<Gals>();
  const [selectedRows, setSelectedRows] = useState<GalsFile[]>([]);

  const { data, isLoading, isError } = useGetGalsListQuery(undefined, {
    pollingInterval: 1000 * 50,
  });
  const {
    data: files,
    isLoading: filesLoading,
    isError: filesError,
  } = useGetUnloadGalsListQuery(undefined, {
    pollingInterval: 1000 * 50,
  });
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);
  const isEditable = userPermissions?.includes(Permissions.mapDataManagment);

  const [
    createGals,
    {
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
      reset: createReset,
    },
  ] = useCreateGalsMutation();
  const [
    deleteGals,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      reset: deleteReset,
    },
  ] = useDeleteGalsMutation();

  const onAdd = () => {
    const galses = selectedRows.map((item) => {
      return { path: item.path, name: item.name };
    });

    createGals({ galses: galses });
  };

  const preparedData = data?.data || [];
  const preparedFiles = files?.data || [];

  const handleGetTotalSize = (selectedRows: GalsFile[]): string => {
    let size = 0;
    selectedRows.forEach((item) => {
      size = size + parseFloat(item?.size) || 0;
    });
    return size.toFixed(1);
  };

  const handleToggleAdd = (visible: boolean) => {
    setShowAddModal(visible);
    createReset();
  };

  const handleToggleDelete = (row: Gals): void => {
    setSelectedItem(row);
    setShowDeleteModal((prevState) => !prevState);
    deleteReset();
  };

  const handleDelete = (id: number): void => {
    deleteGals({ id });
  };

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify('Лог галса добавлен', 'Добавление лога галса прошла успешно');
      setSelectedRows([]);
      setShowAddModal(false);
      setShowAddFilesContent(false);
    }
    if (createError) {
      openErrorNotify('Ошибка добавление лога галса', 'При добавлении лога галса произошла ошибка');
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify('Лог галса удален', 'Удаление лога галса прошла успешно');
    }
    if (deleteError) {
      openErrorNotify('Ошибка удаление лога галса', 'При удалении лога галса произошла ошибка');
    }
  }, [deleteSuccess, deleteError]);

  return (
    <GalsTabContent
      files={preparedFiles}
      data={preparedData}
      loading={isLoading}
      filesLoading={filesLoading}
      error={isError}
      isEditable={isEditable}
      filesError={filesError}
      selectedItem={selectedItem as Gals}
      currentFilesLength={selectedRows.length}
      currentTotalSize={handleGetTotalSize(selectedRows)}
      onClickDeleteBtn={handleToggleDelete}
      showAddFilesContent={showAddFilesContent}
      onToggleAddFiles={setShowAddFilesContent}
      onSelectRow={setSelectedRows}
      selectedRows={selectedRows}
      showAddModals={showAddsModal}
      setShowAddModal={handleToggleAdd}
      addLoading={createLoading}
      addSuccess={createSuccess}
      onAdd={onAdd}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={setShowDeleteModal}
      deleteLoading={deleteLoading}
      deleteSuccess={deleteSuccess}
      onDelete={handleDelete}
    />
  );
};
