import React from 'react';
import { TableDatePicker, Icon, TableSearch } from '@/components';
import { ColumnType } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';

export const defaultSearchProps = <T,>(
  dataIndex: string,
  customFilter?: (value: string | number | boolean, record: T, field: string) => boolean,
): ColumnType<T> => {
  return {
    filterDropdown: ({ clearFilters, confirm, setSelectedKeys, selectedKeys, visible }) => (
      <TableSearch
        onSearch={confirm}
        onClear={() => clearFilters && clearFilters()}
        onFilter={() => confirm({ closeDropdown: false })}
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        visible={visible}
      />
    ),
    filterIcon: () => <Icon size={12} icon="outline-search" />,
    onFilter: (value, record) => {
      if (customFilter) {
        return customFilter(value, record, dataIndex);
      }
      const recordAsString = record[dataIndex as keyof T] as string;

      return recordAsString
        ? recordAsString.toLowerCase().includes((value as string).toLowerCase())
        : false;
    },
  };
};

export const defaultDataPickerProps = <T,>(
  dataIndex: string,
  customFilter?: (value: Array<string | Dayjs>, record: T) => boolean,
): ColumnType<T> => {
  return {
    filterDropdown: ({ clearFilters, confirm, setSelectedKeys, selectedKeys, visible }) => (
      <TableDatePicker
        onSearch={confirm}
        onClear={clearFilters}
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        visible={visible}
      />
    ),
    filterIcon: () => <Icon size={12} icon="fill-calendar-date" />,
    onFilter: (value, record): boolean => {
      const preparedValue = value
        .toString()
        .split(';')
        .map((item) => {
          if (item) {
            return dayjs(item.split(/\.\-/).reverse().join('.'));
          }
          return '';
        });
      if (customFilter) {
        return customFilter(preparedValue, record);
      }
      const currentValue =
        record[dataIndex as keyof T] &&
        dayjs((record[dataIndex as keyof T] as string).slice(0, 10));
      const startData = preparedValue[0] && dayjs(preparedValue[0]);
      const endData = preparedValue[1] && dayjs(preparedValue[1]);

      if (currentValue)
        if (startData && endData)
          return currentValue.isSameOrAfter(startData) && currentValue.isSameOrBefore(endData);
        else if (startData) return currentValue.isSameOrAfter(startData);
        else if (endData) return currentValue.isSameOrBefore(endData);

      return false;
    },
  };
};
