import React, { FC, Key, SetStateAction, useEffect, useState } from 'react';
import { TableDatePickerPropsType } from './table-date-picker.types';
import styles from './table-date-picker.module.scss';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { Icon } from '@/components';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);
const { RangePicker } = DatePicker;

export const TableDatePicker: FC<TableDatePickerPropsType> = ({
  onSearch,
  onClear,
  setSelectedKeys,
  selectedKeys,
  visible,
}) => {
  const prepareData = (value?: Key) => (value ? dayjs(value) : null);

  const [value, setValue] = useState<[Dayjs | null, Dayjs | null]>([
    prepareData(selectedKeys[0]),
    prepareData(selectedKeys[1]),
  ]);

  const placeholderItem = dayjs().format('DD.MM.YYYY');

  const handleClearFilter = (): void => {
    setValue([null, null]);
    onClear && onClear();
    onSearch && onSearch();
  };

  useEffect(() => {
    if (!visible) {
      if (value[0] || value[1]) {
        onSearch && onSearch();
      } else {
        handleClearFilter();
      }
    }
  }, [visible]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <RangePicker
          value={value}
          // @ts-ignore
          popupClassName={styles.dropdown}
          open={visible}
          locale={locale}
          autoFocus={true}
          clearIcon={null}
          format="DD.MM.YYYY"
          placeholder={[placeholderItem, placeholderItem]}
          onChange={() => onSearch()}
          onCalendarChange={(dayJsValue, values) => {
            setValue(dayJsValue as SetStateAction<[Dayjs | null, Dayjs | null]>);
            setSelectedKeys([values[0].toString() + ';' + values[1].toString()]);
          }}
          suffixIcon={
            <div className={styles.icon} onClick={() => handleClearFilter()}>
              <Icon size={12} icon="outline-close" />
            </div>
          }
        />
      </div>
    </div>
  );
};
