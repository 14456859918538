export const MILITARY_OBJECT_LAYER = 'military_object';
export const UNDISCOVERED_OBJECT_LAYER = 'undiscovered_object';
export const DANGEROUS_OBJECT_LAYER = 'dangerous_object';
export const CULTURE_OBJECT_LAYER = 'culture_object';
export const TECHNICAL_OBJECT_LAYER = 'technical_object';
export const GALSES_LAYER = 'galses';
export const BATHYMETRY_LAYER = 'bathymetry';
export const OLD_BATHYMETRY_LAYER = 'old_bathymetry';
export const SECTORS_LAYER = 'sectors';
export const REGIONS_LAYER = 'regions';
export const WATER_AREAS_LAYER = 'water_areas';
export const SHIPS_BASES_LAYER = 'ships_bases';
export const UTM_BORDERS_LAYER = 'utm_borders';
export const OOPT_LAYER = 'oopt';
export const MO_LAYER = 'mo';

export const ALL_OBJECT_LAYERS = [
  UNDISCOVERED_OBJECT_LAYER,
  MILITARY_OBJECT_LAYER,
  TECHNICAL_OBJECT_LAYER,
  DANGEROUS_OBJECT_LAYER,
  CULTURE_OBJECT_LAYER,
];
