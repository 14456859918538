import React, { FC } from 'react';
import styles from '../object-card-panel.module.scss';
import { CloseButton, ModalHeaderButtons, TableCell } from '@/components';
import { ObjectCardTitlePropsType } from '../object-card-panel.types';

export const ObjectCardTitle: FC<ObjectCardTitlePropsType> = ({
  item,
  onClick3D,
  onClickCentered,
  onClickExport,
  onClickDelete,
  exportLoading,
  isEditable,
  deleteLoading,
  onClose,
  loading,
}) => {
  return (
    <div className={styles.title}>
      <div className={styles.label}>
        <TableCell
          value={loading ? 'Загрузка...' : item?.name}
          className={styles['title-text']}
          maxWidth={1400}
          fontSize={18}
        />
        <span>Карточка объекта</span>
      </div>
      <div className={styles['title-btn-group']}>
        <ModalHeaderButtons
          text={item?.has_3d ? 'Просмотр 3D-модели объекта' : '3D-модель не загружена'}
          onClick={() => onClick3D()}
          icon="outline-3D"
          disable={!item?.has_3d}
          placement="bottomLeft"
        />
        <ModalHeaderButtons
          text="Центрировать объект"
          onClick={() => onClickCentered(item?.id)}
          icon="outline-target"
          placement="bottomLeft"
        />
        {isEditable ? (
          <ModalHeaderButtons
            text="Экспорт в word"
            onClick={() => onClickExport(item?.id)}
            icon="outline-export-Word"
            placement="bottomLeft"
            loading={exportLoading}
          />
        ) : null}
        {isEditable ? (
          <ModalHeaderButtons
            text="Удалить объект"
            onClick={() => onClickDelete(item?.id)}
            icon="outline-trash"
            placement="bottomLeft"
            loading={deleteLoading}
          />
        ) : null}
        <CloseButton className={styles['close-button']} onClick={() => onClose()} />
      </div>
    </div>
  );
};
