import { Field, FieldProps, FormikValues, useField, useFormikContext } from 'formik';
import React, { FC, useEffect, useMemo } from 'react';
import { debounce, isEqual } from 'lodash';
import { InputFieldTypes } from './input-field.types';
import { ErrorMessage, Input } from '@/components';
import { ComponentSize, InputTypes } from '@/constants/enums';
import styles from '@/components/table-components/table-modal/table-modal.module.scss';
import { PasswordTooltipContent } from '../../authorization/new-password';

export const InputField: FC<InputFieldTypes> = ({
  name = 'name',
  placeholder = 'Нет добавленной информации.',
  label = '',
  type = InputTypes.text,
  className = '',
  requestError,
  maxLength,
  onToggleShowPassword,
  showToggleButton = true,
  size = ComponentSize.medium,
  required,
  showErrorPlaceholder = true,
  showInfoPassword = false,
  ...rest
}): JSX.Element => {
  const { validateField } = useFormikContext<FormikValues>();

  const [, { error, value, initialValue }, { setTouched, setError }] = useField<string>(name);

  const handleValidate = (
    name: string,
    value: string,
    initialValue?: string,
    error?: string,
  ): void => {
    validateField(name);
    setTouched(!isEqual(value, initialValue));
    setError(error);
  };

  const debounceValidate = useMemo(() => debounce(handleValidate, 300), []);

  useEffect(() => {
    if (value) {
      debounceValidate(name, value, initialValue, error);
    }
  }, [name, value, initialValue, error]);

  useEffect(() => {
    setError(undefined);
  }, []);

  return (
    <div className={className} key={name}>
      <Field name={name}>
        {({ meta: { error, touched }, field }: FieldProps): JSX.Element => (
          <>
            {(label || showInfoPassword) && (
              <div className={styles.info}>
                {label && <label className="mb-1">{required ? label + '*' : label}</label>}
                {showInfoPassword && <PasswordTooltipContent />}
              </div>
            )}
            <Input
              {...field}
              id={name}
              type={type}
              value={value}
              maxLength={maxLength}
              onToggleShowPassword={(value) => {
                onToggleShowPassword && onToggleShowPassword(value);
              }}
              showToggleButton={showToggleButton}
              placeholder={placeholder}
              size={size}
              error={(error && touched) || !!requestError}
              {...rest}
            />
            {showErrorPlaceholder && <ErrorMessage name={name} />}
          </>
        )}
      </Field>
    </div>
  );
};
