import React from 'react';
import { Modal } from '@/components';
import { ModalPosition } from '@/constants/enums';
import { BookmarkPanelBody } from './bookmark-panel-body';
import { useNavigate } from 'react-router';
import { MAP } from '@/constants';
import styles from './bookmark-panel-body/bookmark-panel-body.module.scss';

export const BookmarkPanel = () => {
  const navigate = useNavigate();

  return (
    <Modal
      position={ModalPosition.left}
      onClose={() => navigate(MAP)}
      width={524}
      wrapClassName={styles.modal}
      title="Мои избранные виды"
    >
      <BookmarkPanelBody />
    </Modal>
  );
};
