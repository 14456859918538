import React, { FC } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { ShipBase } from '@/types';
import { handleSortNumber, handleSortString } from '@/utils';
import { defaultSearchProps, Icon, PanelTableButton, Table, TableCell } from '@/components';
import { ShipsBasesTablePropsType } from './ships-bases-table.types';

export const ShipsBasesTable: FC<ShipsBasesTablePropsType> = ({
  data,
  loading,
  error,
  isEditable,
  onClickDeleteBtn,
  onClickEditBtn,
  onClickFlyToBtn,
}): JSX.Element => {
  const columns: ColumnsType<ShipBase> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      align: 'left',
      width: 60,
      sorter: (first, second) => handleSortNumber(first?.id, second?.id),
    },
    {
      title: <Icon size={12} icon="outline-geo-alt" />,
      dataIndex: 'id',
      fixed: 'left',
      width: 40,
      align: 'center',
      render: (value) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Приблизить к базе судов"
          icon="outline-geo-alt"
          onClick={() => onClickFlyToBtn(value)}
          size={12}
          tooltipPlacement={'bottom'}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      dataIndex: 'id',
      fixed: 'left',
      width: 40,
      align: 'center',
      key: 'editable',
      render: (value: number, row) => (
        <PanelTableButton
          onClick={() => onClickEditBtn(row)}
          tooltipTitle="Редактировать базу судна"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      dataIndex: 'id',
      fixed: 'left',
      align: 'center',
      key: 'editable',
      width: 40,
      render: (value: number) => (
        <PanelTableButton
          onClick={() => onClickDeleteBtn(value)}
          tooltipTitle="Удалить базу судна"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (value: string) => <TableCell value={value} maxWidth={460} />,
      sorter: (first, second) => handleSortString(first?.name, second?.name),
      ...defaultSearchProps('name'),
    },
  ];

  const preparedColumns = isEditable ? columns : columns.filter((item) => item.key !== 'editable');

  return (
    <Table
      dataSource={data}
      columns={preparedColumns}
      loading={loading}
      error={error}
      scroll={{ x: 600, y: 500 }}
      withSelection={false}
    />
  );
};
