import React, { FC } from 'react';
import styles from './text-section.module.scss';
import { TextSectionPropsType } from './text-section.types';
import { ObjectCardField } from '@/components';

export const ObjectCardTextSection: FC<TextSectionPropsType> = ({
  fields = [],
  isEdit = false,
  isEditable = false,
}) => {
  return (
    <div className={styles.wrapper}>
      {fields?.map(({ label, name, ...rest }) => (
        <div className={styles.item} key={`text-section-${name}`}>
          {label && <span className={styles.label}>{label + ':'}</span>}
          <ObjectCardField
            name={name}
            placeholder={isEditable ? 'Редактируйте раздел для заполнения' : 'Нет информации'}
            styles={styles}
            isEdit={isEdit}
            {...rest}
          />
        </div>
      ))}
    </div>
  );
};
