import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  ReactNode,
} from 'react';
import { useLocation } from 'react-router';

type HistoryBackstackContextType = {
  backstack: string[];
  setBackstack: Dispatch<SetStateAction<string[]>>;
};

export const HistoryBackstackContext = createContext<HistoryBackstackContextType>({
  backstack: [],
  setBackstack: () => {},
});

export const useHistoryBackstack = () => useContext(HistoryBackstackContext);

export const HistoryBackstackProvider = ({ children }: { children: ReactNode }) => {
  const [backstack, setBackstack] = useState<string[]>([]);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== backstack[backstack.length - 1]) {
      setBackstack([...backstack, location.pathname]);
    }
  }, [location]);

  return (
    <HistoryBackstackContext.Provider value={{ backstack, setBackstack }}>
      {children}
    </HistoryBackstackContext.Provider>
  );
};
