import { Table, TableCell } from '@/components';
import { ExpeditionStatuses } from '@/constants/enums';
import { ColumnsType } from 'antd/es/table';
import React, { FC } from 'react';
import { BaseSectionPropsType, ExpeditionCardSection } from '../index';
import styles from '../section.module.scss';
import dayjs from 'dayjs';

export const DatesSection: FC<BaseSectionPropsType> = ({ item, label }) => {
  const preparedRows = [
    {
      section: 'created_at',
      value: item?.created_at || '',
      name: 'Создана',
      id: 1,
    },
    {
      section: 'planned_at',
      value: item?.planned_at || '',
      name: 'Дата, планируемого завершения заполнения карточки',
      id: 2,
    },
    {
      section: 'tentative_start_date',
      value: item?.tentative_start_date || '',
      name: 'Планируемая дата начала',
      id: 3,
    },
    {
      section: 'tentative_end_date',
      value: item?.tentative_end_date || '',
      name: 'Планируемая дата окончания',
      id: 4,
    },
    {
      section: 'actual_start_date',
      value: item?.actual_start_date || '',
      name: 'Фактическая дата начала',
      id: 5,
      hidden: item?.status === ExpeditionStatuses.plan || item?.status === ExpeditionStatuses.card,
    },
    {
      section: 'actual_end_date',
      value: item?.actual_end_date || '',
      name: 'Фактическая дата окончания',
      id: 6,
      hidden: item?.status === ExpeditionStatuses.plan || item?.status === ExpeditionStatuses.card,
    },
    {
      section: 'reported_at',
      value: item?.reported_at || '',
      name: 'Создание отчета',
      id: 7,
      hidden: item?.status === ExpeditionStatuses.plan || item?.status === ExpeditionStatuses.card,
    },
    {
      section: 'approved_at',
      value: item?.approved_at || '',
      name: 'Отчет утвержден',
      hidden: item?.status === ExpeditionStatuses.plan || item?.status === ExpeditionStatuses.card,
      id: 8,
    },
  ];

  const preparedData = preparedRows.filter((item) => !item.hidden);

  const columns: ColumnsType<typeof preparedData[0]> = [
    {
      dataIndex: 'name',
      title: 'Наименование',
      align: 'left',
      width: 200,
      render: (value: string) => <TableCell value={value} />,
    },
    {
      title: 'Пользователь',
      dataIndex: 'section',
      key: 'section',
      width: 200,
      render: (value: string) => (
        <TableCell
          value={item?.history_logs.find((item) => item.section === value)?.user?.email || ''}
        />
      ),
    },
    {
      title: 'Дата',
      width: 200,
      dataIndex: 'value',
      align: 'left',
      render: (value: string) => (
        <TableCell
          maxWidth={200}
          value={
            value
              ? dayjs(value, { utc: true }).isValid()
                ? dayjs(value, { utc: true }).format()
                : value
              : ''
          }
        />
      ),
    },
  ];

  return (
    <ExpeditionCardSection label={label}>
      <Table
        withSelection={false}
        dataSource={preparedData}
        columns={columns}
        className={styles.table}
        withPagination={false}
        scroll={{ x: 1225, y: 600 }}
      />
    </ExpeditionCardSection>
  );
};
