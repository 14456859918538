import React, { FC } from 'react';
import { Modal } from '@/components';
import { MAP } from '@/constants';
import styles from './table-panel.module.scss';
import { useNavigate } from 'react-router';
import { TablePanelPropsType } from './table-panel.types';

export const TablePanel: FC<TablePanelPropsType> = ({
  title,
  children,
  additionalTitle,
}): JSX.Element => {
  const navigate = useNavigate();

  const handleCloseModal = () => {
    navigate(MAP);
  };

  return (
    <Modal
      width={1654}
      mask={true}
      centered={true}
      wrapClassName={styles.modal}
      onClose={() => handleCloseModal()}
      additionalTitle={additionalTitle}
      title={title}
    >
      <div className={styles.wrapper}>{children}</div>
    </Modal>
  );
};
