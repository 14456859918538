import React, { FC, useEffect, useState } from 'react';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { Bounds, Center } from '@react-three/drei';
import { ObjectModelPropsType } from './object-model.types';
import { useGet3DModelQuery } from '@/state/api';
import { openErrorNotify } from '@/utils';
import { Group } from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { Loader } from '../loader';
import { Models3DTypes } from '@/constants/enums';

export const ObjectModel: FC<ObjectModelPropsType> = ({ id, setName, intensity, onFitBounds }) => {
  const { data, isError, isSuccess } = useGet3DModelQuery({ id: id });
  const [isModelReady, setIsModelReady] = useState(false);
  const [object, setObject] = useState<GLTF | Group | null>(null);

  useEffect(() => {
    if (isError) {
      openErrorNotify(
        'Произошла ошибка',
        'При загрузке 3D-модели произошла ошибка, либо 3D-модель не существует',
      );
      setIsModelReady(true);
    }
  }, [isError]);

  const handleModelRender = () => {
    if (data?.type === Models3DTypes.glb || data?.type === Models3DTypes.gltf) {
      const loader = new GLTFLoader();
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderConfig({ type: 'js' });
      dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
      loader.setDRACOLoader(dracoLoader);

      loader.load(
        `data:application/octet-stream;base64,${String(data.scene)}`,
        (gltf) => {
          setObject(gltf);
          setIsModelReady(true);
        },
        () => {},
        () => openErrorNotify('Не удалось обработать 3D-модель'),
      );
    }
    if (data?.type === Models3DTypes.obj) {
      const loader = new OBJLoader();
      loader.load(
        `data:application/octet-stream;base64,${String(data.scene)}`,
        (group) => {
          setObject(group);
          setIsModelReady(true);
        },
        () => {},
        () => openErrorNotify('Не удалось обработать 3D-модель'),
      );
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setName(data?.name || '');
      handleModelRender();
    }
  }, [isSuccess]);

  return (
    <>
      {!isModelReady ? (
        <Loader />
      ) : (
        <>
          <ambientLight intensity={intensity} position={[0, 10, 10]} />
          <Bounds fit clip margin={1} onFit={() => setTimeout(() => onFitBounds(), 500)}>
            <Center position={[0, 0, 0]}>
              <group dispose={null}>
                <primitive
                  object={(object && 'scene' in object && object?.scene) || object || {}}
                />
              </group>
            </Center>
          </Bounds>
        </>
      )}
    </>
  );
};
