import { Input } from 'antd';
import React, { ChangeEvent, FC, useCallback } from 'react';
import { SearchPanelHeaderType } from './index.types';
import { Icon, Spinner } from '@/components';
import styles from './styles.module.scss';

export const SearchPanelHeader: FC<SearchPanelHeaderType> = ({
  onClear,
  onSearch,
  onClick,
  onChange,
  searchValue,
  loading,
  inputRef,
  isOpen,
}): JSX.Element => {
  const handleClick = useCallback(() => !loading && onClick(true), [isOpen, loading]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onChange(event?.target.value),
    [],
  );

  return (
    <div className={styles.wrapper} onClick={handleClick}>
      <Icon size={16} icon="outline-ship" />
      <Input
        size="small"
        placeholder="Поиск по объектам..."
        ref={inputRef}
        bordered={false}
        value={searchValue}
        onChange={handleChange}
      />
      {loading ? (
        <Spinner />
      ) : !searchValue ? (
        <Icon size={16} icon="outline-search" onClick={() => onSearch()} />
      ) : (
        <Icon size={16} icon="outline-close" onClick={() => onClear()} />
      )}
    </div>
  );
};
