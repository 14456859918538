import styles from '../coordinate-converter-panel.module.scss';
import { Input } from 'antd';
import { useField, useFormikContext } from 'formik';
import React, { ChangeEvent, FC } from 'react';
import { CordFieldGroupPropsType } from '../coordinate-converter.types';

export const DecimalCordField: FC<CordFieldGroupPropsType> = ({
  name,
  label,
  placeholder,
}): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const handleChangeField = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value) {
      setFieldValue('cordType', 'decimalCord');
    } else {
      setFieldValue('cordType', '');
    }
  };

  return (
    <div className={styles.field}>
      <span className={styles.label}>{label}</span>
      <Input
        {...field}
        maxLength={18}
        onChange={handleChangeField}
        placeholder={placeholder}
        autoComplete="off"
      />
    </div>
  );
};
