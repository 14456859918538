import { Button } from 'antd';
import styles from './button.module.scss';
import React, { FC } from 'react';
import { ButtonPropsTypes } from './button.types';
import { Spinner } from '@/components';
import { ComponentSize, Colors, FillTypes, ButtonTypes } from '@/constants/enums';

export const NewButton: FC<ButtonPropsTypes> = ({
  children,
  onClick,
  minWidth = 'auto',
  maxWidth = 'auto',
  className = '',
  tabIndex,
  shadow = false,
  disable = false,
  loading = false,
  size = ComponentSize.medium,
  color = Colors.secondary,
  fillType = FillTypes.fill,
  type = ButtonTypes.button,
  ...rest
}) => {
  const customClasses = [
    styles.wrapper,
    size ? styles[size] : '',
    color ? styles[color] : '',
    fillType ? styles[fillType] : '',
    shadow ? styles.shadow : '',
    className,
  ].join(' ');

  return (
    <div className={customClasses}>
      <Button
        {...rest}
        shape="round"
        tabIndex={tabIndex}
        disabled={disable || loading}
        htmlType={type}
        onClick={(event) => onClick && onClick(event)}
        style={{
          minWidth,
          maxWidth,
        }}
      >
        {loading ? <Spinner className={styles.spinner} /> : children}
      </Button>
    </div>
  );
};
