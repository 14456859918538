import { FC, useEffect, useRef, useState } from 'react';
import { Marker, Polygon, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-editable';
import { useMap } from '@/context';
import styles from './polygon-tool-panel.module.scss';
import { checkPolygon, getVertexMarker } from '@/utils';
import { PolygonEditableLayerPropsType } from './polygon-tool-panel.types';

export const PolygonEditableLayer: FC<PolygonEditableLayerPropsType> = ({
  polygon,
  name,
  setPolygon,
  tooltipClassname,
  pathOptions,
}) => {
  const { map } = useMap();
  const ref = useRef<L.Polygon>(null);
  const dashArray = [10, 20];
  const [localPolygon, setLocalePolygon] = useState<typeof polygon | null>(null);
  const tooltipRef = useRef<L.Tooltip>(null);

  const handlePolygonChange = () => {
    const coords = ref.current?.getLatLngs() as L.LatLng[][][];
    const preparedCoords = [
      [
        [
          ...coords[0][0].map((coord) => [
            Number(coord.lat.toFixed(6)),
            Number(coord.lng.toFixed(6)),
          ]),
          [Number(coords[0][0][0].lat.toFixed(6)), Number(coords[0][0][0].lng.toFixed(6))],
        ],
      ],
    ] as L.LatLngExpression[][][];
    setPolygon(preparedCoords);
    ref.current?.disableEdit();
  };

  const pathForEdit = { ...pathOptions, dashArray: dashArray };

  useEffect(() => {
    ref.current?.bringToFront();
  }, [map, ref.current]);

  useEffect(() => {
    if (checkPolygon(polygon)) {
      setLocalePolygon(polygon);
      ref.current?.enableEdit();
    }
  }, [polygon, name]);

  useEffect(() => {
    ref.current?.enableEdit();
    map?.on('editable:vertex:deleted', handlePolygonChange);
    map?.on('editable:vertex:new', handlePolygonChange);
    map?.on('editable:vertex:dragend', handlePolygonChange);
    tooltipRef.current && ref.current?.bindTooltip(tooltipRef.current);
    return () => {
      ref.current?.disableEdit();
      map?.off('editable:vertex:deleted', handlePolygonChange);
      map?.off('editable:vertex:new', handlePolygonChange);
      map?.off('editable:vertex:dragend', handlePolygonChange);
      tooltipRef.current && ref.current?.bindTooltip(tooltipRef.current);
    };
  }, [map, localPolygon]);

  return (
    <>
      {localPolygon && localPolygon[0][0].length
        ? localPolygon[0][0].map((item, idx) => {
            return idx !== localPolygon[0][0].length - 1 ? (
              <Marker
                key={idx}
                icon={getVertexMarker(`T${idx + 1}`, styles['vertex-marker'])}
                position={item}
              ></Marker>
            ) : null;
          })
        : null}
      {!!(localPolygon && localPolygon[0][0].length) ? (
        <>
          <Polygon
            key={name?.concat('1')}
            ref={ref}
            positions={localPolygon}
            pathOptions={pathOptions}
          >
            <Tooltip
              permanent={true}
              ref={tooltipRef}
              direction={'top'}
              className={tooltipClassname}
            >
              {name}
            </Tooltip>
          </Polygon>
          <Polygon
            key={name?.concat('2')}
            positions={localPolygon}
            pathOptions={pathForEdit}
          ></Polygon>
        </>
      ) : null}
    </>
  );
};
