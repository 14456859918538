import React, { FC } from 'react';
import styles from './table-cell.module.scss';
import { Popover } from 'antd';
import { TableCellForNoData } from './table-cell-for-no-data';
import { TableCellForString } from './table-cell-for-string';
import { TableCellForArrayPropsType } from './table-cell.types';

export const TableCellForArray: FC<TableCellForArrayPropsType> = ({
  value = [],
  className = '',
  label,
  maxWidth,
  fontSize,
}) => {
  switch (value?.length) {
    case 0:
      return <TableCellForNoData label={label} className={className} />;
    case 1:
      return (
        <TableCellForString
          value={value[0].name}
          maxWidth={maxWidth}
          link={value[0]?.link}
          className={className}
          fontSize={fontSize}
        />
      );
    default:
      const preparedData = value.slice(1);

      return (
        <Popover
          placement="bottom"
          overlayClassName={styles.array}
          content={preparedData.map(({ link, name }, idx) => (
            <div className={link ? className + ' ' + styles['popover-link'] : className} key={idx}>
              {link ?? <span>{name}</span>}
            </div>
          ))}
        >
          <div className={styles['array-cell']}>
            <TableCellForString
              value={value[0].name}
              maxWidth={maxWidth}
              link={value[0]?.link}
              className={className}
              fontSize={fontSize}
            />
            &nbsp;
            {preparedData.length > 0 ? `и еще ${preparedData.length}` : ''}
          </div>
        </Popover>
      );
  }
};
