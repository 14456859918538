import { api } from '../api';
import { DefaultResponse, EditBookmark, GetBookmarkResponse } from '@/types';

export const bookmarksApi = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getBookmarks: builder.query<GetBookmarkResponse, void>({
        query() {
          return {
            url: '/bookmarks/list',
            method: 'GET',
            credentials: 'include',
          };
        },
        providesTags: ['Bookmarks'],
      }),
      createBookmark: builder.mutation<DefaultResponse, EditBookmark>({
        query(body) {
          return {
            url: '/bookmarks/add',
            method: 'POST',
            body,
            credentials: 'include',
          };
        },
        invalidatesTags: ['Bookmarks'],
      }),
      editBookmark: builder.mutation<DefaultResponse, EditBookmark>({
        query(body) {
          return {
            url: '/bookmarks/edit',
            method: 'PUT',
            body,
            credentials: 'include',
          };
        },
        invalidatesTags: ['Bookmarks'],
      }),
      deleteBookmark: builder.mutation<DefaultResponse, number>({
        query(id) {
          return {
            url: '/bookmarks/delete',
            method: 'DELETE',
            body: { id: id },
            credentials: 'include',
          };
        },
        invalidatesTags: ['Bookmarks'],
      }),
    };
  },
});

export const {
  useGetBookmarksQuery,
  useCreateBookmarkMutation,
  useEditBookmarkMutation,
  useDeleteBookmarkMutation,
} = bookmarksApi;
