export interface ClassificationItemTypes {
  type: ClassificationItemEnum | number;
}

export enum ClassificationItemEnum {
  'default' = 'outline-ship',
  'technical' = 'outline-technical',
  'PDO' = 'outline-PDO',
  'culture' = 'outline-amphora-2',
  'military' = 'outline-military-2',
  'unexplored' = 'outline-unexplored',
}
