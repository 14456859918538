import React, { FC } from 'react';
import { menuData, ObjectCardItem } from '@/components';
import { ObjectCardPropsType } from '../object-card-panel.types';

export const ObjectFixationSoilPlacementSection: FC<ObjectCardPropsType> = ({
  onSubmit,
  success,
  loading,
  isEditable,
}) => {
  const fields = [
    {
      label: 'Длина',
      placeholder: 'Длина не указана',
      name: 'attach_frame_length',
    },
    {
      label: 'Ширина по мидель шпангоуту',
      placeholder: 'Ширина не указана',
      name: 'attach_frame_width',
    },
    {
      label: 'Угол дифферента',
      placeholder: 'Угол не указан',
      name: 'attach_angle_different',
    },
    {
      label: 'Угол крена',
      placeholder: 'Угол не указан',
      name: 'attach_angle_heeling',
    },
  ];

  return (
    <ObjectCardItem
      title={menuData[2]?.subtitle?.[1].label}
      smallTitle={true}
      type="column"
      fields={fields}
      isEditable={isEditable}
      id={menuData[2]?.subtitle?.[1].link}
      onSubmit={() => onSubmit()}
      success={success}
      loading={loading}
    />
  );
};
