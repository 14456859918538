import React, { FC, useEffect } from 'react';
import styles from './media-files-panel.module.scss';
import { ImageGallery, Modal, TableEmpty, TableLoader } from '@/components';
import { useLocation, useNavigate, useParams } from 'react-router';
import { LINK_TO_OBJECT_FORMULAR, MAP } from '@/constants';
import { Collapse } from 'antd';
import { useGetMediaListQuery, useGetObjectsQuery, useLazyGetMediaItemQuery } from '@/state/api';
import { MediaFilesPanelFileRow } from './media-files-panel-file-row';
import { getFileTypeByPath, getFileTypeNameByPath, getNameByPath, openErrorNotify } from '@/utils';
import { MediaTargetsTypes } from '@/constants/enums';
import { DefaultMediaType, DefaultResponse } from '@/types';

const { Panel } = Collapse;

export const MediaFilesPanel: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const id = params?.id || (location.state?.id as string);

  const { data, isError, isLoading } = useGetMediaListQuery({
    object_id: +id,
  });
  const [downloadMedia, { isSuccess: downloadSuccess, isLoading: downloadLoading }] =
    useLazyGetMediaItemQuery();
  const { data: objectsData } = useGetObjectsQuery();

  const photo = data?.data?.photo || [];
  const files = data?.data?.documents || [];
  const preparedData = objectsData?.objects || [];

  const object = preparedData.find((object) => object.id === +id);
  const activeKey = !!photo.length ? '1' : !!files.length ? '2' : '';

  const isCollapsible = (data: DefaultMediaType[]) => (!!data?.length ? 'header' : 'disabled');

  const handleCloseModal = (id?: number) => {
    if (id) {
      navigate(LINK_TO_OBJECT_FORMULAR(id));
    } else {
      navigate(MAP);
    }
  };

  const handleDownload = (item: DefaultMediaType) => {
    const preparedData = {
      path: item?.path,
      type: MediaTargetsTypes.object,
    };
    const name: string = getNameByPath(item?.path) || item.name || '';
    const type: string = getFileTypeByPath(item?.path) || item?.media_type;
    const typeName: string = getFileTypeNameByPath(item?.path) || '';

    downloadMedia(preparedData).then((res) => {
      if (res?.data?.success) {
        const data: string = res?.data?.data;
        const link = document.createElement('a');
        link.href = `data:application/${type};base64, ${data}`;
        link.target = '_blank';
        link.download = `${name}.${typeName}`;
        link.click();
      } else {
        const error = res?.error as DefaultResponse;
        openErrorNotify(
          'Произошла ошибка',
          <>
            При загрузке файла <b>{name}</b> произошла ошибка.
            <br />
            {res?.data?.message || error?.data?.message}
          </>,
        );
      }
    });
  };

  useEffect(() => {
    if (isError) {
      openErrorNotify('Произошла ошибка', 'При загрузке списка вложений произошла ошибка');
    }
  }, [isError]);

  return (
    <Modal
      mask={true}
      centered={true}
      onClose={() => handleCloseModal(+id)}
      wrapClassName={styles.modal}
      title="Медиа-файлы"
      additionalTitle={<span>{object?.name}</span>}
      width={800}
    >
      <Collapse defaultActiveKey={activeKey} className={styles.wrapper}>
        {isLoading ? (
          <TableLoader className={styles.loader} />
        ) : !activeKey ? (
          <TableEmpty size={50} />
        ) : (
          <>
            <Panel
              header={`Фотографии (${photo.length})`}
              collapsible={isCollapsible(photo)}
              key="1"
            >
              <ImageGallery
                className={styles['photo-row']}
                data={photo}
                itemSize={90}
                mediaType={MediaTargetsTypes.object}
              />
            </Panel>
            <Panel
              header={`Документы (${files.length})`}
              collapsible={isCollapsible(files)}
              key="2"
            >
              <div className={styles['file-row']}>
                {files.map((item) => (
                  <MediaFilesPanelFileRow
                    type={getFileTypeNameByPath(item?.path)}
                    name={getNameByPath(item?.path)}
                    key={`file-${String(item?.path)}`}
                    onClick={() => handleDownload(item)}
                    success={downloadSuccess}
                    loading={downloadLoading}
                  />
                ))}
              </div>
            </Panel>
          </>
        )}
      </Collapse>
    </Modal>
  );
};
