import { api } from '../api';
import { DefaultResponse, GetToolResponse } from '@/types';

export const toolsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getToolsList: builder.query<
      GetToolResponse,
      {
        expId: number;
      } | void
    >({
      query(body) {
        return {
          url: '/tool/list',
          method: 'GET',
          params: { expId: body?.expId },
          credentials: 'include',
        };
      },
      providesTags: ['Tools'],
    }),
    createTool: builder.mutation<
      DefaultResponse,
      {
        name: string;
        comment?: string;
      }
    >({
      query(body) {
        return {
          url: '/tool/create',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Tools'],
    }),
    editTool: builder.mutation<
      DefaultResponse,
      {
        id: string;
        name: string;
        comment?: string;
      }
    >({
      query(body) {
        return {
          url: '/tool/edit',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Tools'],
    }),
    deleteTool: builder.mutation<
      DefaultResponse,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/tool',
          method: 'DELETE',
          body: { id: body.id },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Tools'],
    }),
  }),
});

export const {
  useCreateToolMutation,
  useEditToolMutation,
  useDeleteToolMutation,
  useGetToolsListQuery,
} = toolsApi;
