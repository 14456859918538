import React, { FC, useEffect, useState } from 'react';
import styles from '../route-panel-table.module.scss';
import { Table } from 'antd';
import L, { Marker, Polyline } from 'leaflet';
import { useAppSelector } from '@/state';
import { useClickPosition, useMap } from '@/context';
import { IPoint, IPointValue, ITableCoords, TColumns } from '@/types';
import save from 'lodash';
import { convertToDecimalCord, getPointMarker, polylineOptions } from '@/utils';
import {
  renderMarkerTextToolTip,
  renderPolylineTextToolTip,
  renderTable,
} from '../../route-panel-utils';
import { setIsAddCoords } from '@/state/slice';
import { useDispatch } from 'react-redux';

export const RouteTableRows: FC<ITableCoords> = ({ coords, setCoords, children }) => {
  const { map } = useMap();
  const checkMap = map !== null;
  const dispatch = useDispatch();
  const [deleteMarker, setDeleteMarker] = useState<(Marker | undefined)[]>([]);
  const [deletePolyline, setDeletePolyline] = useState<(Polyline | undefined)[]>([]);
  const { position } = useClickPosition();
  const [columns, setColumns] = useState<TColumns>(() => {
    return renderTable({ coords, setCoords });
  });
  const activePanelRoute = useAppSelector((state) => {
    return state.toolsPanels.routePanel.activePanelRoute;
  });
  const isShow = useAppSelector((state) => {
    return state.toolsPanels.routePanel.isShow;
  });
  const isSave = useAppSelector((state) => {
    return state.toolsPanels.routePanel.isSave;
  });
  const coordsType = useAppSelector((state) => {
    return state.map.coordsType;
  });

  const handleEffectPoint = (): void => {
    if (activePanelRoute && position) {
      if (position) {
        if (position.lat && position.lng) {
          const coordsTable: IPoint = {
            name: `Координата T${coords.length + 1}`,
            key: `${coords.length + 1}`,
            value: {
              lat: position.lat,
              lng: position.lng,
              gpsLat: convertToDecimalCord(position.lat.toString(), true),
              gpsLng: convertToDecimalCord(position.lng.toString(), false),
              distance: 0,
            },
          };

          const coordsFirst: IPoint = coords[0];
          coordsFirst.value?.isDefault
            ? setCoords([coordsTable])
            : setCoords([...coords, coordsTable]);
        }
      }
    }
  };

  const handleEffectMap = (): void => {
    if (!coords[0].value?.isDefault) {
      deleteMarker?.map((item: Marker | undefined) => {
        item && item.remove();
      });

      deletePolyline?.map((item: Polyline | undefined) => {
        item && item.remove();
      });

      coords.map((coordsDefault, index) => {
        coordsDefault.key = `${index + 1}coords`;
        coordsDefault.name = `Координата T${index + 1}`;
        if (coordsDefault.value && map) {
          coordsDefault.value.id = index + 1;

          coordsDefault.value.marker && coordsDefault.value.marker.remove();
          coordsDefault.value.marker && delete coordsDefault.value.marker;

          const coordsFirstLatLng: [number, number] = [
            coordsDefault.value.lat as number,
            coordsDefault.value.lng as number,
          ];

          const marker: Marker = L.marker(coordsFirstLatLng).addTo(map);
          const text = renderMarkerTextToolTip(coordsDefault.value);
          marker.bindTooltip(text);

          if (coords[0].value === coordsDefault.value) {
            marker.setIcon(
              getPointMarker(styles['no-default-marker'], coordsDefault.value.id, 'first'),
            );
          } else if (coords.slice(-1)[0].value === coordsDefault.value) {
            marker.setIcon(
              getPointMarker(styles['no-default-marker'], coordsDefault.value.id, 'last'),
            );
          } else {
            marker.setIcon(getPointMarker(styles['default-marker'], coordsDefault.value.id));
          }
          coordsDefault.value.marker = marker;
          setDeleteMarker((prevState) => {
            return [...prevState, marker];
          });
        }

        if (coords[index + 1] && coords.length > 1) {
          const coordsOne: IPointValue = coords[index].value as IPointValue;
          const coordsTwo: IPointValue = coords[index + 1].value as IPointValue;

          coordsTwo.polyline?.remove();
          delete coordsTwo.polyline;

          const polylinePoints = [
            [coordsOne.lat as number, coordsOne.lng as number],
            [coordsTwo.lat as number, coordsTwo.lng as number],
          ];

          const distance = coordsTwo.distance;
          const time = coordsTwo.time;

          if (!isShow) {
            const text = renderPolylineTextToolTip(distance as number, time as string);
            const polyLineThis = new L.Polyline(polylinePoints as [], polylineOptions);
            polyLineThis.bindTooltip(text, { permanent: true });

            coordsTwo.polyline = polyLineThis;
            coordsTwo.polyline.openTooltip();

            setDeletePolyline((prevState) => {
              return [...prevState, polyLineThis];
            });

            map && map.addLayer(polyLineThis);
          }
        }
      });
      coords[0].value?.marker?.on('click', () => {
        dispatch(setIsAddCoords(false));
      });
    }

    setColumns(renderTable({ coords, setCoords }));
  };

  const handleSaveChange = (cloneFunc: (point: IPoint) => IPoint, power: boolean): void => {
    if (isSave) {
      const coordsWithSave = coords.map((defaultCoords: IPoint) => {
        const saveCoords = cloneFunc(defaultCoords);
        if (power) {
          saveCoords.value?.polyline?.remove();
          saveCoords.value?.marker?.remove();
        }
        delete saveCoords.value?.polyline;
        delete saveCoords.value?.marker;

        return saveCoords;
      });
      localStorage.setItem('routePathCoords', JSON.stringify(coordsWithSave));
    } else {
      localStorage.setItem('routePathCoords', '');
    }
  };

  useEffect(() => {
    handleSaveChange((point) => save.clone(point), true);
  }, [coords]);

  useEffect(() => {
    handleSaveChange((point) => save.cloneDeep(point), false);
  }, [isSave]);

  useEffect(() => {
    coords.map((coordsDefault: IPoint) => {
      const coordsValue = coordsDefault.value as IPointValue;
      coordsValue.renderLat = coordsType === 'DEC' ? coordsValue.lat : coordsValue.gpsLat;
      coordsValue.renderLng = coordsType === 'DEC' ? coordsValue.lng : coordsValue.gpsLng;
    });
    setColumns(renderTable({ coords, setCoords }));
  }, [coordsType, coords]);

  useEffect(() => {
    handleEffectPoint();
  }, [position]);

  useEffect(() => {
    handleEffectMap();

    return () => {
      coords.map((coordsOne) => {
        coordsOne.value?.marker?.remove();
        coordsOne.value?.polyline?.remove();
      });
    };
  }, [coords, checkMap, isShow]);

  return (
    <Table
      key={'table_1'}
      pagination={false}
      className={styles.table}
      columns={columns}
      dataSource={coords}
      scroll={{ y: 271 }}
      summary={() => children}
    ></Table>
  );
};
