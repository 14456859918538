import { Form, Formik } from 'formik';
import styles from './new-password.module.scss';
import React, { FC } from 'react';
import * as Yup from 'yup';
import { passwordStrengthValidation } from '@/utils';
import { ButtonTypes, Colors, ComponentSize, FillTypes, InputTypes } from '@/constants/enums';
import { Button } from '@/components/common/button';
import { ErrorPlaceholder } from '@/components/authorization';
import { InputField } from '@/components';
import { NewPasswordInitialValues, NewPasswordTypes } from './new-password.types';

export const NewPasswordForm: FC<NewPasswordTypes> = ({
  onSubmitForm,
  onCancel,
  loading,
  error,
  onToggleShowPassword,
  filedTypes,
  isError,
}): JSX.Element => {
  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Пожалуйста, введите пароль')
      .test('verify', 'Пароль недостаточно сложный.', (password) =>
        passwordStrengthValidation(password),
      ),
    confirm_password: Yup.string()
      .required('Пожалуйста, подтвердите пароль')
      .oneOf([Yup.ref('password'), null], 'Пароли не совпадают, проверьте написание пароля.'),
  });

  const initialValues: NewPasswordInitialValues = {
    password: '',
    confirm_password: '',
  };

  return (
    <Formik
      onSubmit={onSubmitForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnMount={true}
    >
      {({ dirty, isValid, handleSubmit, touched, errors }) => (
        <Form
          className={styles.wrapper}
          onSubmit={(event) => {
            event && event.preventDefault();
            handleSubmit();
          }}
        >
          <InputField
            name="password"
            label="Пароль"
            placeholder="Введите пароль"
            size={ComponentSize.large}
            type={InputTypes.password}
            className={styles.field}
            onToggleShowPassword={onToggleShowPassword}
            showErrorPlaceholder={false}
            required={true}
            showInfoPassword={true}
          />
          <InputField
            name="confirm_password"
            label="Подтвердите пароль"
            placeholder="Введите пароль"
            size={ComponentSize.large}
            type={filedTypes}
            className={styles.field}
            onToggleShowPassword={onToggleShowPassword}
            showErrorPlaceholder={false}
            showToggleButton={false}
            required={true}
          />
          <ErrorPlaceholder
            errors={errors}
            requestError={error}
            touched={touched}
            isError={isError}
          />
          <div className={styles.footer}>
            <Button
              minWidth={312}
              color={Colors.black}
              size={ComponentSize.large}
              type={ButtonTypes.submit}
              disable={!dirty || !isValid}
              loading={loading}
            >
              Сменить пароль
            </Button>
            <Button
              minWidth={312}
              color={Colors.black}
              fillType={FillTypes.outline}
              size={ComponentSize.large}
              type={ButtonTypes.button}
              onClick={() => onCancel()}
            >
              Отмена
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
