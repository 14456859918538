import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from '../../default-tab.module.scss';
import {
  BlackTooltip,
  ConfirmModal,
  FieldType,
  Icon,
  PanelTabButton,
  TableModal,
  ToolsTable,
} from '@/components';
import {
  useCreateToolMutation,
  useDeleteToolMutation,
  useEditToolMutation,
  useGetToolsListQuery,
} from '@/state/api/tools-api/tools-api';
import { ComponentSize, InputTypes, PanelTabButtonTypes, Permissions } from '@/constants/enums';
import * as Yup from 'yup';
import { NewTool, Tool } from '@/types';
import { openErrorNotify, openInfoNotify } from '@/utils';
import { useAppSelector } from '@/state';

export const ToolsTab: FC = (): JSX.Element => {
  const [showAddToolModal, setShowAddToolModal] = useState(false);
  const [showEditToolModal, setShowEditToolModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [selectedTool, setSelectedTool] = useState<Tool>();
  const [createdName, setCreatedName] = useState<string>('');
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);
  const isEditable = userPermissions?.includes(Permissions.expeditionsDataManagment);

  const { data, isLoading, isError } = useGetToolsListQuery();
  const [
    createTool,
    {
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
      reset: resetCreate,
    },
  ] = useCreateToolMutation();
  const [
    editTool,
    { isLoading: editLoading, isSuccess: editSuccess, isError: editError, reset: resetEdit },
  ] = useEditToolMutation();
  const [
    deleteTool,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      reset: resetDelete,
    },
  ] = useDeleteToolMutation();

  const preparedTools = data?.tools || [];

  const initialValues: NewTool = {
    name: '',
    comment: '',
  };

  const fields: FieldType[] = [
    {
      type: InputTypes.text,
      label: 'Наименование',
      name: 'name',
      placeholder: 'Введите наименование',
      required: true,
    },
    {
      type: InputTypes.textArea,
      label: 'Комментарий',
      name: 'comment',
      placeholder: 'Введите комментарий',
    },
  ];

  const validationSchema = Yup.object({
    name: Yup.string().required('Пожалуйста, введите наименование МТО'),
  });

  const getTool = (id: number) => {
    const item = preparedTools.find((tool) => tool.id === id);
    item && setSelectedTool(item);
  };

  const handleEdit = (id: number) => {
    getTool(id);
    resetEdit();
    setShowEditToolModal(true);
  };

  const handleDelete = (id: number) => {
    getTool(id);
    resetDelete();
    setShowConfirmDeleteModal(true);
  };

  const handleCreateTool = (values: NewTool): void => {
    setCreatedName(values.name);
    createTool(values);
  };

  const handleEditTool = useCallback(({ id, name, comment }: Tool): void => {
    const preparedValue = {
      id: id.toString(),
      name,
      comment,
    };
    editTool(preparedValue);
  }, []);

  const handleDeleteTool = (id: number): void => {
    deleteTool({
      id: id,
    });
  };

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify(
        'Добавление МТО',
        <>
          Инструмент <b>{createdName}</b> был добавлен
        </>,
      );
    }
    if (createError) {
      openErrorNotify('Произошла ошибка', 'При добавлении МТО произошла ошибка');
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify(
        'Изменение МТО',
        <>
          Инструмент <b>{selectedTool?.name}</b> был изменен
        </>,
      );
    }
    if (editError) {
      openErrorNotify('Произошла ошибка', 'При редактировании МТО произошла ошибка');
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Удаление МТО',
        <>
          Инструмент <b>{selectedTool?.name}</b> был удален
        </>,
      );
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении МТО произошла ошибка');
    }
  }, [deleteSuccess, deleteError]);

  return (
    <div className={styles.wrapper}>
      {!isError && !isLoading && (
        <div className={styles.header}>
          <BlackTooltip
            title={'Перечень материально-технического обеспечения'}
            placement={'bottomLeft'}
          >
            <PanelTabButton
              className={styles['button-panel-help']}
              onClick={() => {}}
              type={PanelTabButtonTypes.icon}
              size={ComponentSize.small}
            >
              <Icon icon="outline-question-circle" size={14} />
            </PanelTabButton>
          </BlackTooltip>
          {isEditable ? (
            <PanelTabButton
              disabled={isError || isLoading}
              type={PanelTabButtonTypes.roundSecondary}
              size={ComponentSize.small}
              onClick={() => {
                resetCreate();
                setShowAddToolModal((prevState) => !prevState);
              }}
            >
              Добавить новое МТО
            </PanelTabButton>
          ) : null}
        </div>
      )}
      {isEditable ? (
        <>
          <ConfirmModal
            open={showConfirmDeleteModal}
            title="Подтвердите удаление МТО"
            content={
              <p>
                Вы уверены, что хотите удалить&nbsp;
                {selectedTool?.name ? (
                  <>
                    МТО <strong>{selectedTool?.name}</strong>
                  </>
                ) : (
                  'выбранное МТО'
                )}
                ?
              </p>
            }
            submitButtonText="Удалить МТО"
            cancelButtonText="Отменить"
            type="danger"
            onSubmit={() => handleDeleteTool(selectedTool?.id as number)}
            onCancel={() => setShowConfirmDeleteModal((prevState) => !prevState)}
            loading={deleteLoading}
            success={deleteSuccess}
          />
          <TableModal
            onSubmit={(value) => handleEditTool(value as Tool)}
            buttonText="Изменить"
            onClose={() => setShowEditToolModal(false)}
            title="Редактирование МТО"
            validationSchema={validationSchema}
            initialValues={selectedTool || initialValues}
            open={showEditToolModal}
            loading={editLoading}
            success={editSuccess}
            fields={fields}
            width={563}
          />
          <TableModal
            onSubmit={(value) => handleCreateTool(value as NewTool)}
            buttonText="Добавить"
            onClose={() => setShowAddToolModal(false)}
            title="Добавление нового МТО"
            validationSchema={validationSchema}
            initialValues={initialValues}
            open={showAddToolModal}
            loading={createLoading}
            success={createSuccess}
            fields={fields}
            width={563}
          />
        </>
      ) : null}
      <ToolsTable
        isEditable={isEditable}
        data={preparedTools}
        loading={isLoading}
        error={isError}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </div>
  );
};
