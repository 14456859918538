import React, { FC, useEffect, useState } from 'react';
import { ExpeditionCardSection, SectionWithSelectPropsType } from '../index';
import {
  ConfirmModal,
  defaultSearchProps,
  DropdownFieldDataType,
  FieldType,
  Icon,
  PanelTableButton,
  Table,
  TableCell,
  TableModal,
} from '@/components';
import {
  handleSortNumber,
  handleSortString,
  openErrorNotify,
  openInfoNotify,
  workTimeValidator,
} from '@/utils';
import { ColumnsType } from 'antd/lib/table';
import { ExpeditionSummarySectorInfo, ExpeditionTableSectorWork } from '@/types';
import styles from '../section.module.scss';
import {
  useCreateExpeditionSectionWorkMutation,
  useDeleteExpeditionSectionWorkMutation,
  useEditExpeditionSectionWorkMutation,
  useGetSectorsQuery,
} from '@/state/api';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { findIndex } from 'lodash';
import { InputTypes } from '@/constants/enums';
import { LINK_TO_EXPEDITION_SECTOR_STEP_PAGE } from '@/constants';
import { useNavigate } from 'react-router';

export const SectorsWorksSection: FC<SectionWithSelectPropsType> = ({
  isEditable,
  item,
  selectData,
  transformToMinutes,
  transformToHours,
  ...rest
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState<ExpeditionTableSectorWork>();

  const navigate = useNavigate();

  const { isLoading, isError } = useGetSectorsQuery({ expId: item?.id as number });
  const [
    createSectorWork,
    {
      isLoading: createLoading,
      isError: createError,
      isSuccess: createSuccess,
      reset: createReset,
    },
  ] = useCreateExpeditionSectionWorkMutation();
  const [
    editSectorWork,
    { isLoading: editLoading, isError: editError, isSuccess: editSuccess, reset: editReset },
  ] = useEditExpeditionSectionWorkMutation();
  const [
    deleteSectorWork,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
      reset: deleteReset,
    },
  ] = useDeleteExpeditionSectionWorkMutation();

  const validationSchema = Yup.object({
    sector_id: Yup.string().required('Пожалуйста, выберите сектор'),
    work_time: Yup.string()
      .required('Пожалуйста, укажите время поисковых работ')
      .test('', 'Пожалуйста, укажите время в формате чч:мм', (value) => workTimeValidator(value)),
  });

  const initialValues = {
    expedition_id: item?.id,
    sector_id: '',
    work_time: '',
  };

  const sectorsIds = item?.sectors_works.map(({ sector_id }) => sector_id);

  const preparedSelectionData = selectData?.filter(
    ({ value }) => !sectorsIds?.includes(Number(value)),
  );

  const handlePrepareSelectedItem = (selectedItem?: ExpeditionTableSectorWork) => {
    if (selectedItem) {
      return Object.fromEntries(
        Object.entries(selectedItem).map(([key, value]) => {
          switch (key) {
            case 'work_time':
              return [key, transformToHours && transformToHours(value as number)];
            default:
              return [key, value];
          }
        }),
      );
    }
  };

  const handleClick = (e?: unknown, index?: number) => {
    if (index === 0) {
      setShowAddModal((prevState) => !prevState);
    } else {
      navigate(LINK_TO_EXPEDITION_SECTOR_STEP_PAGE(item?.id));
    }
  };

  const handleSetSelectedItem = (id: number) => {
    setSelectedItem(item?.sectors_works?.find((item) => item.sector_id === id));
  };

  const handleDelete = (id: number) => {
    deleteSectorWork({
      id,
      expedition_id: item?.id as number,
    });
  };

  const handleEdit = (value: FormikValues) => {
    const preparedValue = {
      ...value,
      expedition_id: item?.id,
      work_time: transformToMinutes && transformToMinutes(value?.work_time),
    };
    editSectorWork(preparedValue);
  };

  const handleAdd = (value: FormikValues) => {
    const preparedValue = {
      ...value,
      work_time: transformToMinutes && transformToMinutes(value?.work_time),
    };
    createSectorWork(preparedValue);
  };

  const toggleDelete = (id: number) => {
    handleSetSelectedItem(id);
    setShowDeleteModal((prevState) => !prevState);
  };

  const toggleEdit = (id: number) => {
    handleSetSelectedItem(id);
    setShowEditModal((prevState) => !prevState);
  };

  const searchCustom = (value: string, record: ExpeditionTableSectorWork) => {
    return record.sector?.name.includes(value) as boolean;
  };

  const editableColumns: ColumnsType<ExpeditionTableSectorWork> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      fixed: 'left',
      width: 62,
      sorter: (first, second) =>
        handleSortNumber(
          findIndex(item?.sectors_works, first),
          findIndex(item?.sectors_works, second),
        ),
      render: (value: string, row) => <TableCell value={findIndex(item?.sectors_works, row) + 1} />,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 62,
      sorter: (first, second) => handleSortNumber(first.id, second.id),
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      width: 40,
      dataIndex: 'sector_id',
      align: 'center',
      fixed: 'left',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleEdit(id)}
          tooltipTitle="Редактировать данные о работе на секторе"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      width: 40,
      dataIndex: 'sector_id',
      align: 'center',
      fixed: 'left',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleDelete(id)}
          tooltipTitle="Удалить данные о работе на секторе"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'Сектор',
      dataIndex: 'sector',
      key: 'sector.name',
      align: 'left',
      render: (value: ExpeditionSummarySectorInfo) => (
        <TableCell value={value?.name} showAllText={true} />
      ),
      sorter: (first, second) => handleSortString(first?.sector?.name, second?.sector?.name),
      ...defaultSearchProps('sector', (value, record) => searchCustom(value as string, record)),
    },
    {
      title: 'Поисковые работы, чч:мм',
      dataIndex: 'work_time',
      key: 'work_time',
      align: 'left',
      width: 220,
      render: (value: number) => {
        const preparedValue = transformToHours && transformToHours(value);

        return <TableCell value={preparedValue} maxWidth={220} isDay={false} />;
      },
      sorter: (first, second) => handleSortNumber(first?.work_time, second?.work_time),
    },
    {
      title: 'Площадь, м2',
      dataIndex: 'sector_id',
      key: 'sector_id.square',
      align: 'left',
      width: 220,
      render: (sector_id: number) => {
        const sector = item?.sectors.find((item) => item.sector_id === sector_id);
        const preparedValue = parseFloat(sector?.info.square || '');

        return (
          <TableCell value={isNaN(preparedValue) ? '' : preparedValue.toFixed(2)} maxWidth={220} />
        );
      },
      sorter: (first, second) => {
        const firstValue = parseFloat(first.sector?.square || '');
        const secondValue = parseFloat(second.sector?.square || '');

        return handleSortNumber(firstValue, secondValue);
      },
    },
  ];

  const readOnlyColumn = editableColumns.filter((item) => item.key !== 'icon');

  const creatableFields: FieldType[] = [
    {
      label: 'Сектор',
      name: 'sector_id',
      type: 'dropdown',
      required: true,
      data: preparedSelectionData as DropdownFieldDataType[],
      error: isError,
      loading: isLoading,
    },
    {
      label: 'Поисковые работы',
      name: 'work_time',
      type: InputTypes.text,
      placeholder: '23:59',
      required: true,
    },
  ];

  const editableFields: FieldType[] = creatableFields.filter((item) => item.name !== 'sector_id');

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        'Добавление информации о поисковых работах на секторах успешно выполнено',
      );
      createReset();
    }
    if (createError) {
      openErrorNotify(
        'Произошла ошибка',
        'При добавлении информации о поисковых работах на секторах произошла ошибка',
      );
      createReset();
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        'Редактирование информации о поисковых работах на секторах успешно выполнено',
      );
      editReset();
    }
    if (editError) {
      openErrorNotify(
        'Произошла ошибка',
        'При редактировании информации о поисковых работах на секторах произошла ошибка',
      );
      editReset();
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        'Удаление информации о поисковых работах на секторах успешно выполнено',
      );
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify(
        'Произошла ошибка',
        'При удалении информации о поисковых работах на секторах произошла ошибка',
      );
      deleteReset();
    }
  }, [deleteSuccess, deleteError]);

  return (
    <ExpeditionCardSection
      {...rest}
      buttonText={['Добавить сектор из списка', 'Добавить сектор на карте']}
      onClickAdd={handleClick}
      isEditable={isEditable}
    >
      <>
        {item?.sectors_works && item?.sectors_works.length > 0 && (
          <Table
            withSelection={false}
            dataSource={item?.sectors_works}
            columns={isEditable ? editableColumns : readOnlyColumn}
            className={styles.table}
            noDataClassName={styles['no-data']}
            scroll={{ x: 1225, y: 300 }}
            withPagination={false}
          />
        )}
        <TableModal
          onSubmit={(value) => handleAdd(value)}
          onClose={() => setShowAddModal(false)}
          validationSchema={validationSchema}
          initialValues={initialValues}
          loading={createLoading}
          success={createSuccess}
          buttonText="Добавить"
          open={showAddModal}
          fields={creatableFields}
          width={563}
          title="Добавление сектора"
        />
        <TableModal
          onSubmit={(value) => handleEdit(value)}
          onClose={() => setShowEditModal(false)}
          validationSchema={validationSchema}
          initialValues={handlePrepareSelectedItem(selectedItem) as ExpeditionTableSectorWork}
          loading={editLoading}
          success={editSuccess}
          buttonText="Изменить"
          open={showEditModal}
          fields={editableFields}
          width={563}
          title="Изменение сектора"
          additionalTitle={
            <div className={styles['additional-title']}>{selectedItem?.sector?.name}</div>
          }
        />
        <ConfirmModal
          open={showDeleteModal}
          title="Подтвердите удаление сектора"
          content={
            <p>
              Вы уверены, что хотите удалить&nbsp;
              {selectedItem?.sector?.name ? (
                <strong>{selectedItem?.sector?.name}</strong>
              ) : (
                'выбранный сектор'
              )}
              ?
            </p>
          }
          submitButtonText="Удалить"
          cancelButtonText="отменить"
          onSubmit={() => selectedItem && handleDelete(selectedItem?.id)}
          onCancel={() => setShowDeleteModal(false)}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
      </>
    </ExpeditionCardSection>
  );
};
