import { FC } from 'react';
import styles from './card-content.module.scss';
import { SectionPropsType } from './card-content.types';

export const Section: FC<SectionPropsType> = ({ className, children, id }) => {
  const customClasses = [styles['section-wrapper'], className].join(' ');

  return (
    <div id={id} className={customClasses}>
      {children}
    </div>
  );
};
