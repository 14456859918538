import { BlackTooltip, DatePickerFieldPropsType, ErrorMessage, Icon } from '@/components';
import React, { FC, useState } from 'react';
import styles from './date-picker-field.module.scss';
import { Field, FieldProps, useField } from 'formik';
import locale from 'antd/es/date-picker/locale/ru_RU';
import generatePicker from 'antd/es/date-picker/generatePicker';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import { ComponentSize } from '@/constants/enums';

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export const DatePickerField: FC<DatePickerFieldPropsType> = ({
  className,
  name,
  label,
  required,
  size = ComponentSize.medium,
  placeholder = 'дд.мм.гггг',
  isDefaultPopupContainer = true,
}): JSX.Element => {
  const [, { value }, { setValue, setTouched }] = useField<string>(name);

  const [isOpen, setIsOpen] = useState(false);
  const [dateValue, setDateValue] = useState<Dayjs | null>(
    dayjs(value).isValid() ? dayjs(value) : null,
  );

  const customStyles = [styles.wrapper, size ? styles[size] : '', className].join(' ');
  const iconClassName = [styles['suffix-icon'], isOpen ? styles['suffix-icon-active'] : ''].join(
    ' ',
  );

  const handleChange = (dayJsValue: Dayjs | null, value?: string): void => {
    const preparedValue = value ? value.split(/\D/).reverse().join('-') : '';
    setTouched(!!value);
    setValue(preparedValue);

    setDateValue(dayJsValue);
  };

  return (
    <div className={customStyles} key={name}>
      {label && <label className="mb-1">{required ? label + '*' : label}</label>}
      <Field name={name}>
        {(props: FieldProps): JSX.Element => (
          <DatePicker
            {...props}
            popupClassName={styles.dropdown}
            locale={locale}
            open={isOpen}
            format="DD.MM.YYYY"
            placement={'bottomRight'}
            getPopupContainer={(trigger) =>
              !isDefaultPopupContainer ? (trigger.parentElement as HTMLElement) : document.body
            }
            placeholder={placeholder}
            value={dateValue}
            onChange={(dayJsValue, value) => handleChange(dayJsValue, value)}
            onOpenChange={setIsOpen}
            suffixIcon={
              <>
                <BlackTooltip placement={'left'} title="Добавить новую дату">
                  <div className={iconClassName}>
                    <Icon size={16} icon="outline-calendar-date" />
                  </div>
                </BlackTooltip>
              </>
            }
          />
        )}
      </Field>
      <ErrorMessage name={name} className={styles.error} />
    </div>
  );
};
