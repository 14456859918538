import React from 'react';
import { Input, Table } from 'antd';
import styles from '../../route-panel-table.module.scss';
import { ISpeed } from '@/types';

export const Speed = ({ speed, setSpeed }: ISpeed) => {
  const installSpeed = (event: React.FormEvent<HTMLInputElement>): void => {
    setSpeed(parseInt(event.currentTarget.value));
  };

  return (
    <Table.Summary.Row className={'ant-table-row'}>
      <Table.Summary.Cell index={0}>
        <div className={styles.data}>Средняя скорость движения, узлы</div>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1}>
        <div className={styles.data}>
          <Input type={'number'} onChange={installSpeed} defaultValue={speed} />
        </div>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};
