import { resetAction } from '@/state/actions';
import { ZcoordsStateType } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ZcoordsStateType = {
  isZCoordsEditor: false,
};

const ZCoordsSlice = createSlice({
  name: 'zCoordsPanel',
  initialState,
  reducers: {
    setIsZCoordsEditor: (state, { payload }: PayloadAction<boolean>) => {
      state.isZCoordsEditor = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAction, () => {
      return initialState;
    });
  },
});

export const { setIsZCoordsEditor } = ZCoordsSlice.actions;
export const { reducer: zCoordsReducer } = ZCoordsSlice;
