import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { SearchPanelOptionProps } from './index.types';
import { TableCellForString } from '@/components';

export const SearchPanelOption: FC<SearchPanelOptionProps> = ({
  value,
  searchValue = '',
  subTitle: propsSubTitle = '',
  title: propsTitle = '',
  prefix = '',
  onClick,
  active,
}) => {
  const [title, setTitle] = useState<string>(propsTitle);
  const [subTitle, setSubTitle] = useState<string>(propsSubTitle);

  const getPreparedText = (value: string, searchValue?: string, prefix?: string) => {
    if (!searchValue) {
      if (prefix) {
        return `<div>${prefix} ${value}</div>`;
      }
      return value;
    }

    const preparedValue = value
      .replaceAll(searchValue, `<strong>${searchValue}</strong>`)
      .toLocaleLowerCase();

    if (prefix) {
      return `<div>${prefix} ${preparedValue}</div>`;
    }
    return `<div>${preparedValue}</div>`;
  };

  useEffect(() => {
    const preparedTitle = getPreparedText(propsTitle, searchValue);
    setTitle(preparedTitle);
    const preparedSubTitle = getPreparedText(propsSubTitle, searchValue, prefix);
    setSubTitle(preparedSubTitle);
  }, [propsTitle, propsSubTitle, searchValue]);

  return (
    <div
      className={`${styles.wrapper} ${active ? styles.active : ''}`}
      onClick={() => onClick(value)}
    >
      <TableCellForString
        value={propsTitle}
        additionalValue={title}
        isHighlighted={true}
        maxWidth={400}
        fontSize={14}
        className={styles.title}
      />
      <TableCellForString
        value={propsTitle}
        additionalValue={subTitle}
        isHighlighted={true}
        maxWidth={400}
        fontSize={10}
        className={styles.subTitle}
      />
      {/*<div dangerouslySetInnerHTML={{__html: title}} className={styles.title}/>*/}
      {/*<div dangerouslySetInnerHTML={{__html: subTitle}} className={styles.subTitle}/>*/}
    </div>
  );
};
