import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GalsStateType, StateGalsMetaData } from '@/types';
import { resetAction } from '@/state/actions';

const initialState: GalsStateType = {
  isMetaGalsOpen: false,
  collectMetaGals: [],
  isMetaGalsOpenTooltip: true,
};

const MetaGalsSlice = createSlice({
  name: 'galsPanel',
  initialState,
  reducers: {
    setIsMetaGalsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isMetaGalsOpen = payload;
    },
    setCollectMetaGals: (state, { payload }: PayloadAction<StateGalsMetaData[] | []>) => {
      state.collectMetaGals = payload;
    },
    setIsMetaGalsOpenTooltip: (state, { payload }: PayloadAction<boolean>) => {
      state.isMetaGalsOpenTooltip = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAction, () => {
      return initialState;
    });
  },
});

export const { setIsMetaGalsOpen, setCollectMetaGals, setIsMetaGalsOpenTooltip } =
  MetaGalsSlice.actions;
export const { reducer: metaGalsReducer } = MetaGalsSlice;
