import { TableEmptyPropsType } from './table-empty.types';
import React, { FC } from 'react';
import styles from './table-empty.module.scss';
import { Icon } from '@/components';

export const TableEmpty: FC<TableEmptyPropsType> = ({ className, size = 60 }) => {
  const customClasses = [styles.empty, className ?? ''].join(' ');

  return (
    <div className={customClasses}>
      <div>
        <Icon icon={'fill-image'} color={'#C0C1C9'} size={size} />
        <h1>Данные отсутствуют</h1>
      </div>
    </div>
  );
};
