import React, { FC } from 'react';
import { SectorSelectContentPropsType } from '@/pages';
import {
  Icon,
  Layout,
  LiveCoordinatePanel,
  Logo,
  MapBaseLayer,
  RulerPanel,
  SidebarButton,
  ZoomControl,
} from '@/components';
import { Route, Routes } from 'react-router-dom';
import { FeatureGroup } from 'react-leaflet';
import { SectorPolygon, WaterAreaPolygon } from '@/components/map/elements';
import { ISector, IWaterArea } from '@/types';
import { Navigate } from 'react-router';
import { LINK_TO_EXPEDITION_SECTOR_CREATE_STEP_PAGE } from '@/constants';

export const SectorSelectContent: FC<SectorSelectContentPropsType> = ({
  expedition_id,
  onCreate,
  onAdd,
  onCancel,
  isChanged,
  data,
  layerRef,
  areasData,
  onAreasRender,
  waterAreaRef,
}) => {
  return (
    <Layout>
      <Layout.Sidebar>
        <Layout.Sidebar.CenterSidebar>
          <SidebarButton onClick={() => onCancel()} hideTooltip={true}>
            Отмена
          </SidebarButton>
          <SidebarButton onClick={() => onCreate()} hideTooltip={true}>
            <Icon icon="outline-plus-circle" size={20} className="me-2" />
            Создать сектор
          </SidebarButton>
          <SidebarButton onClick={() => onAdd()} hideTooltip={true} disable={!isChanged}>
            Сохранить изменения
          </SidebarButton>
        </Layout.Sidebar.CenterSidebar>
      </Layout.Sidebar>
      <Layout.Content>
        <Routes>
          <Route
            path="/create/*"
            element={<Navigate to={LINK_TO_EXPEDITION_SECTOR_CREATE_STEP_PAGE(expedition_id)} />}
          />
        </Routes>
        <MapBaseLayer>
          <FeatureGroup ref={layerRef}>
            {data?.map((item: ISector, idx: number) => (
              <SectorPolygon item={item} key={idx} />
            ))}
          </FeatureGroup>
          <FeatureGroup ref={waterAreaRef}>
            {areasData?.map((item: IWaterArea, idx: number) => (
              <WaterAreaPolygon item={item} key={idx} onAreasRender={onAreasRender} />
            ))}
          </FeatureGroup>
        </MapBaseLayer>
      </Layout.Content>
      <Layout.Footer>
        <Logo />
        <ZoomControl />
        <LiveCoordinatePanel />
        <RulerPanel />
      </Layout.Footer>
    </Layout>
  );
};
