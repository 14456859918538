import React, { FC, useState } from 'react';
import { AuthorizationWrapper } from '@/components/authorization';
import { ResetPasswordInitialValues } from './reset-password.types';
import { ResetPasswordForm } from './reset-password-form';
import { ResetPasswordSubmit } from './reset-password-submit';
import { useSendResetEmailMutation } from '@/state/api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { AUTH } from '@/constants';
import { useNavigate } from 'react-router';

export const ResetPassword: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState('');
  const [sendPassword, { isLoading, isSuccess, error, isError }] = useSendResetEmailMutation();

  const handleSubmit = async (value: ResetPasswordInitialValues): Promise<void> => {
    await sendPassword(value);
    setUserEmail(value.email);
  };

  const handleGoBack = () => {
    navigate(AUTH);
  };

  return (
    <AuthorizationWrapper headerText="сброс пароля">
      {isSuccess ? (
        <ResetPasswordSubmit onResendMail={handleSubmit} email={userEmail} />
      ) : (
        <ResetPasswordForm
          onSubmitForm={handleSubmit}
          onClickBack={handleGoBack}
          error={error as FetchBaseQueryError}
          loading={isLoading}
          isError={isError}
        />
      )}
    </AuthorizationWrapper>
  );
};
