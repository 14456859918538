import React, { FC, useEffect, useState } from 'react';
import styles from '../../default-tab.module.scss';
import { useDeleteWaterAreaMutation, useGetWaterAreasQuery } from '@/state/api';
import { WaterAreasTable, PanelTabButton, BlackTooltip, Icon, ConfirmModal } from '@/components';
import { ComponentSize, PanelTabButtonTypes, Permissions, PolygonTypes } from '@/constants/enums';
import { openErrorNotify, openInfoNotify } from '@/utils';
import { LINK_TO_POLYGON_TOOL } from '@/constants';
import { useNavigate } from 'react-router';
import { IWaterArea } from '@/types';
import { useAppSelector } from '@/state';

export const WaterAreasTab: FC = (): JSX.Element => {
  const { isLoading: isGetLoading, data, isError: isGetError } = useGetWaterAreasQuery();

  const [
    deleteWaterArea,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      reset: resetDelete,
    },
  ] = useDeleteWaterAreaMutation();

  const preparedWaterArea = data ?? [];

  const [selectedWaterArea, setSelectedWaterArea] = useState<IWaterArea>();
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);
  const isEditable = userPermissions?.includes(Permissions.expeditionsDataManagment);

  const navigate = useNavigate();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const getWaterArea = (id: number) => {
    const item = preparedWaterArea.find((waterArea) => waterArea.id === id);
    item && setSelectedWaterArea(item);
  };

  const handleCreateClick = () => {
    navigate(LINK_TO_POLYGON_TOOL(PolygonTypes.waterArea));
  };

  const handleEditClick = (id: number) => {
    navigate(LINK_TO_POLYGON_TOOL(PolygonTypes.waterArea, id));
  };

  const handleDelete = (id: number) => {
    resetDelete();
    setShowConfirmDeleteModal((prevState) => !prevState);
    getWaterArea(id);
  };

  const handleDeleteWaterArea = (id: number): void => {
    deleteWaterArea({
      id: id,
    });
  };

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Удаление акватории',
        <>
          Акватория <b>{selectedWaterArea?.name}</b> была удалена
        </>,
      );
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении акватории произошла ошибка');
    }
  }, [deleteSuccess, deleteError]);

  return (
    <div className={styles.wrapper}>
      {!isGetLoading && !isGetError && (
        <div className={styles.header}>
          <BlackTooltip
            title={'Перечень акваторий, в которых проводятся экспедиции.'}
            placement={'bottomLeft'}
          >
            <PanelTabButton
              className={styles['button-panel-help']}
              onClick={() => {}}
              type={PanelTabButtonTypes.icon}
              size={ComponentSize.small}
            >
              <Icon icon="outline-question-circle" size={14} />
            </PanelTabButton>
          </BlackTooltip>
          {isEditable ? (
            <PanelTabButton
              type={PanelTabButtonTypes.roundSecondary}
              onClick={() => handleCreateClick()}
              size={ComponentSize.small}
            >
              Создать новую акваторию
            </PanelTabButton>
          ) : null}
        </div>
      )}
      {isEditable ? (
        <ConfirmModal
          open={showConfirmDeleteModal}
          title="Подтвердите удаление акватории"
          content={
            <p>
              Вы уверены, что хотите удалить&nbsp;
              {selectedWaterArea?.name ? (
                <>
                  акваторию <strong>{selectedWaterArea?.name}</strong>
                </>
              ) : (
                'выбранную акваторию'
              )}
              ?
            </p>
          }
          submitButtonText="Удалить акваторию"
          cancelButtonText="Отменить"
          onSubmit={() => handleDeleteWaterArea(selectedWaterArea?.id as number)}
          onCancel={() => setShowConfirmDeleteModal((prevState) => !prevState)}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
      ) : null}
      <WaterAreasTable
        isEditable={isEditable}
        data={preparedWaterArea}
        loading={isGetLoading}
        error={isGetError}
        onEdit={(id: number) => handleEditClick(id)}
        onDelete={handleDelete}
      />
    </div>
  );
};
