import React, { FC } from 'react';
import { Icon, PanelTabButton, Spinner } from '@/components';
import { PanelTabButtonTypes } from '@/constants/enums';
import { ObjectCardMediaSectionWrapperPropsType } from './media-section.types';
import styles from './media-section.module.scss';

export const ObjectCardMediaSectionWrapper: FC<ObjectCardMediaSectionWrapperPropsType> = ({
  label,
  onClick,
  onRemove,
  onUpload,
  buttonText = 'Добавить вложение',
  icon = 'outline-plus',
  isEdit,
  uploadedFilesList,
  fileType,
  children,
  className,
  uploadAvailable = false,
  loading,
  id,
}) => {
  const customStyles = [styles.card, className].join(' ');

  return (
    <div className={customStyles} id={id}>
      <div className={styles.title}>
        <h5>{label}</h5> {loading && <Spinner className={styles.spinner} />}
      </div>
      {children ? <>{children}</> : null}
      {isEdit && (
        <PanelTabButton
          type={PanelTabButtonTypes.primary}
          onClick={(event) => onClick && onClick(event)}
          onDelete={(item) => onRemove && onRemove(item)}
          customRequest={(options) => onUpload && onUpload(options)}
          uploadAvailable={uploadAvailable}
          fileList={uploadedFilesList}
          fileType={fileType}
        >
          <Icon icon={icon} size={14} className="me-1" />
          {buttonText}
        </PanelTabButton>
      )}
    </div>
  );
};
