import styles from '../coordinate-converter-panel.module.scss';
import React, { FC } from 'react';
import { Button, Icon } from '@/components';
import { ConvertButtonPropsType } from '../coordinate-converter.types';
import { ButtonTypes } from '@/constants/enums';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { ConvertCoordsFormValues } from '@/types';

export const ConvertButton: FC<ConvertButtonPropsType> = ({ valueAvailable }): JSX.Element => {
  const { initialValues, values } = useFormikContext<ConvertCoordsFormValues>();

  const handleCheckConvertable = (type: 'latitude' | 'longitude'): boolean => {
    const firstField = valueAvailable(values.graduatedCord[`${type}`]);
    const secondField = valueAvailable(values.decimalCord[`${type}`]);

    if (firstField && secondField) {
      return false;
    }
    return firstField || secondField;
  };

  const convertAvailable =
    handleCheckConvertable('longitude') ||
    handleCheckConvertable('latitude') ||
    !isEqual(initialValues, { ...values, cordType: '' });

  const handleGetIcon = () => {
    switch (values?.cordType) {
      case 'graduatedCord':
        return 'outline-arrow-up';
      case 'decimalCord':
        return 'outline-arrow-down';
      default:
        return '';
    }
  };

  return (
    <Button disable={!convertAvailable} className={styles.button} type={ButtonTypes.submit}>
      {convertAvailable && <Icon size={12} color="#FFF" icon={handleGetIcon()} />}
      <span>конвертировать</span>
      {convertAvailable && <Icon size={12} color="#FFF" icon={handleGetIcon()} />}
    </Button>
  );
};
