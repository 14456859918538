import React, { FC } from 'react';
import styles from './logo.module.scss';
import logo from '../../assets/icons/map-logo.svg';
import { CPI_RGO } from '@/constants';

export const Logo: FC = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <img
        src={logo}
        alt="Центр подводных исследований русского геологического общества"
        onClick={() => {
          location.href = CPI_RGO;
        }}
      />
    </div>
  );
};
