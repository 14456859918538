import {
  defaultSearchProps,
  Icon,
  PanelTableButton,
  TableCell,
  Table,
  defaultDataPickerProps,
} from '@/components';
import { handleSortNumber, handleSortString } from '@/utils';
import { WaterAreasTablePropsType } from './water-areas-table.types';
import { LINK_TO_EXPEDITION_PANEL, MAP, WATER_AREAS_LAYER } from '@/constants';
import { ColumnsType } from 'antd/lib/table';
import React, { FC } from 'react';
import { IWaterArea } from '@/types';
import { useMap } from '@/context';
import { setSelectedLayer } from '@/state/slice';
import { useNavigate } from 'react-router';
import { LatLngExpression, Polygon } from 'leaflet';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGetExpeditionListQuery } from '@/state/api';
import { SortOrder } from 'antd/es/table/interface';

export const WaterAreasTable: FC<WaterAreasTablePropsType> = ({
  data,
  loading,
  isEditable,
  error,
  onEdit,
  onDelete,
}): JSX.Element => {
  const { map } = useMap();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFlyToWaterArea = (polygon: LatLngExpression[][][]): void => {
    navigate(MAP);
    map?.flyToBounds(new Polygon(polygon).getBounds(), { maxZoom: 18 });
    dispatch(setSelectedLayer(WATER_AREAS_LAYER));
  };

  const {
    data: expeditionList,
    isLoading: expeditionLoading,
    isError: expeditionError,
  } = useGetExpeditionListQuery();

  const expeditionsIdsWithCardsDuplicates = expeditionList?.expeditions
    .filter((expedition) => expedition.parent_expedition_id)
    .map((expedition) => expedition.parent_expedition_id);

  const preparedExpeditions = expeditionList?.expeditions.filter(
    (expedition) => !expeditionsIdsWithCardsDuplicates?.includes(expedition.id),
  );

  const handleSortExpedition = (first: IWaterArea, second: IWaterArea, order: SortOrder) => {
    const firstValue = preparedExpeditions?.find((item) => item.water_area_id === first.id);
    const secondValue = preparedExpeditions?.find((item) => item.water_area_id === second.id);

    if (firstValue && secondValue) {
      return firstValue.name.localeCompare(secondValue.name);
    }
    if (firstValue) return order === 'ascend' ? -1 : 1;
    if (secondValue) return order === 'ascend' ? 1 : -1;

    return 0;
  };

  const searchExpedition = (value: string, record: IWaterArea) => {
    const item = preparedExpeditions?.find((item) => item.water_area_id === record.id);

    return item?.name.includes(value) as boolean;
  };

  const columns: ColumnsType<IWaterArea> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 60,
      sorter: (first, second) => handleSortNumber(first.id, second.id),
    },
    {
      title: <Icon size={12} icon="outline-geo-alt" />,
      dataIndex: 'polygon',
      fixed: 'left',
      width: 40,
      align: 'center',
      render: (value) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Приблизить к акватории"
          icon="outline-geo-alt"
          onClick={() => handleFlyToWaterArea(value)}
          size={12}
          tooltipPlacement={'bottom'}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      dataIndex: 'id',
      fixed: 'left',
      key: 'editable',
      width: 40,
      align: 'center',
      render: (value: number) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Изменить акваторию"
          icon="outline-pencil"
          onClick={() => onEdit(value)}
          size={12}
          tooltipPlacement={'bottom'}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      dataIndex: 'id',
      fixed: 'left',
      width: 40,
      key: 'editable',
      align: 'center',
      render: (value) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Удалить акваторию"
          icon="outline-trash"
          onClick={() => onDelete(value)}
          size={12}
          tooltipPlacement={'bottom'}
        />
      ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (value: string) => <TableCell value={value} maxWidth={420} />,
      sorter: (first, second, sortOrder) => handleSortString(first.name, second.name, sortOrder),
      ...defaultSearchProps('name'),
    },
    {
      title: 'Экспедиции',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 300,
      render: (value: number) => {
        const preparedData = preparedExpeditions?.filter((item) => item.water_area_id === value);
        const links = preparedData?.map((item) => ({
          key: item.id,
          name: item.name,
          link: <Link to={LINK_TO_EXPEDITION_PANEL(item.id)}>{item.name}</Link>,
        }));

        return <TableCell value={links ?? ''} />;
      },
      sorter: (first, second, sortOrder) =>
        handleSortExpedition(first, second, sortOrder as SortOrder),
      ...defaultSearchProps('id', (value, record) => searchExpedition(value as string, record)),
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'left',
      render: (value: string) => <TableCell value={value} maxWidth={420} />,
      sorter: (first, second, sortOrder) =>
        handleSortString(first.created_at, second.created_at, sortOrder),
      ...defaultDataPickerProps('created_at'),
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      align: 'left',
      key: 'description',
      render: (value: string) => <TableCell value={value} maxWidth={350} />,
    },
  ];

  const preparedColumns = isEditable ? columns : columns.filter((item) => item.key !== 'editable');

  return (
    <Table
      withSelection={false}
      dataSource={data}
      columns={preparedColumns}
      loading={loading || expeditionLoading}
      error={error || expeditionError}
    />
  );
};
