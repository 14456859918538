import React, { FC, useEffect, useState } from 'react';
import { ExpeditionCardSection, SectionWithSelectPropsType } from '../index';
import {
  ConfirmModal,
  defaultSearchProps,
  DropdownFieldDataType,
  FieldType,
  Icon,
  PanelTableButton,
  Table,
  TableCell,
  TableModal,
} from '@/components';
import { handleSortNumber, handleSortString, openErrorNotify, openInfoNotify } from '@/utils';
import { ColumnsType } from 'antd/lib/table';
import { DefaultShipBase, ExpeditionShipBase } from '@/types';
import styles from '../section.module.scss';
import {
  useCreateExpeditionShipBaseMutation,
  useDeleteExpeditionShipBaseMutation,
  useGetShipsBasesQuery,
} from '@/state/api';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { findIndex } from 'lodash';

export const ShipBasesSection: FC<SectionWithSelectPropsType> = ({
  isEditable,
  item,
  selectData,
  ...rest
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState<ExpeditionShipBase>();

  const [
    createShipBase,
    {
      isLoading: createLoading,
      isError: createError,
      isSuccess: createSuccess,
      reset: createReset,
    },
  ] = useCreateExpeditionShipBaseMutation();
  const [
    deleteShipBase,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
      reset: deleteReset,
    },
  ] = useDeleteExpeditionShipBaseMutation();
  const { isLoading, isError } = useGetShipsBasesQuery({ expId: item?.id as number });

  const handleSetSelectedItem = (id: number) => {
    setSelectedItem(item?.ships_bases?.find((item) => item.ships_base_id === id));
  };

  const handleDelete = (shipsBaseId: number) => {
    deleteShipBase({
      ships_base_id: shipsBaseId,
      expedition_id: item?.id as number,
    });
  };

  const handleAdd = (value: FormikValues) => {
    const preparedValue = {
      expedition_id: item?.id as number,
      ships_base_id: value.ships_base_id as number,
    };
    createShipBase(preparedValue);
  };

  const toggleDelete = (id: number) => {
    handleSetSelectedItem(id);
    setShowDeleteModal((prevState) => !prevState);
  };

  const toggleAdd = () => {
    setShowAddModal((prevState) => !prevState);
  };

  const editableColumns: ColumnsType<ExpeditionShipBase> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'index',
      align: 'left',
      fixed: 'left',
      width: 60,
      render: (value: string, row) => <TableCell value={findIndex(item?.ships_bases, row) + 1} />,
      sorter: (first, second) =>
        handleSortNumber(findIndex(item?.ships_bases, first), findIndex(item?.ships_bases, second)),
    },
    {
      title: 'ID',
      dataIndex: 'ships_base_id',
      key: 'ships_base_id',
      align: 'left',
      fixed: 'left',
      width: 60,
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second) => handleSortNumber(first?.ships_base_id, second?.ships_base_id),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      width: 40,
      dataIndex: 'ships_base_id',
      align: 'center',
      fixed: 'left',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleDelete(id)}
          tooltipTitle="Удалить базу из экспедиции"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'База судов',
      dataIndex: 'info',
      key: 'info',
      align: 'left',
      render: (value: DefaultShipBase) => <TableCell value={value?.name} showAllText={true} />,
      sorter: (first, second, sortOrder) =>
        handleSortString(first?.info.name, second?.info.name, sortOrder),
      ...defaultSearchProps('info.name'),
    },
  ];

  const readOnlyColumn = editableColumns.filter((item) => item.key !== 'icon');

  const validationSchema = Yup.object({
    ships_base_id: Yup.string().required('Пожалуйста, выберите базу судов из списка'),
  });

  const initialValues = {
    ships_base_id: '',
  };

  const fields: FieldType[] = [
    {
      label: 'База судов',
      name: 'ships_base_id',
      type: 'dropdown',
      loading: isLoading,
      error: isError,
      data: selectData as DropdownFieldDataType[],
    },
  ];

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify('Изменения сохранены', 'Добавление базы судов успешно выполнено');
      createReset();
    }
    if (createError) {
      openErrorNotify('Произошла ошибка', 'При добавлении базы судов произошла ошибка');
      createReset();
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify('Изменения сохранены', 'Удаление базы судов успешно выполнено');
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении базы судов произошла ошибка');
      deleteReset();
    }
  }, [deleteSuccess, deleteError]);

  return (
    <ExpeditionCardSection
      {...rest}
      buttonText="Добавить базу судов"
      onClickAdd={toggleAdd}
      isEditable={isEditable}
    >
      <>
        {item?.ships_bases && item?.ships_bases?.length > 0 && (
          <Table
            withSelection={false}
            dataSource={item?.ships_bases}
            columns={isEditable ? editableColumns : readOnlyColumn}
            className={styles.table}
            noDataClassName={styles['no-data']}
            scroll={{ x: 1225, y: 300 }}
            withPagination={false}
          />
        )}
        <TableModal
          onSubmit={(value) => handleAdd(value)}
          onClose={() => setShowAddModal(false)}
          validationSchema={validationSchema}
          initialValues={initialValues}
          loading={createLoading}
          success={createSuccess}
          buttonText="Добавить"
          open={showAddModal}
          fields={fields}
          width={563}
          title="Добавить базу судов"
        />
        <ConfirmModal
          open={showDeleteModal}
          title="Подтвердите удаление базы судов экспедиции"
          content={
            <p>
              Вы уверены, что хотите удалить&nbsp;
              {selectedItem?.info.name ? (
                <strong>{selectedItem?.info.name}</strong>
              ) : (
                'выбранную базу судов'
              )}
              ?
            </p>
          }
          submitButtonText="Удалить"
          cancelButtonText="отменить"
          onSubmit={() => selectedItem && handleDelete(selectedItem?.ships_base_id)}
          onCancel={() => setShowDeleteModal(false)}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
      </>
    </ExpeditionCardSection>
  );
};
