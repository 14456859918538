import React, { FC } from 'react';
import styles from './styles.module.scss';
import { Icon } from '@/components';

export const SearchPanelNoDataPlaceholder: FC = (): JSX.Element => {
  return (
    <div className={styles['no-data']}>
      <Icon icon="outline-no-data-object" size={32} />
      <span>Объекты не обнаружены</span>
    </div>
  );
};
