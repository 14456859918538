import React, { FC, useEffect, useState } from 'react';
import styles from '../../default-tab.module.scss';
import { useDeleteSectorMutation, useGetSectorsQuery } from '@/state/api';
import { SectorsTable, PanelTabButton, BlackTooltip, Icon, ConfirmModal } from '@/components';
import { ComponentSize, PanelTabButtonTypes, Permissions, PolygonTypes } from '@/constants/enums';
import { openErrorNotify, openInfoNotify } from '@/utils';
import { useNavigate } from 'react-router';
import { LINK_TO_POLYGON_TOOL } from '@/constants';
import { ISector } from '@/types';
import { useAppSelector } from '@/state';

export const SectorsTab: FC = (): JSX.Element => {
  const { isError: isGetError, isLoading: getLoading, data } = useGetSectorsQuery();

  const [
    deleteSector,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      reset: resetDelete,
    },
  ] = useDeleteSectorMutation();

  const [selectedSector, setSelectedSector] = useState<ISector>();
  const preparedSectors = data || [];

  const userPermissions = useAppSelector((state) => state.auth.user?.rules);
  const isEditable = userPermissions?.includes(Permissions.expeditionsDataManagment);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const getSector = (id: number) => {
    const item = preparedSectors.find((sector) => sector.id === id);
    item && setSelectedSector(item);
  };

  const navigate = useNavigate();

  const handleEdit = (id: number) => {
    getSector(id);
    navigate(LINK_TO_POLYGON_TOOL(PolygonTypes.sector, id));
  };

  const handleDelete = (id: number) => {
    getSector(id);
    resetDelete();
    setShowConfirmDeleteModal((prevState) => !prevState);
  };

  const handleCreate = (): void => {
    navigate(LINK_TO_POLYGON_TOOL(PolygonTypes.sector));
  };

  const handleDeleteSectors = (id: number): void => {
    deleteSector({
      id: id,
    });
  };

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Удаление сектора',
        <>
          Сектор <b>{selectedSector?.name}</b> был удален
        </>,
      );
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении произошла ошибка');
    }
  }, [deleteSuccess, deleteError]);

  return (
    <div className={styles.wrapper}>
      {!isGetError && !getLoading && (
        <div className={styles.header}>
          <BlackTooltip
            title={
              <div>
                <p>
                  Сектор - территория, на которой планируется провести поисковые работы. Является
                  частью Экспедиции.
                </p>
                <p>
                  <b>Важно: </b>
                  Сектор должен территориально входить в Акваторию, которая создаётся изначально. На
                  карте включается и выключается в слое Сектора.
                </p>
              </div>
            }
            placement={'bottomLeft'}
          >
            <PanelTabButton
              className={styles['button-panel-help']}
              onClick={() => {}}
              type={PanelTabButtonTypes.icon}
              size={ComponentSize.small}
            >
              <Icon icon="outline-question-circle" size={14} />
            </PanelTabButton>
          </BlackTooltip>
          {isEditable ? (
            <PanelTabButton
              disabled={getLoading || isGetError}
              type={PanelTabButtonTypes.roundSecondary}
              className={styles.button}
              size={ComponentSize.small}
              onClick={handleCreate}
            >
              Создать новый сектор
            </PanelTabButton>
          ) : null}
        </div>
      )}
      {isEditable ? (
        <ConfirmModal
          open={showConfirmDeleteModal}
          title="Подтвердите удаление сектора"
          content={
            <p>
              Вы уверены, что хотите удалить&nbsp;
              {selectedSector?.name ? (
                <>
                  сектор <strong>{selectedSector?.name}</strong>
                </>
              ) : (
                'выбранный сектор'
              )}
              ?
            </p>
          }
          submitButtonText="Удалить сектор"
          cancelButtonText="Отменить"
          onSubmit={() => handleDeleteSectors(selectedSector?.id as number)}
          onCancel={() => setShowConfirmDeleteModal((prevState) => !prevState)}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
      ) : null}
      <SectorsTable
        data={preparedSectors}
        loading={getLoading}
        isEditable={isEditable}
        error={isGetError}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </div>
  );
};
