import React, { FC, MouseEvent } from 'react';
import { ErrorIndicator, Modal, TableLoader } from '@/components';
import styles from './card.module.scss';
import { ICardPropsType } from './card.types';
import { Annotation, Content } from './card-components';

type CardSubComponents = {
  Content: typeof Content;
  Annotation: typeof Annotation;
};

const Card: FC<ICardPropsType> & CardSubComponents = ({
  children,
  onClose,
  footer,
  className,
  loading,
  error,
  ...rest
}): JSX.Element => {
  const customStyles = [styles.modal, className].join(' ');

  const reloadAfterError = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    location.reload();
  };

  return (
    <Modal
      {...rest}
      onClose={onClose}
      width={1654}
      mask={true}
      centered={true}
      wrapClassName={customStyles}
      footer={footer}
    >
      <div className={styles.wrapper}>
        {error ? (
          <ErrorIndicator reloadAfterError={(e) => reloadAfterError(e)} />
        ) : loading ? (
          <TableLoader className={styles.loader} />
        ) : (
          <>{children}</>
        )}
      </div>
    </Modal>
  );
};

Card.Annotation = Annotation;
Card.Content = Content;

export { Card };
