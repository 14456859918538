import { api } from '../api';
import { DefaultExpeditionType, CreationProposal, Proposal } from '@/types';

export const proposalApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createExpeditionProposal: builder.mutation<DefaultExpeditionType, CreationProposal>({
      query(body) {
        return {
          url: '/proposal/add',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    editExpeditionProposal: builder.mutation<DefaultExpeditionType, Proposal>({
      query(body) {
        return {
          url: '/proposal/edit',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    deleteExpeditionProposal: builder.mutation<
      DefaultExpeditionType,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/proposal',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
  }),
});

export const {
  useCreateExpeditionProposalMutation,
  useEditExpeditionProposalMutation,
  useDeleteExpeditionProposalMutation,
} = proposalApi;
