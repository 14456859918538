import {
  CREATE_SECTOR_PAGE,
  EXPEDITION_STEP_PAGE,
  LINK_TO_DISTANCE,
  LINK_TO_LOG,
  LINK_TO_GALSES,
  LINK_TO_OBJECT_CREATE,
  LINK_TO_Z_CORD,
  POLYGON_TOOL,
  SECTORS_LAYER,
  WATER_AREAS_LAYER,
} from '@/constants';
import { useAppSelector } from '@/state';
import { PathOptions, Polygon as LP } from 'leaflet';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Polygon } from 'react-leaflet';
import { RegionPolygonPropsType } from './region-polygon.types';
import { MapTooltip } from '../map-tooltip';
import { MapPopup } from '../map-popup';
import { matchPath, useLocation, useParams } from 'react-router';

export const RegionPolygon: FC<RegionPolygonPropsType> = ({ item }) => {
  const selectedLayers = useAppSelector((state) => state.map.selectedLayers);
  const location = useLocation();
  const { id } = useParams();
  const isHidden =
    !!matchPath(POLYGON_TOOL + '/region/*', location.pathname) && id && Number(id) === item.id;

  const ref = useRef<LP>(null);

  const isSecondary =
    !!matchPath(CREATE_SECTOR_PAGE + '/*', location.pathname) ||
    !!matchPath(EXPEDITION_STEP_PAGE + '/*', location.pathname) ||
    !!matchPath(POLYGON_TOOL + '/*', location.pathname);

  const isNonInteractive =
    !!matchPath(POLYGON_TOOL + '/*', location.pathname) ||
    !!matchPath(LINK_TO_Z_CORD, location.pathname) ||
    !!matchPath(LINK_TO_LOG, location.pathname) ||
    !!matchPath(LINK_TO_GALSES, location.pathname) ||
    !!matchPath(LINK_TO_DISTANCE, location.pathname) ||
    !!matchPath(LINK_TO_OBJECT_CREATE, location.pathname) ||
    !!matchPath(EXPEDITION_STEP_PAGE + '/*', location.pathname) ||
    !!matchPath(CREATE_SECTOR_PAGE + '/*', location.pathname);

  const polygonFillOpacity =
    selectedLayers.includes(SECTORS_LAYER) || selectedLayers.includes(WATER_AREAS_LAYER)
      ? 0.02
      : 0.1;

  const polygonFillColor = '#7912E0';
  const polygonColor = '#7912E0';

  const weight =
    isSecondary ||
    selectedLayers.includes(SECTORS_LAYER) ||
    selectedLayers.includes(WATER_AREAS_LAYER)
      ? 1
      : 2;

  const dashArray =
    selectedLayers.includes(SECTORS_LAYER) || selectedLayers.includes(WATER_AREAS_LAYER)
      ? [10, 20]
      : undefined;

  const defaultPathOptions: PathOptions = {
    fillOpacity: polygonFillOpacity,
    fillColor: polygonFillColor,
    color: polygonColor,
    dashArray: dashArray,
    weight: weight,
    lineJoin: 'round',
    lineCap: 'round',
  };
  const isOpenRegionTooltip = useAppSelector((state) => state.regions.isOpenRegionTooltip);

  useEffect(() => {
    if (isNonInteractive) {
      ref.current?.getElement()?.classList.add('inherit-cursor');
    } else {
      ref.current?.getElement()?.classList.remove('inherit-cursor');
    }
  }, [isNonInteractive]);

  return useMemo(() => {
    return isHidden ? null : (
      <Polygon
        key={item.id}
        positions={item.polygon}
        interactive={!isNonInteractive}
        ref={ref}
        pathOptions={defaultPathOptions}
      >
        {isOpenRegionTooltip && (
          <MapTooltip isSecondary={isSecondary} typeOfGeometry={'region'} {...item} />
        )}
        {!isNonInteractive ? <MapPopup typeOfGeometry={'region'} {...item} /> : null}
      </Polygon>
    );
  }, [item, defaultPathOptions, isOpenRegionTooltip, isNonInteractive, isSecondary, isHidden]);
};
