import { getObjectIconByType } from '@/utils';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Marker } from 'react-leaflet';
import styles from './object-marker.module.scss';
import { ObjectMarkerPropsType } from './object-marker.types';
import { MapTooltip } from '../map-tooltip';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { LINK_TO_OBJECT_FORMULAR, POLYGON_TOOL } from '@/constants';
import { useAppSelector } from '@/state';
import { Marker as LM } from 'leaflet';

export const ObjectMarker: FC<ObjectMarkerPropsType> = ({
  item,
  expeditionId,
  active,
  onClick,
  isInteractive: isInteractiveProps = true,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const activeItem = useAppSelector((state) => state.objects.selectedObject);
  const activeItems = useAppSelector((state) => state.objects.selectedObjects);

  const markerRef = useRef<LM>(null);

  const isInteractive = !matchPath(POLYGON_TOOL + '/*', location.pathname) && isInteractiveProps;
  const { lat, lng, identifier, id, type } = item;

  const eventHandlers = {
    click: () => {
      if (isInteractive) {
        navigate(LINK_TO_OBJECT_FORMULAR(id, expeditionId));
      }
      onClick && onClick(id);
    },
  };

  useEffect(() => {
    markerRef.current?.setIcon(
      getObjectIconByType(
        Number(type),
        active !== undefined ? active : activeItem === id || activeItems.includes(id),
        styles,
        expeditionId,
      ),
    );
  }, [activeItem, markerRef, item, activeItems]);

  return useMemo(
    () => (
      <Marker
        position={[parseFloat(lat), parseFloat(lng)]}
        key={`${identifier}-${id}`}
        eventHandlers={eventHandlers}
        ref={markerRef}
      >
        <MapTooltip typeOfGeometry={'object'} item={item} />
      </Marker>
    ),
    [item, isInteractive],
  );
};
