import { ALL_OBJECT_LAYERS } from '@/constants';
import { MapStateType } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoordsTypes } from '@/constants/enums';
import { resetAction } from '@/state/actions';

const initialState: MapStateType = {
  coordsType:
    (localStorage.getItem('styleCoords') as CoordsTypes | undefined) ?? CoordsTypes.decimal,
  selectedLayers: [],
  selectedItem: null,
  coordsEditorEnabled: false,
  activePanel: null,
  selectObjectMode: false,
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    changeCoordsType: (state, { payload }: PayloadAction<CoordsTypes>) => {
      state.coordsType = payload;
      localStorage.setItem('styleCoords', payload.toString());
    },
    setSelectedLayers: (state, { payload }: PayloadAction<Array<string>>) => {
      state.selectedLayers = payload;
    },
    setCoordsEditorEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.coordsEditorEnabled = payload;
    },
    setSelectedItem: (state, { payload }: PayloadAction<null | number>) => {
      state.selectedItem = payload;
    },
    setActivePanel: (state, { payload }: PayloadAction<null | string>) => {
      state.activePanel = payload;
    },
    setSelectObjectMode: (state, { payload }: PayloadAction<boolean>) => {
      state.selectObjectMode = payload;
    },
    setSelectedLayer: (state, { payload }: PayloadAction<string>) => {
      if (!state.selectedLayers.includes(payload)) {
        state.selectedLayers.push(payload);
      }
      localStorage.setItem('layers', JSON.stringify(state.selectedLayers));
    },
    toggleCordStyle: (state) => {
      const preparedCoords =
        state.coordsType === CoordsTypes.decimal ? CoordsTypes.graduated : CoordsTypes.decimal;
      localStorage.setItem('styleCoords', preparedCoords.toString());
      state.coordsType = preparedCoords;
    },
    toggleSelectedLayer: (state, { payload }: PayloadAction<string>) => {
      if (!state.selectedLayers.includes(payload)) {
        state.selectedLayers.push(payload);
      } else {
        state.selectedLayers = state.selectedLayers.filter((layer) => layer !== payload);
      }
      localStorage.setItem('layers', JSON.stringify(state.selectedLayers));
    },
    toggleObjectLayer: (state, { payload }: PayloadAction<boolean>) => {
      if (payload) {
        ALL_OBJECT_LAYERS.forEach((layer) => {
          if (!state.selectedLayers.includes(layer)) {
            state.selectedLayers.push(layer);
          }
        });
      } else {
        state.selectedLayers = state.selectedLayers.filter(
          (layer) => !ALL_OBJECT_LAYERS.includes(layer),
        );
      }
      localStorage.setItem('layers', JSON.stringify(state.selectedLayers));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAction, () => {
      return initialState;
    });
  },
});

export const {
  changeCoordsType,
  setActivePanel,
  setCoordsEditorEnabled,
  setSelectObjectMode,
  setSelectedItem,
  toggleCordStyle,
  setSelectedLayer,
  toggleSelectedLayer,
  setSelectedLayers,
  toggleObjectLayer,
} = mapSlice.actions;
export const { reducer: mapReducer } = mapSlice;
