import React, { FC } from 'react';
import { ExpeditionsTablePropsType } from './expeditions-table.types';
import { ColumnsType } from 'antd/lib/table';
import { IExpedition } from '@/types';
import { handleDateSort, handleSortNumber, handleSortString } from '@/utils';
import dayjs, { Dayjs } from 'dayjs';
import { Link } from 'react-router-dom';
import styles from './expeditions-table.module.scss';
import { LINK_TO_EXPEDITION_PANEL } from '@/constants';
import { ExpeditionStatuses, ExpeditionTypeTranslation } from '@/constants/enums';
import { useGetWaterAreasQuery } from '@/state/api';
import {
  defaultDataPickerProps,
  defaultSearchProps,
  Icon,
  PanelTableButton,
  Table,
  TableCell,
  TableFilter,
} from '@/components';

export const ExpeditionsTable: FC<ExpeditionsTablePropsType> = ({
  data,
  loading,
  error,
  isEditable,
  onNavigate,
  onDelete,
}): JSX.Element => {
  const { data: waterAreas } = useGetWaterAreasQuery();
  const [plan, card, report, approved] = [
    ExpeditionTypeTranslation.plan,
    ExpeditionTypeTranslation.card,
    ExpeditionTypeTranslation.report,
    ExpeditionTypeTranslation.approved,
  ];
  const collectStatus = [plan, card, report, approved];

  const handleSortPeriod = (value: (string | Dayjs)[], item: IExpedition) => {
    const [dateStartPeriod, dateEndPeriod] = item.period.split(' - ');

    const checkBefore = dayjs(dateStartPeriod.split(/\.\-/).reverse().join('.')).isAfter(
      dayjs(value[0]),
    );
    const checkAfter = dayjs(dateEndPeriod?.split(/\.\-/).reverse().join('.')).isBefore(
      dayjs(value[1]),
    );

    return checkBefore && checkAfter;
  };

  const getStatusName = (value: string) => {
    const key = value as keyof typeof ExpeditionTypeTranslation;

    return ExpeditionTypeTranslation[key];
  };

  const getTooltipIcon = (id: number, value: string) => {
    const iconText = 'outline-file-earmark-text';
    const iconCheck = 'outline-file-earmark-check';
    const tooltipTitle = (
      <div className={styles.links}>
        <p>
          <Link to={LINK_TO_EXPEDITION_PANEL(id, ExpeditionStatuses.report)}>Отчет экспедиции</Link>
        </p>
        <p>
          <Link to={LINK_TO_EXPEDITION_PANEL(id, ExpeditionStatuses.card)}>
            Карточка экспедиции
          </Link>
        </p>
      </div>
    );

    switch (value) {
      case 'plan':
        return ['План экспедиции', iconText];
      case 'card':
        return ['Карточка экспедиции', iconText];
      case 'report':
        return [tooltipTitle, iconCheck];
      case 'approved':
        return [tooltipTitle, iconCheck];
      default:
        return ['', ''];
    }
  };

  const columns: ColumnsType<IExpedition> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 60,
      sorter: (first, second) => handleSortNumber(first.id, second.id),
    },
    {
      title: <Icon size={12} icon="outline-file-earmark-text" />,
      dataIndex: 'id',
      fixed: 'left',
      width: 40,
      align: 'center',
      render: (id: number, item) => {
        const [tooltipTitle, icon] = getTooltipIcon(id, item.status);

        return (
          <PanelTableButton
            showTooltip={true}
            tooltipTitle={tooltipTitle}
            icon={icon as string}
            onClick={() => onNavigate(id, item.status)}
            size={12}
            tooltipPlacement={'bottom'}
          />
        );
      },
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      dataIndex: 'id',
      fixed: 'left',
      key: 'editable',
      width: 40,
      align: 'center',
      render: (value) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Удалить экспедицию"
          icon="outline-trash"
          onClick={() => onDelete(value)}
          size={12}
          tooltipPlacement={'bottom'}
        />
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: 110,
      render: (value: string) => {
        return <TableCell value={getStatusName(value)} maxWidth={100} />;
      },
      sorter: (first, second) => handleSortNumber(first.status, second.status),
      onFilter: (value, record) => {
        return getStatusName(record.status) === value;
      },
      filterDropdown: ({ filters, clearFilters, confirm, setSelectedKeys, selectedKeys }) => (
        <TableFilter
          filters={filters}
          onFilter={confirm}
          onClear={clearFilters}
          selectedKeys={selectedKeys}
          onChange={setSelectedKeys}
        />
      ),
      filters: collectStatus.map((value) => {
        return {
          value: value,
          text: value,
        };
      }),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 700,
      render: (value: string) => <TableCell value={value} maxWidth={700} />,
      sorter: (first, second, sortOrder) => handleSortString(first.name, second.name, sortOrder),
      ...defaultSearchProps('name'),
    },
    {
      title: 'Акватория',
      dataIndex: 'water_area_id',
      key: 'water_area_id',
      align: 'left',
      width: 240,
      render: (value: number) => {
        const waterArea = waterAreas?.find((waterArea) => waterArea.id === value);

        return <TableCell value={waterArea?.name ?? ''} maxWidth={240} />;
      },
      sorter: (first, second, sortOrder) =>
        handleSortString(
          first.water_area_id.toString(),
          second.water_area_id.toString(),
          sortOrder,
        ),
      ...defaultSearchProps('water_area_id'),
    },
    {
      title: 'Период',
      dataIndex: 'period',
      key: 'period',
      align: 'left',
      width: 190,
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second, sortOrder) =>
        handleDateSort(first.tentative_start_date, second.tentative_end_date, sortOrder),
      ...defaultDataPickerProps('period', (value, record) => handleSortPeriod(value, record)),
    },
    {
      title: 'Изменено',
      dataIndex: 'updated_at',
      align: 'left',
      key: 'updated_at',
      width: 180,
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second, sortOrder) =>
        handleDateSort(first.updated_at, second.updated_at, sortOrder),
      ...defaultDataPickerProps('updated_at'),
    },
  ];

  const preparedColumns = isEditable ? columns : columns.filter((item) => item.key !== 'editable');

  return (
    <Table
      withSelection={false}
      dataSource={data}
      error={error}
      columns={preparedColumns}
      loading={loading}
    />
  );
};
