import { DefaultResponse } from '@/types';
import { api } from '../api';

export const objectsMediaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createObjectMedia: builder.mutation<
      DefaultResponse,
      {
        object_id: string;
        file: File;
      }
    >({
      query(body) {
        const data = new FormData();
        data.append('object_id', body?.object_id?.toString());
        data.append('file', body?.file);

        return {
          url: '/object/media',
          method: 'POST',
          body: data,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ObjectsMedia'],
    }),
    deleteObjectMedia: builder.mutation<
      DefaultResponse,
      {
        id: string;
      }
    >({
      query(body) {
        return {
          url: '/object/media',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ObjectsMedia'],
    }),
  }),
});

export const { useDeleteObjectMediaMutation, useCreateObjectMediaMutation } = objectsMediaApi;
