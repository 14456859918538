import { api } from '../api';
import type { UserProfile, Token, DefaultResponse } from '@/types';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation<Token, { email: string; password: string; otpCode: string }>({
      query(body) {
        return {
          url: '/auth/login',
          method: 'POST',
          body: { email: body.email, password: body.password, otp_code: body.otpCode },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Auth'],
    }),
    checkUser: builder.mutation<DefaultResponse, { email: string; password: string }>({
      query(body) {
        return {
          url: '/auth/check_user',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
    }),
    getOtpLifetime: builder.query<{ created_at?: string; validity?: number }, { email: string }>({
      query(params) {
        return {
          url: '/auth/get_otp_lifetime',
          method: 'GET',
          params: { email: params.email },
          credentials: 'include',
        };
      },
      providesTags: ['OTP lifetime'],
    }),
    logoutUser: builder.mutation<Token, void>({
      query() {
        return {
          url: '/auth/logout',
          method: 'POST',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Auth'],
    }),
    getUserInfo: builder.mutation<UserProfile, void>({
      query() {
        return {
          url: '/auth/user',
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Auth'],
    }),
    sendResetEmail: builder.mutation<Token, { email: string }>({
      query(body) {
        return {
          url: '/auth/password/email',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Auth'],
    }),
    resendOtp: builder.mutation<{ created_at?: string; validity?: number }, { email: string }>({
      query(body) {
        return {
          url: '/auth/resend_otp',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['OTP lifetime'],
    }),
    sendResetPassword: builder.mutation<
      Token,
      {
        email: string;
        token: string;
        password: string;
        confirm_password: string;
      }
    >({
      query(body) {
        return {
          url: '/auth/password/reset',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Auth'],
    }),
  }),
});

export const {
  useLoginUserMutation,
  useGetUserInfoMutation,
  useSendResetEmailMutation,
  useSendResetPasswordMutation,
  useLogoutUserMutation,
  useCheckUserMutation,
  useGetOtpLifetimeQuery,
  useResendOtpMutation,
} = authApi;
