import React, { FC } from 'react';
import { BathymetricAddFileTablePropsType } from './bathymetric-table.types';
import {
  defaultDataPickerProps,
  defaultSearchProps,
  Icon,
  PanelTabButton,
  PanelTableButton,
  Table,
  TableCell,
  TableEmpty,
  TableError,
  TableFilter,
  TableLoader,
} from '@/components';
import { handleDateSort, handleSortNumber, handleSortString } from '@/utils';
import { ColumnsType } from 'antd/lib/table';
import { Bathymetric, BathymetricFileType } from '@/types';
import { ComponentSize, PanelTabButtonTypes } from '@/constants/enums';
import styles from './bathymetric-add-file-table.module.scss';

export const BathymetricAddFileTable: FC<BathymetricAddFileTablePropsType> = ({
  className,
  loading,
  error,
  expeditionLoading,
  expeditionError,
  bathData,
  filesData,
  onAddFiles,
  onSelectRow,
  selectedRows,
  disableRowSelection,
  fileTableClassName,
  bathTableClassName,
  info,
}) => {
  const leftTable: ColumnsType<Bathymetric> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 62,
      sorter: (first, second) => handleSortNumber(first.id, second.id),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: 110,
      align: 'center',
      sorter: (first, second, sortOrder) =>
        handleSortNumber(first?.status, second?.status, sortOrder),
      filterDropdown: ({ filters, clearFilters, confirm, setSelectedKeys, selectedKeys }) => (
        <TableFilter
          filters={filters}
          onFilter={confirm}
          onClear={clearFilters}
          selectedKeys={selectedKeys}
          onChange={setSelectedKeys}
        />
      ),
      onFilter: (value, record) => record.status === value,
      filters: [
        {
          text: 'Обработан',
          value: 1,
        },
        {
          text: 'В обработке',
          value: 2,
        },
      ],
      render: (value: number) => {
        switch (value) {
          case 1:
            return (
              <PanelTableButton
                showTooltip={true}
                tooltipTitle="Обработан"
                icon="outline-status-confirmed"
                size={12}
                tooltipPlacement="bottom"
              />
            );
          case 2:
            return (
              <PanelTableButton
                showTooltip={true}
                tooltipTitle="В процессе обработки"
                icon="outline-status-planned"
                size={12}
                tooltipPlacement="bottom"
              />
            );
          case 3:
            return (
              <PanelTableButton
                showTooltip={true}
                tooltipTitle=""
                icon="outline-status-error"
                size={12}
                tooltipPlacement="bottom"
              />
            );
        }
      },
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: (first, second, sortOrder) => handleSortString(first?.name, second?.name, sortOrder),
      render: (value: string) => <TableCell value={value} maxWidth={349} />,
      ...defaultSearchProps('name'),
    },
  ];

  const RightTable: ColumnsType<BathymetricFileType> = [
    {
      title: 'Путь',
      dataIndex: 'path',
      key: 'path',
      align: 'left',
      width: 200,
      render: (value: string) => <TableCell value={value} maxWidth={608} />,
      sorter: (first, second, sortOrder) => handleSortNumber(first.path, second.path, sortOrder),
      ...defaultSearchProps('path'),
    },
    {
      title: 'Дата съемки',
      dataIndex: 'lastEdit',
      key: 'lastEdit',
      align: 'left',
      width: 160,
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second, sortOrder) =>
        handleDateSort(first.lastEdit ?? null, second.lastEdit ?? null, sortOrder),
      ...defaultDataPickerProps<BathymetricFileType>('lastEdit'),
    },
    {
      title: 'Размер, Мб',
      dataIndex: 'size',
      width: 120,
      align: 'left',
      key: 'size',
      render: (value?: string) => {
        const preparedValue = value ? parseFloat(value).toFixed(1) : '';

        return <TableCell value={preparedValue} maxWidth={150} className="text-right" />;
      },
      sorter: (first, second, sortOrder) => handleSortNumber(first?.size, second?.size, sortOrder),
    },
  ];

  return (
    <div className={className}>
      {loading || expeditionLoading ? (
        <TableLoader className={styles.loader} />
      ) : error || expeditionError ? (
        <TableError text={'Произошла ошибка'} />
      ) : (
        <>
          <Table
            className={styles['table-bath-from']}
            dataSource={bathData}
            paginationPosition={['topLeft']}
            columns={leftTable}
            loading={loading}
            scroll={{ x: 400, y: 475 }}
            withSelection={false}
            showTotal={false}
            emptyPlaceholder={<TableEmpty className={bathTableClassName} />}
          />
          <PanelTabButton
            className={styles['button-flex']}
            disabled={selectedRows.length === 0}
            type={PanelTabButtonTypes.roundSecondary}
            size={ComponentSize.medium}
            onClick={() => onAddFiles()}
          >
            <Icon size={16} icon="outline-arrow-left" />
          </PanelTabButton>
          <div style={{ position: 'relative' }}>
            {info}
            <Table
              className={styles['table-bath-to']}
              columns={RightTable}
              dataSource={filesData}
              loading={loading}
              scroll={{ x: 800, y: 475 }}
              onSelectRow={(row, checked, selectedRows) => onSelectRow(selectedRows)}
              disableRowSelection={disableRowSelection}
              selectedRows={selectedRows}
              emptyPlaceholder={<TableEmpty className={fileTableClassName} />}
            />
          </div>
        </>
      )}
    </div>
  );
};
