import React, { FC } from 'react';
import styles from './documents-table.module.scss';
import { DocumentsTablePropsType } from './documents-table.types';
import {
  defaultSearchProps,
  Icon,
  PanelTableButton,
  Table,
  TableCell,
  TableFilter,
} from '@/components';
import { ColumnsType } from 'antd/lib/table';
import { DefaultMediaType } from '@/types';
import {
  getFileTypeNameByPath,
  getNameByPath,
  handleFilterString,
  handleSortNumber,
  handleSortString,
} from '@/utils';
import { FileTypes } from '@/constants';
import { isEqual } from 'lodash';

export const ObjectCardDocumentsTable: FC<DocumentsTablePropsType> = ({
  data,
  isEdit,
  onDownload,
  onDelete,
  downloadLoading,
  selectedItem,
}) => {
  const preparedData =
    data?.map((item) => ({
      ...item,
      name: getNameByPath(item?.path) || item?.name || '',
      media_type: getFileTypeNameByPath(item?.path) || item?.media_type || '',
    })) || [];

  const editableColumns: ColumnsType<DefaultMediaType> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 60,
      sorter: (first, second) => handleSortNumber(first?.id, second?.id),
    },
    {
      title: <Icon size={12} icon="outline-download" />,
      dataIndex: 'path',
      key: 'path',
      width: 40,
      align: 'center',
      render: (value: string, row) => (
        <PanelTableButton
          loading={downloadLoading && isEqual(row, selectedItem)}
          onClick={() => onDownload && onDownload(row)}
          loaderClassName={styles.loader}
          tooltipTitle="Скачать документ"
          tooltipPlacement="bottom"
          icon="outline-download"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      dataIndex: 'id',
      width: 40,
      key: 'icon',
      align: 'center',
      render: (value: string, row) => (
        <PanelTableButton
          showTooltip={true}
          icon="outline-trash"
          tooltipTitle="Удалить документ"
          onClick={() => onDelete && onDelete(row)}
          tooltipPlacement="bottom"
          size={12}
        />
      ),
    },
    {
      title: 'Расширение файла',
      dataIndex: 'media_type',
      key: 'media_type',
      align: 'left',
      width: 200,
      render: (value: string) => <TableCell value={value} maxWidth={200} />,
      sorter: (first, second) => handleSortString(first?.media_type, second?.media_type),
      onFilter: (value, record) =>
        handleFilterString(value?.toString()?.replace(/\W/, ''), record, 'media_type'),
      filterDropdown: ({ filters, clearFilters, confirm, setSelectedKeys, selectedKeys }) => (
        <TableFilter
          filters={filters}
          onFilter={confirm}
          onClear={clearFilters}
          selectedKeys={selectedKeys}
          onChange={setSelectedKeys}
        />
      ),
      filters: FileTypes?.map((item) => ({
        value: item,
        text: item,
      })),
    },
    {
      title: 'Наименование документа',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second) => handleSortString(first?.name, second?.name),
      ...defaultSearchProps('name'),
    },
  ];

  const readOnlyColumn = editableColumns.filter((item) => item.key !== 'icon');

  return (
    <Table
      dataSource={preparedData}
      columns={isEdit ? editableColumns : readOnlyColumn}
      className={styles.table}
      noDataClassName={styles['no-data']}
      scroll={{ x: 800, y: 190 }}
      withPagination={false}
      withSelection={false}
    />
  );
};
