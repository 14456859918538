import React, { FC, useEffect, useState } from 'react';
import styles from './object-card-panel.module.scss';
import { openErrorNotify, openInfoNotify, prepareCoordsFieldToString } from '@/utils';
import { IObject } from '@/types';
import { useHistoryBackstack, useMap } from '@/context';
import { CoordsTypes, Permissions, Targets3DTypes } from '@/constants/enums';
import { matchPath, useNavigate, useParams } from 'react-router';
import { useAppSelector } from '@/state';
import { Formik, FormikValues } from 'formik';
import {
  BaseObjectInfoSection,
  Card,
  ConfirmModal,
  FormHelper,
  ObjectBottomDetailsSection,
  ObjectCardTitle,
  ObjectDivingSpecialistActionSection,
  ObjectEnvironmentSection,
  ObjectFilesSection,
  ObjectFixationPlacementSection,
  ObjectFixationSoilPlacementSection,
  ObjectFixationSSSObjectSection,
  ObjectFrameResearchSection,
  ObjectHeightAngDepthsSection,
  ObjectHistoryAndDescriptionSection,
} from '@/components';
import {
  LINK_TO_OBJECT_FORMULAR,
  LINK_TO_VIEW_3D,
  MAP,
  OBJECT_CARD,
  SELECT_COORDS_PAGE,
  VIEW_3D_ROUTE,
} from '@/constants';
import {
  useDeleteObjectMutation,
  useEditObjectMutation,
  useExportObjectToDocxMutation,
  useGetExpeditionListQuery,
  useGetObjectsQuery,
  useGetToolsListQuery,
} from '@/state/api';
import { clearFormWithCoordsMarker } from '@/state/slice';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

export const menuData = [
  {
    label: 'Основная информация',
    link: 'base_info',
  },
  {
    label: 'Историческая справка и описание',
    link: 'history',
  },
  {
    label: 'Фиксация объекта ГБО, МЛЭ, подготовка планшета для нанесения отметок',
    link: 'object_fixation',
    subtitle: [
      {
        label: 'Фиксация места объекта по трем пеленгам магнитного компаса',
        link: 'sss_fixation',
      },
      {
        label: 'Определение положения объекта на грунте с фиксацией',
        link: 'soil_fixation',
      },
      {
        label: 'Окружающая среда',
        link: 'environment',
      },
      {
        label: 'Детали объекта на дне',
        link: 'bottom_details',
      },
    ],
  },
  {
    label: 'Обследование корпуса с видео-фото фиксацией, водолазами и/или ТНПА',
    link: 'hull_inspection',
  },
  {
    label: 'Действия возможные только с применением водолазных специалистов',
    link: 'diving_actions',
  },
  {
    label: 'Вложения',
    link: 'files',
    subtitle: [
      {
        label: 'Ссылки',
        link: 'links',
      },
      {
        label: 'Изображения',
        link: 'images',
      },
      {
        label: '3D-модель',
        link: '3d_view',
      },
      {
        label: 'Документы',
        link: 'documents',
      },
    ],
  },
];

export const ObjectCardPanel: FC = () => {
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const { map } = useMap();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { backstack } = useHistoryBackstack();

  const routeForIgnore = [
    `${MAP}${VIEW_3D_ROUTE}`,
    `${MAP}${OBJECT_CARD}`,
    `${SELECT_COORDS_PAGE}/*`,
  ];

  const { data: objectsData, isError, isLoading, isFetching } = useGetObjectsQuery();
  const preparedObjects = objectsData?.objects || [];
  const preparedTypes = objectsData?.object_types || [];

  const cordType = useAppSelector((state) => state.map.coordsType);
  const formData = useAppSelector((state) => state.toolsPanels.objectCreationPanel.persistedForm);
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);
  const isEditable = userPermissions?.includes(Permissions.mapDataManagment);
  const isShowingCoords = userPermissions?.includes(Permissions.specificDataViewing);
  const isShowingExpedition = userPermissions?.includes(Permissions.expeditionsDataViewing);

  const id = Number(params?.id);
  const selectedItem = preparedObjects.find((item) => item.id === id);

  const [
    deleteObject,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      reset: deleteReset,
    },
  ] = useDeleteObjectMutation();
  const [
    editObject,
    { isLoading: editLoading, isSuccess: editSuccess, isError: editError, reset: editReset },
  ] = useEditObjectMutation();
  const [
    exportToDoc,
    {
      data: blobData,
      isLoading: exportLoading,
      isSuccess: exportSuccess,
      isError: exportError,
      reset: exportReset,
    },
  ] = useExportObjectToDocxMutation();

  const { data: expeditionsData } = useGetExpeditionListQuery({ id: Number(id) });
  const { data: toolsData } = useGetToolsListQuery();

  const preparedObjectTypesData = preparedTypes.map(({ id, type }) => ({
    label: type,
    value: id.toString(),
  }));

  const preparedExpeditionData = expeditionsData?.expeditions?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const preparedToolsData = toolsData?.tools.map(({ name, id }) => ({
    label: name,
    value: id.toString(),
  }));

  const preparedSelectedObject = {
    ...selectedItem,
    expeditions: selectedItem?.expeditions.map(({ expedition_id }) => expedition_id),
    coordinates: prepareCoordsFieldToString(selectedItem, cordType, 'coordinates'),
  };

  const defaultEditIsActive = formData ? !isEqual(preparedSelectedObject, formData) : false;
  const formIsEqual = formData && isEqual(formData, preparedSelectedObject);

  const toggleConfirmModal = () => {
    setShowConfirmCloseModal((prevState) => !prevState);
  };

  const toggleDelete = () => {
    setShowDeleteModal((prevState) => !prevState);
  };

  const handleClick3D = () => {
    navigate(LINK_TO_VIEW_3D(Targets3DTypes.object, id));
  };

  const handleClose = (dirty: boolean) => {
    if (dirty) {
      toggleConfirmModal();
    } else {
      dispatch(clearFormWithCoordsMarker());
      const filteredBackstack = backstack.filter(
        (item) => routeForIgnore.filter((route) => matchPath(route, item)).length === 0,
      );
      navigate(filteredBackstack.pop() || MAP);
    }
  };

  const handleFlyToObject = (object?: IObject) => {
    if (object) {
      const { lat, lng, id } = object;
      navigate(LINK_TO_OBJECT_FORMULAR(+id));
      const coordinates = { lat: Number(lat), lng: Number(lng) - 0.04 };
      map?.flyTo(coordinates, 14);
    }
  };

  const handleSubmit = (values: FormikValues) => {
    dispatch(clearFormWithCoordsMarker());
    const preparedData = {
      ...values,
      detection_tools: String(values?.detection_tools) || '0',
      coordinates: prepareCoordsFieldToString(values, CoordsTypes.decimal, 'coordinates'),
    } as IObject;

    editObject(preparedData);
  };

  const handleDelete = (id: number) => {
    deleteObject({ id: id });
  };

  const handleExport = (id: number) => {
    const preparedData = {
      id: id,
      name: String(selectedItem?.name),
    };
    exportToDoc(preparedData);
  };

  useEffect(() => {
    const idUnavailable = !id || isNaN(id);
    const requestEnded = !isLoading && !isFetching && !isError;
    const dataUnavailable = selectedItem === undefined || !selectedItem;
    if ((dataUnavailable && requestEnded) || idUnavailable) {
      navigate(MAP);
    }
  }, [isLoading, isFetching, isError]);

  useEffect(() => {
    if (formData && !formIsEqual) {
      !formIsDirty && setFormIsDirty(true);
    }
  }, [formData, preparedSelectedObject]);

  useEffect(() => {
    if (editSuccess) {
      setFormIsDirty(false);
      openInfoNotify('Изменения сохранены', 'Редактирование успешно');
      editReset();
    }
    if (editError) {
      openErrorNotify('Произошла ошибка', 'При редактировании карточки объекта произошла ошибка');
      editReset();
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify('Изменения сохранены', 'Удаление успешно');
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении объекта произошла ошибка');
      deleteReset();
    }
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    if (exportSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        'Данные для экспорта успешно получены. Начинается загрузка',
      );
      exportReset();
    }
    if (exportError) {
      openErrorNotify('Произошла ошибка', 'При экспорте данных объекта произошла ошибка');
      exportReset();
    }
  }, [exportSuccess, exportError, blobData]);

  return (
    <Card
      onClose={() => handleClose(formIsDirty)}
      width={1654}
      centered={true}
      showCloseIcon={false}
      loading={isLoading}
      error={isError}
      footer={<div className={styles.footer} />}
      additionalTitle={
        <ObjectCardTitle
          loading={isLoading}
          item={selectedItem}
          onClick3D={() => handleClick3D()}
          isEditable={isEditable}
          onClickCentered={() => handleFlyToObject(selectedItem)}
          onClickExport={() => handleExport(id)}
          onClickDelete={() => toggleDelete()}
          onClose={() => handleClose(formIsDirty)}
          exportLoading={exportLoading}
          deleteLoading={deleteLoading}
        />
      }
    >
      <Card.Annotation>
        {menuData.map(({ label, link, subtitle }) => {
          if (subtitle) {
            return (
              <Card.Annotation.Item key={label} label={label} link={'#' + link}>
                {subtitle.map(({ label: subtitleTitle, link: subtitleValue }) => (
                  <Card.Annotation.Item
                    key={subtitleTitle}
                    label={subtitleTitle}
                    link={'#' + subtitleValue}
                  />
                ))}
              </Card.Annotation.Item>
            );
          }
          return <Card.Annotation.Item key={label} label={label} link={'#' + link} />;
        })}
      </Card.Annotation>

      <Card.Content loading={isFetching || isLoading}>
        <div className={styles.card}>
          <Formik
            initialValues={formData ? formData : preparedSelectedObject}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validateOnBlur={true}
            validateOnChange={false}
          >
            {({ handleSubmit }) => (
              <>
                <FormHelper setFormIsDirty={setFormIsDirty} />
                <BaseObjectInfoSection
                  onSubmit={handleSubmit}
                  item={selectedItem}
                  isShowingCoords={isShowingCoords}
                  objectTypes={preparedObjectTypesData}
                  expeditionData={preparedExpeditionData}
                  toolsData={preparedToolsData}
                  success={editSuccess}
                  loading={editLoading}
                  isShowingExpedition={isShowingExpedition}
                  isEditable={isEditable}
                  defaultEdit={defaultEditIsActive}
                  isDirty={!formIsEqual}
                  defaultItem={preparedSelectedObject}
                />
                <ObjectHistoryAndDescriptionSection
                  onSubmit={handleSubmit}
                  success={editSuccess}
                  loading={editLoading}
                  isEditable={isEditable}
                />
                {isShowingCoords ? (
                  <ObjectFixationSSSObjectSection
                    onSubmit={handleSubmit}
                    success={editSuccess}
                    loading={editLoading}
                    isEditable={isEditable}
                  />
                ) : null}
                {isShowingCoords ? (
                  <ObjectFixationPlacementSection
                    onSubmit={handleSubmit}
                    success={editSuccess}
                    loading={editLoading}
                    isEditable={isEditable}
                  />
                ) : null}
                <ObjectFixationSoilPlacementSection
                  onSubmit={handleSubmit}
                  success={editSuccess}
                  loading={editLoading}
                  isEditable={isEditable}
                />
                <ObjectEnvironmentSection
                  onSubmit={handleSubmit}
                  success={editSuccess}
                  loading={editLoading}
                  item={selectedItem}
                  isEditable={isEditable}
                />
                <ObjectBottomDetailsSection
                  onSubmit={handleSubmit}
                  success={editSuccess}
                  loading={editLoading}
                  isEditable={isEditable}
                />
                <ObjectHeightAngDepthsSection
                  onSubmit={handleSubmit}
                  success={editSuccess}
                  loading={editLoading}
                  isEditable={isEditable}
                />
                <ObjectFrameResearchSection
                  onSubmit={handleSubmit}
                  success={editSuccess}
                  loading={editLoading}
                  isEditable={isEditable}
                />
                <ObjectDivingSpecialistActionSection
                  onSubmit={handleSubmit}
                  success={editSuccess}
                  loading={editLoading}
                  isEditable={isEditable}
                />
                <ObjectFilesSection
                  onSubmit={handleSubmit}
                  success={editSuccess}
                  loading={editLoading}
                  isEditable={isEditable}
                  item={selectedItem}
                />
              </>
            )}
          </Formik>
        </div>
      </Card.Content>
      <>
        <ConfirmModal
          open={showConfirmCloseModal}
          onCancel={() => toggleConfirmModal()}
          onSubmit={() => handleClose(false)}
        />
        <ConfirmModal
          open={showDeleteModal}
          title="Подтвердите удаление объекта."
          content={
            <p>
              Вы уверены, что хотите удалить объект&nbsp;
              {selectedItem?.name ? <strong>{selectedItem?.name}</strong> : 'выбранный объект'}?
            </p>
          }
          submitButtonText="Удалить"
          cancelButtonText="Отменить"
          onSubmit={() => handleDelete(id)}
          onCancel={() => toggleDelete()}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
      </>
    </Card>
  );
};
