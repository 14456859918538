import { FC, useState } from 'react';
import styles from './sidebar-collapse-button.module.scss';
import { useLocation } from 'react-router';
import { useAppSelector } from '@/state';
import { Permissions } from '@/constants/enums';
import { CSSTransition } from 'react-transition-group';
import { Icon, Tooltip } from '@/components';
import { LINK_TO_EXPEDITION_TABLE, LINK_TO_LAYERS_TABLE, LINK_TO_OBJECT_CREATE } from '@/constants';

export const SidebarCollapseButton: FC<{
  handleNavigateToModal: (isActive: boolean, path?: string) => void;
}> = ({ handleNavigateToModal }): JSX.Element => {
  const { pathname } = useLocation();
  const buttonIsActive = (path: string): boolean => pathname.includes(path);
  const transitionClasses = {
    enter: styles.enter,
    enterActive: styles['enter-active'],
    enterDone: styles['enter-done'],
    exit: styles.exit,
    exitActive: styles['exit-active'],
    exitDone: styles['exit-done'],
  };

  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  const listTools = [
    {
      icon: 'outline-plus-circle',
      title: 'Добавить объект',
      name: 'addObject',
      hidden: !userPermissions?.includes(Permissions.mapDataManagment),
      path: LINK_TO_OBJECT_CREATE,
      onClick: handleNavigateToModal,
      isActive: buttonIsActive(LINK_TO_OBJECT_CREATE),
    },
    {
      icon: 'outline-ship',
      title: 'Таблицы карты и экспедиций',
      name: 'layersTable',
      path: userPermissions?.includes(Permissions.mapDataViewing)
        ? LINK_TO_LAYERS_TABLE
        : LINK_TO_EXPEDITION_TABLE,
      onClick: handleNavigateToModal,
      isActive: buttonIsActive(LINK_TO_LAYERS_TABLE),
    },
  ];

  const [isActive, setIsActive] = useState(false);
  const [isShowButton, setIsShowButton] = useState(true);

  return (
    <div className={styles.wrapper}>
      <div className={styles['sub-wrap']}>
        <CSSTransition
          in={isActive}
          timeout={300}
          classNames={transitionClasses}
          onExited={() => setIsShowButton(true)}
        >
          <div className={styles.items}>
            {isShowButton ? (
              <Tooltip placement={'right'} title={'Панель данных карты'}>
                <div
                  className={styles.item}
                  onClick={() => {
                    setIsShowButton(false);
                    setIsActive(true);
                  }}
                >
                  <Icon icon={'outline-anchor'} size={20} />
                </div>
              </Tooltip>
            ) : (
              <>
                <button className={`${styles.item} arrow`} onClick={() => setIsActive(false)}>
                  <Icon icon={'outline-chevron-right'} size={20} />
                </button>
                {listTools.map(({ icon, title, path, onClick, isActive, hidden }, idx) =>
                  hidden ? null : (
                    <div className={styles.item} key={idx} onClick={() => onClick(isActive, path)}>
                      <Icon icon={icon} size={20} />
                      <span>{title}</span>
                    </div>
                  ),
                )}
              </>
            )}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};
