import React, { FC } from 'react';
import styles from './water-area.module.scss';
import { IWaterArea } from '@/types';

export const WaterArea: FC<{ item: IWaterArea }> = ({ item }) => {
  return (
    <>
      <div>
        {item.description && (
          <p className={styles.text}>
            <span className={styles.other}>Описание:</span>
            {item.description}
          </p>
        )}
      </div>
    </>
  );
};
