import React, { FC, useEffect, useState } from 'react';
import { PanelTabButton, AccessesAreasTable, ConfirmModal, BlackTooltip, Icon } from '@/components';
import { ComponentSize, PanelTabButtonTypes, PolygonTypes } from '@/constants/enums';
import { useDeleteAccessAreaMutation, useGetAccessesAreasListQuery } from '@/state/api';
import styles from '../../default-tab.module.scss';
import { AccessArea, EditAccessArea } from '@/types';
import { useNavigate } from 'react-router';
import { LINK_TO_POLYGON_TOOL } from '@/constants';
import { openErrorNotify, openInfoNotify } from '@/utils';

export const AccessesAreasTab: FC = () => {
  const { isLoading, isError, data } = useGetAccessesAreasListQuery();

  const preparedData = data?.data || [];
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedAccessesArea, setSelectedAccessesArea] = useState<AccessArea | null>(null);

  const navigate = useNavigate();

  const [
    deleteAccessArea,
    {
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      reset: resetDelete,
    },
  ] = useDeleteAccessAreaMutation();

  const handleDeleteAccessArea = (): void => {
    deleteAccessArea({
      id: selectedAccessesArea?.id || 0,
    });
  };

  const handleCreateClick = () => {
    navigate(LINK_TO_POLYGON_TOOL(PolygonTypes.accessArea));
  };

  const handleEditClick = (row: AccessArea) => {
    navigate(LINK_TO_POLYGON_TOOL(PolygonTypes.accessArea, row.id));
  };

  const handleDeleteClick = (accessArea: AccessArea) => {
    const preparedValue: EditAccessArea = {
      ...accessArea,
      id: accessArea.id || 0,
    };
    setSelectedAccessesArea(preparedValue);
    resetDelete();
    setShowConfirmDeleteModal(true);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      openInfoNotify(
        'Удаление области видимости',
        <>
          Область вимдимости <b>{selectedAccessesArea?.name}</b> была удалена
        </>,
      );
    }
    if (isDeleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении области видимости произошла ошибка');
    }
  }, [isDeleteSuccess, isDeleteError]);

  return (
    <div className={styles.wrapper}>
      {!isError && !isLoading && (
        <div className={styles.header}>
          <BlackTooltip
            title={'Области видимости ограничивают доступ роли на карте'}
            placement={'bottomLeft'}
          >
            <PanelTabButton
              className={styles['button-panel-help']}
              onClick={() => {}}
              type={PanelTabButtonTypes.icon}
              size={ComponentSize.small}
            >
              <Icon icon="outline-question-circle" size={14} />
            </PanelTabButton>
          </BlackTooltip>
          <PanelTabButton
            disabled={isLoading || isError}
            className={styles.button}
            type={PanelTabButtonTypes.roundSecondary}
            size={ComponentSize.small}
            onClick={() => handleCreateClick()}
          >
            Добавить
          </PanelTabButton>
        </div>
      )}
      <ConfirmModal
        open={showConfirmDeleteModal}
        title="Подтвердите удаление области видимости"
        content={
          <p>
            Вы уверены, что хотите удалить&nbsp;
            {selectedAccessesArea?.name ? (
              <>
                область <strong>{selectedAccessesArea?.name}</strong>
              </>
            ) : (
              'указанную область'
            )}
            ?
          </p>
        }
        submitButtonText="Удалить область"
        cancelButtonText="Отменить"
        onSubmit={() => handleDeleteAccessArea()}
        onCancel={() => {
          setShowConfirmDeleteModal(false);
          setSelectedAccessesArea(null);
        }}
        loading={isDeleteLoading}
        success={isDeleteSuccess}
        type="danger"
      />
      <AccessesAreasTable
        data={preparedData}
        onDelete={handleDeleteClick}
        onEdit={(item: AccessArea) => handleEditClick(item)}
        loading={isLoading}
        error={isError}
      />
    </div>
  );
};
