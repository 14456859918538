import styles from './live-coordinate-panel.module.scss';
import React, { FC, useMemo } from 'react';
import { useLiveCords, useMap } from '@/context';
import { useAppSelector } from '@/state';
import { prepareCoords, prepareScale } from '@/utils';
import { LatLng } from 'leaflet';

export const LiveCoordinatePanel: FC = (): JSX.Element => {
  const { liveCords } = useLiveCords();
  const cordType = useAppSelector((state) => state.map.coordsType);
  const preparedCord = (cordType: string, liveCords: LatLng | null) => {
    const [lat, long] = prepareCoords(liveCords, cordType);
    if (cordType === 'DEC') {
      return `X ${lat}, Y ${long}`;
    }
    return `${lat.slice(-1)} ${lat.substring(0, lat.length - 1)}, ${long.slice(
      -1,
    )} ${long.substring(0, long.length - 1)}`;
  };
  const { map } = useMap();
  const maxMeters = map?.distance(
    map.containerPointToLatLng([0, map.getSize().y / 2]),
    map.containerPointToLatLng([100, map.getSize().y / 2]),
  );
  const [meters, feets] = useMemo(() => prepareScale(maxMeters ?? 0), [maxMeters]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>{preparedCord(cordType, liveCords)}</div>
      <div className={styles.distance}>
        <div className={styles.card}>{meters}</div>
        <div className={styles.card}>{feets}</div>
      </div>
    </div>
  );
};
