import { RadioChangeEvent } from 'antd';
import React, { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import styles from '../coordinate-converter-panel.module.scss';
import { GraduatedCordFieldRadioPropsType } from '../coordinate-converter.types';
import { Radio, Group } from '@/components';
import { ComponentSize } from '@/constants/enums';

export const GraduatedCordFieldRadio: FC<GraduatedCordFieldRadioPropsType> = ({
  name,
  label,
}): JSX.Element => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (event: RadioChangeEvent): void => {
    const value = !!event?.target?.value;
    setFieldValue(name, value);
  };

  return (
    <Group value={!!field.value} className={styles['radio-group']} onChange={handleChange}>
      <Radio
        key={0}
        className={styles.item}
        size={ComponentSize.small}
        labelClassName={styles['radio-label']}
        value={false}
      >
        {label?.[0]}
      </Radio>
      <Radio
        key={1}
        className={styles.item}
        size={ComponentSize.small}
        labelClassName={styles['radio-label']}
        value={true}
      >
        {label?.[1]}
      </Radio>
    </Group>
  );
};
