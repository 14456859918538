import React, { FC, useEffect, useState } from 'react';
import styles from './object-card-item.module.scss';
import {
  Card,
  Icon,
  DeepAndHeightTable,
  PanelTabButton,
  ObjectCardTextSection,
  ObjectCardColumnSection,
  ObjectCardMediaSection,
  ObjectCardTextAreaSection,
} from '@/components';
import { ObjectCardItemPropsType } from '../../object-card-panel.types';
import { PanelTabButtonTypes } from '@/constants/enums';
import { FormikValues, useFormikContext } from 'formik';
import { isEqual } from 'lodash';

export const ObjectCardItem: FC<ObjectCardItemPropsType> = ({
  title,
  type = 'column',
  fields,
  id,
  isEditable,
  onSubmit,
  success,
  smallTitle = false,
  loading,
  item,
  showSaveButton = true,
  defaultEdit = false,
  isDirty = false,
  defaultItem,
}) => {
  const { resetForm, values, initialValues, setValues } = useFormikContext<FormikValues>();
  const [isEdit, setIsEdit] = useState(defaultEdit);
  const handleCancel = (values: FormikValues) => {
    setIsEdit(false);
    resetForm(defaultItem ?? values);
    setValues(defaultItem ?? values);
  };

  const handleSubmit = (initialValues: FormikValues, values: FormikValues) => {
    const formIsDirty = isDirty || !isEqual(initialValues, values);
    if (formIsDirty) {
      onSubmit && onSubmit();
    } else {
      setIsEdit(false);
    }
  };

  useEffect(() => {
    if (success) {
      handleCancel(values);
    }
  }, [success, values]);

  return (
    <Card.Content.Section id={id} key={`card-${String(id)}`} className={styles.wrapper}>
      <div className={styles.title}>
        {smallTitle ? <h5>{title}</h5> : <h3>{title}</h3>}
        {isEditable ? (
          <>
            {isEdit ? (
              <div className={styles['btn-group']}>
                <PanelTabButton
                  className={styles.btn}
                  type={PanelTabButtonTypes.primary}
                  onClick={() => handleCancel(values)}
                >
                  Отменить
                </PanelTabButton>
                {showSaveButton && (
                  <PanelTabButton
                    className={styles.btn}
                    type={PanelTabButtonTypes.tertiary}
                    onClick={() => handleSubmit(initialValues, values)}
                    loading={loading}
                  >
                    Сохранить
                  </PanelTabButton>
                )}
              </div>
            ) : (
              <PanelTabButton
                className={styles.btn}
                type={PanelTabButtonTypes.primary}
                onClick={() => setIsEdit(true)}
              >
                <Icon icon="outline-pencil" size={14} className="me-1" />
                Редактировать
              </PanelTabButton>
            )}
          </>
        ) : null}
      </div>
      <>
        {type === 'text' ? (
          <ObjectCardTextSection fields={fields} isEdit={isEdit} isEditable={isEditable} />
        ) : type === 'textArea' ? (
          <ObjectCardTextAreaSection fields={fields} isEdit={isEdit} isEditable={isEditable} />
        ) : type === 'column' ? (
          <ObjectCardColumnSection fields={fields} isEdit={isEdit} isEditable={isEditable} />
        ) : type === 'table' ? (
          <DeepAndHeightTable isEdit={isEdit} isEditable={isEditable} />
        ) : type === 'media' ? (
          <ObjectCardMediaSection item={item} isEdit={isEdit} isEditable={isEditable} />
        ) : null}
      </>
    </Card.Content.Section>
  );
};
