import React, { FC } from 'react';
import styles from './authorization-wrapper.module.scss';
import logo from '@/assets/icons/login-logo.svg';
import { AuthorizationWrapperTypes } from './authorization-wrapper.types';
import { CPI_RGO } from '@/constants';

export const AuthorizationWrapper: FC<AuthorizationWrapperTypes> = ({
  children,
  headerText,
}): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.background} />
      <div className={styles.card}>
        <div className={styles.header}>
          <img
            src={logo}
            alt="Центр подводных исследований"
            className={styles['header-logo']}
            onClick={() => {
              location.href = CPI_RGO;
            }}
          />
          <h1>{headerText}</h1>
        </div>

        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
};
