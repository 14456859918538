import React, { FC, useEffect } from 'react';
import { Table } from 'antd';
import styles from '../../route-panel-table.module.scss';
import { IDistance, IPointValue } from '@/types';
import { handleGetAzimuth, handleGetDistance } from '../../../route-panel-utils';

export const Distance: FC<IDistance> = ({ coords, distanceKm, setDistanceKm }) => {
  useEffect(() => {
    let distance = 0;

    if (!coords[0].value?.isDefault && coords.length > 1) {
      //distance

      for (let i = 0; i < coords.length - 1; i++) {
        const pointOne: IPointValue = coords[i].value as IPointValue;
        const pointTwo: IPointValue = coords[i + 1].value as IPointValue;

        const lat1 = pointOne?.lat as number;
        const lng1 = pointOne?.lng as number;
        const lat2 = pointTwo?.lat as number;
        const lng2 = pointTwo?.lng as number;

        const path = handleGetDistance(coords, lat1, lat2, lng1, lng2);
        pointTwo.distance = path;
        distance = parseFloat(distance.toString()) + path;
      }

      //azimuth

      const lat1 = coords[0].value?.lat as number;
      const lng1 = coords[0].value?.lng as number;
      const lat2 = coords.slice(-1)[0].value?.lat as number;
      const lng2 = coords.slice(-1)[0].value?.lng as number;

      const azimuth = handleGetAzimuth(coords, lat1, lat2, lng1, lng2);
      const coordsLast: IPointValue = coords.slice(-1)[0].value as IPointValue;
      coordsLast.azimuth = azimuth;
    }

    if (distance.toFixed(2) !== '0.00') {
      setDistanceKm(parseFloat(distance.toFixed(2)));
    } else {
      setDistanceKm(0);
    }
  }, [coords]);

  return (
    <Table.Summary.Row className={'ant-table-row'}>
      <Table.Summary.Cell index={0}>
        <div className={styles.data}>Расстояние до цели, км</div>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1}>
        <div className={styles.data}>{distanceKm ? distanceKm : 'Нет добавленной информации'}</div>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};
