export const defaultCoords = [
  {
    id: 0,
    number: undefined,
    x: undefined,
    y: undefined,
    z: undefined,
    marker: undefined,
    isDefault: true,
  },
];

export const columns = [
  {
    title: 'Номер точки',
    dataIndex: 'number',
    key: 'number',
    render: (_: number) => {
      return _;
    },
  },
  {
    title: 'Широта, X',
    dataIndex: 'x',
    key: 'x',
    render: (_: string) => {
      return _;
    },
  },
  {
    title: 'Долгота, Y',
    dataIndex: 'y',
    key: 'y',
    render: (_: string) => {
      return _;
    },
  },
  {
    title: 'Глубина, Z (м)',
    dataIndex: 'z',
    key: 'z',
    width: 140,
    render: (_: string) => {
      return _;
    },
  },
];
