import { defaultSearchProps, Icon, PanelTableButton, TableCell, Table } from '@/components';
import { handleSortNumber, handleSortString } from '@/utils';
import { SectorsTablePropsType } from './sectors-table.types';
import { MAP, SECTORS_LAYER } from '@/constants';
import { ColumnsType } from 'antd/lib/table';
import React, { FC } from 'react';
import { ISector } from '@/types';
import { useMap } from '@/context';
import { setSelectedLayer } from '@/state/slice';
import { useNavigate } from 'react-router';
import { LatLngExpression, Polygon } from 'leaflet';
import { useDispatch } from 'react-redux';

export const SectorsTable: FC<SectorsTablePropsType> = ({
  data,
  loading,
  error,
  isEditable,
  onEdit,
  onDelete,
}): JSX.Element => {
  const { map } = useMap();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFlyToSector = (polygon: LatLngExpression[][][]): void => {
    navigate(MAP);
    map?.flyToBounds(new Polygon(polygon).getBounds(), { maxZoom: 18 });
    dispatch(setSelectedLayer(SECTORS_LAYER));
  };

  const columns: ColumnsType<ISector> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 62,
      sorter: (first, second) => handleSortNumber(first.id, second.id),
    },
    {
      title: <Icon size={12} icon="outline-geo-alt" />,
      dataIndex: 'polygon',
      fixed: 'left',
      width: 40,
      align: 'center',
      render: (value) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Приблизить к сектору"
          icon="outline-geo-alt"
          onClick={() => handleFlyToSector(value)}
          size={12}
          tooltipPlacement={'bottom'}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      dataIndex: 'id',
      fixed: 'left',
      key: 'editable',
      width: 40,
      align: 'center',
      render: (value) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Изменить сектор"
          icon="outline-pencil"
          onClick={() => onEdit(value)}
          size={12}
          tooltipPlacement={'bottom'}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      dataIndex: 'id',
      fixed: 'left',
      width: 40,
      key: 'editable',
      align: 'center',
      render: (value) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Удалить сектор"
          icon="outline-trash"
          onClick={() => onDelete(value)}
          size={12}
          tooltipPlacement={'bottom'}
        />
      ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 372,
      render: (value: string) => <TableCell value={value} maxWidth={372} />,
      sorter: (first, second, sortOrder) => handleSortString(first.name, second.name, sortOrder),
      ...defaultSearchProps('name'),
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      render: (value: string) => <TableCell value={value} maxWidth={1000} />,
      sorter: (first, second, sortOrder) =>
        handleSortString(first.description, second.description, sortOrder),
      ...defaultSearchProps('description'),
    },
  ];

  const preparedColumns = isEditable ? columns : columns.filter((item) => item.key !== 'editable');

  return (
    <Table
      withSelection={false}
      dataSource={data}
      columns={preparedColumns}
      loading={loading}
      error={error}
      scroll={{ x: 1000, y: 500 }}
    />
  );
};
