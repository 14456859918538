import React, { FC, useEffect, useState } from 'react';
import styles from './z-coords-panel-title.module.scss';
import { LatLngBoundsExpression } from 'leaflet';
import { useMap } from '@/context';
import { IZCoords, IZCoordsPanelTitle } from '@/types';
import { ModalHeaderButtons } from '@/components';

export const ZCoordsPanelTitle: FC<IZCoordsPanelTitle> = ({
  coords,
  setCoords,
  checkBoxMarked,
  setCheckBoxMarked,
}) => {
  const { map } = useMap();
  const checkMap = map !== null;

  const [isFlyTo, setIsFlyTo] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    if (checkBoxMarked.length === 0 || checkBoxMarked[0].isDefault) {
      setIsDelete(true);
    } else {
      setIsDelete(false);
    }

    return () => {};
  }, [checkBoxMarked, coords]);

  useEffect(() => {
    if (coords[0].isDefault) {
      setIsFlyTo(true);
    } else {
      setIsFlyTo(false);
    }
  }, [coords]);

  const handleFlyTo = () => {
    if (checkMap) {
      const bounds: LatLngBoundsExpression = [];

      if (checkBoxMarked.length !== 0) {
        checkBoxMarked.map((defaultCoords) => {
          bounds.push([defaultCoords.x as number, defaultCoords.y as number]);
        });
      } else {
        coords.map((defaultCoords) => {
          bounds.push([defaultCoords.x as number, defaultCoords.y as number]);
        });
      }
      map?.flyToBounds(bounds);
    }
  };

  const handleDeleteCoords = () => {
    const newCoords: IZCoords[] = coords.filter((i) => !checkBoxMarked.includes(i));
    setCoords(() => newCoords);
    setCheckBoxMarked(() => []);
  };

  return (
    <>
      <p className={styles['text-title']}>Глубина</p>
      <div className={styles['list-icon']}>
        <ModalHeaderButtons
          text="Приблизиться к точкам"
          onClick={() => handleFlyTo()}
          icon="fill-geo-alt"
          placement="top"
          className={styles.btn}
          disable={isFlyTo}
        />
        <ModalHeaderButtons
          className={styles.btn}
          disable={isDelete}
          text="Удалить точки"
          onClick={() => handleDeleteCoords()}
          icon="outline-trash"
          placement="top"
        />
      </div>
    </>
  );
};
