import { DefaultResponse, DefaultShipBase, NewShipBase, ShipBase } from '@/types';
import { LatLngTuple } from 'leaflet';
import { api } from '../api';

const transformToShipsBases = (res: {
  success: boolean;
  ships_bases: DefaultShipBase[];
}): ShipBase[] => {
  return res.ships_bases.map((shipBase) => ({
    id: shipBase.id,
    created_at: shipBase.created_at,
    name: shipBase.name,
    updated_at: shipBase.updated_at,
    coordinates: [parseFloat(shipBase.lat), parseFloat(shipBase.lng)] as LatLngTuple,
  }));
};

export const shipsBasesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getShipsBases: builder.query<
      ShipBase[],
      {
        expId: number;
      } | void
    >({
      query(body) {
        return {
          url: '/ships-base/list',
          method: 'GET',
          params: { expId: body?.expId },
          credentials: 'include',
        };
      },
      transformResponse: transformToShipsBases,
      providesTags: ['ShipsBases'],
    }),
    createShipBase: builder.mutation<DefaultResponse, NewShipBase>({
      query(body) {
        return {
          url: '/ships-base/create',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ShipsBases'],
    }),
    editShipBase: builder.mutation<DefaultResponse, NewShipBase>({
      query(body) {
        return {
          url: '/ships-base/edit',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ShipsBases'],
    }),
    deleteShipBase: builder.mutation<
      DefaultResponse,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/ships-base/delete',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ShipsBases'],
    }),
  }),
});

export const {
  useGetShipsBasesQuery,
  useCreateShipBaseMutation,
  useEditShipBaseMutation,
  useDeleteShipBaseMutation,
} = shipsBasesApi;
