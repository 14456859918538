import React, { FC, useEffect, useState } from 'react';
import { ExpeditionCardSection, SectionWithSelectPropsType } from '../index';
import {
  ConfirmModal,
  defaultSearchProps,
  DropdownFieldDataType,
  FieldType,
  Icon,
  PanelTableButton,
  Table,
  TableCell,
  TableModal,
} from '@/components';
import { handleSortNumber, handleSortString, openErrorNotify, openInfoNotify } from '@/utils';
import { ColumnsType } from 'antd/lib/table';
import { ExpeditionTableTool, ExpeditionTool } from '@/types';
import styles from '../section.module.scss';
import {
  useCreateExpeditionToolMutation,
  useDeleteExpeditionToolMutation,
  useGetToolsListQuery,
} from '@/state/api';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { findIndex } from 'lodash';

export const ToolsSection: FC<SectionWithSelectPropsType> = ({
  isEditable,
  item,
  selectData,
  ...rest
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const preparedData = item?.tools.map((item) => ({ ...item, ...item.info }));

  const [selectedItem, setSelectedItem] = useState<ExpeditionTool>();

  const [
    createTool,
    {
      isLoading: createLoading,
      isError: createError,
      isSuccess: createSuccess,
      reset: createReset,
    },
  ] = useCreateExpeditionToolMutation();
  const [
    deleteTool,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
      reset: deleteReset,
    },
  ] = useDeleteExpeditionToolMutation();
  const { isLoading, isError } = useGetToolsListQuery(item?.id ? { expId: item?.id } : undefined);

  const handleSetSelectedItem = (id: number) => {
    setSelectedItem(item?.tools?.find((item) => item.tool_id === id));
  };

  const handleDelete = (expedition_id: number, tool_id: number) => {
    deleteTool({
      expedition_id,
      tool_id,
    });
  };

  const handleAdd = (value: FormikValues) => {
    const preparedValue = {
      ...value,
      expedition_id: item?.id,
    };
    createTool(preparedValue);
  };

  const toggleDelete = (id: number) => {
    handleSetSelectedItem(id);
    setShowDeleteModal((prevState) => !prevState);
  };

  const toggleAdd = () => {
    setShowAddModal((prevState) => !prevState);
  };

  const editableColumns: ColumnsType<ExpeditionTableTool> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'index',
      align: 'left',
      fixed: 'left',
      width: 60,
      render: (value: string, row) => <TableCell value={findIndex(preparedData, row) + 1} />,
      sorter: (first, second) =>
        handleSortNumber(findIndex(preparedData, first), findIndex(preparedData, second)),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      fixed: 'left',
      width: 60,
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second) => handleSortNumber(first?.id, second?.id),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      fixed: 'left',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleDelete(id)}
          tooltipTitle="Удалить материально-техническое обеспечение из экспедиции"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'МТО',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (value: string) => <TableCell value={value} showAllText={true} />,
      sorter: (first, second, sortOrder) => handleSortString(first?.name, second?.name, sortOrder),
      ...defaultSearchProps('name'),
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      key: 'comment',
      align: 'left',
      render: (value: string) => <TableCell value={value} showAllText={true} />,
      sorter: (first, second, sortOrder) => handleSortString(first?.name, second?.name, sortOrder),
      ...defaultSearchProps('comment'),
    },
  ];

  const readOnlyColumn = editableColumns.filter((item) => item.key !== 'icon');

  const validationSchema = Yup.object({
    tool_id: Yup.string().required(
      'Пожалуйста, выберите материально-техническое обеспечение из списка',
    ),
  });

  const initialValues = {
    tool_id: '',
  };

  const fields: FieldType[] = [
    {
      label: 'Материально-техническое обеспечение',
      name: 'tool_id',
      type: 'dropdown',
      required: true,
      loading: isLoading,
      error: isError,
      data: selectData as DropdownFieldDataType[],
    },
  ];

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        'Добавление материально-технического обеспечения экспедиции успешно выполнено',
      );
      createReset();
    }
    if (createError) {
      openErrorNotify(
        'Произошла ошибка',
        'При добавлении материально-технического обеспечения экспедиции произошла ошибка',
      );
      createReset();
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        'Удаление материально-технического обеспечения экспедиции успешно выполнено',
      );
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify(
        'Произошла ошибка',
        'При удалении материально-технического обеспечения экспедиции произошла ошибка',
      );
      deleteReset();
    }
  }, [deleteSuccess, deleteError]);

  return (
    <ExpeditionCardSection
      {...rest}
      buttonText="Добавить МТО"
      onClickAdd={toggleAdd}
      isEditable={isEditable}
    >
      <>
        {item?.tools && item?.tools?.length > 0 && (
          <Table
            withSelection={false}
            dataSource={preparedData}
            columns={isEditable ? editableColumns : readOnlyColumn}
            className={styles.table}
            noDataClassName={styles['no-data']}
            scroll={{ x: 1225, y: 300 }}
            withPagination={false}
          />
        )}
        <TableModal
          onSubmit={(value) => handleAdd(value)}
          onClose={() => setShowAddModal(false)}
          validationSchema={validationSchema}
          initialValues={initialValues}
          loading={createLoading}
          success={createSuccess}
          buttonText="Добавить"
          open={showAddModal}
          fields={fields}
          width={563}
          title="Добавление МТО"
        />
        <ConfirmModal
          open={showDeleteModal}
          title="Подтвердите удаление МТО"
          content={
            <p>
              Вы уверены, что хотите удалить&nbsp;
              {selectedItem?.info.name ? (
                <strong>{selectedItem?.info.name}</strong>
              ) : (
                'выбранное МТО'
              )}
              ?
            </p>
          }
          submitButtonText="Удалить"
          cancelButtonText="отменить"
          onSubmit={() =>
            selectedItem && handleDelete(selectedItem?.expedition_id, selectedItem?.tool_id)
          }
          onCancel={() => setShowDeleteModal(false)}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
      </>
    </ExpeditionCardSection>
  );
};
