import { resetAction } from '@/state/actions';
import { ObjectsStateType } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ObjectsStateType = {
  selectedObject: null,
  selectedObjects: [],
};

const objectsSlice = createSlice({
  name: 'objects',
  initialState,
  reducers: {
    setSelectedObject: (state, { payload }: PayloadAction<number | null>) => {
      state.selectedObject = payload;
    },
    setSelectedObjectsIds: (state, { payload }: PayloadAction<Array<number> | undefined>) => {
      if (payload) {
        state.selectedObjects = payload;
      } else {
        state.selectedObjects = [];
      }
    },
    addSelectedObjects: (state, { payload }: PayloadAction<number>) => {
      const valueUnavailable = !state.selectedObjects?.find((item) => item === payload);
      if (valueUnavailable) {
        state.selectedObjects = [...state.selectedObjects, payload];
      }
    },
    removeSelectedObjects: (state, { payload }: PayloadAction<number>) => {
      const valueAvailable = state.selectedObjects?.find((item) => item === payload);
      if (valueAvailable) {
        state.selectedObjects = state.selectedObjects.filter((item) => item !== payload);
      }
    },
    toggleSelectedObjects: (state, { payload }: PayloadAction<number>) => {
      const valueAvailable = state.selectedObjects?.find((item) => item === payload);
      if (valueAvailable) {
        state.selectedObjects = state.selectedObjects.filter((item) => item !== payload);
      } else {
        state.selectedObjects = [...state.selectedObjects, payload];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAction, () => {
      return initialState;
    });
  },
});

export const {
  setSelectedObject,
  toggleSelectedObjects,
  setSelectedObjectsIds,
  addSelectedObjects,
  removeSelectedObjects,
} = objectsSlice.actions;
export const { reducer: objectsReducer } = objectsSlice;
