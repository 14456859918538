import { FieldType, TableModal } from '@/components/table-components';
import { FC, useState } from 'react';
import type { SectorFormPropsType, SectorFormType } from './sector-content.types';
import * as Yup from 'yup';
import { InputTypes } from '@/constants/enums';

export const SectorForm: FC<SectorFormPropsType> = ({ item, setData, onRedirect }) => {
  const fields: FieldType[] = [
    {
      label: 'Наименование сектора',
      name: 'name',
      placeholder: 'Введите наименование',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Описание сектора',
      name: 'description',
      placeholder: 'Введите описание',
      type: InputTypes.textArea,
    },
  ];

  const validationSchema = Yup.object({
    id: Yup.number(),
    name: Yup.string().required('Пожалуйста, введите наименование сектора'),
    description: Yup.string().nullable(),
  });

  const [isShowAddModal, setIsShowAddModal] = useState(item ? false : true);
  const [isShowEditModal, setIsShowEditModal] = useState(item ? true : false);

  const initialValues = {
    name: item?.name || '',
    description: item?.description || null,
  };

  const handleCreateForm = (value: SectorFormType) => {
    setData(value);
    setIsShowAddModal(false);
  };
  const handleUpdateForm = (value: SectorFormType) => {
    setData(value);
    setIsShowEditModal(false);
  };

  return (
    <>
      <TableModal
        onSubmit={(value) => handleCreateForm(value as SectorFormType)}
        buttonText="Перейти к созданию полигона"
        onClose={() => setIsShowAddModal(false)}
        title="Создание сектора"
        validationSchema={validationSchema}
        redirectOnClose={onRedirect}
        initialValues={initialValues}
        open={isShowAddModal}
        loading={false}
        success={false}
        fields={fields}
        width={563}
      />
      <TableModal
        onSubmit={(value) => handleUpdateForm(value as SectorFormType)}
        onClose={() => setIsShowEditModal(false)}
        validationSchema={validationSchema}
        initialValues={initialValues}
        loading={false}
        redirectOnClose={onRedirect}
        initialFormDirty={true}
        success={false}
        buttonText="Перейти к редактированию полигона"
        open={isShowEditModal}
        fields={fields}
        width={563}
        title={'Редактирование сектора'}
      />
    </>
  );
};
