import { useMap } from '@/context';
import { useAppSelector } from '@/state';
import { IPoint, IPointValue } from '@/types';
import L, { LatLngExpression, LeafletMouseEvent, Polyline } from 'leaflet';
import { FC, useEffect } from 'react';
import styles from './route-panel-map-handlers.module.scss';
import { convertToDecimalCord, getPointMarker, polylineOptions } from '@/utils';
import { handleGetAzimuth, handleGetDistance, renderMarkerTextToolTip } from '../route-panel-utils';

export const RoutePanelMapHandlers: FC<{ coords: IPoint[] }> = ({ coords }) => {
  const { map } = useMap();
  const checkMap = map !== null;
  const coordsType = useAppSelector((state) => {
    return state.map.coordsType;
  });
  const isAddCoords = useAppSelector((state) => {
    return state.toolsPanels.routePanel.isAddCoords;
  });

  let collectPointValue: IPointValue;

  const handleEditRenderCoords = (event: LeafletMouseEvent): void => {
    if (collectPointValue) {
      collectPointValue.marker?.remove();
      collectPointValue.polyline?.remove();
    }

    collectPointValue = {
      lat: event.latlng.lat,
      lng: event.latlng.lng,
    };

    const marker = L.marker(event.latlng, {
      icon: getPointMarker(styles['route-panel-cursor']),
      opacity: 0,
    });

    map && map.addLayer(marker);
    collectPointValue.marker = marker;

    if (!coords.slice(-1)[0].value?.isDefault) {
      const coordsLastValue = coords.slice(-1)[0].value;
      const polylinePoints: LatLngExpression[] = [
        [coordsLastValue?.lat as number, coordsLastValue?.lng as number],
        [event.latlng.lat, event.latlng.lng],
      ];
      const polyline: Polyline = new L.Polyline(polylinePoints, polylineOptions);
      if (polyline) {
        map && map.addLayer(polyline);
        collectPointValue.polyline = polyline;
      }
    }

    if (coords.length > 0 && !coords.slice(-1)[0].value?.isDefault) {
      let lat1 = coords.slice(-1)[0].value?.lat as number;
      let lng1 = coords.slice(-1)[0].value?.lng as number;
      const lat2 = collectPointValue.lat as number;
      const lng2 = collectPointValue.lng as number;

      collectPointValue.distance = handleGetDistance(coords, lat1, lat2, lng1, lng2);

      lat1 = coords[0].value?.lat as number;
      lng1 = coords[0].value?.lng as number;

      collectPointValue.azimuth = handleGetAzimuth(coords, lat1, lat2, lng1, lng2);
    }

    collectPointValue.renderLat =
      coordsType === 'DEC'
        ? parseFloat(collectPointValue.lat.toString()).toFixed(6)
        : convertToDecimalCord(collectPointValue.lat.toString(), true);
    collectPointValue.renderLng =
      coordsType === 'DEC'
        ? parseFloat(collectPointValue.lng.toString()).toFixed(6)
        : convertToDecimalCord(collectPointValue.lng.toString(), false);

    const text = renderMarkerTextToolTip(collectPointValue);

    collectPointValue.marker?.bindTooltip(text, { permanent: true });
  };

  useEffect(() => {
    if (isAddCoords) {
      map?.addEventListener('mousemove', handleEditRenderCoords);
    }
    return () => {
      map?.removeEventListener('mousemove', handleEditRenderCoords);
      if (collectPointValue) {
        collectPointValue.marker?.remove();
        collectPointValue.polyline?.remove();
      }
    };
  }, [isAddCoords, checkMap, coords]);

  return null;
};
