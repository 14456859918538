import React, { FC, useEffect, useState } from 'react';
import styles from '../../default-tab.module.scss';
import { useDeleteRegionMutation, useGetRegionsQuery } from '@/state/api';
import { RegionsTable, PanelTabButton, BlackTooltip, Icon, ConfirmModal } from '@/components';
import { ComponentSize, PanelTabButtonTypes, Permissions, PolygonTypes } from '@/constants/enums';
import { openErrorNotify, openInfoNotify } from '@/utils';
import { useNavigate } from 'react-router';
import { LINK_TO_POLYGON_TOOL } from '@/constants';
import { IRegion } from '@/types';
import { useAppSelector } from '@/state';

export const RegionsTab: FC = (): JSX.Element => {
  const { isError: isGetError, isLoading: getLoading, data } = useGetRegionsQuery();
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);
  const isEditable = userPermissions?.includes(Permissions.mapDataManagment);

  const [
    deleteRegion,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      reset: resetDelete,
    },
  ] = useDeleteRegionMutation();

  const [selectedRegion, setSelectedRegion] = useState<IRegion>();
  const preparedRegions = data || [];

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const navigate = useNavigate();

  const getRegion = (id: number) => {
    const item = preparedRegions.find((region) => region.id === id);
    item && setSelectedRegion(item);
  };

  const handleEdit = (id: number) => {
    navigate(LINK_TO_POLYGON_TOOL(PolygonTypes.region, id));
  };

  const handleDelete = (id: number) => {
    resetDelete();
    setShowConfirmDeleteModal((prevState) => !prevState);
    getRegion(id);
  };

  const handleCreate = (): void => {
    navigate(LINK_TO_POLYGON_TOOL(PolygonTypes.region));
  };

  const handleDeleteRegion = (id: number): void => {
    deleteRegion({
      id: id,
    });
  };

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Удаление района',
        <>
          Район <b>{selectedRegion?.name}</b> был удален
        </>,
      );
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении произошла ошибка');
    }
  }, [deleteSuccess, deleteError]);

  return (
    <div className={styles.wrapper}>
      {!isGetError && !getLoading && (
        <div className={styles.header}>
          <BlackTooltip
            title={
              'Перечень районов, на которых проводятся экспедиции. Возможно редактировать информацию, удалять, создавать новые. Отображаются на карте в слое Районы.'
            }
            placement={'bottomLeft'}
          >
            <PanelTabButton
              className={styles['button-panel-help']}
              onClick={() => {}}
              type={PanelTabButtonTypes.icon}
              size={ComponentSize.small}
            >
              <Icon icon="outline-question-circle" size={14} />
            </PanelTabButton>
          </BlackTooltip>
          {isEditable ? (
            <PanelTabButton
              disabled={getLoading || isGetError}
              type={PanelTabButtonTypes.roundSecondary}
              className={styles.button}
              size={ComponentSize.small}
              onClick={handleCreate}
            >
              Создать новый район
            </PanelTabButton>
          ) : null}
        </div>
      )}
      {isEditable ? (
        <ConfirmModal
          open={showConfirmDeleteModal}
          title="Подтвердите удаление района"
          content={
            <p>
              Вы уверены, что хотите удалить&nbsp;
              {selectedRegion?.name ? (
                <>
                  район <strong>{selectedRegion?.name}</strong>
                </>
              ) : (
                'указанный район'
              )}
              ?
            </p>
          }
          submitButtonText="Удалить район"
          cancelButtonText="Отменить"
          onSubmit={() => handleDeleteRegion(selectedRegion?.id as number)}
          onCancel={() => setShowConfirmDeleteModal((prevState) => !prevState)}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
      ) : null}
      <RegionsTable
        data={preparedRegions}
        loading={getLoading}
        error={isGetError}
        isEditable={isEditable}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </div>
  );
};
