import { SortOrder } from 'antd/lib/table/interface';

export const handleSortString = (
  firstValue?: string | null,
  secondValue?: string | null,
  sortOrder?: SortOrder | null,
): number => {
  if (firstValue && secondValue) {
    return firstValue.localeCompare(secondValue);
  }
  if (firstValue) return sortOrder === 'ascend' ? -1 : 1;
  if (secondValue) return sortOrder === 'ascend' ? 1 : -1;
  return 0;
};
export const handleSortNumber = (
  firstValue?: number | string | null,
  secondValue?: number | string | null,
  sortOrder?: SortOrder | null,
): number => {
  if (typeof firstValue === 'string' && typeof secondValue === 'string') {
    if (Number(firstValue) && Number(secondValue)) {
      return Number(firstValue) - Number(secondValue);
    } else {
      return firstValue.localeCompare(secondValue);
    }
  }
  if (typeof firstValue === 'number' && typeof secondValue === 'number') {
    return firstValue - secondValue;
  }
  if (firstValue) return sortOrder === 'ascend' ? -1 : 1;
  if (secondValue) return sortOrder === 'ascend' ? 1 : -1;
  return 0;
};

export const handleDateSort = (
  firstValue?: string | null,
  secondValue?: string | null,
  sortOrder?: SortOrder | null,
): number => {
  if (firstValue && secondValue) {
    const dateA = new Date(firstValue),
      dateB = new Date(secondValue);

    return dateB.getTime() - dateA.getTime();
  }

  if (firstValue) return sortOrder === 'ascend' ? -1 : 1;
  if (secondValue) return sortOrder === 'ascend' ? 1 : -1;
  return 0;
};

export const handleFilterString = <T>(
  searchValue: string | number | boolean,
  values: T,
  field: string,
  key?: string,
): boolean => {
  const preparedData = values?.[field as keyof T];
  if (preparedData) {
    if (Array.isArray(preparedData)) {
      return (
        preparedData
          .map((item: T) => {
            if (key) {
              const recordAsString = item[key as keyof T];

              return recordAsString
                ?.toString()
                ?.toLowerCase()
                .includes(searchValue.toString().toLowerCase());
            }
            return item?.toString().toLowerCase().includes(searchValue.toString().toLowerCase());
          })
          .filter(Boolean).length > 0
      );
    }
    return preparedData?.toString()?.toLowerCase().includes(searchValue.toString().toLowerCase());
  }
  return false;
};
