import {
  ExpeditionStatusesType,
  ExpeditionTablesTabsType,
  LayerTablesTabsType,
  PolygonType,
  SelectCoordsPages,
  SettingTablesTabsType,
  Targets3DType,
} from '@/constants/enums';

export const MAP = '/map';
export const POLYGON_TOOL = '/polygon_tool';
export const ERROR = '/error';
export const AUTH = '/auth';
export const RESET_PASSWORD = '/reset-password';
export const OTP_VALIDATION = '/otp-validation';
export const NEW_PASSWORD = '/new-password';
export const LOGIN = '/login';
export const PROFILE = '/profile';
export const LINK_TO_RESET_PASSWORD = `${AUTH}${RESET_PASSWORD}`;
export const LINK_TO_NEW_PASSWORD = `${AUTH}${NEW_PASSWORD}`;
export const LINK_TO_LOGIN = `${AUTH}${LOGIN}`;
export const LINK_TO_OTP_VALIDAITON = `${AUTH}${OTP_VALIDATION}`;
export const LINK_TO_PROFILE = `${MAP}${PROFILE}`;

export const LAYERS = '/layers';
export const Z_COORDS = '/z-coords';
export const LOG = '/meta-data-log';
export const GALSES = '/meta-data-galses';
export const DISTANCE = '/distance';
export const CORDS_CONVERTER = '/cords-converter';
export const INFO = '/info';
export const LINK_TO_LAYERS = `${MAP}${LAYERS}`;
export const LINK_TO_LOG = `${MAP}${LOG}`;
export const LINK_TO_GALSES = `${MAP}${GALSES}`;
export const LINK_TO_Z_CORD = `${MAP}${Z_COORDS}`;
export const LINK_TO_DISTANCE = `${MAP}${DISTANCE}`;
export const LINK_TO_CORDS_CONVERTER = `${MAP}${CORDS_CONVERTER}`;
export const LINK_TO_INFO = `${MAP}${INFO}`;

export const LINK_TO_POLYGON_TOOL = (type: PolygonType, id?: number) => {
  const idParam = id?.toString() ?? '';

  return POLYGON_TOOL + '/' + type + '/' + idParam;
};

export const OBJECT = '/object';
export const OBJECT_CREATE = OBJECT + '/create';
export const OBJECT_FORMULAR = OBJECT + '/formular' + '/:id';
export const FORMULAR = '/formular' + '/:id';
export const OBJECT_CARD = OBJECT + '/card' + '/:id';
export const MEDIA = '/media';
export const OBJECT_MEDIA = OBJECT_FORMULAR + MEDIA;

export const LINK_TO_OBJECT_CARD = (id: number): string =>
  MAP + OBJECT_CARD.replace(':id', id.toString());

export const LINK_TO_OBJECT_CREATE = `${MAP}${OBJECT_CREATE}`;

export const LINK_TO_OBJECT_MEDIA = (id: number): string =>
  MAP + OBJECT_FORMULAR.replace(':id', id.toString()) + MEDIA;

export const TABLE = '/table';
export const LAYERS_TABLE = '/layers';
export const EXPEDITION_TABLE = '/expedition';
export const BOOKMARK = '/bookmark';
export const SETTING = '/setting';

export const LINK_TO_TABLE = `${MAP}${TABLE}${LAYERS_TABLE}`;
export const LINK_TO_LAYERS_TABLE = `${MAP}${TABLE}${LAYERS_TABLE}`;

export const LINK_TO_LAYERS_TAB = (key: LayerTablesTabsType): string =>
  `${MAP}${TABLE}${LAYERS_TABLE}/${key as string}`;
export const LINK_TO_EXPEDITION_TAB = (key: ExpeditionTablesTabsType): string =>
  `${MAP}${TABLE}${EXPEDITION_TABLE}/${key as string}`;
export const LINK_TO_SETTING_TAB = (key: SettingTablesTabsType) =>
  `${MAP}${SETTING}/${key as string}`;

export const LINK_TO_EXPEDITION_TABLE = `${MAP}${TABLE}${EXPEDITION_TABLE}`;

export const LINK_TO_BOOKMARK = `${MAP}${BOOKMARK}`;

export const OBJECT_GROUP = '/object-group';
export const LINK_TO_OBJECT_GROUP = (id: number | string): string => `${MAP}${OBJECT_GROUP}/${id}`;

export const EXPEDITION = '/expedition';
export const EXPEDITION_PANEL = EXPEDITION + '/:id' + '/:status';
export const EXPEDITION_PANEL_WITHOUT_STATUS = EXPEDITION + '/:id';

export const LINK_TO_EXPEDITION_PANEL = (id: number, status?: ExpeditionStatusesType): string =>
  MAP + EXPEDITION_PANEL.replace(':id', id.toString()).replace(':status', status ?? '');

export const BATHYMETRIC = '/bathymetric';
export const VIEW_3D = '/3d';

export const VIEW_3D_ROUTE = `/:type${VIEW_3D}/:id`;
export const LINK_TO_VIEW_3D = (type: Targets3DType, id: number | string): string =>
  `${MAP}${VIEW_3D_ROUTE.replace(':id', String(id)).replace(':type', type)}`;

export const GALS = '/gals';

export const LINK_TO_GALS = (id: number | string): string => `${MAP}${GALS}/${id}`;

export const SHIP_BASE = '/ship-base';

export const SECTOR = '/sector';
export const CREATE_SECTOR = `${SECTOR}/create`;

export const CREATE_SHIP_BASE = `${SHIP_BASE}/create`;

export const EDIT_SHIP_BASE = (id: number | string): string => `${SHIP_BASE}/edit/${id}`;

export const EXPEDITION_STEP_PAGE = `${EXPEDITION}/:expeditionId`;

export const CREATE_SECTOR_PAGE = `/${EXPEDITION}/:expeditionId/sector/create`;

export const LINK_TO_OBJECT_FORMULAR = (
  id: number | string,
  expeditionId?: number | string,
): string =>
  expeditionId
    ? EXPEDITION_STEP_PAGE.replace(':expeditionId', expeditionId.toString()) +
      OBJECT_FORMULAR.replace(':id', id.toString())
    : MAP + OBJECT_FORMULAR.replace(':id', id.toString());

export const LINK_TO_EXPEDITION_OBJECT_STEP_PAGE = (expeditionId?: number | string): string =>
  expeditionId
    ? EXPEDITION_STEP_PAGE.replace(':expeditionId', expeditionId.toString()) + OBJECT
    : '';

export const LINK_TO_EXPEDITION_SECTOR_STEP_PAGE = (expeditionId?: number | string): string =>
  expeditionId
    ? EXPEDITION_STEP_PAGE.replace(':expeditionId', expeditionId.toString()) + SECTOR
    : '';

export const LINK_TO_EXPEDITION_SECTOR_CREATE_STEP_PAGE = (
  expeditionId?: number | string,
): string =>
  expeditionId
    ? EXPEDITION_STEP_PAGE.replace(':expeditionId', expeditionId.toString()) + CREATE_SECTOR
    : '';

export const LINK_TO_EXPEDITION_OBJECT_CREATE_STEP_PAGE = (
  expeditionId?: number | string,
): string =>
  expeditionId
    ? EXPEDITION_STEP_PAGE.replace(':expeditionId', expeditionId.toString()) + OBJECT_CREATE
    : '';

export const SELECT_COORDS_PAGE = 'select-cord';
export const SELECT_OBJECT_COORDS_PAGE = `${SELECT_COORDS_PAGE}/${SelectCoordsPages.object}`;
export const SELECT_SHIP_BASE_COORDS_PAGE = `${SELECT_COORDS_PAGE}/${SelectCoordsPages.shipBases}`;
export const LINK_TO_SELECT_COORDS_PAGES = (type: string, id?: number) =>
  `/${SELECT_COORDS_PAGE}/${type}${id ? `/${id}` : ''}}`;
export const LINK_TO_SELECT_COORDS_PAGES_LAYOUT = (type: string, id?: number) =>
  `/${SELECT_COORDS_PAGE}/${type}${id ? `/${id}` : ''}${LAYERS}`;
