import React, { FC, useState } from 'react';
import styles from './group-card.module.scss';
import { Icon } from '@/components';
import { Collapse } from 'antd';
import { listCheck } from './group-card-data';
import { useDispatch } from 'react-redux';
import { toggleObjectLayer } from '@/state/slice';
import { ALL_OBJECT_LAYERS } from '@/constants';
import { useAppSelector } from '@/state';
import { Card } from '../card';

export const GroupCard: FC = (): JSX.Element => {
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const { selectedLayers } = useAppSelector((state) => state.map);

  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const checked = ALL_OBJECT_LAYERS.filter((layer) => selectedLayers.includes(layer)).length > 0;

  const handleToggleAllObjectLayers = (value: boolean) => {
    dispatch(toggleObjectLayer(!value));
  };

  const handleToggleCollapse = (): void => {
    setCollapseIsOpen((prevState) => !prevState);
  };

  return (
    <div className={styles.wrapper}>
      <Collapse
        activeKey={Number(collapseIsOpen)}
        expandIcon={() => (
          <Icon
            className={styles.arrow}
            icon="outline-chevron-down"
            size={16}
            onClick={handleToggleCollapse}
          />
        )}
      >
        <Panel
          key={1}
          header={
            <Card
              icon="fill-object-simple"
              title="Объекты исследования"
              value={''}
              isChecked={checked}
              onChange={() => handleToggleAllObjectLayers(checked)}
            />
          }
        >
          {listCheck.map(({ icon, title, value }) => (
            <Card icon={icon} key={title} title={title} value={value} border={false} />
          ))}
        </Panel>
      </Collapse>
    </div>
  );
};
