import React, { FC } from 'react';
import { CopyButton } from '@/components';
import { CopyButtonWrapperPropsType } from '../coordinate-converter.types';
import { DecimalCordType, GraduatedCordType } from '@/types';
import { prepareCordToString } from '@/utils';

export const CopyButtonWrapper: FC<CopyButtonWrapperPropsType> = ({
  values,
  className,
  valueAvailable,
}): JSX.Element => {
  const handleMorphValue = ({
    latitude,
    longitude,
  }: DecimalCordType | GraduatedCordType): string => {
    if (valueAvailable(latitude) && valueAvailable(longitude)) {
      return [
        prepareCordToString(latitude, ['N', 'S']),
        prepareCordToString(longitude, ['E', 'W']),
      ].join(', ');
    }
    if (valueAvailable(latitude)) {
      return prepareCordToString(latitude, ['N', 'S']);
    }
    if (valueAvailable(longitude)) {
      return prepareCordToString(longitude, ['E', 'W']);
    }
    return '';
  };

  return (
    <CopyButton
      value={handleMorphValue(values)}
      className={className}
      size={16}
      disabled={!(valueAvailable(values?.latitude) || valueAvailable(values?.longitude))}
    />
  );
};
