import React, { FC } from 'react';
import { menuData, ObjectCardItem } from '@/components';
import { FieldType, ObjectCardPropsType } from '../object-card-panel.types';
import dayjs from 'dayjs';
import { FormFieldsTypes } from '@/constants/enums';

export const ObjectEnvironmentSection: FC<ObjectCardPropsType> = ({
  onSubmit,
  item,
  success,
  loading,
  isEditable,
}) => {
  const fields: FieldType[] = [
    {
      label: 'Классификация грунта по основным признакам',
      placeholder: 'Классификация не указана',
      name: 'env_soil_characteristics',
    },
    {
      label: 'Отбор пробы воды на ph и солёность',
      placeholder: 'Информация не указана',
      name: 'env_soil_example',
    },
    {
      label: 'Обрастание объекта',
      placeholder: 'Информация не указана',
      name: 'env_soil_foul',
    },
    {
      label: 'Температура, градусы Цельсия',
      placeholder: 'Температура не указана',
      name: 'env_water_temp',
    },
    {
      label: 'Дата замера видимости',
      placeholder: 'Дата не указана',
      name: 'env_water_visibility_date',
      preparedValue: item?.env_water_visibility_date
        ? dayjs(item.env_water_visibility_date).isValid()
          ? dayjs(item.env_water_visibility_date).format('DD.MM.YYYY')
          : item.env_water_visibility_date
        : '',
      type: FormFieldsTypes.datePicker,
    },
    {
      label: 'Течение',
      name: 'env_water_flow',
      placeholder: 'Информация не указана',
    },
    {
      label: 'Видимость (с отметкой времени замера)',
      placeholder: 'Информация не указана',
      name: 'env_water_visibility',
    },
    {
      label: 'Дата замера видимости',
      placeholder: 'Дата не указана',
      name: 'env_water_temp_date',
      preparedValue: item?.env_water_temp_date
        ? dayjs(item?.env_water_temp_date).isValid()
          ? dayjs(item.env_water_temp_date).format('DD.MM.YYYY')
          : item.env_water_temp_date
        : '',
      type: FormFieldsTypes.datePicker,
    },
  ];

  return (
    <ObjectCardItem
      title={menuData[2]?.subtitle?.[2].label}
      smallTitle={true}
      type="column"
      fields={fields}
      isEditable={isEditable}
      id={menuData[2]?.subtitle?.[2].link}
      onSubmit={() => onSubmit()}
      success={success}
      loading={loading}
    />
  );
};
