import { ButtonTypes, ModalPosition, PolygonType, PolygonTypes } from '@/constants/enums';
import { Button, CloseButton, ConfirmModal, Modal, ModalHeaderButtons } from '@/components';
import React, { FC, useRef, useState } from 'react';
import styles from './polygon-tool-panel.module.scss';
import { CSSTransition } from 'react-transition-group';
import { PolygonToolPanelPropsType, Handlers } from './polygon-tool-panel.types';
import { AccessAreaContent } from './access-area-content';
import { WaterAreaContent } from './water-area-content';
import { SectorContent } from './sector-content';
import { RegionContent } from './regions-content';

const getButtonTextByType = (type: PolygonType, id?: number) => {
  switch (type) {
    case PolygonTypes.accessArea:
      return id ? 'Изменить область видимости' : 'Создать область видимости';
    case PolygonTypes.region:
      return id ? 'Изменить район' : 'Создать район';
    case PolygonTypes.waterArea:
      return id ? 'Изменить акваторию' : 'Создать акваторию';
    case PolygonTypes.sector:
      return id ? 'Изменить сектор' : 'Создать сектор';
    default:
      return id ? 'Изменить' : 'Создать';
  }
};

export const PolygonToolPanel: FC<PolygonToolPanelPropsType> = ({
  type = PolygonTypes.sector,
  id,
  expeditionId,
}): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [title, setTitle] = useState('Полигон');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);
  const ref = useRef<Handlers>(null);

  const handleClickOnExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleClickOnCancel = () => {
    ref?.current?.handleCancel();
  };

  const handleClickOnChange = () => {
    ref?.current?.handleChange();
  };

  const handleClickFlyTo = () => {
    ref?.current?.handleFlyTo();
  };

  const handleClickPurge = () => {
    setShowDeleteModal(true);
  };

  const closeButtonClasses = [
    styles['close-button'],
    isExpanded ? styles['close-button__active'] : '',
  ].join(' ');

  return (
    <>
      <ConfirmModal
        open={showDeleteModal}
        title="Подтвердите очистку полигона"
        content={<p>Вы уверены, что хотите очистить полигон?</p>}
        submitButtonText="Очистить"
        cancelButtonText="Отменить"
        onSubmit={() => {
          ref.current?.handlePurge();
          setShowDeleteModal(false);
        }}
        onCancel={() => setShowDeleteModal(false)}
        type="danger"
      />
      <div className={styles['button-wrapper']}>
        <Button className={styles.button} onClick={handleClickOnCancel}>
          {id ? 'Отменить редактирование' : 'Отменить создание'}
        </Button>
        <Button
          type={ButtonTypes.submit}
          className={styles.submit}
          disable={isButtonsDisabled}
          onClick={handleClickOnChange}
        >
          {getButtonTextByType(type, id)}
        </Button>
      </div>
      <Modal
        width={540}
        position={ModalPosition.left}
        wrapClassName={styles.modal}
        closeIcon={
          <CloseButton
            className={closeButtonClasses}
            onClick={handleClickOnExpand}
            icon={'outline-chevron-down'}
          />
        }
        title={
          <div className={styles.title}>
            <h5>{title}</h5>
            <div className={styles['title-group']}>
              <ModalHeaderButtons
                icon="outline-geo-alt"
                text="Приблизить к полигону"
                onClick={() => handleClickFlyTo()}
              />
              <ModalHeaderButtons
                icon="outline-trash"
                text="Очистить полигон"
                onClick={() => handleClickPurge()}
              />
            </div>
          </div>
        }
      >
        <CSSTransition
          in={isExpanded}
          timeout={500}
          unmountOnExit={false}
          classNames={{
            enter: styles['body-transition-enter'],
            enterActive: styles['body-transition-enter-active'],
            exit: styles['body-transition-exit'],
            exitActive: styles['body-transition-exit-active'],
          }}
          onEntered={() => setIsActive(true)}
          onExited={() => setIsActive(false)}
        >
          <div className={isActive ? styles.body + ' ' + styles.active : styles.body}>
            {type === PolygonTypes.accessArea && (
              <AccessAreaContent
                ref={ref}
                id={id}
                setIsButtonsDisabled={(disabled) => setIsButtonsDisabled(disabled)}
                setTitle={(title) => setTitle(title)}
              />
            )}
            {type === PolygonTypes.waterArea && (
              <WaterAreaContent
                ref={ref}
                id={id}
                setIsButtonsDisabled={(disabled) => setIsButtonsDisabled(disabled)}
                setTitle={(title) => setTitle(title)}
              />
            )}
            {type === PolygonTypes.region && (
              <RegionContent
                ref={ref}
                id={id}
                setIsButtonsDisabled={(disabled) => setIsButtonsDisabled(disabled)}
                setTitle={(title) => setTitle(title)}
              />
            )}
            {type === PolygonTypes.sector && (
              <SectorContent
                expeditionId={expeditionId}
                ref={ref}
                id={id}
                setIsButtonsDisabled={(disabled) => setIsButtonsDisabled(disabled)}
                setTitle={(title) => setTitle(title)}
              />
            )}
          </div>
        </CSSTransition>
      </Modal>
    </>
  );
};
