import { CoordsSelectorField, MenuItem, TableCell } from '@/components';
import React, { FC } from 'react';
import styles from './formular-content.module.scss';
import { FormularContentPropsType } from './formular-content.types';
import { useAppSelector } from '@/state';
import { ComponentSize, Permissions } from '@/constants/enums';
import { Formik } from 'formik';
import { useGetObjectsQuery } from '@/state/api';

export const FormularContent: FC<FormularContentPropsType> = ({ item }): JSX.Element => {
  const { data: objectsData } = useGetObjectsQuery();

  const objectTypes = objectsData?.object_types || [];

  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  const itemType = objectTypes.find((type) => type.id === Number(item?.type));

  const rows = [
    {
      key: 'Районный ID',
      value: item?.id_in_district as string,
    },
    {
      key: 'Наименование',
      value: item?.name as string,
    },
    {
      key: 'Идентификатор',
      value: item?.identifier as string,
    },
    {
      key: 'Дата обнаружения',
      value: item?.date as string,
    },
    {
      key: 'Дата последней съемки',
      value: item?.updated_date as string,
    },
    {
      key: 'Экспедиции',
      hidden: !userPermissions?.includes(Permissions.expeditionsDataViewing),
      value: item?.expeditions as MenuItem[],
    },
    {
      key: 'Координаты объекта',
      hidden: !userPermissions?.includes(Permissions.specificDataViewing),
      value: item?.coordinates as string,
      component: (
        <CoordsSelectorField
          name="coordinates"
          size={ComponentSize.small}
          className={styles.field}
          readOnly={true}
        />
      ),
    },
    {
      key: 'Тип объекта',
      value: itemType?.type as string,
    },
  ];

  const cellValueStyles = [styles.cell, styles['cell-value']].join(' ');
  const cellKeyStyles = [styles.cell, styles['cell-key']].join(' ');
  const emptyText = 'Нет добавленной информации';
  const preparedRows = rows.filter((item) => !item?.hidden);

  return (
    <Formik enableReinitialize={true} initialValues={item || {}} onSubmit={() => {}}>
      {() => (
        <>
          {preparedRows.map((row, idx) => (
            <div className={styles.row} key={idx}>
              <div className={cellKeyStyles}>
                <TableCell value={row.key} className={styles['cell-content']} />
              </div>
              <div className={cellValueStyles}>
                {row?.component ?? (
                  <TableCell
                    value={row?.value}
                    className={styles['cell-content']}
                    label={emptyText}
                    maxWidth={350}
                  />
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </Formik>
  );
};
