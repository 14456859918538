import React, { FC, useEffect, useState } from 'react';
import styles from './gallery-item.module.scss';
import { GalleryItemPropsType } from './gallery-item.types';
import { Image } from 'antd';
import noImg from '@/assets/icons/no-img.svg';
import { Button, Icon, TableCellForString, TableLoader } from '@/components';
import { ComponentSize, FillTypes } from '@/constants/enums';
import { useGetMediaItemQuery } from '@/state/api';
import { getNameByPath } from '@/utils';

export const GalleryItem: FC<GalleryItemPropsType> = ({
  className,
  name,
  path,
  isEdit,
  onDelete,
  id,
  mediaType,
  size,
}) => {
  const { data, isLoading, isSuccess, isError, isFetching } = useGetMediaItemQuery({
    path: path,
    type: mediaType,
  });
  const [preparedPhoto, setPreparedPhoto] = useState('');

  const preparedStyles = [styles.wrapper, className].join(' ');
  const showDeleteBtn = !!id && isEdit && !isLoading;
  const preparedName = name || getNameByPath(path) || '';
  const requestPending = (isLoading || isFetching || !preparedPhoto) && !isError;

  useEffect(() => {
    if (isSuccess) {
      setPreparedPhoto(`data:image/png;base64,${String(data?.data)}`);
    }
  }, [isSuccess, requestPending]);

  return (
    <div className={preparedStyles} style={{ width: size }}>
      {showDeleteBtn && (
        <Button
          onClick={() => onDelete && onDelete()}
          className={styles.btn}
          size={ComponentSize.small}
          fillType={FillTypes.tertiary}
        >
          <Icon size={12} icon="outline-trash" />
        </Button>
      )}
      {requestPending ? (
        <div style={{ width: size, height: size }}>
          <TableLoader className={styles.loader} />
        </div>
      ) : (
        <Image
          width={size}
          height={size}
          fallback={noImg}
          src={preparedPhoto}
          className={styles.item}
          alt="Не удалось загрузить фото"
        />
      )}
      <TableCellForString value={preparedName} maxWidth={100} className={styles.cell} />
    </div>
  );
};
