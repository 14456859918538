import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useHistoryBackstack, useMap } from '@/context';
import { ObjectSelectContent } from './object-select-content';
import { LatLngExpression, Polygon } from 'leaflet';
import { openErrorNotify, openInfoNotify } from '@/utils';
import { setSelectedObjectsIds, toggleSelectedObjects } from '@/state/slice';
import { useDispatch } from 'react-redux';
import { difference, isEqual } from 'lodash';
import { ExpeditionStatuses } from '@/constants/enums';
import {
  useAddExpeditionObjectsWorkMutation,
  useGetExpeditionSummaryQuery,
  useGetObjectsQuery,
  useGetWaterAreasQuery,
} from '@/state/api';
import { useAppSelector } from '@/state';
import {
  LINK_TO_EXPEDITION_OBJECT_CREATE_STEP_PAGE,
  LINK_TO_EXPEDITION_OBJECT_STEP_PAGE,
  LINK_TO_EXPEDITION_PANEL,
  MAP,
} from '@/constants';

export const ObjectSelectPage: FC = () => {
  const { map } = useMap();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { backstack, setBackstack } = useHistoryBackstack();

  const [mapRendered, setMapRendered] = useState(false);
  const [initialBackstack] = useState(backstack);

  const expeditionId = Number(params?.expeditionId);

  const { data: expeditionData } = useGetExpeditionSummaryQuery({ id: expeditionId });
  const {
    data: objectsData,
    isLoading,
    isSuccess,
    isError,
    isFetching,
  } = useGetObjectsQuery({ expId: expeditionId });

  const [addObjectsWork, { isError: addError, isSuccess: addSuccess, reset: addReset }] =
    useAddExpeditionObjectsWorkMutation();

  const activeItems = useAppSelector((state) => state.objects.selectedObjects);
  const { data: waterAreas } = useGetWaterAreasQuery();

  const actualData = expeditionData?.expedition_report || expeditionData?.expedition_card;
  const selectedObjects = actualData?.objects_works.map((item) => item.object_id);
  const preparedWaterAreas = waterAreas?.filter((item) => item.id === actualData?.water_area_id);
  const objectsPreparedData = objectsData?.objects;
  const isChanged = !isEqual(selectedObjects, activeItems);
  const [previousSelected, setPreviousSelected] = useState<number[]>([]);

  const requestEnded = !isLoading && isSuccess && !isError && !isFetching;
  const mapAvailable = map !== null && map?.getZoom() !== undefined;

  const handleToggleAdd = (activeItemId?: number[]) => {
    if (activeItemId) {
      const preparedData = {
        objects_id: activeItemId,
        expedition_id: expeditionId,
      };
      addObjectsWork(preparedData);
    }
  };

  const handleToggleCreateObject = () => {
    navigate(LINK_TO_EXPEDITION_OBJECT_CREATE_STEP_PAGE(expeditionId));
  };

  const handleClose = () => {
    const closeAvailable =
      pathname === LINK_TO_EXPEDITION_OBJECT_CREATE_STEP_PAGE(expeditionId) ||
      pathname === LINK_TO_EXPEDITION_OBJECT_STEP_PAGE(expeditionId);
    if (closeAvailable) {
      navigate(
        initialBackstack[initialBackstack.length - 1] || LINK_TO_EXPEDITION_PANEL(expeditionId),
      );
    }
  };

  const handleFlyTo = (item?: LatLngExpression[][][]) => {
    if (item) {
      map?.whenReady(() => {
        map?.flyToBounds(new Polygon(item).getBounds(), { maxZoom: 10 });
      });
      setMapRendered(false);
    }
  };

  const handleClick = (id: number) => {
    dispatch(toggleSelectedObjects(id));
  };

  useEffect(() => {
    const idUnavailable =
      expeditionId === null || expeditionId === undefined || String(expeditionId) === 'undefined';
    if (idUnavailable) {
      handleClose();
    }
    return () => {
      dispatch(setSelectedObjectsIds());
    };
  }, []);

  useEffect(() => {
    if (
      actualData?.status === ExpeditionStatuses.plan ||
      actualData?.status === ExpeditionStatuses.approved
    ) {
      navigate(MAP);
    }
  }, [actualData]);

  useEffect(() => {
    if (selectedObjects && selectedObjects?.length > 0) {
      const newItems = difference(selectedObjects, previousSelected);
      if (newItems.length) {
        dispatch(setSelectedObjectsIds([...activeItems, ...newItems]));
      } else if (!activeItems.length) {
        dispatch(setSelectedObjectsIds(selectedObjects));
      }
      setPreviousSelected(selectedObjects);
    }
  }, [selectedObjects && selectedObjects.length]);

  useEffect(() => {
    const actionAvailable =
      mapAvailable && requestEnded && !!preparedWaterAreas?.[0] && mapRendered;

    if (actionAvailable) {
      handleFlyTo(preparedWaterAreas?.[0]?.polygon);
    }
  }, [waterAreas]);

  useEffect(() => {
    return () => {
      setBackstack(initialBackstack);
    };
  }, [backstack]);

  useEffect(() => {
    if (addSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        'Добавление информации о поисковых работах на объектах исследования успешно выполнено',
      );
      setTimeout(() => handleClose(), 1500);
      addReset();
    }
    if (addError) {
      openErrorNotify(
        'Произошла ошибка',
        'При добавлении информации о поисковых работах на объектах исследования произошла ошибка',
      );
      addReset();
    }
  }, [addSuccess, addError]);

  return (
    <ObjectSelectContent
      isChanged={isChanged}
      expeditionId={expeditionId}
      onCreate={() => handleToggleCreateObject()}
      onAdd={() => handleToggleAdd(activeItems)}
      onCancel={() => handleClose()}
      onAreasRender={() => setMapRendered(true)}
      onMarkerClick={(id) => handleClick(id)}
      data={objectsPreparedData}
      areasData={preparedWaterAreas}
    />
  );
};
