import React, { FC } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { handleDateSort, handleSortNumber, handleSortString } from '@/utils';
import {
  defaultDataPickerProps,
  defaultSearchProps,
  Icon,
  PanelTableButton,
  Table,
  TableCell,
  TableFilter,
} from '@/components';
import { Polygon } from 'geojson';
import { Link, useNavigate } from 'react-router-dom';
import { BATHYMETRY_LAYER, CordSystemData, LINK_TO_EXPEDITION_PANEL, MAP } from '@/constants';
import { Bathymetric } from '@/types';
import { BathymetricDefaultTablePropsType } from './bathymetric-table.types';
import { useMap } from '@/context';
import { LatLngExpression, Polygon as LP } from 'leaflet';
import { useDispatch } from 'react-redux';
import { setSelectedLayer } from '@/state/slice';
import {
  LinkElement,
  MenuItemLinkElement,
} from '@/components/table-components/table-cell/table-cell.types';
import { ColumnType } from 'antd/es/table';
import { useAppSelector } from '@/state';
import { Permissions } from '@/constants/enums';

export const BathymetricDefaultTable: FC<BathymetricDefaultTablePropsType> = ({
  data,
  loading,
  error,
  isEditable,
  onClick3DBtn,
  expeditionsData,
  onClickDeleteBtn,
  onClickEditBtn,
}): JSX.Element => {
  const handleFilterExpedition = (value: string | number | boolean, record: Bathymetric) => {
    const preparedExpedition = expeditionsData
      .find((exp) => exp.id === Number(record.expedition))
      ?.name.toLowerCase();

    return preparedExpedition ? preparedExpedition.includes(value.toString().toLowerCase()) : false;
  };

  const { map } = useMap();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFlyToBathymetry = (value: string) => {
    const parsedPolygon: LatLngExpression[][][] = [
      [(JSON.parse(value) as Polygon).coordinates[0].map((cord) => [cord[0], cord[1]])],
    ];
    const polygon = new LP(parsedPolygon);
    navigate(MAP);
    map?.flyToBounds(polygon.getBounds(), { maxZoom: 18 });
    dispatch(setSelectedLayer(BATHYMETRY_LAYER));
  };

  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  const filterColumnsByPermissions = (column: ColumnType<Bathymetric>) => {
    if (column.key === 'editable' && !isEditable) {
      return false;
    }
    if (
      column.key === 'expedition' &&
      !userPermissions?.includes(Permissions.expeditionsDataViewing)
    ) {
      return false;
    }
    return true;
  };

  const columns: ColumnsType<Bathymetric> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 62,
      sorter: (first, second) => handleSortNumber(first.id, second.id),
    },
    {
      title: <Icon size={12} icon="outline-geo-alt" />,
      width: 40,
      dataIndex: 'bounds',
      fixed: 'left',
      align: 'center',
      render: (value: string) => (
        <PanelTableButton
          onClick={() => value && handleFlyToBathymetry(value)}
          tooltipTitle="Приблизиться к батиметрии"
          tooltipPlacement="bottom"
          icon="outline-geo-alt"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-3D" />,
      dataIndex: 'id',
      fixed: 'left',
      width: 40,
      align: 'center',
      render: (value: number) => {
        const bathymetry = data.find((item) => item.id === value);
        const isDisabled = bathymetry?.status !== 1 || !bathymetry.has_3d;

        return (
          <PanelTableButton
            onClick={() => onClick3DBtn(value)}
            tooltipTitle="Посмотреть в 3D"
            tooltipPlacement="bottom"
            icon="outline-3D"
            disabled={isDisabled}
            showTooltip={true}
            size={12}
          />
        );
      },
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      dataIndex: 'id',
      fixed: 'left',
      width: 40,
      align: 'center',
      key: 'editable',
      render: (value: number, row) => (
        <PanelTableButton
          onClick={() => onClickEditBtn(row)}
          tooltipTitle="Редактировать батиметрию"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      dataIndex: 'id',
      fixed: 'left',
      key: 'editable',
      align: 'center',
      width: 40,
      render: (value: number, row) => (
        <PanelTableButton
          onClick={() => onClickDeleteBtn(row)}
          tooltipTitle="Удалить батиметрию"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: 110,
      fixed: 'left',
      className: 'text-center',
      render: (value: number) => {
        switch (value) {
          case 1:
            return (
              <PanelTableButton
                showTooltip={true}
                tooltipTitle="Обработан"
                icon="outline-status-confirmed"
                size={12}
                tooltipPlacement="bottom"
              />
            );
          case 2:
            return (
              <PanelTableButton
                showTooltip={true}
                tooltipTitle="В процессе обработки"
                icon="outline-status-planned"
                size={12}
                tooltipPlacement="bottom"
              />
            );
          case 3:
            return (
              <PanelTableButton
                showTooltip={true}
                tooltipTitle=""
                icon="outline-status-error"
                size={12}
                tooltipPlacement="bottom"
              />
            );
        }
      },
      sorter: (first, second, sortOrder) =>
        handleSortNumber(first?.status, second?.status, sortOrder),
      filterDropdown: ({ filters, clearFilters, confirm, setSelectedKeys, selectedKeys }) => (
        <TableFilter
          filters={filters}
          onFilter={confirm}
          onClear={clearFilters}
          selectedKeys={selectedKeys}
          onChange={setSelectedKeys}
        />
      ),
      onFilter: (value, record) => record.status === value,
      filters: [
        {
          text: 'Обработан',
          value: 1,
        },
        {
          text: 'В обработке',
          value: 2,
        },
      ],
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      width: 230,
      sorter: (first, second, sortOrder) => handleSortString(first?.name, second?.name, sortOrder),
      render: (value: string) => {
        return <TableCell value={value} maxWidth={230} />;
      },
      ...defaultSearchProps('name'),
    },
    {
      title: 'Экспедиция',
      dataIndex: 'expedition',
      key: 'expedition',
      width: 180,
      render: (value: string | null) => {
        const link: MenuItemLinkElement = {
          key: value ?? '',
          name: value ?? '',
          link: (
            <Link to={LINK_TO_EXPEDITION_PANEL(Number(value))}>
              {expeditionsData.find((exp) => exp.id === Number(value))?.name}
            </Link>
          ),
        };
        if ((link.link as LinkElement).props.children) {
          return <TableCell value={link} maxWidth={180} />;
        }
        return <TableCell value={''} maxWidth={180} />;
      },
      sorter: (first, second, sortOrder) =>
        handleSortString(
          expeditionsData.find((exp) => exp.id === Number(first.expedition))?.name,
          expeditionsData.find((exp) => exp.id === Number(second.expedition))?.name,
          sortOrder,
        ),
      ...defaultSearchProps('expedition', handleFilterExpedition),
    },
    {
      title: 'Дата съемки',
      dataIndex: 'date_shoot',
      key: 'date_shoot',
      width: 172,
      render: (value: string) => {
        return <TableCell value={value} />;
      },
      sorter: (first, second, sortOrder) =>
        handleDateSort(first.date_shoot, second.date_shoot, sortOrder),
      ...defaultDataPickerProps<Bathymetric>('date_shoot'),
    },
    {
      title: 'Система координат',
      dataIndex: 'cs',
      key: 'cs',
      width: 287,
      render: (value: string) => {
        const cordSystemItem: { secondLabel: string; firstLabel: string; value: string } =
          CordSystemData.filter((item) => item.value === value)[0];
        const preparedValue = cordSystemItem
          ? cordSystemItem.secondLabel + ' / ' + cordSystemItem.firstLabel
          : '';

        return <TableCell value={preparedValue} maxWidth={287} />;
      },
      sorter: (first, second, sortOrder) => handleSortNumber(first?.cs, second?.cs, sortOrder),
      onFilter: (value, record) => record.cs === value,
      filterDropdown: ({ filters, clearFilters, confirm, setSelectedKeys, selectedKeys }) => (
        <TableFilter
          filters={filters}
          onFilter={confirm}
          onClear={clearFilters}
          selectedKeys={selectedKeys}
          onChange={setSelectedKeys}
        />
      ),
      filters: CordSystemData.map(
        ({
          secondLabel,
          firstLabel,
          value,
        }: {
          secondLabel: string;
          firstLabel: string;
          value: string;
        }) => ({
          text: secondLabel + ' / ' + firstLabel,
          value,
        }),
      ),
    },
    {
      title: 'Путь',
      dataIndex: 'path',
      key: 'path',
      width: 219,
      render: (value: string) => <TableCell value={value} maxWidth={219} />,
      sorter: (first, second, sortOrder) => handleSortNumber(first.path, second.path, sortOrder),
      ...defaultSearchProps('path'),
    },
    {
      title: 'Размер, Мб',
      dataIndex: 'size',
      key: 'size',
      width: 120,
      render: (value: string) => {
        const preparedValue = value ? parseFloat(value).toFixed(1) : '';

        return <TableCell value={preparedValue} maxWidth={142} />;
      },
      sorter: (first, second, sortOrder) => handleSortNumber(first?.size, second?.size, sortOrder),
    },
  ];

  const preparedColumns = columns.filter(filterColumnsByPermissions);

  return (
    <Table
      dataSource={data}
      columns={preparedColumns}
      loading={loading}
      error={error}
      withSelection={false}
    />
  );
};
