import React from 'react';
import { BathymetricTab, GalsTab, ObjectsTab, RegionsTab } from './layers-tabs';
import { LayerTablesTabsTypes } from '@/constants/enums';

export const LayersTabs = [
  {
    label: 'Батиметрия',
    key: LayerTablesTabsTypes.bathymetric,
    children: <BathymetricTab />,
  },
  {
    label: 'Объекты исследования',
    key: LayerTablesTabsTypes.objectsResearch,
    children: <ObjectsTab />,
  },
  {
    label: 'Районы',
    key: LayerTablesTabsTypes.regions,
    children: <RegionsTab />,
  },
  {
    label: 'Галсы',
    key: LayerTablesTabsTypes.galses,
    children: <GalsTab />,
  },
];
