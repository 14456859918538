import { Button } from '@/components/common';
import { ButtonTypes, Colors, ComponentSize, FillTypes } from '@/constants/enums';
import styles from './otp-validation.module.scss';
import { ResendCodeButtonProps } from './otp-validation.types';
import { FC } from 'react';
import dayjs from 'dayjs';
import { useTimer } from '@/utils';

export const ResendCodeButton: FC<ResendCodeButtonProps> = ({
  otpCreatedAt,
  validity,
  loading,
  onResendOtp,
}) => {
  const { minutes, seconds, isExpired } = useTimer({
    to: dayjs(otpCreatedAt ?? null).isValid()
      ? dayjs(otpCreatedAt, { utc: true })
          .add(validity ?? 0, 'minutes')
          .toISOString()
      : dayjs().toISOString(),
  });

  return (
    <Button
      className={styles['resend-btn']}
      minWidth={312}
      color={Colors.black}
      fillType={FillTypes.noFill}
      size={ComponentSize.medium}
      disable={!isExpired}
      loading={!!loading}
      type={ButtonTypes.button}
      onClick={onResendOtp}
    >
      {isExpired
        ? 'Отправить снова'
        : `Повторная отправка доступна через ${minutes < 10 ? `0${minutes}` : minutes}:${
            seconds < 10 ? `0${seconds}` : seconds
          }`}
    </Button>
  );
};
