import React, { FC } from 'react';
import {
  CoordsSelectorField,
  DatePickerField,
  DropdownField,
  InputField,
  TableCell,
} from '@/components';
import { ComponentSize, FormFieldsTypes, InputTypes } from '@/constants/enums';
import { ObjectCardFieldPropsType } from '../../object-card-panel.types';

export const ObjectCardField: FC<ObjectCardFieldPropsType> = ({
  isEdit,
  type = FormFieldsTypes.input,
  placeholder,
  name = 'name',
  styles = {},
  data = [],
  loading,
  error,
  preparedValue,
}) => {
  if (!isEdit) {
    switch (type) {
      case FormFieldsTypes.multiselect:
      case FormFieldsTypes.select:
        return (
          <TableCell
            value={preparedValue}
            label={placeholder}
            className={styles?.cell}
            maxWidth={800}
          />
        );

      case FormFieldsTypes.textArea:
        return (
          <InputField
            name={name}
            type={InputTypes.textArea}
            placeholder={placeholder}
            className={styles?.textArea}
            size={ComponentSize.small}
            maxLength={2000}
            autoSize={true}
            readOnly={true}
          />
        );
      default:
        if (preparedValue) {
          if (!!React.isValidElement(preparedValue)) {
            return <div className={styles?.cell}>{preparedValue}</div>;
          }

          return <span className={styles?.cell}>{preparedValue.toString()}</span>;
        }
        return (
          <InputField
            name={name}
            placeholder={placeholder}
            className={styles?.input}
            size={ComponentSize.small}
            readOnly={true}
          />
        );
    }
  }

  switch (type) {
    case FormFieldsTypes.coordsSelect:
      return (
        <CoordsSelectorField name={name} className={styles?.[type]} size={ComponentSize.small} />
      );
    case FormFieldsTypes.datePicker:
      return (
        <DatePickerField
          isDefaultPopupContainer={false}
          name={name}
          placeholder={placeholder}
          className={styles?.[type]}
        />
      );
    case FormFieldsTypes.select:
      return (
        <DropdownField
          loading={loading}
          name={name}
          data={data}
          size={ComponentSize.small}
          className={styles?.[type]}
          error={error}
          isDefaultPopupContainer={false}
        />
      );
    case FormFieldsTypes.multiselect:
      return (
        <DropdownField
          loading={loading}
          name={name}
          data={data}
          size={ComponentSize.small}
          className={styles?.[type]}
          error={error}
          isDefaultPopupContainer={false}
          mode="multiple"
        />
      );
    case FormFieldsTypes.textArea:
      return (
        <InputField
          name={name}
          type={InputTypes.textArea}
          placeholder={placeholder}
          className={styles?.textArea}
          size={ComponentSize.small}
          autoSize={true}
          maxLength={2000}
        />
      );
    default:
      return (
        <InputField
          name={name}
          placeholder={placeholder}
          className={styles?.input}
          size={ComponentSize.small}
        />
      );
  }
};
