import React from 'react';
import styles from './object.module.scss';
import { ITooltipObjectMarker } from './';
import dayjs from 'dayjs';
import { useAppSelector } from '@/state';
import { Permissions } from '@/constants/enums';

export const ObjectMarker = ({ item }: ITooltipObjectMarker) => {
  const expeditionText: string = item?.expeditions
    ?.map((exp) => {
      return exp.name.toString();
    })
    .join(', ');

  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  return (
    <>
      <div className={styles.title}>
        <p className={styles.bold}>Информация об объекте</p>
      </div>
      <div className={styles.body}>
        {item.id_in_district && (
          <p className={styles.text}>
            <span className={styles.other}>Районный ID:</span>
            {item.id_in_district}
          </p>
        )}
        {item.name && (
          <p className={styles.text}>
            <span className={styles.other}>Наименование:</span> {item.name}
          </p>
        )}
        {item.identifier && (
          <p className={styles.text}>
            <span className={styles.other}>Идентификатор:</span>
            {item.identifier}
          </p>
        )}
        {expeditionText && userPermissions?.includes(Permissions.expeditionsDataViewing) ? (
          <p className={styles.text}>
            <span className={styles.other}>Экспедиции:</span>
            {expeditionText}
          </p>
        ) : null}
        {item.date && (
          <p className={styles.text}>
            <span className={styles.other}>Дата обнаружения:</span>
            {dayjs(item.date).isValid() ? dayjs(item.date).format('DD.MM.YYYY') : item.date}
          </p>
        )}
      </div>
    </>
  );
};
