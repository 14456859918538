import React, { FC, useEffect, useRef, useState } from 'react';
import { SectorSelectContent } from './sector-select-content';
import {
  EXPEDITION_STEP_PAGE,
  LINK_TO_EXPEDITION_PANEL,
  LINK_TO_EXPEDITION_SECTOR_CREATE_STEP_PAGE,
  LINK_TO_EXPEDITION_SECTOR_STEP_PAGE,
} from '@/constants';
import { FeatureGroup as FG, LatLngExpression, Polygon } from 'leaflet';
import {
  useAddExpeditionSectorsWorkMutation,
  useGetExpeditionSummaryQuery,
  useGetSectorsQuery,
  useGetWaterAreasQuery,
} from '@/state/api';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router';
import { useHistoryBackstack, useMap } from '@/context';
import { useAppSelector } from '@/state';
import { setSelectedSectorsIds } from '@/state/slice';
import { useDispatch } from 'react-redux';
import { openErrorNotify, openInfoNotify } from '@/utils';
import { difference, isEqual } from 'lodash';
import { IWaterArea } from '@/types';

export const SectorSelectPage: FC = ({}) => {
  const { map } = useMap();
  const params = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { backstack } = useHistoryBackstack();
  const layerRef = useRef<FG>(null);
  const waterAreaRef = useRef<FG>(null);

  const [areasRendered, setAreasRendered] = useState(false);
  const [initialActionsAvailable, setInitialActionsAvailable] = useState(false);
  const [previousSelected, setPreviousSelected] = useState<number[]>([]);

  const expeditionId = Number(params?.expeditionId);

  const { data, isLoading, isSuccess, isError, isFetching } = useGetSectorsQuery({
    expId: expeditionId,
  });
  const { data: expeditionData } = useGetExpeditionSummaryQuery({ id: expeditionId });

  const [addSectorWork, { isError: addError, isSuccess: addSuccess, reset: addReset }] =
    useAddExpeditionSectorsWorkMutation();

  const { data: waterAreas } = useGetWaterAreasQuery();
  const activeSectors = useAppSelector((state) => state.sectors.selectedSectors);

  const actualData = expeditionData?.expedition_report || expeditionData?.expedition_card;
  const preparedWaterAreas = waterAreas?.filter((item) => item.id === actualData?.water_area_id);
  const selectedSectors = actualData?.sectors_works.map(({ sector_id }) => sector_id || 0);

  const requestEnded = !isLoading && isSuccess && !isError && !isFetching;
  const mapAvailable = map !== null && map?.getZoom() !== undefined;

  const ignoredRoutes = [
    EXPEDITION_STEP_PAGE,
    LINK_TO_EXPEDITION_SECTOR_STEP_PAGE(expeditionId),
    LINK_TO_EXPEDITION_SECTOR_CREATE_STEP_PAGE(expeditionId),
  ];

  const handleAdd = (items: number[]) => {
    const preparedData = {
      sectors_id: items,
      expedition_id: expeditionId,
    };
    addSectorWork(preparedData);
  };

  const handleCreate = () => {
    navigate(LINK_TO_EXPEDITION_SECTOR_CREATE_STEP_PAGE(expeditionId));
  };

  const handleClose = () => {
    const filteredBackstack = backstack.filter(
      (path) => ignoredRoutes.filter((route) => matchPath(route, path)).length === 0,
    );

    const closeAvailable =
      pathname === LINK_TO_EXPEDITION_SECTOR_CREATE_STEP_PAGE(expeditionId) ||
      pathname === LINK_TO_EXPEDITION_SECTOR_STEP_PAGE(expeditionId);
    if (closeAvailable) {
      navigate(
        filteredBackstack[filteredBackstack.length - 1] || LINK_TO_EXPEDITION_PANEL(expeditionId),
      );
    }
  };

  const handleFlyTo = (item?: LatLngExpression[][][]) => {
    if (item) {
      map?.whenReady(() => {
        map?.flyToBounds(new Polygon(item).getBounds(), { maxZoom: 10 });
      });
      setInitialActionsAvailable(false);
      setAreasRendered(false);
    }
  };

  useEffect(() => {
    const waterAreaAvailable = !!preparedWaterAreas?.[0];

    const actionAvailable = mapAvailable && requestEnded && waterAreaAvailable && areasRendered;

    setInitialActionsAvailable(actionAvailable);
  }, [mapAvailable, requestEnded, preparedWaterAreas, areasRendered]);

  useEffect(() => {
    //TODO fix
    if (initialActionsAvailable) {
      handleFlyTo(preparedWaterAreas?.[0]?.polygon);
      setInitialActionsAvailable(false);
    }
  }, [initialActionsAvailable]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedSectorsIds([]));
    };
  }, []);

  useEffect(() => {
    layerRef?.current?.bringToFront();
    waterAreaRef?.current?.bringToBack();
  }, [selectedSectors && selectedSectors.length, layerRef.current, waterAreaRef.current]);

  useEffect(() => {
    if (addSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        'Добавление информации о поисковых работах на секторах успешно выполнено',
      );
      setTimeout(() => handleClose(), 1500);
      addReset();
    }
    if (addError) {
      openErrorNotify(
        'Произошла ошибка',
        'При добавлении информации о поисковых работах на секторах произошла ошибка',
      );
      addReset();
    }
  }, [addSuccess, addError]);

  useEffect(() => {
    if (selectedSectors && selectedSectors?.length > 0) {
      const newItems = difference(selectedSectors, previousSelected);
      if (newItems.length) {
        dispatch(setSelectedSectorsIds([...activeSectors, ...newItems]));
      } else if (!activeSectors.length) {
        dispatch(setSelectedSectorsIds(selectedSectors));
      }
      setPreviousSelected(selectedSectors);
    }
  }, [selectedSectors && selectedSectors.length]);

  return (
    <SectorSelectContent
      expedition_id={expeditionId}
      onCancel={() => handleClose()}
      onAdd={() => handleAdd(activeSectors)}
      onCreate={() => handleCreate()}
      onAreasRender={() => setAreasRendered(true)}
      isChanged={!isEqual(selectedSectors, activeSectors)}
      areasData={preparedWaterAreas as IWaterArea[] | []}
      layerRef={layerRef}
      waterAreaRef={waterAreaRef}
      data={data}
    />
  );
};
