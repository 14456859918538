import React, { FC } from 'react';
import { Children } from '@/types/default-types';
import styles from '../layout.module.scss';

export const RightSidebar: FC<{ children?: Children; className?: string }> = ({
  children,
  className,
}) => {
  const customStyles = [styles['right-sidebar'], className ?? ''].join(' ');

  return <div className={customStyles}>{children}</div>;
};
