import { TableCell, Table } from '@/components';
import { ExpeditionStatuses } from '@/constants/enums';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { BaseSectionPropsType, ExpeditionCardSection } from '../index';
import styles from '../section.module.scss';

export const HistoryLogSection: FC<BaseSectionPropsType> = ({ item, label }) => {
  const data = item?.history_logs;

  const preparedRows = [
    {
      section: 'history_description',
      value: data?.find((item) => item.section === 'history_description')?.log_date || '',
      name: 'Историческая справка',
      id: 1,
      hidden: item?.status === ExpeditionStatuses.plan,
    },
    {
      section: 'goals',
      value: data?.find((item) => item.section === 'goals')?.log_date || '',
      name: 'Цели',
      id: 2,
      hidden: item?.status === ExpeditionStatuses.plan,
    },
    {
      section: 'tasks',
      value: data?.find((item) => item.section === 'tasks')?.log_date || '',
      name: 'Задачи',
      id: 3,
      hidden: item?.status === ExpeditionStatuses.plan,
    },
    {
      section: 'sectors_works',
      value: data?.find((item) => item.section === 'sectors_works')?.log_date || '',
      name: 'Работы на секторах',
      id: 4,
      hidden: item?.status === ExpeditionStatuses.plan,
    },
    {
      section: 'objects_works',
      value: data?.find((item) => item.section === 'objects_works')?.log_date || '',
      name: 'Работы на объектах',
      id: 5,
      hidden: item?.status === ExpeditionStatuses.plan,
    },
    {
      section: 'members',
      value: data?.find((item) => item.section === 'members')?.log_date || '',
      name: 'Список участников',
      id: 6,
      hidden: item?.status === ExpeditionStatuses.plan,
    },
    {
      section: 'tools',
      value: data?.find((item) => item.section === 'tools')?.log_date || '',
      name: 'Список МТО',
      id: 7,
      hidden: item?.status === ExpeditionStatuses.plan,
    },
    {
      section: 'ships_bases',
      value: data?.find((item) => item.section === 'ships_bases')?.log_date || '',
      name: 'Базы судов',
      id: 8,
      hidden: item?.status === ExpeditionStatuses.plan,
    },
    {
      section: 'conclusions',
      value: data?.find((item) => item.section === 'conclusions')?.log_date || '',
      name: 'Выводы',
      hidden: item?.status === ExpeditionStatuses.plan || item?.status === ExpeditionStatuses.card,
      id: 9,
    },
    {
      section: 'proposals',
      value: data?.find((item) => item.section === 'proposals')?.log_date || '',
      name: 'Предложения',
      hidden: item?.status === ExpeditionStatuses.plan || item?.status === ExpeditionStatuses.card,
      id: 10,
    },
    {
      section: 'dives',
      value: data?.find((item) => item.section === 'dives')?.log_date || '',
      name: 'Погружения',
      hidden: item?.status === ExpeditionStatuses.plan || item?.status === ExpeditionStatuses.card,
      id: 11,
    },
    {
      section: 'tools_dives',
      value: data?.find((item) => item.section === 'tools_dives')?.log_date || '',
      name: 'Погружения техники',
      hidden: item?.status === ExpeditionStatuses.plan || item?.status === ExpeditionStatuses.card,
      id: 12,
    },
    {
      section: 'attachments',
      value: data?.find((item) => item.section === 'attachments')?.log_date || '',
      name: 'Вложения',
      hidden: item?.status === ExpeditionStatuses.plan,
      id: 13,
    },
  ];

  const preparedData = preparedRows.filter((item) => !item.hidden);

  const columns: ColumnsType<typeof preparedData[0]> = [
    {
      dataIndex: 'name',
      title: 'Раздел',
      align: 'left',
      width: 200,
      render: (value: string) => <TableCell value={value} />,
    },
    {
      title: 'Пользователь',
      dataIndex: 'section',
      key: 'section',
      width: 200,
      render: (value: string) => (
        <TableCell
          value={item?.history_logs.find((item) => item.section === value)?.user?.email || ''}
        />
      ),
    },
    {
      title: 'Дата',
      width: 200,
      dataIndex: 'value',
      align: 'left',
      render: (value: string) => (
        <TableCell
          maxWidth={200}
          value={
            value
              ? dayjs(value, { utc: true }).isValid()
                ? dayjs(value, { utc: true }).format()
                : value
              : ''
          }
          showTime={true}
        />
      ),
    },
  ];

  return (
    <ExpeditionCardSection label={label}>
      <Table
        withSelection={false}
        dataSource={preparedData}
        columns={columns}
        className={styles.table}
        withPagination={false}
        scroll={{ x: 1225, y: 600 }}
      />
    </ExpeditionCardSection>
  );
};
