export const getTextWidth = (value: string, fontSize = 12): number => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = `${fontSize}px Montserrat`;
    const width = context.measureText(value).width;

    //equal letter spacing 0.2px
    const letterSpacing = 0.2;

    return width + width * letterSpacing + 26;
  }
  return 0;
};
