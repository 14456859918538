import React, { FC } from 'react';
import { Button } from 'antd';
import { CloseButtonPropsTypes } from './close-button.types';
import { Icon } from '@/components';
import styles from './close-button.module.scss';

export const CloseButton: FC<CloseButtonPropsTypes> = ({
  onClick,
  icon = 'outline-close',
  className,
  iconSize = 18,
}) => {
  const customClasses = [className, styles.wrapper].join(' ');

  return (
    <Button
      type="text"
      shape="circle"
      className={customClasses}
      onClick={() => onClick && onClick()}
    >
      <Icon size={iconSize} icon={icon} />
    </Button>
  );
};
