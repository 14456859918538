import L, { DivIcon, MarkerClusterGroup as MarkerClusterGroupType } from 'leaflet';
import cultureIcon from '@/assets/icons/culture-object.svg';
import undiscoveredIcon from '@/assets/icons/undiscovered-object.svg';
import dangerIcon from '@/assets/icons/danger-object.svg';
import militaryIcon from '@/assets/icons/military-object.svg';
import technicalIcon from '@/assets/icons/technical-object.svg';
import shipBaseIcon from '@/assets/icons/ship-base.svg';
import mapGreen from '@/assets/icons/marker-map-green.svg';
import mapRed from '@/assets/icons/marker-map-red.svg';
import mapDefault from '@/assets/icons/marker-map-default.svg';

const getIconByType = (type: number): string => {
  switch (type) {
    case 1:
      return undiscoveredIcon;
    case 2:
      return technicalIcon;
    case 3:
      return dangerIcon;
    case 4:
      return cultureIcon;
    case 5:
      return militaryIcon;
    default:
      return undiscoveredIcon;
  }
};

export const getObjectIconByType = (
  type: number,
  active: boolean,
  styles: CSSModuleClasses,
  expedition_id?: number,
) => {
  return L.divIcon({
    className: styles['marker-sphere'],
    html: `
        <div 
            class="${styles[type !== 1 ? 'marker-circle-base' : 'marker-circle-base-undiscovered']}
            ${
              expedition_id && active
                ? styles?.['expedition-selected']
                : active
                ? styles?.active
                : ''
            }"
        >
          <div>
              <img src='${getIconByType(type)}' alt="не удалось загрузить изображение"/>
          </div>
        </div>`,
    iconSize: type === 1 ? [20, 20] : [40, 40],
    iconAnchor: type === 1 ? [10, 10] : [20, 20],
    tooltipAnchor: type === 1 ? [10, 15] : [18, 20],
  });
};

export const createClusterCustomIcon = (
  cluster: MarkerClusterGroupType,
  styles: CSSModuleClasses,
) => {
  const count = cluster.getChildCount();

  const color = count < 10 ? styles.green : count < 50 ? styles.yellow : styles.red;

  return L.divIcon({
    html: `<div>${cluster.getChildCount()}</div>`,
    className: `${styles['marker-cluster-custom']} ${color}`,
    iconSize: L.point(40, 40, true),
  });
};

export const getShipsBaseIcon = (active: boolean, styles: CSSModuleClasses) => {
  return L.divIcon({
    className: styles['marker-sphere'],
    html: `
        <div class="${styles['marker-circle-base']} ${active ? styles.active : ''}">
          <div>
            <img src='${String(shipBaseIcon)}' alt='не удалось загрузить изображение'/>
          </div>
        </div>`,
    iconSize: [40, 40],
    iconAnchor: [20, 20],
    tooltipAnchor: [18, 20],
  });
};

export const getVertexMarker = (label: string, className: string) => {
  return L.divIcon({
    className: className,
    html: ` <span>${label}</span>
        `,
    iconSize: [20, 20],
    iconAnchor: [20, 20],
  });
};

export const getPointMarker = (
  className?: string,
  number?: number,
  icon = 'default',
  hideText = false,
): DivIcon => {
  let src = '';

  switch (icon) {
    case 'default': {
      src = mapDefault;
      break;
    }
    case 'first': {
      src = mapGreen;
      break;
    }
    case 'last': {
      src = mapRed;
      break;
    }
    default: {
      break;
    }
  }

  let text = `<img alt="icon marker" src="${src}"/>`;

  if (number && !hideText) {
    text += `<p style="white-space: nowrap; margin:0">Точка ${number}</p>`;
  }

  return L.divIcon({
    className: className,
    html: text,
  });
};

export const polylineOptions = {
  color: 'red',
  weight: 2,
  opacity: 0.6,
  dashArray: '6',
};
