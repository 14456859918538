import React, { FC } from 'react';
import styles from './panel-table-button.module.scss';
import { PanelTableButtonPropsType } from './panel-table-button.types';
import { Icon, Spinner } from '@/components';
import { Popover } from 'antd';

export const PanelTableButton: FC<PanelTableButtonPropsType> = ({
  size,
  icon,
  onClick,
  showTooltip,
  tooltipTitle,
  tooltipPlacement,
  loading = false,
  disabled = false,
  loaderClassName = '',
}) => {
  const customStyles = [
    styles.wrapper,
    onClick && !disabled ? styles.action : '',
    disabled ? styles.disabled : '',
  ].join(' ');
  if (showTooltip && !disabled) {
    return (
      <Popover
        overlayClassName={styles.dropdown}
        content={tooltipTitle}
        placement={tooltipPlacement ?? 'top'}
      >
        <div className={customStyles} onClick={() => !disabled && onClick && onClick()}>
          {loading ? <Spinner className={loaderClassName} /> : <Icon size={size} icon={icon} />}
        </div>
      </Popover>
    );
  }

  return (
    <div className={customStyles} onClick={() => !disabled && onClick && onClick()}>
      {loading ? <Spinner className={loaderClassName} /> : <Icon size={size} icon={icon} />}
    </div>
  );
};
