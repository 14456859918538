import React, { FC } from 'react';
import { menuData, ObjectCardItem } from '@/components';
import { ObjectCardPropsType } from '../object-card-panel.types';

export const ObjectFixationSSSObjectSection: FC<ObjectCardPropsType> = ({
  onSubmit,
  success,
  loading,
  isEditable,
}) => {
  const fields = [
    {
      label: 'Координаты объекта в десятичном виде (Широта)',
      placeholder: 'Координаты не указаны',
      name: 'lat',
    },
    {
      label: 'Координаты объекта в десятичном виде (Долгота)',
      placeholder: 'Координаты не указаны',
      name: 'lng',
    },
    {
      label: 'Координаты объекта в градуированном виде (Широта)',
      placeholder: 'Координаты не указаны',
      name: 'latGrad',
    },
    {
      label: 'Координаты объекта в градуированном виде (Долгота)',
      placeholder: 'Координаты не указаны',
      name: 'lngGrad',
    },
    {
      label: 'Координаты носовой части (Широта)',
      placeholder: 'Координаты не указаны',
      name: 'attach_bow_lat',
    },
    {
      label: 'Координаты носовой части (Долгота)',
      placeholder: 'Координаты не указаны',
      name: 'attach_bow_lng',
    },
    {
      label: 'Координаты района мидель шпангоута (Широта)',
      placeholder: 'Координаты не указаны',
      name: 'attach_frame_lat',
    },
    {
      label: 'Координаты района мидель шпангоута (Долгота)',
      placeholder: 'Координаты не указаны',
      name: 'attach_frame_lng',
    },
    {
      label: 'Координаты кормовой части (Широта)',
      placeholder: 'Координаты не указаны',
      name: 'attach_stern_lat',
    },
    {
      label: 'Координаты кормовой части (Долгота)',
      placeholder: 'Координаты не указаны',
      name: 'attach_stern_lng',
    },
  ];

  return (
    <ObjectCardItem
      title="Фиксация объекта ГБО, МЛЭ, подготовка планшета для нанесения отметок"
      type="column"
      fields={fields}
      id={menuData[2].link}
      onSubmit={() => onSubmit()}
      isEditable={isEditable}
      success={success}
      loading={loading}
    />
  );
};
