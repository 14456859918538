import { api } from '../api';
import { DefaultResponse, ObjectsGroup } from '@/types';

export const objectGroupsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getObjectsGroupsList: builder.query<{ success: boolean; data: Array<ObjectsGroup> }, void>({
      query() {
        return {
          url: '/objects_group/list',
          method: 'GET',
          credentials: 'include',
        };
      },
      providesTags: ['ObjectsGroups'],
    }),
    createObjectsGroup: builder.mutation<
      DefaultResponse,
      {
        name: string;
        objects_ids: Array<number>;
      }
    >({
      query(body) {
        return {
          url: '/objects_group',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ObjectsGroups'],
    }),
    editObjectsGroup: builder.mutation<
      DefaultResponse,
      {
        name: string;
        objects_ids: Array<number>;
      }
    >({
      query(body) {
        return {
          url: '/objects_group',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ObjectsGroups'],
    }),
    deleteObjectsGroup: builder.mutation<
      DefaultResponse,
      {
        objects_groups_ids: number;
      }
    >({
      query(body) {
        return {
          url: '/objects_group',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ObjectsGroups'],
    }),
  }),
});

export const {
  useGetObjectsGroupsListQuery,
  useCreateObjectsGroupMutation,
  useDeleteObjectsGroupMutation,
  useEditObjectsGroupMutation,
} = objectGroupsApi;
