import React, { FC, useEffect, useState } from 'react';
import { CoordsSystemDropdownField, DropdownFieldDataType, FieldType } from '@/components';
import {
  useCreateBathymetricMutation,
  useDeleteBathymetricMutation,
  useEditBathymetricMutation,
  useGetBathymetricDataQuery,
  useGetExpeditionListQuery,
} from '@/state/api';
import { useNavigate } from 'react-router';
import { Bathymetric, BathymetricFileType, EditBathymetric, NewBathymetric } from '@/types';
import { InputTypes, Permissions, Targets3DTypes } from '@/constants/enums';
import * as Yup from 'yup';
import { CordSystemData, LINK_TO_VIEW_3D } from '@/constants';
import { BathymetricTabContent } from './bathymetric-tab-content';
import { openErrorNotify, openInfoNotify } from '@/utils';
import { useAppSelector } from '@/state';
//TODO: improve component arch, logic
export const BathymetricTab: FC = (): JSX.Element => {
  const navigate = useNavigate();

  const [showAddFilesContent, setShowAddFilesContent] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddsModal, setShowAddModal] = useState(false);
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);
  const isEditable = userPermissions?.includes(Permissions.mapDataManagment);

  const [selectedItem, setSelectedItem] = useState<Bathymetric>();
  const [selectedRows, setSelectedRows] = useState<BathymetricFileType[]>([]);

  const { data, isLoading, isError } = useGetBathymetricDataQuery(undefined, {
    pollingInterval: 1000 * 50,
  });

  const {
    data: expeditionsData,
    isLoading: expeditionLoading,
    isError: expeditionError,
  } = useGetExpeditionListQuery();

  const [
    addBathymetric,
    { isLoading: addLoading, isSuccess: addSuccess, isError: addError, reset: resetCreate },
  ] = useCreateBathymetricMutation();
  const [
    editBathymetric,
    { isLoading: editLoading, isSuccess: editSuccess, isError: editError, reset: resetEdit },
  ] = useEditBathymetricMutation();
  const [
    deleteBathymetric,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      reset: resetDelete,
    },
  ] = useDeleteBathymetricMutation();

  const preparedExpeditions =
    expeditionsData?.expeditions.filter((item) => item.parent_expedition_id) || [];

  const preparedData = data?.projects?.filter((bath) => bath.status !== 3) || [];
  const preparedFilesData = data?.list || [];
  const preparedOptions: DropdownFieldDataType[] = preparedExpeditions.map((item) => ({
    label: item?.name || '',
    value: item.id.toString(),
  }));

  const addFields: FieldType[] = [
    {
      label: 'Наименование',
      name: 'name',
      placeholder: 'Введите наименование',
      type: InputTypes.text,
    },
    {
      label: 'Экспедиция',
      name: 'expedition',
      type: 'dropdown',
      required: true,
      data: preparedOptions,
      loading: expeditionLoading,
      error: expeditionError,
    },
    {
      component: <CoordsSystemDropdownField name="cs" label="Система координат" required={true} />,
    },
  ];

  const editableFields: FieldType[] = [
    {
      label: 'Наименование',
      name: 'name',
      placeholder: 'Введите наименование',
      type: InputTypes.text,
    },
    {
      label: 'Экспедиция',
      name: 'expedition',
      type: 'dropdown',
      required: true,
      data: preparedOptions,
      loading: expeditionLoading,
      error: expeditionError,
    },
  ];

  const initialValues: NewBathymetric = {
    cs: CordSystemData[0].value,
    name: '',
    expedition: null,
    path: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().nullable(true),
    expedition: Yup.string().required('Пожалуйста, выберите экспедицию').nullable(),
    cs: Yup.string().required('Пожалуйста, укажите систему координат'),
  });

  const handleGetTotalSize = (selectedRows: BathymetricFileType[]): string => {
    let size = 0;
    selectedRows.forEach((item) => {
      size = size + parseFloat(item?.size) || 0;
    });
    return size.toFixed(1);
  };

  const handleOpen3D = (id: number): void => {
    navigate(LINK_TO_VIEW_3D(Targets3DTypes.bathymetry, id));
  };

  const handleToggleEdit = (row: Bathymetric): void => {
    setSelectedItem(row);
    setShowEditModal((prevState) => !prevState);
  };

  const handleToggleDelete = (row: Bathymetric): void => {
    setSelectedItem(row);
    setShowDeleteModal((prevState) => !prevState);
  };

  const handleAdd = ({ array }: { array: NewBathymetric[] }): void => {
    addBathymetric(array);
  };

  const handleEdit = (value: EditBathymetric): void => {
    const preparedData = {
      id: Number(value.id),
      name: value?.name || '',
      expedition: Number(value.expedition),
    };
    editBathymetric(preparedData);
  };

  const handleDelete = (id: number): void => {
    deleteBathymetric({
      id: id.toString(),
    });
  };

  const handleToggleAddModal = (visible: boolean) => {
    resetCreate();
    setShowAddModal(visible);
  };

  const handleToggleEditModal = (visible: boolean) => {
    resetEdit();
    setShowEditModal(visible);
  };

  const handleToggleDeleteModal = (visible: boolean) => {
    resetDelete();
    setShowDeleteModal(visible);
  };

  const handleToggleShowContent = () => {
    setShowAddFilesContent((prev) => !prev);
    setSelectedRows([]);
  };

  useEffect(() => {
    if (addSuccess) {
      openInfoNotify('Изменения сохранены', 'Добавление успешно');
      setSelectedRows([]);
      setShowAddModal(false);
      setShowAddFilesContent(false);
    }
    if (addError) {
      openErrorNotify('Произошла ошибка', 'При добавлении произошла ошибка');
    }
  }, [addSuccess, addError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify('Изменения сохранены', 'Редактирование успешно');
    }
    if (editError) {
      openErrorNotify('Произошла ошибка', 'При редактировании произошла ошибка');
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify('Изменения сохранены', 'Удаление успешно');
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении произошла ошибка');
    }
  }, [deleteSuccess, deleteError]);

  return (
    <BathymetricTabContent
      addFields={addFields}
      editableFields={editableFields}
      bathData={preparedData}
      filesData={preparedFilesData}
      loading={isLoading}
      error={isError}
      expeditionsData={preparedExpeditions}
      expeditionLoading={expeditionLoading}
      expeditionError={expeditionError}
      selectedItem={selectedItem as Bathymetric}
      initialValues={initialValues}
      validationSchema={validationSchema}
      currentFilesLength={selectedRows.length}
      currentTotalSize={handleGetTotalSize(selectedRows)}
      onClick3DBtn={handleOpen3D}
      onClickDeleteBtn={handleToggleDelete}
      onClickEditBtn={handleToggleEdit}
      showAddFilesContent={showAddFilesContent}
      onToggleAddFiles={handleToggleShowContent}
      onSelectRow={setSelectedRows}
      selectedRows={selectedRows}
      showAddModals={showAddsModal}
      setShowAddModal={handleToggleAddModal}
      addLoading={addLoading}
      addSuccess={addSuccess}
      onAdd={handleAdd}
      showEditModal={showEditModal}
      setShowEditsModal={handleToggleEditModal}
      editLoading={editLoading}
      editSuccess={editSuccess}
      onEdit={handleEdit}
      isEditable={isEditable}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={handleToggleDeleteModal}
      deleteLoading={deleteLoading}
      deleteSuccess={deleteSuccess}
      onDelete={handleDelete}
    />
  );
};
