import { resetAction } from '@/state/actions';
import { SectorsStateType } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: SectorsStateType = {
  isOpenSectorTooltip: true,
  selectedSectors: [],
};

const sectorsSlice = createSlice({
  name: 'sectors',
  initialState,
  reducers: {
    setIsOpenSectorTooltip: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenSectorTooltip = payload;
    },
    setSelectedSectorsIds: (state, { payload }: PayloadAction<Array<number>>) => {
      state.selectedSectors = payload;
    },
    toggleSectorInSelectedIds: (state, { payload }: PayloadAction<number>) => {
      const valueAvailable = state.selectedSectors?.find((item) => item === payload);
      if (valueAvailable) {
        state.selectedSectors = state.selectedSectors.filter((item) => item !== payload);
      } else {
        state.selectedSectors = [...state.selectedSectors, payload];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAction, () => {
      return initialState;
    });
  },
});

export const { setIsOpenSectorTooltip, setSelectedSectorsIds, toggleSectorInSelectedIds } =
  sectorsSlice.actions;
export const { reducer: sectorsReducer } = sectorsSlice;
