import React, { FC } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { AccessArea } from '@/types';
import { handleSortNumber, handleSortString } from '@/utils';
import { defaultSearchProps, Icon, PanelTableButton, Table, TableCell } from '@/components';
import { AccessesAreasTablePropsType } from './accesses-areas-table.types';
import { Polygon } from 'leaflet';
import { useMap } from '@/context';
import { useNavigate } from 'react-router';
import { MAP } from '@/constants';

export const AccessesAreasTable: FC<AccessesAreasTablePropsType> = ({
  data,
  onDelete,
  onEdit,
  loading,
  error,
}): JSX.Element => {
  const { map } = useMap();
  const navigate = useNavigate();

  const handleFlyToAccessArea = (value: number) => {
    const accessArea = data.find((item) => item.id === value);
    if (accessArea) {
      const polygon = new Polygon(accessArea.restricted_area);
      map?.flyToBounds(polygon.getBounds(), { maxZoom: accessArea.max_zoom });
      navigate(MAP);
    }
  };

  const columns: ColumnsType<AccessArea> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      fixed: 'left',
      width: 62,
      sorter: (first, second) => handleSortNumber(first.id, second.id),
    },
    {
      title: <Icon size={12} icon="outline-geo-alt" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      fixed: 'left',
      render: (value: number) => (
        <PanelTableButton
          onClick={() => handleFlyToAccessArea(value)}
          tooltipTitle="Приблизиться к области видимости"
          tooltipPlacement="bottom"
          icon="outline-geo-alt"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      dataIndex: 'id',
      fixed: 'left',
      align: 'center',
      width: 40,
      render: (value: number, record) => (
        <PanelTableButton
          onClick={() => onEdit(record)}
          tooltipTitle="Редактировать область видимости"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      dataIndex: 'id',
      fixed: 'left',
      align: 'center',
      width: 40,
      render: (value: number, record) => (
        <PanelTableButton
          onClick={() => onDelete(record)}
          tooltipTitle="Удалить область видимости"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (value: string) => <TableCell value={value} maxWidth={300} />,
      sorter: (first, second) => handleSortString(first?.name, second?.name),
      ...defaultSearchProps('name'),
    },
    {
      title: 'Максимальное увеличение',
      dataIndex: 'max_zoom',
      key: 'max_zoom',
      align: 'left',
      width: 250,
      render: (value: string) => <TableCell value={value} maxWidth={100} />,
      sorter: (first, second) => handleSortNumber(first?.max_zoom, second?.max_zoom),
    },
  ];

  return (
    <Table
      withSelection={false}
      dataSource={data}
      columns={columns}
      loading={loading}
      scroll={{ x: 800, y: 500 }}
      error={error}
    />
  );
};
