import { AccessArea, EditAccessArea, NewAccessArea, AccessAreaEntity } from '@/types';
import { api } from '../api';
import { LatLngExpression } from 'leaflet';
import { parsePolygonToWKT } from '@/utils';

const transformAccessesAreas = (res: {
  data: AccessAreaEntity[];
  success: true;
}): { data: AccessArea[]; success: true } => {
  const transformedData = res.data.map((area) => {
    const parsedPolygon: LatLngExpression[][][] = [
      [area.restricted_area.coordinates[0].map((cord) => [cord[0], cord[1]])],
    ];

    return {
      ...area,
      restricted_area: parsedPolygon,
    };
  });

  return { data: transformedData, success: true };
};

export const accessesAreasApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccessesAreasList: builder.query<{ success: true; data: Array<AccessArea> }, void>({
      query() {
        return {
          url: '/access_area/list',
          method: 'GET',
          credentials: 'include',
        };
      },
      transformResponse: transformAccessesAreas,
      providesTags: ['AccessesAreas'],
    }),
    createAccessArea: builder.mutation<{ success: 'true' }, NewAccessArea>({
      query(body) {
        const polygon = parsePolygonToWKT(body.restricted_area);

        return {
          url: '/access_area',
          method: 'POST',
          body: { ...body, restricted_area: polygon },
          credentials: 'include',
        };
      },
      invalidatesTags: ['AccessesAreas'],
    }),
    editAccessArea: builder.mutation<{ success: true }, EditAccessArea>({
      query(body) {
        const polygon = parsePolygonToWKT(body.restricted_area);

        return {
          url: '/access_area',
          method: 'PUT',
          body: { ...body, restricted_area: polygon },
          credentials: 'include',
        };
      },
      invalidatesTags: ['AccessesAreas'],
    }),
    deleteAccessArea: builder.mutation<
      { success: true },
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/access_area',
          method: 'DELETE',
          body: { id: body.id },
          credentials: 'include',
        };
      },
      invalidatesTags: ['AccessesAreas'],
    }),
  }),
});

export const {
  useCreateAccessAreaMutation,
  useDeleteAccessAreaMutation,
  useEditAccessAreaMutation,
  useGetAccessesAreasListQuery,
} = accessesAreasApi;
