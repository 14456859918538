import React, { FC } from 'react';
import styles from '../../../default-tab.module.scss';
import {
  BlackTooltip,
  Button,
  ConfirmModal,
  GalsAddFileTable,
  GalsDefaultTable,
  Icon,
  Modal,
  PanelTabButton,
} from '@/components';
import { ComponentSize, ModalPosition, PanelTabButtonTypes } from '@/constants/enums';
import { GalsTableInfoPropsType, GalsTablePropsType } from './gals-tab.types';

const GalsTabContentInfo: FC<GalsTableInfoPropsType> = ({
  loading,
  error,
  currentTotalSize,
  currentFilesLength,
  maxTotalSize = 200,
  maxFilesLength = 5,
  onToggleAddFiles,
}) => {
  return (
    <div className={styles['header-bath']}>
      <div className={styles['title-bath']}>
        <div className={styles['text-bath']}>
          <p>
            Файлов выбрано{' '}
            <strong>
              {currentFilesLength}/{maxFilesLength}
            </strong>
          </p>
          <p
            className={
              parseFloat(currentTotalSize) > maxTotalSize ? styles['title-second-warning'] : ''
            }
          >
            Объем выбранных файлов <strong>{currentTotalSize} Мб</strong> (рекомендовано{' '}
            {maxTotalSize} Мб)
          </p>
        </div>
        <PanelTabButton
          disabled={error && loading}
          type={PanelTabButtonTypes.roundSecondary}
          size={ComponentSize.small}
          onClick={() => onToggleAddFiles((prevState) => !prevState)}
        >
          <div>
            <span>Закрыть </span>
            <Icon size={16} icon="outline-arrow-right" />
          </div>
        </PanelTabButton>
      </div>
    </div>
  );
};

export const GalsTabContent: FC<GalsTablePropsType> = ({
  showAddFilesContent,
  onToggleAddFiles,
  onSelectRow,
  selectedRows,
  onClickDeleteBtn,
  isEditable,
  currentTotalSize,
  currentFilesLength,
  maxTotalSize = 200,
  maxFilesLength = 5,

  data,
  loading,
  error,
  files,
  filesLoading,
  filesError,
  selectedItem,

  showDeleteModal,
  setShowDeleteModal,
  deleteLoading,
  deleteSuccess,
  onDelete,

  showAddModals,
  setShowAddModal,
  addLoading,
  addSuccess,
  onAdd,
}) => {
  return (
    <div className={styles.wrapper}>
      {!loading && !error && !showAddFilesContent && (
        <div className={styles.header}>
          <BlackTooltip
            title={`Перечень галсов логов съёмки. Возможно приблизить, 
            редактировать, удалить или добавить новый из Excel файла с 
            координатами перемещения. Отображается на карте в слое Галсы.`}
            placement={'bottomLeft'}
          >
            <PanelTabButton
              className={styles['button-panel-help']}
              onClick={() => {}}
              type={PanelTabButtonTypes.icon}
              size={ComponentSize.small}
            >
              <Icon icon="outline-question-circle" size={14} />
            </PanelTabButton>
          </BlackTooltip>
          {isEditable ? (
            <PanelTabButton
              disabled={error && loading}
              type={PanelTabButtonTypes.roundSecondary}
              size={ComponentSize.small}
              onClick={() => onToggleAddFiles((prevState) => !prevState)}
            >
              Создать галс
            </PanelTabButton>
          ) : null}
        </div>
      )}
      {showAddModals && isEditable ? (
        <Modal
          title={'Добавление файлов логов'}
          mask={true}
          centered={true}
          onClose={() => setShowAddModal(false)}
          position={ModalPosition.center}
        >
          <div className={styles['add-content']}>
            {selectedRows.map((item) => (
              <p>{item.path}</p>
            ))}
            <Button loading={addLoading} disable={addLoading || addSuccess} onClick={() => onAdd()}>
              Добавить
            </Button>
          </div>
        </Modal>
      ) : null}

      {showAddFilesContent && isEditable ? (
        <GalsAddFileTable
          onAddFiles={() => setShowAddModal(true)}
          selectedRows={selectedRows}
          galsData={data}
          galsLoading={loading}
          galsError={error}
          className={styles['additional-table']}
          fileData={files}
          galsClassName={styles['additional-table-additional']}
          fileLoading={filesLoading}
          filesError={filesError}
          fileClassName={styles['additional-table-file']}
          onSelectRow={onSelectRow}
          info={
            <GalsTabContentInfo
              error={error}
              loading={loading}
              currentFilesLength={currentFilesLength}
              currentTotalSize={currentTotalSize}
              maxFilesLength={maxFilesLength}
              maxTotalSize={maxTotalSize}
              onToggleAddFiles={onToggleAddFiles}
            />
          }
        />
      ) : (
        <GalsDefaultTable
          isEditable={isEditable}
          onClickDeleteBtn={onClickDeleteBtn}
          loading={loading}
          error={error}
          data={data}
        />
      )}
      <ConfirmModal
        open={showDeleteModal}
        title="Подтвердите удаление галса"
        content={
          <p>
            Вы уверены, что хотите удалить&nbsp;
            {selectedItem?.file_path ? (
              <>
                галс лога дна <strong>{selectedItem?.file_path}</strong>
              </>
            ) : (
              'выбранный галс лога дна?'
            )}
            ?
          </p>
        }
        submitButtonText="Удалить галс"
        cancelButtonText="отменить"
        onSubmit={() => onDelete(selectedItem?.id)}
        onCancel={() => setShowDeleteModal((prevState) => !prevState)}
        loading={deleteLoading}
        success={deleteSuccess}
        type="danger"
      />
    </div>
  );
};
