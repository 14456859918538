import { FC } from 'react';
import { PinInputProps } from './pin-input.types';
import styles from './pin-input.module.scss';
import ReactPinInput from 'react-pin-input';

export const PinInput: FC<PinInputProps> = ({
  placeholder,
  disabled,
  autoSelect = false,
  type = 'numeric',
  length = 4,
  secret = false,
  secretDelay = 200,
  onChange,
}) => {
  return (
    <div className={styles.wrapper}>
      <ReactPinInput
        placeholder={placeholder}
        disabled={disabled}
        autoSelect={autoSelect}
        secret={secret}
        type={type}
        onComplete={(value) => onChange(value)}
        secretDelay={secretDelay}
        length={length}
        onChange={(value, index) => onChange(value, index)}
      />
    </div>
  );
};
