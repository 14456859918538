import { Layout } from '@/components/layout';
import React, { FC, useEffect, useState } from 'react';
import {
  BathymetricLayer,
  CoordinateConverterPanel,
  GalsesLayer,
  LayersPanel,
  LiveCoordinatePanel,
  Logo,
  MapBaseLayer,
  MoLayer,
  ObjectsLayer,
  OldBathymetryLayer,
  OoptLayer,
  PolygonToolPanel,
  RegionsLayer,
  RoutePanel,
  SectorsLayer,
  ShipsBasesLayer,
  SidebarButton,
  TopomapsLayer,
  UtmBorders,
  WaterAreasLayer,
  ZoomControl,
} from '@/components';
import { PolygonToolPageTools } from './polygon-tool-page-tools';
import { useNavigate, useParams } from 'react-router';
import { Permissions, PolygonType, PolygonTypes } from '@/constants/enums';
import { useAppSelector } from '@/state';
import { MAP } from '@/constants';

const getHintByType = (type: PolygonType = PolygonTypes.sector) => {
  switch (type) {
    case PolygonTypes.accessArea:
      return 'Область видимости будет формироваться из крайних координат точек полигона. В результате будет прямоугольник.';
    default:
      return 'Инструмент позволяет создать полигон при помощи манипуляций на карте.';
  }
};

export const PolygonToolPage: FC = (): JSX.Element => {
  const { id, type, expeditionId } = useParams();
  const [isRoutePanelOpen, setIsRoutePanelOpen] = useState(false);
  const [isLayersPanelOpen, setIsLayersPanelOpen] = useState(false);
  const [isConvertPanelOpen, setIsConvertPanelOpen] = useState(false);
  const navigate = useNavigate();
  const key = type as unknown as PolygonTypes;
  const hint = getHintByType(key);
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  const toggleConvertPanel = () => {
    if (isConvertPanelOpen) {
      setIsConvertPanelOpen(false);
    } else {
      setIsConvertPanelOpen(true);
      setIsLayersPanelOpen(false);
      setIsRoutePanelOpen(false);
    }
  };

  const toggleRoutePanel = () => {
    if (isRoutePanelOpen) {
      setIsRoutePanelOpen(false);
    } else {
      setIsRoutePanelOpen(true);
      setIsLayersPanelOpen(false);
      setIsConvertPanelOpen(false);
    }
  };

  const toggleLayersPanel = () => {
    if (isLayersPanelOpen) {
      setIsLayersPanelOpen(false);
    } else {
      setIsLayersPanelOpen(true);
      setIsRoutePanelOpen(false);
      setIsConvertPanelOpen(false);
    }
  };

  useEffect(() => {
    if (
      type === PolygonTypes.accessArea &&
      userPermissions &&
      !userPermissions.includes(Permissions.rolesManagment)
    ) {
      navigate(MAP);
    }
    if (
      (type === PolygonTypes.sector || type === PolygonTypes.waterArea) &&
      userPermissions &&
      !userPermissions.includes(Permissions.expeditionsDataManagment)
    ) {
      navigate(MAP);
    }
    if (
      type === PolygonTypes.region &&
      userPermissions &&
      !userPermissions.includes(Permissions.mapDataManagment)
    ) {
      navigate(MAP);
    }
  }, [userPermissions, type]);

  return (
    <>
      <Layout>
        <Layout.Sidebar.RightSidebar>
          <SidebarButton
            title="Слои"
            icon="outline-layers"
            onClick={() => toggleLayersPanel()}
            isActive={isLayersPanelOpen}
          />
          <PolygonToolPageTools
            isConvertPanelOpen={isConvertPanelOpen}
            isRoutePanelOpen={isRoutePanelOpen}
            toggleConvertPanel={() => toggleConvertPanel()}
            toggleRoutePanel={() => toggleRoutePanel()}
          />
          <SidebarButton title={hint} icon="outline-unexplored--2" onClick={() => {}} />
        </Layout.Sidebar.RightSidebar>
        <Layout.Content>
          <MapBaseLayer>
            <PolygonToolPanel type={key} id={Number(id)} expeditionId={Number(expeditionId)} />
            <BathymetricLayer />
            <MoLayer />
            <OoptLayer />
            <GalsesLayer />
            <OldBathymetryLayer />
            <TopomapsLayer />
            <UtmBorders />
            <ObjectsLayer />
            <RegionsLayer />
            <ShipsBasesLayer />
            <WaterAreasLayer />
            <SectorsLayer />
          </MapBaseLayer>
        </Layout.Content>
        <Layout.Footer>
          <Logo />
          <ZoomControl />
          <LiveCoordinatePanel />
        </Layout.Footer>
      </Layout>
      {isLayersPanelOpen && <LayersPanel onClose={() => setIsLayersPanelOpen(false)} />}
      {isRoutePanelOpen && <RoutePanel onClose={() => setIsRoutePanelOpen(false)} />}
      {isConvertPanelOpen && (
        <CoordinateConverterPanel onClose={() => setIsConvertPanelOpen(false)} />
      )}
    </>
  );
};
