import { resetAction } from '@/state/actions';
import { authApi, usersApi } from '@/state/api';
import { AuthStateType, DefaultResponse, Token, UserProfile } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: AuthStateType = {
  accessToken: null,
  refreshToken: null,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, { payload }: PayloadAction<string | null>) => {
      state.accessToken = payload;
    },
    setRefreshToken: (state, { payload }: PayloadAction<string | null>) => {
      state.refreshToken = payload;
    },
    clearTokens: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAction, () => {
      return initialState;
    });
    builder.addMatcher(
      authApi.endpoints.loginUser.matchFulfilled,
      (state, { payload }: PayloadAction<Token>) => {
        state.accessToken = payload.access_token;
        state.refreshToken = payload.refresh_token;
      },
    );
    builder.addMatcher(
      authApi.endpoints.getUserInfo.matchFulfilled,
      (state, { payload }: PayloadAction<UserProfile>) => {
        state.user = payload;
      },
    );
    builder.addMatcher(
      usersApi.endpoints.editUserProfile.matchFulfilled,
      (state, { payload }: PayloadAction<DefaultResponse & { user: UserProfile }>) => {
        state.user = payload.user;
      },
    );
  },
});

export const { clearTokens, setAccessToken, setRefreshToken } = authSlice.actions;
export const { reducer: authReducer } = authSlice;
