import React, { FC, useEffect, useState } from 'react';
import styles from './profile-panel.module.scss';
import {
  Button,
  ConfirmModal,
  FormHelper,
  InputField,
  Modal,
  ModalHeaderButtons,
} from '@/components';
import { useNavigate } from 'react-router';
import { LINK_TO_RESET_PASSWORD, LOGIN, MAP } from '@/constants';
import { useAppSelector } from '@/state';
import { ComponentSize, ModalPosition, Permissions } from '@/constants/enums';
import { Formik, FormikValues } from 'formik';
import {
  useDeleteUserMutation,
  useLogoutUserMutation,
  useEditUserProfileMutation,
  api,
} from '@/state/api';
import { DefaultResponse, User } from '@/types';
import { openErrorNotify, openInfoNotify } from '@/utils';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { resetAction } from '@/state/actions';

export const ProfilePanel: FC = () => {
  const [showConfirmResetPassword, setShowConfirmResetPassword] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmLeave, setShowConfirmLeave] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [logoutRequest, { isLoading: logoutLoading }] = useLogoutUserMutation();
  const [deleteUser, { isLoading: deleteLoading, isSuccess: deleteSuccess, isError: deleteError }] =
    useDeleteUserMutation();
  const [updateUser, { isLoading: updateLoading, isError: updateError, isSuccess: updateSuccess }] =
    useEditUserProfileMutation();

  const userData = useAppSelector((state) => state.auth.user);
  const userPermissions = userData?.rules ?? undefined;

  const modalClasses = [styles.modal].join(' ');

  const initialValue = {
    uid: userData?.id,
    name: userData?.name,
    middlename: userData?.middlename,
    family: userData?.family,
    email: userData?.email,
  };

  const handleSubmit = (values: FormikValues) => {
    const preparedValue = {
      ...values,
      uid: values?.id as number,
    };
    updateUser(preparedValue as User);
  };

  const handleDeleteUser = (uid: number) => {
    deleteUser({ uid });
  };

  const handleLogout = () => {
    logoutRequest().then((res: unknown) => {
      const response = res as DefaultResponse;
      const success = response?.data?.success;
      if (success) {
        dispatch(resetAction());
        dispatch(api.util.resetApiState());
        navigate(LOGIN);
      } else {
        openErrorNotify('Произошла ошибка', 'При выходе из системы произошла ошибка');
      }
    });
  };

  const handleResetPassword = () => {
    logoutRequest().then((res: unknown) => {
      const response = res as DefaultResponse;
      const success = response?.data?.success;
      if (success) {
        setTimeout(() => navigate(LINK_TO_RESET_PASSWORD), 300);
        dispatch(resetAction());
        dispatch(api.util.resetApiState());
      } else {
        openErrorNotify('Произошла ошибка', 'При выходе из системы произошла ошибка');
      }
    });
  };

  useEffect(() => {
    if (updateSuccess) {
      setFormIsDirty(false);
      openInfoNotify('Изменения сохранены', 'Редактирование успешно');
    }
    if (updateError) {
      openErrorNotify('Произошла ошибка', 'При редактировании произошла ошибка');
    }
  }, [updateSuccess, updateError]);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(resetAction());
      dispatch(api.util.resetApiState());
      navigate(LOGIN);
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении произошла ошибка');
    }
  }, [deleteSuccess, deleteError]);

  return (
    <Modal
      onClose={() => (formIsDirty ? setShowConfirmLeave(true) : navigate(MAP))}
      width={380}
      position={ModalPosition.left}
      wrapClassName={modalClasses}
      title={
        <div className={styles.title}>
          <h5>Профиль</h5>
          <div className={styles['title-group']}>
            {userPermissions?.includes(Permissions.rolesManagment) ? (
              <>
                <ModalHeaderButtons
                  icon="outline-pencil"
                  text="Редактировать"
                  onClick={() => setIsEdit((prevState) => !prevState)}
                  loading={updateLoading}
                />
                <ModalHeaderButtons
                  icon="outline-trash"
                  text="Удалить"
                  onClick={() => setShowConfirmDelete(true)}
                  loading={deleteLoading}
                />
              </>
            ) : null}
            <ModalHeaderButtons
              icon="outline-log-out"
              text="Выйти из системы"
              onClick={() => handleLogout()}
              loading={logoutLoading}
            />
          </div>
        </div>
      }
    >
      <Formik initialValues={initialValue} onSubmit={handleSubmit} enableReinitialize={true}>
        {({ isValid, dirty, handleSubmit }) => (
          <div className={styles.form}>
            <InputField
              name="family"
              label="Фамилия"
              size={ComponentSize.large}
              className={styles.field}
              readOnly={!isEdit}
            />
            <InputField
              name="name"
              label="Имя"
              size={ComponentSize.large}
              className={styles.field}
              readOnly={!isEdit}
            />
            <InputField
              name="middlename"
              label="Отчество"
              size={ComponentSize.large}
              className={styles.field}
              readOnly={!isEdit}
            />
            <InputField
              name="email"
              label="E-mail"
              size={ComponentSize.large}
              className={styles.field}
              readOnly={!isEdit}
            />
            <CSSTransition
              in={isEdit}
              classNames={{
                enter: styles['button-transition-enter'],
                enterActive: styles['button-transition-enter-active'],
                exit: styles['button-transition-exit'],
                exitActive: styles['button-transition-exit-active'],
              }}
              unmountOnExit={true}
              timeout={400}
            >
              <Button
                disable={!isValid || !dirty || !formIsDirty}
                className={styles.button}
                size={ComponentSize.medium}
                onClick={() => handleSubmit()}
                loading={updateLoading}
              >
                сохранить
              </Button>
            </CSSTransition>
            <FormHelper setFormIsDirty={setFormIsDirty} listenForm={true} />
          </div>
        )}
      </Formik>
      <ConfirmModal
        open={showConfirmDelete}
        title="Подтвердите удаление профиля"
        content="Вы уверены, что хотите удалить свой профиль?"
        submitButtonText="Удалить профиль"
        cancelButtonText="Отменить"
        onSubmit={() => handleDeleteUser(userData?.id as number)}
        onCancel={() => setShowConfirmDelete(false)}
        loading={deleteLoading}
        success={deleteSuccess}
        type="danger"
      />
      <ConfirmModal
        open={showConfirmLeave}
        onSubmit={() => navigate(MAP)}
        onCancel={() => setShowConfirmLeave(false)}
      />
      <ConfirmModal
        open={showConfirmResetPassword}
        onSubmit={() => handleResetPassword()}
        onCancel={() => setShowConfirmResetPassword(false)}
      />
    </Modal>
  );
};
