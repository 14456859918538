import { api } from '@/state/api';
import { DefaultRequestType } from '@/types';

export const expeditionMediaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addExpeditionMedia: builder.mutation<DefaultRequestType, { expedition_id: number; file: File }>(
      {
        query(body) {
          const data = new FormData();
          data.append('expedition_id', body?.expedition_id?.toString());
          data.append('file', body?.file);

          return {
            url: '/expedition/media',
            method: 'POST',
            body: data,
            credentials: 'include',
          };
        },
        invalidatesTags: ['ExpeditionMedia'],
      },
    ),
    deleteExpeditionMedia: builder.mutation<{ success: boolean; message: string }, { id: number }>({
      query(body) {
        return {
          url: '/expedition/media',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionMedia'],
    }),
  }),
});

export const { useAddExpeditionMediaMutation, useDeleteExpeditionMediaMutation } =
  expeditionMediaApi;
