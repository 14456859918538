import React, { useEffect, useRef } from 'react';
import { ITooltip } from './';
import styles from './map-tooltip.module.scss';
import { Tooltip } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { Region, Sector, WaterArea } from './polygon';
import { Gals, Log, ObjectMarker, ShipsBase } from './marker';
import { Tooltip as TooltipTypes } from 'leaflet';
import { setIsMetaGalsOpenTooltip, setIsMetaLogOpenTooltip } from '@/state/slice';

export const MapTooltip: React.FC<ITooltip> = (props): JSX.Element | null => {
  const dispatch = useDispatch();
  const isNonActive = props?.isSecondary as boolean;
  const type = props.typeOfGeometry as string;
  const ref = useRef<TooltipTypes>(null);

  const metaDataLogEventsHandlers = () => {
    dispatch(setIsMetaLogOpenTooltip(false));
  };

  const metaDataGalsEventsHandlers = () => {
    dispatch(setIsMetaGalsOpenTooltip(false));
  };

  useEffect(() => {
    if (ref?.current) {
      if (!isNonActive) {
        switch (type) {
          case 'region':
          case 'sector':
          case 'water-area':
            ref?.current?.getElement()?.classList.add(styles[type]);
            break;
          default:
            break;
        }
      } else {
        switch (type) {
          case 'region':
          case 'sector':
          case 'water-area':
            ref?.current?.getElement()?.classList.remove(styles[type]);
            break;
          default:
            break;
        }
      }
    }
  }, [isNonActive, ref.current]);

  switch (props.typeOfGeometry) {
    case 'region':
      return (
        <Tooltip
          ref={ref}
          direction={'top'}
          permanent={true}
          className={styles['default-area-tooltip'].concat(!isNonActive ? ' ' + styles[type] : '')}
        >
          <Region item={props} />
        </Tooltip>
      );
    case 'sector':
      return (
        <Tooltip
          ref={ref}
          direction={'top'}
          permanent={true}
          className={styles['default-area-tooltip'].concat(!isNonActive ? ' ' + styles[type] : '')}
        >
          <Sector item={props} />
        </Tooltip>
      );
    case 'water-area':
      return (
        <Tooltip
          ref={ref}
          direction={'top'}
          permanent={true}
          className={styles['default-area-tooltip'].concat(!isNonActive ? ' ' + styles[type] : '')}
        >
          <WaterArea item={props} />
        </Tooltip>
      );
    case 'meta-data-log':
      return (
        <Tooltip
          eventHandlers={{ click: metaDataLogEventsHandlers }}
          permanent={true}
          interactive={true}
          ref={ref}
          className={`${styles.tooltip} ${styles.permanent}`}
        >
          {props.typeOfGeometry === 'meta-data-log' ? <Log {...props} /> : null}
        </Tooltip>
      );
    case 'meta-data-gals':
      return (
        <Tooltip
          eventHandlers={{ click: metaDataGalsEventsHandlers }}
          permanent={true}
          interactive={true}
          ref={ref}
          className={`${styles.tooltip} ${styles.permanent}`}
        >
          {props.typeOfGeometry === 'meta-data-gals' ? <Gals {...props} /> : null}
        </Tooltip>
      );
    case 'ships-base':
      return (
        <Tooltip className={styles.tooltip} ref={ref}>
          <ShipsBase item={props} />
        </Tooltip>
      );
    case 'object':
      return (
        <Tooltip className={styles.tooltip} ref={ref}>
          <ObjectMarker {...props} />
        </Tooltip>
      );
    default:
      return null;
  }
};
