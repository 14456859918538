import { api } from '../api';
import { DefaultExpeditionType, CreationLink, Link, DefaultRequestType } from '@/types';

export const linksApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createExpeditionLinks: builder.mutation<DefaultExpeditionType, CreationLink>({
      query(body) {
        return {
          url: '/link',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    editExpeditionLinks: builder.mutation<DefaultExpeditionType, Link>({
      query(body) {
        return {
          url: '/link',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    deleteExpeditionLinks: builder.mutation<
      DefaultExpeditionType,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/link',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    createObjectCardLinks: builder.mutation<DefaultRequestType, CreationLink>({
      query(body) {
        return {
          url: 'object/link',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Objects'],
    }),
    editObjectCardLinks: builder.mutation<DefaultRequestType, Link>({
      query(body) {
        return {
          url: 'object/link',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Objects'],
    }),
    deleteObjectCardLinks: builder.mutation<
      DefaultRequestType,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: 'object/link',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Objects'],
    }),
  }),
});

export const {
  useCreateExpeditionLinksMutation,
  useEditExpeditionLinksMutation,
  useDeleteExpeditionLinksMutation,
  useCreateObjectCardLinksMutation,
  useEditObjectCardLinksMutation,
  useDeleteObjectCardLinksMutation,
} = linksApi;
