import { EditRole, NewRole, Role, DefaultResponse } from '@/types';
import { api } from '../api';

export const rolesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRolesList: builder.query<{ success: true; data: Role[] }, void>({
      query() {
        return {
          url: '/role/list',
          method: 'GET',
          credentials: 'include',
        };
      },
      providesTags: ['Roles'],
    }),
    createRole: builder.mutation<DefaultResponse, NewRole>({
      query(body) {
        return {
          url: '/role',
          method: 'POST',
          body: { ...body, rules_ids: body.rules_ids.map((rule) => rule.id) },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Roles'],
    }),
    editRole: builder.mutation<DefaultResponse, EditRole>({
      query(body) {
        return {
          url: '/role',
          method: 'PUT',
          body: { ...body, rules_ids: body.rules_ids.map((rule) => rule.id) },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Roles'],
    }),
    deleteRole: builder.mutation<
      DefaultResponse,
      {
        role_id: number;
      }
    >({
      query(body) {
        return {
          url: '/role',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Roles'],
    }),
  }),
});

export const {
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useEditRoleMutation,
  useGetRolesListQuery,
} = rolesApi;
