import { RuleResponse } from '@/types';
import { api } from '../api';

export const rulesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRulesList: builder.query<RuleResponse, void>({
      query() {
        return {
          url: '/rule/list',
          method: 'GET',
          credentials: 'include',
        };
      },
      providesTags: ['Rules'],
    }),
  }),
});

export const { useGetRulesListQuery } = rulesApi;
