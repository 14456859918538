import React, { FC, useEffect, useState } from 'react';
import { TableModalPropsType } from './table-modal.types';
import {
  ConfirmModal,
  FormHelper,
  Button,
  Modal,
  InputField,
  DropdownField,
  DatePickerField,
  LoadFileField,
  TransferField,
  HiddenField,
  CoordsSelectorField,
  TimePickerField,
} from '@/components';
import { Form, Formik } from 'formik';
import styles from './table-modal.module.scss';
import { ButtonTypes, ComponentSize, InputTypes } from '@/constants/enums';

export const TableModal: FC<TableModalPropsType> = ({
  onClose,
  onSubmit,
  width,
  title,
  open,
  fields,
  initialValues,
  validationSchema,
  buttonText,
  footer,
  loading = false,
  success = false,
  className,
  additionalTitle,
  destroyOnClose = true,
  additionalContent,
  redirectOnClose,
  initialFormDirty = false,
  setFormIsDirty: onFormDirty,
}): JSX.Element => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const requestAvailable = (formIsDirty || initialFormDirty) && !loading && !success;

  const getRequiredProp = (name: string | undefined): boolean =>
    name && validationSchema?.fields?.[name]?.exclusiveTests?.required;

  const toggleConfirmModal = (value?: boolean) => {
    if (value !== undefined) {
      setShowConfirmModal(value);
    } else {
      setShowConfirmModal((prevState) => !prevState);
    }
  };

  const handleClose = (modalVisible?: boolean) => {
    toggleConfirmModal(modalVisible);
    setTimeout(onClose, 100, formIsDirty);
    setFormIsDirty(false);
  };

  const handleSetFormIsDirty = (dirty: boolean) => {
    setFormIsDirty(dirty);
    onFormDirty && onFormDirty(dirty);
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        handleClose(false);
      }, 500);
    }
  }, [success]);

  const customClasses = [styles.wrapper, className].join(' ');

  return (
    <Modal
      open={open}
      width={width}
      centered={true}
      additionalTitle={additionalTitle}
      destroyOnClose={destroyOnClose}
      mask={true}
      maskClosable={false}
      title={title}
      className={customClasses}
      onClose={() => {
        if (formIsDirty) {
          toggleConfirmModal();
        } else {
          onClose();
          redirectOnClose && redirectOnClose();
        }
      }}
    >
      {additionalContent ? additionalContent : null}
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(value, event) => {
          (requestAvailable || initialFormDirty) && onSubmit && onSubmit(value, event);
          setFormIsDirty(false);
        }}
      >
        {({ handleSubmit, isValid, dirty }) => (
          <Form
            autoComplete="off"
            className={styles.form}
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit(event);
            }}
          >
            <FormHelper listenForm={!success} setFormIsDirty={handleSetFormIsDirty} />
            <ConfirmModal
              success={success}
              loading={loading}
              open={showConfirmModal}
              onCancel={() => toggleConfirmModal()}
              onSubmit={() => {
                toggleConfirmModal();
                setTimeout(onClose, 100, formIsDirty);
                setTimeout(() => redirectOnClose && redirectOnClose(), 100);
              }}
            />
            {fields.map(
              (
                {
                  type,
                  name,
                  label,
                  placeholder,
                  data,
                  value,
                  component,
                  loading,
                  leftTableColumns,
                  rightTableColumns,
                  leftTableTitle,
                  emptyLeftDisclaimer,
                  rightTableTitle,
                  maxLength,
                  rightTableDatasource,
                  error,
                  required,
                  onIconClick,
                  customLeftTableSelectionFilter,
                  customRightTableSelectionFilter,
                  className,
                  noDataPlaceholder,
                  iconType,
                },
                index,
              ) => {
                const textAreaClasses = [styles['text-area'], className].join(' ');
                switch (type) {
                  case InputTypes.text:
                  case InputTypes.number:
                    return (
                      <InputField
                        name={name}
                        label={label}
                        required={required || getRequiredProp(name)}
                        placeholder={placeholder}
                        size={ComponentSize.large}
                        type={type}
                        className={styles.field}
                        key={index}
                      />
                    );
                  case InputTypes.password:
                    return (
                      <InputField
                        name={name}
                        label={label}
                        required={required || getRequiredProp(name)}
                        placeholder={placeholder}
                        size={ComponentSize.large}
                        type={type}
                        className={styles.field}
                        key={index}
                        showInfoPassword={true}
                      />
                    );
                  case InputTypes.textArea:
                    return (
                      <InputField
                        name={name}
                        label={label}
                        maxLength={maxLength}
                        required={required || getRequiredProp(name)}
                        placeholder={placeholder}
                        size={ComponentSize.large}
                        type={type}
                        className={textAreaClasses}
                        key={index}
                      />
                    );
                  case 'hidden':
                    return (
                      <HiddenField
                        name={name}
                        type={type}
                        className={styles.hidden}
                        key={index}
                        value={value}
                      />
                    );
                  case 'datePicker':
                    return (
                      <DatePickerField
                        name={name}
                        label={label}
                        required={required || getRequiredProp(name)}
                        className={styles.field}
                        key={index}
                      />
                    );
                  case 'timePicker':
                    return (
                      <TimePickerField
                        name={name}
                        label={label}
                        required={required || getRequiredProp(name)}
                        className={styles.field}
                        key={index}
                      />
                    );
                  case 'dropdown':
                    return (
                      <DropdownField
                        loading={loading}
                        name={name}
                        label={label}
                        required={required || getRequiredProp(name)}
                        data={data}
                        className={styles.field}
                        noDataPlaceholder={noDataPlaceholder}
                        key={index}
                        error={error}
                      />
                    );
                  case 'multiselect':
                    return (
                      <DropdownField
                        loading={loading}
                        name={name}
                        label={label}
                        required={required || getRequiredProp(name)}
                        data={data}
                        className={styles.field}
                        noDataPlaceholder={noDataPlaceholder}
                        key={index}
                        error={error}
                        mode="multiple"
                      />
                    );
                  case 'cordSelector':
                    const customClasses = [styles.field, className].join(' ');

                    return (
                      <CoordsSelectorField
                        name={name}
                        label={label}
                        onIconClick={onIconClick}
                        required={required || getRequiredProp(name)}
                        className={customClasses}
                        key={index}
                        iconType={iconType}
                      />
                    );
                  case 'file':
                    return (
                      <LoadFileField
                        name={name}
                        label={label}
                        required={required || getRequiredProp(name)}
                        className={styles.field}
                        key={index}
                      />
                    );
                  case 'transfer':
                    return (
                      <TransferField
                        name={name}
                        label={label}
                        loading={loading}
                        customLeftTableSelectionFilter={customLeftTableSelectionFilter}
                        customRightTableSelectionFilter={customRightTableSelectionFilter}
                        leftTableColumns={leftTableColumns}
                        leftTableTitle={leftTableTitle}
                        rightTableDatasource={rightTableDatasource}
                        rightTableTitle={rightTableTitle}
                        emptyLeftDisclaimer={emptyLeftDisclaimer}
                        rightTableColumns={rightTableColumns}
                        required={required || getRequiredProp(name)}
                        className={styles.field}
                        key={index}
                      />
                    );
                  default:
                    return <div key={index}>{component}</div>;
                }
              },
            )}
            {footer ? (
              footer
            ) : (
              <Button
                disable={!isValid || (!dirty && !initialFormDirty) || !requestAvailable}
                className={styles.button}
                size={ComponentSize.small}
                type={ButtonTypes.submit}
                loading={loading}
              >
                {buttonText}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
