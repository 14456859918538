import { ClassificationItemTypes } from './classification-item.types';
import React, { FC } from 'react';
import styles from './classification-item.module.scss';
import { Icon } from '@/components';

export const ClassificationItem: FC<ClassificationItemTypes> = ({ type }): JSX.Element => {
  const selectIcon = (type: number): string => {
    switch (type) {
      case 0:
        return 'outline-ship';
      case 1:
        return 'outline-technical';
      case 2:
        return 'outline-PDO';
      case 3:
        return 'outline-amphora-2';
      case 4:
        return 'outline-military-2';
      default:
        return 'outline-unexplored';
    }
  };

  const selectedIcon = typeof type === 'number' ? selectIcon(type) : type;
  const selectedSize = selectedIcon === 'outline-unexplored' ? styles.little : styles.medium;

  return (
    <div className={selectedSize}>
      <Icon size={20} color="#FFFFFF" icon={selectedIcon} />
    </div>
  );
};
