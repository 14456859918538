import { ConfirmModal, SettingTabs } from '@/components';
import {
  useCreateAccessAreaMutation,
  useEditAccessAreaMutation,
  useGetAccessesAreasListQuery,
} from '@/state/api';
import styles from '../polygon-tool-panel.module.scss';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Handlers } from '../polygon-tool-panel.types';
import { AccessAreaContentPropsType, AccessAreaFormType } from './access-area-content.types';
import { useNavigate } from 'react-router';
import { useHistoryBackstack, useMap } from '@/context';
import { LINK_TO_SETTING_TAB } from '@/constants';
import { AccessAreaForm } from './access-area-form';
import { checkPolygon, openErrorNotify, openInfoNotify } from '@/utils';
import { LatLngExpression, Polygon } from 'leaflet';
import { isEqual } from 'lodash';
import { CoordsTable } from '../coords-table';
import { PolygonEditableLayer } from '../polygon-editable-layer';
import { PolygonDrawableLayer } from '../polygon-drawable-layer';

export const AccessAreaContent = forwardRef<Handlers, AccessAreaContentPropsType>(
  ({ id, setIsButtonsDisabled, setTitle }, ref) => {
    const { isLoading: isGetLoading, isError: isGetError, data } = useGetAccessesAreasListQuery();

    const preparedAccessArea = data?.data.find((item) => item.id === id);

    const pathOptions: L.PathOptions = {
      fillOpacity: 0.08,
      fillColor: '#797B89',
      color: '#797B89',
      weight: 1,
      lineJoin: 'round',
      lineCap: 'round',
    };

    const [isEditMode, setIsEditMode] = useState(id ? true : false);
    const [isCreateMode, setIsCreateMode] = useState(id ? false : true);

    const [accessArea, setAccessArea] = useState<AccessAreaFormType>();

    const [isPurged, setIsPurged] = useState(false);

    const [polygon, setPolygon] = useState<LatLngExpression[][][]>([
      [
        [
          [NaN, NaN],
          [NaN, NaN],
          [NaN, NaN],
        ],
      ],
    ]);

    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

    useEffect(() => {
      preparedAccessArea && setPolygon(preparedAccessArea.restricted_area);
    }, [preparedAccessArea]);

    useEffect(() => {
      const title = accessArea?.name || preparedAccessArea?.name || 'Область видимости';
      setTitle(title);
    }, [preparedAccessArea, accessArea]);

    const [
      createAccessArea,
      { isError: isCreateError, reset: resetCreate, isSuccess: isCreateSuccess },
    ] = useCreateAccessAreaMutation();

    const [
      updateAccessArea,
      { isError: isUpdateError, reset: resetUpdate, isSuccess: isUpdateSuccess },
    ] = useEditAccessAreaMutation();

    const navigate = useNavigate();

    const { backstack } = useHistoryBackstack();

    const handleRedirect = () => {
      navigate(backstack[backstack.length - 2] ?? LINK_TO_SETTING_TAB(SettingTabs[3].key));
    };

    const handleChangePolygon = (data: LatLngExpression[][][]) => {
      setPolygon(data);
      setIsPurged(false);
    };

    const { map } = useMap();

    useImperativeHandle(
      ref,
      () => ({
        handleCancel() {
          if (accessArea && id) {
            const initialPolygon = preparedAccessArea?.restricted_area;
            const initialData = {
              name: preparedAccessArea?.name,
              maxZoom: preparedAccessArea?.max_zoom,
            };
            if (!isEqual(initialData, accessArea) || !isEqual(polygon, initialPolygon)) {
              setIsShowConfirmModal(true);
            } else {
              handleRedirect();
            }
          } else if (accessArea) {
            setIsShowConfirmModal(true);
          } else {
            handleRedirect();
          }
        },
        handleChange() {
          if (id && accessArea) {
            const preparedData = {
              id: id,
              name: accessArea.name,
              restricted_area: polygon,
              max_zoom: accessArea.maxZoom,
            };
            updateAccessArea(preparedData);
          } else if (accessArea) {
            const preparedData = {
              name: accessArea.name,
              restricted_area: polygon,
              max_zoom: accessArea.maxZoom,
            };
            createAccessArea(preparedData);
          }
        },
        handleFlyTo() {
          if (checkPolygon(polygon)) {
            const lPolygon = new Polygon(polygon);
            map?.flyToBounds(lPolygon.getBounds());
          }
        },
        handlePurge() {
          setIsEditMode(false);
          setIsCreateMode(true);
          setIsPurged(true);
        },
      }),
      [backstack, accessArea, polygon, preparedAccessArea, id],
    );

    useEffect(() => {
      if (isGetError) {
        openErrorNotify('Произошла ошибка', 'Не удалось загрузить область видимости');
        handleRedirect();
      }
    }, [isGetError]);

    useEffect(() => {
      setIsButtonsDisabled(isGetLoading && !isGetError);
    }, [isGetLoading, isGetError]);

    useEffect(() => {
      const initialData = {
        name: preparedAccessArea?.name,
        maxZoom: preparedAccessArea?.max_zoom,
      };
      const isTouchedPolygon = !isEqual(polygon, preparedAccessArea?.restricted_area);
      const isTouchedData = !isEqual(initialData, accessArea);
      setIsButtonsDisabled(
        (!isTouchedData && !isTouchedPolygon) || !checkPolygon(polygon) || isCreateMode,
      );
    }, [polygon, accessArea]);

    useEffect(() => {
      if (isCreateSuccess) {
        openInfoNotify('Изменения сохранены', 'Область видимости создана');
        handleRedirect();
      }
      if (isCreateError) {
        openErrorNotify('Произошла ошибка', 'При создании области видимости произошла ошибка');
      }
      resetCreate();
    }, [isCreateSuccess, isCreateError]);

    useEffect(() => {
      if (isUpdateSuccess) {
        handleRedirect();
        openInfoNotify('Изменения сохранены', 'Область видимости изменена');
      }
      if (isUpdateError) {
        openErrorNotify(
          'Произошла ошибка',
          'При редактировании области видимости произошла ошибка',
        );
      }
      resetUpdate();
    }, [isUpdateSuccess, isUpdateError]);

    return (
      <>
        <ConfirmModal
          open={isShowConfirmModal}
          onCancel={() => setIsShowConfirmModal(false)}
          onSubmit={() => {
            handleRedirect();
          }}
        />
        <CoordsTable
          data={polygon}
          isCreateMode={isCreateMode}
          setIsCreateMode={(value: boolean) => setIsCreateMode(value)}
          setIsEditMode={(value: boolean) => setIsEditMode(value)}
          isEditMode={isEditMode}
          isError={isGetError}
          isLoading={isGetLoading}
          setData={(data) => handleChangePolygon(data)}
        />
        {!isGetLoading && (
          <AccessAreaForm
            item={preparedAccessArea}
            setData={(item) => setAccessArea(item)}
            onRedirect={handleRedirect}
          />
        )}
        {isEditMode ? (
          <PolygonEditableLayer
            name={accessArea?.name || preparedAccessArea?.name || 'Область видимости'}
            polygon={polygon}
            tooltipClassname={styles.tooltip}
            pathOptions={pathOptions}
            setPolygon={(value) => handleChangePolygon(value)}
          />
        ) : null}
        {isCreateMode ? (
          <PolygonDrawableLayer
            polygon={polygon}
            isPurged={isPurged}
            setIsCreateMode={(value) => setIsCreateMode(value)}
            setIsEditMode={(value: boolean) => setIsEditMode(value)}
            tooltipClassname={styles.tooltip}
            pathOptions={pathOptions}
            setPolygon={(value) => handleChangePolygon(value)}
          />
        ) : null}
      </>
    );
  },
);
