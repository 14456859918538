import { api } from '@/state/api';
import {
  DefaultRequestType,
  DefaultGetPhotoResponseType,
  DefaultMediaResponseType,
  Either,
} from '@/types';
import { MediaTargetsType, MediaTargetsTypes } from '@/constants/enums';

export const mediaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMediaList: builder.query<
      DefaultMediaResponseType,
      Either<{ expedition_id: number }, { object_id: number }>
    >({
      query(body) {
        let route = '';
        if ('object_id' in body) {
          route = `/${MediaTargetsTypes.object as string}/media/list`;
        }
        if ('expedition_id' in body) {
          route = `/${MediaTargetsTypes.expedition as string}/media/list`;
        }
        return {
          url: route,
          method: 'GET',
          params: body,
          credentials: 'include',
        };
      },
      providesTags: (q, w, arg) => {
        if ('expedition_id' in arg) {
          return ['ExpeditionMedia'];
        }
        if ('object_id' in arg) {
          return ['ObjectsMedia'];
        }
        return [''];
      },
    }),
    getMediaItem: builder.query<
      DefaultGetPhotoResponseType,
      { path: string; type: MediaTargetsType }
    >({
      query(body) {
        return {
          url: `/${body.type}/media`,
          method: 'GET',
          params: body,
          credentials: 'include',
        };
      },
      keepUnusedDataFor: 120,
    }),
    uploadMediaItem: builder.mutation<
      DefaultRequestType,
      { expedition_id: number; file: File } | { object_id: number; file: File }
    >({
      query(body) {
        let route = '';
        const data = new FormData();
        data.append('file', body?.file);
        if ('object_id' in body) {
          data.append('object_id', body.object_id?.toString());
          route = `/${MediaTargetsTypes.object as string}/media`;
        }
        if ('expedition_id' in body) {
          data.append('object_id', body.expedition_id?.toString());
          route = `/${MediaTargetsTypes.expedition as string}/media`;
        }

        return {
          url: route,
          method: 'POST',
          body: data,
          credentials: 'include',
        };
      },
      invalidatesTags: (q, w, arg) => {
        if ('expedition_id' in arg) {
          return ['ExpeditionMedia'];
        }
        if ('object_id' in arg) {
          return ['ObjectsMedia'];
        }
        return [''];
      },
    }),
    deleteMediaItem: builder.mutation<
      { success: boolean; message: string },
      { id: number; type: MediaTargetsTypes }
    >({
      query(body) {
        return {
          url: `/${body.type as string}/media`,
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: (q, w, arg) => {
        if (arg?.type === MediaTargetsTypes.expedition) {
          return ['ExpeditionMedia'];
        }
        if (arg?.type === MediaTargetsTypes.object) {
          return ['ObjectsMedia'];
        }
        return [''];
      },
    }),
  }),
});

export const {
  useGetMediaItemQuery,
  useGetMediaListQuery,
  useLazyGetMediaItemQuery,
  useUploadMediaItemMutation,
  useDeleteMediaItemMutation,
} = mediaApi;
