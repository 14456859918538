import {
  CULTURE_OBJECT_LAYER,
  DANGEROUS_OBJECT_LAYER,
  MILITARY_OBJECT_LAYER,
  TECHNICAL_OBJECT_LAYER,
  UNDISCOVERED_OBJECT_LAYER,
} from '@/constants';

export const listCheck = [
  {
    value: CULTURE_OBJECT_LAYER,
    title: 'Объект, имеющий культурную ценность',
    icon: 'fill-object-history',
  },
  {
    value: TECHNICAL_OBJECT_LAYER,
    title: 'Объект технический',
    icon: 'fill-object-technical',
  },
  {
    value: MILITARY_OBJECT_LAYER,
    title: 'Объект военно-исторического наследия',
    icon: 'fill-object-military',
  },
  {
    value: DANGEROUS_OBJECT_LAYER,
    title: 'Объект потенциально опасный (ВОП)',
    icon: 'fill-object-pdo',
  },
  {
    value: UNDISCOVERED_OBJECT_LAYER,
    title: 'Неисследованный объект',
    icon: 'fill-object-unexplored',
  },
];
