import React, { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import styles from '../../route-panel-table.module.scss';
import { IPointValue, ITime } from '@/types';
import { handleGetFormulaSecond, MINUTE, SECOND } from '../../../route-panel-utils';

export const Time: FC<ITime> = ({ speed, distanceKm, coords }) => {
  const [time, setTime] = useState('');

  const handleTime = (): void => {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    coords.slice(1).map((defaultCoords) => {
      if (defaultCoords.value) {
        const coordsValue: IPointValue = defaultCoords.value;
        const distance = coordsValue.distance;
        const second = handleGetFormulaSecond(distance as number, speed);
        seconds += second;

        let minute = Math.floor(second / SECOND);
        const hour = Math.floor(minute / MINUTE);

        minute -= hour * MINUTE;
        coordsValue.time = `${hour < 10 ? '0' + hour.toString() : hour}:${
          minute < 10 ? '0' + minute.toString() : minute
        }`;
      }
    });

    minutes += Math.floor(seconds / 60);

    const coefficient = Math.floor(minutes / 60);

    hours += coefficient;
    minutes -= 60 * coefficient;

    const hoursString: string = hours < 10 ? `0${hours}` : `${hours}`;
    const minutesString: string = minutes < 10 ? `0${minutes}` : `${minutes}`;

    if ((hoursString === '00' && minutesString === '00') || isNaN(minutes)) {
      setTime('');
    } else {
      setTime(`${hoursString}:${minutesString}`);
    }
  };

  useEffect(() => {
    handleTime();
  }, [coords, distanceKm, speed]);

  return (
    <Table.Summary.Row className={'ant-table-row'}>
      <Table.Summary.Cell index={0}>
        <div className={styles.data}>Время в пути, часы:минуты</div>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1}>
        <div className={styles.data}>{time ? time : 'Нет добавленной информации'}</div>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};
