import { api } from '../api';
import { CreationDive, EditDive, DefaultExpeditionType } from '@/types';

export const diveApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createExpeditionDive: builder.mutation<DefaultExpeditionType, CreationDive>({
      query(body: unknown) {
        return {
          url: '/dive/add',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    editExpeditionDive: builder.mutation<DefaultExpeditionType, EditDive>({
      query(body: unknown) {
        return {
          url: '/dive/edit',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    deleteExpeditionDive: builder.mutation<
      DefaultExpeditionType,
      {
        id: number;
        expedition_id: number;
      }
    >({
      query(body) {
        return {
          url: '/dive',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    createExpeditionToolDive: builder.mutation<DefaultExpeditionType, unknown>({
      query(body: unknown) {
        return {
          url: '/dive-tools/add',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    editExpeditionToolDive: builder.mutation<DefaultExpeditionType, unknown>({
      query(body: unknown) {
        return {
          url: '/dive-tools/edit',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
    deleteExpeditionToolDive: builder.mutation<
      DefaultExpeditionType,
      {
        id?: number | null;
        expedition_id?: number;
      }
    >({
      query(body) {
        return {
          url: '/dive-tools',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['ExpeditionSummary'],
    }),
  }),
});

export const {
  useCreateExpeditionDiveMutation,
  useEditExpeditionDiveMutation,
  useDeleteExpeditionDiveMutation,
  useCreateExpeditionToolDiveMutation,
  useEditExpeditionToolDiveMutation,
  useDeleteExpeditionToolDiveMutation,
} = diveApi;
