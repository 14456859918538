import React from 'react';
import { WaterArea, Sector, Region, IPolygonPopup } from './';
import styles from './map-popup.module.scss';
import { Popup } from 'react-leaflet';
import { CloseButton } from '@/components';
import { useMap } from '@/context';

export const MapPopup: React.FC<IPolygonPopup> = (props) => {
  const { map } = useMap();

  const handleClose = () => {
    map?.closePopup();
  };

  return (
    <Popup className={styles.popup} closeButton={false}>
      <div className={styles.title}>
        <p className={styles.bold}>{props.name}</p>
        <CloseButton className={styles.btn} iconSize={14} onClick={handleClose} />
      </div>
      <div className={styles.body}>
        {props.typeOfGeometry === 'water-area' ? <WaterArea item={props} /> : null}
        {props.typeOfGeometry === 'sector' ? <Sector item={props} /> : null}
        {props.typeOfGeometry === 'region' ? <Region item={props} /> : null}
      </div>
    </Popup>
  );
};
