import React, { FC } from 'react';
import { UsersTablePropsType } from './users-table.types';
import { handleDateSort, handleSortNumber, handleSortString } from '@/utils';
import { ColumnsType } from 'antd/lib/table';
import { User } from '@/types';
import {
  defaultSearchProps,
  Icon,
  MenuItem,
  PanelTableButton,
  Table,
  TableCell,
  TableFilter,
} from '@/components';
import { defaultDataPickerProps } from '@/components/table-components/table-utils';

export const UsersTable: FC<UsersTablePropsType> = ({
  data,
  dataRoles,
  loading,
  idToDisable,
  onClickEditBtn,
  onClickDeleteBtn,
  error,
}) => {
  const columns: ColumnsType<User> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 62,
      sorter: (first, second) => handleSortNumber(first.id, second.id),
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      dataIndex: 'id',
      fixed: 'left',
      align: 'center',
      width: 40,
      className: 'text-center',
      render: (value: number, row) => (
        <PanelTableButton
          onClick={() => onClickEditBtn(row)}
          tooltipTitle="Редактировать пользователя"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      dataIndex: 'id',
      fixed: 'left',
      align: 'center',
      width: 40,
      className: 'text-center',
      render: (value: number, row) => (
        <PanelTableButton
          onClick={() => idToDisable !== value && onClickDeleteBtn(row)}
          tooltipTitle="Удалить пользователя"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      align: 'left',
      width: 417,
      render: (value: string) => <TableCell value={value} maxWidth={417} />,
      sorter: (first, second, sortOrder) =>
        handleSortString(first?.email, second?.email, sortOrder),
      ...defaultSearchProps('email'),
    },
    {
      title: 'Последний вход',
      dataIndex: 'date_enter',
      align: 'left',
      key: 'date_enter',
      width: 175,
      render: (value: string) => <TableCell value={value} maxWidth={175} showTime={true} />,
      sorter: (first, second, sortOrder) =>
        handleDateSort(first?.date_enter, second?.date_enter, sortOrder),
      ...defaultDataPickerProps<User>('date_enter'),
    },
    {
      title: 'Роль',
      dataIndex: 'roles',
      key: 'roles',
      align: 'left',
      width: 126,
      render: (value: Array<{ id: number; name: string }>) => {
        const preparedData: MenuItem[] = value?.map(
          ({ id, name }: { id: number; name: string }): MenuItem => ({
            key: id,
            name: name,
          }),
        );

        return <TableCell value={preparedData} maxWidth={126} />;
      },
      sorter: (first, second, sortOrder) =>
        handleSortString(
          first.roles ? first?.roles[0]?.name : null,
          second.roles ? second?.roles[0]?.name : null,
          sortOrder,
        ),
      onFilter: (value, record) => {
        const checkRoles = record.roles.map((role) => role.id === value);

        return checkRoles.includes(true);
      },
      filterDropdown: ({ filters, clearFilters, confirm, setSelectedKeys, selectedKeys }) => (
        <TableFilter
          filters={filters}
          onFilter={confirm}
          onClear={clearFilters}
          selectedKeys={selectedKeys}
          onChange={setSelectedKeys}
        />
      ),
      filters: dataRoles?.map((role) => {
        return {
          value: role.id,
          text: role.name,
        };
      }),
    },
    {
      title: 'Фамилия',
      dataIndex: 'family',
      key: 'family',
      align: 'left',
      width: 180,
      render: (value: string) => <TableCell value={value} maxWidth={180} />,
      sorter: (first, second, sortOrder) =>
        handleSortString(first?.family, second?.family, sortOrder),
      ...defaultSearchProps('family'),
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 180,
      render: (value: string) => <TableCell value={value} maxWidth={180} />,
      sorter: (first, second, sortOrder) => handleSortString(first?.name, second?.name, sortOrder),
      ...defaultSearchProps('name'),
    },
    {
      title: 'Отчество',
      dataIndex: 'middlename',
      key: 'middlename',
      align: 'left',
      width: 180,
      render: (value: string) => <TableCell value={value} maxWidth={180} />,
      sorter: (first, second, sortOrder) =>
        handleSortString(first?.middlename, second?.middlename, sortOrder),
      ...defaultSearchProps('middlename'),
    },
    {
      title: 'Должность',
      dataIndex: 'position',
      key: 'position',
      align: 'left',
      width: 160,
      render: (value: string) => <TableCell value={value} maxWidth={160} />,
      sorter: (first, second, sortOrder) =>
        handleSortString(first?.position, second?.position, sortOrder),
    },
    {
      title: 'Количество входов',
      dataIndex: 'count_enters',
      key: 'count_enters',
      align: 'left',
      width: 180,
      render: (value: number) => <TableCell value={value} maxWidth={194} />,
      sorter: (first, second, sortOrder) =>
        handleSortNumber(first?.count_enters, second?.count_enters, sortOrder),
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      loading={loading}
      error={error}
      idToDisable={idToDisable}
      withSelection={false}
    />
  );
};
