import {
  DefaultResponse,
  IObject,
  NewObject,
  ObjectDefaultResponse,
  ObjectResponse,
  ObjectResponseTransformed,
} from '@/types';
import { api } from '../api';
import { FileApplicationTypes } from '@/constants/enums';

const prepareObject = (item: IObject, cordType?: string) => ({
  map_object: {
    id: +item.id,
    coordinates: {
      lat: { value: item?.lat, type: cordType || 'DEC' },
      lng: { value: item?.lng, type: cordType || 'DEC' },
    },
    wm_history: item.history,
    wm_annotation: item.annotation,
    attach_bow_lat: item.attach_bow_lat,
    attach_bow_lng: item.attach_bow_lng,
    attach_frame_lat: item.attach_frame_lat,
    attach_frame_lng: item.attach_frame_lng,
    attach_stern_lat: item.attach_stern_lat,
    attach_stern_lng: item.attach_stern_lng,
    attach_bearing_OA: item.attach_bearing_OA,
    attach_bearing_OB: item.attach_bearing_OB,
    attach_bearing_OC: item.attach_bearing_OC,
    attach_frame_length: item.attach_frame_length,
    attach_frame_width: item.attach_frame_width,
    attach_angle_different: item.attach_angle_different,
    attach_angle_heeling: item.attach_angle_heeling,
    dim_height_LB: item.dim_height_LB,
    dim_height_RB: item.dim_height_RB,
    dim_height_sternpost: item.dim_height_sternpost,
    dim_height_voorsteven: item.dim_height_voorsteven,
    dim_height_bow_LB: item.dim_height_bow_LB,
    dim_height_bow_RB: item.dim_height_bow_RB,
    dim_height_stern_LB: item.dim_height_stern_LB,
    dim_height_stern_RB: item.dim_depth_stern_RB,
    dim_depth_LB: item.dim_depth_LB,
    dim_depth_RB: item.dim_depth_RB,
    dim_depth_sternpost: item.dim_depth_sternpost,
    dim_depth_voorsteven: item.dim_depth_voorsteven,
    dim_depth_bow_LB: item.dim_depth_bow_LB,
    dim_depth_bow_RB: item.dim_depth_bow_RB,
    dim_depth_stern_LB: item.dim_depth_stern_LB,
    dim_depth_stern_RB: item.dim_depth_stern_RB,
    env_soil_characteristics: item.env_soil_characteristics,
    env_soil_example: item.env_soil_example,
    env_soil_foul: item.env_soil_foul,
    env_water_temp: item.env_water_temp,
    env_water_visibility_date: item.env_water_visibility_date,
    env_water_flow: item.env_water_flow,
    env_water_visibility: item.env_water_visibility,
    env_water_temp_date: item.env_water_temp_date,
    obj_immersion: item.obj_immersion,
    obj_delta: item.obj_delta,
    obj_direction: item.obj_direction,
    obj_details: item.obj_details,
    obj_environment: item.obj_environment,
    survey_anchor: item.survey_anchor,
    survey_hold: item.survey_hold,
    survey_porthole: item.survey_porthole,
    survey_manhole: item.survey_manhole,
    survey_mast: item.survey_mast,
    survey_wheel: item.survey_wheel,
    survey_bitt: item.survey_bitt,
    survey_mechanism: item.survey_mechanism,
    survey_hull: item.survey_hull,
    survey_corrosion: item.survey_corrosion,
    survey_weapon: item.survey_weapon,
    dive_cabin: item.dive_cabin,
    dive_bulkheads: item.dive_bulkheads,
    dive_cargo_hold: item.dive_cargo_hold,
    dive_cargo_hull: item.dive_cargo_hull,
    dive_door: item.dive_door,
    dive_fitting: item.dive_fitting,
    dive_latrine: item.dive_latrine,
    wm_name: item.name,
    wm_identifier: item.identifier,
    wm_expeditions: item.expeditions,
    wm_date: item.date,
    wm_type: item.type,
    wm_deep: item.deep,
    wm_deep2: item.deep2,
    wm_detection_tools: item.detection_tools,
    wm_soil_characteristics: item.soil_characteristics,
    wm_classification: item.classification,
    wm_work: item.work,
    wm_status: item.status,
    wm_area: item.area,
    wm_position: item.position,
    wm_survey_tools: item.survey_tools,
    wm_deepening: item.deepening,
    description: item?.description,
    expedition_id: item?.expedition_id,
    updated_date: item?.updated_date,
  },
});

export const objectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getObjects: builder.query<
      ObjectResponseTransformed,
      {
        expId: number;
      } | void
    >({
      query(body) {
        return {
          url: '/object/list',
          method: 'GET',
          params: { expId: body?.expId },
          credentials: 'include',
        };
      },
      transformResponse: (response: ObjectResponse) => {
        const objects = response.features.map((item) => item.properties);

        return {
          object_types: response.object_types,
          objects: objects,
        };
      },
      providesTags: ['Objects'],
    }),
    createObject: builder.mutation<ObjectDefaultResponse, NewObject>({
      query(body) {
        return {
          url: '/object/info',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Objects', 'ExpeditionSummary'],
    }),
    editObject: builder.mutation<ObjectResponse, IObject>({
      query(body) {
        return {
          url: '/object/info/batch',
          method: 'POST',
          body: prepareObject(body),
          credentials: 'include',
        };
      },
      invalidatesTags: ['Objects', 'ExpeditionsByObject'],
    }),
    deleteObject: builder.mutation<
      ObjectResponse,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/object/delete',
          method: 'DELETE',
          body: { id: body.id },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Objects', 'ExpeditionsByObject'],
    }),
    getUnloaded3DList: builder.query<DefaultResponse & { data: string[] }, void>({
      query() {
        return {
          url: '/object/3d/unloaded_list',
          method: 'GET',
          credentials: 'include',
        };
      },
      providesTags: ['Available3D'],
    }),
    get3DList: builder.query<
      Array<{
        id: number;
        name: string;
        scene_path: string;
        texture_path: string;
        material_path: string;
      }>,
      { id: number }
    >({
      query() {
        return {
          url: '/object/3d/list',
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    get3DModel: builder.query<
      DefaultResponse & {
        material: string | null;
        texture: string | null;
        scene: string;
        name: string;
        type: string;
      },
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/object/3d',
          method: 'GET',
          params: { id: body.id },
          credentials: 'include',
        };
      },
      keepUnusedDataFor: 20,
    }),
    delete3DModel: builder.mutation<
      DefaultResponse,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/object/3d',
          method: 'DELETE',
          body: { id: body.id },
          credentials: 'include',
        };
      },
      invalidatesTags: ['ObjectsMedia', 'Objects', 'Available3D'],
    }),
    add3DModel: builder.mutation<
      DefaultResponse,
      {
        name: string;
        object_id: number;
      }
    >({
      query(body) {
        return {
          url: '/object/3d',
          method: 'POST',
          body: { name: body.name, object_id: body.object_id },
          credentials: 'include',
        };
      },
      invalidatesTags: ['ObjectsMedia', 'Objects', 'Available3D'],
    }),
    exportObjectToDocx: builder.mutation<
      Blob,
      {
        id: number;
        name: string;
      }
    >({
      query(body) {
        return {
          url: '/object/export/docx',
          method: 'GET',
          credentials: 'include',
          params: { uid: body.id },
          cache: 'no-cache',
          responseHandler: async (response) => {
            const text = await response?.blob();
            const blob = new Blob([text], {
              type: FileApplicationTypes.docx,
            });
            const fileName = `${body.name}.docx`;
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.target = '_blank';
            link.download = fileName;
            link.click();
            return blob.text();
          },
        };
      },
    }),
  }),
});

export const {
  useGetObjectsQuery,
  useCreateObjectMutation,
  useDeleteObjectMutation,
  useEditObjectMutation,
  useAdd3DModelMutation,
  useDelete3DModelMutation,
  useGet3DListQuery,
  useGetUnloaded3DListQuery,
  useGet3DModelQuery,
  useExportObjectToDocxMutation,
} = objectsApi;
