import { CoordsTypes, FileApplicationTypes, Permissions } from '@/constants/enums';
import { AnyObject } from 'yup/lib/types';
import { DropdownFieldDataType, DropdownFieldValueType } from '@/components';
import { FormikValues } from 'formik';
import { decimalCordValidator, graduatedCoordsValidator } from '@/utils/validators';
import { convertToDecimalCord, convertToGraduatedCord } from '@/utils/map-utils';
import { isEmpty } from 'lodash';

export const prepareStatusValue = (status?: string): string => {
  switch (status?.toString()) {
    case '1':
      return 'Не подтвержден';
    case '2':
      return 'Подтвержден';
    case '3':
      return 'Завершен';
    case '4':
      return 'Идентифицирован';
    default:
      return 'Не выбран';
  }
};

export const prepareTypeValue = (type?: string | number) => {
  switch (type?.toString()) {
    case '1':
      return 'Неисследованный объект';
    case '2':
      return 'Объект технический';
    case '3':
      return 'Объект потенциально опасный (ВОП)';
    case '4':
      return 'Объект, имеющий культурную ценность';
    case '5':
      return 'Объект военно-исторического наследия';
    default:
      return 'Неисследованный объект';
  }
};

export const preparePermissionForMovingToAvailable = (
  rows: Array<AnyObject & { id: number }>,
  row: AnyObject & { id: number },
  checked: boolean,
  data: Array<AnyObject & { id: number }>,
) => {
  const rowId = row?.id;
  const selectedRows = rows.map((item) => item?.id);
  const availableData = data.map((item) => item.id);
  const permissions = Object.keys(Permissions)
    .filter((key) => !isNaN(Number(key)))
    .map((key) => Number(key))
    .filter((item) => availableData.includes(item));

  if (!selectedRows.length) {
    return checked ? [rowId] : [];
  }

  let filteredData: number[] = [];
  let permissionForCheck: number;
  let includedAtFilteredData: boolean;
  let includedAtSelectedData: boolean;

  switch (rowId) {
    case Permissions.baseContentViewing:
      return checked ? permissions : [];
    case Permissions.rolesManagment:
      return checked ? selectedRows : [];
    case Permissions.specificDataViewing:
      return checked
        ? permissions.filter((item) => item >= rowId)
        : selectedRows.filter((item) => item > rowId);
    case Permissions.mapDataViewing:
      filteredData = checked
        ? permissions.filter((item) => item >= rowId)
        : selectedRows.filter((item) => item > rowId);

      permissionForCheck = Permissions.expeditionsDataViewing;
      includedAtFilteredData = filteredData.includes(permissionForCheck);
      includedAtSelectedData = selectedRows.includes(permissionForCheck);

      return includedAtSelectedData && !includedAtFilteredData
        ? [...filteredData, permissionForCheck]
        : !includedAtSelectedData
        ? filteredData.filter((item) => item !== permissionForCheck)
        : filteredData;
    case Permissions.expeditionsDataViewing:
      filteredData = checked
        ? permissions.filter((item) => item >= rowId)
        : selectedRows.filter((item) => item > rowId);

      permissionForCheck = Permissions.mapDataViewing;
      includedAtFilteredData = filteredData.includes(permissionForCheck);
      includedAtSelectedData = selectedRows.includes(permissionForCheck);

      return includedAtSelectedData && !includedAtFilteredData
        ? [...filteredData, permissionForCheck]
        : !includedAtSelectedData
        ? filteredData.filter((item) => item !== permissionForCheck)
        : filteredData;
    case Permissions.expeditionsDataManagment:
      filteredData = checked
        ? permissions.filter((item) => item >= rowId)
        : selectedRows.filter((item) => item > rowId);

      permissionForCheck = Permissions.mapDataManagment;
      includedAtFilteredData = filteredData.includes(permissionForCheck);
      includedAtSelectedData = selectedRows.includes(permissionForCheck);

      return includedAtSelectedData && !includedAtFilteredData
        ? [...filteredData, permissionForCheck]
        : !includedAtSelectedData
        ? filteredData.filter((item) => item !== permissionForCheck)
        : filteredData;
    case Permissions.mapDataManagment:
      filteredData = checked
        ? permissions.filter((item) => item >= rowId)
        : selectedRows.filter((item) => item > rowId);

      permissionForCheck = Permissions.expeditionsDataManagment;
      includedAtFilteredData = filteredData.includes(permissionForCheck);
      includedAtSelectedData = selectedRows.includes(permissionForCheck);

      return includedAtSelectedData && !includedAtFilteredData
        ? [...filteredData, permissionForCheck]
        : !includedAtSelectedData
        ? filteredData.filter((item) => item !== permissionForCheck)
        : filteredData;
    default:
      return [];
  }
};

export const preparePermissionForMovingToSelected = (
  rows: Array<AnyObject & { id: number }>,
  row: AnyObject & { id: number },
  checked: boolean,
  data: Array<AnyObject & { id: number }>,
) => {
  const rowId = row?.id;
  const selectedRows = rows.map((item) => item?.id);
  const availableData = data.map((item) => item.id);
  const permissions = Object.keys(Permissions)
    .filter((key) => !isNaN(Number(key)))
    .map((key) => Number(key))
    .filter((item) => availableData.includes(item));

  let filteredData: number[] = [];
  let permissionForCheck: number;
  let includedAtFilteredData: boolean;
  let includedAtSelectedData: boolean;

  if (!selectedRows.length) {
    return checked ? [rowId] : [];
  }

  switch (rowId) {
    case Permissions.baseContentViewing:
      return checked ? selectedRows : [];
    case Permissions.rolesManagment:
      return checked ? permissions : selectedRows.filter((item) => item !== rowId);
    case Permissions.specificDataViewing:
      return checked
        ? permissions.filter((item) => item <= rowId)
        : selectedRows.filter((item) => item < rowId);
    case Permissions.mapDataViewing:
      filteredData = checked
        ? permissions.filter((item) => item <= rowId)
        : selectedRows.filter((item) => item < rowId);

      permissionForCheck = Permissions.expeditionsDataViewing;
      includedAtFilteredData = filteredData.includes(permissionForCheck);
      includedAtSelectedData = selectedRows.includes(permissionForCheck);

      return includedAtSelectedData && !includedAtFilteredData
        ? [...filteredData, permissionForCheck]
        : !includedAtSelectedData
        ? filteredData.filter((item) => item !== permissionForCheck)
        : filteredData;
    case Permissions.expeditionsDataViewing:
      filteredData = checked
        ? permissions.filter((item) => item <= rowId)
        : selectedRows.filter((item) => item < rowId);

      permissionForCheck = Permissions.mapDataViewing;
      includedAtFilteredData = filteredData.includes(permissionForCheck);
      includedAtSelectedData = selectedRows.includes(permissionForCheck);

      return includedAtSelectedData && !includedAtFilteredData
        ? [...filteredData, permissionForCheck]
        : !includedAtSelectedData
        ? filteredData.filter((item) => item !== permissionForCheck)
        : filteredData;
    case Permissions.expeditionsDataManagment:
      filteredData = checked
        ? permissions.filter((item) => item <= rowId)
        : selectedRows.filter((item) => item < rowId);

      permissionForCheck = Permissions.mapDataManagment;
      includedAtFilteredData = filteredData.includes(permissionForCheck);
      includedAtSelectedData = selectedRows.includes(permissionForCheck);

      return includedAtSelectedData && !includedAtFilteredData
        ? [...filteredData, permissionForCheck]
        : !includedAtSelectedData
        ? filteredData.filter((item) => item !== permissionForCheck)
        : filteredData;
    case Permissions.mapDataManagment:
      filteredData = checked
        ? permissions.filter((item) => item <= rowId)
        : selectedRows.filter((item) => item < rowId);

      permissionForCheck = Permissions.expeditionsDataManagment;
      includedAtFilteredData = filteredData.includes(permissionForCheck);
      includedAtSelectedData = selectedRows.includes(permissionForCheck);

      return includedAtSelectedData && !includedAtFilteredData
        ? [...filteredData, permissionForCheck]
        : !includedAtSelectedData
        ? filteredData.filter((item) => item !== permissionForCheck)
        : filteredData;
    default:
      return [];
  }
};

export const getNameByPath = (path?: string): string =>
  path?.split('/').pop()?.split('.').slice(0, -1).join(' ') || '';

export const getFileTypeNameByPath = (path?: string): string =>
  path?.split('/').pop()?.split('.').pop() || '';

export const getFileTypeByPath = (path?: string): FileApplicationTypes => {
  const typeName: string = getFileTypeNameByPath(path);
  const applicationType = FileApplicationTypes as AnyObject;

  return applicationType[typeName] || '';
};

// export const angleToRad = (angle: number) => angle * (Math.PI / 180);

export const prepareSelectFieldValue = (
  value?: DropdownFieldValueType,
  data?: DropdownFieldDataType[],
): DropdownFieldValueType => {
  const valueIsArray = Array.isArray(value);
  let preparedValue;

  if (valueIsArray) {
    preparedValue = value?.map((item) => {
      const preparedItem = item?.toString();

      return !!data?.find((searchElement) => searchElement?.value === preparedItem)
        ? preparedItem
        : undefined;
    });
  } else {
    const preparedItem = value?.toString() as string;
    preparedValue = !!data?.find((searchElement) => searchElement?.value === preparedItem)
      ? preparedItem
      : undefined;
  }

  return preparedValue as DropdownFieldValueType;
};

export const prepareCoordsFieldToString = (
  value?: FormikValues,
  cordType?: CoordsTypes,
  name = '',
): string => {
  if (!value || isEmpty(value)) {
    return '';
  }

  let preparedLat, preparedLng;
  const coordsFieldsAvailable =
    !!value && 'lat' in value && 'lng' in value && !!value.lat && !!value.lng;

  if (coordsFieldsAvailable) {
    preparedLat = value.lat as string;
    preparedLng = value.lng as string;
  } else {
    preparedLat = value?.[name]?.split(', ')[0] as string;
    preparedLng = value?.[name]?.split(', ')[1] as string;
  }

  const isValidDecimalCoords =
    decimalCordValidator(preparedLat) && decimalCordValidator(preparedLng);
  const isValidGraduatedCoords =
    graduatedCoordsValidator(preparedLat, true) && graduatedCoordsValidator(preparedLng);

  if (isValidDecimalCoords) {
    if (cordType === CoordsTypes.graduated) {
      preparedLat = convertToDecimalCord(preparedLat, true);
      preparedLng = convertToDecimalCord(preparedLng);
      const isValidGraduatedCoords =
        graduatedCoordsValidator(preparedLat, true) && graduatedCoordsValidator(preparedLng);

      return isValidGraduatedCoords ? `${String(preparedLat)}, ${String(preparedLng)}` : '';
    }
    return `${Number(preparedLat).toFixed(6)}, ${Number(preparedLng).toFixed(6)}`;
  }
  if (isValidGraduatedCoords) {
    if (cordType === CoordsTypes.decimal) {
      preparedLat = convertToGraduatedCord(preparedLat);
      preparedLng = convertToGraduatedCord(preparedLng);

      const isValidDecimalCoords =
        decimalCordValidator(preparedLat) && decimalCordValidator(preparedLng);

      return isValidDecimalCoords
        ? `${Number(preparedLat).toFixed(6)}, ${Number(preparedLng).toFixed(6)}`
        : '';
    }
    return `${String(preparedLat)}, ${String(preparedLng)}`;
  }
  return '';
};
