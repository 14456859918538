import React, { FC } from 'react';
import styles from './table-cell.module.scss';
import { TableCellPropsType } from '@/components';
import { isArray } from 'lodash';
import { TableCellForArray } from './table-cell-for-array';
import { TableCellForString } from './table-cell-for-string';
import { TableCellForNoData } from './table-cell-for-no-data';
import dayjs from 'dayjs';

export const TableCellWrapper: FC<TableCellPropsType> = ({
  value,
  className,
  label = 'Нет добавленной информации',
  maxWidth = 1200,
  showTime = false,
  showAllText = false,
  isDay = true,
  fontSize,
}) => {
  const cellClasses = [styles.cell, className].join(' ');

  if (value || value === 0) {
    switch (typeof value) {
      case 'string': {
        if (!isNaN(Number(value))) {
          return (
            <TableCellForString
              showAllText={showAllText}
              className={cellClasses}
              maxWidth={maxWidth}
              fontSize={fontSize}
              value={value}
              label={label}
            />
          );
        }
        if (dayjs(value).isValid() && isDay && !/[a-sv-yа-яё]/i.test(value)) {
          let preparedValue = dayjs(value).format('DD.MM.YYYY');
          if (showTime) {
            preparedValue = dayjs(value).format('DD.MM.YYYY HH:mm');
          }

          return (
            <TableCellForString
              showAllText={showAllText}
              fontSize={fontSize}
              className={cellClasses}
              maxWidth={maxWidth}
              value={preparedValue}
              label={label}
            />
          );
        }
        return (
          <TableCellForString
            showAllText={showAllText}
            fontSize={fontSize}
            className={cellClasses}
            maxWidth={maxWidth}
            value={value}
            label={label}
          />
        );
      }
      case 'number':
        return (
          <TableCellForString
            showAllText={showAllText}
            fontSize={fontSize}
            className={cellClasses}
            maxWidth={maxWidth}
            value={value?.toString()}
            label={label}
          />
        );
      case 'object':
        if (isArray(value)) {
          return (
            <TableCellForArray
              showAllText={showAllText}
              fontSize={fontSize}
              className={cellClasses}
              maxWidth={maxWidth}
              value={value}
              label={label}
            />
          );
        }
        if (value?.name) {
          return (
            <TableCellForString
              showAllText={showAllText}
              fontSize={fontSize}
              className={cellClasses}
              maxWidth={maxWidth}
              value={value?.name}
              link={value?.link as string}
              label={label}
            />
          );
        }
        return <TableCellForNoData label={label} className={cellClasses} />;

      default:
        return null;
    }
  }

  return <TableCellForNoData label={label} className={cellClasses} />;
};
