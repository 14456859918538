/* eslint-disable */
//@ts-nocheck
import { createPathComponent } from '@react-leaflet/core';
import L from 'leaflet';
// import 'react-leaflet-markercluster/dist/styles.min.css';
import 'leaflet.markercluster';
// TODO: think about https://github.com/SINTEF-9012/PruneCluster

L.MarkerClusterGroup.include({
  _flushLayerBuffer() {
    this.addLayers(this._layerBuffer);
    this._layerBuffer = [];
  },

  addLayer(layer) {
    if (this._layerBuffer.length === 0) {
      setTimeout(this._flushLayerBuffer.bind(this), 50);
    }
    this._layerBuffer.push(layer);
  },
});

L.MarkerClusterGroup.addInitHook(function () {
  this._layerBuffer = [];
});

const MarkerClusterGroup = createPathComponent(
  ({ children: _c, ...props }, ctx) => {
    const clusterProps = {};
    const clusterEvents = {};

    // Splitting props and events to different objects
    Object.entries(props).forEach(([propName, prop]) => {
      if (propName.startsWith('on')) {
        return (clusterEvents[propName] = prop);
      }
      return (clusterProps[propName] = prop);
    });
    // Creating markerClusterGroup Leaflet element
    const markerClusterGroup = new L.markerClusterGroup(clusterProps);

    // Initializing event listeners
    Object.entries(clusterEvents).forEach(([eventAsProp, callback]) => {
      const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`;
      markerClusterGroup.on(clusterEvent, callback);
    });

    ctx.map.on('zoomend', function () {
      const layers = markerClusterGroup.getLayers();
      markerClusterGroup.clearLayers();
      markerClusterGroup.addLayers(layers);
    });
    return {
      instance: markerClusterGroup,
      context: { ...ctx, layerContainer: markerClusterGroup },
    };
  },
  (instance, { children, ...props }, prevProps) => {
    // delete all layers cos react create a new elements
    // if u don't need this use original react leaflet markercluster
    // instance.clearLayers();
  },
);

export { MarkerClusterGroup };
