import React, { FC, useEffect, useMemo, useState } from 'react';
import { ObjectCardMediaSectionPropsType } from './media-section.types';
import {
  ConfirmModal,
  FieldType,
  ImageGallery,
  menuData,
  ObjectCardDocumentsTable,
  ObjectCardLinkTable,
  ObjectCardMediaSectionWrapper,
  TableModal,
} from '@/components';
import { FileTypes, MediaTargetsTypes, Targets3DTypes } from '@/constants/enums';
import styles from './media-section.module.scss';
import {
  useUploadMediaItemMutation,
  useDeleteMediaItemMutation,
  useGetMediaListQuery,
  useLazyGetMediaItemQuery,
  useGetUnloaded3DListQuery,
  useAdd3DModelMutation,
  useDelete3DModelMutation,
} from '@/state/api';
import { useNavigate, useParams } from 'react-router';
import { UploadFile } from 'antd';
import { DefaultMediaType, DefaultResponse } from '@/types';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { debounce } from 'lodash';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import {
  getFileTypeByPath,
  getFileTypeNameByPath,
  getNameByPath,
  openErrorNotify,
  openInfoNotify,
} from '@/utils';
import * as Yup from 'yup';
import { Preview3D } from '../3d-preview';
import { LINK_TO_VIEW_3D } from '@/constants';

export const ObjectCardMediaSection: FC<ObjectCardMediaSectionPropsType> = ({
  className = '',
  isEdit = false,
  item,
}) => {
  const [selectedImg, setSelectedImg] = useState<UploadFile[]>([]);
  const [selectedDocs, setSelectedDocs] = useState<UploadFile[]>([]);
  const [isShowDelete3D, setIsShowDelete3D] = useState(false);
  const [selected3Dname, setSelected3Dname] = useState('');
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState<DefaultMediaType>();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isShowingAdd3DModal, setIsShowingAdd3DModal] = useState(false);

  const params = useParams();

  const id = String(params?.id);
  const customStyles = [styles.wrapper, className].join(' ');

  const { data, isError, isLoading } = useGetMediaListQuery({
    object_id: +id,
  });

  const {
    data: unloaded3Ds,
    isLoading: is3DsUnloadedLoading,
    isError: is3DsUnloadedError,
  } = useGetUnloaded3DListQuery();

  const [
    delete3DModel,
    {
      isError: isDelete3DError,
      isLoading: isDelete3DLoading,
      isSuccess: isDelete3DSuccess,
      reset: deleteReset3D,
    },
  ] = useDelete3DModelMutation();

  const [
    deleteMedia,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
      reset: deleteReset,
    },
  ] = useDeleteMediaItemMutation();

  const [uploadMedia] = useUploadMediaItemMutation();

  const [downloadMedia, { isLoading: downloadLoading }] = useLazyGetMediaItemQuery();

  const photoList = data?.data?.photo || [];
  const docsList = data?.data?.documents || [];
  const model3D = data?.data.model_3d;

  const preparedUnloaded3DOptions = unloaded3Ds?.data.map((item) => ({
    value: item,
    label: item,
  }));

  const fields: FieldType[] = [
    {
      label: '3D-модель',
      name: 'path',
      placeholder: 'Выберите 3D-модель',
      type: 'dropdown',
      required: true,
      data: preparedUnloaded3DOptions,
      loading: is3DsUnloadedLoading,
      error: is3DsUnloadedError,
    },
  ];

  const [
    add3DModel,
    {
      isError: isAdd3DError,
      isLoading: isAdd3DLoading,
      isSuccess: isAdd3DSuccess,
      reset: addReset3D,
    },
  ] = useAdd3DModelMutation();

  const handleAdd3DModel = (value: string) => {
    add3DModel({ name: value, object_id: Number(id) });
  };

  const validationSchema = Yup.object({
    path: Yup.string().required('Пожалуйста, выберите 3D-модель'),
  });

  const isImage = (file?: File): boolean => !!file && file?.type?.split('/')[0] === 'image';

  const handleRemove = (file?: File | RcFile) => {
    if (file) {
      const prepareData = (prevState: UploadFile[]) =>
        prevState?.filter(({ name, fileName }) => file?.name !== name && fileName !== name);

      if (isImage(file)) {
        setSelectedImg((prevState) => prepareData(prevState));
      } else {
        setSelectedDocs((prevState) => prepareData(prevState));
      }
    }
  };

  const debounceRemove = useMemo(() => debounce(handleRemove, 300), []);

  const handleUpload = (options: UploadRequestOption) => {
    const { file: fileInfo, onError, onSuccess, filename } = options;
    const file = fileInfo as File;
    uploadMedia({
      object_id: +id,
      file,
    }).then((response: unknown) => {
      const result = response as DefaultResponse;
      // @ts-ignore
      const error = result?.error as DefaultResponse;
      if (result?.data?.success || error?.data?.success) {
        handleRemove(file);
        onSuccess && onSuccess(response);
      } else {
        const preparedData = {
          ...result,
          name: file?.name || filename || '',
          message: error?.data?.message || result?.data?.message || '',
        };
        onError && onError(preparedData);
      }
    });
  };

  const handleDownload = (item: DefaultMediaType) => {
    const preparedData = {
      path: item?.path,
      type: MediaTargetsTypes.object,
    };
    const name: string = getNameByPath(item?.path) || item.name || '';
    const type: string = getFileTypeByPath(item?.path) || item?.media_type;
    const typeName: string = getFileTypeNameByPath(item?.path) || '';

    setSelectedItem(item);

    downloadMedia(preparedData).then((res) => {
      if (res?.data?.success) {
        const data: string = res?.data?.data;
        const link = document.createElement('a');
        link.href = `data:application/${type};base64, ${data}`;
        link.target = '_blank';
        link.download = `${name}.${typeName}`;
        link.click();
      } else {
        const error = res?.error as DefaultResponse;
        openErrorNotify(
          'Произошла ошибка',
          <>
            При загрузке файла <b>{name}</b> произошла ошибка.
            <br />
            {res?.data?.message || error?.data?.message}
          </>,
        );
      }
    });
  };

  const handleClick = (event?: UploadChangeParam<UploadFile>) => {
    if (event && event?.file.status !== 'removed') {
      const prepareData = (newItem: UploadFile, prevState: UploadFile[]) => {
        if (prevState?.length === 0) {
          return [newItem];
        }
        const preparedArray = prevState?.map((item) => {
          const itemAvailable = item?.name === newItem?.name;
          if (itemAvailable) {
            return newItem;
          }
          return item;
        });

        return preparedArray?.find((item) => item?.name === newItem?.name)
          ? preparedArray
          : [...preparedArray, newItem];
      };

      if (isImage(event?.file?.originFileObj)) {
        setSelectedImg((prevState) => prepareData(event?.file, prevState));
      } else {
        setSelectedDocs((prevState) => prepareData(event?.file, prevState));
      }
    }
  };

  const handleDelete = (id?: number) => {
    if (id) {
      deleteMedia({ id, type: MediaTargetsTypes.object });
    }
  };

  const handleToggleDelete = (item: DefaultMediaType) => {
    setSelectedItem(item);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    const handleCheck = (data: UploadFile[]) => {
      data.forEach((item) => {
        if (item?.status === 'done') {
          debounceRemove(item?.originFileObj);
        }
      });
    };
    if (selectedImg.length > 0) {
      handleCheck(selectedImg);
    }
    if (selectedDocs.length > 0) {
      handleCheck(selectedDocs);
    }
  }, [selectedImg, selectedDocs]);

  const handleDelete3D = () => {
    delete3DModel({ id: Number(model3D?.id) });
  };

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        <>
          Удаление файла <b>{selectedItem?.name}</b> успешно выполнено
        </>,
      );
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify(
        'Произошла ошибка',
        <>
          При удалении файла <b>{selectedItem?.name}</b> произошла ошибка
        </>,
      );
      deleteReset();
    }
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    if (isAdd3DSuccess) {
      openInfoNotify('Изменения сохранены', <>Добавление 3D-модели успешно</>);
      addReset3D();
    }
    if (isAdd3DError) {
      openErrorNotify('Произошла ошибка', <>При добавлении 3D-модели произошла ошибка</>);
      addReset3D();
    }
  }, [isAdd3DSuccess, isAdd3DError]);

  useEffect(() => {
    if (isDelete3DSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        <>
          Удаление 3D-модели <b>{selected3Dname}</b> успешно выполнено
        </>,
      );
      deleteReset3D();
    }
    if (isDelete3DError) {
      openErrorNotify(
        'Произошла ошибка',
        <>
          При удалении 3D-модели <b>{selected3Dname}</b> произошла ошибка
        </>,
      );
      deleteReset3D();
    }
  }, [isDelete3DError, isDelete3DSuccess]);

  useEffect(() => {
    if (isError) {
      openErrorNotify('Произошла ошибка', 'При загрузке списка вложений произошла ошибка');
    }
  }, [isError]);

  const handleView3D = () => {
    navigate(LINK_TO_VIEW_3D(Targets3DTypes.object, Number(item?.id)));
  };

  return (
    <div className={customStyles}>
      <ObjectCardLinkTable item={item} isEdit={isEdit} />
      <ObjectCardMediaSectionWrapper
        fileType={FileTypes.image}
        uploadedFilesList={selectedImg}
        isEdit={isEdit}
        id={menuData[5]?.subtitle?.[1]?.link}
        label={menuData[5]?.subtitle?.[1]?.label}
        onClick={(event) => handleClick(event)}
        onUpload={(options) => handleUpload(options)}
        onRemove={(uploadFile) => handleRemove(uploadFile?.originFileObj)}
        buttonText="Добавить изображение"
        uploadAvailable={true}
        loading={isLoading}
      >
        {photoList && photoList?.length > 0 && (
          <ImageGallery
            data={photoList}
            isEdit={isEdit}
            onDelete={(item) => handleToggleDelete(item)}
            mediaType={MediaTargetsTypes.object}
          />
        )}
      </ObjectCardMediaSectionWrapper>
      <ObjectCardMediaSectionWrapper
        isEdit={isEdit && !model3D}
        onClick={() => setIsShowingAdd3DModal(true)}
        buttonText="Добавить 3D-модель"
        uploadAvailable={false}
        id={menuData[5]?.subtitle?.[2]?.link}
        label={menuData[5]?.subtitle?.[2]?.label}
        loading={isAdd3DLoading}
      >
        {model3D && (
          <Preview3D
            name={model3D.name}
            isEdit={isEdit}
            onDelete={() => {
              setSelected3Dname(model3D.name);
              setIsShowDelete3D(true);
            }}
            onView={() => handleView3D()}
          />
        )}
      </ObjectCardMediaSectionWrapper>
      <ObjectCardMediaSectionWrapper
        fileType={FileTypes.docs}
        uploadedFilesList={selectedDocs}
        isEdit={isEdit}
        buttonText="Добавить документ"
        onClick={(event) => handleClick(event)}
        onUpload={(options) => handleUpload(options)}
        onRemove={(uploadFile) => handleRemove(uploadFile?.originFileObj)}
        uploadAvailable={true}
        loading={isLoading}
        id={menuData[5]?.subtitle?.[3]?.link}
        label={menuData[5]?.subtitle?.[3]?.label}
      >
        {docsList && docsList?.length > 0 && (
          <ObjectCardDocumentsTable
            data={docsList}
            isEdit={isEdit}
            onDownload={(item) => handleDownload(item)}
            onDelete={(item) => handleToggleDelete(item)}
            downloadLoading={downloadLoading}
            selectedItem={selectedItem}
          />
        )}
      </ObjectCardMediaSectionWrapper>
      <div className="position-absolute">
        <ConfirmModal
          open={showDeleteModal}
          title="Подтвердите удаление файла"
          content={
            <p>
              Вы уверены, что хотите удалить&nbsp;
              {selectedItem?.name || getNameByPath(selectedItem?.path) ? (
                <>
                  файл <b>{selectedItem?.name || getNameByPath(selectedItem?.path)}</b>
                </>
              ) : (
                'выбранный файл'
              )}
              ?
            </p>
          }
          submitButtonText="Удалить файл"
          cancelButtonText="отменить"
          onSubmit={() => handleDelete(selectedItem?.id)}
          onCancel={() => setShowDeleteModal(false)}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
        <ConfirmModal
          open={isShowDelete3D}
          title="Подтвердите удаление 3D-модели"
          content={
            <p>
              Вы уверены, что хотите удалить 3D-модель&nbsp;
              <b>{selected3Dname}</b>?
            </p>
          }
          submitButtonText="Удалить 3D-модель"
          cancelButtonText="Отменить"
          onSubmit={() => handleDelete3D()}
          onCancel={() => setIsShowDelete3D(false)}
          loading={isDelete3DLoading}
          success={isDelete3DSuccess}
          type="danger"
        />
        <TableModal
          onSubmit={(value) => handleAdd3DModel(value.path)}
          buttonText="Добавить"
          onClose={() => setIsShowingAdd3DModal(false)}
          title="Добавление 3D-модели"
          validationSchema={validationSchema}
          initialValues={{ path: '' }}
          open={isShowingAdd3DModal}
          loading={isAdd3DLoading}
          success={isAdd3DSuccess}
          fields={fields}
          width={563}
        />
      </div>
    </div>
  );
};
