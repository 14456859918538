import React, { FC, useEffect } from 'react';
import { Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { MapPageTablesPropsType } from './map-page-tables.types';
import {
  EntireTablesTabsType,
  ExpeditionTablesTabsTypes,
  LayerTablesTabsTypes,
  SettingTablesTabsTypes,
} from '@/constants/enums';

export const MapPageTables: FC<
  MapPageTablesPropsType<ExpeditionTablesTabsTypes | LayerTablesTabsTypes | SettingTablesTabsTypes>
> = ({ data, link }) => {
  const navigate = useNavigate();
  const { key } = useParams();

  const handleNavigate = (key: EntireTablesTabsType) => {
    const path = link(key);
    navigate(path);
  };

  useEffect(() => {
    const pathUnavailable = data.filter((item) => item.key === key).length === 0;
    if (pathUnavailable) {
      handleNavigate(data[0].key as EntireTablesTabsType);
    }
  }, [key]);

  return (
    <Tabs
      type="line"
      activeKey={key}
      onChange={handleNavigate as () => string}
      defaultActiveKey={key || data[0].key}
      items={data}
    />
  );
};
