import React, { FC, useEffect, useState } from 'react';
import styles from '../../default-tab.module.scss';
import {
  BlackTooltip,
  ConfirmModal,
  ExpeditionsTable,
  FieldType,
  Icon,
  PanelTabButton,
  TableModal,
} from '@/components';
import {
  useCreateExpeditionMutation,
  useDeleteExpeditionMutation,
  useGetExpeditionListQuery,
  useGetWaterAreasQuery,
} from '@/state/api';
import {
  ComponentSize,
  ExpeditionStatusesType,
  InputTypes,
  PanelTabButtonTypes,
  Permissions,
} from '@/constants/enums';
import { openErrorNotify, openInfoNotify } from '@/utils';
import * as Yup from 'yup';
import { ICreateExpedition, IExpedition } from '@/types';
import { useNavigate } from 'react-router';
import { LINK_TO_EXPEDITION_PANEL } from '@/constants';
import { useAppSelector } from '@/state';

export const ExpeditionsTab: FC = (): JSX.Element => {
  const { isLoading, data, isError } = useGetExpeditionListQuery();

  const userPermissions = useAppSelector((state) => state.auth.user?.rules);
  const isEditable = userPermissions?.includes(Permissions.expeditionsDataManagment);

  const [
    createExpedition,
    {
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
      reset: resetCreate,
    },
  ] = useCreateExpeditionMutation();

  const [
    deleteExpedition,
    {
      isSuccess: deleteSuccess,
      isError: deleteError,
      isLoading: deleteLoading,
      reset: deleteReset,
    },
  ] = useDeleteExpeditionMutation();

  const {
    data: waterAreaData,
    isLoading: isWaterAreaLoading,
    isError: isWaterAreaError,
  } = useGetWaterAreasQuery();

  const loading = isLoading || isWaterAreaLoading;
  const error = isError || isWaterAreaError;

  const duplicatedCards = data?.expeditions
    .filter((item) => item.parent_expedition_id)
    .map((item) => item.parent_expedition_id);

  const preparedExpedition =
    data?.expeditions.filter((item) => !duplicatedCards?.includes(item.id)) || [];

  const preparedRolesData =
    waterAreaData?.map((item) => ({ label: item.name, value: item.id })) || [];

  const [selectedExpedition, setSelectedExpedition] = useState<IExpedition>();
  const [createdName, setCreatedName] = useState<string>('');

  const [showAddModal, setShowAddModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const getExpedition = (id: number) => {
    const item = preparedExpedition.find((expedition) => expedition.id === id);
    item && setSelectedExpedition(item);
  };

  const handleCreate = () => {
    resetCreate();
    setShowAddModal(true);
  };

  const navigate = useNavigate();

  const handleNavigate = (id: number, link: ExpeditionStatusesType) => {
    navigate(LINK_TO_EXPEDITION_PANEL(id, link));
  };

  const handleCreateExpedition = (value: ICreateExpedition) => {
    setCreatedName(value.name);
    createExpedition(value);
  };

  const handleDelete = (id: number) => {
    deleteReset();
    setShowConfirmDeleteModal((prevState) => !prevState);
    getExpedition(id);
  };

  const handleDeleteExpedition = (id: number): void => {
    deleteExpedition({
      id: id,
    });
  };

  const initialValues: ICreateExpedition = {
    name: '',
    tentative_start_date: '',
    tentative_end_date: '',
  };

  const fields: FieldType[] = [
    {
      label: 'Акватория',
      name: 'water_area_id',
      placeholder: 'Выберите наименование',
      type: 'dropdown',
      required: true,
      data: preparedRolesData,
      loading: isWaterAreaLoading,
      error: isWaterAreaError,
    },
    {
      label: 'Наименование',
      name: 'name',
      placeholder: 'Введите наименование',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Планируемая дата начала',
      name: 'tentative_start_date',
      type: 'datePicker',
      required: true,
    },
    {
      label: 'Планируемая дата окончания',
      name: 'tentative_end_date',
      type: 'datePicker',
      required: true,
    },
  ];

  const validationSchema = Yup.object({
    water_area_id: Yup.number().required('Пожалуйста, выберите акваторию'),
    name: Yup.string().required('Пожалуйста, введите наименование экспедиции'),
    tentative_start_date: Yup.date()
      .min(new Date(1900, 0, 1), 'Вы указали некорректную дату')
      .required('Пожалуйста, укажите дату'),
    tentative_end_date: Yup.date()
      .min(new Date(1900, 0, 1), 'Вы указали некорректную дату')
      .when('tentative_start_date', (eventStartDate, schema) => {
        return (
          eventStartDate &&
          schema.min(eventStartDate, 'Дата окончания должна быть больше, чем дата начала.')
        );
      })
      .required('Пожалуйста, укажите дату'),
  });

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify(
        'Добавление экспедиции',
        <>
          Экспедиция <b>{createdName}</b> была добавлена
        </>,
      );
      resetCreate();
    }
    if (createError) {
      openErrorNotify('Произошла ошибка', 'При добавлении произошла ошибка');
      resetCreate();
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Удаление экспедиции',
        <>
          Экспедиция <b>{selectedExpedition?.name}</b> была удалена
        </>,
      );
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении произошла ошибка');
      deleteReset();
    }
  }, [deleteSuccess, deleteError, createdName]);

  return (
    <div className={styles.wrapper}>
      {!error && !loading && (
        <div className={styles.header}>
          <BlackTooltip title={'Список экспедиций.'} placement={'bottomLeft'}>
            <PanelTabButton
              className={styles['button-panel-help']}
              onClick={() => {}}
              type={PanelTabButtonTypes.icon}
              size={ComponentSize.small}
            >
              <Icon icon="outline-question-circle" size={14} />
            </PanelTabButton>
          </BlackTooltip>
          {isEditable ? (
            <PanelTabButton
              disabled={isLoading || isError}
              type={PanelTabButtonTypes.roundSecondary}
              onClick={() => handleCreate()}
              size={ComponentSize.small}
            >
              Создать новую экспедицию
            </PanelTabButton>
          ) : null}
        </div>
      )}
      {isEditable ? (
        <>
          <TableModal
            onSubmit={(value) => handleCreateExpedition(value as ICreateExpedition)}
            buttonText="Добавить"
            onClose={() => {
              setShowAddModal(false);
            }}
            title="Новая экспедиция"
            validationSchema={validationSchema}
            initialValues={initialValues}
            open={showAddModal}
            loading={createLoading}
            success={createSuccess}
            fields={fields}
            width={524}
          />
          <ConfirmModal
            open={showConfirmDeleteModal}
            title="Подтвердите удаление экспедиции"
            content={
              <p>
                Вы уверены, что хотите удалить&nbsp;
                {selectedExpedition?.name ? (
                  <>
                    экспедицию <strong>{selectedExpedition?.name}</strong>
                  </>
                ) : (
                  'выбранную экспедицию'
                )}
                ?
              </p>
            }
            submitButtonText="Удалить экспедицию"
            cancelButtonText="Отменить"
            onSubmit={() => handleDeleteExpedition(selectedExpedition?.id as number)}
            onCancel={() => setShowConfirmDeleteModal((prevState) => !prevState)}
            loading={deleteLoading}
            success={deleteSuccess}
            type="danger"
          />
        </>
      ) : null}
      <ExpeditionsTable
        data={preparedExpedition}
        loading={loading}
        isEditable={isEditable}
        error={error}
        onNavigate={handleNavigate}
        onDelete={handleDelete}
      />
    </div>
  );
};
