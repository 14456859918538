import { SHIPS_BASES_LAYER } from '@/constants';
import { useAppSelector } from '@/state';
import { useGetShipsBasesQuery } from '@/state/api';
import { MarkerClusterGroup as MarkerClusterGroupType } from 'leaflet';
import { MarkerClusterGroup, createClusterCustomIcon } from '@/utils';
import React, { FC, useRef } from 'react';
import { ShipsBaseMarker } from '../../elements/ships-base-marker';
import styles from './canvas-layer.module.scss';
import { ShipBase } from '@/types';

// eslint-disable-next-line
const MarkerClusterGroupElement = MarkerClusterGroup as any;

export const ShipsBasesLayer: FC<{ defaultShipBases?: ShipBase[] }> = ({ defaultShipBases }) => {
  const selectedLayers = useAppSelector((state) => state.map.selectedLayers);
  const { data: shipsBases } = useGetShipsBasesQuery();
  const clusterGroupRef = useRef<MarkerClusterGroupType | null>(null);
  const preparedShipBases = defaultShipBases ?? shipsBases;

  return (
    <MarkerClusterGroupElement
      ref={clusterGroupRef}
      maxClusterRadius={20}
      animate={true}
      iconCreateFunction={(cluster: MarkerClusterGroupType) =>
        createClusterCustomIcon(cluster, styles)
      }
      spiderLegPolylineOptions={{ opacity: 0 }}
      showCoverageOnHover={false}
    >
      {selectedLayers.includes(SHIPS_BASES_LAYER)
        ? preparedShipBases?.map((item, idx) => {
            return <ShipsBaseMarker item={item} key={idx} />;
          })
        : false}
    </MarkerClusterGroupElement>
  );
};
