import React, { FC } from 'react';
import styles from './conventions-panel.module.scss';
import { areas, objects } from './conventions-panel-text';
import { ClassificationItem, Modal } from '@/components';
import { ConventionsPanelDesignationsAreaItem } from './conventions-panel-designations-area-item';
import { useNavigate } from 'react-router';
import { MAP } from '@/constants';

export const ConventionsPanel: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const handleCloseModal = () => {
    navigate(MAP);
  };

  return (
    <Modal
      centered
      width={1654}
      mask={true}
      wrapClassName={styles.wrapper}
      onClose={() => handleCloseModal()}
      title="Условные обозначения"
    >
      <div className={styles.body}>
        <div className={styles.col}>
          <h5 className="mb-3">Классификация объектов</h5>
          <div>
            <p>
              Объекты на карте делятся на исследованные и неисследованные. Каждой группе
              соответствует свой набор символов, иконок.
            </p>
            <p>Исследованные объекты делятся по категории:</p>
            <ul>
              <li>объекты, имеющие культурную ценность;</li>
              <li>технические объекты;</li>
              <li>объекты военно-исторического наследия;</li>
              <li>потенциально опасные объекты (ВОП)</li>
            </ul>
            <p>
              Для исследованных объектов используются иконки большего размера, цвет заливки более
              насыщенный, чем для неисследованных объектов. <br />
              Неисследованные объекты обозначаются иконкой со знаком вопроса меньшего размера с
              цветом заливки менее насыщенным, чем для исследованных объектов.
            </p>
            <p>Ниже представлено отображение объектов в зависимости от типа объекта:</p>
          </div>
          <div className={styles.circles}>
            {objects.map(({ type, description }) => (
              <div className={styles['circle-wrapper']} key={description}>
                <ClassificationItem type={type} />
                <p>{description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.col}>
          <h5 className="mb-3">Обозначение районов, акваторий, секторов</h5>
          <p>
            Области исследований:
            <span> районы, акватории, сектора </span>
            на карте обозначаются зонами с заливкой цветом, с обводкой (сплошной, пунктирной) в
            зависимости от того, к какой области исследований эта зона относится.
          </p>
          <p>
            Более насыщенным цветом зоны и ободка, сплошной линией обозначаются зоны, находящиеся на
            верхнем уровне, для зон, находящихся уровнем ниже, заливка менее насыщенная, обводка
            пунктирной линией.
          </p>
          <p>Ниже приведена градация зон в зависимости от того, на каком уровне они находятся.</p>
          <div className={styles.areas}>
            {areas.map((item) => (
              <ConventionsPanelDesignationsAreaItem key={item.title} area={item} />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};
