import React, { FC } from 'react';
import { Modal } from 'antd';
import styles from './modal.module.scss';
import { ModalPropsTypes } from '@/components/common/modal/modal.types';
import { ModalPosition } from '@/constants/enums';
import { CloseButton, ErrorBoundary } from '@/components';

export const NewModal: FC<ModalPropsTypes> = ({
  children,
  wrapClassName = '',
  centered,
  position = ModalPosition.right,
  open = true,
  mask = false,
  footer = null,
  showCloseIcon = true,
  closeIcon,
  width,
  onClose,
  title,
  additionalTitle,
  ...rest
}): JSX.Element => {
  const customStyles = [
    styles.wrapper,
    wrapClassName,
    centered ? '' : styles[position],
    mask ? styles.mask : styles['disable-mask'],
  ].join(' ');

  const preparedTitle = (
    <div className={styles['title-wrapper']}>
      <div className={styles.title}>
        {typeof title === 'string' ? <h5>{title}</h5> : title}
        {showCloseIcon &&
          (closeIcon ? (
            closeIcon
          ) : (
            <CloseButton className={styles['close-button']} onClick={() => onClose && onClose()} />
          ))}
      </div>
      {additionalTitle ? additionalTitle : null}
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <ErrorBoundary>
        <Modal
          wrapClassName={customStyles}
          onCancel={() => onClose && onClose()}
          open={open}
          mask={mask}
          width={width}
          footer={footer}
          centered={centered}
          closeIcon={<></>}
          title={preparedTitle}
          {...rest}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </Modal>
      </ErrorBoundary>
    </div>
  );
};
