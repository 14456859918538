import React, { FC } from 'react';
import { menuData, ObjectCardItem } from '@/components';
import { Link } from 'react-router-dom';
import { LINK_TO_EXPEDITION_PANEL } from '@/constants';
import { BaseInfoCardPropsType, FieldType } from '../object-card-panel.types';
import { IObject } from '@/types';
import { prepareStatusValue, prepareTypeValue } from '@/utils';
import { FormFieldsTypes } from '@/constants/enums';
import dayjs from 'dayjs';

export const BaseObjectInfoSection: FC<BaseInfoCardPropsType> = ({
  onSubmit,
  item,
  isShowingCoords,
  objectTypes,
  expeditionData,
  toolsData,
  success,
  isEditable,
  isShowingExpedition,
  loading,
  defaultEdit,
  isDirty,
  defaultItem,
}) => {
  const statusData = [
    {
      label: 'Не выбран',
      value: 0,
    },
    {
      label: 'Не подтвержден',
      value: 1,
    },
    {
      label: 'Подтвержден',
      value: 2,
    },
    {
      label: 'Завершен',
      value: 3,
    },
    {
      label: 'Идентифицирован',
      value: 4,
    },
  ];

  const preparedExpeditionRow = (item?: IObject) =>
    item?.expeditions?.map(({ expedition_id, name }) => ({
      key: expedition_id,
      name: name,
      link: <Link to={LINK_TO_EXPEDITION_PANEL(expedition_id)}>{name}</Link>,
    }));

  const fields: FieldType[] = [
    {
      label: 'Наименование',
      name: 'name',
    },
    {
      label: 'Идентификатор',
      name: 'identifier',
    },
    {
      label: 'Экспедиции',
      name: 'expeditions',
      type: FormFieldsTypes.multiselect,
      preparedValue: preparedExpeditionRow(item),
      data: expeditionData,
    },
    {
      label: 'Дата обнаружения',
      name: 'date',
      type: FormFieldsTypes.datePicker,
      preparedValue: item?.date
        ? dayjs(item.date).isValid()
          ? dayjs(item.date).format('DD.MM.YYYY')
          : item.date
        : '',
    },
    {
      label: 'Дата последней съемки',
      name: 'updated_date',
      type: FormFieldsTypes.datePicker,
      preparedValue: item?.updated_date
        ? dayjs(item.updated_date).isValid()
          ? dayjs(item.updated_date).format('DD.MM.YYYY')
          : item.updated_date
        : '',
    },
    {
      label: 'Тип',
      name: 'type',
      type: FormFieldsTypes.select,
      data: objectTypes,
      preparedValue: prepareTypeValue(item?.type),
    },
    {
      label: 'Координаты объекта',
      name: 'coordinates',
      type: FormFieldsTypes.coordsSelect,
    },
    {
      label: 'Глубина носа, м',
      name: 'deep',
    },
    {
      label: 'Глубина кормы, м',
      name: 'deep2',
    },
    {
      label: 'Средство обнаружения',
      name: 'detection_tools',
      type: FormFieldsTypes.select,
      data: toolsData,
      preparedValue: toolsData?.find(
        ({ value }) => value && item?.detection_tools && +value === +item?.detection_tools,
      )?.label,
    },
    {
      label: 'Характеристика грунта',
      name: 'soil_characteristics',
    },
    {
      label: 'Классификация',
      name: 'classification',
    },
    {
      label: 'Выполненные работы',
      name: 'work',
    },
    {
      label: 'Статус',
      name: 'status',
      preparedValue: prepareStatusValue(item?.status),
      type: FormFieldsTypes.select,
      data: statusData,
    },
    {
      label: 'Район',
      name: 'area',
    },
    {
      label: 'Положение',
      name: 'position',
    },
    {
      label: 'Средства обследования',
      name: 'survey_tools',
    },
  ];

  const prepareFieldsByPermissions = (field: FieldType) => {
    if (field.name === 'coordinates' && !isShowingCoords) {
      return false;
    }
    if (field.name === 'expeditions' && !isShowingExpedition) {
      return false;
    }
    return true;
  };

  const preparedFields = fields.filter(prepareFieldsByPermissions);

  return (
    <ObjectCardItem
      title="Основная информация"
      type="text"
      fields={preparedFields}
      defaultEdit={defaultEdit}
      isDirty={isDirty}
      isEditable={isEditable}
      id={menuData[0].link}
      onSubmit={() => onSubmit()}
      success={success}
      loading={loading}
      defaultItem={defaultItem}
    />
  );
};
