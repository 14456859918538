import React, { FC } from 'react';
import styles from './column-section.module.scss';
import { ObjectCardDefaultSectionPropsType } from './column-section.types';
import { ObjectCardField } from '@/components';

export const ObjectCardColumnSection: FC<ObjectCardDefaultSectionPropsType> = ({
  fields,
  isEdit = false,
  isEditable = false,
}) => {
  return (
    <div className={styles.wrapper}>
      {fields?.map(({ label, name, placeholder, ...rest }) => (
        <div className={styles.item} key={`column-section-${String(name)}`}>
          {label && <span className={styles.label}>{label}</span>}
          <div className={styles.divider} />
          <ObjectCardField
            name={name}
            placeholder={
              placeholder
                ? placeholder
                : isEditable
                ? 'Редактируйте раздел для заполнения'
                : 'Нет информации'
            }
            styles={styles}
            isEdit={isEdit}
            {...rest}
          />
        </div>
      ))}
    </div>
  );
};
