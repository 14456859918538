import React, { FC } from 'react';
import styles from './table-modal-with-arrow.module.scss';
import { TableModalWithArrowTitlePropsType } from './table-modal-with-arrow.types';
import { Popover } from 'antd';

export const TableModalWithArrowTitle: FC<TableModalWithArrowTitlePropsType> = ({
  index,
  length,
  name,
}) => {
  return (
    <div className={styles.title}>
      <span>
        Файлов{' '}
        <strong>
          {index}/{length}
        </strong>
      </span>
      <Popover placement="bottom" overlayClassName={styles.tooltip} content={name}>
        <span className={styles['title-file']}>{name}</span>
      </Popover>
    </div>
  );
};
