import React, { FC, useEffect, useState } from 'react';
import { ExpeditionCardSection, SectionWithSelectPropsType } from '../index';
import {
  ConfirmModal,
  defaultDataPickerProps,
  defaultSearchProps,
  DropdownFieldDataType,
  FieldType,
  Icon,
  PanelTableButton,
  Table,
  TableCell,
  TableModal,
} from '@/components';
import {
  handleDateSort,
  handleSortNumber,
  handleSortString,
  openErrorNotify,
  openInfoNotify,
} from '@/utils';
import { ColumnsType } from 'antd/lib/table';
import { ExpeditionMember, Member } from '@/types';
import styles from '../section.module.scss';
import {
  useCreateExpeditionMemberMutation,
  useDeleteExpeditionMemberMutation,
  useEditExpeditionMemberMutation,
  useGetMembersQuery,
} from '@/state/api';
import { FormikValues } from 'formik';
import { findIndex } from 'lodash';
import { InputTypes } from '@/constants/enums';

export const ParticipantsSection: FC<SectionWithSelectPropsType> = ({
  isEditable,
  item,
  selectData,
  ...rest
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const preparedData: Member[] | undefined = item?.members.map((item) => ({ ...item.info }));

  const [selectedItem, setSelectedItem] = useState<ExpeditionMember>();

  const [
    createMember,
    {
      isLoading: createLoading,
      isError: createError,
      isSuccess: createSuccess,
      reset: createReset,
    },
  ] = useCreateExpeditionMemberMutation();
  const [
    editMember,
    { isLoading: editLoading, isError: editError, isSuccess: editSuccess, reset: editReset },
  ] = useEditExpeditionMemberMutation();
  const [
    deleteMember,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
      reset: deleteReset,
    },
  ] = useDeleteExpeditionMemberMutation();
  const { isLoading, isError } = useGetMembersQuery(item?.id ? { expId: item?.id } : undefined);

  const handleSetSelectedItem = (id: number) => {
    setSelectedItem(item?.members?.find((item) => item.member_id === id));
  };

  const handleDelete = (expedition_id: number, member_id: number) => {
    deleteMember({ expedition_id, member_id });
  };

  const handleEdit = (value: FormikValues) => {
    const preparedValue = {
      member_id: value.member_id as number,
      value: value.purpose as string,
      expedition_id: item?.id,
    };
    editMember(preparedValue);
  };

  const handleAdd = (value: FormikValues) => {
    const preparedValue = {
      ...value,
      expedition_id: item?.id,
    };
    createMember(preparedValue);
  };

  const toggleDelete = (id: number) => {
    handleSetSelectedItem(id);
    setShowDeleteModal((prevState) => !prevState);
  };

  const toggleEdit = (id: number) => {
    handleSetSelectedItem(id);
    setShowEditModal((prevState) => !prevState);
  };

  const toggleAdd = () => {
    setShowAddModal((prevState) => !prevState);
  };

  const editableColumns: ColumnsType<Member> = [
    {
      dataIndex: 'id',
      title: '№',
      align: 'left',
      fixed: 'left',
      key: 'index',
      width: 62,
      sorter: (first, second) =>
        handleSortNumber(findIndex(preparedData, first), findIndex(preparedData, second)),
      render: (value: string, row) => <TableCell value={findIndex(preparedData, row) + 1} />,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 62,
      sorter: (first, second) => handleSortNumber(first.id, second.id),
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      fixed: 'left',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleEdit(id)}
          tooltipTitle="Редактировать участника экспедиции"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      fixed: 'left',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleDelete(id)}
          tooltipTitle="Удалить участника из экспедиции"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'ФИО',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second, sortOrder) => handleSortString(first.name, second.name, sortOrder),
      ...defaultSearchProps('name'),
    },
    {
      title: 'Дата рождения',
      dataIndex: 'birthday',
      key: 'birthday',
      align: 'left',
      width: 180,
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second, sortOrder) =>
        handleDateSort(first.birthday ?? null, second.birthday ?? null, sortOrder),
      ...defaultDataPickerProps<Member>('birthday'),
    },
    {
      title: 'Должность',
      dataIndex: 'position',
      key: 'position',
      align: 'left',
      width: 180,
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second, sortOrder) =>
        handleSortString(first.position, second.position, sortOrder),
      ...defaultSearchProps('position'),
    },
    {
      title: 'Функция в экспедиции',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 180,
      render: (id: number) => (
        <TableCell value={item?.members.find((item) => item.member_id === id)?.purpose ?? ''} />
      ),
    },
  ];

  const readOnlyColumn: ColumnsType<Member> = editableColumns.filter((item) => item.key !== 'icon');

  const initialValues = {
    member_id: '',
  };

  const creatableFields: FieldType[] = [
    {
      label: 'Участник',
      name: 'member_id',
      type: 'dropdown',
      loading: isLoading,
      error: isError,
      data: selectData as DropdownFieldDataType[],
    },
    {
      label: 'Функция',
      name: 'purpose',
      placeholder: 'Напишите функцию участника',
      type: InputTypes.text,
    },
  ];

  const editableFields: FieldType[] = creatableFields.filter((item) => item.name !== 'member_id');

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify('Изменения сохранены', 'Добавление участника экспедиции успешно выполнено');
      createReset();
    }
    if (createError) {
      openErrorNotify('Произошла ошибка', 'При добавлении участника экспедиции произошла ошибка');
      createReset();
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        'Редактирование участника экспедиции успешно выполнено',
      );
      editReset();
    }
    if (editError) {
      openErrorNotify(
        'Произошла ошибка',
        'При редактировании участника экспедиции произошла ошибка',
      );
      editReset();
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify('Изменения сохранены', 'Удаление участника экспедиции успешно выполнено');
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении участника экспедиции произошла ошибка');
      deleteReset();
    }
  }, [deleteSuccess, deleteError]);

  return (
    <ExpeditionCardSection
      {...rest}
      buttonText="Добавить участника"
      onClickAdd={toggleAdd}
      isEditable={isEditable}
    >
      <>
        {item?.members && item?.members?.length > 0 && (
          <Table
            withSelection={false}
            dataSource={preparedData}
            columns={isEditable ? editableColumns : readOnlyColumn}
            className={styles.table}
            noDataClassName={styles['no-data']}
            scroll={{ x: 1225, y: 300 }}
            withPagination={false}
          />
        )}
        <TableModal
          onSubmit={(value) => handleAdd(value)}
          onClose={() => setShowAddModal(false)}
          initialValues={initialValues}
          loading={createLoading}
          success={createSuccess}
          buttonText="Добавить"
          open={showAddModal}
          fields={creatableFields}
          width={563}
          title="Добавление участника экспедиции"
        />
        <TableModal
          onSubmit={(value) => handleEdit(value)}
          onClose={() => setShowEditModal(false)}
          initialValues={selectedItem as ExpeditionMember}
          loading={editLoading}
          success={editSuccess}
          buttonText="Изменить"
          open={showEditModal}
          fields={editableFields}
          width={563}
          title="Изменение участника экспедиции"
          additionalTitle={
            <div className={styles['additional-title']}>{selectedItem?.info.name}</div>
          }
        />
        <ConfirmModal
          open={showDeleteModal}
          title="Подтвердите удаление участника экспедиции"
          content={
            <p>
              Вы уверены, что хотите удалить&nbsp;
              {selectedItem?.info.name ? (
                <strong>{selectedItem?.info.name}</strong>
              ) : (
                'выбранного участника экспедиции'
              )}
              ?
            </p>
          }
          submitButtonText="Удалить"
          cancelButtonText="отменить"
          onSubmit={() =>
            selectedItem && handleDelete(selectedItem?.expedition_id, selectedItem?.member_id)
          }
          onCancel={() => setShowDeleteModal(false)}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
      </>
    </ExpeditionCardSection>
  );
};
