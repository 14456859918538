import React, { FC } from 'react';
import { defaultSearchProps, Icon, PanelTableButton, Table, TableCell } from '@/components';
import { ColumnsType } from 'antd/lib/table';
import { Member } from '@/types';
import { handleDateSort, handleSortNumber, handleSortString } from '@/utils';
import { MemberTablePropsType } from './member-table.types';
import { defaultDataPickerProps } from '@/components/table-components/table-utils';

export const MembersTable: FC<MemberTablePropsType> = ({
  data,
  loading,
  isEditable,
  error,
  onEdit,
  onDelete,
}): JSX.Element => {
  const columns: ColumnsType<Member> = [
    {
      title: 'ID',
      dataIndex: 'id',
      fixed: 'left',
      key: 'id',
      align: 'left',
      width: 60,
      sorter: (first, second) => handleSortNumber(first?.id, second?.id),
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      dataIndex: 'id',
      key: 'editable',
      fixed: 'left',
      width: 40,
      align: 'center',
      render: (value: number) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Изменить участника"
          icon="outline-pencil"
          onClick={() => onEdit(value)}
          size={12}
          tooltipPlacement={'bottom'}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      dataIndex: 'id',
      fixed: 'left',
      key: 'editable',
      width: 40,
      align: 'center',
      render: (value) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Удалить участника"
          icon="outline-trash"
          onClick={() => onDelete(value)}
          size={12}
          tooltipPlacement={'bottom'}
        />
      ),
    },
    {
      title: 'ФИО',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 500,
      render: (value: string) => <TableCell value={value} maxWidth={350} />,
      sorter: (first, second) => handleSortString(first?.name, second?.name),
      ...defaultSearchProps('name'),
    },
    {
      title: 'Дата рождения',
      dataIndex: 'birthday',
      key: 'birthday',
      align: 'left',
      width: 350,
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second, sortOrder) =>
        handleDateSort(first?.birthday, second?.birthday, sortOrder),
      ...defaultDataPickerProps<Member>('birthday'),
    },
    {
      title: 'Должность',
      dataIndex: 'position',
      key: 'position',
      align: 'left',
      width: 500,
      render: (value: string) => <TableCell value={value} maxWidth={400} />,
      sorter: (first, second) => handleSortString(first?.position, second?.position),
      ...defaultSearchProps('position'),
    },
  ];

  const preparedColumns = isEditable ? columns : columns.filter((item) => item.key !== 'editable');

  return (
    <Table
      dataSource={data}
      columns={preparedColumns}
      loading={loading}
      error={error}
      scroll={{ x: 1000, y: 500 }}
      withSelection={false}
    />
  );
};
