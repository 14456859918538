import React, { FC, useEffect, useState } from 'react';
import styles from '../../default-tab.module.scss';
import { useAppSelector } from '@/state';
import {
  useCreateShipBaseMutation,
  useDeleteShipBaseMutation,
  useEditShipBaseMutation,
  useGetShipsBasesQuery,
} from '@/state/api';
import {
  BlackTooltip,
  ConfirmModal,
  FieldType,
  Icon,
  PanelTabButton,
  ShipsBasesTable,
  TableModal,
} from '@/components';
import {
  ComponentSize,
  CoordsTypes,
  InputTypes,
  PanelTabButtonTypes,
  Permissions,
} from '@/constants/enums';
import { DefaultResponse, NewShipBase, ShipBase } from '@/types';
import {
  convertToDecimalCord,
  decimalCoordsValidator,
  graduatedCoordsValidator,
  openErrorNotify,
  openInfoNotify,
  prepareCoordsFieldToString,
} from '@/utils';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useMap } from '@/context';
import { MAP, SELECT_SHIP_BASE_COORDS_PAGE, SHIPS_BASES_LAYER } from '@/constants';
import {
  clearFormWithCoordsMarker,
  setFormWithCoordsMarkerValue,
  setSelectedLayer,
} from '@/state/slice';
import { FormikValues } from 'formik';
import * as Yup from 'yup';
import { isEqual, words } from 'lodash';

export const ShipsBasesTab: FC = (): JSX.Element => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedShipBase, setSelectedShipBase] = useState<ShipBase>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { map } = useMap();

  const { data: shipsBases, isLoading, isError, error } = useGetShipsBasesQuery();
  const [
    createShipBase,
    {
      isError: createError,
      isLoading: createLoading,
      isSuccess: createSuccess,
      reset: createReset,
      error: createResponse,
    },
  ] = useCreateShipBaseMutation();
  const [
    editShipBase,
    {
      isError: editError,
      isLoading: editLoading,
      isSuccess: editSuccess,
      reset: editReset,
      error: editResponse,
    },
  ] = useEditShipBaseMutation();
  const [
    deleteShipBase,
    {
      isSuccess: deleteSuccess,
      isError: deleteError,
      isLoading: deleteLoading,
      error: deleteResponse,
      reset: deleteReset,
    },
  ] = useDeleteShipBaseMutation();

  const persistedFormData = useAppSelector(
    (state) => state.toolsPanels.objectCreationPanel.persistedForm,
  );
  const preparedData = shipsBases || [];
  const cordType = useAppSelector((state) => state.map.coordsType);
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  const selectedItem = shipsBases?.find(
    (item) => !!selectedShipBase?.id && +item.id === +selectedShipBase?.id,
  );
  const isEditable = userPermissions?.includes(Permissions.expeditionsDataManagment);

  const initialValues = {
    name: '',
    coords: '',
  };

  const preparedSelectedItem = {
    ...selectedItem,
    name: selectedItem?.name,
    coords:
      selectedItem?.coordinates[0] && selectedItem?.coordinates[1]
        ? cordType === CoordsTypes.decimal
          ? `${selectedItem?.coordinates[0]}, ${selectedItem?.coordinates[1]}`
          : `${convertToDecimalCord(selectedItem?.coordinates[0]?.toString())}, ` +
            `${convertToDecimalCord(selectedItem?.coordinates[1]?.toString())}`
        : '',
  };

  const handleToggleSelectCord = (values?: FormikValues) => {
    values && dispatch(setFormWithCoordsMarkerValue(values));
    navigate(`/${SELECT_SHIP_BASE_COORDS_PAGE}${values?.id ? `/${String(values?.id)}` : ''}`);
  };

  const fields: FieldType[] = [
    {
      label: 'Наименование базы',
      name: 'name',
      placeholder: 'Наименование базы',
      type: InputTypes.text,
      required: true,
    },
    {
      name: 'coords',
      label: 'Координаты',
      type: 'cordSelector',
      className: styles['coords-field'],
      onIconClick: handleToggleSelectCord,
      required: true,
    },
  ];

  const errorText = (label: string) =>
    `${label} координата указанно не верно. Необходимый формат: XX.XXXXXX YY.YYYYYY или HH°MM'SS.s" HH°MM'SS.s"`;

  const validationSchema = Yup.object({
    name: Yup.string().required('Пожалуйста, введите наименование базы судов'),
    coords: Yup.string()
      .required('Пожалуйста, введите координаты базы судов')
      .test('', errorText('Первая'), (value): boolean => {
        const lat = words(value, /[^, ]+/g)[0] || '';

        return graduatedCoordsValidator(lat, true) || decimalCoordsValidator(lat);
      })
      .test(
        '',
        'Пожалуйста, введите вторую координату объекта',
        (value): boolean => !!words(value, /[^, ]+/g)[1],
      )
      .test('', errorText('Вторая'), (value): boolean => {
        const lng = words(value, /[^, ]+/g)[1] || '';

        return graduatedCoordsValidator(lng) || decimalCoordsValidator(lng);
      }),
  });

  const handleCreate = (values: NewShipBase) => {
    const preparedData = {
      ...values,
      coords: prepareCoordsFieldToString(values, CoordsTypes.decimal, 'coords'),
    };
    createShipBase(preparedData);
  };

  const handleEdit = (values: NewShipBase) => {
    const preparedData = {
      ...values,
      coords: prepareCoordsFieldToString(values, CoordsTypes.decimal, 'coords'),
    };
    editShipBase(preparedData);
  };

  const handleDeleteShipBase = (id: number) => {
    deleteShipBase({ id });
  };

  const toggleAddShipBase = () => {
    setShowCreateModal((prevState) => !prevState);
    createReset();
  };

  const toggleEditShipBase = (row?: ShipBase) => {
    setSelectedShipBase(row);
    setShowEditModal((prevState) => !prevState);
    editReset();
  };

  const toggleDeleteShipBase = (id?: number) => {
    const item = preparedData?.filter((item) => item.id === id)[0];
    setSelectedShipBase(item);
    setShowDeleteModal((prevState) => !prevState);
    deleteReset();
  };

  const handleFlyToShipsBase = (id: string | number): void => {
    const { coordinates } = preparedData?.filter((item) => item.id === id)[0];
    navigate(MAP);
    map?.flyTo(coordinates, 16);
    dispatch(setSelectedLayer(SHIPS_BASES_LAYER));
  };

  useEffect(() => {
    if (persistedFormData) {
      !!persistedFormData?.id ? setShowEditModal(true) : setShowCreateModal(true);
      const currentItem = shipsBases?.find((item) => +item?.id === +persistedFormData?.id);
      currentItem && setSelectedShipBase(currentItem);
    }
  }, [persistedFormData]);

  useEffect(() => {
    if (isError) {
      const response = error as DefaultResponse;
      openErrorNotify(
        'Произошла ошибка',
        `При загрузке списка баз судов произошла ошибка ${response?.data?.message ?? ''}`,
      );
    }
  }, [isError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Удаление базы судов',
        <>
          База судов <b>{selectedShipBase?.name}</b> была удалена
        </>,
      );
    }
    if (deleteError) {
      const response = deleteResponse as DefaultResponse;
      openErrorNotify(
        'Произошла ошибка',
        `При удалении базы судов произошла ошибка ${response?.data?.message ?? ''}`,
      );
    }
  }, [deleteError, deleteSuccess]);

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify('Изменения сохранены', 'База судов успешно создана');
    }
    if (createError) {
      const response = createResponse as DefaultResponse;
      openErrorNotify(
        'Произошла ошибка',
        `При создании базы судов произошла ошибка ${response?.data?.message ?? ''}`,
      );
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        `База судов${selectedItem?.name ? ': ' + selectedItem?.name : ''} успешно изменена`,
      );
    }
    if (editError) {
      const response = editResponse as DefaultResponse;
      openErrorNotify(
        'Произошла ошибка',
        `При редактировании базы судов${
          selectedItem?.name ? ': ' + selectedItem?.name : ''
        } произошла ошибка. ${response?.data?.message ?? ''}`,
      );
    }
  }, [editSuccess, editError]);

  return (
    <div className={styles.wrapper}>
      {!isError && !isLoading && (
        <div className={styles.header}>
          <BlackTooltip title={'Перечень баз судов'} placement={'bottomLeft'}>
            <PanelTabButton
              className={styles['button-panel-help']}
              onClick={() => {}}
              type={PanelTabButtonTypes.icon}
              size={ComponentSize.small}
            >
              <Icon icon="outline-question-circle" size={14} />
            </PanelTabButton>
          </BlackTooltip>
          {isEditable ? (
            <PanelTabButton
              className={styles.button}
              disabled={isLoading || isError}
              type={PanelTabButtonTypes.roundSecondary}
              size={ComponentSize.small}
              onClick={() => toggleAddShipBase()}
            >
              Создать новую базу судов
            </PanelTabButton>
          ) : null}
        </div>
      )}
      {isEditable ? (
        <>
          <TableModal
            initialFormDirty={!isEqual(persistedFormData, initialValues)}
            onSubmit={(value) => handleCreate(value as NewShipBase)}
            onClose={() => {
              dispatch(clearFormWithCoordsMarker());
              toggleAddShipBase();
            }}
            buttonText="Добавить"
            title="Создание новой базы судов"
            validationSchema={validationSchema}
            initialValues={persistedFormData ?? initialValues}
            open={showCreateModal}
            destroyOnClose={false}
            loading={createLoading}
            success={createSuccess}
            fields={fields}
            width={476}
          />
          <TableModal
            onSubmit={(value) => handleEdit(value as NewShipBase)}
            onClose={() => {
              dispatch(clearFormWithCoordsMarker());
              toggleEditShipBase();
            }}
            validationSchema={validationSchema}
            initialValues={persistedFormData ? persistedFormData : preparedSelectedItem}
            loading={editLoading}
            success={editSuccess}
            buttonText="Изменить"
            open={showEditModal}
            destroyOnClose={false}
            fields={fields}
            width={476}
            initialFormDirty={!isEqual(persistedFormData, preparedSelectedItem)}
            title={`Изменение базы судов${selectedItem?.name ? ': ' + selectedItem?.name : ''}`}
          />
          <ConfirmModal
            open={showDeleteModal}
            title="Подтвердите удаление базы судов"
            content={
              <p>
                Вы уверены, что хотите удалить&nbsp;
                {selectedShipBase?.name ? (
                  <>
                    базу судов <strong>{selectedShipBase?.name}</strong>
                  </>
                ) : (
                  'выбранную базу судов'
                )}
                ?
              </p>
            }
            submitButtonText="Удалить"
            cancelButtonText="отменить"
            onSubmit={() => selectedShipBase && handleDeleteShipBase(selectedShipBase.id)}
            onCancel={() => toggleDeleteShipBase()}
            loading={deleteLoading}
            success={deleteSuccess}
            type="danger"
          />
        </>
      ) : null}
      <ShipsBasesTable
        data={preparedData}
        loading={isLoading}
        error={isError}
        isEditable={isEditable}
        onClickEditBtn={toggleEditShipBase}
        onClickDeleteBtn={toggleDeleteShipBase}
        onClickFlyToBtn={handleFlyToShipsBase}
      />
    </div>
  );
};
