import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

interface TimerProps {
  to: string;
}

export const useTimer = ({ to }: TimerProps) => {
  const [ellapsed, setEllapsed] = useState({ minutes: 0, hours: 0, seconds: 0 });

  const isExpired = useMemo(() => {
    const isExpired = dayjs().isSameOrAfter(dayjs(to));

    return isExpired;
  }, [to, ellapsed]);

  const calculateElapsedTime = () => {
    const currentTime = new Date().getTime();
    const timeDifference = Math.max(new Date(to).getTime() - currentTime, 0);
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    setEllapsed({ hours, minutes, seconds });
  };

  useEffect(() => {
    calculateElapsedTime();
    const timerInterval = setInterval(calculateElapsedTime, 1000);

    return () => clearInterval(timerInterval);
  }, [to]);

  return { ...ellapsed, isExpired: isExpired };
};
