import React, { FC } from 'react';
import { UploadButtonWrapperPropsType } from './upload-button-wrapper.types';
import { Upload } from 'antd';
import styles from './upload-button-wrapper.module.scss';
import { FileTypes } from '@/constants/enums';
import { ErrorMessage } from '@/components';

export const UploadButtonWrapper: FC<UploadButtonWrapperPropsType> = ({
  children,
  className = '',
  onClick,
  customRequest,
  onDelete,
  fileList,
  fileType = FileTypes.all,
}) => {
  const preparedStyles = [styles.wrapper, className].join(' ');

  return (
    <Upload
      multiple
      accept={fileType}
      className={preparedStyles}
      listType="picture"
      fileList={fileList}
      onChange={(event) => onClick && onClick(event)}
      onRemove={(item) => onDelete && onDelete(item)}
      progress={{ strokeWidth: 0 }}
      customRequest={(options) => customRequest && customRequest(options)}
      itemRender={(item, file) => (
        <div className={styles.item} key={file?.name}>
          {item}
          <ErrorMessage message={(file?.error?.message as string) || ''} className={styles.error} />
        </div>
      )}
    >
      {children}
    </Upload>
  );
};
