import { PreloadedState, configureStore } from '@reduxjs/toolkit';
import { rootReducer } from '../../reducer';
import loggerMiddleware from 'redux-logger';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { api } from '@/state/api';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const devMiddlewares = [loggerMiddleware, api.middleware];

const productionMiddlewares = [api.middleware];

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = (preloadedState?: PreloadedState<RootStateType>) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState: preloadedState,
    devTools: process.env.MODE === 'development',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(process.env.MODE === 'development' ? devMiddlewares : productionMiddlewares),
  });
};

export type StoreType = ReturnType<typeof setupStore>;
export type RootStateType = ReturnType<typeof rootReducer>;
export const store = setupStore();
export const persistor = persistStore(store);
