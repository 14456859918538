import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/state';
import styles from './ruler-panel.module.scss';

export const RulerPanel = () => {
  const isOpen = useAppSelector((state) => {
    return state.panels.rulerPanelIsOpen;
  });

  const multiplier = 80;
  /* colormap:
  0 - #ff0000
  -5 - #fc7f00
  -10 - #f9ff00
  -20 - #7dd900
  -30 - #867f5e
  -40 - #ea00ff
  -50 - #7d73ed
  -60 - #5d91f2
  -80 - #3fb1f6
  -120 - #20cffb
  -200 - #00f0ff
  */
  const deeps = [
    { value: -200, fromColor: '#20cffb', toColor: '#00f0ff' },
    { value: -120, fromColor: '#3fb1f6', toColor: '#20cffb' },
    { value: -80, fromColor: '#5d91f2', toColor: '#3fb1f6' },
    { value: -60, fromColor: '#7d73ed', toColor: '#5d91f2' },
    { value: -50, fromColor: '#ea00ff', toColor: '#7d73ed' },
    { value: -40, fromColor: '#867f5e', toColor: '#ea00ff' },
    { value: -30, fromColor: '#7dd900', toColor: '#867f5e' },
    { value: -20, fromColor: '#f9ff00', toColor: '#7dd900' },
    { value: -10, fromColor: '#fc7f00', toColor: '#f9ff00' },
    { value: -5, fromColor: '#ff0000', toColor: '#fc7f00' },
  ];
  const [width, setWidth] = useState(window.innerWidth - multiplier);

  useEffect(() => {
    setWidth(window.innerWidth - multiplier);
  }, [window.innerWidth]);

  return (
    <div className={styles.control}>
      {isOpen && (
        <div className={styles.wrapper} style={{ width: width }}>
          {deeps.map(({ value, fromColor, toColor }) => {
            return (
              <div className={styles.block}>
                <div className={styles.text}>{value}</div>
                <div
                  style={{
                    width: '100%',
                    background: `linear-gradient(to left, ${fromColor}, ${toColor})`,
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
