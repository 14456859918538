import { Layout } from '@/components/layout';
import {
  BookmarkPanel,
  ConventionsPanel,
  CoordinateConverterPanel,
  ExpeditionPanel,
  ExpeditionTabs,
  GeoPanel,
  LayersPanel,
  LayersTabs,
  LiveCoordinatePanel,
  Logo,
  MapTablePanelHeader,
  MapView,
  MediaFilesPanel,
  MetaGalsPanel,
  MetaLogPanel,
  ObjectCardPanel,
  ObjectCreationPanel,
  ObjectFormularPanel,
  ProfilePanel,
  RoutePanel,
  RulerPanel,
  SearchPanel,
  SettingTabs,
  SidebarButton,
  SidebarCollapseButton,
  TablePanel,
  View3D,
  ZCoordsPanel,
  ZoomControl,
} from '@/components';
import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  BOOKMARK,
  CORDS_CONVERTER,
  DISTANCE,
  EXPEDITION,
  EXPEDITION_PANEL,
  EXPEDITION_PANEL_WITHOUT_STATUS,
  EXPEDITION_TABLE,
  GALSES,
  INFO,
  LAYERS,
  LAYERS_TABLE,
  LINK_TO_BOOKMARK,
  LINK_TO_EXPEDITION_TAB,
  LINK_TO_INFO,
  LINK_TO_LAYERS,
  LINK_TO_LAYERS_TAB,
  LINK_TO_PROFILE,
  LINK_TO_SETTING_TAB,
  LOG,
  MAP,
  OBJECT_CARD,
  OBJECT_CREATE,
  OBJECT_FORMULAR,
  OBJECT_MEDIA,
  PROFILE,
  SETTING,
  TABLE,
  VIEW_3D,
  VIEW_3D_ROUTE,
  Z_COORDS,
} from '@/constants';
import { MapPageTools } from './map-page-tools';
import styles from './map-page.module.scss';
import { useNavigate } from 'react-router';
import { MapPageTables } from '@/pages';
import { useAppSelector } from '@/state';
import { Permissions, SettingTablesTabsTypes } from '@/constants/enums';

export const MapPage: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  const handleNavigateToModal = (isActive: boolean, path?: string): void => {
    if (isActive || !path) {
      navigate(MAP);
    } else {
      navigate(path);
    }
  };

  const canViewSpecificData = userPermissions?.includes(Permissions.specificDataViewing);
  const canViewMapData = userPermissions?.includes(Permissions.mapDataViewing);
  const canViewExpeditionData = userPermissions?.includes(Permissions.expeditionsDataViewing);

  const canManagmentObjectData = userPermissions?.includes(Permissions.mapDataManagment);
  const canManagmentRoles = userPermissions?.includes(Permissions.rolesManagment);

  return (
    <>
      <Layout>
        <Layout.Sidebar>
          <Layout.Sidebar.LeftSidebar>
            {canViewExpeditionData || canViewMapData ? (
              <SidebarCollapseButton handleNavigateToModal={handleNavigateToModal} />
            ) : null}
            <SidebarButton
              title="Пользователь"
              path={LINK_TO_PROFILE}
              icon="outline-person"
              onClick={handleNavigateToModal}
            />
            <SidebarButton
              title="Избранные виды"
              path={LINK_TO_BOOKMARK}
              icon="outline-bookmark-star"
              onClick={handleNavigateToModal}
            />
            {canManagmentRoles ? (
              <SidebarButton
                title="Параметры"
                path={LINK_TO_SETTING_TAB(SettingTablesTabsTypes.users)}
                icon="outline-setting"
                onClick={handleNavigateToModal}
              />
            ) : null}
          </Layout.Sidebar.LeftSidebar>
          <Layout.Sidebar.RightSidebar className={canViewMapData ? styles['with-search'] : ''}>
            <SidebarButton
              title="Слои"
              path={LINK_TO_LAYERS}
              icon="outline-layers"
              onClick={handleNavigateToModal}
            />
            <MapPageTools navigateToModal={handleNavigateToModal} />
            <SidebarButton
              title="Памятка"
              path={LINK_TO_INFO}
              icon="outline-unexplored--2"
              onClick={handleNavigateToModal}
            />
          </Layout.Sidebar.RightSidebar>
        </Layout.Sidebar>
        <Layout.Content>
          <MapView />
        </Layout.Content>
        <Layout.Footer>
          <Logo />
          <ZoomControl />
          {canViewSpecificData ? <LiveCoordinatePanel /> : null}
          <RulerPanel />
        </Layout.Footer>
      </Layout>
      {canViewMapData ? <SearchPanel /> : null}
      <GeoPanel />
      <Routes>
        <Route path={INFO} element={<ConventionsPanel />} />
        <Route path={LAYERS} element={<LayersPanel />} />

        {canViewSpecificData ? (
          <Route path={DISTANCE} element={<RoutePanel />} />
        ) : (
          <Route path={DISTANCE + '/*'} element={<Navigate to={MAP} />} />
        )}

        {canViewSpecificData ? (
          <Route path={CORDS_CONVERTER} element={<CoordinateConverterPanel />} />
        ) : (
          <Route path={CORDS_CONVERTER + '/*'} element={<Navigate to={MAP} />} />
        )}

        {canViewMapData ? (
          <Route path={OBJECT_FORMULAR + '/*'} element={<ObjectFormularPanel />} />
        ) : (
          <Route path={OBJECT_FORMULAR + '/*'} element={<Navigate to={MAP} />} />
        )}

        {canViewMapData ? (
          <Route path={VIEW_3D_ROUTE} element={<View3D />} />
        ) : (
          <Route path={VIEW_3D + '/*'} element={<Navigate to={MAP} />} />
        )}

        {canViewSpecificData ? (
          <Route path={Z_COORDS} element={<ZCoordsPanel />} />
        ) : (
          <Route path={Z_COORDS + '/*'} element={<Navigate to={MAP} />} />
        )}

        {canManagmentObjectData ? (
          <Route path={OBJECT_CREATE} element={<ObjectCreationPanel />} />
        ) : (
          <Route path={OBJECT_CREATE} element={<Navigate to={MAP} />} />
        )}

        {canViewMapData ? (
          <Route path={OBJECT_MEDIA} element={<MediaFilesPanel />} />
        ) : (
          <Route path={OBJECT_MEDIA + '/*'} element={<Navigate to={MAP} />} />
        )}

        {canViewMapData ? (
          <Route path={OBJECT_CARD} element={<ObjectCardPanel />} />
        ) : (
          <Route path={OBJECT_CARD + '/*'} element={<Navigate to={MAP} />} />
        )}

        <Route path={PROFILE} element={<ProfilePanel />} />

        {canViewMapData ? (
          <Route path={LOG} element={<MetaLogPanel />} />
        ) : (
          <Route path={LOG + '/*'} element={<Navigate to={MAP} />} />
        )}

        {canViewMapData ? (
          <Route path={GALSES} element={<MetaGalsPanel />} />
        ) : (
          <Route path={GALSES + '/*'} element={<Navigate to={MAP} />} />
        )}

        {canViewExpeditionData ? (
          <>
            <Route path={EXPEDITION_PANEL} element={<ExpeditionPanel />} />
            <Route path={EXPEDITION_PANEL_WITHOUT_STATUS} element={<ExpeditionPanel />} />
          </>
        ) : (
          <Route path={EXPEDITION + '/*'} element={<Navigate to={MAP} />} />
        )}

        <Route
          path={TABLE + '/*'}
          element={
            <TablePanel title="" additionalTitle={<MapTablePanelHeader />}>
              <Routes>
                {canViewMapData ? (
                  <Route
                    path={LAYERS_TABLE + '/:key' + '/*'}
                    element={<MapPageTables data={LayersTabs} link={LINK_TO_LAYERS_TAB} />}
                  />
                ) : (
                  <Route path={LAYERS_TABLE + '/:key' + '/*'} element={<Navigate to={MAP} />} />
                )}

                {canViewExpeditionData ? (
                  <Route
                    path={EXPEDITION_TABLE + '/:key' + '/*'}
                    element={
                      <MapPageTables
                        data={
                          canViewMapData
                            ? ExpeditionTabs
                            : ExpeditionTabs.filter((tab) => tab.key !== 'objects_research')
                        }
                        link={LINK_TO_EXPEDITION_TAB}
                      />
                    }
                  />
                ) : (
                  <Route path={EXPEDITION_TABLE + '/:key' + '/*'} element={<Navigate to={MAP} />} />
                )}

                {canViewMapData ? (
                  <Route
                    path={LAYERS_TABLE + '/*'}
                    element={<Navigate to={LINK_TO_LAYERS_TAB(LayersTabs[0].key)} />}
                  />
                ) : (
                  <Route path={LAYERS_TABLE + '/*'} element={<Navigate to={MAP} />} />
                )}

                {canViewExpeditionData ? (
                  <Route
                    path={EXPEDITION_TABLE + '/*'}
                    element={<Navigate to={LINK_TO_EXPEDITION_TAB(ExpeditionTabs[0].key)} />}
                  />
                ) : (
                  <Route path={EXPEDITION_TABLE + '/*'} element={<Navigate to={MAP} />} />
                )}
              </Routes>
            </TablePanel>
          }
        />
        {canManagmentRoles ? (
          <Route
            path={SETTING + '/*'}
            element={
              <Routes>
                <Route
                  path={'/:key' + '/*'}
                  element={
                    <TablePanel title="Параметры">
                      <MapPageTables data={SettingTabs} link={LINK_TO_SETTING_TAB} />
                    </TablePanel>
                  }
                />
                <Route
                  path={'/*'}
                  element={<Navigate to={LINK_TO_SETTING_TAB(SettingTabs[0].key)} />}
                />
              </Routes>
            }
          />
        ) : (
          <Route path={SETTING + '/*'} element={<Navigate to={MAP} />} />
        )}
        <Route path={BOOKMARK + '/*'} element={<BookmarkPanel />} />
      </Routes>
    </>
  );
};
