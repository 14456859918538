import React, { FC, useEffect, useState } from 'react';
import { DivingToolsSectionPropsType, ExpeditionCardSection } from '../index';
import { DiveTool } from '@/types';
import { FormikValues } from 'formik';
import styles from '../section.module.scss';
import { ColumnsType } from 'antd/lib/table';
import { findIndex } from 'lodash';
import { InputTypes } from '@/constants/enums';
import * as Yup from 'yup';
import {
  useCreateExpeditionToolDiveMutation,
  useDeleteExpeditionToolDiveMutation,
  useEditExpeditionToolDiveMutation,
} from '@/state/api';
import {
  handleDateSort,
  handleSortNumber,
  openErrorNotify,
  openInfoNotify,
  workTimeValidator,
} from '@/utils';
import {
  ConfirmModal,
  defaultDataPickerProps,
  defaultSearchProps,
  DropdownNoDataPlaceholder,
  DropdownFieldDataType,
  FieldType,
  Icon,
  PanelTableButton,
  Table,
  TableCell,
  TableModal,
} from '@/components';

export const ToolsDivingsSection: FC<DivingToolsSectionPropsType> = ({
  label,
  item,
  isEditable,
  membersSelectionData,
  objectsSelectionData,
  toolsSelectionData,
  transformToMinutes,
  transformToHours,
  ...rest
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState<DiveTool>();

  const [
    createDiving,
    {
      isLoading: createLoading,
      isError: createError,
      isSuccess: createSuccess,
      reset: createReset,
    },
  ] = useCreateExpeditionToolDiveMutation();
  const [
    editDiving,
    { isLoading: editLoading, isError: editError, isSuccess: editSuccess, reset: editReset },
  ] = useEditExpeditionToolDiveMutation();
  const [
    deleteDiving,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
      reset: deleteReset,
    },
  ] = useDeleteExpeditionToolDiveMutation();

  const handlePrepareSelectedItem = (selectedItem?: DiveTool) => {
    if (selectedItem) {
      return Object.fromEntries(
        Object.entries(selectedItem).map(([key, value]) => {
          switch (key) {
            case 'duration':
              return [key, transformToHours && transformToHours(value as number)];
            default:
              return [key, value];
          }
        }),
      );
    }
  };

  const searchNameFromIdObject = (value: string, record: DiveTool) => {
    const OnlyOneObject = item?.map_objects.find(
      (object) => object.map_object_id === record.map_object_id,
    );

    return OnlyOneObject?.info.wm_name.includes(value) ?? false;
  };

  const searchNameFromIdMember = (value: string, record: DiveTool) => {
    const OnlyOneMember = item?.members.find((member) => member.member_id === record.member_id);

    return OnlyOneMember?.info.name.includes(value) ?? false;
  };

  const searchNameFromIdTool = (value: string, record: DiveTool) => {
    const OnlyOneMember = item?.tools.find((tool) => tool.tool_id === record.tool_id);

    return OnlyOneMember?.info.name.includes(value) ?? false;
  };

  const handleSetSelectedItem = (id: number) => {
    setSelectedItem(item?.dives_tools?.find((item) => item.id === id));
  };

  const toggleAdd = () => {
    setShowAddModal((prevState) => !prevState);
  };

  const toggleEdit = (id: number) => {
    handleSetSelectedItem(id);
    setShowEditModal((prevState) => !prevState);
  };

  const toggleDelete = (id: number) => {
    handleSetSelectedItem(id);
    setShowDeleteModal((prevState) => !prevState);
  };

  const handleAdd = (value: FormikValues) => {
    createDiving({
      expedition_id: Number(item?.id),
      deep: Number(value.deep),
      duration: (transformToMinutes && transformToMinutes(value.duration)) || 0,
      date: value.date as string,
      member_id: Number(value.member_id),
      map_object_id: Number(value.map_object_id),
      tool_id: Number(value.tool_id),
      comment: value.comment as string,
    });
  };

  const handleEdit = (value: FormikValues) => {
    editDiving({
      ...value,
      deep: Number(value.deep),
      duration: (transformToMinutes && transformToMinutes(value.duration)) || 0,
      date: value.date as string,
      member_id: Number(value.member_id),
      map_object_id: Number(value.map_object_id),
      tool_id: Number(value.tool_id),
      comment: value.comment as string,
    });
  };

  const handleDelete = (id?: number | null) => {
    deleteDiving({
      id: id,
      expedition_id: Number(item?.id),
    });
  };

  const editableColumns: ColumnsType<DiveTool> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      fixed: 'left',
      width: 60,
      render: (value: string, row) => <TableCell value={findIndex(item?.dives_tools, row) + 1} />,
      sorter: (first, second) =>
        handleSortNumber(findIndex(item?.dives_tools, first), findIndex(item?.dives_tools, second)),
    },
    {
      title: 'ID',
      align: 'left',
      fixed: 'left',
      dataIndex: 'id',
      width: 60,
      sorter: (first, second, sortOrder) => handleSortNumber(first?.id, second?.id, sortOrder),
      render: (value: string) => <TableCell value={value} />,
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleEdit(id)}
          tooltipTitle="Редактировать погружение техники"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleDelete(id)}
          tooltipTitle="Удалить погружение техники"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'Объект',
      width: 160,
      dataIndex: 'map_object_id',
      key: 'map_object_id',
      align: 'left',
      render: (value: number) => {
        const preparedData = item?.map_objects?.find((object) => object?.info?.id === value)?.info
          ?.wm_name;

        return <TableCell value={preparedData} maxWidth={160} />;
      },
      sorter: (first, second, sortOrder) =>
        handleSortNumber(first?.map_object_id, second?.map_object_id, sortOrder),
      ...defaultSearchProps('map_object_id', (value, record) =>
        searchNameFromIdObject(value as string, record),
      ),
    },
    {
      title: 'Техника',
      width: 160,
      dataIndex: 'tool_id',
      key: 'tool_id',
      align: 'left',
      render: (value: number) => {
        const preparedData = String(
          item?.tools.find((object) => object?.info.id === value)?.info?.name,
        );

        return <TableCell value={preparedData} maxWidth={160} />;
      },
      sorter: (first, second, sortOrder) =>
        handleSortNumber(first?.tool_id, second?.tool_id, sortOrder),
      ...defaultSearchProps('tool_id', (value, record) =>
        searchNameFromIdTool(value as string, record),
      ),
    },
    {
      title: 'Участник',
      width: 160,
      dataIndex: 'member_id',
      key: 'member_id',
      align: 'left',
      render: (value: number) => {
        const preparedData = item?.members?.find((member) => member?.info?.id === value)?.info
          ?.name;

        return <TableCell value={preparedData} maxWidth={160} />;
      },
      sorter: (first, second, sortOrder) =>
        handleSortNumber(first?.member_id, second?.member_id, sortOrder),
      ...defaultSearchProps('member_id', (value, record) =>
        searchNameFromIdMember(value as string, record),
      ),
    },
    {
      title: 'Глубина, м',
      width: 110,
      dataIndex: 'deep',
      key: 'deep',
      align: 'left',
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second, sortOrder) => handleSortNumber(first?.deep, second?.deep, sortOrder),
    },
    {
      title: 'Время, чч:мм',
      width: 120,
      dataIndex: 'duration',
      key: 'duration',
      align: 'left',
      render: (value: number) => {
        const preparedData = transformToHours && transformToHours(value);

        return <TableCell value={preparedData} maxWidth={125} isDay={false} />;
      },
      sorter: (first, second, sortOrder) =>
        handleSortNumber(first?.duration, second?.duration, sortOrder),
    },
    {
      title: 'Дата погружения',
      width: 170,
      dataIndex: 'date',
      key: 'date',
      align: 'left',
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second, sortOrder) => handleDateSort(first?.date, second?.date, sortOrder),
      ...defaultDataPickerProps('date'),
    },
    {
      title: 'Комментарии',
      width: 140,
      dataIndex: 'comment',
      key: 'comment',
      align: 'left',
      render: (value: string) => value,
    },
  ];

  const initialValues = {
    expedition_id: item?.id,
    deep: '',
    duration: '',
    date: '',
    member_id: '',
    map_object_id: '',
    tool_id: '',
    comment: '',
  };

  const validationSchema = Yup.object({
    member_id: Yup.string().required('Пожалуйста, выберите участника погружения').nullable(true),
    map_object_id: Yup.string().required('Пожалуйста, выберите объект погружения').nullable(true),
    tool_id: Yup.string().required('Пожалуйста, выберите технику погружения').nullable(true),
    deep: Yup.number()
      .typeError('Можно вводить только числа')
      .required('Пожалуйста, введите глубину погружения')
      .nullable(true),
    duration: Yup.string()
      .test('', 'Пожалуйста, укажите время в формате HH:MM', (value) => workTimeValidator(value))
      .required('Пожалуйста, введите время погружения')
      .nullable(true),
    date: Yup.date()
      .typeError('Можно вводить только числа и знаки разделителя ( , . - )')
      .required('Пожалуйста, введите дату погружения')
      .max(
        new Date(),
        'Пожалуйста, введите корректную дату, погружение не может быть указана в будущем',
      )
      .nullable(true),
    comment: Yup.string(),
  });

  const fields: FieldType[] = [
    {
      label: 'Объект',
      name: 'map_object_id',
      type: 'dropdown',
      placeholder: 'Выберите Объект',
      required: true,
      data: objectsSelectionData as DropdownFieldDataType[],
      noDataPlaceholder: (
        <DropdownNoDataPlaceholder
          text={
            item?.objects_works && item?.objects_works?.length > 0
              ? 'Объекты не найдены'
              : 'Не выбраны объекты исследования'
          }
        />
      ),
    },
    {
      label: 'Техника',
      name: 'tool_id',
      type: 'dropdown',
      placeholder: 'Выберите технику',
      required: true,
      data: toolsSelectionData as DropdownFieldDataType[],
      noDataPlaceholder: (
        <DropdownNoDataPlaceholder
          text={
            item?.members && item?.members?.length > 0
              ? 'Техника не найдена'
              : 'Не выбрано МТО экспедиции'
          }
        />
      ),
    },
    {
      label: 'Участник',
      name: 'member_id',
      type: 'dropdown',
      placeholder: 'Выберите участника',
      required: true,
      data: membersSelectionData as DropdownFieldDataType[],
      noDataPlaceholder: (
        <DropdownNoDataPlaceholder
          text={
            item?.members && item?.members?.length > 0
              ? 'Участники экспедиции не найдены'
              : 'Не выбраны участники экспедиции'
          }
        />
      ),
    },
    {
      label: 'Глубина, м',
      name: 'deep',
      placeholder: 'Введите глубину погружения',
      required: true,
      type: InputTypes.number,
    },
    {
      label: 'Время, чч:мм',
      name: 'duration',
      placeholder: 'Введите время погружения',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Дата погружения',
      name: 'date',
      placeholder: 'Введите день погружения',
      type: 'datePicker',
      required: true,
    },
    {
      label: 'Комментарий',
      name: 'comment',
      placeholder: 'Введите комментарии',
      type: InputTypes.text,
    },
  ];

  const readOnlyColumn = editableColumns.filter((item) => item.key !== 'icon');

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify(
        'Добавление погружение',
        'Добавление данных о погружении техники успешно выполнено',
      );
      createReset();
    }
    if (createError) {
      openErrorNotify(
        'Произошла ошибка',
        'При добавлении данных о погружении техники произошла ошибка',
      );
      createReset();
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify(
        'Изменение погружение',
        'Изменение данных о погружении техники успешно выполнено',
      );
      editReset();
    }
    if (editError) {
      openErrorNotify(
        'Произошла ошибка',
        'При редактировании данных о погружении техники произошла ошибка',
      );
      editReset();
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Удаление погружение',
        'Удаление данных о погружении техники успешно выполнено',
      );
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify(
        'Произошла ошибка',
        'При удалении данных о погружении техники произошла ошибка',
      );
      deleteReset();
    }
  }, [deleteSuccess, deleteError]);

  return (
    <ExpeditionCardSection
      {...rest}
      label={label}
      buttonText="Добавить погружение техники"
      onClickAdd={toggleAdd}
      isEditable={isEditable}
    >
      <>
        {item?.dives_tools && item?.dives_tools?.length > 0 && (
          <Table
            withSelection={false}
            dataSource={item?.dives_tools}
            columns={isEditable ? editableColumns : readOnlyColumn}
            className={styles.table}
            noDataClassName={styles['no-data']}
            scroll={{ x: 1225, y: 300 }}
            withPagination={false}
          />
        )}
        <TableModal
          onSubmit={(value) => handleAdd(value)}
          onClose={() => setShowAddModal(false)}
          initialValues={initialValues}
          validationSchema={validationSchema}
          loading={!!createLoading}
          success={!!createSuccess}
          buttonText="Добавить"
          open={showAddModal}
          fields={fields}
          width={563}
          title="Добавление данных о погружении техники"
        />
        <TableModal
          onSubmit={(value) => handleEdit(value)}
          onClose={() => setShowEditModal(false)}
          initialValues={handlePrepareSelectedItem(selectedItem) as DiveTool}
          validationSchema={validationSchema}
          loading={!!editLoading}
          success={!!editSuccess}
          buttonText="Изменить"
          open={showEditModal}
          fields={fields}
          width={563}
          title="Изменение данных о погружении техники"
        />
        <ConfirmModal
          open={showDeleteModal}
          title="Подтвердите удаление погружения техники"
          content={<p>Вы уверены, что хотите удалить выбранные данные о погружении техники?</p>}
          submitButtonText="Удалить"
          cancelButtonText="отменить"
          onSubmit={() => {
            selectedItem && handleDelete(selectedItem?.id);
          }}
          onCancel={() => setShowDeleteModal(false)}
          loading={!!deleteLoading}
          success={!!deleteSuccess}
          type="danger"
        />
      </>
    </ExpeditionCardSection>
  );
};
