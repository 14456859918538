import React, { FC, useEffect, useState } from 'react';
import styles from '../../default-tab.module.scss';
import { useAppSelector } from '@/state';
import { useDeleteObjectMutation, useGetObjectsQuery } from '@/state/api';
import { BlackTooltip, ConfirmModal, Icon, ObjectsTable, PanelTabButton } from '@/components';
import { ComponentSize, PanelTabButtonTypes, Permissions } from '@/constants/enums';
import { openErrorNotify, openInfoNotify } from '@/utils';
import { IObject } from '@/types';
import { LINK_TO_OBJECT_CREATE } from '@/constants';
import { useNavigate } from 'react-router';

export const ObjectsTab: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);
  const isEditable = userPermissions?.includes(Permissions.mapDataManagment);
  const { data: objectsData, isError, isLoading } = useGetObjectsQuery();

  const preparedData = objectsData?.objects || [];

  const [
    deleteObject,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      reset: deleteReset,
    },
  ] = useDeleteObjectMutation();

  const [selectedObject, setSelectedObject] = useState<IObject>();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const getObject = (id: number) => {
    const item = preparedData?.find((object) => object.id === id);
    item && setSelectedObject(item);
  };

  const handleCreateClick = () => {
    navigate(LINK_TO_OBJECT_CREATE);
  };

  const handleDelete = (id: number) => {
    deleteReset();
    setShowConfirmDeleteModal((prevState) => !prevState);
    getObject(id);
  };

  const handleDeleteObject = (id: number): void => {
    deleteObject({
      id: id,
    });
  };

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Удаление объекта',
        <>
          Объект <b>{selectedObject?.name}</b> был удален
        </>,
      );
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении произошла ошибка');
    }
  }, [deleteSuccess, deleteError]);

  return (
    <div className={styles.wrapper}>
      {!isError && !isLoading && (
        <div className={styles.header}>
          <BlackTooltip
            title={`Перечень объектов исследования. Возможно открыть карточку 
              объекта, приблизить к объекту карту, удалить объект и 
              редактировать информацию об объекте. Отображаются на карте в 
              слое Объекты исследования.`}
            placement={'bottomLeft'}
          >
            <PanelTabButton
              className={styles['button-panel-help']}
              onClick={() => {}}
              type={PanelTabButtonTypes.icon}
              size={ComponentSize.small}
            >
              <Icon icon="outline-question-circle" size={14} />
            </PanelTabButton>
          </BlackTooltip>
          {isEditable ? (
            <PanelTabButton
              disabled={isError && isLoading}
              type={PanelTabButtonTypes.roundSecondary}
              size={ComponentSize.small}
              onClick={() => handleCreateClick()}
            >
              Создать новый объект
            </PanelTabButton>
          ) : null}
        </div>
      )}
      {isEditable ? (
        <ConfirmModal
          open={showConfirmDeleteModal}
          title="Подтвердите удаление объекта"
          content={
            <p>
              Вы уверены, что хотите удалить&nbsp;
              {selectedObject?.name ? (
                <>
                  объект <strong>{selectedObject?.name}</strong>
                </>
              ) : (
                'выбранный объект'
              )}
              ?
            </p>
          }
          submitButtonText="Удалить объект"
          cancelButtonText="Отменить"
          onSubmit={() => handleDeleteObject(selectedObject?.id as number)}
          onCancel={() => setShowConfirmDeleteModal((prevState) => !prevState)}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
      ) : null}
      <ObjectsTable
        data={preparedData}
        loading={isLoading}
        error={isError}
        onDelete={handleDelete}
      />
    </div>
  );
};
