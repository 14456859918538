import React, { FC } from 'react';
import { Children } from '@/types/default-types';
import styles from '../layout.module.scss';

export const Content: FC<{ children?: Children; className?: string }> = ({
  children,
  className = '',
}): JSX.Element => {
  return <div className={`${styles.content} ${className}`}>{children}</div>;
};
