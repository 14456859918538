import {
  OLD_BATHYMETRY_LAYER_URL,
  MAX_ZOOM,
  MIN_ZOOM,
  WMS_SPLITTED_URL,
  OLD_BATHYMETRY_LAYER,
} from '@/constants';
import React, { FC, useMemo } from 'react';
import { WMSTileLayer } from 'react-leaflet';
import L, { LatLngExpression, Polygon } from 'leaflet';
import { useAppSelector } from '@/state';

export const OldBathymetryLayer: FC = () => {
  const selectedLayers = useAppSelector((state) => state.map.selectedLayers);
  const userInfo = useAppSelector((state) => state.auth.user);
  const accessAreaGeometry = userInfo?.role?.access_area?.restricted_area.coordinates;
  const restrictedAreaBounds = accessAreaGeometry
    ? new Polygon(accessAreaGeometry as LatLngExpression[][]).getBounds()
    : undefined;

  return useMemo(
    () =>
      selectedLayers.includes(OLD_BATHYMETRY_LAYER) ? (
        <WMSTileLayer
          url={WMS_SPLITTED_URL}
          zIndex={293}
          bounds={restrictedAreaBounds}
          crs={L.CRS.EPSG900913}
          maxZoom={MAX_ZOOM}
          minZoom={MIN_ZOOM}
          params={{
            layers: OLD_BATHYMETRY_LAYER_URL,
            service: 'WMS',
            version: '1.3.0',
            format: 'image/png',
            transparent: true,
          }}
        />
      ) : (
        <></>
      ),
    [selectedLayers.includes(OLD_BATHYMETRY_LAYER)],
  );
};
