import React, { FC } from 'react';
import { FileTypes } from '@/constants/enums';
import {
  defaultSearchProps,
  ExpeditionCardSection,
  Icon,
  MediaSectionPropsType,
  PanelTableButton,
  Spinner,
  Table,
  TableCell,
  TableFilter,
} from '@/components';
import { ColumnsType } from 'antd/lib/table';
import { DefaultMediaType } from '@/types';
import { handleFilterString, handleSortNumber, handleSortString } from '@/utils';
import styles from '../../section.module.scss';
import { FileTypes as FT } from '@/constants';

export const DocumentsAttachments: FC<MediaSectionPropsType> = ({
  isEditable,
  label,
  loading,
  fileList,
  uploadedFilesList,
  onClick,
  onUpload,
  onRemove,
  onDelete,
  onSelectItem,
  onDownload,
}) => {
  const handleToggleDelete = (item: DefaultMediaType) => {
    onSelectItem && onSelectItem(item);
    onDelete && onDelete();
  };

  const handleToggleDownload = (item: DefaultMediaType) => {
    onSelectItem && onSelectItem(item);
    onDownload && onDownload(item);
  };

  const editableColumns: ColumnsType<DefaultMediaType> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 60,
      sorter: (first, second) => handleSortNumber(first?.id, second?.id),
    },
    {
      title: <Icon size={12} icon="outline-download" />,
      dataIndex: 'path',
      key: 'path',
      width: 40,
      align: 'center',
      render: (value: string, row) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Скачать документ"
          icon="outline-download"
          onClick={() => handleToggleDownload(row)}
          size={12}
          tooltipPlacement="bottom"
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      dataIndex: 'id',
      width: 40,
      key: 'icon',
      align: 'center',
      render: (value: string, row) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Удалить документ"
          icon="outline-trash"
          onClick={() => handleToggleDelete(row)}
          size={12}
          tooltipPlacement="bottom"
        />
      ),
    },
    {
      title: 'Расширение файла',
      dataIndex: 'media_type',
      key: 'media_type',
      align: 'left',
      width: 200,
      render: (value: string) => <TableCell value={value} maxWidth={200} />,
      sorter: (first, second) => handleSortString(first?.media_type, second?.media_type),
      onFilter: (value, record) =>
        handleFilterString(value?.toString()?.replace(/\W/, ''), record, 'media_type'),
      filterDropdown: ({ filters, clearFilters, confirm, setSelectedKeys, selectedKeys }) => (
        <TableFilter
          filters={filters}
          onFilter={confirm}
          onClear={clearFilters}
          selectedKeys={selectedKeys}
          onChange={setSelectedKeys}
        />
      ),
      filters: FT?.map((item) => ({
        value: item,
        text: item,
      })),
    },
    {
      title: 'Наименование документа',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second) => handleSortString(first?.name, second?.name),
      ...defaultSearchProps('name'),
    },
  ];

  const readOnlyColumn = editableColumns.filter((item) => item.key !== 'icon');

  return (
    <ExpeditionCardSection
      isEditable={isEditable}
      fileList={uploadedFilesList}
      buttonText="Добавить документ"
      fileType={FileTypes.docs}
      uploadAvailable={true}
      customRequest={(options) => onUpload && onUpload(options)}
      onDelete={(item) => onRemove && onRemove(item)}
      onClickAdd={(event) => onClick && onClick(event)}
      labelCustom={
        <h5 className={styles.label}>
          {label}
          {loading && <Spinner className={styles.spinner} />}
        </h5>
      }
    >
      <>
        {fileList && fileList.length > 0 ? (
          <Table
            dataSource={fileList}
            columns={isEditable ? editableColumns : readOnlyColumn}
            className={styles.table}
            noDataClassName={styles['no-data']}
            scroll={{ x: 1225, y: 300 }}
            withPagination={false}
            withSelection={false}
          />
        ) : null}
      </>
    </ExpeditionCardSection>
  );
};
