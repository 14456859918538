import React, { FC, useEffect } from 'react';
import styles from './confirm-modal.module.scss';
import { ConfirmModalPropsType } from './confirm-modal.types';
import { Button, Modal } from '@/components';
import { Colors, ComponentSize, FillTypes } from '@/constants/enums';

export const ConfirmModal: FC<ConfirmModalPropsType> = ({
  open,
  onSubmit,
  onCancel,
  loading,
  success,
  title = 'Подтвердите закрытие окна',
  content = 'Вы уверенны, что хотите закрыть окно? В этом случае вы потеряете введенные данные.',
  submitButtonText = 'Да, закрыть',
  cancelButtonText = 'Нет, продолжить',
  type = 'default',
}): JSX.Element => {
  useEffect(() => {
    if (success) {
      setTimeout(onCancel, 500);
    }
  }, [success]);

  const buttonColor = type === 'default' ? Colors.secondary : Colors.red;

  return (
    <Modal
      open={open}
      width={420}
      mask={true}
      showCloseIcon={false}
      centered={true}
      destroyOnClose={true}
      wrapClassName={styles.modal}
      onClose={() => onCancel()}
      title={title}
    >
      <>
        <span className={styles.content}>{content}</span>
        <div className={styles['button-group']}>
          <Button
            color={Colors.black}
            size={ComponentSize.small}
            fillType={FillTypes.outline}
            className={styles.button}
            onClick={() => onCancel()}
          >
            {cancelButtonText}
          </Button>
          <Button
            loading={loading}
            color={buttonColor}
            className={styles.button}
            size={ComponentSize.small}
            onClick={() => onSubmit()}
          >
            {submitButtonText}
          </Button>
        </div>
      </>
    </Modal>
  );
};
