import React, { useEffect, useState } from 'react';
import styles from './bookmark-panel-body.module.scss';
import { EditBookmark } from '@/types';
import * as Yup from 'yup';
import { useMap } from '@/context';
import { useAppSelector } from '@/state';
import { ComponentSize, InputTypes, PanelTabButtonTypes } from '@/constants/enums';
import {
  BookmarksTable,
  ConfirmModal,
  FieldType,
  PanelTabButton,
  TableLoader,
  TableModal,
} from '@/components';
import { getConvertLatLngSixSigns, openErrorNotify, openInfoNotify } from '@/utils';
import {
  useCreateBookmarkMutation,
  useEditBookmarkMutation,
  useDeleteBookmarkMutation,
  useGetBookmarksQuery,
} from '@/state/api';

export const BookmarkPanelBody = () => {
  const { isLoading, data } = useGetBookmarksQuery();

  const [
    createBookmarks,
    {
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
      reset: resetCreate,
    },
  ] = useCreateBookmarkMutation();
  const [
    editBookmarks,
    { isLoading: editLoading, isSuccess: editSuccess, isError: editError, reset: resetEdit },
  ] = useEditBookmarkMutation();
  const [
    deleteBookmarks,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      reset: resetDelete,
    },
  ] = useDeleteBookmarkMutation();

  const userId = useAppSelector((state) => {
    return state.auth.user?.id;
  });

  const [selectedRowsId, setSelectedRowsId] = useState<number>(0);
  const [initialValues, setInitialValues] = useState<EditBookmark>({});

  const { map } = useMap();
  const [showAddsModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditsModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const preparedData = data?.bookmarks || [];

  const fields: FieldType[] = [
    {
      label: 'Наименование избранного вида',
      name: 'name',
      placeholder: 'Введите наименование',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Описание',
      name: 'description',
      placeholder: 'Не заполнено',
      type: InputTypes.textArea,
      required: true,
    },
    {
      name: 'lat',
      value: getConvertLatLngSixSigns(map?.getCenter().lat as number),
      type: 'hidden',
    },
    {
      name: 'lng',
      value: getConvertLatLngSixSigns(map?.getCenter().lng as number),
      type: 'hidden',
    },
    {
      name: 'zoom',
      value: map?.getZoom() as number,
      type: 'hidden',
    },
  ];

  const handleOpenCreate = (): void => {
    resetCreate();

    setInitialValues({
      user_id: userId,
      lat: getConvertLatLngSixSigns(map?.getCenter().lat as number),
      lng: getConvertLatLngSixSigns(map?.getCenter().lng as number),
      zoom: map?.getZoom(),
    });

    setShowAddModal(true);
  };

  const handleOpenEdit = (id: number): void => {
    resetEdit();
    setSelectedRowsId(id);

    const bookmark = preparedData?.filter((item) => item?.id === id)[0];
    setInitialValues({
      id: bookmark?.id,
      user_id: userId,
      name: bookmark?.name,
      description: bookmark?.description,
      lat: getConvertLatLngSixSigns(bookmark?.lat as number),
      lng: getConvertLatLngSixSigns(bookmark?.lng as number),
      zoom: bookmark?.zoom,
    });

    setShowEditsModal(true);
  };

  const handleOpenDelete = (id: number): void => {
    resetDelete();
    setSelectedRowsId(id);
    setShowConfirmDeleteModal(true);
  };

  const handleCreateBookmark = (value: EditBookmark): void => {
    setShowAddModal(false);
    createBookmarks(value);
  };

  const handleEditBookmark = (value: EditBookmark): void => {
    setShowEditsModal(false);
    editBookmarks(value);
  };

  const handleDeleteBookmark = (id: number) => {
    deleteBookmarks(id);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Пожалуйста, введите наименование вида'),
    description: Yup.string().notRequired(),
  });

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify('Изменения сохранены', 'Добавление успешно');
    }
    if (createError) {
      openErrorNotify('Произошла ошибка', 'При добавлении произошла ошибка');
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify('Изменения сохранены', 'Редактирование успешно');
    }
    if (editError) {
      openErrorNotify('Произошла ошибка', 'При редактировании произошла ошибка');
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify('Изменения сохранены', 'Удаление успешно');
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении произошла ошибка');
    }
  }, [deleteSuccess, deleteError]);

  if (isLoading) {
    return (
      <div className={styles.body}>
        <TableLoader className={styles.loader} />
      </div>
    );
  }

  return (
    <div className={styles.body}>
      <PanelTabButton
        className={styles.button}
        type={PanelTabButtonTypes.icon}
        size={ComponentSize.small}
        onClick={() => handleOpenCreate()}
      >
        Создать вид
      </PanelTabButton>

      <BookmarksTable
        data={preparedData}
        isLoading={isLoading}
        handleOpenDelete={handleOpenDelete}
        handleOpenEdit={handleOpenEdit}
      />

      <TableModal
        onSubmit={(value) => handleCreateBookmark(value as EditBookmark)}
        buttonText="Создать"
        onClose={() => setShowAddModal(false)}
        title="Новый избранный вид"
        validationSchema={validationSchema}
        initialValues={initialValues}
        open={showAddsModal}
        loading={createLoading}
        success={createSuccess}
        mask={true}
        fields={fields}
        width={524}
      />
      <TableModal
        onSubmit={(value) => handleEditBookmark(value as EditBookmark)}
        buttonText="Изменить"
        onClose={() => setShowEditsModal(false)}
        title="Изменить избранный вид"
        validationSchema={validationSchema}
        initialValues={initialValues}
        open={showEditModal}
        loading={editLoading}
        success={editSuccess}
        mask={true}
        fields={fields}
        width={524}
      />
      <ConfirmModal
        open={showConfirmDeleteModal}
        title="Удаление избранного вида"
        content={
          <p>
            Вы уверены, что хотите удалить избранный вид{' '}
            <b>{preparedData.find((bookmark) => bookmark.id === selectedRowsId)?.name}</b> из
            списка?
          </p>
        }
        submitButtonText="удалить"
        cancelButtonText="отменить"
        onSubmit={() => handleDeleteBookmark(selectedRowsId)}
        onCancel={() => setShowConfirmDeleteModal(false)}
        loading={deleteLoading}
        success={deleteSuccess}
        type={'danger'}
      />
    </div>
  );
};
