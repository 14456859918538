import { DefaultResponse, EditRegion, IRegion, NewRegion } from '@/types';
import { parsePolygonToWKT } from '@/utils';
import { Polygon } from 'geojson';
import { LatLngExpression } from 'leaflet';
import { api } from '../api';

const transformRegions = (
  res: Array<{
    id: number;
    name: string;
    district_id: string;
    region: string;
    item_type: number;
    research_start: string | null;
    research_end: string | null;
    polygon: string;
    center: string;
  }>,
): IRegion[] =>
  res.map((region) => {
    const splitCenter = region.center
      ? region.center.slice(6, region.center.length - 7).split(' ')
      : ['0', '0'];
    const parsedPolygon: LatLngExpression[][][] = [
      [(JSON.parse(region.polygon) as Polygon).coordinates[0].map((cord) => [cord[1], cord[0]])],
    ];

    return {
      id: region.id,
      name: region.name,
      district_id: region.district_id,
      region: region.region,
      coordinates: [parseFloat(splitCenter[1]), parseFloat(splitCenter[0])],
      polygon: parsedPolygon,
      item_type: region.item_type,
    };
  });

export const regionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRegions: builder.query<IRegion[], void>({
      query() {
        return {
          url: '/regions/list',
          method: 'GET',
          credentials: 'include',
        };
      },
      transformResponse: transformRegions,
      providesTags: ['Regions'],
    }),
    createRegion: builder.mutation<DefaultResponse, NewRegion>({
      query(body) {
        const polygon = parsePolygonToWKT(body.polygon, true);

        return {
          url: '/region',
          method: 'POST',
          body: { ...body, polygon: polygon },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Regions'],
    }),
    editRegion: builder.mutation<DefaultResponse, EditRegion>({
      query(body) {
        const polygon = parsePolygonToWKT(body.polygon, true);

        return {
          url: '/region',
          method: 'PUT',
          body: { ...body, polygon: polygon },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Regions'],
    }),
    deleteRegion: builder.mutation<
      DefaultResponse,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/region',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Regions'],
    }),
  }),
});

export const {
  useGetRegionsQuery,
  useCreateRegionMutation,
  useDeleteRegionMutation,
  useEditRegionMutation,
} = regionsApi;
