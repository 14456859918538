import { TableLoader } from '@/components/table-components';
import { Html } from '@react-three/drei';
import styles from './loader.module.scss';

export const Loader = () => {
  return (
    <Html center>
      <TableLoader className={styles.loader} />
    </Html>
  );
};
