import React, { FC } from 'react';
import { defaultSearchProps, Icon, PanelTableButton, Table, TableCell } from '@/components';
import { ColumnsType } from 'antd/lib/table';
import { ObjectsGroup } from '@/types';
import { handleSortNumber, handleSortString } from '@/utils';
import { ObjectsGroupsTablePropsType } from './objects-groups-table.types';
import { useMap } from '@/context';
import { LatLngBoundsExpression } from 'leaflet';
import { useNavigate } from 'react-router';
import { MAP } from '@/constants';
import { useGetObjectsQuery } from '@/state/api';

export const ObjectsGroupsTable: FC<ObjectsGroupsTablePropsType> = ({
  data,
  loading,
  error,
  onEdit,
  onDelete,
}): JSX.Element => {
  const { map } = useMap();
  const navigate = useNavigate();

  const { data: objectsData } = useGetObjectsQuery();

  const preparedData = objectsData?.objects || [];

  const handleToFly = (row: ObjectsGroup) => {
    const rowIds = row.objects.map((item) => item.id);
    const selectObjects = preparedData.filter((item) => {
      if (rowIds.includes(item.id)) {
        return item;
      }
    });
    const bounds: LatLngBoundsExpression = selectObjects.map((item) => [
      parseFloat(item.lat),
      parseFloat(item.lng),
    ]);

    map?.flyToBounds(bounds);
    navigate(MAP);
  };

  const columns: ColumnsType<ObjectsGroup> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      width: 60,
      sorter: (first, second) => handleSortNumber(first?.id, second?.id),
    },
    {
      title: <Icon size={12} icon={'outline-geo-alt'} />,
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 40,
      render: (value: number, row) => (
        <PanelTableButton
          showTooltip={true}
          tooltipPlacement={'bottom'}
          tooltipTitle={'Приблизиться к группе объектов'}
          icon="outline-geo-alt"
          onClick={() => handleToFly(row)}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 40,
      render: (value: number, row) => (
        <PanelTableButton
          onClick={() => onEdit(row.id)}
          tooltipTitle="Изменить группу объектов"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 40,
      render: (value: number, row) => (
        <PanelTableButton
          onClick={() => onDelete(row.id)}
          tooltipTitle="Удалить группу объектов"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (value: string, row) => <TableCell value={row.name.toString()} maxWidth={350} />,
      sorter: (first, second) => handleSortString(first?.name, second?.name),
      ...defaultSearchProps('name'),
    },
    {
      title: 'Количество объектов',
      dataIndex: 'objects',
      key: 'objects',
      align: 'left',
      width: 250,
      render: (value: Array<number>) => <TableCell value={value.length} />,
      sorter: (first, second, sortOrder) =>
        handleSortNumber(first?.objects.length, second?.objects.length, sortOrder),
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      loading={loading}
      error={error}
      scroll={{ x: 750, y: 500 }}
      withSelection={false}
    />
  );
};
