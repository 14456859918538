import React, { FC, useEffect, useState } from 'react';
import {
  BlackTooltip,
  ConfirmModal,
  defaultSearchProps,
  DropdownFieldDataType,
  FieldType,
  Icon,
  PanelTabButton,
  RolesTable,
  TableCell,
  TableModal,
} from '@/components';
import { ComponentSize, InputTypes, PanelTabButtonTypes } from '@/constants/enums';
import {
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useEditRoleMutation,
  useGetAccessesAreasListQuery,
  useGetObjectsGroupsListQuery,
  useGetRolesListQuery,
  useGetRulesListQuery,
} from '@/state/api';
import styles from '../../default-tab.module.scss';
import { AccessArea, EditRole, NewRole, ObjectsGroup, Role } from '@/types';
import { AnyObject } from 'yup/lib/types';
import { ColumnsType } from 'antd/es/table';
import {
  handleSortString,
  openErrorNotify,
  openInfoNotify,
  preparePermissionForMovingToAvailable,
  preparePermissionForMovingToSelected,
} from '@/utils';
import * as Yup from 'yup';

export const RolesTab: FC = () => {
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [showEditRoleModal, setShowEditRoleModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState<EditRole | null>(null);
  const [selectedName, setCreatedName] = useState<string>('');

  const {
    isError: isRolesError,
    isLoading: isRolesLoading,
    data: rolesData,
  } = useGetRolesListQuery();
  const {
    isLoading: isRulesLoading,
    data: rulesData,
    isError: isRulesError,
  } = useGetRulesListQuery();
  const {
    isLoading: isAccessesAreasLoading,
    data: accessesAreasData,
    isError: isAccessesAreasError,
  } = useGetAccessesAreasListQuery();
  const {
    isLoading: isObjectsGroupsLoading,
    data: objectsGroupData,
    isError: isObjectsGroupsError,
  } = useGetObjectsGroupsListQuery();

  const loading =
    isRolesLoading || isRulesLoading || isAccessesAreasLoading || isObjectsGroupsLoading;
  const error = isRolesError || isRulesError || isAccessesAreasError || isObjectsGroupsError;

  const preparedRolesData = rolesData?.data || [];
  const preparedRulesData = rulesData?.data || [];
  const preparedObjectsGroupsData = objectsGroupData?.data || [];
  const preparedAccessesAreasData = accessesAreasData?.data || [];

  const [
    createRole,
    {
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: createError,
      reset: resetCreate,
    },
  ] = useCreateRoleMutation();
  const [
    editRole,
    { isLoading: editLoading, isSuccess: editSuccess, isError: editError, reset: resetEdit },
  ] = useEditRoleMutation();
  const [
    deleteRole,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteError,
      reset: resetDelete,
    },
  ] = useDeleteRoleMutation();

  const initialValues: NewRole = {
    name: '',
    rules_ids: [],
    objects_group_id: 0,
    description: '',
    access_area_id: 0,
  };

  const handleCreateRole = ({
    name,
    rules_ids,
    objects_group_id,
    description,
    access_area_id,
  }: NewRole): void => {
    setCreatedName(name);
    const preparedValue = {
      name,
      rules_ids,
      objects_group_id,
      description,
      access_area_id,
    };
    createRole(preparedValue);
  };

  const handleEditRole = ({
    name,
    id,
    rules_ids,
    objects_group_id,
    description,
    access_area_id,
  }: EditRole): void => {
    const preparedValue = {
      name,
      rules_ids,
      objects_group_id,
      description,
      access_area_id,
      id,
    };
    editRole(preparedValue);
  };

  const handleDeleteRole = (): void => {
    deleteRole({
      role_id: selectedRole?.id || 0,
    });
  };

  const optionsColumns: ColumnsType<AnyObject & { id: number }> = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (value: string) => <TableCell value={value} maxWidth={200} />,
      sorter: (first, second, sortOrder) => handleSortString(first.name, second.name, sortOrder),
      ...defaultSearchProps('name'),
    },
  ];

  const valuesColumns: ColumnsType<AnyObject & { id: number }> = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (value: string) => <TableCell value={value} maxWidth={300} />,
      sorter: (first, second, sortOrder) => handleSortString(first.name, second.name, sortOrder),
      ...defaultSearchProps('name'),
    },
  ];

  const customEmptyLeftDisclaimer = (
    <span className={styles['transfer-disclaimer']}>
      Для создания роли,
      <br />
      выберите функции в правой части
      <br />и потом нажмите на кнопку
      <Icon size={20} icon={'outline-chevron-left'} className={styles['transfer-button']} />
    </span>
  );

  const fields: FieldType[] = [
    {
      label: 'Наименование',
      name: 'name',
      placeholder: 'Введите наименование',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Описание',
      name: 'description',
      placeholder: 'Не заполнено',
      type: InputTypes.textArea,
      required: true,
    },
    {
      label: 'Группа объектов',
      name: 'objects_group_id',
      type: 'dropdown',
      required: true,
      data: [
        { label: 'Все', value: 0 },
        ...preparedObjectsGroupsData.map(
          (item: ObjectsGroup): DropdownFieldDataType => ({
            label: item?.name,
            value: item.id,
          }),
        ),
      ],
      loading: isObjectsGroupsLoading,
      error: isObjectsGroupsError,
    },
    {
      label: 'Область видимости',
      name: 'access_area_id',
      type: 'dropdown',
      required: true,
      data: [
        { label: 'Все', value: 0 },
        ...preparedAccessesAreasData.map(
          (item: AccessArea): DropdownFieldDataType => ({
            label: item?.name,
            value: item.id,
          }),
        ),
      ],
      loading: isAccessesAreasLoading,
      error: isAccessesAreasError,
    },
    {
      label: 'Функции',
      name: 'rules_ids',
      type: 'transfer',
      rightTableTitle: 'Функции',
      rightTableColumns: optionsColumns,
      rightTableDatasource: preparedRulesData.map((rule) => ({
        id: rule.id,
        name: rule.name,
      })),
      customLeftTableSelectionFilter: (rows, row, checked, data) =>
        preparePermissionForMovingToAvailable(rows, row, checked, data),
      customRightTableSelectionFilter: (rows, row, checked, data) =>
        preparePermissionForMovingToSelected(rows, row, checked, data),
      loading: isRulesLoading,
      emptyLeftDisclaimer: customEmptyLeftDisclaimer,
      leftTableTitle: 'Функции роли',
      leftTableColumns: valuesColumns,
      required: true,
    },
  ];

  const validationSchema = Yup.object({
    name: Yup.string().required('Пожалуйста, введите наименование роли'),
    rules_ids: Yup.array().min(1, 'Пожалуйста, добавьте функции роли').required(),
    objects_group_id: Yup.number().required('Пожалуйста, выберите группу объектов'),
    description: Yup.string().max(200, 'Уменьшите количество символов').notRequired(),
    access_area_id: Yup.number().required('Пожалуйста, выберите область видимости'),
  });

  const handleDeleteClick = (role: Role) => {
    const preparedValue: EditRole = {
      name: role.name || '',
      description: role.description,
      access_area_id: role.access_area?.id || 0,
      objects_group_id: role.objects_group?.id || 0,
      rules_ids:
        role.rules.map((rule) => ({
          id: rule.id,
          name: rule.name,
        })) || [],
      id: role.id || 0,
    };
    setSelectedRole(preparedValue);
    resetDelete();
    setShowConfirmDeleteModal(true);
  };

  const handleEditClick = (role: Role) => {
    const preparedValue: EditRole = {
      name: role.name || initialValues.name,
      description: role.description || initialValues.description,
      access_area_id: role.access_area?.id || initialValues.access_area_id,
      objects_group_id: role.objects_group?.id || initialValues.objects_group_id,
      rules_ids:
        role.rules.map((rule) => ({
          id: rule.id,
          name: rule.name,
        })) || initialValues.rules_ids,
      id: role.id,
    };
    setSelectedRole(preparedValue);
    resetEdit();
    setShowEditRoleModal(true);
  };

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify(
        'Добавление роли',
        <>
          Роль <b>{selectedName}</b> была добавлена
        </>,
      );
    }
    if (createError) {
      openErrorNotify('Произошла ошибка', 'При добавлении произошла ошибка');
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify(
        'Изменение роли',
        <>
          Роль <b>{selectedRole?.name}</b> была изменена
        </>,
      );
    }
    if (editError) {
      openErrorNotify('Произошла ошибка', 'При редактировании пользователя произошла ошибка');
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Удаление роли',
        <>
          Роль <b>{selectedRole?.name}</b> была удалена
        </>,
      );
    }
    if (deleteError) {
      openErrorNotify('Произошла ошибка', 'При удалении пользователя произошла ошибка');
    }
  }, [deleteSuccess, deleteError]);

  return (
    <div className={styles.wrapper}>
      {!error && !loading && (
        <div className={styles.header}>
          <BlackTooltip title={'Управление ролями'} placement={'bottomLeft'}>
            <PanelTabButton
              className={styles['button-panel-help']}
              onClick={() => {}}
              type={PanelTabButtonTypes.icon}
              size={ComponentSize.small}
            >
              <Icon icon="outline-question-circle" size={14} />
            </PanelTabButton>
          </BlackTooltip>
          <PanelTabButton
            disabled={error || loading}
            type={PanelTabButtonTypes.roundSecondary}
            className={styles.button}
            size={ComponentSize.small}
            onClick={() => {
              resetCreate();
              setShowAddRoleModal(true);
            }}
          >
            Добавить
          </PanelTabButton>
        </div>
      )}
      <TableModal
        onSubmit={(value) => handleCreateRole(value as NewRole)}
        buttonText="Добавить"
        onClose={() => {
          setShowAddRoleModal(false);
          setSelectedRole(null);
        }}
        title="Добавление роли"
        validationSchema={validationSchema}
        initialValues={initialValues}
        open={showAddRoleModal}
        loading={createLoading}
        success={createSuccess}
        fields={fields}
        width={1000}
      />
      <TableModal
        onSubmit={(value) => handleEditRole(value as EditRole)}
        buttonText="Изменить"
        onClose={() => {
          setShowEditRoleModal(false);
          setSelectedRole(null);
        }}
        title="Редактирование роли"
        validationSchema={validationSchema}
        initialValues={selectedRole || initialValues}
        open={showEditRoleModal}
        loading={editLoading}
        success={editSuccess}
        fields={fields}
        width={1000}
      />
      <ConfirmModal
        open={showConfirmDeleteModal}
        title="Подтвердите удаление роли"
        content={
          <p>
            Вы уверены, что хотите удалить&nbsp;
            {selectedRole?.name ? (
              <>
                роль <strong>{selectedRole?.name}</strong>
              </>
            ) : (
              'указанную роль'
            )}
            ?
          </p>
        }
        submitButtonText="Удалить роль"
        cancelButtonText="Отменить"
        onSubmit={() => handleDeleteRole()}
        onCancel={() => {
          setShowConfirmDeleteModal(false);
          setSelectedRole(null);
        }}
        loading={deleteLoading}
        success={deleteSuccess}
        type="danger"
      />

      <RolesTable
        rolesData={preparedRolesData}
        rulesData={preparedRulesData}
        onDelete={handleDeleteClick}
        onEdit={handleEditClick}
        loading={loading}
        error={error}
      />
    </div>
  );
};
