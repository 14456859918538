import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WaterAreasStateType } from '@/types';
import { resetAction } from '@/state/actions';

const initialState: WaterAreasStateType = {
  isOpenWaterAreaTooltip: true,
};

const waterAreasSlice = createSlice({
  name: 'waterAreas',
  initialState,
  reducers: {
    setIsOpenWaterAreaTooltip: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenWaterAreaTooltip = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAction, () => {
      return initialState;
    });
  },
});

export const { setIsOpenWaterAreaTooltip } = waterAreasSlice.actions;
export const { reducer: waterAreasReducer } = waterAreasSlice;
