import { Form, Formik } from 'formik';
import styles from './otp-validation.module.scss';
import React, { FC } from 'react';
import * as Yup from 'yup';
import { ButtonTypes, Colors, ComponentSize, FillTypes } from '@/constants/enums';
import { Button } from '@/components/common/button';
import { ErrorPlaceholder } from '@/components/authorization';
import { PinInput } from '@/components';
import { OTP, OtpFormProps } from './otp-validation.types';
import { ResendCodeButton } from './resend-code-button';

export const OTPForm: FC<OtpFormProps> = ({
  onSubmitForm,
  onCancel,
  loading,
  error,
  isError,
  otpCreatedAt,
  validity,
  otpDataLoading,
  onResendOtp,
}): JSX.Element => {
  const validationSchema = Yup.object({
    otpCode: Yup.string()
      .required('Пожалуйста, введите проверочный код')
      .length(6, 'Введите все цифры кода'),
  });

  const initialValues: OTP = {
    otpCode: '',
  };

  return (
    <Formik
      onSubmit={onSubmitForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnMount={true}
    >
      {({ dirty, isValid, handleSubmit, touched, setFieldValue }) => (
        <Form
          className={styles.wrapper}
          onSubmit={(event) => {
            event && event.preventDefault();
            handleSubmit();
          }}
        >
          <span className={styles.title}>
            Вам на почту был отправлен код подтверждения, пожалуйста, введите его.
          </span>
          <PinInput
            placeholder=""
            length={6}
            secret
            secretDelay={400}
            onChange={(value) => {
              setFieldValue('otpCode', value, true);
            }}
            type={'numeric'}
            disabled={loading}
          />
          <ErrorPlaceholder errors={{}} requestError={error} touched={touched} isError={isError} />
          <ResendCodeButton
            loading={otpDataLoading}
            otpCreatedAt={otpCreatedAt}
            validity={validity}
            onResendOtp={onResendOtp}
          />
          <div className={styles.footer}>
            <Button
              minWidth={312}
              color={Colors.black}
              size={ComponentSize.large}
              type={ButtonTypes.submit}
              disable={!dirty || !isValid}
              loading={loading}
            >
              Подтвердить
            </Button>
            <Button
              minWidth={312}
              color={Colors.black}
              fillType={FillTypes.outline}
              size={ComponentSize.large}
              type={ButtonTypes.button}
              onClick={() => onCancel()}
            >
              Отмена
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
