import React, { FC } from 'react';
import {
  Icon,
  Layout,
  LiveCoordinatePanel,
  Logo,
  MapBaseLayer,
  ObjectCreationPanel,
  RulerPanel,
  SearchPanel,
  SidebarButton,
  ZoomControl,
} from '@/components';
import type { MarkerClusterGroup as MarkerClusterGroupType } from 'leaflet';
import { Route, Routes } from 'react-router-dom';
import { createClusterCustomIcon, MarkerClusterGroup } from '@/utils';
import styles from './object-select-page.module.scss';
import { ObjectMarker, WaterAreaPolygon } from '@/components/map/elements';
import { ObjectSelectContentPropsType } from '@/pages';
import { FeatureGroup } from 'react-leaflet';

// eslint-disable-next-line
const MarkerClusterGroupElement = MarkerClusterGroup as any;

export const ObjectSelectContent: FC<ObjectSelectContentPropsType> = ({
  onCancel,
  onCreate,
  onAdd,
  data,
  expeditionId,
  isChanged,
  areasData,
  onAreasRender,
  onMarkerClick,
}) => {
  return (
    <>
      <Layout>
        <Layout.Sidebar>
          <Layout.Sidebar.CenterSidebar>
            <SidebarButton onClick={() => onCancel()} hideTooltip={true}>
              Отмена
            </SidebarButton>
            <SidebarButton onClick={() => onCreate()} hideTooltip={true}>
              <Icon icon="outline-plus-circle" size={20} className="me-2" />
              Создать объект
            </SidebarButton>
            <SidebarButton onClick={() => onAdd()} hideTooltip={true} disable={!isChanged}>
              Сохранить изменения
            </SidebarButton>
          </Layout.Sidebar.CenterSidebar>
        </Layout.Sidebar>
        <Layout.Content>
          <Routes>
            <Route
              path="/create/*"
              element={<ObjectCreationPanel expeditionId={expeditionId} className={styles.modal} />}
            />
          </Routes>
          <MapBaseLayer>
            <MarkerClusterGroupElement
              maxClusterRadius={20}
              iconCreateFunction={(cluster: MarkerClusterGroupType) =>
                createClusterCustomIcon(cluster, styles)
              }
              spiderLegPolylineOptions={{ opacity: 0 }}
              showCoverageOnHover={false}
            >
              {data?.map((item, idx) => (
                <ObjectMarker
                  item={item}
                  key={idx}
                  expeditionId={expeditionId}
                  onClick={onMarkerClick}
                  isInteractive={false}
                />
              ))}
            </MarkerClusterGroupElement>
            <FeatureGroup>
              {areasData?.map((item, idx) => (
                <WaterAreaPolygon
                  item={item}
                  key={idx}
                  onAreasRender={onAreasRender}
                  isNonInteractive={true}
                />
              ))}
            </FeatureGroup>
          </MapBaseLayer>
        </Layout.Content>
        <Layout.Footer>
          <Logo />
          <ZoomControl />
          <LiveCoordinatePanel />
          <RulerPanel />
        </Layout.Footer>
      </Layout>
      <SearchPanel expeditionId={expeditionId} withFormularNavigation={false} />
    </>
  );
};
