import React, { FC } from 'react';
import styles from './black-tooltip.module.scss';
import { Tooltip } from 'antd';
import { IBlackTooltip } from './black-tooltip.types';

export const BlackTooltip: FC<IBlackTooltip> = ({ title, placement, children, ...rest }) => {
  if (title) {
    return (
      <Tooltip overlayClassName={styles.tooltip} title={title} placement={placement} {...rest}>
        {children}
      </Tooltip>
    );
  }
  return null;
};
