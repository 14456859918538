import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMetaDataMap, MetaDataStateType } from '@/types';
import { resetAction } from '@/state/actions';

const initialState: MetaDataStateType = {
  isMetaLogOpen: false,
  collectMetaLog: [],
  isMetaLogOpenTooltip: true,
};

const MetaLogSlice = createSlice({
  name: 'metaDataPanel',
  initialState,
  reducers: {
    setIsMetaLogOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isMetaLogOpen = payload;
    },
    setCollectMetaLog: (state, { payload }: PayloadAction<IMetaDataMap[]>) => {
      state.collectMetaLog = payload;
    },
    setIsMetaLogOpenTooltip: (state, { payload }: PayloadAction<boolean>) => {
      state.isMetaLogOpenTooltip = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAction, () => {
      return initialState;
    });
  },
});

export const { setIsMetaLogOpen, setCollectMetaLog, setIsMetaLogOpenTooltip } =
  MetaLogSlice.actions;
export const { reducer: metaLogReducer } = MetaLogSlice;
