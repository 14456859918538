import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Icon } from '@/components';
import styles from './map-table-panel-header.module.scss';
import {
  LINK_TO_EXPEDITION_TAB,
  LINK_TO_EXPEDITION_TABLE,
  LINK_TO_LAYERS_TAB,
  LINK_TO_LAYERS_TABLE,
} from '@/constants';
import { useAppSelector } from '@/state';
import { Permissions, ExpeditionTablesTabsTypes, LayerTablesTabsTypes } from '@/constants/enums';

export const MapTablePanelHeader: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  const customClasses = (path: string): string =>
    [styles.title, pathname.includes(path) ? styles.active : ''].join(' ');

  return (
    <div className={styles.header}>
      {userPermissions?.includes(Permissions.mapDataViewing) ? (
        <div
          className={customClasses(LINK_TO_LAYERS_TABLE)}
          onClick={() => navigate(LINK_TO_LAYERS_TAB(LayerTablesTabsTypes.bathymetric))}
        >
          <Icon icon="outline-layers" size={20} />
          <span>Таблицы карты</span>
        </div>
      ) : null}
      {userPermissions?.includes(Permissions.expeditionsDataViewing) ? (
        <div
          className={customClasses(LINK_TO_EXPEDITION_TABLE)}
          onClick={() => navigate(LINK_TO_EXPEDITION_TAB(ExpeditionTablesTabsTypes.expeditions))}
        >
          <Icon icon="outline-ship" size={20} />
          <span>Таблицы экспедиции</span>
        </div>
      ) : null}
    </div>
  );
};
