import React, { FC, useState } from 'react';
import styles from './time-picker-field.module.scss';
import { Field, FieldProps, useField } from 'formik';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { BlackTooltip, ErrorMessage, Icon, TimePickerFieldPropsType } from '@/components';
import dayjs from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import { ComponentSize } from '@/constants/enums';

const DatePicker = generatePicker(dayjsGenerateConfig);

export const TimePickerField: FC<TimePickerFieldPropsType> = ({
  name,
  label,
  required = false,
  className,
  size = ComponentSize.medium,
  isDefaultPopupContainer = true,
}) => {
  const [, { value }, { setValue, setTouched }] = useField<string[]>(name);

  const [isOpen, setIsOpen] = useState(false);

  const customStyles = [styles.wrapper, size ? styles[size] : '', className].join(' ');
  const iconClassName = [styles['suffix-icon'], isOpen ? styles['suffix-icon-active'] : ''].join(
    ' ',
  );

  const handleChange = (value: [string, string]): void => {
    setTouched(!!value[0] && !!value[1]);
    const preparedValue = value[0] && value[1] ? value : value[0] ? [value[0]] : [];
    setValue(preparedValue);
  };

  return (
    <div className={customStyles} key={name}>
      {label && <label className="mb-1">{required ? label + '*' : label}</label>}
      <Field name={name}>
        {({ field }: FieldProps): JSX.Element => (
          <DatePicker.RangePicker
            {...field}
            id={name}
            name={name}
            popupClassName={styles.popup}
            getPopupContainer={(trigger) =>
              !isDefaultPopupContainer ? (trigger.parentElement as HTMLElement) : document.body
            }
            picker="time"
            mode={undefined}
            locale={locale}
            open={isOpen}
            format="HH:mm"
            value={[
              value[0] ? dayjs(value[0], 'HH:mm') : null,
              value[1] ? dayjs(value[1], 'HH:mm') : null,
            ]}
            onCalendarChange={(dayJsValue, value) => handleChange(value)}
            onOpenChange={setIsOpen}
            suffixIcon={
              <>
                <BlackTooltip placement={'left'} title="Добавить новую дату">
                  <div className={iconClassName}>
                    <Icon size={16} icon="outline-clock" />
                  </div>
                </BlackTooltip>
              </>
            }
          />
        )}
      </Field>
      <ErrorMessage name={name} className={styles.error} />
    </div>
  );
};
