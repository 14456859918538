import React, { FC, useState } from 'react';
import { Icon } from '@/components';
import { SearchPanelErrorPlaceholderType } from '../search-panel.types';
import styles from './styles.module.scss';

export const SearchPanelErrorPlaceholder: FC<SearchPanelErrorPlaceholderType> = ({
  error,
}): JSX.Element => {
  const [showError, setShowError] = useState(false);

  const toggleShowError = () => {
    setShowError((prevState) => !prevState);
  };

  return (
    <div className={styles.error}>
      {showError ? (
        <div className={styles['error-additional']}>
          <Icon
            size={16}
            icon="outline-close"
            onClick={() => toggleShowError()}
            className="text-dark ms-auto"
          />
          <span>{error.toString()}</span>
        </div>
      ) : (
        <div className={styles['error-default']}>
          <Icon size={32} icon="outline-exclamation-triangle" className="text-danger me-auto" />
          <span>Произошла ошибка. </span>
          {error && <span onClick={() => toggleShowError()}>Подробнее об ошибке.</span>}
        </div>
      )}
    </div>
  );
};
