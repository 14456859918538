import React from 'react';
import {
  MembersTab,
  SectorsTab,
  ShipsBasesTab,
  ToolsTab,
  ExpeditionsTab,
  WaterAreasTab,
} from './expedition-tabs';
import { ObjectsTab } from '../map-table-panels/layers-tabs';
import { ExpeditionTablesTabsTypes } from '@/constants/enums';

export const ExpeditionTabs = [
  {
    label: 'Экспедиции',
    key: ExpeditionTablesTabsTypes.expeditions,
    children: <ExpeditionsTab />,
  },
  {
    label: 'Объекты исследования',
    key: ExpeditionTablesTabsTypes.objectsResearch,
    children: <ObjectsTab />,
  },
  {
    label: 'Акватории',
    key: ExpeditionTablesTabsTypes.waterAreas,
    children: <WaterAreasTab />,
  },
  {
    label: 'Сектора',
    key: ExpeditionTablesTabsTypes.sectors,
    children: <SectorsTab />,
  },
  {
    label: 'Базы судов',
    key: ExpeditionTablesTabsTypes.shipBases,
    children: <ShipsBasesTab />,
  },
  {
    label: 'МТО',
    key: ExpeditionTablesTabsTypes.tools,
    children: <ToolsTab />,
  },
  {
    label: 'Список участников',
    key: ExpeditionTablesTabsTypes.members,
    children: <MembersTab />,
  },
];
