import React, { FC, useState } from 'react';
import { Button } from 'antd';
import styles from './copy-button.module.scss';
import { CopyButtonPropsTypes } from './copy-button.types';
import { Icon, BlackTooltip } from '@/components';

export const CopyButton: FC<CopyButtonPropsTypes> = ({
  value = '',
  size = 12,
  icon = 'outline-stickies',
  className = '',
  iconClassName = '',
  onCopy,
  tooltipPlacement = 'left',
  tooltipText,
  disabled = false,
}): JSX.Element => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [copyIsDone, setCopyIsDone] = useState(false);

  const preparedText =
    tooltipText || copyIsDone ? 'Текст успешно скопирован' : 'Скопировать в буфер обмена';

  const handleCopyValue = (value: string): void => {
    navigator.clipboard.writeText(value).then(() => {
      onCopy && onCopy();
      setShowTooltip(true);
      setCopyIsDone(true);
      setTimeout(() => setShowTooltip(false), 1500);
      setTimeout(() => setCopyIsDone(false), 10000);
    });
  };

  return (
    <div
      className={styles.wrapper + ' ' + className}
      style={{
        width: size,
        height: size,
        lineHeight: size.toString() + 'px',
      }}
    >
      <BlackTooltip
        title={preparedText}
        open={showTooltip || undefined}
        placement={tooltipPlacement}
      >
        <Button
          type="text"
          disabled={disabled}
          onClick={() => handleCopyValue(value)}
          style={{
            width: size,
            height: size,
            lineHeight: size.toString() + 'px',
          }}
        >
          <Icon size={size} icon={icon} className={iconClassName} />
        </Button>
      </BlackTooltip>
    </div>
  );
};
