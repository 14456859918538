import React, { FC } from 'react';
import styles from './icon-button.module.scss';
import { Icon, Spinner, Tooltip } from '@/components';
import { IconButtonPropsType } from './icon-button.types';

export const IconButton: FC<IconButtonPropsType> = ({
  icon,
  tooltipContent,
  onClick,
  loading,
  tooltipPlacement = 'bottom',
  className = '',
  isActive = false,
  iconSize = 16,
  iconColor = '#151515',
  activeColor = '#00b2ff',
}) => {
  const customStyles = [styles.wrapper, isActive ? styles.active : '', className].join(' ');

  return tooltipContent ? (
    <Tooltip title={tooltipContent} placement={tooltipPlacement}>
      {loading ? (
        <Spinner className={styles.loader} />
      ) : (
        <div onClick={() => onClick && onClick()} className={customStyles}>
          <Icon size={iconSize} icon={icon} color={isActive ? activeColor : iconColor} />
        </div>
      )}
    </Tooltip>
  ) : loading ? (
    <Spinner className={styles.loader} />
  ) : (
    <div onClick={() => onClick && onClick()} className={customStyles}>
      <Icon size={iconSize} icon={icon} color={iconColor} />
    </div>
  );
};
