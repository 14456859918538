import { resetAction } from '@/state/actions';
import { RegionsStateType } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: RegionsStateType = {
  isOpenRegionTooltip: true,
};

const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    setIsOpenRegionTooltip: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenRegionTooltip = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAction, () => {
      return initialState;
    });
  },
});

export const { setIsOpenRegionTooltip } = regionsSlice.actions;
export const { reducer: regionsReducer } = regionsSlice;
