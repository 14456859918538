import React, { FC } from 'react';
import { menuData, ObjectCardItem } from '@/components';
import { ObjectCardPropsType } from '../object-card-panel.types';

export const ObjectBottomDetailsSection: FC<ObjectCardPropsType> = ({
  onSubmit,
  success,
  loading,
  isEditable,
}) => {
  const fields = [
    {
      label: 'Величина погружения в грунт, м',
      placeholder: 'Нет информации',
      name: 'deepening',
    },
    {
      label: 'Расстояниие между корпусом и грунтом (место)',
      placeholder: 'Расстояние не указано',
      name: 'obj_delta',
    },
    {
      label: 'Направление истинного курса судна',
      placeholder: 'Направление не указано',
      name: 'obj_direction',
    },
    {
      label: 'Надстройка: ширина/ длина / высота',
      placeholder: 'Нет информации',
      name: 'obj_details',
    },
    {
      label: 'Замер глубины по периметру судна',
      placeholder: 'Глубина не указана',
      name: 'obj_environment',
    },
  ];

  return (
    <ObjectCardItem
      title={menuData[2]?.subtitle?.[3].label}
      smallTitle={true}
      type="column"
      fields={fields}
      isEditable={isEditable}
      id={menuData[2]?.subtitle?.[3].link}
      onSubmit={() => onSubmit()}
      success={success}
      loading={loading}
    />
  );
};
