import React, { FC } from 'react';
import styles from './modal-header-button.module.scss';
import { BlackTooltip, Icon, Spinner } from '@/components';
import { ModalHeaderButtonPropsType } from './modal-header-button.types';

export const ModalHeaderButtons: FC<ModalHeaderButtonPropsType> = ({
  icon,
  text,
  onClick,
  loading,
  placement = 'bottom',
  className = '',
  disable = false,
}) => {
  const customStyles = [styles.wrapper, disable ? styles.disable : '', className].join(' ');

  return (
    <BlackTooltip title={text} placement={placement}>
      {loading ? (
        <Spinner className={styles.loader} />
      ) : (
        <div onClick={() => onClick()} className={customStyles}>
          <Icon size={16} icon={icon} />
        </div>
      )}
    </BlackTooltip>
  );
};
