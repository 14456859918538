import React, { FC, useEffect } from 'react';
import { BATHYMETRY_LAYER } from '@/constants';
import { useMap } from '@/context';
import { useDispatch } from 'react-redux';
import {
  BathymetricLayer,
  OoptLayer,
  MoLayer,
  GalsesLayer,
  OldBathymetryLayer,
  TopomapsLayer,
  UtmBorders,
  ObjectsLayer,
  RegionsLayer,
  ShipsBasesLayer,
  WaterAreasLayer,
  SectorsLayer,
  MetaLogLayer,
  MetaGalsLayer,
} from '../layers';
import { setSelectedLayer, toggleObjectLayer } from '@/state/slice';
import { MapBaseLayer } from '@/components';
import { Permissions } from '@/constants/enums';
import { useAppSelector } from '@/state';

export const MapView: FC = () => {
  const { map } = useMap();
  const dispatch = useDispatch();
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  useEffect(() => {
    if (map) {
      if (localStorage.getItem('layers')) {
        const layersFromStorage = localStorage.getItem('layers');
        if (layersFromStorage) {
          const layers = JSON.parse(layersFromStorage) as string[];
          for (let i = 0; i < layers.length; i++) {
            dispatch(setSelectedLayer(layers[i]));
          }
        }
      } else {
        dispatch(toggleObjectLayer(true));
        dispatch(setSelectedLayer(BATHYMETRY_LAYER));
      }
    }
  }, [map]);

  return (
    <MapBaseLayer>
      <MoLayer />
      <OoptLayer />
      <TopomapsLayer />
      <UtmBorders />
      {userPermissions?.includes(Permissions.expeditionsDataViewing) ? (
        <>
          <SectorsLayer />
          <ShipsBasesLayer />
          <WaterAreasLayer />
        </>
      ) : null}
      {userPermissions?.includes(Permissions.mapDataViewing) ? (
        <>
          <ObjectsLayer />
          <RegionsLayer />
          <MetaLogLayer />
          <MetaGalsLayer />
          <GalsesLayer />
          <OldBathymetryLayer />
          <BathymetricLayer />
        </>
      ) : null}
    </MapBaseLayer>
  );
};
