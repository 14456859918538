import React, { FC, useEffect, useState } from 'react';
import { ExpeditionCardSection, SectionWithSelectPropsType } from '../index';
import {
  ConfirmModal,
  defaultSearchProps,
  DropdownFieldDataType,
  FieldType,
  Icon,
  PanelTableButton,
  Table,
  TableCell,
  TableModal,
} from '@/components';
import {
  handleSortNumber,
  handleSortString,
  openErrorNotify,
  openInfoNotify,
  workTimeValidator,
} from '@/utils';
import { ColumnsType } from 'antd/lib/table';
import { ExpeditionTableObjectWork, IObject } from '@/types';
import styles from '../section.module.scss';
import {
  useCreateExpeditionObjectWorkMutation,
  useDeleteExpeditionObjectWorkMutation,
  useEditExpeditionObjectWorkMutation,
  useGetObjectsQuery,
} from '@/state/api';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { findIndex } from 'lodash';
import { InputTypes } from '@/constants/enums';
import { useNavigate } from 'react-router';
import { LINK_TO_EXPEDITION_OBJECT_STEP_PAGE } from '@/constants';

export const ObjectWorkSection: FC<SectionWithSelectPropsType> = ({
  isEditable,
  item,
  selectData,
  transformToMinutes,
  transformToHours,
  ...rest
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState<ExpeditionTableObjectWork>();

  const navigate = useNavigate();

  const { isLoading, isError } = useGetObjectsQuery({ expId: item?.id as number });
  const [
    createObjectWork,
    {
      isLoading: createLoading,
      isError: createError,
      isSuccess: createSuccess,
      reset: createReset,
    },
  ] = useCreateExpeditionObjectWorkMutation();
  const [
    editObjectWork,
    { isLoading: editLoading, isError: editError, isSuccess: editSuccess, reset: editReset },
  ] = useEditExpeditionObjectWorkMutation();
  const [
    deleteObjectWork,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
      reset: deleteReset,
    },
  ] = useDeleteExpeditionObjectWorkMutation();

  const validationSchema = Yup.object({
    object_id: Yup.string().required('Пожалуйста, выберите объект'),
    dive_time: Yup.string()
      .required('Пожалуйста, укажите время поисковых работ')
      .test('', 'Пожалуйста, укажите время в формате чч:мм', (value) => workTimeValidator(value)),
    ROUUV_time: Yup.string()
      .required('Пожалуйста, укажите время поисковых работ')
      .test('', 'Пожалуйста, укажите время в формате чч:мм', (value) => workTimeValidator(value)),
    HUV_time: Yup.string()
      .required('Пожалуйста, укажите время поисковых работ')
      .test('', 'Пожалуйста, укажите время в формате чч:мм', (value) => workTimeValidator(value)),
    search_time: Yup.string()
      .required('Пожалуйста, укажите время поисковых работ')
      .test('', 'Пожалуйста, укажите время в формате чч:мм', (value) => workTimeValidator(value)),
  });

  const initialValues = {
    expedition_id: item?.id,
    object_id: '',
    dive_time: '',
    ROUUV_time: '',
    HUV_time: '',
    search_time: '',
  };

  const objectsIds = item?.works.map(({ object_id }) => object_id);

  const preparedSelectionData = selectData?.filter(
    ({ value }) => !objectsIds?.includes(Number(value)),
  );

  const handlePrepareValue = (selectedItem?: ExpeditionTableObjectWork) => {
    if (selectedItem) {
      return Object.fromEntries(
        Object.entries(selectedItem).map(([key, value]) => {
          switch (key) {
            case 'dive_time':
            case 'ROUUV_time':
            case 'HUV_time':
            case 'search_time':
              return [key, transformToHours && transformToHours(value as number)];
            default:
              return [key, value];
          }
        }),
      );
    }
  };

  const handleClick = (e?: unknown, index?: number) => {
    if (index === 0) {
      setShowAddModal((prevState) => !prevState);
    } else {
      navigate(LINK_TO_EXPEDITION_OBJECT_STEP_PAGE(item?.id));
    }
  };

  const handleSetSelectedItem = (id: number) => {
    setSelectedItem(item?.objects_works?.find((item) => item.id === id));
  };

  const handleDelete = (id: number) => {
    deleteObjectWork({
      id: id,
      expedition_id: item?.id as number,
    });
  };

  const handleEdit = (value: FormikValues) => {
    const preparedValue = {
      ...value,
      expedition_id: item?.id,
      dive_time: transformToMinutes && transformToMinutes(value.dive_time),
      ROUUV_time: transformToMinutes && transformToMinutes(value.ROUUV_time),
      HUV_time: transformToMinutes && transformToMinutes(value.HUV_time),
      search_time: transformToMinutes && transformToMinutes(value.search_time),
    };
    editObjectWork(preparedValue);
  };

  const handleAdd = (value: FormikValues) => {
    const preparedValue = {
      ...value,
      object_id: +value.object_id,
      dive_time: transformToMinutes && transformToMinutes(value.dive_time),
      ROUUV_time: transformToMinutes && transformToMinutes(value.ROUUV_time),
      HUV_time: transformToMinutes && transformToMinutes(value.HUV_time),
      search_time: transformToMinutes && transformToMinutes(value.search_time),
    };
    createObjectWork(preparedValue);
  };

  const toggleDelete = (id: number) => {
    handleSetSelectedItem(id);
    setShowDeleteModal((prevState) => !prevState);
  };

  const toggleEdit = (id: number) => {
    handleSetSelectedItem(id);
    setShowEditModal((prevState) => !prevState);
  };

  const searchCustom = (value: string, record: ExpeditionTableObjectWork) => {
    return record.object?.wm_name.includes(value);
  };

  const editableColumns: ColumnsType<ExpeditionTableObjectWork> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      fixed: 'left',
      width: 62,
      sorter: (first, second) =>
        handleSortNumber(
          findIndex(item?.objects_works, first),
          findIndex(item?.objects_works, second),
        ),
      render: (value: string, row) => <TableCell value={findIndex(item?.objects_works, row) + 1} />,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 62,
      sorter: (first, second) => handleSortNumber(first.id, second.id),
    },
    {
      title: <Icon size={12} icon="outline-pencil" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      fixed: 'left',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleEdit(id)}
          tooltipTitle="Редактировать отчет о работе на объекте"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      width: 40,
      dataIndex: 'id',
      align: 'center',
      fixed: 'left',
      key: 'icon',
      render: (id: number) => (
        <PanelTableButton
          onClick={() => toggleDelete(id)}
          tooltipTitle="Удалить отчет о работе на объекте"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'Объект',
      dataIndex: 'object',
      key: 'object.name',
      align: 'left',
      render: (value: IObject) => <TableCell value={value?.wm_name} showAllText={true} />,
      sorter: (first, second) => handleSortString(first?.object?.wm_name, second?.object?.wm_name),
      ...defaultSearchProps('sector', (value, record) => searchCustom(value as string, record)),
    },
    {
      title: 'Водолазные работы, чч:мм',
      dataIndex: 'dive_time',
      key: 'dive_time',
      align: 'left',
      width: 225,
      render: (value: number) => {
        const preparedData = transformToHours && transformToHours(value);

        return <TableCell value={preparedData} maxWidth={175} isDay={false} />;
      },
      sorter: (first, second) => handleSortNumber(first?.dive_time, second?.dive_time),
    },
    {
      title: 'ТНПА, чч:мм',
      dataIndex: 'ROUUV_time',
      key: 'ROUUV_time',
      align: 'left',
      width: 150,
      render: (value: number) => {
        const preparedData = transformToHours && transformToHours(value);

        return <TableCell value={preparedData} isDay={false} maxWidth={175} />;
      },
      sorter: (first, second) => handleSortNumber(first?.ROUUV_time, second?.ROUUV_time),
    },
    {
      title: 'ОПА, чч:мм',
      dataIndex: 'HUV_time',
      key: 'HUV_time',
      align: 'left',
      width: 150,
      render: (value: number) => {
        const preparedData = transformToHours && transformToHours(value);

        return <TableCell value={preparedData} isDay={false} maxWidth={175} />;
      },
      sorter: (first, second) => handleSortNumber(first?.HUV_time, second?.HUV_time),
    },
    {
      title: 'Поиск, чч:мм',
      dataIndex: 'search_time',
      key: 'search_time',
      align: 'left',
      width: 150,
      render: (value: number) => {
        const preparedData = transformToHours && transformToHours(value);

        return <TableCell value={preparedData} isDay={false} maxWidth={175} />;
      },
      sorter: (first, second) => handleSortNumber(first?.search_time, second?.search_time),
    },
  ];

  const readOnlyColumn = editableColumns.filter((item) => item.key !== 'icon');

  const fields: FieldType[] = [
    {
      label: 'Объект',
      name: 'object_id',
      type: 'dropdown',
      required: true,
      data: preparedSelectionData as DropdownFieldDataType[],
      error: isError,
      loading: isLoading,
    },
    {
      label: 'Водолазные работы, чч:мм',
      name: 'dive_time',
      type: InputTypes.text,
      placeholder: '23:59',
      required: true,
    },
    {
      label: 'ТНПА, чч:мм',
      name: 'ROUUV_time',
      type: InputTypes.text,
      placeholder: '23:59',
      required: true,
    },
    {
      label: 'ОПА, чч:мм',
      name: 'HUV_time',
      type: InputTypes.text,
      placeholder: '23:59',
      required: true,
    },
    {
      label: 'Поиск, чч:мм',
      name: 'search_time',
      type: InputTypes.text,
      placeholder: '23:59',
      required: true,
    },
  ];

  useEffect(() => {
    if (createSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        'Добавление работы по объектам исследования успешно выполнено',
      );
      createReset();
    }
    if (createError) {
      openErrorNotify(
        'Произошла ошибка',
        'При добавлении работы по объектам исследования произошла ошибка',
      );
      createReset();
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (editSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        'Редактирование работы по объектам исследования успешно выполнено',
      );
      editReset();
    }
    if (editError) {
      openErrorNotify(
        'Произошла ошибка',
        'При редактировании работы по объектам исследования произошла ошибка',
      );
      editReset();
    }
  }, [editSuccess, editError]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        'Удаление работы по объектам исследования успешно выполнено',
      );
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify(
        'Произошла ошибка',
        'При удалении работы по объектам исследования произошла ошибка',
      );
      deleteReset();
    }
  }, [deleteSuccess, deleteError]);

  return (
    <ExpeditionCardSection
      {...rest}
      buttonText={['Добавить объект из списка', 'Добавить объект на карте']}
      onClickAdd={handleClick}
      isEditable={isEditable}
    >
      <>
        {item?.objects_works && item?.objects_works.length > 0 && (
          <Table
            withSelection={false}
            dataSource={item?.objects_works}
            columns={isEditable ? editableColumns : readOnlyColumn}
            className={styles.table}
            noDataClassName={styles['no-data']}
            scroll={{ x: 1225, y: 300 }}
            withPagination={false}
          />
        )}
        <TableModal
          onSubmit={(value) => handleAdd(value)}
          onClose={() => setShowAddModal(false)}
          validationSchema={validationSchema}
          initialValues={initialValues}
          loading={createLoading}
          success={createSuccess}
          buttonText="Добавить"
          open={showAddModal}
          fields={fields}
          width={563}
          title="Добавление объекта"
        />
        <TableModal
          onSubmit={(value) => handleEdit(value)}
          onClose={() => setShowEditModal(false)}
          validationSchema={validationSchema}
          initialValues={handlePrepareValue(selectedItem) as ExpeditionTableObjectWork}
          loading={editLoading}
          success={editSuccess}
          buttonText="Изменить"
          open={showEditModal}
          fields={fields}
          width={563}
          title="Изменение объекта"
          additionalTitle={
            <div className={styles['additional-title']}>{selectedItem?.object?.wm_name}</div>
          }
        />
        <ConfirmModal
          open={showDeleteModal}
          title="Подтвердите удаление объекта"
          content={
            <p>
              Вы уверены, что хотите удалить&nbsp;
              {selectedItem?.object?.wm_name ? (
                <strong>{selectedItem?.object?.wm_name}</strong>
              ) : (
                'выбранный объект'
              )}
              ?
            </p>
          }
          submitButtonText="Удалить"
          cancelButtonText="отменить"
          onSubmit={() => selectedItem && handleDelete(selectedItem?.id)}
          onCancel={() => setShowDeleteModal(false)}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
      </>
    </ExpeditionCardSection>
  );
};
