import React, { FC, useEffect, useRef } from 'react';
import { MarkerClusterGroup, createClusterCustomIcon, openErrorNotify } from '@/utils';
import { ObjectMarker } from '../../elements';
import type { MarkerClusterGroup as MarkerClusterGroupType } from 'leaflet';
import { useAppSelector } from '@/state';
import styles from './canvas-layer.module.scss';
import { useGetObjectsQuery } from '@/state/api';
import {
  CULTURE_OBJECT_LAYER,
  DANGEROUS_OBJECT_LAYER,
  MILITARY_OBJECT_LAYER,
  TECHNICAL_OBJECT_LAYER,
  UNDISCOVERED_OBJECT_LAYER,
} from '@/constants';
import { IObject } from '@/types';

// eslint-disable-next-line
const MarkerClusterGroupElement = MarkerClusterGroup as any;

export const ObjectsLayer: FC<{ defaultObjects?: IObject[]; isInteractive?: boolean }> = ({
  defaultObjects,
  isInteractive = true,
}): JSX.Element => {
  const selectedLayers = useAppSelector((state) => state.map.selectedLayers);
  const clusterGroupRef = useRef<MarkerClusterGroupType | null>(null);
  const { data: objectsData, isError } = useGetObjectsQuery();

  const preparedObjectsData = defaultObjects || objectsData?.objects || [];

  useEffect(() => {
    if (isError) {
      openErrorNotify('Произошла ошибка', 'Не удалось загрузить данные объектов исследования');
    }
  }, [isError]);

  return (
    <MarkerClusterGroupElement
      ref={clusterGroupRef}
      maxClusterRadius={20}
      iconCreateFunction={(cluster: MarkerClusterGroupType) =>
        createClusterCustomIcon(cluster, styles)
      }
      spiderLegPolylineOptions={{ opacity: 0 }}
      showCoverageOnHover={false}
    >
      {selectedLayers.includes(UNDISCOVERED_OBJECT_LAYER)
        ? preparedObjectsData
            .filter((obj) => Number(obj.type) === 1)
            .map((item, idx) => (
              <ObjectMarker item={item} key={idx} isInteractive={isInteractive} />
            ))
        : []}
      {selectedLayers.includes(TECHNICAL_OBJECT_LAYER)
        ? preparedObjectsData
            .filter((obj) => Number(obj.type) === 2)
            .map((item, idx) => (
              <ObjectMarker item={item} key={idx} isInteractive={isInteractive} />
            ))
        : []}
      {selectedLayers.includes(DANGEROUS_OBJECT_LAYER)
        ? preparedObjectsData
            .filter((obj) => Number(obj.type) === 3)
            .map((item, idx) => (
              <ObjectMarker item={item} key={idx} isInteractive={isInteractive} />
            ))
        : []}
      {selectedLayers.includes(CULTURE_OBJECT_LAYER)
        ? preparedObjectsData
            .filter((obj) => Number(obj.type) === 4)
            .map((item, idx) => (
              <ObjectMarker item={item} key={idx} isInteractive={isInteractive} />
            ))
        : []}
      {selectedLayers.includes(MILITARY_OBJECT_LAYER)
        ? preparedObjectsData
            .filter((obj) => Number(obj.type) === 5)
            .map((item, idx) => (
              <ObjectMarker item={item} key={idx} isInteractive={isInteractive} />
            ))
        : []}
    </MarkerClusterGroupElement>
  );
};
