import { GraduatedCordFieldInputPropsType } from '../coordinate-converter.types';
import styles from '../coordinate-converter-panel.module.scss';
import { Input } from 'antd';
import React, { ChangeEvent, FC } from 'react';
import { useField, useFormikContext } from 'formik';

export const GraduatedCordFieldInput: FC<GraduatedCordFieldInputPropsType> = ({
  name,
  spanTitle,
  prevRef = null,
  currentRef = null,
  nextRef = null,
}): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const handleSelectNextField = (event: ChangeEvent<HTMLInputElement>, value: string): void => {
    //TODO: replace with keyboard ev for typing
    //@ts-ignore
    // eslint-disable-next-line
    const eventType = event.nativeEvent.inputType;

    if (value.length === 2) {
      if (eventType === 'insertText') {
        nextRef?.current?.focus();
      }
    }
    if (value.length === 0) {
      if (eventType === 'deleteContentBackward') {
        prevRef?.current?.focus();
      }
    }
  };

  const handleChangeField = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;

    if (!isNaN(Number(value))) {
      handleSelectNextField(event, value);
      if (value) {
        setFieldValue('cordType', 'graduatedCord');
      } else {
        setFieldValue('cordType', '');
      }
    }
  };

  return (
    <div className={styles.item}>
      <Input
        {...field}
        ref={currentRef}
        className={styles['graduated-cord-input']}
        onChange={handleChangeField}
      />
      <span>{spanTitle}</span>
    </div>
  );
};
