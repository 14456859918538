import React, { FC, useState } from 'react';
import { ConfirmModal, TableModal } from '@/components';
import { Field, FieldArray, Formik } from 'formik';
import { TableModalWithArrowPropsType } from './table-modal-with-arrow.types';
import { TableModalWithArrowLayout } from './table-modal-with-arrow-layout';
import { TableModalWithArrowTitle } from './table-modal-with-arrow-title';

export const TableModalWithArrow: FC<TableModalWithArrowPropsType> = ({
  onSubmit,
  validationSchema,
  onClose,
  fields,
  success,
  loading,
  selectedRows,
  initialValues,
}): JSX.Element => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalValue, setConfirmModalValue] = useState(0);

  const [formIsDirty, setFormIsDirty] = useState(false);
  const [currentModal, setCurrentModal] = useState(0);

  const preparedInitialValues = { array: selectedRows.map(() => initialValues) };
  const isLastForm = currentModal === selectedRows.length - 1;

  const handleClose = (dirty: boolean): void => {
    if (dirty) {
      !formIsDirty && setShowConfirmModal(true);
      setConfirmModalValue(0);
    } else {
      setCurrentModal(0);
      setFormIsDirty(false);
      onClose();
    }
  };

  const handleClick = (formIsDirty: boolean, value: number): void => {
    if (formIsDirty) {
      setShowConfirmModal(true);
      setConfirmModalValue(value);
    } else {
      setShowConfirmModal(false);
      setCurrentModal((prevState) => prevState + value);
    }
    if (value === 0) {
      handleClose(false);
    }
  };

  return (
    <Formik
      onSubmit={(array) => onSubmit(array)}
      enableReinitialize={true}
      initialValues={preparedInitialValues}
    >
      {({ setFieldValue, handleSubmit, values, dirty }) => (
        <FieldArray name="array">
          {() => (
            <div>
              <ConfirmModal
                success={success}
                loading={loading}
                open={showConfirmModal}
                onCancel={() => setShowConfirmModal(false)}
                onSubmit={() => handleClick(false, confirmModalValue)}
              />
              {selectedRows.map((item, index) => (
                <Field name={`array.${index}`} key={index}>
                  {() => (
                    <TableModal
                      open={index === currentModal}
                      validationSchema={validationSchema}
                      initialValues={values.array[index]}
                      loading={loading}
                      success={success}
                      fields={fields}
                      width={563}
                      buttonText="Добавить"
                      title="Добавить файлы лога"
                      additionalTitle={
                        <TableModalWithArrowTitle
                          index={index + 1}
                          length={selectedRows.length}
                          name={item.path as string}
                        />
                      }
                      additionalContent={
                        <TableModalWithArrowLayout
                          onClickBack={() => handleClick(formIsDirty, -1)}
                          onClickForward={() => handleClick(formIsDirty, 1)}
                          disableBackButton={currentModal === 0}
                          disableForwardButton={isLastForm}
                        />
                      }
                      onSubmit={(values) => {
                        setFieldValue(`array.${index}`, values);
                        setFieldValue(`array.${index}.path`, item.path);
                        isLastForm ? handleSubmit() : setCurrentModal((prevState) => prevState + 1);
                      }}
                      onClose={(confirmed) => (confirmed ? onClose() : handleClose(dirty))}
                      setFormIsDirty={setFormIsDirty}
                    />
                  )}
                </Field>
              ))}
            </div>
          )}
        </FieldArray>
      )}
    </Formik>
  );
};
