import React, { FC } from 'react';
import { Checkbox } from 'antd';
import styles from './checkbox.module.scss';
import { CheckboxPropsTypes } from './checkbox.types';

export const NewCheckbox: FC<CheckboxPropsTypes> = ({
  children,
  className,
  fill = false,
  error = false,
  disabled = false,
  ...rest
}): JSX.Element => {
  const customStyles = [
    className,
    styles.checkbox,
    fill ? styles.fill : '',
    error ? styles.error : '',
    disabled ? styles.disabled : '',
  ].join(' ');

  return (
    <div className={customStyles}>
      <Checkbox disabled={disabled} {...rest}>
        {children}
      </Checkbox>
    </div>
  );
};
