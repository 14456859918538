import React, { FC, useMemo } from 'react';
import { Polygon } from 'react-leaflet';
import { PathOptions, SVG } from 'leaflet';
import { FogPolygonPropsType } from './fog-polygon.types';

export const FogPolygon: FC<FogPolygonPropsType> = ({ polygon }) => {
  const polygonFillOpacity = 1;
  const polygonFillColor = '#B2B3B0';
  const polygonColor = '#B2B3B0';
  const weight = 2;

  const pathOptions: PathOptions = {
    fillOpacity: polygonFillOpacity,
    fillColor: polygonFillColor,
    color: polygonColor,
    weight: weight,
    lineJoin: 'round',
    renderer: new SVG({ padding: 1 }),
    lineCap: 'round',
  };

  return useMemo(
    () => <Polygon key={'fog'} positions={polygon} interactive={false} pathOptions={pathOptions} />,
    [polygon],
  );
};
