import { IPoint, IPointValue, IRenderTable, TRenderTable } from '@/types';
import styles from './route-panel-table/route-panel-table.module.scss';
import { DeleteCoordsButton } from './route-panel-table/route-table-rows';

export const MILE = 1.852;
export const MINUTE = 60;
export const SECOND = 60;
export const R = 6371;
export const RADIAN = Math.PI / 180;

export const handleGetDistance = (
  coords: IPoint[],
  lat1: number,
  lat2: number,
  lng1: number,
  lng2: number,
): number => {
  const deltaF = (lat2 - lat1) * RADIAN;
  const deltaL = (lng2 - lng1) * RADIAN;
  const a =
    Math.sin(deltaF / 2) * Math.sin(deltaF / 2) +
    Math.cos(lat1 * RADIAN) * Math.cos(lat2 * RADIAN) * Math.sin(deltaL / 2) * Math.sin(deltaL / 2);
  const b = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * b;
};

export const handleGetAzimuth = (
  coords: IPoint[],
  lat1: number,
  lat2: number,
  lng1: number,
  lng2: number,
): number => {
  const a = Math.sin((lng2 - lng1) * RADIAN) * Math.cos(lat2 * RADIAN);

  const b =
    Math.cos(lat1 * RADIAN) * Math.sin(lat2 * RADIAN) -
    Math.sin(lat1 * RADIAN) * Math.cos(lat2 * RADIAN) * Math.cos((lng2 - lng1) * RADIAN);

  let azimuth = Math.atan2(a, b) * (180 / Math.PI);
  azimuth += azimuth < 0 ? 360 : 0;

  return azimuth;
};

export const handleGetFormulaSecond = (distance: number, speed: number): number => {
  return (distance / (speed * MILE)) * MINUTE * SECOND;
};

export const defaultCoords: IPoint[] = [
  {
    key: '1001 coords',
    name: 'Координата T1',
    value: {
      id: 1001,
      lat: 'xx.xxxxx',
      lng: 'yy.yyyyy',
      gpsLat: 'xx°xx\'xx"xx',
      gpsLng: 'yy°yy\'yy"yy',
      renderLat: 'xx.xxxxx',
      renderLng: 'yy.yyyyy',
      isDefault: true,
    },
  },
  {
    key: '1002 coords',
    name: 'Координата T2',
    value: {
      id: 1002,
      lat: 'xx.xxxxx',
      lng: 'yy.yyyyy',
      gpsLat: 'xx°xx\'xx"xx',
      gpsLng: 'yy°yy\'yy"yy',
      renderLat: 'xx.xxxxx',
      renderLng: 'yy.yyyyy',
      isDefault: true,
    },
  },
];

export const renderMarkerTextToolTip = (point: IPointValue) => {
  const azimuth = parseFloat(point.azimuth as string).toFixed(2);
  const distance = (parseFloat(point.distance as string) / 1.852).toFixed(2);

  let text = `<div class="${styles['tooltip-all']}">`;
  if (point.distance) {
    text += ` <div class="${styles['tooltip-all-azimuth-and-distance']}">
                    <p class="${styles['tooltip-all-p']}">Азимут:</p>
                    <p class="${styles['tooltip-all-coords-p']}">
                        ${azimuth} °
                    </p>
                </div>
                <div class="${styles['tooltip-all-azimuth-and-distance']}">
                    <p class="${styles['tooltip-all-p']}">Расстояние:</p>
                    <p class="${styles['tooltip-all-coords-p']}">
                        ${distance} морские мили
                    </p>
                </div>`;
  }
  text += `<div class="${styles['tooltip-all-main']}">
                <p class="${styles['tooltip-all-p']}">Координаты: </p>
                <div class="${styles['tooltip-all-coords']}">
                    <p class="${styles['tooltip-all-coords-p']}">
                        X ${parseFloat(point.lat.toString()).toFixed(6)}
                    </p>
                    <p class="${styles['tooltip-all-coords-p']}">
                        Y ${parseFloat(point.lng.toString()).toFixed(6)}
                    </p>
                </div>
              </div>`;

  text += '</div>';

  return text;
};

export const renderPolylineTextToolTip = (distance: number, time: string) => {
  return `<div class="${styles['tooltip-all-coords-extra']}">
              <p class="${styles['tooltip-all-distance-p']}">
                ${distance.toFixed(2)} км
              </p>
              <p class="${styles['tooltip-all-time-p']}">${time}</p>
            </div>`;
};

export const renderTable = ({ coords, setCoords }: TRenderTable) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 304,
      render: (_: undefined) => <div className={styles.data}>{_}</div>,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: 304,
      render: ({ renderLat, renderLng, id }: IRenderTable) => (
        <div className={styles.data} key={id}>
          <p>{renderLat}</p>
          <p>{renderLng}</p>
          <DeleteCoordsButton key={id} id={id} coords={coords} setCoords={setCoords} />
        </div>
      ),
    },
  ];
};
