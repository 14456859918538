import { resetAction } from '@/state/actions';
import { IObject, ISector, PanelsStateType } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: PanelsStateType = {
  isSelectObjectOpen: false,
  isInfoTablePanelOpen: false,
  isMobile: false,
  isDesktop: true,
  isInfoModalShowing: false,
  metaDataPanelIsOpen: false,
  locatePanelIsOpen: false,
  rulerPanelIsOpen: false,
  layersPanelIsOpen: false,
  selectedObjects: [],
  selectedSectors: [],
  previousRoute: null,
};

const panelsSlice = createSlice({
  name: 'panels',
  initialState,
  reducers: {
    setIsSelectObjectOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isSelectObjectOpen = payload;
    },
    setIsInfoTablePanelOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isInfoTablePanelOpen = payload;
    },
    setIsMobile: (state, { payload }: PayloadAction<boolean>) => {
      state.isMobile = payload;
    },
    setIsDesktop: (state, { payload }: PayloadAction<boolean>) => {
      state.isDesktop = payload;
    },
    setIsInfoModalShowing: (state, { payload }: PayloadAction<boolean>) => {
      state.isInfoModalShowing = payload;
    },
    setLocatePanelIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.locatePanelIsOpen = payload;
    },
    setRulerPanelIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.rulerPanelIsOpen = payload;
    },
    setSelectedObjects: (state, { payload }: PayloadAction<Array<IObject>>) => {
      state.selectedObjects = payload;
    },
    setSelectedSectors: (state, { payload }: PayloadAction<Array<ISector>>) => {
      state.selectedSectors = payload;
    },
    setPreviousRoute: (state, { payload }: PayloadAction<null | string>) => {
      state.previousRoute = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAction, () => {
      return initialState;
    });
  },
});

export const {
  setIsDesktop,
  setIsInfoModalShowing,
  setIsInfoTablePanelOpen,
  setIsMobile,
  setIsSelectObjectOpen,
  setPreviousRoute,
  setSelectedObjects,
  setSelectedSectors,
  setLocatePanelIsOpen,
  setRulerPanelIsOpen,
} = panelsSlice.actions;
export const { reducer: panelsReducer } = panelsSlice;
