import { Button, ErrorMessage, Icon, Table, TransferFieldPropsType } from '@/components';
import { ComponentSize, FillTypes } from '@/constants/enums';
import { useField } from 'formik';
import { isEqual } from 'lodash';
import React, { useState, FC, useEffect } from 'react';
import { AnyObject } from 'yup/lib/types';
import styles from './transfer-field.module.scss';

export const TransferField: FC<TransferFieldPropsType> = ({
  leftTableColumns,
  rightTableDatasource,
  leftTableTitle,
  rightTableColumns,
  rightTableTitle,
  emptyLeftDisclaimer,
  loading,
  name,
  customLeftTableSelectionFilter,
  customRightTableSelectionFilter,
}) => {
  const [, { value, initialValue }, { setValue, setTouched }] =
    useField<Array<AnyObject & { id: number }>>(name);
  const valuesIds = value.map((item) => item.id);
  const availableData = rightTableDatasource.filter((item) => !valuesIds.includes(item.id));
  const availableLength = availableData.length;
  const valuesLength = value.length;
  const [selectedAvailable, setSelectedAvailable] = useState<number[]>([]);
  const [selectedAddedItem, setSelectedAddedItem] = useState<number[]>([]);

  const handleSelectAvailable = (
    data: Array<AnyObject & { id: number }>,
    row: AnyObject & { id: number },
    checked: boolean,
  ) => {
    const preparedData = customRightTableSelectionFilter
      ? customRightTableSelectionFilter(data, row, checked, availableData)
      : data.map((item) => item?.id);
    setSelectedAvailable(preparedData);
  };

  const handleSelectAdded = (
    data: Array<AnyObject & { id: number }>,
    row: AnyObject & { id: number },
    checked: boolean,
  ) => {
    const preparedData = customLeftTableSelectionFilter
      ? customLeftTableSelectionFilter(data, row, checked, value)
      : data.map((item) => item?.id);
    setSelectedAddedItem(preparedData);
  };

  const handleTransferRight = () => {
    const preparedData = value.filter((item) =>
      item?.id ? !selectedAddedItem.includes(item.id) : false,
    );
    setValue([...preparedData]);
    setSelectedAddedItem([]);
  };

  useEffect(() => {
    if (!isEqual(initialValue, value)) {
      setTouched(true);
    }
  }, [value]);

  const handleTransferLeft = () => {
    const preparedData = rightTableDatasource.filter((item) =>
      item.id ? selectedAvailable.includes(item.id) : false,
    );
    setValue([...value, ...preparedData]);
    setSelectedAvailable([]);
  };

  const customEmptyLeftPlaceholder = (
    <div className={styles.empty}>
      <Icon icon={'fill-image'} color={'#C0C1C9'} size={60} />
      <span className={styles.title}>Данные отсутствуют</span>
      {emptyLeftDisclaimer}
    </div>
  );

  const customEmptyRightPlaceholder = (
    <div className={styles.empty}>
      <Icon icon={'fill-image'} color={'#C0C1C9'} size={60} />
      <span className={styles.title}>Данные отсутствуют</span>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles['table-wrapper']}>
        <p>
          <b>{leftTableTitle.concat(' (', valuesLength.toString(), ')*')}</b>
        </p>
        <Table
          withPagination={false}
          scroll={{ y: 400, x: 200 }}
          loaderClassName={styles.loader}
          selectedRows={selectedAddedItem.map((id) => ({ id: id }))}
          emptyPlaceholder={customEmptyLeftPlaceholder}
          columns={leftTableColumns}
          dataSource={value.sort((a, b) => a.id - b.id)}
          onSelectAllRows={(checked, rows) => setSelectedAddedItem(rows.map((item) => item.id))}
          onSelectRow={(row, checked, checkedRows) => handleSelectAdded(checkedRows, row, checked)}
        />
      </div>
      <div className={styles['button-wrapper']}>
        <Button
          onClick={() => handleTransferLeft()}
          disable={!selectedAvailable.length || loading}
          className={styles['transfer-button']}
          size={ComponentSize.large}
          fillType={FillTypes.tertiary}
        >
          <Icon size={20} icon={'outline-chevron-left'} />
        </Button>
        <Button
          onClick={() => handleTransferRight()}
          disable={!selectedAddedItem.length}
          className={styles['transfer-button']}
          size={ComponentSize.large}
          fillType={FillTypes.tertiary}
        >
          <Icon size={20} icon={'outline-chevron-right'} />
        </Button>
      </div>
      <div className={styles['table-wrapper']}>
        <p>
          <b>{rightTableTitle.concat(' (', availableLength.toString(), ')')}</b>
        </p>
        <Table
          withPagination={false}
          columns={rightTableColumns}
          loading={loading}
          emptyPlaceholder={customEmptyRightPlaceholder}
          loaderClassName={styles.loader}
          onSelectAllRows={(checked, rows) => setSelectedAvailable(rows.map((item) => item.id))}
          selectedRows={selectedAvailable.map((id) => ({ id: id }))}
          onSelectRow={(row, checked, checkedRows) => {
            handleSelectAvailable(checkedRows, row, checked);
          }}
          scroll={{ y: 400, x: 200 }}
          dataSource={availableData}
        />
      </div>
      <ErrorMessage name={name} className={styles.error} />
    </div>
  );
};
