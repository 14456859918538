import { CoordsType, CoordsTypes } from '@/constants/enums';

export const emailValidator = (value?: string): boolean => {
  if (value === undefined) {
    return false;
  }
  const pattern =
    /^(([^А-Яа-я<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return pattern.test(value);
};

export const passwordStrengthValidation = (value?: string): boolean => {
  if (value === undefined) {
    return false;
  }
  const pattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@\]\[\{\\\@#$%^\/;:\?><&\'"+_.,=`\(\)~\-|*}]{8,}$/;

  return pattern.test(value);
};

export const graduatedCordValidator = (value?: string): boolean => {
  if (!value) {
    return false;
  }
  const pattern = /-?\d{1,2}°\d{1,2}'\d{1,2}.\d+"/;

  return pattern.test(value);
};

export const decimalCordValidator = (value?: string): boolean => {
  if (!value) {
    return false;
  }
  const pattern = /[^|\-?]\d{1,2}.\d+$/;

  return pattern.test(value);
};

export const cordSelectorValidator = (value?: string, cordType?: CoordsType): boolean => {
  if (!value) {
    return false;
  }
  if (cordType === CoordsTypes.decimal) {
    return decimalCordValidator(value);
  }

  return graduatedCordValidator(value);
};

export const onlyOneGap = (value?: string): boolean => {
  if (!value) {
    return false;
  }

  return value.replace(/\S/gi, '').length <= 1;
};

export const onlyEnglishSymbolsValidator = (value?: string): boolean => {
  if (!value) {
    return false;
  }
  const pattern = /^([A-Za-z\s/\-N№".,()]+)$/;

  return pattern.test(value);
};

export const onlyRussianSymbolsValidator = (value?: string): boolean => {
  if (!value) {
    return false;
  }
  const pattern = /^([а-яА-ЯёЁ\s/\-N№".,()]+)$/;

  return pattern.test(value);
};

export const decimalCoordsValidator = (value: string) => {
  const regexDecInt = /^[\-]{0,1}[0-9]{1,3}$/g;
  const regexDecFloat = /^[\-]{0,1}[0-9]{1,3}[\.]{1}[0-9]{1,20}$/g;

  return regexDecInt.test(value) || regexDecFloat.test(value);
};

export const graduatedCoordsValidator = (value: string, isLat = false) => {
  const regexDegInt = isLat
    ? /^[0-9]{1,3}[°]{1}[0-9]{1,2}[\']{1}[0-9]{1,2}[\"]{1}[NS]{1}$/g
    : /^[0-9]{1,3}[°]{1}[0-9]{1,2}[\']{1}[0-9]{1,2}[\"]{1}[WE]{1}$/g;
  const regexDegFloat = isLat
    ? /^[0-9]{1,3}[°]{1}[0-9]{1,2}[\']{1}[0-9]{1,2}[\.]{1}[0-9]{1,20}[\"]{1}[NS]{1}$/g
    : /^[0-9]{1,3}[°]{1}[0-9]{1,2}[\']{1}[0-9]{1,2}[\.]{1}[0-9]{1,20}[\"]{1}[WE]{1}$/g;

  return regexDegInt.test(value) || regexDegFloat.test(value);
};

export const timeValidator = (value?: string | number): boolean => {
  if (!value) {
    return false;
  }

  const pattern = /^(?:0\d|1\d|2[0-3]):[0-5]\d$/gm;

  return pattern.test(value.toString());
};

export const workTimeValidator = (value?: string | number): boolean => {
  if (!value) {
    return false;
  }

  const defaultPattern = /^(\d{1,2}|([1-9]\d*)):((0[1-9])|([1-5]\d))$/m;
  const hoursPattern = /^((0?[1-9])|([1-9]\d*)):0{2}$/m;
  const minutesPattern = /^0{1,2}:((0[1-9])|[1-9]{2})$/m;
  const emptyPattern = '00:00';

  const isValidByDefault = defaultPattern.test(value.toString());
  const isValidByMinutes = minutesPattern.test(value.toString());
  const isValidByHours = hoursPattern.test(value.toString());
  const isValidByEmptyPattern = value === emptyPattern;

  return isValidByDefault || isValidByMinutes || isValidByHours || isValidByEmptyPattern;
};

export const dividerAvailableValidator = (value?: string | number) => {
  if (!value) {
    return false;
  }

  const pattern = /(:|;|,|\.)/gm;

  return pattern.test(value.toString());
};
