import React, { FC, useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { AccessArea, ObjectsGroup, Role, Rule } from '@/types';
import { handleSortString } from '@/utils';
import styles from './roles-table.module.scss';
import { Icon, PanelTableButton, Table, TableCell, TableFilter } from '@/components';
import { RolesTablePropsType } from './roles-table.types';
import { Popover } from 'antd';

const CellWithTooltip: FC<{ value: string; tooltipContent?: string | null }> = ({
  value,
  tooltipContent,
}) => {
  return tooltipContent ? (
    <Popover
      overlayClassName={styles['tooltip-overlay']}
      showArrow={true}
      placement={'bottom'}
      align={{
        offset: [-13, -9],
      }}
      content={<span className={styles['tooltip-text']}>{tooltipContent}</span>}
    >
      <p className={styles.cell}>{value}</p>
    </Popover>
  ) : (
    <p className={styles.cell}>{value}</p>
  );
};

export const RolesTable: FC<RolesTablePropsType> = ({
  rulesData,
  rolesData,
  error,
  onDelete,
  onEdit,
  loading,
}): JSX.Element => {
  const rulesColumns: ColumnsType<Role> = useMemo(
    () =>
      rulesData.map((rule) => ({
        title: (
          <CellWithTooltip value={rule.name} tooltipContent={rule.description}></CellWithTooltip>
        ),
        dataIndex: 'rules',
        key: 'rules',
        align: 'center',
        width: 300,
        render: (value: Rule[]) => {
          const check = value.map((rule) => rule.id).includes(rule.id);

          return (
            <div style={{ height: '16px' }}>
              <Icon icon={check ? 'outline-check2' : 'outline-x'} size={16} />
            </div>
          );
        },
      })),
    [rulesData, rolesData],
  );

  const columns: ColumnsType<Role> = [
    {
      title: <Icon size={12} icon="outline-pencil" />,
      dataIndex: 'id',
      fixed: 'left',
      align: 'center',
      width: 40,
      render: (value: number, record) => (
        <PanelTableButton
          onClick={() => onEdit(record)}
          tooltipTitle="Редактировать роль"
          tooltipPlacement="bottom"
          icon="outline-pencil"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      dataIndex: 'id',
      fixed: 'left',
      align: 'center',
      width: 40,
      render: (value: number, record) => (
        <PanelTableButton
          onClick={() => onDelete(record)}
          tooltipTitle="Удалить роль"
          tooltipPlacement="bottom"
          icon="outline-trash"
          showTooltip={true}
          size={12}
        />
      ),
    },
    {
      title: 'Роль',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 300,
      render: (value: string, record) => (
        <CellWithTooltip value={value} tooltipContent={record?.description} />
      ),
      sorter: (first, second) => handleSortString(first?.name, second?.name),
      onFilter: (value, record) => record.id === value,
      filterDropdown: ({ filters, clearFilters, confirm, setSelectedKeys, selectedKeys }) => (
        <TableFilter
          filters={filters}
          onFilter={confirm}
          onClear={clearFilters}
          selectedKeys={selectedKeys}
          onChange={setSelectedKeys}
        />
      ),
      filters: rolesData?.map((role) => {
        return {
          value: role.id,
          text: role.name,
        };
      }),
    },
    {
      title: (
        <CellWithTooltip
          value={'Ограничение по группе объектов'}
          tooltipContent={'Ограничение по объектам, которые видят пользователи в данной роли'}
        ></CellWithTooltip>
      ),
      dataIndex: 'objects_group',
      key: 'objects_group',
      align: 'center',
      width: 300,
      render: (value: ObjectsGroup) => <TableCell value={value?.name || 'Нет'} maxWidth={200} />,
    },
    {
      title: (
        <CellWithTooltip
          value={'Ограничение по области видимости'}
          tooltipContent={'Ограничение по территории, которую видят пользователи в данной роли'}
        ></CellWithTooltip>
      ),
      dataIndex: 'access_area',
      key: 'access_area',
      align: 'center',
      width: 300,
      render: (value: AccessArea) => <TableCell value={value?.name || 'Нет'} maxWidth={200} />,
    },
    ...rulesColumns,
  ];

  return (
    <Table
      withSelection={false}
      dataSource={rolesData}
      columns={columns}
      error={error}
      loading={loading}
    />
  );
};
