import { ModalHeaderButtons } from '@/components/buttons';
import { Anchor } from 'antd';
import { FC, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Item } from './card-annotation-item';
import styles from './card-annotation.module.scss';
import { AnnotationPropsType } from './card-annotation.types';

type AnnotationSubComponents = {
  Item: typeof Item;
};

export const Annotation: FC<AnnotationPropsType> & AnnotationSubComponents = ({
  className,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenTransitionState, setIsOpenTransitionState] = useState(true);
  const [isTransitionHandling, setIsTransitionHandling] = useState(false);

  const customClasses = [
    styles.annotation,
    className,
    !isOpenTransitionState ? styles['annotation-close'] : '',
  ].join(' ');

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <CSSTransition
      in={isOpen}
      timeout={500}
      unmountOnExit={false}
      classNames={{
        enter: styles['annotation-transition-enter'],
        enterActive: styles['annotation-transition-enter-active'],
        exit: styles['annotation-transition-exit'],
        exitActive: styles['annotation-transition-exit-active'],
      }}
      onEntered={() => {
        setIsTransitionHandling(false);
        setIsOpenTransitionState(true);
      }}
      onExited={() => {
        setIsTransitionHandling(false);
        setIsOpenTransitionState(false);
      }}
      onEntering={() => setIsTransitionHandling(true)}
      onExiting={() => setIsTransitionHandling(true)}
    >
      <div className={customClasses}>
        <Anchor
          className={styles['annotation-wrapper']}
          getContainer={() => document.getElementById('scroll-area') || window}
          targetOffset={150}
        >
          {isOpenTransitionState && (
            <div className={styles['annotation-menu']}>
              <div className={styles['annotation-title']}>
                <span>Содержание</span>
                <ModalHeaderButtons
                  text={isTransitionHandling ? '' : isOpen ? 'Скрыть меню' : 'Показать меню'}
                  onClick={toggleIsOpen}
                  icon="outline-chevron-double-left"
                  placement="bottomLeft"
                  className={styles['annotation-btn']}
                />
              </div>
              <div>{children}</div>
            </div>
          )}
          {!isOpenTransitionState && (
            <ModalHeaderButtons
              text={isTransitionHandling ? '' : isOpen ? 'Скрыть меню' : 'Показать меню'}
              onClick={toggleIsOpen}
              icon="outline-chevron-double-right"
              placement="bottomLeft"
              className={styles['annotation-close-btn']}
            />
          )}
        </Anchor>
      </div>
    </CSSTransition>
  );
};

Annotation.Item = Item;
