import React, { FC } from 'react';
import styles from './sidebar-button.module.scss';
import { Button, Icon, Tooltip } from '@/components';
import { SidebarButtonPropsType } from './sidebar-button.types';
import { useLocation } from 'react-router';
import { ComponentSize, FillTypes } from '@/constants/enums';

export const SidebarButton: FC<SidebarButtonPropsType> = ({
  icon,
  title,
  path,
  name,
  isActive,
  className,
  onClick,
  hideTooltip = false,
  disable = false,
  children,
}): JSX.Element => {
  const { pathname } = useLocation();

  const isActiveByPath = path && pathname.includes(path);

  const buttonIsActive = isActive || isActiveByPath;

  const customClasses = [buttonIsActive ? styles.active : '', styles.wrapper, className].join(' ');

  if (buttonIsActive || hideTooltip) {
    return (
      <Tooltip placement={'left'} title={title}>
        <Button
          onClick={() => onClick && onClick(!!buttonIsActive, path || name)}
          className={customClasses}
          disable={disable}
          size={ComponentSize.large}
          fillType={FillTypes.tertiary}
        >
          {icon ? <Icon size={20} icon={icon} /> : children}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip placement={'left'} title={title}>
      <Button
        onClick={() => onClick && onClick(!!buttonIsActive, path || name)}
        className={customClasses}
        disable={disable}
        size={ComponentSize.large}
        fillType={FillTypes.tertiary}
      >
        {icon && <Icon size={20} icon={icon} />}
      </Button>
    </Tooltip>
  );
};
