import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import {
  DEFAULT_LAT,
  DEFAULT_LNG,
  DEFAULT_ZOOM,
  MAX_ZOOM,
  MIN_ZOOM,
  WMS_TILE_URL,
} from '@/constants';
import { LatLng, LatLngExpression, Polygon } from 'leaflet';
import { useMap } from '@/context';
import { MapEvents } from './map-events';
import 'leaflet-editable';
import { useAppSelector } from '@/state';
import { FogLayer } from '../layers';

export const MapBaseLayer: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const center = new LatLng(DEFAULT_LAT, DEFAULT_LNG);
  const { map, setMap } = useMap();
  const userInfo = useAppSelector((state) => state.auth.user);
  const accessAreaGeometry = userInfo?.role?.access_area?.restricted_area.coordinates;
  const restrictedAreaBounds = accessAreaGeometry
    ? new Polygon(accessAreaGeometry as LatLngExpression[][]).getBounds()
    : undefined;
  const minZoom = restrictedAreaBounds && map?.getBoundsZoom(restrictedAreaBounds);

  const documentResize = useCallback(() => {
    if (map) {
      try {
        map.invalidateSize && map.invalidateSize(true);
        minZoom && map?.setMinZoom(minZoom);
      } catch (e) {
        console.warn(e);
      }
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      document.addEventListener('resize', documentResize);
    }
    return () => {
      document.removeEventListener('resize', documentResize);
    };
  }, [map]);

  useEffect(() => {
    return () => {
      setMap(null);
    };
  }, []);

  const tileLayer = useMemo(
    () => (
      <TileLayer
        maxZoom={userInfo?.role?.access_area?.max_zoom || MAX_ZOOM}
        maxNativeZoom={userInfo?.role?.access_area?.max_zoom || MAX_ZOOM}
        attribution=""
        noWrap={true}
        url={WMS_TILE_URL.concat('/{z}/{x}/{y}.png')}
      />
    ),
    [],
  );

  useEffect(() => {
    minZoom && map?.setMinZoom(minZoom);
  }, [minZoom]);

  return (
    <MapContainer
      id="map"
      ref={setMap}
      editable={true}
      maxBoundsViscosity={1}
      preferCanvas={false}
      zoomAnimation={true}
      center={center}
      boundsOptions={{ padding: [0, 0] }}
      maxBounds={restrictedAreaBounds}
      bounds={restrictedAreaBounds}
      zoom={DEFAULT_ZOOM <= MAX_ZOOM && DEFAULT_ZOOM >= MIN_ZOOM ? DEFAULT_ZOOM : MIN_ZOOM}
      minZoom={minZoom || MIN_ZOOM}
      maxZoom={userInfo?.role?.access_area?.max_zoom || MAX_ZOOM}
      zoomControl={false}
      doubleClickZoom={false}
    >
      {tileLayer}
      <MapEvents />
      {accessAreaGeometry ? <FogLayer /> : null}
      {children}
    </MapContainer>
  );
};
