import { LatLng } from 'leaflet';
import { createContext, useContext, useState, Dispatch, SetStateAction } from 'react';

type ClickPositionContextType = {
  position: LatLng | null;
  setPosition: Dispatch<SetStateAction<LatLng | null>>;
};

export const ClickPositionContext = createContext<ClickPositionContextType>({
  position: null,
  setPosition: () => {},
});

export const useClickPosition = () => useContext(ClickPositionContext);

export const ClickPostionProvider = ({ children }: { children: JSX.Element }) => {
  const [position, setPosition] = useState<LatLng | null>(null);

  return (
    <ClickPositionContext.Provider value={{ position, setPosition }}>
      {children}
    </ClickPositionContext.Provider>
  );
};
