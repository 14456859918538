import { api } from '../api';
import {
  CoordsGals,
  DefaultRequestType,
  Gals,
  GalsMetaDataResponse,
  GetGalsFilesRequestType,
  GetGalsRequestType,
  NewGals,
} from '@/types';

export const galsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGalsList: builder.query<GetGalsRequestType, void>({
      query() {
        return {
          url: '/galses/list',
          method: 'GET',
          credentials: 'include',
        };
      },
      providesTags: ['Gals'],
    }),
    getUnloadGalsList: builder.query<GetGalsFilesRequestType, void>({
      query() {
        return {
          url: 'galses/unloaded',
          method: 'GET',
          credentials: 'include',
        };
      },
      providesTags: ['Gals'],
    }),
    createGals: builder.mutation<DefaultRequestType, { galses: NewGals[] }>({
      query(body) {
        return {
          url: '/galses/add',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Gals'],
    }),
    editGals: builder.mutation<DefaultRequestType, Gals>({
      query(body) {
        return {
          url: '/galses/edit',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Gals'],
    }),
    deleteGals: builder.mutation<
      DefaultRequestType,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/galses/delete',
          method: 'DELETE',
          body: { id: body.id },
          credentials: 'include',
        };
      },
    }),
    getCoordsGals: builder.mutation<GalsMetaDataResponse, CoordsGals>({
      query(point) {
        return {
          url: 'galses/checkpoint',
          method: 'GET',
          credentials: 'include',
          params: { x: point.x, y: point.y },
        };
      },
    }),
  }),
});

export const {
  useGetGalsListQuery,
  useGetCoordsGalsMutation,
  useCreateGalsMutation,
  useDeleteGalsMutation,
  useGetUnloadGalsListQuery,
  useEditGalsMutation,
} = galsApi;
