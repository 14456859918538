import { useEffect, useState } from 'react';
import { useMap } from '@/context';
import { useAppSelector } from '@/state';
import L, { LocationEvent, Marker } from 'leaflet';
import { getPointMarker } from '@/utils';
import { renderMarkerTextToolTip } from '@/components';
import { Permissions } from '@/constants/enums';

export const GeoPanel = () => {
  const { map } = useMap();
  const [open, setOpen] = useState(false);
  const [markerState, setMarkerState] = useState<Marker>();
  const isOpenGeo = useAppSelector((state) => state.panels.locatePanelIsOpen);
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  function onLocationFound(e: LocationEvent) {
    const marker = L.marker(e.latlng);

    marker.setIcon(getPointMarker('', NaN, 'first'));
    userPermissions?.includes(Permissions.specificDataViewing) &&
      marker.bindTooltip(renderMarkerTextToolTip(e.latlng));
    setMarkerState(marker);
  }

  useEffect(() => {
    if (isOpenGeo && map) {
      map.locate({ setView: true, maxZoom: 16 });
      map.on('locationfound', onLocationFound);
    }

    return () => {
      map?.stopLocate();
      map?.off('locationfound', onLocationFound);
    };
  }, [isOpenGeo]);

  useEffect(() => {
    if (map && isOpenGeo) {
      setOpen(true);
      if (open) {
        markerState?.addTo(map);
      }
    }

    return () => {
      setOpen(false);
      markerState?.remove();
    };
  }, [markerState, isOpenGeo]);

  return null;
};
