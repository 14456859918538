import { Radio } from 'antd';
import styles from './radio-button.module.scss';
import React, { FC } from 'react';
import { IRadioButtonPropsTypes } from './radio-button.types';
import { ComponentSize } from '@/constants/enums';

export const Group = Radio.Group;

export const NewRadioButton: FC<IRadioButtonPropsTypes> = ({
  children,
  className,
  size = ComponentSize.medium,
  labelClassName = '',
  disable = false,
  error = false,
  ...rest
}) => {
  const customStyles = [
    styles.wrapper,
    size ? styles[size] : '',
    disable ? styles.disable : '',
    error ? styles.error : '',
    className,
  ].join(' ');

  return (
    <div className={customStyles}>
      <Radio disabled={disable} {...rest}>
        <span className={styles.label + ' ' + labelClassName}>{children}</span>
      </Radio>
    </div>
  );
};
