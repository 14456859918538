import React, { FC, useEffect } from 'react';
import { useClickPosition, useMap } from '@/context';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Modal } from '@/components';
import styles from './route-panel.module.scss';
import { MAP } from '@/constants';
import { RoutePanelTitle } from './route-panel-title';
import { RoutePanelTable } from './route-panel-table';
import { useAppSelector } from '@/state';
import { setActivePanelRoute, setIsAddCoords } from '@/state/slice';
import { RoutePanelPropsType } from './route-panel.types';
import { LatLng } from 'leaflet';

export const RoutePanel: FC<RoutePanelPropsType> = ({ onClose }) => {
  const { map } = useMap();
  const checkMap = map !== null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultCloseModal = () => navigate(MAP);
  const handleCloseModal = onClose ?? defaultCloseModal;
  const position = useClickPosition();
  const isAddCoords = useAppSelector((state) => {
    return state.toolsPanels.routePanel.isAddCoords;
  });

  useEffect(() => {
    dispatch(setIsAddCoords(true));
  }, []);

  useEffect(() => {
    if (isAddCoords) {
      map?.getContainer().classList.add(styles['change-point']);
      dispatch(setActivePanelRoute(true));
    } else {
      map?.getContainer().classList.remove(styles['change-point']);
      dispatch(setActivePanelRoute(false));
    }
    return () => {
      position.setPosition({} as LatLng);
      map?.getContainer().classList.remove(styles['change-point']);
      dispatch(setActivePanelRoute(false));
    };
  }, [isAddCoords, checkMap]);

  return (
    <>
      <Modal
        width={656}
        wrapClassName={styles.wrapper}
        title={<RoutePanelTitle />}
        onClose={handleCloseModal}
      >
        <RoutePanelTable />
      </Modal>
    </>
  );
};
