import React, { FC, useState } from 'react';
import { RouteTableRows } from './route-table-rows';
import { RouteTableSummary } from './route-table-summary';
import { IPoint } from '@/types';
import { RoutePanelMapHandlers } from '../route-panel-map-handlers';
import { defaultCoords } from '../route-panel-utils';

export const RoutePanelTable: FC = () => {
  const storageCoords = localStorage.getItem('routePathCoords') || '';
  const oldCoords: IPoint[] = storageCoords ? (JSON.parse(storageCoords) as IPoint[]) : [];
  const [coords, setCoords] = useState<IPoint[]>(oldCoords);
  const changeCoords = coords.length === 0 ? defaultCoords : coords;

  return (
    <>
      <RoutePanelMapHandlers coords={changeCoords} />
      <RouteTableRows coords={changeCoords} setCoords={setCoords}>
        <RouteTableSummary coords={changeCoords} />
      </RouteTableRows>
    </>
  );
};
