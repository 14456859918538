import React, { PropsWithChildren, useEffect, useState } from 'react';
import styles from './table.module.scss';
import { Table } from 'antd';
import { ErrorBoundary, TableEmpty, TableError, TableLoader } from '@/components';
import { TablePropsType } from './table.types';
import { AnyObject } from 'yup/lib/types';
import { isEqual } from 'lodash';

export const CustomTable = <T extends { id: number }>({
  pageSize = 15,
  className,
  loading = false,
  error = false,
  loaderClassName,
  emptyPlaceholder,
  dataSource = [],
  onSelectRow,
  onSelectAllRows,
  withSelection = true,
  withPagination = true,
  paginationPosition = ['topRight'],
  idToDisable,
  selectedRows,
  disableRowSelection = false,
  errorClassName = '',
  errorMessage = '',
  noDataClassName,
  scroll = { x: 1574, y: 497 },
  ...rest
}: PropsWithChildren<TablePropsType<T>>) => {
  const preparedSelectedRows = selectedRows?.map(({ id }) => id);
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const [total, setTotal] = useState(dataSource?.length || 1);
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<Array<number>>(
    preparedSelectedRows || [],
  );

  const customClasses = [styles.table, className].join(' ');
  const customLoaderClasses = [styles.loader, loaderClassName].join(' ');
  const customErrorClasses = [styles.error, errorClassName].join(' ');

  const preparedScroll = scroll;

  const handleSelectRow = (id: number, checked: boolean): void => {
    if (checked) {
      setSelectedRowsKeys((prevState) => [...prevState, id]);
    } else {
      setSelectedRowsKeys((prevState) => prevState.filter((item) => item !== id));
    }
  };

  const handleSetPageSize = (size?: number) => {
    setCurrentPageSize(size || pageSize);
  };

  const disableRowSelect = (record: AnyObject): boolean => {
    if (selectedRowsKeys.some((item) => item === record.id)) {
      return false;
    }
    return disableRowSelection || record.id === idToDisable;
  };

  useEffect(() => {
    preparedSelectedRows &&
      !isEqual(selectedRowsKeys, preparedSelectedRows) &&
      setSelectedRowsKeys(preparedSelectedRows);
  }, [preparedSelectedRows]);

  useEffect(() => {
    setTotal(dataSource?.length);
  }, [loading]);

  return (
    <ErrorBoundary>
      {loading ? (
        <TableLoader className={customLoaderClasses} />
      ) : error ? (
        <TableError className={customErrorClasses} text={errorMessage} />
      ) : (
        <Table
          {...rest}
          dataSource={dataSource}
          className={customClasses}
          showSorterTooltip={false}
          tableLayout="fixed"
          scroll={preparedScroll}
          locale={{
            emptyText: emptyPlaceholder ? (
              emptyPlaceholder
            ) : (
              <TableEmpty className={noDataClassName} />
            ),
          }}
          onChange={(pagination, filters, FilterValue, sorter) => {
            setTotal(sorter.currentDataSource.length);
            handleSetPageSize(pagination.pageSize);
          }}
          rowKey={(record) => record?.id}
          pagination={
            withPagination
              ? {
                  total: total || 1,
                  pageSize: currentPageSize,
                  showSizeChanger: true,
                  position: paginationPosition,
                  className: styles.pagination,
                  pageSizeOptions: ['15', '20', '30', '60'],
                  locale: {
                    items_per_page: 'на странице',
                    jump_to: '1',
                    jump_to_confirm: '2',
                    page: '3',
                  },
                }
              : false
          }
          rowSelection={
            !withSelection
              ? undefined
              : {
                  columnWidth: 36,
                  getCheckboxProps: (record) => ({
                    id: record?.id.toString(),
                    disabled: disableRowSelect(record),
                  }),
                  fixed: 'left',
                  onSelect: (row, checked, selectedRows, event) => {
                    onSelectRow && onSelectRow(row, checked, selectedRows, event);
                    handleSelectRow(row?.id, checked);
                  },
                  onSelectAll: (checked, selectedRows, changeRows) => {
                    onSelectAllRows && onSelectAllRows(checked, selectedRows, changeRows);
                    !onSelectAllRows &&
                      changeRows?.forEach((item) => {
                        onSelectRow && onSelectRow(item, checked, selectedRows);
                        handleSelectRow(item?.id, checked);
                      });
                  },
                  onSelectNone: () => {
                    setSelectedRowsKeys([]);
                  },
                  selectedRowKeys: selectedRowsKeys,
                }
          }
        />
      )}
    </ErrorBoundary>
  );
};
