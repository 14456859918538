import React, { FC, useEffect, useMemo, useState } from 'react';
import { BaseSectionPropsType, ExpeditionCardSection } from '../index';
import { LinksAttachments, ImagesAttachments, DocumentsAttachments } from './attachments';
import {
  useAddExpeditionMediaMutation,
  useDeleteMediaItemMutation,
  useLazyGetMediaItemQuery,
  useGetMediaListQuery,
} from '@/state/api';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { DefaultResponse, DefaultMediaType } from '@/types';
import { UploadFile } from 'antd';
import { debounce } from 'lodash';
import { getNameByPath, openErrorNotify, openInfoNotify } from '@/utils';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { ConfirmModal } from '@/components';
import { MediaTargetsTypes } from '@/constants/enums';

export const AttachmentsSection: FC<BaseSectionPropsType> = ({ label, isEditable, item }) => {
  const [selectedImg, setSelectedImg] = useState<UploadFile[]>([]);
  const [selectedDocs, setSelectedDocs] = useState<UploadFile[]>([]);

  const [selectedItem, setSelectedItem] = useState<DefaultMediaType>();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data, isError, isLoading } = useGetMediaListQuery({
    expedition_id: Number(item?.id),
  });

  const [
    deleteMedia,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
      reset: deleteReset,
    },
  ] = useDeleteMediaItemMutation();

  const [uploadMedia] = useAddExpeditionMediaMutation();

  const [downloadMedia] = useLazyGetMediaItemQuery();

  const photoList = data?.data?.photo || [];
  const docsList = data?.data?.documents || [];

  const isImage = (file?: File): boolean => !!file && file?.type?.split('/')[0] === 'image';

  const handleRemove = (file?: File | RcFile) => {
    if (file) {
      const prepareData = (prevState: UploadFile[]) =>
        prevState?.filter(({ name, fileName }) => file?.name !== name && fileName !== name);

      if (isImage(file)) {
        setSelectedImg((prevState) => prepareData(prevState));
      } else {
        setSelectedDocs((prevState) => prepareData(prevState));
      }
    }
  };

  const debounceRemove = useMemo(() => debounce(handleRemove, 300), []);

  const handleUpload = (options: UploadRequestOption) => {
    const { file: fileInfo, onError, onSuccess, filename } = options;
    const file = fileInfo as File;
    uploadMedia({
      expedition_id: Number(item?.id),
      file,
    }).then((response: unknown) => {
      const result = response as DefaultResponse;
      // @ts-ignore
      const error = result?.error as DefaultResponse;
      if (result?.data?.success || error?.data?.success) {
        handleRemove(file);
        onSuccess && onSuccess(response);
      } else {
        const preparedData = {
          ...result,
          name: file?.name || filename || '',
          message: error?.data?.message || result?.data?.message || '',
        };
        onError && onError(preparedData);
      }
    });
  };

  const handleDownload = (item: DefaultMediaType) => {
    const preparedData = {
      path: item?.path,
      type: MediaTargetsTypes.expedition,
    };
    downloadMedia(preparedData).then((res) => {
      if (res?.data?.success) {
        const data = res?.data?.data;
        const link = document.createElement('a');
        link.href = `data:application/${String(item?.media_type)};base64, ${String(data)}`;
        link.target = '_blank';
        link.download = item.name;
        link.click();
      } else {
        const error = res?.error as DefaultResponse;
        openErrorNotify(
          'Произошла ошибка',
          <>
            При загрузке файла <b>{item.name}</b> произошла ошибка.
            <br />
            {res?.data?.message || error?.data?.message}
          </>,
        );
      }
    });
  };

  const handleClick = (event?: UploadChangeParam<UploadFile>) => {
    if (event && event?.file.status !== 'removed') {
      const prepareData = (newItem: UploadFile, prevState: UploadFile[]) => {
        if (prevState?.length === 0) {
          return [newItem];
        }
        const preparedArray = prevState?.map((item) => {
          const itemAvailable = item?.name === newItem?.name;
          if (itemAvailable) {
            return newItem;
          }
          return item;
        });

        return preparedArray?.find((item) => item?.name === newItem?.name)
          ? preparedArray
          : [...preparedArray, newItem];
      };

      if (isImage(event?.file?.originFileObj)) {
        setSelectedImg((prevState) => prepareData(event?.file, prevState));
      } else {
        setSelectedDocs((prevState) => prepareData(event?.file, prevState));
      }
    }
  };

  const handleDelete = (id?: number) => {
    if (id) {
      deleteMedia({ id, type: MediaTargetsTypes.expedition });
    }
  };

  useEffect(() => {
    const handleCheck = (data: UploadFile[]) => {
      data.forEach((item) => {
        if (item?.status === 'done') {
          debounceRemove(item?.originFileObj);
        }
      });
    };
    if (selectedImg.length > 0) {
      handleCheck(selectedImg);
    }
    if (selectedDocs.length > 0) {
      handleCheck(selectedDocs);
    }
  }, [selectedImg, selectedDocs]);

  useEffect(() => {
    if (deleteSuccess) {
      openInfoNotify(
        'Изменения сохранены',
        <>
          Удаление файла <b>{selectedItem?.name}</b> успешно выполнено
        </>,
      );
      deleteReset();
    }
    if (deleteError) {
      openErrorNotify(
        'Произошла ошибка',
        <>
          При удалении файла <b>{selectedItem?.name}</b> произошла ошибка
        </>,
      );
      deleteReset();
    }
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    if (isError) {
      openErrorNotify('Произошла ошибка', 'При загрузке списка вложений произошла ошибка');
    }
  }, [isError]);

  return (
    <ExpeditionCardSection label={label}>
      <>
        <LinksAttachments item={item} isEditable={isEditable} label="Ссылки" />
        <ImagesAttachments
          item={item}
          loading={isLoading}
          isEditable={isEditable}
          label="Изображения"
          fileList={photoList}
          uploadedFilesList={selectedImg}
          onClick={(event) => handleClick(event)}
          onUpload={(options) => handleUpload(options)}
          onRemove={(uploadFile) => handleRemove(uploadFile?.originFileObj)}
          onSelectItem={(item) => setSelectedItem(item)}
          onDelete={() => setShowDeleteModal(true)}
        />
        <DocumentsAttachments
          item={item}
          isEditable={isEditable}
          label="Документы"
          fileList={docsList}
          uploadedFilesList={selectedDocs}
          loading={isLoading}
          onClick={(event) => handleClick(event)}
          onDownload={(item) => handleDownload(item)}
          onUpload={(options) => handleUpload(options)}
          onRemove={(uploadFile) => handleRemove(uploadFile?.originFileObj)}
          onSelectItem={(item) => setSelectedItem(item)}
          onDelete={() => setShowDeleteModal(true)}
        />
        <ConfirmModal
          open={showDeleteModal}
          title="Подтвердите удаление файла"
          content={
            <p>
              Вы уверены, что хотите удалить&nbsp;
              {selectedItem?.name || getNameByPath(selectedItem?.path) ? (
                <>
                  файл <b>{selectedItem?.name || getNameByPath(selectedItem?.path)}</b>
                </>
              ) : (
                'выбранный файл'
              )}
              ?
            </p>
          }
          submitButtonText="Удалить файл"
          cancelButtonText="отменить"
          onSubmit={() => handleDelete(selectedItem?.id)}
          onCancel={() => setShowDeleteModal(false)}
          loading={deleteLoading}
          success={deleteSuccess}
          type="danger"
        />
      </>
    </ExpeditionCardSection>
  );
};
