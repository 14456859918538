import React, { FC } from 'react';
import styles from './error-message.module.scss';
import { ErrorTooltipPropsType } from './error-message.types';
import { ErrorMessage } from 'formik';

export const NewErrorMessage: FC<ErrorTooltipPropsType> = ({
  name,
  className,
  message,
}): JSX.Element | null => {
  const customStyles = [styles.wrapper, className].join(' ');

  if (name === undefined && message !== undefined) {
    return message ? <div className={customStyles}>{message}</div> : null;
  }

  return (
    <ErrorMessage name={name}>
      {(errorText) => <div className={customStyles}>{errorText}</div>}
    </ErrorMessage>
  );
};
