import { resetAction } from '@/state/actions';
import { MarkerCreationStateType } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormikValues } from 'formik';

const initialState: MarkerCreationStateType = {
  isMarkerCreationSelection: false,
  persistedForm: null,
};

const MarkerCreationSlice = createSlice({
  name: 'markerCreationPanel',
  initialState,
  reducers: {
    setIsMarkerCreationSelection: (state, { payload }: PayloadAction<boolean>) => {
      state.isMarkerCreationSelection = payload;
    },
    clearFormWithCoordsMarker: (state) => {
      state.persistedForm = null;
    },
    setFormWithCoordsMarkerValue: (state, { payload }: PayloadAction<FormikValues>) => {
      state.persistedForm = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAction, () => {
      return initialState;
    });
  },
});

export const {
  setIsMarkerCreationSelection,
  clearFormWithCoordsMarker,
  setFormWithCoordsMarkerValue,
} = MarkerCreationSlice.actions;
export const { reducer: objectCreationReducer } = MarkerCreationSlice;
