import React from 'react';
import styles from '../meta-data.module.scss';
import { CloseButton } from '@/components';
import { useDispatch } from 'react-redux';
import { ITooltipMetaDataGals } from './gals.types';
import { setCollectMetaGals, setIsMetaGalsOpenTooltip } from '@/state/slice';
import { useAppSelector } from '@/state';

export const Gals = ({ project, collectLatLng, setOpen }: ITooltipMetaDataGals) => {
  const dispatch = useDispatch();
  const collectMetaGals = useAppSelector(
    (state) => state.toolsPanels.metaGalsPanel.collectMetaGals,
  );

  const getClose = () => {
    setOpen(false);
    dispatch(setIsMetaGalsOpenTooltip(false));
    const collectMetaGalsLatLng = collectMetaGals.filter(
      (item) => item.collectLatLng !== collectLatLng,
    );
    setCollectMetaGals(collectMetaGalsLatLng);
  };

  return (
    <>
      <div className={styles.title}>
        <p className={styles.bold}>Метаданные галса</p>
        <CloseButton onClick={getClose} className={styles.button} />
      </div>
      <div className={styles.body}>
        {project.psd_path && (
          <div className={styles.block}>
            <p className={styles.other}>Путь:</p>
            <p className={styles.path}>{project.psd_path}</p>
          </div>
        )}
        {!project.psd_path && <p className={styles.none}>Метаданных не найдено</p>}
      </div>
    </>
  );
};
