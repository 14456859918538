import {
  defaultSearchProps,
  Icon,
  PanelTableButton,
  TableCell,
  TableFilter,
  Table,
} from '@/components';
import { handleDateSort, handleFilterString, handleSortNumber, handleSortString } from '@/utils';
import { ObjectsTablePropsType } from './objects-table.types';
import { LINK_TO_EXPEDITION_PANEL, LINK_TO_OBJECT_CARD, MAP } from '@/constants';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import React, { FC } from 'react';
import { IObject } from '@/types';
import type { MenuItem } from '@/components';
import { useMap } from '@/context';
import { useNavigate } from 'react-router';
import { defaultDataPickerProps } from '@/components/table-components/table-utils';
import { useDispatch } from 'react-redux';
import { toggleObjectLayer } from '@/state/slice';
import { useAppSelector } from '@/state';
import { Permissions } from '@/constants/enums';
import { ColumnType } from 'antd/es/table';

export const ObjectsTable: FC<ObjectsTablePropsType> = ({
  data,
  loading,
  error,
  onDelete,
}): JSX.Element => {
  const { map } = useMap();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  const handleFlyToObject = (id: string | number): void => {
    const { lat, lng } = data.filter((item) => item.id === id)[0];
    const preparedCords = { lat: Number(lat), lng: Number(lng) };
    navigate(MAP);
    map?.flyTo(preparedCords, 16);
    dispatch(toggleObjectLayer(true));
  };

  const handleOpenObjectCard = (id: string | number): void => {
    navigate(LINK_TO_OBJECT_CARD(Number(id)));
  };

  const handleFilterDistrictId = (
    searchValue: string | number | boolean,
    record: IObject,
  ): boolean => {
    if (!!record.id_in_district) {
      return (
        record.id_in_district
          .split(', ')
          .map((item) => item.toLowerCase().includes(searchValue.toString().toLowerCase()))
          .filter(Boolean).length > 0
      );
    }
    return false;
  };

  const filterColumnsByPermissions = (column: ColumnType<IObject>) => {
    if (column.key === 'groups' && !userPermissions?.includes(Permissions.rolesManagment)) {
      return false;
    }
    if (column.key === 'editable' && !userPermissions?.includes(Permissions.mapDataManagment)) {
      return false;
    }
    if (
      column.key === 'expeditions' &&
      !userPermissions?.includes(Permissions.expeditionsDataViewing)
    ) {
      return false;
    }
    return true;
  };

  const columns: ColumnsType<IObject> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 62,
      sorter: (first, second) => handleSortNumber(first.id, second.id),
    },
    {
      title: <Icon size={12} icon="outline-file-earmark-text" />,
      width: 40,
      align: 'center',
      dataIndex: 'id',
      fixed: 'left',
      render: (value) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Карточка объекта"
          icon="outline-file-earmark-text"
          onClick={() => handleOpenObjectCard(value)}
          size={12}
          tooltipPlacement={'bottom'}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-geo-alt" />,
      dataIndex: 'id',
      fixed: 'left',
      align: 'center',
      width: 40,
      render: (value) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Приблизить к объекту"
          icon="outline-geo-alt"
          onClick={() => handleFlyToObject(value)}
          size={12}
          tooltipPlacement={'bottom'}
        />
      ),
    },
    {
      title: <Icon size={12} icon="outline-trash" />,
      dataIndex: 'id',
      fixed: 'left',
      align: 'center',
      key: 'editable',
      width: 40,
      render: (value) => (
        <PanelTableButton
          showTooltip={true}
          tooltipTitle="Удалить объект"
          icon="outline-trash"
          onClick={() => onDelete(value)}
          size={12}
          tooltipPlacement={'bottom'}
        />
      ),
    },
    {
      title: 'Районный ID',
      dataIndex: 'id_in_district',
      key: 'id_in_district',
      width: 170,
      align: 'left',
      render: (value: string) => {
        const preparedData: MenuItem[] = value.split(', ').map(
          (item: string, index: number): MenuItem => ({
            name: item,
            key: index.toString(),
          }),
        );

        return <TableCell value={preparedData} maxWidth={170} />;
      },
      sorter: (first, second, sortOrder) =>
        handleSortString(first?.id_in_district, second?.id_in_district, sortOrder),
      ...defaultSearchProps('id_in_district', handleFilterDistrictId),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 372,
      render: (value: string) => <TableCell value={value} maxWidth={372} />,
      sorter: (first, second, sortOrder) => handleSortString(first.name, second.name, sortOrder),
      ...defaultSearchProps('name'),
    },
    {
      title: 'Идентификатор',
      dataIndex: 'identifier',
      key: 'identifier',
      align: 'left',
      width: 178,
      sorter: (first, second, sortOrder) =>
        handleSortString(first.identifier, second.identifier, sortOrder),
      render: (value: string) => <TableCell value={value} maxWidth={178} />,
      ...defaultSearchProps('identifier'),
    },
    {
      title: 'Экспедиции',
      dataIndex: 'expeditions',
      align: 'left',
      key: 'expeditions',
      width: 300,
      render: (value: Array<{ expedition_id: number; name: string }>) => {
        const preparedData: MenuItem[] = value?.map(
          ({ expedition_id, name }): MenuItem => ({
            key: expedition_id,
            name: name,
            link: <Link to={LINK_TO_EXPEDITION_PANEL(expedition_id)}>{name}</Link>,
          }),
        );

        return <TableCell value={preparedData} />;
      },
      sorter: (first, second, sortOrder) =>
        handleSortString(first?.expeditions[0]?.name, second?.expeditions[0]?.name, sortOrder),
      ...defaultSearchProps('expeditions', (...rest) =>
        handleFilterString<IObject>(...rest, 'name'),
      ),
    },
    {
      title: 'Дата обнаружения',
      dataIndex: 'date',
      key: 'date',
      align: 'left',
      width: 189,
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second, sortOrder) => handleDateSort(first.date, second.date, sortOrder),
      ...defaultDataPickerProps('date'),
    },
    {
      title: 'Тип объекта',
      dataIndex: 'type',
      key: 'type',
      align: 'left',
      width: 248,
      render: (value) => {
        switch (value) {
          case '1':
            return 'Неисследованный';
          case '2':
            return 'Технический';
          case '3':
            return 'Потенциально опасный';
          case '4':
            return 'Имеющий культурную ценность';
          case '5':
            return 'Военно-исторического наследия';
        }
      },
      sorter: (first, second, sortOrder) => handleSortNumber(first.type, second.type, sortOrder),
      onFilter: (value, record) => record.type === value,
      filterDropdown: ({ filters, clearFilters, confirm, setSelectedKeys, selectedKeys }) => (
        <TableFilter
          filters={filters}
          onFilter={confirm}
          onClear={clearFilters}
          selectedKeys={selectedKeys}
          onChange={setSelectedKeys}
        />
      ),
      filters: [
        {
          text: 'Неисследованный',
          value: '1',
        },
        {
          text: 'Технический',
          value: '2',
        },
        {
          text: 'Потенциально опасный',
          value: '3',
        },
        {
          text: 'Имеющий культурную ценность',
          value: '4',
        },
        {
          text: 'Военно-исторического наследия',
          value: '5',
        },
      ],
    },
    {
      title: 'Группы объектов',
      dataIndex: 'groups',
      key: 'groups',
      align: 'left',
      width: 179,
      render: (value: Array<{ id: number; name: string }>) => {
        const preparedData: MenuItem[] = value?.map(
          ({ id, name }: { id: number; name: string }): MenuItem => ({
            key: id,
            name: name,
          }),
        );

        return <TableCell value={preparedData} maxWidth={179} />;
      },
      sorter: (first, second, sortOrder) =>
        handleSortString(
          first.groups ? first?.groups[0]?.name : null,
          second.groups ? second?.groups[0]?.name : null,
          sortOrder,
        ),
      ...defaultSearchProps('groups', (...rest) => handleFilterString<IObject>(...rest, 'name')),
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'left',
      width: 172,
      render: (value: string) => <TableCell value={value} />,
      sorter: (first, second, sortOrder) =>
        handleDateSort(first.updated_at ?? null, second.updated_at ?? null, sortOrder),
      ...defaultDataPickerProps<IObject>('updated_at'),
    },
  ];

  const preparedColumns = columns.filter(filterColumnsByPermissions);

  return (
    <Table
      dataSource={data}
      columns={preparedColumns}
      loading={loading}
      error={error}
      withSelection={false}
    />
  );
};
