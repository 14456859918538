import { FC, useEffect } from 'react';
import { FormHelperPropsType } from './form-helper.types';
import { isEmpty, isEqual } from 'lodash';
import { FormikTouched, FormikValues, useFormikContext } from 'formik';

export const FormHelper: FC<FormHelperPropsType> = ({ listenForm, setFormIsDirty }) => {
  const formikContext = useFormikContext<FormikValues>();
  const { values, initialValues, touched } = formikContext;

  const handleCheckFormIsDirty = (
    values?: FormikValues,
    touched?: FormikTouched<FormikValues>,
  ): void => {
    if (values && touched && listenForm) {
      setFormIsDirty(!isEqual(values, initialValues) && !isEmpty(touched));
    }
  };

  useEffect(() => {
    handleCheckFormIsDirty(values, touched);
  }, [values, touched]);

  return null;
};
