import React, { FC, useEffect, useState } from 'react';
import { LINK_TO_LOGIN } from '@/constants';
import { AuthorizationWrapper } from '@/components/authorization';
import { useNavigate } from 'react-router';
import { InputTypes } from '@/constants/enums';
import { useSendResetPasswordMutation } from '@/state/api';
import { NewPasswordRequestTypes } from './new-password.types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { NewPasswordForm } from './new-password-form';

export const NewPassword: FC = (): JSX.Element => {
  const [filedTypes, setFiledTypes] = useState(InputTypes.password);
  const [setNewPassword, { isLoading, error, isError, isSuccess }] = useSendResetPasswordMutation();
  const navigate = useNavigate();

  const searchParams = new URL(document.location.toString()).searchParams;
  const token = searchParams.get('token');
  const email = searchParams.get('email');

  useEffect(() => {
    if (!token || !email) {
      navigate(LINK_TO_LOGIN);
    }
  }, [token, email]);

  const handleSubmit = async ({
    password,
    confirm_password,
    token,
    email,
  }: NewPasswordRequestTypes): Promise<void> => {
    if (token && email) {
      await setNewPassword({
        token,
        email,
        password,
        confirm_password,
      });
    }
  };

  const handleCancelResetPassword = (): void => {
    navigate(LINK_TO_LOGIN);
  };

  const handleToggleFieldTypes = (showPassword: boolean): void => {
    if (showPassword) {
      setFiledTypes(InputTypes.text);
    } else {
      setFiledTypes(InputTypes.password);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(LINK_TO_LOGIN);
    }
  }, [isSuccess]);

  return (
    <AuthorizationWrapper headerText="сброс пароля">
      <NewPasswordForm
        onSubmitForm={(value) => {
          handleSubmit({ ...value, token, email });
        }}
        onToggleShowPassword={handleToggleFieldTypes}
        onCancel={handleCancelResetPassword}
        filedTypes={filedTypes}
        loading={isLoading}
        error={error as FetchBaseQueryError}
        isError={isError}
      />
    </AuthorizationWrapper>
  );
};
