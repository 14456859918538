import React, { FC } from 'react';
import { menuData, ObjectCardItem } from '@/components';
import { ObjectCardPropsType } from '../object-card-panel.types';

export const ObjectFrameResearchSection: FC<ObjectCardPropsType> = ({
  onSubmit,
  success,
  loading,
  isEditable,
}) => {
  const fields = [
    {
      label: 'Якоря',
      placeholder: 'Информация не указана',
      name: 'survey_anchor',
    },
    {
      label: 'Трюмы',
      placeholder: 'Информация не указана',
      name: 'survey_hold',
    },
    {
      label: 'Иллюминаторы, бронекрышки',
      placeholder: 'Информация не указана',
      name: 'survey_porthole',
    },
    {
      label: 'Люки и вентиляционные шахты',
      placeholder: 'Информация не указана',
      name: 'survey_manhole',
    },
    {
      label: 'Мачты',
      placeholder: 'Информация не указана',
      name: 'survey_mast',
    },
    {
      label: 'ВРГ',
      placeholder: 'Информация не указана',
      name: 'survey_wheel',
    },
    {
      label: 'Кнехты, швартовые механизмы',
      placeholder: 'Информация не указана',
      name: 'survey_bitt',
    },
    {
      label: 'Механизмы',
      placeholder: 'Информация не указана',
      name: 'survey_mechanism',
    },
    {
      label: 'Корпус',
      placeholder: 'Информация не указана',
      name: 'survey_hull',
    },
    {
      label: 'Коррозия металла, толщина корпуса',
      placeholder: 'Информация не указана',
      name: 'survey_corrosion',
    },
    {
      label: 'Орудия и ВОП',
      placeholder: 'Информация не указана',
      name: 'survey_weapon',
    },
  ];

  return (
    <ObjectCardItem
      title={menuData[3].label}
      type="column"
      fields={fields}
      id={menuData[3].link}
      onSubmit={() => onSubmit()}
      success={success}
      isEditable={isEditable}
      loading={loading}
    />
  );
};
