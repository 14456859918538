import { ToolsButton } from '@/components';
import {
  LINK_TO_Z_CORD,
  LINK_TO_DISTANCE,
  LINK_TO_CORDS_CONVERTER,
  LINK_TO_LOG,
  LINK_TO_GALSES,
} from '@/constants';
import { Permissions } from '@/constants/enums';
import { useAppSelector } from '@/state';
import { setLocatePanelIsOpen, setRulerPanelIsOpen, toggleCordStyle } from '@/state/slice';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

export const MapPageTools: FC<{ navigateToModal: (isActive: boolean, name?: string) => void }> = ({
  navigateToModal,
}) => {
  const {
    panels: { locatePanelIsOpen, rulerPanelIsOpen },
    map: { coordsType: cordStyle },
  } = useAppSelector((state) => state);
  const dispatch = useDispatch();
  const userPermissions = useAppSelector((state) => state.auth.user?.rules);

  const handleOpenModal = (isActive: boolean, name?: string): void => {
    switch (name) {
      case 'locate':
        dispatch(setLocatePanelIsOpen(!isActive));
        break;
      case 'cordStyle':
        dispatch(toggleCordStyle());
        break;
      case 'ruler':
        dispatch(setRulerPanelIsOpen(!isActive));
        break;
      default:
        break;
    }
  };

  const toolsList = [
    {
      icon: 'outline-metadata-log',
      title: 'Метаданные лога',
      path: LINK_TO_LOG,
      hidden: !userPermissions?.includes(Permissions.mapDataViewing),
      onClick: navigateToModal,
    },
    {
      icon: 'outline-metadata-gals',
      title: 'Метаданные галса',
      path: LINK_TO_GALSES,
      hidden: !userPermissions?.includes(Permissions.mapDataViewing),
      onClick: navigateToModal,
    },
    {
      icon: 'outline-coordinate-Z',
      title: 'Глубина',
      path: LINK_TO_Z_CORD,
      hidden: !userPermissions?.includes(Permissions.specificDataViewing),
      onClick: navigateToModal,
    },
    {
      icon: 'outline-geo-alt',
      title: 'Определить местоположение',
      name: 'locate',
      onClick: handleOpenModal,
      isActive: locatePanelIsOpen,
    },
    {
      icon: 'outline-divider',
      title: 'Измерить расстояние',
      path: LINK_TO_DISTANCE,
      hidden: !userPermissions?.includes(Permissions.specificDataViewing),
      onClick: navigateToModal,
    },
    {
      icon: 'outline-convertion',
      title: 'Конвертер координат',
      hidden: !userPermissions?.includes(Permissions.specificDataViewing),
      path: LINK_TO_CORDS_CONVERTER,
      onClick: navigateToModal,
    },
    {
      icon: 'outline-globe',
      title: 'Стиль координат',
      hidden: !userPermissions?.includes(Permissions.specificDataViewing),
      name: 'cordStyle',
      onClick: handleOpenModal,
      isActive: cordStyle === 'DEG',
    },
    {
      icon: 'fill-depth',
      title: 'Глубины',
      name: 'ruler',
      hidden: !userPermissions?.includes(Permissions.mapDataViewing),
      onClick: handleOpenModal,
      isActive: rulerPanelIsOpen,
    },
  ];

  const preparedToolsList = toolsList.filter((item) => !item.hidden);
  const isVisible = preparedToolsList.length;

  return isVisible ? <ToolsButton toolsList={preparedToolsList} /> : null;
};
