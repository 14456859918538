import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/index.scss';
import { App } from '@/components';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const app =
  process.env.REACT_APP_MODE === 'production' ? (
    <App />
  ) : (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

root.render(app);
