import React, { FC } from 'react';
import styles from './conventions-panel.module.scss';
import { ConventionsPanelDesignationsAreaItemTypes } from './conventions-panel.types';

export const ConventionsPanelDesignationsAreaItem: FC<
  ConventionsPanelDesignationsAreaItemTypes
> = ({ area }): JSX.Element => {
  return (
    <>
      <span className={styles['designations-title']}>{area.title}</span>
      <div className={styles.designations}>
        {area.designations.map(
          (item): JSX.Element => (
            <div className={styles['designations-item']} key={item.title}>
              <div className={styles.area + ' ' + styles[`area-${item.color}`]} />
              <p className={styles.textBlock}>
                <span>{item.title}</span>
                <p>{item.description}</p>
              </p>
            </div>
          ),
        )}
      </div>
    </>
  );
};
