import { DefaultResponse, EditSector, ISector, NewSector } from '@/types';
import { parsePolygonToWKT } from '@/utils';
import { Polygon, Point } from 'geojson';
import { LatLngExpression } from 'leaflet';
import { api } from '../api';

const transformSectors = (res: {
  sectors: Array<{
    id: number;
    name: string;
    description: string | null;
    polygon: Polygon;
    center: Point;
  }>;
  success: true;
}): ISector[] =>
  res.sectors.map((sector) => {
    const parsedPolygon: LatLngExpression[][][] = [
      [sector.polygon.coordinates[0].map((cord) => [cord[0], cord[1]])],
    ];

    return {
      id: sector.id,
      name: sector.name,
      description: sector.description,
      coordinates: sector.center.coordinates as LatLngExpression,
      polygon: parsedPolygon,
    };
  });

export const sectorsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSectors: builder.query<
      ISector[],
      {
        expId: number;
      } | void
    >({
      query(body) {
        return {
          url: '/sector/list',
          method: 'GET',
          params: { expId: body?.expId },
          credentials: 'include',
        };
      },
      transformResponse: transformSectors,
      providesTags: ['Sectors'],
    }),
    createSector: builder.mutation<DefaultResponse, NewSector>({
      query(body) {
        const polygon = parsePolygonToWKT(body.polygon, true);

        return {
          url: '/sector',
          method: 'POST',
          body: { ...body, polygon: polygon },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Sectors', 'ExpeditionSummary'],
    }),
    editSector: builder.mutation<DefaultResponse, EditSector>({
      query(body) {
        const polygon = parsePolygonToWKT(body.polygon, true);

        return {
          url: '/sector',
          method: 'PUT',
          body: { ...body, polygon: polygon },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Sectors'],
    }),
    deleteSector: builder.mutation<
      DefaultResponse,
      {
        id: number;
      }
    >({
      query(body) {
        return {
          url: '/sector',
          method: 'DELETE',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Sectors'],
    }),
  }),
});

export const {
  useGetSectorsQuery,
  useCreateSectorMutation,
  useEditSectorMutation,
  useDeleteSectorMutation,
} = sectorsApi;
