import React, { FC } from 'react';
import styles from './header.module.scss';
import { IconButton } from '@/components/buttons';
import { Icon } from '@/components/icon';
import { HeaderPropsType } from './header.types';
import { TableCell } from '@/components/table-components';

export const Header: FC<HeaderPropsType> = ({
  isAutoRotate,
  onClose,
  onReset,
  onAutoRotate,
  title,
}) => {
  const iconSize = 26;

  const infoTooltipContent = (
    <div className={styles['tooltip-content']}>
      Взаимодействия с 3D-моделью:
      <div className={styles.row}>
        <Icon icon={'outline-mouse-pressed-left-button'} color={'black'} size={20} /> Перемещение
        камеры
      </div>
      <div className={styles.row}>
        <Icon icon={'outline-mouse-pressed-right-button'} color={'black'} size={20} /> Вращение
        модели
      </div>
      <div className={styles.row}>
        <Icon icon={'outline-mouse'} color={'black'} size={20} /> Масштабирование
      </div>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.background} />
      <div className={styles.spacer} />
      <div className={styles.title}>
        {title ? <TableCell value={title} maxWidth={200} /> : null}
      </div>
      <div className={styles.spacer} />
      <div className={styles['buttons-wrapper']}>
        <IconButton
          icon={'outline-arrow-counterclockwise'}
          tooltipContent={'Сбросить вид'}
          iconSize={iconSize}
          onClick={() => onReset()}
          iconColor={'#ffffff'}
        />
        <IconButton
          icon={'outline-infinity'}
          iconSize={iconSize}
          onClick={() => onAutoRotate()}
          tooltipContent={'Автовращение модели'}
          iconColor={'#ffffff'}
          isActive={isAutoRotate}
        />
        <IconButton
          icon={'outline-question'}
          iconSize={iconSize}
          tooltipContent={infoTooltipContent}
          iconColor={'#ffffff'}
        />
        <IconButton
          icon={'outline-close'}
          onClick={() => onClose()}
          iconSize={iconSize}
          iconColor={'#ffffff'}
        />
      </div>
    </div>
  );
};
