import React, { FC } from 'react';
import { menuData, ObjectCardItem } from '@/components';
import { ObjectCardPropsType } from '../object-card-panel.types';

export const ObjectDivingSpecialistActionSection: FC<ObjectCardPropsType> = ({
  onSubmit,
  success,
  loading,
  isEditable,
}) => {
  const fields = [
    {
      label: 'Помещения',
      placeholder: 'Нет информации',
      name: 'dive_cabin',
    },
    {
      label: 'Переборки',
      placeholder: 'Нет информации',
      name: 'dive_bulkheads',
    },
    {
      label: 'Груз в трюмах',
      placeholder: 'Нет информации',
      name: 'dive_cargo_hold',
    },
    {
      label: 'Грунт в корпусе',
      placeholder: 'Нет информации',
      name: 'dive_cargo_hull',
    },
    {
      label: 'Люки, двери, горловины',
      placeholder: 'Нет информации',
      name: 'dive_door',
    },
    {
      label: 'Арматура',
      placeholder: 'Нет информации',
      name: 'dive_fitting',
    },
    {
      label: 'Помещения СУ, танки',
      placeholder: 'Нет информации',
      name: 'dive_latrine',
    },
  ];

  return (
    <ObjectCardItem
      title={menuData[4].label}
      type="column"
      fields={fields}
      id={menuData[4].link}
      onSubmit={() => onSubmit()}
      success={success}
      isEditable={isEditable}
      loading={loading}
    />
  );
};
