import { ResetPassword, NewPassword, Login, OtpValidation } from '@/components/authorization';
import { LINK_TO_LOGIN, LOGIN, NEW_PASSWORD, RESET_PASSWORD, OTP_VALIDATION } from '@/constants';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

export const AuthPage: FC = (): JSX.Element => {
  return (
    <Routes>
      <Route path={LOGIN} element={<Login />} />
      <Route path={NEW_PASSWORD} element={<NewPassword />} />
      <Route path={RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={OTP_VALIDATION} element={<OtpValidation />} />
      <Route path={'*'} element={<Navigate to={LINK_TO_LOGIN} />}></Route>
    </Routes>
  );
};
