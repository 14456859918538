import { FieldType, TableModal } from '@/components/table-components';
import { FC, useState } from 'react';
import type { AccessAreaFormType, AccessAreaFormPropsType } from './access-area-content.types';
import * as Yup from 'yup';
import { InputTypes } from '@/constants/enums';

export const AccessAreaForm: FC<AccessAreaFormPropsType> = ({ item, setData, onRedirect }) => {
  const fields: FieldType[] = [
    {
      label: 'Наименование',
      name: 'name',
      placeholder: 'Введите наименование',
      type: InputTypes.text,
      required: true,
    },
    {
      label: 'Максимальное увеличение',
      name: 'maxZoom',
      placeholder: 'Введите увеличение (число, от 4 до 22)',
      type: InputTypes.number,
      required: true,
    },
  ];

  const validationSchema = Yup.object({
    id: Yup.number(),
    name: Yup.string().required('Пожалуйста, введите наименование'),
    maxZoom: Yup.number()
      .typeError('Увеличение должно быть числом')
      .required('Пожалуйста, введите увеличение')
      .min(4, 'Минимальное допустимое увеличение - 4')
      .max(22, 'Максимальное допустимое увеличение - 22'),
  });

  const [isShowAddModal, setIsShowAddModal] = useState(!item);
  const [isShowEditModal, setIsShowEditModal] = useState(!!item);

  const initialValues = {
    name: item?.name || '',
    maxZoom: item?.max_zoom || null,
  };

  const handleCreateForm = (value: AccessAreaFormType) => {
    setData(value);
    setIsShowAddModal(false);
  };
  const handleUpdateForm = (value: AccessAreaFormType) => {
    setData(value);
    setIsShowEditModal(false);
  };

  return (
    <>
      <TableModal
        onSubmit={(value) => handleCreateForm(value as AccessAreaFormType)}
        buttonText="Перейти к созданию полигона"
        onClose={() => setIsShowAddModal(false)}
        title="Добавление области видимости"
        validationSchema={validationSchema}
        redirectOnClose={onRedirect}
        initialValues={initialValues}
        open={isShowAddModal}
        loading={false}
        success={false}
        fields={fields}
        width={563}
      />
      <TableModal
        onSubmit={(value) => handleUpdateForm(value as AccessAreaFormType)}
        onClose={() => setIsShowEditModal(false)}
        validationSchema={validationSchema}
        initialValues={initialValues}
        loading={false}
        redirectOnClose={onRedirect}
        initialFormDirty={true}
        success={false}
        buttonText="Перейти к редактированию полигона"
        open={isShowEditModal}
        fields={fields}
        width={563}
        title={'Изменение области видимости'}
      />
    </>
  );
};
