import React, { FC } from 'react';
import styles from './panel-tab-button.module.scss';
import { Button } from 'antd';
import { TabButtonPropsType } from './panel-tab-button.types';
import { ComponentSize, PanelTabButtonTypes } from '@/constants/enums';
import { UploadButtonWrapper } from '@/components';

export const PanelTabButton: FC<TabButtonPropsType> = ({
  children,
  type = PanelTabButtonTypes.tertiary,
  size = ComponentSize.small,
  onClick,
  tabIndex,
  className,
  disabledStyle,
  loading,
  uploadAvailable = false,
  customRequest,
  onDelete,
  fileType,
  fileList,
  ...rest
}): JSX.Element => {
  const customStyles = [
    styles.wrapper,
    styles[type],
    styles[size],
    disabledStyle ? styles.disabled : '',
    className,
  ].join(' ');

  if (uploadAvailable) {
    return (
      <div className={customStyles}>
        <UploadButtonWrapper
          customRequest={(options) => customRequest && customRequest(options)}
          onDelete={(item) => onDelete && onDelete(item)}
          onClick={(event) => onClick && onClick(event)}
          fileType={fileType}
          fileList={fileList}
        >
          <Button tabIndex={tabIndex} loading={loading} {...rest}>
            {children}
          </Button>
        </UploadButtonWrapper>
      </div>
    );
  }

  return (
    <div className={customStyles}>
      <Button onClick={() => onClick && onClick()} tabIndex={tabIndex} loading={loading} {...rest}>
        {children}
      </Button>
    </div>
  );
};
