import {
  CREATE_SECTOR_PAGE,
  EXPEDITION_STEP_PAGE,
  LINK_TO_DISTANCE,
  LINK_TO_GALSES,
  LINK_TO_LOG,
  LINK_TO_OBJECT_CREATE,
  LINK_TO_Z_CORD,
  POLYGON_TOOL,
  SECTORS_LAYER,
} from '@/constants';
import { useAppSelector } from '@/state';
import { PathOptions, Polygon as LP } from 'leaflet';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Polygon } from 'react-leaflet';
import { WaterAreaPolygonPropsType } from './water-area-polygon.types';
import { MapTooltip } from '../map-tooltip';
import { MapPopup } from '../map-popup';
import { matchPath, useLocation, useParams } from 'react-router';

export const WaterAreaPolygon: FC<WaterAreaPolygonPropsType> = ({
  item,
  onAreasRender,
  isNonInteractive: isNonInteractiveProps,
}) => {
  const selectedLayers = useAppSelector((state) => state.map.selectedLayers);
  const location = useLocation();
  const { id } = useParams();

  const isHidden =
    !!matchPath(POLYGON_TOOL + '/water_area/*', location.pathname) && id && Number(id) === item.id;

  const ref = useRef<LP>(null);

  const isSecondary =
    !!matchPath(CREATE_SECTOR_PAGE + '/*', location.pathname) ||
    !!matchPath(EXPEDITION_STEP_PAGE + '/*', location.pathname) ||
    !!matchPath(POLYGON_TOOL + '/*', location.pathname);

  const isNonInteractive =
    !!matchPath(POLYGON_TOOL + '/*', location.pathname) ||
    !!matchPath(LINK_TO_Z_CORD, location.pathname) ||
    !!matchPath(LINK_TO_LOG, location.pathname) ||
    !!matchPath(LINK_TO_GALSES, location.pathname) ||
    !!matchPath(LINK_TO_DISTANCE, location.pathname) ||
    !!matchPath(LINK_TO_OBJECT_CREATE, location.pathname) ||
    (isNonInteractiveProps !== undefined && isNonInteractiveProps) ||
    !!matchPath(EXPEDITION_STEP_PAGE + '/*', location.pathname) ||
    !!matchPath(CREATE_SECTOR_PAGE + '/*', location.pathname);

  const polygonFillOpacity = isSecondary || selectedLayers.includes(SECTORS_LAYER) ? 0.05 : 0.15;
  const polygonFillColor = '#5CA80F';
  const polygonColor = '#5CA80F';
  const weight = isSecondary ? 1 : 2;
  const withoutTooltip = !!matchPath(EXPEDITION_STEP_PAGE + '/*', location.pathname);
  const dashArray = selectedLayers.includes(SECTORS_LAYER) ? [15, 15] : undefined;
  const isOpenWaterAreaTooltip = useAppSelector((state) => state.waterAreas.isOpenWaterAreaTooltip);

  const defaultPathOptions: PathOptions = {
    fillOpacity: polygonFillOpacity,
    fillColor: polygonFillColor,
    color: polygonColor,
    dashArray: dashArray,
    weight: weight,
    lineJoin: 'round',
    lineCap: 'round',
  };

  useEffect(() => {
    onAreasRender &&
      setTimeout(() => {
        onAreasRender();
      }, 150);
  }, []);

  useEffect(() => {
    if (isNonInteractive) {
      ref.current?.getElement()?.classList.add('inherit-cursor');
    } else {
      ref.current?.getElement()?.classList.remove('inherit-cursor');
    }
  }, [isNonInteractive]);

  return useMemo(() => {
    return isHidden ? null : (
      <Polygon
        key={item.id}
        ref={ref}
        positions={item.polygon}
        interactive={!isNonInteractive}
        pathOptions={defaultPathOptions}
      >
        {isOpenWaterAreaTooltip && !withoutTooltip && (
          <MapTooltip typeOfGeometry={'water-area'} isSecondary={isSecondary} {...item} />
        )}
        {!isNonInteractive ? <MapPopup typeOfGeometry={'water-area'} {...item} /> : null}
      </Polygon>
    );
  }, [item, defaultPathOptions, isOpenWaterAreaTooltip, isNonInteractive, isSecondary, isHidden]);
};
